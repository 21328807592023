<template>
  <div class="ma-4">
    <div class="d-flex flex-wrap">
      <div class="ma-0 pa-0">
        <p class="text-h5 font-weight-bold mb-0">
          {{ $t("vehicles.my_vehicles") }}
        </p>
        <p class="text-caption">
          {{ $t("vehicles.my_vehicles_help") }}
        </p>
      </div>
      <v-spacer></v-spacer>
      <v-menu offset-y v-if="$vuetify.breakpoint.mobile">
        <template v-slot:activator="{ on, attrs }">
          <v-btn :disabled="loading" class="ml-2 black--text" color="primary" depressed fab small v-bind="attrs"
            v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-if="vehicleTypes.length > 0" @click="handleNewVehicleClick">
            <v-list-item-title>
              <v-icon left>mdi-car</v-icon>{{ $t("vehicles.new_vehicle") }}</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="vehicleTypes.length > 0" @click="handleImportVehiclesClick">
            <v-list-item-title>
              <v-icon left> mdi-upload</v-icon>{{ $t("vehicles.import") }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="handleExportVehiclesClick">
            <v-list-item-title><v-icon left>mdi-download</v-icon>{{ $t("vehicles.export") }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn class="ml-auto black--text" v-if="!$vuetify.breakpoint.mobile && vehicleTypes.length > 0"
        :disabled="loading" color="primary" depressed @click="handleNewVehicleClick">
        <v-icon left>mdi-car</v-icon>{{ $t("vehicles.new_vehicle") }}
      </v-btn>
      <v-btn class="ml-2 black--text" v-if="!$vuetify.breakpoint.mobile && vehicleTypes.length > 0" :disabled="loading"
        color="primary" depressed @click="handleImportVehiclesClick">
        <v-icon left> mdi-upload</v-icon>{{ $t("vehicles.import") }}
      </v-btn>
      <v-btn class="ml-2 black--text" v-if="!$vuetify.breakpoint.mobile" :disabled="loading" color="primary" depressed
        @click="handleExportVehiclesClick">
        <v-icon left>mdi-download</v-icon>{{ $t("vehicles.export") }}
      </v-btn>
    </div>
    <v-data-iterator :items="items" :custom-filter="itemsFilter" item-key="id" :items-per-page="4" hide-default-footer
      group-by="status" :search="search" :loading="loading" disable-pagination>
      <template v-slot:header>
        <v-text-field :disabled="loading" v-model="search" class="my-5" clearable flat solo outlined hide-details
          prepend-inner-icon="mdi-magnify" :label="$t('global.search')"></v-text-field>
      </template>
      <template v-slot:no-results>
        <v-container fill-height fluid>
          <v-row align="center" justify="center" class="text-h6 text-center">
            <v-icon left>mdi-car-off</v-icon>{{ $t("vehicles.no_vehicles_found") }}
          </v-row></v-container>
      </template>
      <template v-slot:no-data>
        <v-container fill-height fluid>
          <v-row align="center" justify="center" class="text-h6 text-center">
            <v-icon left>mdi-car-off</v-icon>{{ $t("vehicles.no_vehicles_found") }}
          </v-row></v-container>
      </template>
      <template v-slot:loading>
        <div style="position: relative; min-height: 250px">
          <Loading v-model="loading"></Loading>
        </div>
      </template>
      <template v-slot:group="{ group }">
        <h3>{{ group }}</h3>
      </template>
      <template v-slot:default="{ groupedItems }">
        <div v-for="groupedItem in groupedItems" :key="groupedItem.name">
          <p class="text-h6 ml-2 font-weight-black text-uppercase">
            {{ $t(`vehicle_states_plural.${groupedItem.name.toLowerCase()}`) }}
          </p>
          <v-row>
            <v-col v-for="item in groupedItem.items" :key="item.id" cols="12" sm="6" md="4" lg="3">
              <Vehicle :item="item" @click.stop="handleVehicleClick(item)"></Vehicle>
            </v-col>
          </v-row>
          <v-divider class="my-4"></v-divider>
        </div>
      </template>
    </v-data-iterator>

    <v-snackbar :timeout="5000" :value="showImportedMessage" top absolute center color="success">
      <h3 class="text-center"><v-icon left>mdi-check-circle-outline</v-icon>{{ $t("global.import_successful") }}</h3>
    </v-snackbar>


    <NewVehicleForm v-model="addVehicleForm"></NewVehicleForm>
    <ImportVehiclesForm :open.sync="importVehiclesForm" :success.sync="showImportedMessage"></ImportVehiclesForm>
    <ExportVehiclesForm :open.sync="exportVehiclesForm"></ExportVehiclesForm>

    <VehicleDetails v-model="viewVehicleDetails" :item.sync="selectedItem" @edit="handleEditVehicle"></VehicleDetails>

    <EditItemDialog :open.sync="openEditVehicleForm" :item="selectedItem" @cancel="confirmCancel"
      @closeVehicleDetails="handleCloseVehicleDetails" />
    <ConfirmDialog v-model="confirmDialogOpen" :loading="loading" @confirm="handleCancelVehicle">
      <template v-slot:title>{{ $t("vehicles.cancel_request") }}</template>
      <template v-slot:content>
        {{ $t("vehicles.cancel_request_explanation") }}
      </template>
    </ConfirmDialog>
  </div>
</template>

<script>
import Loading from "@/components/Main/Loading";
import Vehicle from "@/components/Main/Vehicles/Vehicle";
import NewVehicleForm from "@/components/Main/Vehicles/NewVehicleForm";
import VehicleDetails from "@/components/Main/Vehicles/VehicleDetails";
import ConfirmDialog from "@/components/Main/ConfirmDialog";
import ImportVehiclesForm from "@/components/Main/Vehicles/ImportVehiclesForm";
import ExportVehiclesForm from "@/components/Main/Vehicles/ExportVehiclesForm";
import EditItemDialog from "@/components/Main/Vehicles/EditItemDialog";

export default {
  components: {
    Loading,
    Vehicle,
    NewVehicleForm,
    VehicleDetails,
    ConfirmDialog,
    ImportVehiclesForm,
    ExportVehiclesForm,
    EditItemDialog,
  },
  data() {
    return {
      search: "",
      addVehicleForm: false,
      importVehiclesForm: false,
      exportVehiclesForm: false,
      selectedItem: null,
      viewVehicleDetails: false,
      confirmDialogOpen: false,
      openEditVehicleForm: false,
      showImportedMessage: false,
    };
  },
  computed: {
    loading() {
      return this.$store.state.user_vehicles.loading;
    },
    items() {
      return this.$store.state.user_vehicles.all;
    },
    vehicleTypes() {
      return this.$store.state.vehicle_types.all;
    },
  },
  watch: {
    selectedItem() {
      if (this.selectedItem) {
        this.viewVehicleDetails = true;
      }
    },
  },
  mounted() {
    this.loadVehicles();
    this.loadVehicleTypes();
  },
  methods: {
    confirmCancel() {
      this.confirmDialogOpen = true;
    },
    handleVehicleClick(item) {
      this.selectedItem = item;
    },
    handleNewVehicleClick() {
      this.addVehicleForm = true;
    },
    handleImportVehiclesClick() {
      this.importVehiclesForm = true;
    },
    handleExportVehiclesClick() {
      this.exportVehiclesForm = true;
    },
    loadVehicles() {
      this.$store.dispatch("user_vehicles/GET_USER_VEHICLES").catch((e) => {
        console.error(e);
      });
    },
    loadVehicleTypes() {
      this.$store
        .dispatch("vehicle_types/GET_ALL_WITHOUT_PAGINATED")
        .catch((ex) => {
          console.error("Error loading vehicle types", ex);
        });
    },
    handleCloseVehicleDetails() {
      this.viewVehicleDetails = false;
    },
    handleEditVehicle() {
      this.openEditVehicleForm = true;
    },
    handleCancelVehicle() {
      this.viewVehicleDetails = false;
      this.$store
        .dispatch("user_vehicles/CANCEL", this.selectedItem.id)
        .then(() => {
          this.confirmDialogOpen = false;
          this.selectedItem = null;
          this.openEditVehicleForm = false;
          this.loadVehicles();
        })
        .catch((ex) => {
          this.confirmDialogOpen = false;
          console.error("Error canceling vehicle", ex);
        });
    },
    itemsFilter(items, search) {
      if (!search || search === "") return items;

      // Remove dashes from the search string
      const sanitizedSearch = search.replace(/-/g, "");

      // Create a search regex without dashes
      const searchRegex = new RegExp(sanitizedSearch, "i");

      return this.items.filter((item) => {
        // Remove dashes from the registration plate before testing against the search regex
        const sanitizedPlate = item.registration_plate.replace(/-/g, "");
        return (
          searchRegex.test(sanitizedPlate) ||
          searchRegex.test(item.brand) ||
          searchRegex.test(item.model) ||
          searchRegex.test(item.seating_capacity)
        );
      });
    },
  },
};
</script>
