<template>
  <Vehicles />
</template>

<script>
import Vehicles from "@/components/Configurations/Vehicles.vue";

export default {
  components: {
    Vehicles,
  },
};
</script>
