<template>
  <div class="ma-4">
    <div class="d-flex flex-wrap">
      <div class="ma-0 pa-0">
        <p class="text-h5 font-weight-black mb-0">
          {{ $t("configurations.vessel_calls") }}
        </p>
        <p class="text-caption">
          {{ $t("configurations.vessel_calls_explanation") }}
        </p>
      </div>
    </div>

    <List />
  </div>
</template>

<script>
import List from "./VesselCalls/List";
export default {
  components: {
    List,
  },
};
</script>
