<template>
  <v-bottom-sheet v-model="showEntryDetails">
    <v-card class="text-center" tile v-if="!!selectedItem">
      <v-toolbar flat color="primary">
        <v-toolbar-title class="font-weight-bold">
          <v-icon class="black--text" left>mdi-boom-gate-arrow-up-outline</v-icon>
          <span v-if="selectedItem.vehicle.vehicle_type.code != 'TAXI'" class="registration-plate-font">
            {{ selectedItem.vehicle.registration_plate }}
          </span>
          <span v-else class="registration-plate-font">{{ selectedItem.vehicle.license_number }}
          </span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="showEntryDetails = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="entry-details-section pa-2 ma-0">
        <Loading v-model="loading"></Loading>
        <v-card class="pa-0 ma-0" flat v-if="!!entryRequestDetails">
          <v-card-text class="pa-0 ma-0">
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td width="50%" class="font-weight-black text-uppercase">
                      {{ $t("entry_requests.status") }}
                    </td>
                    <td>
                      <v-chip small label :color="getStatusColor(entryRequestDetails.status)" dark
                        class="text-uppercase">
                        {{ getStatusLabel(entryRequestDetails.status) }}
                      </v-chip>
                    </td>
                  </tr>
                  <tr>
                    <td width="50%" class="font-weight-black text-uppercase">
                      {{ $t("vehicles.registration_plate") }}
                    </td>
                    <td class="registration-plate-font">
                      {{ selectedItem.vehicle.registration_plate }}
                    </td>
                  </tr>
                  <tr>
                    <td width="50%" class="font-weight-black text-uppercase">
                      {{ $t("global.ship") }}
                    </td>
                    <td>{{ entryRequestDetails.vessel_call.name }}</td>
                  </tr>
                  <tr>
                    <td class="font-weight-black text-uppercase">
                      {{ $t("global.day") }}
                    </td>
                    <td>
                      {{ entryRequestDetails.vehicle_at_pier_date | formatDatetimeFromDatabaseOnlyDate }}
                    </td>
                  </tr>
                  <tr>
                    <td width="50%" class="font-weight-black text-uppercase">
                      {{ $t("entries.vehicle_at_pier_date_request") }}
                    </td>
                    <td>
                      {{ entryRequestDetails.vehicle_at_pier_date | formatDatetimeFromDatabaseOnlyTime }}
                    </td>
                  </tr>
                  <tr v-if="!!entryRequestDetails.responsible_name">
                    <td width="50%" class="font-weight-black text-uppercase">
                      {{ $t("entries.responsible_name") }}
                    </td>
                    <td>{{ entryRequestDetails.responsible_name }}</td>
                  </tr>
                  <tr v-if="!!entryRequestDetails.responsible_phone">
                    <td width="50%" class="font-weight-black text-uppercase">
                      {{ $t("entries.responsible_phone") }}
                    </td>
                    <td>{{ entryRequestDetails.responsible_phone }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-card-text><v-card-actions class="entry-request-actions" v-if="!!entryRequestDetails">
        <div class="stacked-buttons">
          <v-btn v-if="item.accredited && entryRequestDetails.status != 'BLOCKED' &&
    entryRequestDetails.status != 'CANCELED' && entryRequestDetails.status != 'REJECTED' &&
    !isOutdated()" @click.stop="handleSwapVehicleEntryRequest" class="flex-grow-1" :disabled="loading" depressed>
            <v-icon left>mdi-pencil</v-icon>
            {{ $t("entry_requests.edit") }}
          </v-btn>
          <v-btn v-if="item.accredited && entryRequestDetails.status == 'CANCELED' && !isOutdated()"
            @click.stop="handleRevertCancelEntryRequest" class="flex-grow-1" :disabled="loading" depressed>
            <v-icon left>mdi-rotate-left</v-icon>
            {{ $t("entry_requests.revert_cancel") }}
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>

    <EditItemDialog :open.sync="substituteVehicleDialogOpen" :entryRequestDetails.sync="entryRequestDetails"
      @editedRequestSuccessfully="handleEditedRequestSuccessfully" @cancelRequest="handleCancelEntryRequest"
      @loadEntryRequest="loadEntryRequestDetails">
    </EditItemDialog>

    <ConfirmDialog v-model="confirmDialogOpen" :loading="loadingCancelRequest"
      @confirm="handleCancelEntryRequestConfirmation">
      <template v-slot:title>{{ $t("entry_requests.cancel_request") }}
      </template>
      <template v-slot:content>
        {{ $t("entry_requests.cancel_request_explanation") }}
      </template>
    </ConfirmDialog>
  </v-bottom-sheet>
</template>
<script>
import Vehicle from "@/components/Main/Vehicles/Vehicle";
import Loading from "@/components/Main/Loading";
import ConfirmDialog from "@/components/Main/ConfirmDialog";
import CountryFlag from "vue-country-flag";
import EditItemDialog from "@/components/Main/EntryRequests/EditItemDialog";
import configs from "@/helpers/configs";

export default {
  props: ["value", "item"],
  components: {
    Loading,
    ConfirmDialog,
    Vehicle,
    CountryFlag,
    EditItemDialog,
  },
  data() {
    return {
      editLoading: false,
      loading: false,
      loadingCancelRequest: false,
      entryRequestDetails: null,
      confirmDialogOpen: false,
      loadingSubstituteVehicleRequest: false,
      substituteVehicleDialogOpen: false,
      openEditEntryRequestDialog: false,
    };
  },
  computed: {
    userVehicles() {
      return this.$store.state.user_vehicles.all;
    },
    selectedItem: {
      get() {
        return this.item;
      },
      set(val) {
        this.$emit("update:item", val);
      },
    },
    showEntryDetails: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
        if (!val) {
          setTimeout(() => {
            this.selectedItem = null;
          }, 100);
        }
      },
    },
  },
  watch: {
    showEntryDetails(val) {
      if (val) {
        this.loadEntryRequestDetails();
      }
    },
  },
  methods: {
    loadEntryRequestDetails() {
      if (this.selectedItem?.id) {
        this.loading = true;
        this.entryRequestDetails = null;
        this.$store
          .dispatch("entry_requests/GET_BY_ID", this.selectedItem.id)
          .then((data) => {
            this.loading = false;
            this.entryRequestDetails = data;
          })
          .catch((e) => {
            console.error(e);
          });
      }
    },
    handleEditedRequestSuccessfully() {
      this.showEntryDetails = false;
      this.$store.dispatch(
        "entry_requests/GET_ALL_BY_DATE",
        this.entryRequestDetails.vehicle_at_pier_date.toString().slice(0, 10)
      );
    },
    handleCancelEntryRequest() {
      this.confirmDialogOpen = true;
    },
    handleSwapVehicleEntryRequest() {
      this.substituteVehicleDialogOpen = true;
    },
    handleEditEntryRequest() {
      this.openEditEntryRequestDialog = true;
    },
    handleRevertCancelEntryRequest() {
      this.loading = true;
      this.$store
        .dispatch("entry_requests/REVERT_CANCEL", this.selectedItem.id)
        .then(() => {
          this.loading = false;
          this.$store.dispatch(
            "entry_requests/GET_ALL_BY_DATE",
            this.entryRequestDetails.vehicle_at_pier_date
              .toString()
              .slice(0, 10)
          );
          this.loadEntryRequestDetails();
        })
        .catch((e) => {
          console.error(e);
        });
    },
    handleCancelEntryRequestConfirmation() {
      this.loadingCancelRequest = true;
      this.$store
        .dispatch("entry_requests/CANCEL", this.selectedItem.id)
        .then(() => {
          this.substituteVehicleDialogOpen = false;
          this.confirmDialogOpen = false;
          this.loadingCancelRequest = false;
          this.$store.dispatch(
            "entry_requests/GET_ALL_BY_DATE",
            this.entryRequestDetails.vehicle_at_pier_date
              .toString()
              .slice(0, 10)
          );
          this.loadEntryRequestDetails();
        })
        .catch((e) => {
          console.error(e);
        });
    },
    isOutdated() {
      let currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);

      let entryRequestDate = new Date(
        this.entryRequestDetails.vehicle_at_pier_date
      );
      entryRequestDate.setHours(0, 0, 0, 0);

      return entryRequestDate < currentDate;
    },
    getStatusLabel(status) {
      return configs.getStatusLabel(status);
    },
    getStatusColor(status) {
      return configs.getStatusColor(status);
    },
  },
};
</script>
<style scoped>
.entry-details-section {
  min-height: 300px;
  max-height: calc(60vh - var(--toolbar-height));
  overflow-y: auto;
  position: relative;
  text-align: left;
}

.entry-details-section:has(+ .entry-request-actions) {
  min-height: 300px;
  max-height: calc(60vh - var(--toolbar-height) - 60px);
}

.stacked-buttons {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
}

.registration-plate-font {
  font-family: "Fira Mono", monospace !important;
  font-weight: 700 !important;
}
</style>
