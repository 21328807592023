<template>
  <v-dialog
    v-model="isOpen"
    persistent
    width="80%"
    scrollable
    :fullscreen="$vuetify.breakpoint.mobile"
  >
    <v-card tile>
      <v-expand-transition>
        <div v-show="expand">
          <v-alert class="mb-0" tile type="error" @click="expand = false">
            <div
              v-for="(message, index) in this.messages"
              :key="index"
              class="subtitle"
            >
              {{ message }}
            </div>
          </v-alert>
        </div>
      </v-expand-transition>
      <v-progress-linear
        height="10"
        striped
        color="primary"
        indeterminate
        v-show="loading"
      ></v-progress-linear>
      <v-toolbar color="primary" flat>
        <v-toolbar-title>
          <span>
            <v-icon left>mdi-pencil</v-icon>{{ $t("entry_requests.edit") }}
          </span>
        </v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text
        :style="
          $vuetify.breakpoint.mobile
            ? 'height: calc(100vh - 110px); overflow-y: auto'
            : 'overflow-y: auto'
        "
      >
        <v-form ref="form">
          <div class="d-flex flex-column">
            <div v-if="item && item.vehicle">
              <div>
                <p class="mt-5 mb-2">
                  <span class="text-h6 font-weight-bold"
                    >Veículos Disponíveis:</span
                  >
                </p>
                <Loading
                  v-if="loadingAvailableVehicles"
                  :value="loadingAvailableVehicles"
                  :custom-message="$t('vehicles.loading_available_vehicles')"
                ></Loading>
                <v-item-group v-if="vehicles.length > 0">
                  <v-container
                    class="d-flex flex-row flex-wrap vehicle-container"
                  >
                    <v-row>
                      <v-col
                        v-for="(item, index) in vehicles"
                        :key="index"
                        cols="12"
                        sm="6"
                        md="4"
                        lg="3"
                      >
                        <SimpleVehicleCard
                          :item="item"
                          :index="index"
                          :activeIndex="activeIndex"
                          :disabled="loading"
                          class="ma-1"
                          @activate="activateCard"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-item-group>
                <div v-else>
                  <p class="text-h6 text-center">
                    {{ $t("vehicles.no_available_vehicles") }}
                  </p>
                </div>
              </div>

              <div>
                <p class="mt-2 mb-2">
                  <span class="text-h6 font-weight-bold"
                    >Informação do Pedido:</span
                  >
                </p>
                <v-container class="info-container">
                  <div class="flex-container">
                    <v-text-field
                      v-model="item.responsible_name"
                      clearable
                      append-icon="mdi-badge-account-horizontal-outline"
                      :label="$t('entry_requests.responsible_name')"
                      outlined
                      counter="50"
                      maxlength="50"
                      :rules="responsibleNameRules"
                    ></v-text-field>
                  </div>
                  <div class="flex-container">
                    <v-text-field
                      v-model="item.responsible_phone"
                      clearable
                      append-icon="mdi-phone"
                      :label="$t('entry_requests.responsible_phone')"
                      :rules="responsiblePhoneRules"
                      outlined
                      counter="25"
                      maxlength="25"
                    ></v-text-field>
                  </div>
                </v-container>
              </div>
            </div>
          </div>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn
          @click.stop="cancelEntryRequest"
          :disabled="loading"
          depressed
          color="error"
        >
          <v-icon left>mdi-cancel</v-icon>
          {{ $t("entry_requests.cancel_request") }}
        </v-btn>
        <v-spacer />
        <v-btn
          class="black--text"
          color="primary"
          depressed
          @click="confirmSave"
          :disabled="loading || activeIndex == -1"
        >
          {{ $t("global.update") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="editRequestDialog" persistent width="450">
      <v-card>
        <v-card-text>
          <div class="text-center pt-4">
            <v-icon size="100" color="green" left>mdi-check-circle</v-icon>
          </div>
          <p class="text-center text-h6 ma-0 pt-4" style="color: black">
            {{ $t("entry_requests.your_request_was_edited_successfully") }}
          </p>
        </v-card-text>
      </v-card>
    </v-dialog>
    <ConfirmDialog
      v-model="confirmDialogOpen"
      :loading="loadingEditRequest"
      @confirm="save"
      @cancel="confirmDialogOpen = false"
    >
      <template v-slot:title>{{ $t("global.edit") }}</template>
      <template v-slot:content>
        {{ $t("entry_requests.edit_request_explanation") }}
      </template>
    </ConfirmDialog>
  </v-dialog>
</template>

<script>
import ConfirmDialog from "@/components/Main/ConfirmDialog";
import { phoneNumberValidation } from "@/helpers/common_validations.js";
import SimpleVehicleCard from "@/components/Main/EntryRequests/SimpleVehicleCard";
import Loading from "@/components/Main/Loading";

const MAX_SEATING = 100;
export default {
  props: ["open", "entryRequestDetails"],
  components: {
    ConfirmDialog,
    SimpleVehicleCard,
    Loading,
  },
  data() {
    return {
      loadingAvailableVehicles: false,
      loadingEditRequest: false,
      confirmDialogOpen: false,
      loading: false,
      expand: false,
      messages: [],
      responsibleNameRules: [(v) => !!v || this.$t("rules.required_field")],
      responsiblePhoneRules: [
        phoneNumberValidation,
        (v) => !!v || this.$t("rules.required_field"),
      ],
      numberOfPassengersRules: [
        (v) => !v || this.isNumeric(v) || this.$t("rules.must_be_numeric"),
      ],
      activeIndex: 0,
      editRequestDialog: false,
    };
  },
  computed: {
    isOpen: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("update:open", val);
      },
    },
    item() {
      return JSON.parse(JSON.stringify(this.entryRequestDetails));
    },
    availableVehicles() {
      return this.$store.state.vehicles.available;
    },
    vehicles() {
      return [this.item.vehicle].concat(this.availableVehicles);
    },
    maxSeating() {
      return this.vehicles[this.activeIndex].seating_capacity ?? MAX_SEATING;
    },
  },
  watch: {
    isOpen(val) {
      if (val) {
        this.loadingAvailableVehicles = true;
        this.$store
          .dispatch("vehicles/GET_AVAILABLE", {
            vehicleId: this.item.vehicle.id,
            numberOfPassengers: this.item.number_of_passengers,
            vesselCallId: this.item.vessel_call_id,
            vehicleTypeId: this.item.vehicle.vehicle_type.id,
            date: this.item.vehicle_at_pier_date,
          })
          .then(() => {
            this.loadingAvailableVehicles = false;
          })
          .catch(() => {
            this.loadingAvailableVehicles = false;
          });
      }
    },
  },
  methods: {
    isNumeric(num) {
      return num && !isNaN(num) && parseInt(num) > 0;
    },
    restrictInput(event, entryRequest) {
      let max = this.maxSeating;

      if (
        event.key === "ArrowUp" &&
        parseInt(entryRequest.number_of_passengers) >= max
      ) {
        event.preventDefault();
      }

      if (
        event.target.classList.contains("v-input__icon--append") &&
        currentValue >= max
      ) {
        event.preventDefault();
      }

      if (
        event.key.match(/[0-9]/) &&
        parseInt(entryRequest.number_of_passengers + event.key) > max
      ) {
        event.preventDefault();
      }
    },
    getLabelPassengerToCollect(number) {
      return "Nº de passageiros (máx: " + (number ?? MAX_SEATING) + ")";
    },
    close() {
      this.isOpen = false;
      this.expand = false;
      this.loading = false;
      this.messages = [];
      this.activeIndex = 0;
      this.$emit("loadEntryRequest");
    },
    confirmSave() {
      if (this.$refs.form.validate()) {
        this.confirmDialogOpen = true;
      }
    },
    activateCard(index) {
      this.activeIndex = index;
    },
    cancelEntryRequest() {
      this.$emit("cancelRequest");
    },
    save() {
      this.confirmDialogOpen = false;
      this.expand = false;
      this.loading = true;
      this.messages = [];
      this.$store
        .dispatch("entry_requests/EDIT", {
          entryRequestId: this.item.id,
          vehicleId: this.vehicles[this.activeIndex].id,
          responsibleName: this.item.responsible_name,
          responsiblePhone: this.item.responsible_phone,
          numberOfPassengers: this.item.number_of_passengers,
        })
        .then((res) => {
          if (res.errors) {
            Object.entries(res.errors).forEach(([, value]) => {
              this.messages = this.messages.concat(value);
            });
            this.expand = true;
            this.loading = false;
          } else {
            this.editRequestDialog = true;
            setTimeout(() => {
              this.editRequestDialog = false;
              this.$emit("editedRequestSuccessfully");
              this.close();
            }, 2000);
          }
        });
    },
  },
};
</script>

<style scoped>
.info-container {
  overflow-y: auto;
  max-width: 100%;
}

/* To make v-text-field fill the width */
.flex-container {
  display: flex;
  width: 100%;
}

/* To force v-text-field to occupy 100% width */
.v-text-field {
  width: 100%;
}
.vehicle-container {
  overflow-y: auto;
  max-width: 100%;
}
</style>
