<template>
  <div style="position: absolute; top: 10px; right: 10px" v-if="!!zone">
    <v-card width="300">
      <v-toolbar flat dense>
        <v-toolbar-title>{{ zone.park }} - {{ zone.code }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="setSelectedZone(null)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-text style="max-height: 300px; overflow-y: auto" class="pa-0">
        <v-list
          dense
          class="ma-0 pa-0 pb-2"
          v-if="this.entries && this.entries.length > 0"
        >
          <template v-for="item in entries">
            <v-list-item
              :key="item.id"
              style="min-height: 20px; height: 20px"
              class="mt-2"
            >
              <v-list-item-avatar
                size="20"
                tile
                color="primary"
                style="border: 1px solid black"
                outlined
                class="mr-2 black--text"
              >
                <v-icon x-small class="black--text">
                  {{
                    item.vehicle.vehicle_type
                      ? item.vehicle.vehicle_type.icon
                      : "mdi-car"
                  }}
                </v-icon>
              </v-list-item-avatar>

              <v-list-item-content
                class="d-inline-block text-truncate pa-0 ma-0"
              >
                {{ item.vehicle.registration_plate }}
              </v-list-item-content>
              <v-list-item-action>
                <div>
                  <v-btn icon small @click="setSelectedEntry(item)"
                    ><v-icon small>mdi-fit-to-screen-outline</v-icon></v-btn
                  >
                </div>
              </v-list-item-action>
            </v-list-item>
          </template>
        </v-list>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
export default {
  props: ["zone", "entries", "entry"],
  computed: {
    selectedEntry: {
      get() {
        return this.entry;
      },
      set(val) {
        this.$emit("update:entry", val);
      },
    },
    selectedZone: {
      get() {
        return this.zone;
      },
      set(val) {
        this.$emit("update:zone", val);
      },
    },
  },
  methods: {
    setSelectedEntry(item) {
      this.selectedEntry = item;
    },
    setSelectedZone(item) {
      this.selectedZone = item;
    },
  },
};
</script>
