<template>
  <v-data-table
    v-model="selectedZone"
    :footer-props="{
      showFirstLastPage: true,
      firstIcon: 'mdi-page-first',
      lastIcon: 'mdi-page-last',
      'items-per-page-options': [5, 10, 25, 50],
    }"
    :search.sync="options.search"
    :page.sync="options.page"
    :headers="headers"
    :loading="loading"
    :items="items"
    :server-items-length="totalItemCount"
    @update:options="handleOptionsUpdate('options', $event)"
    :items-per-page.sync="options.itemsPerPage"
    item-key="id"
    fixed-header
    :sort-by.sync="options.sortBy"
    :sort-desc.sync="options.sortDesc"
    must-sort
    class="zones-list"
  >
    <template v-slot:top>
      <v-text-field
        v-model="options.search"
        hide-details="auto"
        single-line
        :label="$t('zones.search')"
        prepend-inner-icon="mdi-magnify"
        outlined
        @input="filterItems"
        style="margin: 20px; width: 95%"
      />
    </template>
    <template v-slot:item.priority_spot="{ item }">
      {{ item.priority_spot ? $t("global.yes") : $t("global.no") }}
    </template>
    <template v-slot:item.unavailable="{ item }">
      {{ item.unavailable ? $t("global.yes") : $t("global.no") }}
    </template>
    <template
      v-if="hasUserPermissionToViewEditOrManage('ZONES')"
      v-slot:[`item.actions`]="{ item }"
    >
      <v-menu bottom absolute>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item @click="edit(item)">
            <v-list-item-icon class="mr-2">
              <v-icon>mdi-pencil</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t("global.edit") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="hasUserPermissionToManage('ZONES')" @click="remove(item)">
            <v-list-item-icon class="mr-2">
              <v-icon>mdi-delete</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t("global.remove") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
    <template #footer.prepend>
      <v-spacer />
    </template>
  </v-data-table>
</template>

<script>
import { debounce } from "debounce";
export default {
  props: ["selected"],
  data() {
    return {
      search: "",
      page: 1,
      itemsPerPage: 10,
      headers: [
        {
          text: "",
          value: "actions",
          sortable: false,
          width: "30px",
        },
        {
          text: this.$t("zones.code"),
          value: "code",
          sortable: true,
        },
        {
          text: this.$t("zones.park"),
          value: "park",
          sortable: true,
        },
        {
          text: this.$t("zones.priority_spot"),
          value: "priority_spot",
          sortable: false,
        },
        {
          text: this.$t("zones.unavailable"),
          value: "unavailable",
          sortable: true,
        },
      ],
      sortBy: "code",
      sortDesc: false,
    };
  },
  computed: {
    loading() {
      return this.$store.state.zones.loading;
    },
    items() {
      return this.$store.state.zones.paginatedZones;
    },
    totalItemCount() {
      return this.$store.state.zones.total;
    },
    options: {
      cache: false,
      get() {
        return this.$store.state.zones.options;
      },
      set(val) {
        this.$store.dispatch("zones/UPDATE_OPTIONS", val);
      },
    },
    selectedZone: {
      get() {
        return this.selected;
      },
      set(val) {
        this.$emit("update:selected", val);
      },
    },
  },
  methods: {
    edit(item) {
      this.$emit("edit", item);
    },
    remove(item) {
      this.$emit("remove", item);
    },
    loadZones() {
      this.$store.dispatch("zones/GET_ALL_PAGINATED").catch((ex) => {
        console.error("Error loading zones", ex);
      });
    },
    handleOptionsUpdate: debounce(function () {
      this.loadZones();
    }, 500),

    filterItems: debounce(function () {
      // Changes the current page to the first one to reset the search stop bugs from happening
      if (this.options.page == 1) {
        this.loadZones();
      } else {
        this.options.page = 1;
      }
    }, 500),
  },
};
</script>

<style>
.zones-list > .v-data-table__wrapper {
  height: calc(100vh - 495px) !important;
}
tr.v-data-table__selected {
  background: #e4ecc2 !important;
}

</style>
