<template>
  <div>
    <Loading v-if="loading" :value="loading"></Loading>
    <v-slide-group
      :class="disabled ? 'slider-disabled ' : warning ? 'warning-border' : ''"
      v-model="type"
      show-arrows
      center-active
      v-else
    >
      <v-slide-item
        v-for="vehicleType in vehicleTypes"
        :key="vehicleType.id"
        v-slot="{ active, toggle }"
        :value="vehicleType.code"
      >
        <v-card
          outlined
          :dark="active"
          :color="active ? 'primary' : ''"
          :class="{
            'disabled-card': disabled && !active,
            'mx-2 my-2 text-center': true,
            'warning-border': warning,
          }"
          @click="toggle"
          :disabled="disabled"
          height="100"
          width="150"
        >
          <v-container fill-height fluid>
            <v-row align="center" justify="center">
              <v-avatar tile class="mt-5"
                ><v-icon size="45">{{ vehicleType.icon }}</v-icon></v-avatar
              >
            </v-row>
            <v-row align="center" justify="center"
              ><p class="overline text-uppercase font-weight-black">
                {{ vehicleType.name }}
              </p></v-row
            ></v-container
          >
        </v-card>
      </v-slide-item>
    </v-slide-group>
    <span v-if="warning" class="warning-text">Campo obrigatório</span>
  </div>
</template>
<script>
import Loading from "@/components/Main/Loading";
export default {
  props: {
    value: {
      type: String,
      required: true,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    warning: {
      type: Boolean,
      default: false,
    },
  },
  components: { Loading },
  computed: {
    type: {
      get() {
        return this.value || "";
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    vehicleTypes() {
      return this.$store.state.vehicle_types.all;
    },
    loading() {
      return this.$store.state.vehicle_types.loading;
    },
  },
};
</script>

<style>
.slider-disabled .v-slide-group__next {
  display: none !important;
}

.slider-disabled .v-slide-group__prev {
  display: none !important;
}

.disabled-card {
  opacity: 0.5 !important;
  pointer-events: none !important;
}

.warning-border {
  border: 2px solid #b71c1c;
  border-radius: 5px;
}

.warning-text {
  color: #b71c1c;
  font-size: 12px;
  margin: 10px;
}
</style>
