import { settingsService } from "@/api_services/settings.service";

export default {
  namespaced: true,
  state: {
    hdEnabled: false,
    locale: "pt",
  },
  mutations: {
    setHDEnabled(state, enabled) {
      state.hdEnabled = enabled;
    },
    setLocale(state, payload) {
      state.locale = payload.locale;
    },
  },
  actions: {
    ENABLE_HD: (context) => {
      context.commit("setHDEnabled", true);
    },
    DISABLE_HD: (context) => {
      context.commit("setHDEnabled", false);
    },
    SET_HD_ENABLED: (context, enabled) => {
      context.commit("setHDEnabled", enabled);
    },

    GET_LDAP_CONFIGURATIONS: () => {
      return new Promise((resolve, reject) => {
        settingsService
          .getLdapConfigurations()
          .then((res) => {
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    UPDATE_LDAP_CONFIGURATIONS: (context, configs) => {
      return new Promise((resolve, reject) => {
        settingsService
          .saveLdapConfigurations(configs)
          .then(() => {
            resolve();
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },
  },
};
