<template>
  <OperatorTypes />
</template>

<script>
import OperatorTypes from "@/components/Configurations/OperatorTypes";

export default {
  components: {
    OperatorTypes,
  },
};
</script>
