<template>
  <Zones />
</template>

<script>
import Zones from "@/components/Configurations/Zones";

export default {
  components: {
    Zones,
  },
};
</script>
