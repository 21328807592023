<template>
  <v-bottom-sheet v-model="isOpen" content-class="new-member-dialog" persistent>
    <v-card flat class="fill-height">
      <v-toolbar color="primary" flat>
        <v-toolbar-title>
          <span>
            <v-icon left>mdi-account-box</v-icon>{{ $t("members.new_member") }}
          </span>
        </v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text
        class="overflow-y-auto"
        style="height: calc(100% - var(--toolbar-height) - 80px)"
      >
        <v-form ref="form" class="py-4">
          <v-alert color="blue-grey" text icon="mdi-information-outline">{{
            $t("members.new_member_help")
          }}</v-alert>
          <v-text-field
            v-model="item.name"
            autocomplete="off"
            type="text"
            :label="$t('users.name')"
            :placeholder="$t('users.name')"
            :rules="requiredRule"
            prepend-inner-icon="mdi-card-text"
            counter="50"
            maxlength="50"
            outlined
          ></v-text-field>

          <v-text-field
            v-model="item.email"
            :label="$t('users.email')"
            :placeholder="$t('users.email')"
            :rules="emailRules"
            prepend-inner-icon="mdi-email-outline"
            counter="50"
            maxlength="50"
            outlined
          ></v-text-field>

          <v-text-field
            counter="25"
            maxlength="25"
            v-model="item.phone"
            prepend-inner-icon="mdi-phone"
            required
            :placeholder="$t('users.phone')"
            :label="$t('users.phone')"
            :rules="phoneNumberRules"
            outlined
          />
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          large
          color="primary"
          class="black--text"
          depressed
          @click.stop="save"
          :block="$vuetify.breakpoint.mobile"
        >
          <v-icon>mdi-plus</v-icon>{{ $t("global.invite") }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog v-model="creatingDialog" persistent width="520">
      <v-card class="fill-height">
        <v-card-text style="min-height: 250px; position: relative">
          <Loading
            v-model="creatingDialog"
            :custom-message="$t('members.inviting_member_please_wait')"
          ></Loading>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="createdDialog" persistent width="450">
      <v-card>
        <v-card-text>
          <div class="text-center pt-4">
            <v-icon size="100" color="green" left>mdi-check-circle</v-icon>
          </div>
          <p class="text-center text-h6 ma-0 pt-4" style="color: black">
            {{ $t("members.member_was_invited_successfully") }}
          </p>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="errorDialog" width="450">
      <v-card>
        <v-card-text>
          <div class="text-center pt-4">
            <v-icon size="100" color="red" left>mdi-close-circle</v-icon>
          </div>
          <p class="text-center text-h6 ma-0 pt-4" style="color: black">
            {{
              errorMsg || $t("global.an_error_occurred_processing_your_request")
            }}
          </p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn block depressed color="primary black--text" @click="errorDialog = false">
            {{ $t("global.close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-bottom-sheet>
</template>
<script>
const DEFAULT_ITEM = {
  email: null,
  name: null,
  phone: null,
};

import { phoneNumberValidation } from "@/helpers/common_validations.js";
import Loading from "@/components/Main/Loading";
export default {
  props: ["value"],
  components: { Loading },
  computed: {
    isOpen: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    operatorId() {
      return this.$store.state.user.account.user.operator_id;
    },
  },
  data() {
    return {
      item: Object.assign({}, DEFAULT_ITEM),
      creatingDialog: false,
      createdDialog: false,
      errorDialog: false,
      errorMsg: null,
      requiredRule: [(v) => !!v || this.$t("rules.required_field")],
      phoneNumberRules: [
        (v) => !!v || this.$t("rules.required_field"),
        phoneNumberValidation,
      ],
      emailRules: [
        (v) => !!v || "Endereço de e-mail obrigatório",
        (v) =>
          !v ||
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          this.$t("rules.email_invalid"),
        (v) => (v && v.length <= 50) || "O limite de caracteres é 255",
      ],
    };
  },
  watch: {
    isOpen(val) {
      if (val) {
        this.$nextTick(() => {
          this.item = Object.assign({}, DEFAULT_ITEM);
          this.$refs.form.reset();
        });
      }
    },
  },
  methods: {
    close() {
      this.isOpen = false;
    },
    save() {
      let member = Object.assign({}, this.item);
      if (this.$refs.form.validate()) {
        this.creatingDialog = true;
        this.$store
          .dispatch("operators/INVITE_MEMBER", {
            operator_id: this.operatorId,
            member: member,
          })
          .then((data) => {
            if (data.error) {
              this.creatingDialog = false;
              this.errorDialog = true;
              this.errorMsg = data.msg;
            } else {
              this.creatingDialog = false;
              this.createdDialog = true;
              setTimeout(() => {
                this.createdDialog = false;
                this.close();
              }, 2000);
            }
          })
          .catch(() => {
            this.creatingDialog = false;
            this.errorDialog = true;
          });
      }
    },
  },
};
</script>
<style>
.new-member-dialog {
  min-height: 40dvh;
  max-height: 70dvh;
}
</style>
