<template>
  <v-bottom-sheet
    v-model="isOpen"
    content-class="new-vehicle-dialog"
    persistent
  >
    <v-card flat class="fill-height">
      <v-toolbar color="primary" flat>
        <v-toolbar-title>
          <span>
            <v-icon left>mdi-car</v-icon>{{ $t("vehicles.new_vehicle") }}
          </span>
        </v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text
        class="overflow-y-auto"
        style="height: calc(100% - var(--toolbar-height) - 80px)"
      >
        <v-form ref="form" class="py-4">
          <div v-if="!operator.types.map((t) => t.code).includes('CAB')">
            <v-alert color="blue-grey" text icon="mdi-information-outline">{{
              $t("vehicles.vehicles_form_step_1")
            }}</v-alert>
            <VehicleTypeSelector
              v-model="item.vehicle_type"
              :disabled="false"
              :warning="selectorWarning"
            ></VehicleTypeSelector>
            <v-divider class="mb-4"></v-divider>
          </div>

          <v-alert color="blue-grey" text icon="mdi-information-outline">{{
            $t("vehicles.vehicles_form_step_2")
          }}</v-alert>
          <v-text-field
            v-model="item.registration_plate"
            autocomplete="off"
            type="text"
            :label="$t('vehicles.license_plate')"
            :placeholder="$t('vehicles.license_plate')"
            :rules="requiredRule"
            @keyup="transfromRegistrationPlate"
            prepend-inner-icon="mdi-card-text"
            counter="25"
            maxlength="25"
            outlined
            class="registration-plate-font"
          ></v-text-field>
          <v-text-field
            v-model="item.brand"
            :label="$t('vehicles.brand')"
            :placeholder="$t('vehicles.brand')"
            prepend-inner-icon="mdi-text"
            :rules="operator.accredited ? [] : requiredRule"
            counter="25"
            maxlength="25"
            outlined
          ></v-text-field>
          <v-text-field
            v-model="item.model"
            :label="$t('vehicles.model')"
            :placeholder="$t('vehicles.model')"
            prepend-inner-icon="mdi-car-info"
            :rules="operator.accredited ? [] : requiredRule"
            counter="25"
            maxlength="25"
            outlined
          ></v-text-field>

          <v-text-field
            counter="25"
            maxlength="25"
            autocomplete="off"
            v-model="item.responsible_name"
            :label="$t('vehicles.responsible_name')"
            :placeholder="$t('vehicles.responsible_name')"
            prepend-inner-icon="mdi-card-account-details"
            outlined
          ></v-text-field>

          <v-text-field
            counter="25"
            maxlength="25"
            v-model="item.phone"
            prepend-inner-icon="mdi-phone"
            :label="$t('vehicles.contact_phone')"
            :rules="phoneNumberRules"
            outlined
          />
          <v-text-field
            v-model="item.seating_capacity"
            type="number"
            :label="$t('vehicles.seating_capacity')"
            :placeholder="$t('vehicles.seating_capacity')"
            :rules="seatingCapacityRules"
            prepend-inner-icon="mdi-car-seat"
            outlined
          ></v-text-field>
          <v-file-input
            v-model="item.vehicle_image"
            prepend-icon=""
            prepend-inner-icon="mdi-image"
            :label="$t('vehicles.image')"
            outlined
            accept="image/png, image/jpeg"
          ></v-file-input>
          <v-fade-transition>
            <div v-show="item.vehicle_type == 'TAXI'">
              <v-text-field
                v-model="item.license_number"
                autocomplete="off"
                type="text"
                :label="$t('vehicles.license_number')"
                :placeholder="$t('vehicles.license_number')"
                prepend-inner-icon="mdi-car-door"
                counter="10"
                maxlength="10"
                :rules="item.vehicle_type == 'TAXI' ? requiredRule : []"
                outlined
                class="registration-plate-font"
              ></v-text-field>
              <v-text-field
                v-model="item.permit_number"
                autocomplete="off"
                type="text"
                :label="$t('vehicles.permit_number')"
                :placeholder="$t('vehicles.permit_number')"
                prepend-inner-icon="mdi-license"
                counter="10"
                maxlength="10"
                :rules="item.vehicle_type == 'TAXI' ? requiredRule : []"
                outlined
              ></v-text-field>
            </div>
          </v-fade-transition>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          large
          color="primary"
          class="black--text"
          depressed
          @click.stop="save"
          :block="$vuetify.breakpoint.mobile"
        >
          <v-icon>mdi-plus</v-icon>{{ $t("global.register") }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog v-model="creatingDialog" persistent width="520">
      <v-card class="fill-height">
        <v-card-text style="min-height: 250px; position: relative">
          <Loading
            v-model="creatingDialog"
            :custom-message="$t('vehicles.creating_your_vehicle_please_wait')"
          ></Loading>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="createdDialog" persistent width="450">
      <v-card>
        <v-card-text>
          <div class="text-center pt-4">
            <v-icon size="100" color="green" left>mdi-check-circle</v-icon>
          </div>
          <p class="text-center text-h6 ma-0 pt-4" style="color: black">
            {{ $t("vehicles.your_vehicle_was_created_successfully") }}
          </p>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="errorDialog" width="450">
      <v-card>
        <v-card-text>
          <div class="text-center pt-4">
            <v-icon size="100" color="red" left>mdi-close-circle</v-icon>
          </div>

          <div
            v-for="(message, index) in messages"
            :key="index"
            class="subtitle text-center"
          >
            <p class="text-center text-h6 ma-0 pt-4" style="color: black">
              {{ message }}
            </p>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            block
            color="primary"
            @click="errorDialog = false"
            class="black--text"
          >
            {{ $t("global.close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-bottom-sheet>
</template>
<script>
const DEFAULT_ITEM = {
  registration_plate: "",
  vehicle_type: "",
  seating_capacity: "",
  brand: "",
  model: "",
  license_number: "",
  permit_number: "",
  vehicle_image: null,
  phone: null,
  responsible_name: "",
};

import { phoneNumberValidation } from "@/helpers/common_validations.js";
import Loading from "@/components/Main/Loading";
import VehicleTypeSelector from "@/components/Main/Vehicles/VehicleTypeSelector";
export default {
  props: ["value"],
  components: { Loading, VehicleTypeSelector },
  computed: {
    operator() {
      return this.$store.state.operators.currentOperator;
    },
    isOpen: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  data() {
    return {
      messages: [],
      item: Object.assign({}, DEFAULT_ITEM),
      selectorWarning: false,
      creatingDialog: false,
      createdDialog: false,
      errorDialog: false,
      requiredRule: [(v) => !!v || this.$t("rules.required_field")],
      phoneNumberRules: [
        phoneNumberValidation,
        (v) => {
          if (!this.operator.accredited && !v) {
            return this.$t("rules.required_field");
          }
          return true;
        },
      ],
      seatingCapacityRules: [
        (v) => {
          if (!this.operator.accredited && !v) {
            return this.$t("rules.required_field");
          }

          if (v && v.toString().trim() !== "") {
            if (/^[0-9]+$/.test(v)) {
              const parsedValue = parseInt(v);
              return parsedValue > 0
                ? true
                : this.$t("rules.invalid_positive_integer");
            } else {
              return this.$t("rules.invalid_positive_integer");
            }
          }
          return true;
        },
      ],
    };
  },
  watch: {
    isOpen(val) {
      if (val) {
        this.$nextTick(() => {
          this.loadVehicleTypes();
          this.item = Object.assign({}, DEFAULT_ITEM);
          this.$refs.form.reset();
        });
      }
    },
  },
  methods: {
    transfromRegistrationPlate() {
      // Remove any character that is not a letter or a number and after that transform to uppercase
      this.item.registration_plate = this.item.registration_plate
        .replace(/[^a-zA-Z0-9]/g, "")
        .toUpperCase();
    },
    loadVehicleTypes() {
      this.$store
        .dispatch("vehicle_types/GET_ALL_WITHOUT_PAGINATED")
        .catch((ex) => {
          console.error("Error loading vehicle types", ex);
        });
    },
    close() {
      this.$emit("closed");
      this.isOpen = false;
    },
    save() {
      this.messages = [];
      this.selectorWarning = false;
      let vehicle = Object.assign({}, this.item);

      if (
        this.$refs.form.validate() &&
        vehicle.vehicle_type != "" &&
        vehicle.vehicle_type != undefined
      ) {
        this.creatingDialog = true;
        this.$store
          .dispatch("user_vehicles/CREATE_USER_VEHICLE", vehicle)
          .then((res) => {
            if (res.errors) {
              Object.entries(res.errors).forEach(([, value]) => {
                this.messages = this.messages.concat(value);
              });
              this.creatingDialog = false;
              this.errorDialog = true;
            } else {
              this.creatingDialog = false;
              this.createdDialog = true;
              setTimeout(() => {
                this.createdDialog = false;
                this.close();
              }, 2000);
            }
          })
          .catch((e) => {
            this.creatingDialog = false;
            this.errorDialog = true;
            console.error(e);
          });
      } else if (
        vehicle.vehicle_type == "" ||
        vehicle.vehicle_type == undefined
      ) {
        this.selectorWarning = true;
      }
    },
  },
};
</script>
<style>
.new-vehicle-dialog {
  height: 90% !important;
}
</style>

<style scoped>
.registration-plate-font >>> .v-input__control input {
  font-family: "Fira Mono", monospace !important;
  font-weight: 300 !important;
}
</style>
