<template>
  <v-sheet style="min-height: 150px">
    <Loading v-model="loading"></Loading>
    <div v-if="!loading" class="fill-height">
      <v-text-field
        :disabled="loading"
        v-model="search"
        clearable
        flat
        solo
        outlined
        hide-details
        prepend-inner-icon="mdi-magnify"
        :label="$t('global.search')"
        class="mb-2 px-1"
      ></v-text-field>

      <div class="text-center no-results" v-if="items.length == 0">
        <v-icon left>mdi-close</v-icon
        >{{ $t("vessel_calls.no_vessel_calls_found") }}
      </div>

      <v-item-group v-else v-model="itemSelected">
        <v-row>
          <v-col
            v-for="item in items"
            :key="item.id"
            cols="12"
            lg="3"
            md="4"
            sm="12"
          >
            <v-item v-slot="{ active, toggle }" v-model="item.id">
              <v-card
                :dark="active"
                :color="active ? 'primary' : ''"
                width="auto"
                @click="toggle"
                class="ma-1"
                flat
                outlined
              >
                <v-toolbar flat>
                  <v-toolbar-title>
                    <span class="font-weight-black text-uppercase">{{
                      item.name
                    }}</span>
                  </v-toolbar-title>
                  <v-spacer />
                  <v-btn icon v-if="active">
                    <v-icon>mdi-check-circle</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-divider />
                <v-card-text class="pa-0">
                  <div class="backgroundIcon">
                    <v-icon color="grey lighten-4" size="80">mdi-ferry</v-icon>
                  </div>
                  <v-list dense>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          class="font-weight-bold text-subtitle-1"
                          >{{
                            item.eta | formatDatetimeWithoutTimezone
                          }}</v-list-item-title
                        >
                        <v-list-item-subtitle class="text-caption"
                          >{{ $t("vessel_calls.expected_arrival") }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          class="font-weight-bold text-subtitle-1"
                          >{{
                            item.etd | formatDatetimeWithoutTimezone
                          }}</v-list-item-title
                        >
                        <v-list-item-subtitle class="text-caption"
                          >{{ $t("vessel_calls.expected_departure") }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="active">
                      <v-list-item-content>
                        <v-select
                          outlined
                          dense
                          :label="$t('entry_requests.request_day')"
                          :items="availableDays"
                          v-model="vesselCallDate"
                          hide-details
                          @click.stop
                        >
                          <template v-slot:item="{ item }">
                            {{ item | formatJSDate }}
                          </template>
                          <template v-slot:selection="{ item }">
                            {{ item | formatJSDate }}
                          </template>
                        </v-select>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-item>
          </v-col>
        </v-row>
      </v-item-group>
    </div>
  </v-sheet>
</template>
<script>
import Loading from "@/components/Main/Loading";
import { Settings, DateTime } from "luxon";
export default {
  components: { Loading },
  props: ["value", "item", "date", "archivedPlanDates"],
  data() {
    return {
      loading: false,
      vesselCalls: [],
      search: "",
    };
  },
  computed: {
    itemSelected: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
        let selectedItem = this.items.find((item) => item.id == val);
        this.$emit("update:item", selectedItem);
      },
    },
    vesselCallDate: {
      get() {
        return this.date;
      },
      set(val) {
        this.$emit("update:date", val);
      },
    },
    items() {
      if (!this.search || this.search === "") return this.vesselCalls;

      // Remove dashes from the search string
      const sanitizedSearch = this.search.replace(/-/g, "");

      // Create a search regex without dashes
      const searchRegex = new RegExp(sanitizedSearch, "i");

      return this.vesselCalls.filter((item) => {
        // Remove dashes from the registration plate before testing against the search regex
        const sanitizedPlate = item.name.replace(/-/g, "");
        return searchRegex.test(sanitizedPlate) || this.itemSelected == item.id;
      });
    },
    availableDays() {
      if (!this.item) return [];

      const start = this.item.eta
        ? DateTime.fromISO(this.item.eta)
        : DateTime.now();
      const end = this.item.eta
        ? DateTime.fromISO(this.item.etd)
        : DateTime.now();

      // Convert archivedPlanDates to an array of DateTime objects
      const archivedDates =
        this.archivedPlanDates && this.archivedPlanDates.length > 0
          ? this.archivedPlanDates.map((dateStr) => DateTime.fromISO(dateStr))
          : [];
      // Calculate the difference in days
      const diffInDays = end
        .startOf("day")
        .diff(start.startOf("day"), "days").days;

      // Generate the array of dates
      return Array.from({ length: diffInDays + 1 }, (_, index) =>
        start.plus({ days: index }).toJSDate()
      ).filter((date) => {
        const dateToCompare = DateTime.fromJSDate(date);
        return (
          !archivedDates.some((archivedDate) =>
            archivedDate.hasSame(dateToCompare, "day")
          ) && dateToCompare >= DateTime.now().startOf("day")
        );
      });
    },
  },
  mounted() {
    this.loadVesselCalls();
  },
  watch: {
    availableDays() {
      if (this.availableDays && this.availableDays.length > 0) {
        this.vesselCallDate = this.availableDays[0];
      }
    },
  },
  methods: {
    loadVesselCalls() {
      this.vesselCalls = [];
      this.loading = true;
      this.$store
        .dispatch("vessel_calls/GET_UPCOMING")
        .then((data) => {
          this.vesselCalls = data;
          this.loading = false;
        })
        .catch((ex) => {
          console.error("Error loading vessel calls", ex);
          this.loading = false;
          this.vesselCalls = [];
        });
    },
  },
};
</script>
<style scoped>
.backgroundIcon {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  position: absolute;
  width: 100%;
  height: 100%;
  margin-top: 20px;
}

.no-results {
  min-height: 150px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
