<template>
  <div>
    <v-btn @click="resetView" fab small :title="$t('global.reset_view')">
      <v-icon>mdi-home</v-icon>
    </v-btn>
  </div>
</template>

<script>
const DEFAULT_PITCH = 0;
import configs from "@/helpers/configs";
export default {
  props: ["map"],
  computed: {
    locode() {
      return configs.getLocode();
    },
  },
  methods: {
    resetView() {
      if (this.map) {
        this.map.setView({
          center: this.locode.coordinates,
          zoom: this.locode.zoom,
          pitch: DEFAULT_PITCH,
          bearing: this.locode.bearing,
        });
      }
    },
  },
};
</script>
