import configs from "@/helpers/configs";
import axios from "axios";
import { sharedHelpers } from "@/api_services/utils/shared-helpers";

export const membersService = {
  getMembers,
  inviteMember,
  removeMember,
};

async function getMembers() {

  const requestOptions = {
    method: "GET",
    headers: sharedHelpers.getRequestHeaders()
  };
  return await axios.get(configs.getApiUrl() + "/v1/my-shorex/team_members", requestOptions);
}

async function inviteMember(member) {

  const requestOptions = {
    method: "POST",
    headers: sharedHelpers.getRequestHeaders()
  };
  return await axios.post(
    configs.getApiUrl() + "/v1/members",
    member,
    requestOptions
  );
}

async function removeMember(id) {
  const requestOptions = {
    method: "POST",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.delete(
    configs.getApiUrl() + "/v1/members/" + id,
    requestOptions
  );
}
