import { countriesService } from "@/api_services/countries.service";

export default {
  namespaced: true,
  state: {
    all: [],
    loading: false,
  },
  getters: {},
  mutations: {
    setCountries(state, items) {
      state.all = items;
    },
    setLoading(state, isLoading) {
      state.loading = isLoading;
    },
  },
  actions: {
    GET_ALL: (context) => {
      return new Promise((resolve, reject) => {
        context.commit("setLoading", true);
        countriesService
          .getCountries()
          .then((res) => {
            context.commit("setCountries", res.data);
            context.commit("setLoading", false);
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            context.commit("setCountries", []);
            context.commit("setLoading", false);
            reject(e);
          });
      });
    },
  },
};
