<template>
  <div>
    <v-data-table
      :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'mdi-page-first',
        lastIcon: 'mdi-page-last',
        'items-per-page-options': [5, 10, 25, 50],
      }"
      :loading="loading"
      :search.sync="options.search"
      :page.sync="options.page"
      :items-per-page.sync="options.itemsPerPage"
      :headers="headers"
      :items="items"
      :server-items-length="totalItemCount"
      @update:options="handleOptionsUpdate('options', $event)"
      item-key="id"
      fixed-header
      :sort-by.sync="options.sortBy"
      :sort-desc.sync="options.sortDesc"
      must-sort
      class="audit-logs-table"
    >
      <template v-slot:top>
        <v-text-field
          v-model="options.search"
          single-line
          :label="$t('audit_logs.search')"
          prepend-inner-icon="mdi-magnify"
          outlined
          @input="filterItems"
        >
        </v-text-field>
      </template>
      <template v-slot:item.name="{ item }">
        <v-chip small label outlined class="text-uppercase">
          {{ $t("audit_log_types." + item.name.toLowerCase()) }}
        </v-chip>
      </template>
      <template v-slot:item.created_at="{ item }">
        {{ item.created_at | formatDatetime }}
      </template>
      <template v-slot:item.data="{ item }">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              v-on="on"
              v-bind="attrs"
              @click="viewAuditableObjectInfo(item)"
            >
              <v-icon>mdi-table</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("global.view") }}</span>
        </v-tooltip>
      </template>
      <template #footer.prepend>
        <v-spacer />
      </template>
    </v-data-table>
    <AuditableObjectInfoDialog
      :open.sync="openAuditableObjectInfoDialog"
      :item="auditableObject"
    />
  </div>
</template>

<script>
import { debounce } from "debounce";
import AuditableObjectInfoDialog from "@/components/Configurations/AuditLogs/AuditableObjectInfoDialog.vue";
export default {
  components: {
    AuditableObjectInfoDialog,
  },
  data() {
    return {
      openAuditableObjectInfoDialog: false,
      auditableObject: null,
      headers: [
        {
          text: this.$t("audit_logs.created_at"),
          value: "created_at",
          class: "font-weight-bold",
          align: "start",
          sortable: true,
        },
        {
          text: this.$t("audit_logs.user_name"),
          value: "user_name",
          class: "font-weight-bold",
          align: "start",
          sortable: true,
        },
        {
          text: this.$t("audit_logs.name"),
          value: "name",
          class: "font-weight-bold",
          align: "start",
          sortable: true,
        },
        {
          text: this.$t("audit_logs.details"),
          value: "data",
          class: "font-weight-bold",
          sortable: false,
          width: "60px",
        },
      ],
    };
  },
  computed: {
    loading() {
      return this.$store.state.audit_logs.loading;
    },
    items() {
      return this.$store.state.audit_logs.all;
    },
    options: {
      cache: false,
      get() {
        return this.$store.state.audit_logs.options;
      },
      set(val) {
        this.$store.dispatch("audit_logs/UPDATE_OPTIONS", val);
      },
    },
    totalItemCount() {
      return this.$store.state.audit_logs.total;
    },
  },
  methods: {
    viewAuditableObjectInfo(item) {
      this.auditableObject = {
        title: this.$t("audit_log_types." + item.name.toLowerCase()),
        data: JSON.parse(item.data),
      };
      this.openAuditableObjectInfoDialog = true;
    },
    loadAuditLogs() {
      this.$store.dispatch("audit_logs/GET_ALL").catch((ex) => {
        console.error("Error loading audit logs", ex);
      });
    },
    handleOptionsUpdate: debounce(function () {
      this.loadAuditLogs();
    }, 500),
    filterItems: debounce(function () {
      // Changes the current page to the first one to reset the search stop bugs from happening
      if (this.options.page == 1) {
        this.loadAuditLogs();
      } else {
        this.options.page = 1;
      }
    }, 500),
    resetFilters() {
      this.options.filterByStatus = null;
      this.options.filterByType = null;
      this.filterItems();
    },
  },
};
</script>
<style>
.audit-logs-table .v-data-table__wrapper {
  max-height: calc(100vh - 290px);
}

.audit-logs-table.v-data-table
  > .v-data-table__wrapper
  tbody
  tr.v-data-table__expanded__content {
  box-shadow: none !important;
  background-color: rgb(245, 242, 223);
}
</style>
