import * as maptalks from "maptalks";
import * as wkt from "wkt";
import Rope from "@/helpers/map/rope";
import * as turf from "@turf/turf";

const MOORING_BITT_IN_USE_COLOR = "#1976d2";
/**
 * MOORING BITT DRAW UTILITIES
 */
export default {
  drawMooringBitt(bitt) {
    var bittMarker = null;
    if (bitt && bitt.MapGeom) {
      let location = wkt.parse(bitt.MapGeom);
      if (location.type == "Point") {
        bittMarker = new maptalks.Marker(location.coordinates, {
          properties: {
            id: bitt.Id,
            shortenedCode: bitt.Code
              ? bitt.Code.slice(bitt.Code.length - 2)
              : "",
            backgroundColor: bitt.Unavailable
              ? "#d32f2f"
              : bitt.InUse
              ? MOORING_BITT_IN_USE_COLOR
              : window.VUE_APP_DEFAULT_BITTS_COLOR,
            borderColor: bitt.Notes ? "#ffff00" : "black",
            textColor: bitt.Notes ? "#ffff00" : "#ffffff",
            code: bitt.Code,
            name: bitt.Name,
            notes: bitt.Notes,
            unavailable: bitt.Unavailable,
            inUse: bitt.InUse,
          },
        });
        bittMarker.updateSymbol(this.getMooringBittStyle());
        if (bitt.Notes) {
          bittMarker.on("dblclick", (e) => {
            if (e && e.domEvent) {
              e.domEvent.preventDefault();
              e.domEvent.stopPropagation();
            }
            if (e && e.target) {
              e.target.openInfoWindow();
            }
          });
          bittMarker.setInfoWindow({
            content:
              '<div class="mooringbitt-notes">' +
              "<small>" +
              bitt.Code +
              "</small>" +
              "<p>" +
              bitt.Notes +
              "</p>" +
              "</div>",
            autoPan: false,
            dy: -10,
            custom: true,
            autoOpenOn: null, //set to null if not to open when clicking on marker
            autoCloseOn: "click",
          });
        }
      }
    }
    return bittMarker;
  },

  drawMooringBitts(map, bitts) {
    var drawnBitts = [];
    if (bitts) {
      bitts.forEach((bitt) => {
        drawnBitts.push(this.drawMooringBitt(map, bitt));
      });
    }
    return drawnBitts;
  },

  getMooringBittStyle() {
    return {
      textFaceName: "Roboto",
      textName: "{shortenedCode}", //value from name in geometry's properties
      textWeight: "bold", //'bold', 'bold'
      textStyle: "normal", //'italic', 'oblique'
      textSize: {
        stops: [
          [5, 0],
          [10, 0],
          [15, 0],
          [16, 7],
          [17, 8],
        ],
      },
      textFill: {
        // use heat value in property
        property: "textColor",
        type: "identity",
      },
      textOpacity: 1,
      textLineSpacing: 0,
      textDx: 0,
      textDy: 0,
      textHorizontalAlignment: "middle", //left | middle | right | auto
      textVerticalAlignment: "middle", // top | middle | bottom | auto
      textAlign: "center", //left | right | center | auto,
      markerType: "ellipse",
      markerWidth: {
        stops: [
          [5, 0],
          [10, 4],
          [15, 5],
          [16, 10],
          [17, 14],
        ],
      },
      markerHeight: {
        stops: [
          [5, 0],
          [10, 4],
          [15, 5],
          [16, 10],
          [17, 14],
        ],
      },
      markerFill: {
        // use heat value in property
        property: "backgroundColor",
        type: "identity",
      },
      markerFillOpacity: 1,
      markerLineColor: {
        // use heat value in property
        property: "borderColor",
        type: "identity",
      },
      markerLineWidth: 1,
    };
  },

  getMooringRopeStyle() {
    return {
      symbol: [
        {
          lineColor: "DarkSlateGrey",
          lineWidth: 2,
          "lineOpacity ": {
            stops: [
              [14, 0],
              [14, 1],
            ],
          },
        },
      ],
    };
  },

  drawBowMooringRope(drawnShip, mooringBitt) {
    let connector = new Rope(drawnShip, mooringBitt, {
      showOn: "always", //'moving', 'click', 'mouseover', 'always'
      arrowPlacement: "point", // 'vertex-last', //vertex-first, vertex-last, vertex-firstlast, point
      symbol: this.getMooringRopeStyle().symbol,
    });
    return connector;
  },

  drawSternMooringRope(drawnShip, mooringBitt) {
    let connector = new Rope(drawnShip, mooringBitt, {
      showOn: "always", //'moving', 'click', 'mouseover', 'always'
      arrowPlacement: "point", // 'vertex-last', //vertex-first, vertex-last, vertex-firstlast, point
      symbol: this.getMooringRopeStyle().symbol,
    });
    return connector;
  },

  calcAngle(startPoint, midPoint, endPoint) {
    let angle = turf.angle(startPoint, midPoint, endPoint);
    return angle > 180 ? 360 - angle : angle;
  },

  drawAngleMooringRope(startPoint, midPoint, endPoint) {
    //calulate angle between 3 points
    let angle = this.calcAngle(startPoint, midPoint, endPoint);

    //calculate azimuth vector1 (startpoint and midpoint)
    var bearing = turf.bearing(startPoint, midPoint);
    let startAngle = turf.bearingToAzimuth(bearing); //devia ser 360 - qq coisa, mas os eixos do maptalks estao rodados 90. logo 270 = 360 - 90

    //calculate azimuth vector2 (midpoint and endpoint)
    var bearing2 = turf.bearing(midPoint, endPoint);
    let endAngle = turf.bearingToAzimuth(bearing2); //devia ser 360 - qq coisa, mas os eixos do maptalks estao rodados 90. logo 270 = 360 - 90

    let startSector = 270 - startAngle;
    let endSector = startSector + angle;

    let diff = (startAngle - endAngle) % 360;
    if ((diff > 0 && diff < 180) || (diff > -360 && diff < -180)) {
      //invert
      startSector = startSector - angle;
      endSector = 270 - startAngle;
    }

    var from = turf.point(startPoint);
    var to = turf.point(midPoint);
    var distance = turf.distance(to, from, { units: "kilometers" });

    var startSectorPoint = turf.along(
      turf.lineString([startPoint, midPoint]),
      distance / 2,
      { units: "kilometers" } //kms
    );

    var endSectorPoint = turf.along(
      turf.lineString([midPoint, endPoint]),
      distance / 2,
      { units: "kilometers" } //kms
    );

    //calculates center between startPointSector, midPoint, and endPointSector
    let labelCoords = turf.center(
      turf.points([
        startSectorPoint.geometry.coordinates,
        midPoint,
        endSectorPoint.geometry.coordinates,
      ])
    ).geometry.coordinates;

    //draw label angle
    let label = new maptalks.Label(angle.toFixed(0) + " °", labelCoords, {
      textSymbol: {
        textFaceName: "monospace",
        textFill: "black",
        textHaloFill: "#fff",
        textHaloRadius: 4,
        textWeight: "bold",
        textSize: {
          stops: [
            [17, 0],
            [18, 10],
            [19, 11],
            [20, 12],
          ],
        },
      },
    });

    let sector = new maptalks.Sector(
      midPoint,
      (distance * 1000) / 3,
      startSector,
      endSector,
      {
        symbol: {
          lineColor: "black",
          lineWidth: 0,
          polygonFill: "black",
          polygonOpacity: 0.1,
        },
      }
    );

    return new maptalks.GeometryCollection([sector, label]);
  },
};
