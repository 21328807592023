<template>
  <div>
    <v-dialog
      v-model="isOpen"
      max-width="800"
      persistent
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar color="primary" flat>
          <v-toolbar-title>
            <span>
              <v-icon left>mdi-file-document-outline</v-icon
              >{{ $t("operator_licenses.list") }}
            </span>
          </v-toolbar-title>
          <v-spacer />
          <v-btn icon @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-data-table
            :loading="loading"
            :headers="headers"
            :items="items"
            item-key="id"
            fixed-header
            must-sort
            hide-default-footer
          >
            <template v-slot:item.updated_at="{ item }">
              {{ item.updated_at | formatDatetime }}
            </template>
            <template v-slot:item.legal_doc_path="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    :disabled="!item.legal_doc_path"
                    class="mx-2"
                    icon
                    small
                    color="primary"
                    @click="downloadFile(item.id)"
                  >
                    <v-progress-circular
                      v-if="
                        downloadingFile !== null && downloadingFile === item.id
                      "
                      indeterminate
                      color="primary"
                    ></v-progress-circular>
                    <v-icon v-else-if="!item.legal_doc_path">mdi-file</v-icon>
                    <v-icon v-else>mdi-file-download-outline</v-icon>
                  </v-btn></template
                >
                <span>{{ $t("global.download") }}</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ["open", "item"],
  data() {
    return {
      downloadingFile: null,
      loading: false,
      headers: [
        {
          text: this.$t("operator_licenses.operator_type_name"),
          value: "name",
          sortable: false,
          width: "200px",
        },
        {
          text: this.$t("operator_licenses.file_number"),
          value: "file_number",
          sortable: false,
        },
        {
          text: this.$t("operator_licenses.file"),
          value: "legal_doc_path",
          sortable: false,
          align: "center",
        },
        {
          text: this.$t("operator_licenses.validation_date"),
          value: "validation_date",
          sortable: false,
        },
        {
          text: this.$t("global.updated_at"),
          value: "updated_at",
          class: "font-weight-bold",
          sortable: false,
          width: "150px",
        },
      ],
    };
  },
  computed: {
    isOpen: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("update:open", val);
      },
    },
    operator() {
      return this.item;
    },
    items() {
      return this.$store.state.operators.operatorLicenses;
    },
  },
  watch: {
    open(val) {
      if (val) {
        this.loadOperatorLicenses();
      }
    },
  },
  methods: {
    loadOperatorLicenses() {
      this.loading = true;
      this.$store
        .dispatch("operators/GET_OPERATOR_LICENSES", this.operator.id)
        .then(() => {
          this.loading = false;
        });
    },

    downloadFile(id) {
      this.downloadingFile = id;
      this.$store
        .dispatch("operators/DOWNLOAD_FILE", id)
        .then(() => {
          this.downloadingFile = null;
        })
        .catch((err) => {
          this.downloadingFile = null;
          this.errorDialog = true;
          console.log(err);
        });
    },

    close() {
      this.isOpen = false;
    },
  },
};
</script>
