export default {
  namespaced: true,
  state: {
    global: {
      darkModeActivated: false,
    },
    map: {
      selectedVehicle: null,
    },
  },
  mutations: {
    setDarkModeActivated(state, activated) {
      state.global.darkModeActivated = activated;
    },
    setMapSelectedVehicle(state, vehicle) {
      state.map.selectedVehicle = vehicle;
    },
  },
  actions: {
    SET_DARK_MODE_ACTIVATED: (context, activated) => {
      context.commit("setDarkModeActivated", activated);
    },

    SET_MAP_SELECTED_VEHICLE: (context, vehicle) => {
      context.commit("setMapSelectedVehicle", vehicle);
    },
  },
};
