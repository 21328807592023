<template>
  <v-app>
    <router-view />
  </v-app>
</template>

<script>
export default {
  name: "App",
};
</script>
<style>
html,
body {
  overflow: hidden !important;
}
.v-data-table-header th {
  white-space: nowrap;
  font-size: 12px !important;
}
@import './assets/styles/variables.css';
</style>
