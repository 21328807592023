<template>
  <v-card class="sector-card" width="100%" outlined>
    <v-toolbar flat color="transparent" dense>
      <v-list-item-avatar
        size="20"
        tile
        :color="item.color"
        style="border: 1px solid black"
        class="mr-2"
      />
      <v-toolbar-title class="font-weight-black">
        {{ item.name }}
      </v-toolbar-title>
    </v-toolbar>
    <v-divider></v-divider>
    <v-card-text>
      <div v-if="item.zones.length > 0">
        <fieldset
          v-for="(zones, parkname) in groupedZones"
          class="pa-1"
          style="border: 1px rgb(211, 211, 211) solid"
          :key="parkname"
        >
          <legend class="px-3 font-weight-bold">{{ parkname }}</legend>
          <div class="ma-1 d-flex flex-wrap justify-left">
            <v-chip
              v-for="(zone, index) in zones.sort((a, b) =>
                a.code.localeCompare(b.code, undefined, {
                  numeric: true,
                })
              )"
              :key="index"
              small
              outlined
              label
              class="font-weight-bold black--text ma-1 text-center text-uppercase"
              >{{ zone.code }}</v-chip
            >
          </div>
        </fieldset>
      </div>
      <div v-else class="text-center">{{ $t("sectors.no_zones_defined") }}</div>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn text small @click.stop="handleEditSector">
        <v-icon small left>mdi-pencil</v-icon>{{ $t("global.edit") }}
      </v-btn>
      <v-btn text small @click.stop="handleChangeZones"
        ><v-icon small left>mdi-texture-box</v-icon
        >{{ $t("sectors.set_zones") }}</v-btn
      >
      <v-btn text color="error" small @click.stop="handleDeleteSector"
        ><v-icon small left>mdi-delete</v-icon>{{ $t("global.delete") }}</v-btn
      >
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  props: ["item", "selected"],
  computed: {
    groupedZones() {
      return this.item.zones.reduce((acc, item) => {
        const park = item.park;
        if (!acc[park]) {
          acc[park] = [item];
        } else {
          acc[park].push(item);
        }
        return acc;
      }, {});
    },
  },
  methods: {
    handleEditSector() {
      this.$emit("edit", this.item);
    },
    handleChangeZones() {
      this.$emit("changezones", this.item);
    },
    handleDeleteSector() {
      this.$emit("delete", this.item);
    },
  },
};
</script>
