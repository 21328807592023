var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading)?_c('Loading',{attrs:{"value":_vm.loading}}):_c('v-slide-group',{class:_vm.disabled ? 'slider-disabled ' : _vm.warning ? 'warning-border' : '',attrs:{"show-arrows":"","center-active":""},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}},_vm._l((_vm.vehicleTypes),function(vehicleType){return _c('v-slide-item',{key:vehicleType.id,attrs:{"value":vehicleType.code},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-card',{class:{
          'disabled-card': _vm.disabled && !active,
          'mx-2 my-2 text-center': true,
          'warning-border': _vm.warning,
        },attrs:{"outlined":"","dark":active,"color":active ? 'primary' : '',"disabled":_vm.disabled,"height":"100","width":"150"},on:{"click":toggle}},[_c('v-container',{attrs:{"fill-height":"","fluid":""}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-avatar',{staticClass:"mt-5",attrs:{"tile":""}},[_c('v-icon',{attrs:{"size":"45"}},[_vm._v(_vm._s(vehicleType.icon))])],1)],1),_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('p',{staticClass:"overline text-uppercase font-weight-black"},[_vm._v(" "+_vm._s(vehicleType.name)+" ")])])],1)],1)]}}],null,true)})}),1),(_vm.warning)?_c('span',{staticClass:"warning-text"},[_vm._v("Campo obrigatório")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }