<template>
  <v-card class="pa-0 ma-0 full-height" outlined tile>
    <v-toolbar color="default" dense flat>
      <v-toolbar-title dense class="font-weight-bold">
        <v-list-item class="ma-0 pa-0">
          <v-list-item-content class="ma-0 pa-0">
            <v-list-item-title class="font-weight-bold text-uppercase">
              {{ $t("global.vehicles_reatime") }} ({{ items.length }})
            </v-list-item-title>
            <v-list-item-subtitle
              class="font-weight-regular text-lowercase"
              style="font-size: 11px"
            >
              {{ $t("global.last_update") }}:
              {{ lastUpdated | formatJSDatetime }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            :color="filtersOpen ? 'primary' : 'default'"
            @click.stop="toggleFilters"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-filter</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("global.filters") }}</span>
      </v-tooltip>
    </v-toolbar>
    <v-divider></v-divider>
    <v-expand-transition>
      <div class="pa-2" v-show="filtersOpen">
        <v-text-field
          v-model="searchTerm"
          :placeholder="$t('vehicles.search_registration_plate')"
          filled
          rounded
          dense
          hide-details
        ></v-text-field>
      </div>
    </v-expand-transition>
    <div
      class="scanned-vehicles-content full-height"
      :class="filtersOpen ? 'with-filters' : ''"
    >
      <v-container v-if="filteredItems.length == 0" fill-height fluid>
        <v-row align="center" justify="center" class="text-h5 text-center">
          <v-icon x-large>mdi-car-off</v-icon>
          <p>{{ $t("global.no_scanned_vehicles_found") }}</p>
        </v-row></v-container
      >
      <v-list-item-group
        v-model="selectedItem"
        active-class="selected"
        class="ma-0 pa-0"
      >
        <v-list-item
          v-for="item in filteredItems"
          :key="item.id"
          :value="item"
          class="ma-0 pa-1"
        >
          <ScannedEntriesCard
            :item="item"
            :class="
              selectedItem && selectedItem.id == item.id
                ? 'vehicle-selected'
                : ''
            "
          ></ScannedEntriesCard></v-list-item
      ></v-list-item-group>
    </div>
  </v-card>
</template>
<script>
import ScannedEntriesCard from "@/components/Main/ScheduledEntryScans/ScannedEntriesCard.vue";
export default {
  components: {
    ScannedEntriesCard,
  },
  data() {
    return {
      searchTerm: "",
      filtersOpen: false,
      lastUpdated: new Date(),
    };
  },
  computed: {
    selectedItem: {
      get() {
        return this.$store.state.ui.map.selectedVehicle;
      },
      set(val) {
        this.$store.dispatch("ui/SET_MAP_SELECTED_VEHICLE", val);
      },
    },
    items() {
      return this.$store.state.scanned_vehicles.all;
    },
    filteredItems() {

      let results = this.items;

      if (this.searchTerm) {
        let searchTerm = this.searchTerm.toLowerCase().trim();

        // Remove "-" characters from the search string
        const cleanedSearchTerm = searchTerm.replace(/-/g, "");

        results = this.items.filter((s) => {
          return (
            s.registration_plate &&
            s.registration_plate
              .replace(/-/g, "")
              .toLowerCase()
              .includes(cleanedSearchTerm)
          );
        });
      }

      results.sort((a, b) => {
        return new Date(b.scanned_at) - new Date(a.scanned_at);
      });

      return results;
    },
  },
  watch: {
    items() {
      this.lastUpdated = new Date();
    },
  },
  methods: {
    toggleFilters() {
      this.filtersOpen = !this.filtersOpen;
    },
  },
};
</script>
<style scoped>
.scanned-vehicles-content {
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 350px;
}
.full-height {
  height: calc(100% - var(--toolbar-height) - 2px);
}
.with-filters {
  height: calc(100% - var(--toolbar-height) - 2px - 60px);
}

.vehicle-selected {
  background-color: #f9f9e7 !important;
}
</style>
