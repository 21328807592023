var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"vehicle-types-table",attrs:{"footer-props":{
      showFirstLastPage: true,
      firstIcon: 'mdi-page-first',
      lastIcon: 'mdi-page-last',
      'items-per-page-options': [5, 10, 25, 50],
    },"loading":_vm.loading,"search":_vm.options.search,"page":_vm.options.page,"items-per-page":_vm.options.itemsPerPage,"headers":_vm.headers,"items":_vm.items,"server-items-length":_vm.totalItemCount,"item-key":"id","fixed-header":"","sort-by":_vm.options.sortBy,"sort-desc":_vm.options.sortDesc,"must-sort":""},on:{"update:search":function($event){return _vm.$set(_vm.options, "search", $event)},"update:page":function($event){return _vm.$set(_vm.options, "page", $event)},"update:itemsPerPage":function($event){return _vm.$set(_vm.options, "itemsPerPage", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.options, "itemsPerPage", $event)},"update:options":function($event){return _vm.handleOptionsUpdate('options', $event)},"update:sortBy":function($event){return _vm.$set(_vm.options, "sortBy", $event)},"update:sort-by":function($event){return _vm.$set(_vm.options, "sortBy", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.options, "sortDesc", $event)},"update:sort-desc":function($event){return _vm.$set(_vm.options, "sortDesc", $event)}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{attrs:{"single-line":"","label":_vm.$t('vehicle_types.search'),"prepend-inner-icon":"mdi-magnify","outlined":""},on:{"input":_vm.filterItems},model:{value:(_vm.options.search),callback:function ($$v) {_vm.$set(_vm.options, "search", $$v)},expression:"options.search"}})]},proxy:true},{key:"item.icon",fn:function(ref){
    var item = ref.item;
return [_c('v-icon',[_vm._v(_vm._s(item.icon))])]}},{key:"item.updated_at",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDatetime")(item.updated_at))+" ")]}},(_vm.hasUserPermissionToViewEditOrManage('VEHICLE_TYPES'))?{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [(_vm.verifyIfCanEditOrDelete(item))?_c('v-menu',{attrs:{"bottom":"","absolute":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{on:{"click":function($event){return _vm.edit(item)}}},[_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("global.edit")))])],1)],1),(_vm.hasUserPermissionToManage('VEHICLE_TYPES'))?_c('v-list-item',{on:{"click":function($event){return _vm.remove(item)}}},[_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',[_vm._v("mdi-delete")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("global.remove")))])],1)],1):_vm._e()],1)],1):_vm._e()]}}:null,{key:"footer.prepend",fn:function(){return [_c('v-spacer')]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }