var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"scrollable":"","transition":"dialog-bottom-transition","width":"70%"},model:{value:(_vm.dialogOpen),callback:function ($$v) {_vm.dialogOpen=$$v},expression:"dialogOpen"}},[_c('v-card',{attrs:{"tile":""}},[_c('v-toolbar',{attrs:{"flat":"","color":"primary"}},[(!!_vm.item)?_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t("passengers.view_list"))+" - "+_vm._s(_vm.item.process_number))]):_vm._e(),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.dialogOpen = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',{staticClass:"pa-5"},[_c('v-data-table',{attrs:{"footer-props":{
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          'items-per-page-options': [10],
          disableItemsPerPage: true,
        },"loading":_vm.loading,"search":_vm.search,"page":_vm.page,"headers":_vm.headers,"items":_vm.items,"server-items-length":_vm.totalItemCount,"items-per-page":_vm.itemsPerPage,"item-key":"code","fixed-header":"","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"must-sort":""},on:{"update:page":function($event){_vm.page=$event},"update:options":function($event){return _vm.handleOptionsUpdate('options', $event)},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{attrs:{"single-line":"","label":_vm.$t('passengers.search'),"prepend-inner-icon":"mdi-magnify","outlined":""},on:{"input":_vm.filterItems},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.date_of_birth",fn:function(ref){
        var item = ref.item;
return [_vm._v(_vm._s(_vm._f("formatDate")(item.date_of_birth))+" ")]}},{key:"item.country_name",fn:function(ref){
        var item = ref.item;
return [_c('country-flag',{attrs:{"country":item.country_code.toLowerCase(),"size":"normal"}}),_c('span',[_vm._v(_vm._s(item.country_name))])]}},{key:"footer.prepend",fn:function(){return [_c('v-spacer')]},proxy:true}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }