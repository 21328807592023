<template>
  <div class="map-container">
    <div ref="map" class="map" />
    <div ref="mapPrintable" class="mapPrintable" />
    <Loading v-if="loading" :value="loading" :custom-message="$t('zones.loading_zones_please_wait')"></Loading>
    <SectorZoneChangesConfirmation :sector="sector" :mode.sync="mapMode" :plan="plan" :zones="selectedSectorZones">
    </SectorZoneChangesConfirmation>
    <ZoneDetails :zone.sync="selectedZone" :entries="selectedZoneEntries" :entry.sync="selectedEntry"></ZoneDetails>
    <SectorsLegend @zoomOnSector="handleZoomOnSector"></SectorsLegend>
    <VesselCallsMap :active.sync="shipsActive" :map.sync="map" :plan="plan"></VesselCallsMap>
    <div style="position: absolute; top: 20px; left: 20px" v-if="!$vuetify.breakpoint.xs">
      <div class="mb-2">
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn fab small @click.stop="goToHome()" v-bind="attrs" v-on="on">
              <v-icon>mdi-home</v-icon>
            </v-btn>
          </template>
          <span>{{ this.$t("global.home") }}</span>
        </v-tooltip>
      </div>
      <div class="mb-2">
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn fab small @click.stop="toggleShips" v-bind="attrs" v-on="on"
              :color="shipsActive ? 'primary' : 'default'">
              <v-icon>mdi-ferry</v-icon>
            </v-btn>
          </template>
          <span>{{
      shipsActive
        ? this.$t("plans.untoggle_ships")
        : this.$t("plans.toggle_ships")
    }}</span>
        </v-tooltip>
      </div>
      <div class="mb-2">
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-if="hasUserPermissionToViewEditOrManage('PLANS')" fab small @click.stop="toggleSectors"
              v-bind="attrs" v-on="on" :color="sectorsActive ? 'primary' : 'default'"
              :disabled="plan && plan.status === 'ARCHIVED'">
              <v-icon>mdi-texture-box</v-icon>
            </v-btn>
          </template>
          <span>{{ this.$t("plans.config_sectors") }}</span>
        </v-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import "maptalks/dist/maptalks.css";
import * as maptalks from "maptalks";
import configs from "@/helpers/configs";
import Zone from "@/helpers/map/zone";
import Sector from "@/helpers/map/sector";
import Loading from "@/components/Main/Loading";
import ZoneDetails from "@/components/Main/Scheduling/ZoneDetails";
import SectorsLegend from "@/components/Main/Scheduling/SectorsLegend";
import SectorZoneChangesConfirmation from "@/components/Main/Scheduling/SectorZoneChangesConfirmation";
import VesselCallsMap from "@/components/Main/Scheduling/VesselCallsMap";
import html2canvas from "html2canvas";

const ZONES_LAYERNAME = "zones";
const SECTORS_LAYERNAME = "sectors";
const MAP_MODES = {
  VIEW_PLAN: "VIEW_PLAN",
  CHANGE_SECTOR: "CHANGE_SECTOR",
};
const FIT_BOUNDS_PADDING = 10;

export default {
  props: ["zone", "plan", "sector", "mode", "entry"],
  components: {
    Loading,
    ZoneDetails,
    SectorZoneChangesConfirmation,
    SectorsLegend,
    VesselCallsMap,
  },
  data() {
    return {
      map: null,
      mapPrintable: null,
      drawnZones: [],
      drawnSectors: [],
      drawnPrintableZones: [],
      drawnPrintableSectors: [],
      selectedPrintableSector: null,
      shipsActive: true,
    };
  },
  computed: {
    loading() {
      return this.zonesLoading;
    },
    zonesLoading() {
      return this.$store.state.zones.loading;
    },
    mapMode: {
      get() {
        return this.mode;
      },
      set(val) {
        this.$emit("update:mode", val);
      },
    },
    sectors() {
      return this.$store.state.sectors.all;
    },

    zones() {
      return this.$store.state.zones.all.filter((zone) => {
        return !zone.unavailable;
      });
    },

    entries() {
      return this.$store.state.shorex.scheduling;
    },

    selectedZone: {
      get() {
        return this.zone;
      },
      set(val) {
        this.$emit("update:zone", val);
      },
    },

    selectedEntry: {
      get() {
        return this.entry;
      },
      set(val) {
        this.$emit("update:entry", val);
      },
    },

    selectedSector: {
      get() {
        return this.sector;
      },
      set(val) {
        this.$emit("update:sector", val);
      },
    },

    selectedZoneEntries() {
      return this.entries.filter((entry) => {
        return this.selectedZone && entry.zone_id === this.selectedZone.id;
      });
    },

    selectedSectorZones() {
      if (this.selectedSector && this.mapMode == MAP_MODES.CHANGE_SECTOR) {
        return this.drawnZones
          .filter((drawnZone) => {
            return (
              drawnZone.sector && drawnZone.sector.id == this.selectedSector.id
            );
          })
          .map((drawnZone) => {
            return drawnZone.properties.id;
          });
      } else {
        return [];
      }
    },

    sectorsActive() {
      return this.$store.state.shorex.sectorsActive;
    },
  },
  watch: {
    zones() {
      //generic map
      this.processZones();
      this.processSectors();
      this.paintOccupiedZones();

      //printable map
      this.processPrintableZones();
      this.processPrintableSectors();
    },
    sectors() {
      //generic map
      this.processSectors();
      this.paintOccupiedZones();

      //printable map
      this.processPrintableSectors();
    },
    entries: {
      handler() {
        this.paintOccupiedZones();
      },
      deep: true,
    },
    selectedSector(val) {
      if (val) {
        this.fitMapToSectorBounds();
      }
    },
    selectedPrintableSector(val) {
      if (val) {
        this.fitMapToSectorBounds();
      }
    },
    selectedZone(zone) {
      this.drawnZones.forEach((drawnZone) => {
        if (!zone || drawnZone.properties.id != zone.id)
          drawnZone.deHighlight();

        if (zone && drawnZone.properties.id == zone.id) drawnZone.highlight();
      });
    },
    selectedEntry(entry) {
      this.drawnZones.forEach((drawnZone) => {
        drawnZone.deHighlight();
      });

      let zones = [];

      if (entry) {
        if (entry.zone_id) {
          zones = [{ id: entry.zone_id }];
        } else if (entry.sector_id) {
          let sectors = this.sectors.find((sector) => {
            return sector.id == entry.sector_id;
          });
          if (sectors) zones = sectors.zones;
        }
      }

      var extent = new maptalks.Extent();
      if (zones) {
        zones.forEach((zone) => {
          let dz = this.drawnZones.find((dz) => {
            return dz.properties.id == zone.id;
          });
          dz.highlight();
          if (dz) {
            extent = extent.combine(dz.zoneSkeleton.getExtent());
          }
        });
      }
      if (extent && extent.isValid()) {
        this.map.fitExtent(extent, -1, {
          animation: false,
          paddingLeft: FIT_BOUNDS_PADDING,
          paddingRight: FIT_BOUNDS_PADDING,
          paddingTop: FIT_BOUNDS_PADDING,
          paddingBottom: FIT_BOUNDS_PADDING,
        });
      }
    },
  },
  created() {
    this.$root.$on("captureAndEmit", this.handleCaptureAndEmit);
  },
  beforeDestroy() {
    this.map.off("click", this.handleMapClick);
    this.$root.$off("captureAndEmit", this.handleCaptureAndEmit);
  },
  mounted() {
    this.loadZonesAndSectors();
    this.initMap();
  },

  methods: {
    initMap() {
      let locode = configs.getLocode();
      let center = locode.coordinates;
      let bearing = locode.bearing;
      let zoom = locode.zoom;

      this.map = new maptalks.Map(this.$refs.map, {
        center: center,
        zoom: zoom,
        bearing: bearing,
        hitDetect: false, // whether to enable hit detecting of layers for cursor style on this map, disable it to improve performance
        layerCanvasLimitOnInteracting: -1, // -1 to display all layers when interacting
        zoomControl: false, // add zoom control
        scaleControl: false, // add scale control
        attribution: false,
        baseLayer: new maptalks.TileLayer("baselayer", {
          visible: true,
          urlTemplate: configs.getUrlRaster(),
          subdomains: ["a", "b", "c", "d"],
          attribution: "OSM CARTO",
        }),
        layers: [
          new maptalks.VectorLayer(SECTORS_LAYERNAME, []),
          new maptalks.VectorLayer(ZONES_LAYERNAME, []),
        ],
      });

      this.map.on("click", this.handleMapClick);

      this.mapPrintable = new maptalks.Map(this.$refs.mapPrintable, {
        center: center,
        zoom: zoom,
        bearing: bearing,
        hitDetect: false, // whether to enable hit detecting of layers for cursor style on this map, disable it to improve performance
        layerCanvasLimitOnInteracting: -1, // -1 to display all layers when interacting
        zoomControl: false, // add zoom control
        scaleControl: false, // add scale control
        attribution: false,
        baseLayer: new maptalks.TileLayer("baselayer", {
          visible: true,
          urlTemplate: configs.getUrlRaster(),
          subdomains: ["a", "b", "c", "d"],
          attribution: "OSM CARTO",
        }),
        layers: [
          new maptalks.VectorLayer(SECTORS_LAYERNAME, []),
          new maptalks.VectorLayer(ZONES_LAYERNAME, []),
        ],
      });
    },

    goToHome() {
      this.map.setView({
        center: configs.getLocode().coordinates,
        zoom: 16,
        pitch: 0,
        bearing: 0,
      });
    },

    toggleScheduling() {
      this.$store.dispatch("shorex/TOGGLE_SCHEDULING");
    },

    toggleSectors() {
      this.$store.dispatch("shorex/TOGGLE_SECTORS");
    },

    toggleShips() {
      this.shipsActive = !this.shipsActive;
    },

    processZones() {
      if (this.map) {
        let layer = this.map.getLayer(ZONES_LAYERNAME);
        layer.clear();
        this.drawnZones = [];
        if (this.zones) {
          this.zones.forEach((zoneSpec) => {
            let zoneToAdd = new Zone(zoneSpec.geojson, {
              id: zoneSpec.id,
              code: zoneSpec.code,
              park: zoneSpec.park,
            });
            zoneToAdd.addTo(layer);
            zoneToAdd.on("click", this.handleZoneClicked);
            this.drawnZones.push(zoneToAdd);
          });
        }
        this.map.fitExtent(layer.getExtent(), 0, {
          paddingLeft: FIT_BOUNDS_PADDING,
          paddingRight: FIT_BOUNDS_PADDING,
          paddingTop: FIT_BOUNDS_PADDING,
          paddingBottom: FIT_BOUNDS_PADDING,
        });
      }
    },

    processPrintableZones() {
      if (this.mapPrintable) {
        let layer = this.mapPrintable.getLayer(ZONES_LAYERNAME);
        layer.clear();
        this.drawnPrintableZones = [];
        if (this.zones) {
          this.zones.forEach((zoneSpec) => {
            let zoneToAdd = new Zone(zoneSpec.geojson, {
              id: zoneSpec.id,
              code: zoneSpec.code,
              park: zoneSpec.park,
            });
            zoneToAdd.addTo(layer);
            this.drawnPrintableZones.push(zoneToAdd);
          });
        }
        this.mapPrintable.fitExtent(layer.getExtent(), -1);
      }
    },

    handleZoneClicked(e) {
      if (!e || !e.target) {
        return;
      }
      if (e.domEvent) {
        e.domEvent.preventDefault();
        e.domEvent.stopPropagation();
      }
      let zone = e.target;
      if (this.mapMode == MAP_MODES.VIEW_PLAN) {
        this.selectedZone = this.zones.find((z) => {
          return z.id == zone.properties.id;
        });
      } else if (
        this.mapMode == MAP_MODES.CHANGE_SECTOR &&
        this.selectedSector
      ) {
        zone.toggleSector(this.selectedSector);
      }
    },

    processSectors() {
      if (this.map) {
        this.drawnZones.forEach((dz) => {
          dz.removeSectorColor();
        });
        let layer = this.map.getLayer(SECTORS_LAYERNAME);
        layer.clear();
        this.drawnSectors = [];
        this.sectors.forEach((sectorSpec) => {
          let sectorToAdd = new Sector(sectorSpec, {
            color: sectorSpec.color,
            name: sectorSpec.name,
            id: sectorSpec.id,
          });
          sectorToAdd.addTo(layer);
          this.drawnSectors.push(sectorToAdd);
          sectorSpec.zones.forEach((zone) => {
            let dz = this.drawnZones.find((dz) => {
              return dz.properties.id == zone.id;
            });
            if (dz) {
              dz.setSector(sectorSpec);
            }
          });
        });
      }
    },

    processPrintableSectors() {
      if (this.mapPrintable) {
        this.drawnPrintableZones.forEach((dz) => {
          dz.removeSectorColor();
        });

        let layerPrintable = this.mapPrintable.getLayer(SECTORS_LAYERNAME);
        layerPrintable.clear();

        this.drawnPrintableSectors = [];
        this.sectors.forEach((sectorSpec) => {
          let sectorToAdd = new Sector(sectorSpec, {
            color: sectorSpec.color,
            name: sectorSpec.name,
            id: sectorSpec.id,
          });
          sectorToAdd.addTo(layerPrintable);
          this.drawnPrintableSectors.push(sectorToAdd);

          sectorSpec.zones.forEach((zone) => {
            let dz = this.drawnZones.find((dz) => {
              return dz.properties.id == zone.id;
            });
            if (dz) {
              dz.setSector(sectorSpec);
            }
          });
        });
      }
    },

    paintOccupiedZones() {
      // Clear existing occupied state zones
      this.drawnZones.forEach((drawnZone) => {
        drawnZone.setOccupied(false);
      });
      if (this.entries) {
        this.entries.forEach((entry) => {
          if (entry.zone_id) {
            // Find the drawn zone, and clear place it as occupied
            let drawnZone = this.drawnZones.find((z) => {
              return z.properties.id == entry.zone_id;
            });
            if (drawnZone) {
              drawnZone.setOccupied(true);
            }
          }
        });
      }
    },

    handleMapClick() {
      this.selectedZone = null;
      this.selectedEntry = null;

      this.drawnZones.forEach((drawnZone) => {
        drawnZone.deHighlight();
      });
    },

    loadZonesAndSectors() {
      this.loadZones().then(() => {
        this.loadSectors();
      });
    },

    loadZones() {
      return this.$store.dispatch("zones/GET_ALL");
    },

    loadSectors() {
      return this.$store.dispatch("sectors/GET_ALL", this.plan.id);
    },

    fitMapToSectorBounds() {
      if (this.selectedSector) {
        var extent = new maptalks.Extent();
        let zones = this.selectedSector.zones;
        if (zones) {
          zones.forEach((zone) => {
            let dz = this.drawnZones.find((dz) => {
              return dz.properties.id == zone.id;
            });
            if (dz) {
              extent = extent.combine(dz.zoneSkeleton.getExtent());
            }
          });
        }
        if (extent && extent.isValid()) {
          this.map.fitExtent(extent, -1, {
            animation: false,
            paddingLeft: FIT_BOUNDS_PADDING,
            paddingRight: FIT_BOUNDS_PADDING,
            paddingTop: FIT_BOUNDS_PADDING,
            paddingBottom: FIT_BOUNDS_PADDING,
          });
        }
      }
    },

    paintPrintableZones(zones) {
      let zonesIds = zones.map((z) => z.id);
      this.drawnPrintableZones.forEach((drawnZone) => {
        drawnZone.deHighlight();
      });

      this.drawnPrintableZones.forEach((drawnZone) => {
        if (zonesIds.includes(drawnZone.properties.id)) drawnZone.highlight();
      });
    },

    fitPrintableMapToSectorBounds() {
      if (this.selectedPrintableSector) {
        var extent = new maptalks.Extent();
        let zones = this.selectedPrintableSector.zones;
        if (zones) {
          zones.forEach((zone) => {
            let dz = this.drawnPrintableZones.find((dz) => {
              return dz.properties.id == zone.id;
            });

            if (dz) {
              extent = extent.combine(dz.zoneSkeleton.getExtent());
            }
          });
        }
        if (extent && extent.isValid()) {
          this.mapPrintable.fitExtent(extent, -1);
        }
      }
    },

    findEntrySectorAndZone(entry) {
      if (entry.zone_id) {
        let zone = this.zones.find((zone) => {
          return zone.id == entry.zone_id;
        });

        if (zone) {
          this.selectedPrintableSector = { zones: [zone] };
        }
      } else if (entry.sector_id) {
        let sector = this.sectors.find((sector) => {
          return sector.id == entry.sector_id;
        });

        if (sector) {
          this.selectedPrintableSector = sector;
        }
      }

      this.paintPrintableZones(this.selectedPrintableSector.zones);
      this.fitPrintableMapToSectorBounds();
    },

    async captureMap() {
      let mapContainer = this.$refs.mapPrintable;
      // Calculate the size for the square canvas
      let size = Math.min(mapContainer.clientWidth, mapContainer.clientHeight);

      // Calculate the position where the square should start to center it
      let xOffset = (mapContainer.clientWidth - size) / 2;
      let yOffset = (mapContainer.clientHeight - size) / 2;

      // Capture the map container using html2canvas with a square canvas size and offset
      let canvas = await html2canvas(mapContainer, {
        width: size,
        height: size,
        x: xOffset,
        y: yOffset,
      });

      // Convert the canvas to base64
      return canvas ? canvas.toDataURL("image/png", 1.0) : null;
    },

    handleCaptureAndEmit({ entry, day }) {
      this.findEntrySectorAndZone(entry);

      setTimeout(async () => {
        let base64Image = await this.captureMap();

        this.$store
          .dispatch("shorex/EMIT_AUTHORIZATION", {
            day: day,
            entry: entry,
            image: base64Image,
          })
          .then((data) => {
            entry.status = data.status;
            this.$root.$emit("captureAndEmitFinished", entry);
          })
          .catch(() => {
            this.$root.$emit("captureAndEmitFinished", entry);
          });
      }, 1000);
    },

    handleZoomOnSector(sector) {
      this.selectedSector = sector;
      this.fitMapToSectorBounds();
    },
  },
};
</script>

<style>
.map,
.map-container {
  width: 100%;
  height: 100%;
}

.map-container {
  position: relative;
}

.mapPrintable {
  width: 500px;
  height: 500px;
}
</style>
