import locodes from "@/locodes.json";
import store from "@/store/index";
import i18n from "@/locale";

const SHIP_TYPES = [
  {
    id: 1,
    code: [20, 21, 22, 23, 24, 25, 26, 27, 28, 29],
    color: "#C03638",
    name: "Hidroavião",
  },
  {
    id: 2,
    code: [30],
    color: "#FFC11F",
    name: "Pesca",
  },
  {
    id: 3,
    code: [31, 52],
    color: "#9D6666",
    name: "Rebocador",
  },
  {
    id: 4,
    code: [32, 33],
    color: "#9D7050",
    name: "Dragas",
  },
  {
    id: 5,
    code: [34],
    color: "#9D7050",
    name: "Mergulho",
  },
  {
    id: 6,
    code: [35],
    color: "#F34648",
    name: "Militar",
  },
  {
    id: 7,
    code: [36],
    color: "#66C547",
    name: "Veleiro",
  },
  {
    id: 8,
    code: [37],
    color: "#66C547",
    name: "Recreio",
  },
  {
    id: 9,
    code: [38, 39],
    color: "#5EC8BD",
    name: "Reservado",
  },
  {
    id: 10,
    code: [40, 41, 42, 43, 44, 45, 46, 47, 48, 49],
    color: "#C259B5",
    name: "Lancha",
  },
  {
    id: 11,
    code: [50],
    color: "#8C4EB8",
    name: "Piloto",
  },
  {
    id: 12,
    code: [51, 53],
    color: "#F34648",
    name: "Salva-vidas",
  },
  {
    id: 13,
    code: [54],
    color: "#F34648",
    name: "Proteção ambiental",
  },
  {
    id: 14,
    code: [55],
    color: "#F34648",
    name: "Forças de segurança",
  },
  {
    id: 15,
    code: [56, 57],
    color: "#A8A8A8",
    name: "Substituição",
  },
  {
    id: 16,
    code: [58],
    color: "#F34648",
    name: "Transporte médico",
  },
  {
    id: 17,
    code: [60, 61, 62, 63, 64, 65, 66, 67, 68, 69],
    color: "#FF8A22",
    name: "Passageiro",
  },
  {
    id: 18,
    code: [70, 71, 72, 73, 74, 75, 76, 77, 78, 79],
    color: "#3875D7",
    name: "Mercadorias",
  },
  {
    id: 19,
    code: [80, 81, 82, 83, 84, 85, 86, 87, 88, 89],
    color: "#444444",
    name: "Petroleiro",
  },
  {
    id: 20,
    code: [0, 59, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99],
    color: "#A8A8A8",
    name: "Outro",
  },
];


const DEFAULT_PERMISSIONS = [
  {
    code: "MAP",
    profile: "NO-ACCESS",
    type: "SHOREX",
    icon: "mdi-map"
  },
  {
    code: "PLANS",
    profile: "NO-ACCESS",
    type: "SHOREX",
    icon: "mdi-calendar-month-outline"
  },
  {
    code: "SCHEDULED_ENTRIES",
    profile: "NO-ACCESS",
    type: "SHOREX",
    icon: "mdi-table-edit"
  },
  {
    code: "SCHEDULED_ENTRY_SCANS",
    profile: "NO-ACCESS",
    type: "SHOREX",
    icon: "mdi-clipboard-check-multiple-outline"
  },
  {
    code: "NOSHOREX_ENTRIES",
    profile: "NO-ACCESS",
    type: "SHOREX",
    icon: "mdi-boom-gate-arrow-up"
  },
  {
    code: "OPERATORS",
    profile: "NO-ACCESS",
    type: "SHOREX",
    icon: "mdi-store"
  },
  {
    code: "REGISTERED_USERS",
    profile: "NO-ACCESS",
    type: "SHOREX",
    icon: "mdi-account-box"
  },
  {
    code: "VEHICLES",
    profile: "NO-ACCESS",
    type: "SHOREX",
    icon: "mdi-car-multiple"
  },
  {
    code: "STATS",
    profile: "NO-ACCESS",
    type: "ADMIN",
    icon: "mdi-chart-box-outline"
  },
  {
    code: "VESSEL_CALLS",
    profile: "NO-ACCESS",
    type: "ADMIN",
    icon: "mdi-ferry"
  },
  {
    code: "OPERATOR_TYPES",
    profile: "NO-ACCESS",
    type: "ADMIN",
    icon: "mdi-train-car"
  },
  {
    code: "USERS",
    profile: "NO-ACCESS",
    type: "ADMIN",
    icon: "mdi-account-group"
  },
  {
    code: "ZONES",
    profile: "NO-ACCESS",
    type: "ADMIN",
    icon: "mdi-texture-box"
  },
  {
    code: "VEHICLE_TYPES",
    profile: "NO-ACCESS",
    type: "ADMIN",
    icon: "mdi-car-door"
  },
  {
    code: "AUDIT_LOGS",
    profile: "NO-ACCESS",
    type: "ADMIN",
    icon: "mdi-server-security"
  },
];

export default {
  getMeterProjection() {
    return store.state.user.account.locode &&
      store.state.user.account.locode.meter_projection
      ? store.state.user.account.locode.meter_projection
      : `+proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +wktext  +no_defs`;
  },

  getDefaultPermissions()
  {
    return DEFAULT_PERMISSIONS;
  },

  getRegisteredUsersStatuses() {
    return [
      {
        code: "REGISTERED",
        color: "blue-grey lighten-2",
        disabled: false,
      },
      {
        code: "VERIFIED",
        color: "green",
      },
      {
        code: "BLOCKED",
        color: "red",
      },
    ];
  },
  getVehicleStatuses() {
    return [
      {
        code: "PENDING",
        color: "blue-grey lighten-2",
        disabled: false,
      },
      {
        code: "APPROVED",
        color: "green",
      },
      {
        code: "BLOCKED",
        color: "red",
      },
      {
        code: "CANCELED",
        color: "warning",
      },
    ];
  },
  getOperatorStatuses() {
    return [
      {
        code: "PENDING",
        color: "blue-grey lighten-2",
        displayText: i18n.t("operators_states.pending"),
        disabled: false,
      },
      {
        code: "APPROVED",
        color: "green",
        displayText: i18n.t("operators_states.approved"),
      },
      {
        code: "REJECTED",
        color: "red",
        displayText: i18n.t("operators_states.rejected"),
      },
      {
        code: "BLOCKED",
        color: "red",
        displayText: i18n.t("operators_states.blocked"),
      },
    ];
  },
  getEntryRequestStatuses() {
    return [
      {
        code: "PENDING",
        color: "blue-grey lighten-2",
        disabled: true,
      },
      {
        code: "APPROVED",
        color: "green",
      },
      {
        code: "REJECTED",
        color: "red",
      },
      {
        code: "CANCELED",
        color: "warning",
      },
    ];
  },

  getAdminUserType() {
    return "ROOT";
  },

  getManagerUserType() {
    return "USER";
  },

  getShorexUserType() {
    return "SHOREX";
  },

  getLocode() {
    return store.state.user.account.locode;
  },

  getLocale() {
    return store.state.user.account.locode
      ? store.state.user.account.locode.locale
      : "pt-PT";
  },

  getTimezone() {
    return store.state.user.account.locode
      ? store.state.user.account.locode.timezone
      : "Europe/London";
  },

  getLocodes() {
    let vue_app_locodes = window.VUE_APP_LOCODES
      ? window.VUE_APP_LOCODES.split(",")
      : [];
    if (vue_app_locodes.length > 0) {
      return locodes.filter((locode) => {
        return vue_app_locodes.indexOf(locode.code) !== -1;
      });
    } else return locodes;
  },

  getUrlRaster() {
    let url = "https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png";

    if (window.VUE_APP_DEFAULT_URL_RASTER)
      url = window.VUE_APP_DEFAULT_URL_RASTER;

    return url;
  },

  getApiUrl() {
    return process.env.VUE_APP_API_URL;
  },

  getApiImagesUrl() {
    return process.env.VUE_APP_API_IMAGES_URL;
  },

  getRealtimeUrl() {
    return process.env.VUE_APP_REALTIME_URL;
  },

  getCenter() {
    let coords = [0, 0];
    let locode = this.getLocode();
    if (window.VUE_APP_DEFAULT_LOCODE && locode) coords = locode.coordinates;
    if (window.VUE_APP_DEFAULT_LONGITUDE && window.VUE_APP_DEFAULT_LATITUDE)
      coords = [
        window.VUE_APP_DEFAULT_LONGITUDE,
        window.VUE_APP_DEFAULT_LATITUDE,
      ];
    return coords;
  },

  getShipType(code) {
    for (let i = 0; i < SHIP_TYPES.length; i++) {
      var shipClassification = SHIP_TYPES[i];
      if (shipClassification) {
        var j = 0;
        for (j = 0; j < shipClassification.code.length; j++) {
          var shipClassifCode = shipClassification.code[j];
          if (shipClassifCode == code) {
            return shipClassification;
          }
        }
      }
    }

    return SHIP_TYPES[SHIP_TYPES.length - 1];
  },

 getStatusColor(status) {
    switch (status) {
      case "PENDING":
        return "blue-grey lighten-2";
      case "APPROVED":
        return "green";
      case "REJECTED":
        return "red";
      case "CANCELED":
        return "warning";
      default:
        return "grey";
    }
  },
  
  getStatusLabel(status) {
    switch (status) {
      case "PENDING":
        return "PENDENTE";
      case "APPROVED":
        return "APROVADO";
      case "REJECTED":
        return "RECUSADO";
      case "CANCELED":
        return "CANCELADO";
      default:
        return "";
    }
  }
};
