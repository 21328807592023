var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"audit-logs-table",attrs:{"footer-props":{
      showFirstLastPage: true,
      firstIcon: 'mdi-page-first',
      lastIcon: 'mdi-page-last',
      'items-per-page-options': [5, 10, 25, 50],
    },"loading":_vm.loading,"search":_vm.options.search,"page":_vm.options.page,"items-per-page":_vm.options.itemsPerPage,"headers":_vm.headers,"items":_vm.items,"server-items-length":_vm.totalItemCount,"item-key":"id","fixed-header":"","sort-by":_vm.options.sortBy,"sort-desc":_vm.options.sortDesc,"must-sort":""},on:{"update:search":function($event){return _vm.$set(_vm.options, "search", $event)},"update:page":function($event){return _vm.$set(_vm.options, "page", $event)},"update:itemsPerPage":function($event){return _vm.$set(_vm.options, "itemsPerPage", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.options, "itemsPerPage", $event)},"update:options":function($event){return _vm.handleOptionsUpdate('options', $event)},"update:sortBy":function($event){return _vm.$set(_vm.options, "sortBy", $event)},"update:sort-by":function($event){return _vm.$set(_vm.options, "sortBy", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.options, "sortDesc", $event)},"update:sort-desc":function($event){return _vm.$set(_vm.options, "sortDesc", $event)}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{attrs:{"single-line":"","label":_vm.$t('audit_logs.search'),"prepend-inner-icon":"mdi-magnify","outlined":""},on:{"input":_vm.filterItems},model:{value:(_vm.options.search),callback:function ($$v) {_vm.$set(_vm.options, "search", $$v)},expression:"options.search"}})]},proxy:true},{key:"item.name",fn:function(ref){
    var item = ref.item;
return [_c('v-chip',{staticClass:"text-uppercase",attrs:{"small":"","label":"","outlined":""}},[_vm._v(" "+_vm._s(_vm.$t("audit_log_types." + item.name.toLowerCase()))+" ")])]}},{key:"item.created_at",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDatetime")(item.created_at))+" ")]}},{key:"item.data",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":""},on:{"click":function($event){return _vm.viewAuditableObjectInfo(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-table")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("global.view")))])])]}},{key:"footer.prepend",fn:function(){return [_c('v-spacer')]},proxy:true}])}),_c('AuditableObjectInfoDialog',{attrs:{"open":_vm.openAuditableObjectInfoDialog,"item":_vm.auditableObject},on:{"update:open":function($event){_vm.openAuditableObjectInfoDialog=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }