import configs from "@/helpers/configs";
import axios from "axios";
import { sharedHelpers } from "@/api_services/utils/shared-helpers";

export const operatorTypeService = {
  getOperatorTypes,
  getOperatorTypesPaginated,
  createOperatorType,
  updateOperatorType,
  deleteOperatorType,
  getPublicOperatorTypes,
};

async function getOperatorTypes() {
  const requestOptions = {
    method: "GET",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.get(
    configs.getApiUrl() + "/v1/operator_types",
    requestOptions
  );
}

async function getPublicOperatorTypes(locode) {
  const requestOptions = {
    method: "GET",
    headers: sharedHelpers.getPubliRequestHeaders(),
  };

  if (locode) requestOptions.headers["X-PortLocode"] = locode;

  return await axios.get(
    configs.getApiUrl() + `/public/operator_types`,
    requestOptions
  );
}

async function getOperatorTypesPaginated(options) {
  let sort = options.sortDesc ? "DESC" : "ASC";
  const requestOptions = {
    method: "GET",
    headers: sharedHelpers.getRequestHeaders(),
    params: {
      itemsPerPage: options.itemsPerPage,
      currentPage: options.page,
      sortBy: options.sortBy,
      sort: sort,
      search: options.search,
    },
  };
  return await axios.get(
    configs.getApiUrl() + "/v1/operator_types/paginated",
    requestOptions
  );
}

async function createOperatorType(operatorType) {
  const requestOptions = {
    method: "POST",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.post(
    configs.getApiUrl() + "/v1/operator_types",
    operatorType,
    requestOptions
  );
}

async function updateOperatorType(operatorType) {
  const requestOptions = {
    method: "PUT",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.put(
    configs.getApiUrl() + "/v1/operator_types/" + operatorType.id,
    operatorType,
    requestOptions
  );
}

async function deleteOperatorType(operatorTypeId) {
  const requestOptions = {
    method: "POST",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.delete(
    configs.getApiUrl() + "/v1/operator_types/" + operatorTypeId,
    requestOptions
  );
}
