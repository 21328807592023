import configs from "@/helpers/configs";
import axios from "axios";
import { sharedHelpers } from "@/api_services/utils/shared-helpers";

export const noShorexEntriesService = {
  getNoShorexEntries,
  getNoShorexEntriesPaginated,
  createNoShorexEntry,
  deleteNoShorexEntry,
  importNoShorexEntries,
  exportNoShorexEntries,
  registerNoShorexEntryScan,
  getNoShorexEntry,
  registerNoShorexExitScan,
};

async function getNoShorexEntries() {
  const requestOptions = {
    method: "GET",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.get(
    configs.getApiUrl() + "/v1/noshorex_entries",
    requestOptions
  );
}

async function getNoShorexEntriesPaginated(options) {
  let sort = options.sortDesc ? "DESC" : "ASC";
  const requestOptions = {
    method: "GET",
    headers: sharedHelpers.getRequestHeaders(),
    params: {
      itemsPerPage: options.itemsPerPage,
      currentPage: options.page,
      sortBy: options.sortBy,
      sort: sort,
      search: options.search,
      filterByStatus: options.filterByStatus,
    },
  };
  return await axios.get(
    configs.getApiUrl() + "/v1/noshorex_entries/paginated",
    requestOptions
  );
}

async function getNoShorexEntry(id) {
  const requestOptions = {
    method: "GET",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.get(
    configs.getApiUrl() + `/v1/noshorex_entries/${id}`,
    requestOptions
  );
}

async function createNoShorexEntry(entry) {
  const requestOptions = {
    method: "POST",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.post(
    configs.getApiUrl() + "/v1/noshorex_entries",
    entry,
    requestOptions
  );
}

async function deleteNoShorexEntry(entryId) {
  const requestOptions = {
    method: "POST",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.delete(
    configs.getApiUrl() + "/v1/noshorex_entries/" + entryId,
    requestOptions
  );
}

async function importNoShorexEntries(payload) {
  const requestOptions = {
    method: "POST",
    headers: sharedHelpers.getRequestHeaders(),
  };
  var bodyFormData = new FormData();
  bodyFormData.append("file", payload.file);
  bodyFormData.append("replace_entries", payload.replace_entries);

  return await axios.post(
    configs.getApiUrl() + "/v1/noshorex_entries/import",
    bodyFormData,
    requestOptions
  );
}

async function exportNoShorexEntries() {
  const requestOptions = {
    method: "POST",
    responseType: "blob",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.post(
    configs.getApiUrl() + "/v1/noshorex_entries/export",
    {},
    requestOptions
  );
}

async function registerNoShorexEntryScan(payload) {
  const requestOptions = {
    method: "POST",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.post(
    configs.getApiUrl() + "/v1/noshorex_entries/" + payload.id + "/register",
    payload,
    requestOptions
  );
}

async function registerNoShorexExitScan(payload) {
  const requestOptions = {
    method: "POST",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.post(
    configs.getApiUrl() + "/v1/noshorex_entries/" + payload.id + "/register_exit",
    payload,
    requestOptions
  );
}