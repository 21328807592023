<template>
  <v-dialog v-model="isOpen" persistent width="900">
    <v-card tile>
      <v-expand-transition>
        <div v-show="expand">
          <v-alert class="mb-0" tile type="error" @click="expand = false">
            <div
              v-for="(message, index) in this.messages"
              :key="index"
              class="subtitle"
            >
              {{ message }}
            </div>
          </v-alert>
        </div>
      </v-expand-transition>
      <v-progress-linear
        height="10"
        striped
        color="primary"
        indeterminate
        v-show="loading"
      ></v-progress-linear>
      <v-toolbar color="primary" flat>
        <v-toolbar-title>
          <span>
            <v-icon left>mdi-add</v-icon>{{ $t("registered_users.create") }}
          </span>
        </v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="overflow-y-auto pb-0 form-content">
        <Loading
          v-if="saving"
          :value="saving"
          :custom-message="$t('registered_users.creating_please_wait')"
        ></Loading>
        <v-form ref="form" class="mt-10" v-else>
          <v-text-field
            counter="50"
            maxlength="50"
            v-model="item.name"
            required
            :label="$t('registered_users.name')"
            :rules="nameRules"
            outlined
          />
          <v-text-field
            counter="50"
            maxlength="50"
            v-model="item.email"
            required
            :label="$t('registered_users.email')"
            :rules="emailRules"
            outlined
          />
          <v-text-field
            counter="25"
            maxlength="25"
            v-model="item.phone"
            required
            :label="$t('registered_users.phone')"
            outlined
            :rules="phoneNumberRules"
          />
          <v-autocomplete
            v-model="item.operator_id"
            :disabled="isUpdatingOperator"
            :items="operators"
            :rules="operatorRules"
            prepend-inner-icon="mdi-domain"
            :label="$t('registered_users.operator')"
            required
            item-text="name"
            item-value="id"
            outlined
            clearable
            hide-no-data
            cache-items
            :loading="operatorsLoading"
          >
          </v-autocomplete>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer />
        <v-btn
          class="black--text"
          color="primary"
          depressed
          :disabled="loading || saving || operatorsLoading"
          @click="save"
        >
          {{ $t("global.create") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { phoneNumberValidation } from "@/helpers/common_validations.js";
import Loading from "@/components/Main/Loading";
const DEFAULT_ITEM = {
  name: "",
  email: "",
  phone: "",
};
export default {
  props: ["open"],
  components: { Loading },
  data() {
    return {
      loading: false,
      saving: false,
      expand: false,
      messages: [],
      phoneNumberRules: [phoneNumberValidation],
      nameRules: [
        (v) => !!v || this.$t("rules.required_field"),
        (v) => (v && v.length <= 255) || this.$t("rules.character_limit"),
      ],
      emailRules: [
        (v) => !!v || this.$t("rules.required_field"),
        (v) => (v && v.length <= 50) || this.$t("rules.character_limit"),
        (v) =>
          !v ||
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          this.$t("rules.email_invalid"),
      ],
      vatRules: [
        (v) => !!v || this.$t("rules.required_field"),
        (v) => (v && /^\d{9}$/.test(v)) || this.$t("rules.vat_digit_number"),
      ],
      operatorRules: [(v) => !!v || this.$t("rules.required_field")],
      isUpdatingOperator: false,
      item: Object.assign({}, DEFAULT_ITEM),
    };
  },
  computed: {
    isOpen: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("update:open", val);
      },
    },
    operatorsLoading() {
      return this.$store.state.operators.loading;
    },
    operators() {
      return this.$store.state.operators.all;
    },
  },
  watch: {
    open(val) {
      if (val) {
        this.$nextTick(() => {
          this.item = Object.assign({}, DEFAULT_ITEM);
          this.$refs.form.resetValidation();
          this.$store.dispatch("operators/GET_ALL");
        });
      }
    },
  },
  methods: {
    close() {
      this.isOpen = false;
      setTimeout(() => {
        this.expand = false;
        this.loading = false;
        this.saving = false;
      }, 600);
    },
    save() {
      this.expand = false;
      this.saving = true;
      this.messages = [];
      if (this.$refs.form.validate()) {
        let user = Object.assign({}, this.item);
        this.$store.dispatch("registered_users/CREATE", user).then((res) => {
          if (res.errors) {
            Object.entries(res.errors).forEach(([, value]) => {
              this.messages = this.messages.concat(value);
            });
            this.expand = true;
            this.saving = false;
          } else {
            this.close();
          }
        });
      } else {
        this.expand = false;
        this.saving = false;
      }
    },
  },
};
</script>
<style scoped>
.form-content {
  position: relative;
  min-height: calc(350px - var(--toolbar-height));
  max-height: calc(80vh - var(--toolbar-height));
}
</style>
