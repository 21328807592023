<template>
    <div>
      <v-dialog v-model="dialogOpen" persistent max-width="290">
        <v-card tile :loading="loading">
          <v-card-title class="headline">{{
            $t("global.eliminate")
          }}</v-card-title>
          <v-card-text>{{ $t("global.confirmation_eliminate") }}</v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer />
            <v-btn color="darken-1" :disabled="loading" text @click="cancelDelete">{{ $t("global.no") }}</v-btn>
            <v-btn color="red darken-1" :disabled="loading" text @click="confirmDelete">{{ $t("global.yes") }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </template>
  
  <script>
  /*eslint-disable*/
  export default {
    props: ["item", "open"],
    data() {
      return {
        loading: false,
      };
    },
    computed: {
      dialogOpen: {
        // getter
        get: function () {
          return this.open;
        },
        // setter
        set: function (newValue) {
          this.$emit("update:open", newValue);
        },
      },
    },
    methods: {
      cancelDelete() {
        this.dialogOpen = false;
      },
      confirmDelete() {
        this.loading = true;
        this.$store
          .dispatch("sectors/DELETE", this.item)
          .then(() => {
            this.loading = false;
            this.dialogOpen = false;
          })
          .catch(() => {
            this.loading = false;
            this.dialogOpen = false;
          });
      },
    },
  };
  </script>
  