<template>
  <v-dialog v-model="isOpen" persistent width="900">
    <v-card tile>
      <v-expand-transition>
        <div v-show="expand">
          <v-alert class="mb-0" tile type="error" @click="expand = false">
            <div
              v-for="(message, index) in this.messages"
              :key="index"
              class="subtitle"
            >
              {{ message }}
            </div>
          </v-alert>
        </div>
      </v-expand-transition>
      <v-progress-linear
        height="10"
        striped
        color="primary"
        indeterminate
        v-show="loading"
      ></v-progress-linear>
      <v-toolbar color="primary" flat>
        <v-toolbar-title>
          <span>
            <v-icon left>mdi-pencil</v-icon>{{ $t("operators.edit") }}
          </span>
        </v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text style="max-height: 50vh; overflow-y: auto">
        <v-form ref="form" class="mt-10" v-if="!!itemToEdit">
          <v-text-field
            counter="50"
            maxlength="50"
            v-model="itemToEdit.name"
            required
            :label="$t('operators.name')"
            :rules="nameRules"
            outlined
          />
          <v-text-field
            counter="25"
            maxlength="25"
            v-model="itemToEdit.phone"
            required
            :label="$t('operators.phone')"
            :rules="phoneNumberRules"
            outlined
          />
          <v-text-field
            counter="50"
            maxlength="50"
            v-model="itemToEdit.email"
            required
            :label="$t('operators.email')"
            :rules="emailRules"
            outlined
          />
          <v-text-field
            :label="$t('global.vat')"
            counter="9"
            maxlength="9"
            name="vat"
            v-model="itemToEdit.vat_number"
            :error-messages="vatNumberValidation"
            type="text"
            outlined
            :rules="vatRules"
          ></v-text-field>
          <v-select
            v-model="itemToEdit.status"
            required
            :label="$t('operators.status')"
            :placeholder="$t('operators.status')"
            :rules="statusRules"
            item-value="code"
            :items="operatorStatuses"
            prepend-inner-icon="mdi-list-status"
            outlined
          >
            <template v-slot:item="{ item }">
              {{ item.displayText }}
            </template>
            <template v-slot:selection="{ item }">
              {{ item.displayText }}
            </template>
          </v-select>
          <v-checkbox
            class="ml-2"
            v-model="itemToEdit.accredited"
            :label="$t('operators.accredited_operator')"
          ></v-checkbox>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer />
        <v-btn
          class="black--text"
          color="primary"
          depressed
          :disabled="loading"
          @click="save"
        >
          {{ $t("global.update") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { phoneNumberValidation } from "@/helpers/common_validations.js";
import configs from "@/helpers/configs";
export default {
  props: ["open", "item"],
  mounted() {
    this.$store.dispatch("operator_types/GET_ALL");
  },
  data() {
    return {
      loading: false,
      expand: false,
      messages: [],
      localItem: null,
      validationErrors: [],
      vatRules: [
        (v) => !!v || this.$t("rules.required_field"),
        (v) => (v && /^\d+$/.test(v)) || this.$t("rules.vat_digit_number"),
      ],

      nameRules: [
        (v) => !!v || this.$t("rules.required_field"),
        (v) => (v && v.length <= 255) || this.$t("rules.character_limit"),
      ],
      phoneNumberRules: [
        (v) => !!v || this.$t("rules.required_field"),
        phoneNumberValidation,
      ],
      emailRules: [
        (v) => !!v || this.$t("rules.required_field"),
        (v) => (v && v.length <= 50) || this.$t("rules.character_limit"),
        (v) =>
          !v ||
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          this.$t("rules.email_invalid"),
      ],
      statusRules: [(v) => !!v || this.$t("rules.required_field")],
    };
  },
  watch: {
    expand(val) {
      if (!val) {
        this.validationErrors = [];
      }
    },
    isOpen(val) {
      if (val) {
        this.localItem = structuredClone(this.item);

        if (this.localItem.legal_doc_path) {
          const file = new File([], "documento.pdf", {
            type: "application/pdf",
          });

          this.localItem.file = file;
        }
      }
    },
  },
  computed: {
    vatNumberValidation() {
      if (this.validationErrors?.vat_number) {
        return this.validationErrors.vat_number;
      } else {
        return "";
      }
    },
    isOpen: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("update:open", val);
      },
    },
    itemToEdit: {
      get() {
        return this.localItem;
      },
      set(val) {
        this.$emit("update:item", val);
      },
    },
    operatorStatuses() {
      return configs.getOperatorStatuses();
    },
  },
  methods: {
    close() {
      this.isOpen = false;
      this.expand = false;
      this.loading = false;
    },
    save() {
      this.expand = false;
      this.loading = true;
      this.messages = [];
      if (this.$refs.form.validate()) {
        this.$store
          .dispatch("operators/UPDATE", this.itemToEdit)
          .then((res) => {
            if (res.errors) {
              Object.entries(res.errors).forEach(([, value]) => {
                this.messages = this.messages.concat(value);
                this.validationErrors = res.errors;
              });
              this.expand = true;
              this.loading = false;
            } else {
              this.close();
            }
          });
      } else {
        this.expand = false;
        this.loading = false;
      }
    },
  },
};
</script>
