<template>
  <v-bottom-sheet v-model="showDetails">
    <v-card
      v-if="!!selectedItem"
      class="d-flex flex-column details-container"
      tile
    >
      <v-toolbar class="d-flex flex-column flex-grow-0" flat color="primary">
        <v-toolbar-title class="font-weight-bold">
          <v-icon class="black--text" left>mdi-account-box</v-icon>
          {{ item.name }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="showDetails = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text
        class="details-section pa-2 ma-0"
      >
        <Loading v-model="loading"></Loading>
        <v-card v-if="!!memberDetails" class="ma-0 pa-0" flat>
          <v-card-text  class="ma-0 pa-0">
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td width="50%" class="text-subtitle-1 font-weight-black">
                      {{ $t("users.name") }}
                    </td>
                    <td>
                      {{ memberDetails.name }}
                    </td>
                  </tr>
                  <tr>
                    <td width="50%" class="text-subtitle-1 font-weight-black">
                      {{ $t("users.email") }}
                    </td>
                    <td>
                      {{ memberDetails.email }}
                    </td>
                  </tr>
                  <tr>
                    <td width="50%" class="text-subtitle-1 font-weight-black">
                      {{ $t("users.phone") }}
                    </td>
                    <td>
                      {{ memberDetails.phone }}
                    </td>
                  </tr>
                </tbody></template
              ></v-simple-table
            >
          </v-card-text>
        </v-card>
      </v-card-text>
      <v-card-actions class="request-actions" v-if="!!item">
        <v-btn
          v-if="isTeamOwner"
          class="flex-grow-1"
          @click.stop="handleRemoveMember"
          :disabled="loading"
          large
          depressed
          color="error"
        >
          <v-icon left>mdi-delete</v-icon>
          {{ $t("global.delete") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <RemoveItemDialog
      :open.sync="openRemoveItemDialog"
      :item="item"
      @removed="handleItemRemoved"
    />
  </v-bottom-sheet>
</template>
<script>
import Loading from "@/components/Main/Loading";
import RemoveItemDialog from "@/components/Main/MembersOperator/RemoveItemDialog";
export default {
  props: ["value", "item"],
  components: { Loading, RemoveItemDialog },
  data() {
    return {
      loading: false,
      memberDetails: null,
      openRemoveItemDialog: false,
    };
  },
  computed: {
    user() {
      return this.$store.state.user.account.user;
    },
    operator() {
      return this.$store.state.operators.currentOperator;
    },
    isTeamOwner() {
      return this.operator && this.user && this.item
        ? this.operator.created_by == this.user.id &&
            this.item.id !== this.user.id
        : false;
    },
    selectedItem: {
      get() {
        return this.item;
      },
      set(val) {
        this.$emit("update:item", val);
      },
    },
    showDetails: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
        if (!val) {
          this.selectedItem = null;
        }
      },
    },
  },
  watch: {
    showDetails(val) {
      if (val) {
        this.loadMemberDetails();
      }
    },
  },
  methods: {
    loadMemberDetails() {
      if (this.selectedItem.id) {
        this.loading = true;
        this.memberDetails = null;
        this.$store
          .dispatch("operators/GET_MEMBER", {
            operator_id: this.operator.id,
            user_id: this.selectedItem.id,
          })
          .then((data) => {
            this.loading = false;
            this.memberDetails = data;
          })
          .catch((e) => {
            console.error(e);
          });
      }
    },
    handleRemoveMember() {
      this.openRemoveItemDialog = true;
    },
    handleItemRemoved() {
      this.showDetails = false;
    },
  },
};
</script>
<style scoped>
.details-section {
  min-height: 300px;
  max-height: calc(60vh - var(--toolbar-height));
  overflow-y: auto;
  position: relative;
}
.details-section:has(+ .request-actions) {
  min-height: 300px;
  max-height: calc(60vh - var(--toolbar-height) - 60px);
}
</style>
