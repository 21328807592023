import i18n from "@/locale";

export const phoneNumberValidation = (v) => {
  if (!v) return true;
  // Custom regex pattern for phone number validation
  const phoneNumberPattern = /^(?:\+\d{1,3}\s?)?\d{0,9}$/;
  if (!v || v.length === 0) return false;

  return (
    phoneNumberPattern.test(v.trim().replace(/\s/g, "")) ||
    i18n.t("rules.invalid_phone_number_format")
  );
};

// Optional field validation
export const phoneNumberValidationOpt = (v) => {
  if (!v) return true;
  // Custom regex pattern for phone number validation
  const phoneNumberPattern = /^(?:\+\d{1,3}\s?)?\d{0,9}$/;

  return (
    phoneNumberPattern.test(v.trim().replace(/\s/g, "")) ||
    i18n.t("rules.invalid_phone_number_format")
  );
};
