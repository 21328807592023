<template>
  <v-card flat :loading="loading">
    <v-card-title class="text-h6 font-weight-black text-uppercase"
      >{{ $t("members.my_members_recent") }}
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text class="pa-0" style="height: 230px; position: relative">
      <Loading v-if="loading" v-model="loading"></Loading>
      <v-row
        align="center"
        justify="center"
        class="text-h6 text-center fill-height pa-4"
      >
        <v-container v-if="items.length == 0">
          <v-icon left>mdi-account-box</v-icon
          >{{ $t("members.no_members_found") }}
        </v-container>
        <v-slide-group
          v-else
          center-active
          show-arrows="desktop"
          v-model="selectedItem"
        >
          <v-slide-item
            v-for="item in items"
            :key="item.id"
            :value="item"
            v-slot="{ active, toggle }"
          >
            <div style="width: 280px">
              <Member
                :item="item"
                :active="active"
                @click.stop="toggle"
                align="left"
              ></Member></div></v-slide-item
        ></v-slide-group>
      </v-row>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn
        :disabled="loading"
        color="primary"
        class="black--text"
        large
        block
        width="100%"
        depressed
        to="/members"
        ><v-icon left>mdi-account-group</v-icon> Lista de membros</v-btn
      >
    </v-card-actions>
    <MemberDetails
      v-model="viewMemberDetails"
      :item.sync="selectedItem"
    ></MemberDetails>
  </v-card>
</template>
<script>
import Loading from "@/components/Main/Loading";
import Member from "@/components/Main/MembersOperator/Member";
import MemberDetails from "@/components/Main/MembersOperator/MemberDetails";

export default {
  components: {
    Loading,
    Member,
    MemberDetails,
  },
  data: () => ({
    selectedItem: null,
    viewMemberDetails: false,
  }),
  computed: {
    loading() {
      return this.$store.state.members.loading;
    },
    items() {
      return this.$store.state.members.all.splice(0, 5);
    },
  },
  watch: {
    selectedItem(val) {
      if (val) {
        this.viewMemberDetails = true;
      }
    },
  },
  mounted() {
    this.loadMembers();
  },
  methods: {
    handleMemberClick(item) {
      this.selectedItem = item;
    },
    loadMembers() {
      this.$store.dispatch("members/GET_TEAM_MEMBERS").catch((ex) => {
        console.error("Error loading operator members", ex);
      });
    },
  },
};
</script>
