<template>
  <v-dialog v-model="isOpen" persistent width="900">
    <v-card tile>
      <v-expand-transition>
        <div v-show="expand">
          <v-alert class="mb-0" tile type="error" @click="expand = false">
            <div
              v-for="(message, index) in this.messages"
              :key="index"
              class="subtitle"
            >
              {{ message }}
            </div>
          </v-alert>
        </div>
      </v-expand-transition>
      <v-progress-linear
        height="10"
        striped
        color="primary"
        indeterminate
        v-show="loading"
      ></v-progress-linear>
      <v-toolbar color="primary" flat>
        <v-toolbar-title>
          <span>
            <v-icon left>mdi-pencil</v-icon>{{ $t("global.edit_user") }}
          </span>
        </v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="overflow-y-auto pb-0 form-content">
        <v-form ref="form" class="mt-10" v-if="!!itemToEdit">
          <v-alert v-if="item.is_ldap" text icon="mdi-information-outline">
            {{ $t("users.ldap_user_explanation") }}
          </v-alert>
          <v-text-field
            counter="50"
            maxlength="50"
            v-model="itemToEdit.name"
            required
            :label="$t('users.name')"
            :rules="nameRules"
            outlined
          />
          <v-text-field
            :disabled="itemToEdit.is_ldap"
            counter="50"
            maxlength="50"
            v-model="itemToEdit.email"
            :label="$t('users.email')"
            :rules="emailRules"
            autocomplete="new-email"
            outlined
          />
          <v-text-field
            :disabled="itemToEdit.is_ldap"
            type="password"
            v-model="itemToEdit.password"
            :label="$t('users.password')"
            :rules="passwordRules"
            validate-on-blur
            placeholder="(alterar apenas se necessário)"
            autocomplete="new-password"
            outlined
            counter="50"
            maxlength="50"
          />
          <v-text-field
            :disabled="itemToEdit.is_ldap"
            type="password"
            v-model="itemToEdit.confirmPassword"
            :label="$t('users.password_confirm')"
            :rules="confirmPasswordRules"
            outlined
            counter="50"
            maxlength="50"
          />
          <LocodeSelectionInput v-model="item.locodes" :rules="locodesRules" />
          <v-checkbox
            class="ml-2"
            v-model="itemToEdit.receive_email_notification"
            :label="$t('global.receive_email_notification')"
          ></v-checkbox>
          <v-checkbox
            class="ml-2"
            v-model="itemToEdit.speed_dial"
            label="Menu de Atalhos"
          ></v-checkbox>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer />
        <v-btn
          class="black--text"
          color="primary"
          depressed
          :disabled="loading"
          @click="save"
        >
          {{ $t("global.update") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import LocodeSelectionInput from "./LocodeSelectionInput";

export default {
  props: ["open", "item"],
  components: { LocodeSelectionInput },
  data() {
    return {
      loading: false,
      expand: false,
      messages: [],
      nameRules: [
        (v) => !!v || this.$t("rules.required_field"),
        (v) => (v && v.length <= 255) || this.$t("rules.character_limit"),
      ],
      passwordRules: [],
      confirmPasswordRules: [
        (v) => this.itemToEdit.password == v || "As palavra-passes devem coincidir",
      ],
      locodesRules: [
        (v) => (!!v && v.length > 0) || "Porto de escala obrigatório",
      ],
    };
  },
  watch: {
    open(val) {
      if (val) {
        this.itemToEdit.password = "";
        this.itemToEdit.confirmPassword = "";
        this.$nextTick(() => {
          if (this.$refs && this.$refs.form) {
            this.$refs.form.resetValidation();
          }
        });
      }
    },
  },
  computed: {
    isOpen: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("update:open", val);
      },
    },
    itemToEdit: {
      get() {
        return this.item;
      },

      set(val) {
        this.$emit("update:item", val);
      },
    },
    emailRules() {
      if (this.item && this.item.is_ldap) {
        return [];
      } else {
        return [
          (v) => !!v || "Endereço de e-mail obrigatório",
          (v) => /.+@.+/.test(v) || "O e-mail deve ser válido",
          (v) => (v && v.length <= 100) || "O limite de caracteres é 255",
        ];
      }
    },
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    close() {
      this.isOpen = false;
      this.expand = false;
      this.loading = false;
    },
    save() {
      this.expand = false;
      this.loading = true;
      this.messages = [];
      if (this.$refs.form.validate()) {
        this.$store
          .dispatch("users/UPDATE_USER", this.itemToEdit)
          .then((res) => {
            if (res.errors) {
              Object.entries(res.errors).forEach(([, value]) => {
                this.messages = this.messages.concat(value);
              });
              this.expand = true;
              this.loading = false;
            } else {
              this.close();
            }
          });
      } else {
        this.expand = false;
        this.loading = false;
      }
    },
  },
};
</script>
<style scoped>
.form-content {
  position: relative;
  min-height: 300px;
  max-height: calc(80vh - var(--toolbar-height) - 60px);
}
</style>
