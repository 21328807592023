<template>
  <div>
    <v-btn fab small title="Planos" @click="openCalendar">
      <v-icon> mdi-calendar-month-outline </v-icon>
    </v-btn>
    <v-dialog
      v-model="isPlansListEnabled"
      transition="dialog-bottom-transition"
      width="90%"
      persistent
      :fullscreen="$vuetify.breakpoint.xs"
    >
      <v-card tile>
        <v-toolbar dark flat color="primary" class="black--text">
          <v-toolbar-title><b>Planos</b> :: {{ locode.name }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="close" class="black--text">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text
          :class="$vuetify.breakpoint.xs ? 'scheduled_mobile' : 'scheduled'"
        >
          <Loading v-model="loading"></Loading>
          <v-progress-linear
            height="10"
            striped
            color="primary"
            indeterminate
            v-show="loading"
            class="mt-5"
          ></v-progress-linear>
          <div v-if="dateClickNotAllowed" class="prevent-click-calendar"></div>
          <FullCalendar
            :options="calendarOptions"
            ref="fullCalendar"
            class="fullCalendar pt-5"
            :key="calendarKey"
            :style="
              openPlanStatistics ? 'width: calc(100% - 400px)' : 'width: 100%'
            "
          >
            <template v-slot:eventContent="arg">
              <div
                class="ma-1"
                :style="`background-color: ${arg.event.backgroundColor}`"
              >
                <p class="ma-0 wrap-event">
                  <b
                    >{{ arg.event.title }} ({{
                      arg.event.start | formatJSDatetimeOnlyTime
                    }}
                    - {{ arg.event.end | formatJSDatetimeOnlyTime }})</b
                  >
                </p>
              </div>
            </template>
          </FullCalendar>

          <PlanStatistics
            :open.sync="openPlanStatistics"
            :date="selectedDate"
            :hasEvents="clickedDateHasEvents"
            :dateClickNotAllowed.sync="dateClickNotAllowed"
            @openPlanDialog="handleOpenPlanDialog"
            @exportPlanEntryRequests="handleExportPlanEntryRequests"
            @archivePlan="handleArchivePlan"
            :key="statsKey"
            :class="
              openPlanStatistics
                ? $vuetify.breakpoint.xs
                  ? 'pa-0'
                  : 'pa-5 pr-0 pb-0'
                : 'pa-0'
            "
          />
        </v-card-text>
        <v-card-actions v-if="!loading">
          <v-chip
            color="#046d07"
            class="mr-1 text-uppercase"
            style="border: 1px solid grey"
            x-small
            label
          ></v-chip>
          {{ this.$t("plans.opened_plan") }}
          <v-chip
            color="#BF360C"
            class="ml-2 mr-1 text-uppercase"
            style="border: 1px solid grey"
            x-small
            label
          ></v-chip>
          {{ this.$t("plans.closed_plan") }}
          <v-chip
            color="rgba(255,220,40,.15)"
            class="ml-2 mr-1 text-uppercase"
            style="border: 1px solid grey"
            x-small
            label
          ></v-chip>
          {{ this.$t("global.current_day") }}
          <v-chip
            color="rgba(188,232,241,.3)"
            class="ml-2 mr-1 text-uppercase"
            style="border: 1px solid grey"
            x-small
            label
          ></v-chip>
          {{ this.$t("global.selected_day") }}
        </v-card-actions>
      </v-card>
    </v-dialog>
    <Plan
      :open.sync="openPlanDialog"
      :selected.sync="selectedDate"
      :range.sync="range"
      :selectedDates.sync="selectedDates"
      @closePlan="handleRefreshCalendar"
    />
    <VesselCallInfo
      :open.sync="openVesselCallInfo"
      :item="selectedVesselCall"
      :loading="loadingDetail"
      v-if="openVesselCallInfo"
    />
    <ExportPlanEntryRequests
      :open.sync="openExportPlanEntryRequests"
      :date.sync="planDate"
    />
    <ArchivePlanDialog
      :open.sync="openArchivePlanDialog"
      :date.sync="planDate"
      @refreshCalendar="handleRefreshCalendar"
    />
  </div>
</template>

<script>
import VesselCallInfo from "@/components/Configurations/VesselCalls/VesselCallInfo";
import PlanStatistics from "@/components/Main/Scheduling/PlanStatistics";
import Loading from "@/components/Main/Loading";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import ptLocale from "@fullcalendar/core/locales/pt";
import Plan from "@/components/Main/Scheduling/Plan";
import multiMonthPlugin from "@fullcalendar/multimonth";
import configs from "@/helpers/configs";
import ExportPlanEntryRequests from "@/components/Main/Scheduling/ExportPlanEntryRequests.vue";
import ArchivePlanDialog from "@/components/Main/Scheduling/ArchivePlanDialog.vue";

const CRUISE_SHIP_TYPE_COLOR = "rgb(160 171 187)";
const OTHER_SHIP_TYPE_COLOR = "rgb(160 171 187)";
const SHIP_TYPES = ["cruzeiros", "cruzeiro"];

export default {
  components: {
    FullCalendar,
    multiMonthPlugin,
    VesselCallInfo,
    Plan,
    Loading,
    PlanStatistics,
    ExportPlanEntryRequests,
    ArchivePlanDialog,
  },

  data: function () {
    return {
      dateClickNotAllowed: false,
      openVesselCallInfo: false,
      selectedVesselCall: null,
      isPlansListEnabled: false,
      openPlanStatistics: false,
      clickedDateHasEvents: false,
      calendarKey: 0,
      statsKey: 1,
      calendarEvents: [],
      range: null,
      selectedDates: null,
      vessel_calls: [],
      loading: false,
      loadingDetail: false,
      selectedDate: null,
      openPlanDialog: false,
      openExportPlanEntryRequests: false,
      openArchivePlanDialog: false,
      planDate: null,
      calendarOptions: {
        timeZone: "UTC",
        initialView: "dayGridMonth",
        height: "100%",
        locales: [ptLocale],
        plugins: [
          multiMonthPlugin,
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin,
        ],
        headerToolbar: {
          right: "prev,next today",
          left: "title",
        },
        eventColor: OTHER_SHIP_TYPE_COLOR,
        editable: false,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        weekends: true,
        fixedWeekCount: false,
        dateClick: (info) => {
          this.clickedDateHasEvents = this.$refs.fullCalendar.calendar
            .getEvents()
            .some((event) => {
              const eventStartDate = event.start.toISOString().substr(0, 10);
              const eventEndDate = event.end.toISOString().substr(0, 10);
              const clickedDate = info.date.toISOString().substr(0, 10);

              return (
                (eventStartDate <= clickedDate &&
                  eventEndDate >= clickedDate) ||
                eventEndDate == clickedDate
              );
            });

          this.selectedDate = info.dateStr;

          this.openPlanStatistics = true;
        },
        eventClick: (info) => {
          this.handleSelectedVesselCall(info.event.id);
        },
        dayCellDidMount: this.modifyDayCellRendering,
        eventDidMount: this.modifyEventStyle,
        eventOrder: this.customEventOrder,
        eventTimeFormat: {
          // like '14:30:00'
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: false,
        },
        customButtons: {
          prev: {
            // this overrides the prev button
            text: this.$t("global.previous"),
            click: () => {
              // Update calendar
              this.$refs.fullCalendar.getApi().prev();
              // Remove all existent events
              this.$refs.fullCalendar.calendar.removeAllEvents();
              // Load new events
              this.loadVesselCallsAndPlans();
            },
          },
          next: {
            // this overrides the next button
            text: this.$t("global.next"),
            click: () => {
              // Update calendar
              this.$refs.fullCalendar.getApi().next();
              // Remove all existent events
              this.$refs.fullCalendar.calendar.removeAllEvents();
              // Load new events
              this.loadVesselCallsAndPlans();
            },
          },
          today: {
            // this overrides the today button
            text: this.$t("global.today"),
            click: () => {
              // Update calendar
              this.$refs.fullCalendar.getApi().today();
              // Remove all existent events
              this.$refs.fullCalendar.calendar.removeAllEvents();
              // Load new events
              this.loadVesselCallsAndPlans();
            },
          },
        },
      },
    };
  },
  computed: {
    locode() {
      return configs.getLocode();
    },
    plans() {
      return this.$store.state.plans.all;
    },
  },
  watch: {
    isPlansListEnabled(val) {
      if (val) {
        this.$nextTick(() => {
          this.loadVesselCallsAndPlans();
        });
      } else {
        this.$refs.fullCalendar.getApi().destroy();
      }
    },
  },
  methods: {
    handleSelectedVesselCall(processNumber) {
      this.openVesselCallInfo = true;
      this.loadingDetail = true;
      this.$store
        .dispatch("vessel_calls/GET_VESSEL_CALL", processNumber)
        .then((data) => {
          this.selectedVesselCall = data;
          this.loadingDetail = false;
        })
        .catch((e) => {
          console.log(e);
          this.openVesselCallInfo = false;
          this.loadingDetail = false;
        });
    },
    loadPlans() {
      return new Promise((resolve, reject) => {
        let calendarApi = this.$refs.fullCalendar.getApi();
        let dateRange =
          calendarApi.currentDataManager.data.dateProfile.activeRange;
        this.$store
          .dispatch("plans/GET_ALL", {
            startDate: this.$options.filters.formatJSDatetimeOnlyDate(
              dateRange.start
            ),
            endDate: this.$options.filters.formatJSDatetimeOnlyDate(
              dateRange.end
            ),
          })
          .then(() => {
            resolve();
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },
    modifyDayCellRendering(dayCell) {
      let day = dayCell.date.toISOString().substr(0, 10);
      let plan = this.plans.find((p) => {
        return p.day == day;
      });
      if (plan && !!dayCell && !!dayCell.el) {
        dayCell.el.classList.add(
          plan.status == "ARCHIVED" ? "archived" : "noarchived"
        );
      }
    },
    modifyEventStyle(info) {
      info.el.style.border = "none";
    },
    customEventOrder(eventA, eventB) {
      let colorA = eventA.ui.backgroundColor == CRUISE_SHIP_TYPE_COLOR ? 0 : 1;
      let colorB = eventB.ui.backgroundColor == CRUISE_SHIP_TYPE_COLOR ? 0 : 1;
      let dateA = eventA.start;
      let dateB = eventB.start;

      // Sort by ship_type (using backgroundColor)
      if (colorA < colorB) {
        return -1; // eventA comes before eventB
      } else if (colorA > colorB) {
        return 1; // eventA comes after eventB
      }

      // Sort by start date
      if (dateA < dateB) {
        return -1; // eventA comes before eventB
      } else if (dateA > dateB) {
        return 1; // eventA comes after eventB
      }

      return 0; // eventA and eventB are equal
    },

    loadVesselCallsAndPlans() {
      Promise.allSettled([this.loadVesselCalls(), this.loadPlans()]).then(
        () => {
          this.refreshCalendar();
        }
      );
    },

    refreshCalendar() {
      if (this.$refs.fullCalendar) {
        // Method to refresh the calendar
        // Destroy the current calendar instance
        this.$refs.fullCalendar.getApi().destroy();

        // Render the calendar again
        this.$refs.fullCalendar.getApi().render();
      }
    },

    loadVesselCalls() {
      return new Promise((resolve, reject) => {
        let calendarApi = this.$refs.fullCalendar.getApi();
        let dateRange =
          calendarApi.currentDataManager.data.dateProfile.activeRange;

        this.vessel_calls = [];
        this.loading = true;
        this.$store
          .dispatch("vessel_calls/GET_SCHEDULER", {
            startDate: this.$options.filters.formatJSFullDatetime(
              dateRange.start
            ),
            endDate: this.$options.filters.formatJSFullDatetime(dateRange.end),
          })
          .then((data) => {
            this.vessel_calls = data;
            this.loading = false;

            this.vessel_calls
              .filter((v_call) => {
                return SHIP_TYPES.includes(v_call.ship_type.toLowerCase());
              })
              .forEach((vessel_call) => {
                let title = vessel_call.name;
                let calEvent = calendarApi.addEvent({
                  id: vessel_call.process_number,
                  title,
                  start: vessel_call.eta,
                  end: vessel_call.etd,
                  display: "block",
                  backgroundColor: CRUISE_SHIP_TYPE_COLOR,
                });

                this.calendarEvents.push(calEvent);
              });

            this.openPlanStatistics = false;
            resolve();
          })
          .catch((ex) => {
            console.error("Error loading vessel calls", ex);
            this.loading = false;
            this.vessel_calls = [];
            this.calendarEvents = [];
            reject(ex);
          });
      });
    },

    openCalendar() {
      this.isPlansListEnabled = true;
    },
    handleOpenPlanDialog(date) {
      this.range = {
        start: new Date(
          new Date(date).getFullYear(),
          new Date(date).getMonth(),
          new Date(date).getDate(),
          0,
          0,
          0
        ),
        end: new Date(
          new Date(date).getFullYear(),
          new Date(date).getMonth(),
          new Date(date).getDate(),
          23,
          59,
          59
        ),
      };

      this.selectedDates = {
        start: new Date(
          new Date(date).getFullYear(),
          new Date(date).getMonth(),
          new Date(date).getDate(),
          0,
          0,
          0
        ),
        end: new Date(
          new Date(date).getFullYear(),
          new Date(date).getMonth(),
          new Date(date).getDate(),
          23,
          59,
          59
        ),
      };

      this.openPlanDialog = true;
    },
    handleExportPlanEntryRequests(date) {
      this.planDate = date;
      this.openExportPlanEntryRequests = true;
    },
    handleArchivePlan(date) {
      this.planDate = date;
      this.openArchivePlanDialog = true;
    },
    handleRefreshCalendar() {
      this.$refs.fullCalendar.getApi().next();
      this.$refs.fullCalendar.getApi().prev();
      this.$refs.fullCalendar.calendar.removeAllEvents();
      this.loadVesselCallsAndPlans();
    },
    close() {
      this.isPlansListEnabled = false;
      this.openPlanStatistics = false;
      this.calendarKey++;
      this.statsKey++;
    },
  },
};
</script>

<style>
.fullCalendar {
  position: relative;
  z-index: 0 !important;
}

.fullCalendar a {
  color: black !important;
}
.scheduled {
  position: relative;
  height: calc(90vh - 120px);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.scheduled_mobile {
  position: relative;
  height: calc(90vh - 120px);
  padding: 5px !important;
  margin: 0px !important;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.fc-toolbar-title {
  text-transform: uppercase;
  font-size: 8rem;
}

.fc-icon {
  line-height: 12px;
}

.fc-h-event .fc-event-main {
  color: black;
  margin-top: 1px !important;
}

.fc-daygrid-day-top a {
  font-weight: 700;
}

.archived .fc-daygrid-day-top a {
  background-color: #bf360c;
  color: white !important;
}

.noarchived .fc-daygrid-day-top a {
  background: #046d07;
  color: white !important;
}

.background-day {
  background-color: black !important;
}

.theme--light.v-navigation-drawer:not(.v-navigation-drawer--floating)
  .v-navigation-drawer__border {
  background-color: transparent;
}

.wrap-event {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.prevent-click-calendar {
  width: calc(100% - 450px);
  height: calc(100% - 90px);
  background-color: rgb(210, 202, 202);
  opacity: 0.1;
  position: absolute;
  z-index: 999;
  bottom: 20px;
}
</style>
