import configs from "@/helpers/configs";
import axios from "axios";
import { sharedHelpers } from "@/api_services/utils/shared-helpers";

export const scheduledEntryScansService = {
  getScheduledEntryScans,
  exportPlan,
  getScheduledEntryScansPaginated,
  revokeEntryScan,
};

async function getScheduledEntryScans(options) {
  let sort = options.sortDesc ? "DESC" : "ASC";
  const requestOptions = {
    method: "GET",
    headers: sharedHelpers.getRequestHeaders(),
    params: {
      itemsPerPage: options.itemsPerPage,
      currentPage: options.page,
      sortBy: options.sortBy,
      sort: sort,
      search: options.search,
      filterByDates: options.filterByDates,
    },
  };
  return await axios.get(
    configs.getApiUrl() + `/v1/scheduled_entry_scans`,
    requestOptions
  );
}

async function getScheduledEntryScansPaginated(options) {
  let sort = options.sortDesc ? "DESC" : "ASC";
  const requestOptions = {
    method: "GET",
    headers: sharedHelpers.getRequestHeaders(),
    params: {
      itemsPerPage: options.itemsPerPage,
      currentPage: options.page,
      sortBy: options.sortBy,
      sort: sort,
      search: options.search,
      filterByDates: options.filterByDates,
      scanType: options.scanType,
    },
  };
  return await axios.get(
    configs.getApiUrl() + "/v1/scheduled_entry_scans/paginated",
    requestOptions
  );
}

async function exportPlan(options) {
  let sort = options.sortDesc ? "DESC" : "ASC";
  const requestOptions = {
    method: "POST",
    responseType: "blob",
    headers: sharedHelpers.getRequestHeaders(),
    params: {
      itemsPerPage: options.itemsPerPage,
      currentPage: options.page,
      sortBy: options.sortBy,
      sort: sort,
      search: options.search,
      filterByDates: options.filterByDates,
      scanType: options.scanType,
    },
  };
  return await axios.post(
    configs.getApiUrl() + "/v1/scheduled_entry_scans/export",
    {},
    requestOptions
  );
}

async function revokeEntryScan(id, type) {

  const requestOptions = {
    method: "POST",
    headers: sharedHelpers.getRequestHeaders()
  };
  return await axios.post(
    configs.getApiUrl() + `/v1/scheduled_entry_scans/${id}/revoke`,
    { type: type },
    requestOptions
  );
}
