import { userService } from "@/api_services/user.service";
import { sharedHelpers } from "@/api_services/utils/shared-helpers";
import configs from "@/helpers/configs";

export default {
  state: {
    account: {},
    showSessionErr: false,
  },
  mutations: {
    setShowSessionErr(state, value) {
      state.showSessionErr = value;
    },
    setAccount(state, account) {
      state.account = JSON.parse(account);
    },
    setLocode(state, locode) {
      state.account = {
        ...state.account,
        locode: locode,
      };
    },
  },
  actions: {
    LOGIN: (context, credentials) => {
      return new Promise((resolve, reject) => {
        const { user, pass } = credentials;
        userService
          .login(user, pass)
          .then((res) => {
            if (res.status == 200) {
              let locodeStr = res.data.locode ?? "";
              let locodes = configs.getLocodes();
              let locode = locodes.find((l) => {
                return l.code == locodeStr;
              });
              let account = JSON.stringify(res.data);
              sharedHelpers.setStorageItemWithExpiry(
                "account",
                account,
                8640000 //24hours
              );
              sharedHelpers.setStorageItemWithExpiry(
                "locode",
                locode,
                8640000 //24hours
              );
              context.commit("setAccount", account);
              context.commit("setLocode", locode);
              resolve(res.data);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    SEND_EMAIL_VERIFICATION: (_, credential) => {
      return new Promise((resolve, reject) => {
        userService
          .sendEmailVerification(credential)
          .then((res) => {
            if (res.status == 200) {
              resolve(res.data);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    SET_ACCOUNT: (context, account) => {
      context.commit("setAccount", account);
    },

    SET_LOCODE: (context, locode) => {
      context.commit("setLocode", locode);
    },

    LOGOUT: (context) => {
      userService.logout();
      context.dispatch("CLEAR_ALL", null, { root: true }); // clear everything on logout
    },

    FORGOT_PASSWORD: (context, email) => {
      return new Promise((resolve, reject) => {
        userService
          .forgotPassword(email)
          .then((res) => {
            if (res.status == 200) {
              resolve(res.data);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    RESET_PASSWORD: (context, credentials) => {
      const { email, password, token } = credentials;

      return new Promise((resolve, reject) => {
        userService
          .resetPassword(email, password, token)
          .then((res) => {
            if (res.status == 200) {
              resolve(res.data);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    SIGNUP: (_, payload) => {
      const { user, operator, locode, operatorTypes } = payload;

      return new Promise((resolve, reject) => {
        userService
          .signup(user, operator, locode, operatorTypes)
          .then((res) => {
            if (res.status == 200) {
              resolve(res.data);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    UPDATE_PROFILE_DETAILS: (context, newUser) => {
      const { name, email, phone } = newUser;
      return new Promise((resolve, reject) => {
        userService
          .updateProfileDetails(name, email, phone)
          .then((res) => {
            if (res.status == 200) {
              if (res.data?.error) {
                reject(res.data.error);
              } else {
                resolve(res.data);
              }
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    UPDATE_PROFILE_PASSWORD: (context, newUser) => {
      const { currentPassword, password } = newUser;

      return new Promise((resolve, reject) => {
        userService
          .updateProfilePassword(currentPassword, password)
          .then((res) => {
            if (res.status == 200) {
              resolve(res.data);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    UPDATE_ACCOUNT_DATA: ({ commit }, account) => {
      return new Promise((resolve, reject) => {
        try {
          sharedHelpers.updateAccountData(account);
          //commit("setAccount", account);
          resolve();
        } catch (error) {
          reject(error);
        }
      });
    },
  },
};
