<template>
  <div class="ma-4">
    <div class="d-flex flex-wrap">
      <div class="ma-0 pa-0">
        <p class="text-h5 font-weight-bold mb-0">
          {{ $t("access_control_page.page_title") }}
        </p>
        <p class="text-caption">
          {{ $t("access_control_page.page_title_help_list") }}
        </p>
      </div>
    </div>
    <List
    ></List>
  </div>
</template>

<script>
import List from "@/components/Main/GrantEntries/List";
export default {
  components: { List },
  data() {
    return {
    };
  },
  methods: {
  },
};
</script>
