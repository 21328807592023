<template>
  <div>
    <v-data-table
      :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'mdi-page-first',
        lastIcon: 'mdi-page-last',
        'items-per-page-options': [5, 10, 25, 50],
      }"
      :loading="loading"
      :search.sync="options.search"
      :page.sync="options.page"
      :items-per-page.sync="options.itemsPerPage"
      :headers="headers"
      :items="items"
      :server-items-length="totalItemCount"
      @update:options="handleOptionsUpdate('options', $event)"
      item-key="id"
      fixed-header
      :sort-by.sync="options.sortBy"
      :sort-desc.sync="options.sortDesc"
      must-sort
      class="vehicle-types-table"
    >
      <template v-slot:top>
        <v-text-field
          v-model="options.search"
          single-line
          :label="$t('vehicle_types.search')"
          prepend-inner-icon="mdi-magnify"
          outlined
          @input="filterItems"
        >
        </v-text-field>
      </template>
      <template v-slot:item.icon="{ item }">
        <v-icon>{{ item.icon }}</v-icon>
      </template>
      <template v-slot:item.updated_at="{ item }">
        {{ item.updated_at | formatDatetime }}
      </template>
      <template
        v-if="hasUserPermissionToViewEditOrManage('VEHICLE_TYPES')"
        v-slot:[`item.actions`]="{ item }"
      >
        <v-menu bottom absolute v-if="verifyIfCanEditOrDelete(item)">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item @click="edit(item)">
              <v-list-item-icon class="mr-2">
                <v-icon>mdi-pencil</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t("global.edit") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-if="hasUserPermissionToManage('VEHICLE_TYPES')"
              @click="remove(item)"
            >
              <v-list-item-icon class="mr-2">
                <v-icon>mdi-delete</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t("global.remove") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>

      <template #footer.prepend>
        <v-spacer />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { debounce } from "debounce";
export default {
  data() {
    return {
      headers: [
        {
          text: "",
          value: "actions",
          sortable: false,
          width: "30px",
        },
        {
          text: this.$t("vehicle_types.name"),
          align: "start",
          sortable: true,
          value: "name",
        },
        {
          text: this.$t("vehicle_types.icon"),
          align: "start",
          sortable: true,
          value: "icon",
        },
        {
          text: this.$t("vehicle_types.code"),
          align: "start",
          sortable: true,
          value: "code",
        },
        {
          text: this.$t("global.created_by"),
          value: "creator_name",
          class: "font-weight-bold",
          align: "start",
          sortable: true,
          width: "100px",
        },
        {
          text: this.$t("global.updated_by"),
          value: "updater_name",
          class: "font-weight-bold",
          align: "start",
          sortable: true,
          width: "100px",
        },
        {
          text: this.$t("global.updated_at"),
          value: "updated_at",
          class: "font-weight-bold",
          align: "start",
          sortable: true,
          width: "150px",
        },
      ],
      selectedItem: null,
    };
  },
  computed: {
    loading() {
      return this.$store.state.vehicle_types.loading;
    },
    items() {
      return this.$store.state.vehicle_types.all;
    },
    options: {
      cache: false,
      get() {
        return this.$store.state.vehicle_types.options;
      },
      set(val) {
        this.$store.dispatch("vehicle_types/UPDATE_OPTIONS", val);
      },
    },
    totalItemCount() {
      return this.$store.state.vehicle_types.total;
    },
  },
  methods: {
    loadVehicleTypes() {
      this.$store.dispatch("vehicle_types/GET_ALL").catch((ex) => {
        console.error("Error loading vehicle types", ex);
      });
    },
    handleOptionsUpdate: debounce(function () {
      this.loadVehicleTypes();
    }, 500),

    filterItems: debounce(function () {
      // Changes the current page to the first one to reset the search stop bugs from happening
      if (this.options.page == 1) {
        this.loadVehicleTypes();
      } else {
        this.options.page = 1;
      }
    }, 500),
    edit(item) {
      this.$emit("edit", item);
    },
    remove(item) {
      this.$emit("remove", item);
    },
    verifyIfCanEditOrDelete(vehicleType) {
      // If the vehicle type was created by the seeder, it can't be edited or deleted
      return vehicleType.created_by ? true : false;
    },
  },
};
</script>
<style>
.vehicle-types-table .v-data-table__wrapper {
  max-height: calc(100vh - 290px);
}
</style>
