<template>
  <v-data-table
    class="all-operator-types-table"
    :footer-props="{
      showFirstLastPage: true,
      firstIcon: 'mdi-page-first',
      lastIcon: 'mdi-page-last',
      'items-per-page-options': [5, 10, 25, 50],
    }"
    :loading="loading"
    :search.sync="options.search"
    :page.sync="options.page"
    :headers="headers"
    :items="items"
    :items-per-page.sync="options.itemsPerPage"
    :server-items-length="totalItemCount"
    @update:options="handleOptionsUpdate('options', $event)"
    item-key="id"
    fixed-header
    :sort-by.sync="options.sortBy"
    :sort-desc.sync="options.sortDesc"
    must-sort
  >
    <template v-slot:top>
      <v-text-field
        v-model="options.search"
        class="mb-4"
        single-line
        :label="$t('operator_types.search')"
        prepend-inner-icon="mdi-magnify"
        outlined
        hide-details
        @input="filterItems"
      />
    </template>
    <template
      v-if="hasUserPermissionToViewEditOrManage('OPERATOR_TYPES')"
      v-slot:[`item.actions`]="{ item }"
    >
      <v-menu bottom absolute v-if="verifyIfCanEditOrDelete(item)">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item @click="edit(item)">
            <v-list-item-icon class="mr-2">
              <v-icon>mdi-pencil</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t("global.edit") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="hasUserPermissionToManage('OPERATOR_TYPES')"
            @click="remove(item)"
          >
            <v-list-item-icon class="mr-2">
              <v-icon>mdi-delete</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t("global.remove") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
    <template v-slot:item.updated_at="{ item }">
      {{ item.updated_at | formatDatetime }}
    </template>
  </v-data-table>
</template>

<script>
import { debounce } from "debounce";

export default {
  data() {
    return {
      // loading: false,
      headers: [
        {
          text: "",
          value: "actions",
          sortable: false,
          width: "30px",
        },
        {
          text: this.$t("operator_types.code"),
          value: "code",
        },
        {
          text: this.$t("operator_types.name"),
          value: "name",
        },
        {
          text: this.$t("global.created_by"),
          value: "creator_name",
          class: "font-weight-bold",
          align: "start",
          sortable: true,
          width: "100px",
        },
        {
          text: this.$t("global.updated_by"),
          value: "updater_name",
          class: "font-weight-bold",
          align: "start",
          sortable: true,
          width: "100px",
        },
        {
          text: this.$t("global.updated_at"),
          value: "updated_at",
          class: "font-weight-bold",
          align: "start",
          sortable: true,
          width: "150px",
        },
      ],
    };
  },
  computed: {
    loading: {
      get() {
        return this.$store.state.operator_types.loading;
      },
      set(val) {
        this.$store.dispatch("operator_types/UPDATE_LOADING", val);
      },
    },
    items() {
      return this.$store.state.operator_types.allPaginated;
    },
    options: {
      cache: false,
      get() {
        return this.$store.state.operator_types.options;
      },
      set(val) {
        this.$store.dispatch("operator_types/UPDATE_OPTIONS", val);
      },
    },
    totalItemCount() {
      return this.$store.state.operator_types.total;
    },
  },
  mounted() {
    this.$store.dispatch("operator_types/RESET");
  },
  methods: {
    loadOperatorTypes() {
      this.loading = true;
      this.$store
        .dispatch("operator_types/GET_ALL_PAGINATED")
        .then(() => {
          this.loading = false;
        })
        .catch((ex) => {
          console.error("Error loading operator types", ex);
          this.loading = false;
        });
    },

    filterItems: debounce(function () {
      // Changes the current page to the first one to reset the search stop bugs from happening
      if (this.options.page == 1) {
        this.loadOperatorTypes();
      } else {
        this.options.page = 1;
      }
    }, 500),

    handleOptionsUpdate: debounce(function () {
      this.loadOperatorTypes();
    }, 500),

    edit(item) {
      this.$emit("edit", item);
    },
    remove(item) {
      this.$emit("remove", item);
    },

    verifyIfCanEditOrDelete(operatorType) {
      // If the operator type was created by the seeder, it can't be edited or deleted
      return operatorType.created_by ? true : false;
    },
  },
};
</script>

<style>
.all-operator-types-table .v-data-table__wrapper {
  max-height: calc(100vh - 290px);
}
</style>
