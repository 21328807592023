import { noShorexEntriesService } from "@/api_services/noshorex_entries.service";

const DEFAULT_STATE = {
  all: [],
  loading: false,
  allPaginated: [],
  loadingPaginated: false,
  options: {
    search: "",
    page: 1,
    itemsPerPage: 10,
    sortBy: "registration_plate",
    sortDesc: false,
    filterByStatus: null,
  },
  total: 0,
};

export default {
  namespaced: true,
  state: Object.assign({}, JSON.parse(JSON.stringify(DEFAULT_STATE))),
  getters: {},
  mutations: {
    setOptions(state, options) {
      state.options = options;
    },
    setTotal(state, total) {
      state.total = total;
    },
    setNoShorexEntries(state, entries) {
      state.all = entries;
    },
    setLoading(state, isLoading) {
      state.loading = isLoading;
    },
    setPaginated(state, operators) {
      state.allPaginated = operators;
    },
    setLoadingPaginated(state, isLoading) {
      state.loadingPaginated = isLoading;
    },
    setDefaultState(state) {
      Object.assign(state, JSON.parse(JSON.stringify(DEFAULT_STATE)));
    },
  },
  actions: {
    UPDATE_OPTIONS(context, options) {
      context.commit("setOptions", options);
    },
    GET_ALL: (context) => {
      return new Promise((resolve, reject) => {
        context.commit("setLoading", true);
        noShorexEntriesService
          .getNoShorexEntries()
          .then((res) => {
            context.commit("setNoShorexEntries", res.data);
            context.commit("setLoading", false);
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            context.commit("setLoading", false);
            reject(e);
          });
      });
    },

    GET_ALL_PAGINATED: (context, options) => {
      return new Promise((resolve, reject) => {
        context.commit("setLoadingPaginated", true);
        context.commit("setPaginated", []);
        if (options) {
          context.commit("setOptions", options);
        }
        noShorexEntriesService
          .getNoShorexEntriesPaginated(context.state.options)
          .then((res) => {
            context.commit("setPaginated", res.data.data);
            context.commit("setTotal", res.data.total);
            context.commit("setLoadingPaginated", false);
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            context.commit("setLoadingPaginated", false);
            reject(e);
          });
      });
    },

    GET_BY_ID: (_, id) => {
      return new Promise((resolve, reject) => {
        noShorexEntriesService
          .getNoShorexEntry(id)
          .then((res) => {
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    CREATE: (context, entry) => {
      return new Promise((resolve, reject) => {
        noShorexEntriesService
          .createNoShorexEntry(entry)
          .then((res) => {
            resolve(res.data);
            context.dispatch("GET_ALL_PAGINATED");
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    DELETE: (context, entryId) => {
      return new Promise((resolve, reject) => {
        noShorexEntriesService
          .deleteNoShorexEntry(entryId)
          .then((res) => {
            resolve(res.data);
            context.dispatch("GET_ALL_PAGINATED");
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    IMPORT: (context, payload) => {
      return new Promise((resolve, reject) => {
        noShorexEntriesService
          .importNoShorexEntries(payload)
          .then((res) => {
            resolve(res.data);
            context.dispatch("GET_ALL_PAGINATED");
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },
    EXPORT: (_) => {
      return new Promise((resolve, reject) => {
        noShorexEntriesService
          .exportNoShorexEntries()
          .then((res) => {
            var fileURL = window.URL.createObjectURL(new Blob([res.data]));
            var fileLink = document.createElement("a");

            fileLink.href = fileURL;
            fileLink.setAttribute("download", "[3SHOREX]LISTA_DE_ENTRADAS_NOSHOREX.csv");
            document.body.appendChild(fileLink);

            fileLink.click();

            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    REGISTER_NOSHOREX_ENTRY_SCAN: (_, payload) => {
      return new Promise((resolve, reject) => {
        noShorexEntriesService
          .registerNoShorexEntryScan(payload)
          .then((res) => {
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    REGISTER_NOSHOREX_EXIT_SCAN: (_, payload) => {
      return new Promise((resolve, reject) => {
        noShorexEntriesService
          .registerNoShorexExitScan(payload)
          .then((res) => {
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    RESET: (context) => {
      context.commit("setDefaultState");
    },
  },
};
