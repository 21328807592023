<template>
  <v-dialog v-model="isOpen" persistent width="500">
    <v-card tile>
      <v-toolbar color="primary" flat>
        <v-toolbar-title>
          <span>
            <v-icon left>mdi-car</v-icon>{{ item.registration_plate }}
          </span>
        </v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="mt-5">
        <v-simple-table dense>
          <template v-slot:default>
            <tbody>
              <tr>
                <td>{{ $t("vehicles.registration_plate") }}</td>
                <td>{{ item.registration_plate }}</td>
              </tr>
              <tr v-if="!!item.operator_name">
                <td>{{ $t("vehicles.operator") }}</td>
                <td>{{ item.operator_name }}</td>
              </tr>
              <tr>
                <td>{{ $t("vehicles.type") }}</td>
                <td>
                  {{ item.type }}
                </td>
              </tr>
              <tr>
                <td>{{ $t("vehicles.brand") }}</td>
                <td>{{ item.brand }}</td>
              </tr>
              <tr>
                <td>{{ $t("vehicles.model") }}</td>
                <td>{{ item.model }}</td>
              </tr>
              <tr>
                <td>{{ $t("vehicles.seating_capacity") }}</td>
                <td>{{ item.seating_capacity }}</td>
              </tr>
              <tr>
                <td>{{ $t("vehicles.contact_phone") }}</td>
                <td>{{ item.phone }}</td>
              </tr>
              <tr v-if="!!item.permit_number">
                <td>{{ $t("vehicles.permit_number") }}</td>
                <td>{{ item.permit_number }}</td>
              </tr>
              <tr v-if="!!item.client_number">
                <td>{{ $t("vehicles.client_number") }}</td>
                <td>{{ item.client_number }}</td>
              </tr>
              <tr v-if="!!item.license_number">
                <td>{{ $t("vehicles.license_number") }}</td>
                <td>{{ item.license_number }}</td>
              </tr>
              <tr v-if="!!item.valid_until">
                <td>{{ $t("vehicles.valid_until") }}</td>
                <td>{{ item.valid_until | formatDate }}</td>
              </tr>
              <tr v-if="!!item.rate_type">
                <td>{{ $t("vehicles.rate_type") }}</td>
                <td>{{ item.rate_type }}</td>
              </tr>
              <tr v-if="!!item.notes">
                <td>{{ $t("vehicles.notes") }}</td>
                <td>{{ item.notes }}</td>
              </tr>
              <tr>
                <td>{{ $t("vehicles.status") }}</td>
                <td>{{ $t("vehicle_states." + item.status.toLowerCase()) }}</td>
              </tr>
              <tr></tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-card outlined class="mt-4" v-if="!!item.image_path" width="500px">
          <v-card-title>
            <v-icon left>mdi-image</v-icon>
            {{ $t("vehicles.image") }}
          </v-card-title>
          <v-card-text>
            <v-avatar color="blank" height="300" width="100%" tile>
              <v-img contain height="300" width="100%" :src="img_path">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      :size="150"
                      :width="3"
                      color="grey"
                      indeterminate
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-avatar>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import configs from "@/helpers/configs";
export default {
  props: ["open", "item"],
  computed: {
    isOpen: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("update:open", val);
      },
    },
    vehicleStatuses() {
      return configs.getVehicleStatuses();
    },
    img_path() {
      return (
        configs.getApiUrl() + "/" + "storage/images/" + this.item.image_path
      );
    },
  },
  methods: {
    close() {
      this.isOpen = false;
    },
  },
};
</script>
