var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"vehicles-table",attrs:{"footer-props":{
      showFirstLastPage: true,
      firstIcon: 'mdi-page-first',
      lastIcon: 'mdi-page-last',
      'items-per-page-options': [5, 10, 25, 50],
    },"loading":_vm.loading,"search":_vm.options.search,"page":_vm.options.page,"items-per-page":_vm.options.itemsPerPage,"headers":_vm.headers,"items":_vm.items,"server-items-length":_vm.totalItemCount,"item-key":"id","fixed-header":"","sort-by":_vm.options.sortBy,"sort-desc":_vm.options.sortDesc,"must-sort":"","show-expand":""},on:{"update:search":function($event){return _vm.$set(_vm.options, "search", $event)},"update:page":function($event){return _vm.$set(_vm.options, "page", $event)},"update:itemsPerPage":function($event){return _vm.$set(_vm.options, "itemsPerPage", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.options, "itemsPerPage", $event)},"update:options":function($event){return _vm.handleOptionsUpdate('options', $event)},"update:sortBy":function($event){return _vm.$set(_vm.options, "sortBy", $event)},"update:sort-by":function($event){return _vm.$set(_vm.options, "sortBy", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.options, "sortDesc", $event)},"update:sort-desc":function($event){return _vm.$set(_vm.options, "sortDesc", $event)}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{attrs:{"single-line":"","label":_vm.$t('vehicles.search'),"prepend-inner-icon":"mdi-magnify","outlined":""},on:{"input":_vm.filterItems},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":200,"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-badge',{attrs:{"overlap":"","color":"green","value":_vm.nFilters,"content":_vm.nFilters}},[_c('v-btn',_vm._g(_vm._b({staticStyle:{"top":"-6px"},attrs:{"icon":"","text":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-tune-vertical-variant")])],1)],1)]}}]),model:{value:(_vm.showAdvancedSearchOptions),callback:function ($$v) {_vm.showAdvancedSearchOptions=$$v},expression:"showAdvancedSearchOptions"}},[_c('v-card',{attrs:{"width":"300"}},[_c('v-card-title',[_vm._v(_vm._s(_vm.$t("vehicles.filter_by"))+":")]),_c('v-card-text',[_c('v-select',{staticClass:"mb-2",attrs:{"required":"","clearable":"","label":_vm.$t('vehicles.type'),"placeholder":_vm.$t('vehicles.type'),"item-value":"name","items":_vm.vehicleTypes,"prepend-inner-icon":"mdi-car-cog","outlined":"","hide-details":""},on:{"change":_vm.filterItems},scopedSlots:_vm._u([{key:"item",fn:function(ref){
    var item = ref.item;
return [_c('v-icon',{attrs:{"small":"","left":""},domProps:{"innerHTML":_vm._s(item.icon)}}),_vm._v(_vm._s(item.name)+" ")]}},{key:"selection",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}}]),model:{value:(_vm.options.filterByType),callback:function ($$v) {_vm.$set(_vm.options, "filterByType", $$v)},expression:"options.filterByType"}}),_c('v-select',{attrs:{"required":"","clearable":"","label":_vm.$t('vehicles.status'),"placeholder":_vm.$t('vehicles.status'),"item-value":"code","items":_vm.vehicleStatuses,"prepend-inner-icon":"mdi-list-status","outlined":"","hide-details":""},on:{"change":_vm.filterItems},scopedSlots:_vm._u([{key:"item",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t("vehicle_states." + item.code.toLowerCase()))+" ")]}},{key:"selection",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t("vehicle_states." + item.code.toLowerCase()))+" ")]}}]),model:{value:(_vm.options.filterByStatus),callback:function ($$v) {_vm.$set(_vm.options, "filterByStatus", $$v)},expression:"options.filterByStatus"}})],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.resetFilters}},[_vm._v(_vm._s(_vm.$t("global.reset")))]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.showAdvancedSearchOptions = false}}},[_vm._v(_vm._s(_vm.$t("global.close")))])],1)],1)],1)]},proxy:true}]),model:{value:(_vm.options.search),callback:function ($$v) {_vm.$set(_vm.options, "search", $$v)},expression:"options.search"}})]},proxy:true},(
        (_vm.hasUserPermissionToViewEdit('VEHICLES') &&
          _vm.vehicle_types.length > 0) ||
        _vm.hasUserPermissionToManage('VEHICLES')
      )?{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","absolute":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[(_vm.vehicle_types.length > 0)?_c('v-list-item',{on:{"click":function($event){return _vm.edit(item)}}},[_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("global.edit")))])],1)],1):_vm._e(),(
              _vm.hasUserPermissionToManage('VEHICLES') &&
              (item.status == 'CANCELED' || item.status == 'PENDING') &&
              item.can_be_deleted
            )?_c('v-list-item',{on:{"click":function($event){return _vm.remove(item)}}},[_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',[_vm._v("mdi-delete")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("global.remove")))])],1)],1):_vm._e()],1)],1)]}}:null,{key:"item.registration_plate",fn:function(ref){
            var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"pa-0 font-weight-black",attrs:{"color":"black","text":"","plain":"","title":_vm.$t('global.view_details')},on:{"click":function($event){$event.stopPropagation();return _vm.showVehicleDetail(item)}}},'v-btn',attrs,false),on),[_c('span',{staticClass:"registration-plate-font"},[_vm._v(" "+_vm._s(item.registration_plate)+" ")])])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("global.view_details")))])])]}},{key:"item.status",fn:function(ref){
            var item = ref.item;
return [_c('v-chip',{staticClass:"text-uppercase",attrs:{"dark":"","small":"","label":"","color":_vm.getVehicleStatusColor(item)}},[_vm._v(" "+_vm._s(_vm.$t("vehicle_states." + item.status.toLowerCase()))+" ")])]}},{key:"item.type",fn:function(ref){
            var item = ref.item;
return [_c('v-chip',{staticClass:"text-uppercase",attrs:{"small":"","label":"","outlined":""}},[_c('v-icon',{attrs:{"color":"grey lighten-1","small":"","left":""},domProps:{"innerHTML":_vm._s(item.type_icon)}}),_vm._v(_vm._s(item.type)+" ")],1)]}},{key:"item.valid_until",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateFromDatabase")(item.valid_until))+" ")]}},{key:"item.updated_at",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDatetime")(item.updated_at))+" ")]}},{key:"item.data-table-expand",fn:function(ref){
            var item = ref.item;
            var isExpanded = ref.isExpanded;
            var expand = ref.expand;
return [(!!item.notes)?_c('v-btn',{attrs:{"icon":"","text":"","x-small":""},on:{"click":function($event){return expand(!isExpanded)}}},[_c('v-icon',{attrs:{"small":"","color":isExpanded ? 'blue' : ''}},[_vm._v("mdi-chat-processing-outline")])],1):_vm._e()]}},{key:"expanded-item",fn:function(ref){
            var headers = ref.headers;
            var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('div',{staticClass:"pa-2"},[_c('table',[(!!item.notes)?_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t("vehicles.notes"))+":")]),_c('td',{staticClass:"pl-5 text-uppercase",staticStyle:{"white-space":"pre-wrap"},domProps:{"innerHTML":_vm._s(item.notes)}})]):_vm._e()])])])]}},{key:"footer.prepend",fn:function(){return [_c('v-spacer')]},proxy:true}],null,true)}),(_vm.openVehicleDialog)?_c('VehicleDialog',{attrs:{"open":_vm.openVehicleDialog,"item":_vm.selectedVehicle},on:{"update:open":function($event){_vm.openVehicleDialog=$event}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }