<template>
  <div>
    <v-data-table
      class="vessel-calls-table"
      :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'mdi-page-first',
        lastIcon: 'mdi-page-last',
        'items-per-page-options': [5, 10, 25, 50],
      }"
      :loading="loading"
      :search="search"
      :page.sync="page"
      :items-per-page.sync="itemsPerPage"
      :headers="headers"
      :items="items"
      :server-items-length="totalItemCount"
      @update:options="handleOptionsUpdate('options', $event)"
      item-key="id"
      fixed-header
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      must-sort
    >
      <template v-slot:top>
        <v-text-field
          v-model="search"
          single-line
          :label="$t('vessel_calls.search')"
          prepend-inner-icon="mdi-magnify"
          outlined
          @input="filterItems"
        />
      </template>
      <template v-slot:item.eta="{ item }">
        {{ item.eta | formatDatetimeWithoutTimezone }}
      </template>
      <template v-slot:item.etd="{ item }">
        {{ item.etd | formatDatetimeWithoutTimezone }}
      </template>
      <template v-slot:item.updated_at="{ item }">
        {{ item.updated_at | formatDatetime }}
      </template>
      <template v-slot:item.number_of_passengers="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              color="blue"
              text
              plain
              class="pa-0"
              :title="$t('vessel_calls.view_vessel_call_passengers')"
              @click.stop="viewVesselCallPassengers(item)"
            >
              <b>{{ item.number_of_passengers || "N/D" }}</b>
            </v-btn></template
          >
          <span>{{ $t("vessel_calls.view_vessel_call_passengers") }}</span>
        </v-tooltip>
      </template>
      <template #footer.prepend>
        <v-spacer />
      </template>
    </v-data-table>
    <PassengersList
      :open.sync="openPassengersList"
      :item.sync="selectedItem"
    ></PassengersList>
  </div>
</template>

<script>
import PassengersList from "@/components/Configurations/Passengers/List";
import { debounce } from "debounce";
export default {
  components: { PassengersList },
  data() {
    return {
      search: "",
      page: 1,
      itemsPerPage: 10,
      headers: [
        {
          text: "",
          value: "actions",
          sortable: false,
          width: "30px",
        },
        {
          text: this.$t("vessel_calls.process_number"),
          align: "start",
          sortable: true,
          value: "process_number",
          width: "100px",
        },
        {
          text: this.$t("vessel_calls.eta"),
          value: "eta",
          align: "start",
          sortable: true,
          width: "150px",
        },
        {
          text: this.$t("vessel_calls.etd"),
          value: "etd",
          align: "start",
          sortable: true,
          width: "150px",
        },
        {
          text: this.$t("vessel_calls.name"),
          value: "name",
          class: "font-weight-bold",
          align: "start",
          sortable: true,
        },
        {
          text: this.$t("vessel_calls.berth_location"),
          value: "berth_location",
          align: "start",
          sortable: true,
        },
        {
          text: this.$t("vessel_calls.number_of_passengers"),
          value: "number_of_passengers",
          align: "start",
          sortable: true,
          width: "150px",
        },
        {
          text: this.$t("global.updated_at"),
          value: "updated_at",
          class: "font-weight-bold",
          align: "start",
          sortable: true,
          width: "150px",
        },
      ],
      items: [],
      totalItemCount: 0,
      sortBy: "eta",
      sortDesc: false,
      loading: false,
      openPassengersList: false,
      selectedItem: null,
    };
  },
  methods: {
    loadVesselCalls() {
      this.totalItemCount = 0;
      this.items = [];
      this.loading = true;
      this.$store
        .dispatch("vessel_calls/GET_ALL", {
          search: this.search,
          currentPage: this.page,
          itemsPerPage: this.itemsPerPage,
          sortBy: this.sortBy,
          sortDesc: this.sortDesc,
        })
        .then((response) => {
          this.items = response.data;
          this.totalItemCount = response.total;
          this.loading = false;
        })
        .catch((ex) => {
          console.error("Error loading vessel calls", ex);
          this.loading = false;
          this.totalItemCount = 0;
          this.items = [];
        });
    },
    viewVesselCallPassengers(item) {
      this.selectedItem = item;
      this.openPassengersList = true;
    },

    handleOptionsUpdate: debounce(function () {
      this.loadVesselCalls();
    }, 500),

    filterItems: debounce(function () {
      // Changes the current page to the first one to reset the search stop bugs from happening
      if (this.page == 1) {
        this.loadVesselCalls();
      } else {
        this.page = 1;
      }
    }, 500),
  },
};
</script>

<style>
.vessel-calls-table .v-data-table__wrapper {
  max-height: calc(100vh - 290px);
}
</style>
