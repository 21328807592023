var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"map-container"},[_c('div',{ref:"map",staticClass:"map"}),_c('div',{ref:"mapPrintable",staticClass:"mapPrintable"}),(_vm.loading)?_c('Loading',{attrs:{"value":_vm.loading,"custom-message":_vm.$t('zones.loading_zones_please_wait')}}):_vm._e(),_c('SectorZoneChangesConfirmation',{attrs:{"sector":_vm.sector,"mode":_vm.mapMode,"plan":_vm.plan,"zones":_vm.selectedSectorZones},on:{"update:mode":function($event){_vm.mapMode=$event}}}),_c('ZoneDetails',{attrs:{"zone":_vm.selectedZone,"entries":_vm.selectedZoneEntries,"entry":_vm.selectedEntry},on:{"update:zone":function($event){_vm.selectedZone=$event},"update:entry":function($event){_vm.selectedEntry=$event}}}),_c('SectorsLegend',{on:{"zoomOnSector":_vm.handleZoomOnSector}}),_c('VesselCallsMap',{attrs:{"active":_vm.shipsActive,"map":_vm.map,"plan":_vm.plan},on:{"update:active":function($event){_vm.shipsActive=$event},"update:map":function($event){_vm.map=$event}}}),(!_vm.$vuetify.breakpoint.xs)?_c('div',{staticStyle:{"position":"absolute","top":"20px","left":"20px"}},[_c('div',{staticClass:"mb-2"},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.goToHome()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-home")])],1)]}}],null,false,668442000)},[_c('span',[_vm._v(_vm._s(this.$t("global.home")))])])],1),_c('div',{staticClass:"mb-2"},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","small":"","color":_vm.shipsActive ? 'primary' : 'default'},on:{"click":function($event){$event.stopPropagation();return _vm.toggleShips.apply(null, arguments)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-ferry")])],1)]}}],null,false,800092803)},[_c('span',[_vm._v(_vm._s(_vm.shipsActive ? this.$t("plans.untoggle_ships") : this.$t("plans.toggle_ships")))])])],1),_c('div',{staticClass:"mb-2"},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.hasUserPermissionToViewEditOrManage('PLANS'))?_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","small":"","color":_vm.sectorsActive ? 'primary' : 'default',"disabled":_vm.plan && _vm.plan.status === 'ARCHIVED'},on:{"click":function($event){$event.stopPropagation();return _vm.toggleSectors.apply(null, arguments)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-texture-box")])],1):_vm._e()]}}],null,false,516650193)},[_c('span',[_vm._v(_vm._s(this.$t("plans.config_sectors")))])])],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }