var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{staticStyle:{"overflow":"hidden"},attrs:{"persistent":"","scrollable":"","width":"60%"},model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[_c('v-card',{staticStyle:{"height":"100%"},attrs:{"tile":""}},[_c('v-toolbar',{attrs:{"color":"primary","flat":""}},[_c('v-toolbar-title',[_c('span',[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account-group")]),_vm._v(_vm._s(_vm.$t("passengers.view_list"))+" ")],1)]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.close}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',{staticClass:"pa-0",staticStyle:{"height":"350px"}},[_c('v-data-table',{staticClass:"passengersList",attrs:{"headers":_vm.headers,"items":_vm.passengers,"item-key":"id","hide-default-footer":"","disable-pagination":"","fixed-header":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('b',[_vm._v(_vm._s(item.name))]),(item.special_care_or_assistance)?_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-wheelchair")]):_vm._e()]}},{key:"item.date_of_birth",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.date_of_birth ? _vm.formatDateFromDatabase(item.date_of_birth) : "N/D")+" ")]}},{key:"item.country",fn:function(ref){
var item = ref.item;
return [(item.country)?_c('v-avatar',{attrs:{"left":"","tile":"","height":"30"}},[_c('country-flag',{staticStyle:{"margin-top":"-10px"},attrs:{"country":item.country.code.toLowerCase(),"size":"normal"}})],1):_vm._e(),_vm._v(" "+_vm._s(item.country ? item.country.name : "N/D")+" ")]}},{key:"item.founded",fn:function(ref){
var item = ref.item;
return [(_vm.matchingPassengers)?_c('v-progress-linear',{attrs:{"color":"amber","buffer-value":"0","stream":""}}):_vm._e(),_vm._v(" "+_vm._s(item.passengers)+" "),(!_vm.matchingPassengers)?_c('div',{key:_vm.componentKey},_vm._l((item.founded),function(item,i){return _c('v-list-item',{key:i,staticClass:"pa-0 ma-0",staticStyle:{"min-height":"20px"},attrs:{"dense":""}},[_c('v-list-item-content',{staticClass:"pa-0 ma-0"},[_c('v-list-item-title',{staticClass:"pa-0 ma-0"},[_c('v-chip',{staticClass:"ma-2 text-uppercase",attrs:{"x-small":"","label":""}},[_vm._v(_vm._s(Math.round(item.score * 100) .toString() .padStart(2, "0"))+"%")]),_c('v-avatar',{staticClass:"pa-0 ma-0",staticStyle:{"min-width":"24px"},attrs:{"tile":"","height":"24","width":"24"}},[_c('country-flag',{staticStyle:{"margin-top":"-10px"},attrs:{"country":item.country.code.toLowerCase(),"size":"small"}})],1),_c('b',[_vm._v(_vm._s(item.name))]),_vm._v(", "+_vm._s(_vm._f("formatDateFromDatabase")(item.date_of_birth))+", "+_vm._s(item.country.name)+" ")],1)],1)],1)}),1):_vm._e()]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }