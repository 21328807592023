<template>
  <div class="ma-4">
    <div class="d-flex flex-wrap">
      <div class="ma-0 pa-0">
        <p class="text-h5 font-weight-bold mb-0">
          {{ $t("members.my_members") }}
        </p>
        <p class="text-caption">
          {{ $t("members.new_member_help_list") }}
        </p>
      </div>
      <v-spacer></v-spacer>
      <v-btn
        class="ml-auto black--text"
        :block="$vuetify.breakpoint.mobile"
        :disabled="loading"
        color="primary"
        depressed
        @click="invite"
      >
        <v-icon left>mdi-plus-box-multiple</v-icon
        >{{ $t("members.new_member") }}
      </v-btn>
    </div>
    <v-data-iterator
      :items="items"
      :custom-filter="itemsFilter"
      item-key="id"
      :items-per-page="4"
      hide-default-footer
      group-by="status"
      :search="search"
      :loading="loading"
      disable-pagination
    >
      <template v-slot:header>
        <v-text-field
          :disabled="loading"
          v-model="search"
          class="my-5"
          clearable
          flat
          solo
          outlined
          hide-details
          prepend-inner-icon="mdi-magnify"
          :label="$t('global.search')"
        ></v-text-field>
      </template>
      <template v-slot:no-results>
        <v-container fill-height fluid>
          <v-row align="center" justify="center" class="text-h6 text-center">
            <v-icon left>mdi-account-box</v-icon
            >{{ $t("members.no_members_found") }}
          </v-row></v-container
        >
      </template>
      <template v-slot:no-data>
        <v-container fill-height fluid>
          <v-row align="center" justify="center" class="text-h6 text-center">
            <v-icon left>mdi-account-box</v-icon
            >{{ $t("members.no_members_found") }}
          </v-row></v-container
        >
      </template>
      <template v-slot:loading>
        <div style="position: relative; min-height: 250px">
          <Loading v-model="loading"></Loading>
        </div>
      </template>
      <template v-slot:default="{ items }">
        <v-row>
          <v-col
            v-for="item in items"
            :key="item.id"
            cols="12"
            sm="6"
            md="4"
            lg="3"
          >
            <Member :item="item" @click.stop="handleMemberClick(item)"></Member>
          </v-col>
        </v-row>
      </template>
    </v-data-iterator>
    <NewMemberForm v-model="addMemberForm"></NewMemberForm>
    <MemberDetails
      v-model="viewMemberDetails"
      :item.sync="selectedItem"
    ></MemberDetails>
  </div>
</template>

<script>
import Loading from "@/components/Main/Loading";
import RemoveItemDialog from "@/components/Main/MembersOperator/RemoveItemDialog";
import Member from "@/components/Main/MembersOperator/Member";
import NewMemberForm from "@/components/Main/MembersOperator/NewMemberForm";
import MemberDetails from "@/components/Main/MembersOperator/MemberDetails";
export default {
  components: {
    NewMemberForm,
    RemoveItemDialog,
    Loading,
    Member,
    MemberDetails,
  },
  data() {
    return {
      search: "",
      addMemberForm: false,
      selectedItem: null,
      viewMemberDetails: false,
    };
  },
  computed: {
    loading() {
      return this.$store.state.members.loading;
    },
    items() {
      return this.$store.state.members.all;
    },
  },
  watch: {
    selectedItem(val) {
      if (val) {
        this.viewMemberDetails = true;
      }
    },
  },
  mounted() {
    this.loadMembers();
  },
  methods: {
    loadMembers() {
      this.$store.dispatch("members/GET_TEAM_MEMBERS").catch((ex) => {
        console.error("Error loading users from operator_id", ex);
      });
    },
    handleMemberClick(item) {
      this.selectedItem = item;
    },
    invite() {
      this.addMemberForm = true;
    },
    itemsFilter(items, search) {
      if (!search || search === "") return items;
      const searchRegex = new RegExp(search, "i");
      return this.items.filter((item) => {
        // Only by registration plate or vessel call name for now
        return (
          searchRegex.test(item.name) ||
          searchRegex.test(item.email) ||
          searchRegex.test(item.phone)
        );
      });
    },
  },
};
</script>
