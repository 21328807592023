<template>
  <div v-if="isVisible" style="position: absolute; bottom: 10px; right: 10px">
    <v-card width="300" :loading="loading">
      <v-toolbar flat dense>
        <v-toolbar-title 
          >{{ $t("global.sectors") }}</v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-btn icon @click="legendVisible = !legendVisible">
          <v-icon v-if="legendVisible">mdi-chevron-down</v-icon>
          <v-icon v-else>mdi-chevron-up</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-text
        style="max-height: 150px; overflow-y: auto"
        class="pa-0"
        v-if="legendVisible"
      >
        <v-list dense class="ma-0 pa-0 pb-2">
          <template v-for="sector in sectors">
            <v-list-item :key="sector.id" style="min-height: 20px; height: 20px" class="mt-2">
              <v-list-item-avatar
                size="20"
                tile
                :color="sector.color"
                style="border: 1px solid black"
                outlined
                class="mr-2"
              >
              </v-list-item-avatar>
              <v-list-item-content class="d-inline-block text-truncate pa-0 ma-0">
                {{ sector.name }}
              </v-list-item-content>
              <v-list-item-action>
                <div>
                  <v-btn icon small @click="zoomOnSector(sector)"
                    ><v-icon small>mdi-fit-to-screen-outline</v-icon></v-btn
                  >
                </div>
              </v-list-item-action>
            </v-list-item>
          </template>
        </v-list>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      legendVisible: true,
    };
  },
  computed: {
    isVisible() {
      return !this.sectorsActive && this.sectors && this.sectors.length > 0;
    },
    sectorsActive() {
      return this.$store.state.shorex.sectorsActive;
    },
    loading() {
      return this.$store.state.sectors.loading;
    },
    sectors() {
      return this.$store.state.sectors.all;
    },
  },
  methods: {
    zoomOnSector(sector) {
      this.$emit('zoomOnSector', sector);
    },
  },
};
</script>
