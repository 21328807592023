<template>
    <v-card flat :loading="loading">
    <v-card-title class="text-h6 font-weight-black text-uppercase"
      >{{ $t("scheduled_entries.my_entry_requests_recent") }}
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text class="pa-0" style="height: 230px; position: relative">
      <Loading v-if="loading" v-model="loading"></Loading>
      <v-row
        align="center"
        justify="center"
        class="text-h6 text-center fill-height pa-4"
      >
        <v-container v-if="items.length == 0">
          <v-icon left>mdi-account-box</v-icon
          >{{ $t("scheduled_entries.no_entry_requests_found") }}
        </v-container>
        <v-slide-group
          v-else
          center-active
          show-arrows="desktop"
          v-model="selectedItem"
        >
          <v-slide-item
            v-for="item in items"
            :key="item.id"
            :value="item"
            v-slot="{ active, toggle }"
          >
            <div style="width: 280px">
              <VehicleEntryRequest
                :item="item"
                :active="active"
                @click.stop="toggle"
                align="left"
              ></VehicleEntryRequest></div></v-slide-item
        ></v-slide-group>
      </v-row>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn
        :disabled="loading"
        color="primary"
        class="black--text"
        large
        block
        width="100%"
        depressed
        to="/entry_requests"
        ><v-icon left>mdi-boom-gate-arrow-up-outline</v-icon> Lista de pedidos</v-btn
      >
    </v-card-actions>
    <VehicleEntryRequestDetails
      v-model="viewEntryRequestDetails"
      :item.sync="selectedItem"
    ></VehicleEntryRequestDetails>
    <VehicleScheduledEntryDetails
      v-model="viewScheduledEntryDetails"
      :item.sync="selectedItem"
    ></VehicleScheduledEntryDetails>
  </v-card>
</template>

<script>
import VehicleEntryRequestDetails from "@/components/Main/EntryRequests/VehicleEntryRequestDetails";
import VehicleScheduledEntryDetails from "@/components/Main/EntryRequests/VehicleScheduledEntryDetails";
import VehicleEntryRequest from "@/components/Main/EntryRequests/VehicleEntryRequest";
import Loading from "@/components/Main/Loading";
export default {
  components: {
    VehicleEntryRequest,
    Loading,
    VehicleEntryRequestDetails,
    VehicleScheduledEntryDetails,
  },
  computed: {
    loading() {
      return this.$store.state.entry_requests.loading;
    },
    items() {
      return this.$store.state.entry_requests.all.splice(0, 5);
    },
  },
  data() {
    return {
      viewEntryRequestDetails: false,
      viewScheduledEntryDetails: false,
      selectedItem: null
    };
  },
  watch: {
    selectedItem() {
      if (this.selectedItem) {
        if (this.selectedItem.status == "APPROVED") {
          this.viewScheduledEntryDetails = true;
        } else {
          this.viewEntryRequestDetails = true;
        }
      }
    },
  },
  mounted() {
    this.loadEntryRequests();
  },
  methods: {
    loadEntryRequests() {
      this.$store.dispatch("entry_requests/GET_ALL").catch((e) => {
        console.error(e);
      });
    },
  },
};
</script>
