<template>
  <v-card outlined>
    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title class="text-h5 font-weight-black">{{
          this.data.title
        }}</v-list-item-title>
        <v-list-item-subtitle class="font-weight-medium">{{
          this.data.subtitle
        }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-card-text>
      <div id="chart-container" ref="chart"></div>
    </v-card-text>
  </v-card>
</template>

<script>
import Plotly from "plotly.js-dist";
export default {
  props: ["data"],
  data() {
    return {
      chartData: [
        {
          x: [],
          y: [],
          marker: {
            color: [],
          },
          textposition: "auto",
          type: "bar",
        },
      ],
      layout: {
        title: "",
        autosize: true,
        legend: { orientation: "h" },

        margin: {
          l: 15,
          r: 0,
          b: 30,
          t: 0,
        },
      },
      config: {
        displayModeBar: false,
        responsive: true,
      },
    };
  },
  mounted() {
    this.data.chartData.forEach((item) => {
      this.chartData[0].x.push(item.title);
      this.chartData[0].y.push(item.value);
      (this.chartData[0].text = this.chartData[0].y.map(String)),
        this.chartData[0].marker.color.push(item.color);
    });
    this.initializeChart();
  },
  methods: {
    initializeChart() {
      this.$nextTick(() => {
        if (this.$refs.chart) {
          Plotly.newPlot(
            this.$refs.chart,
            this.chartData,
            this.layout,
            this.config
          );
        }
      });
    },
  },
};
</script>

<style scoped>
#chart-container {
  width: 100%;
  height: 225px;
}
</style>
