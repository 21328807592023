<template>
  <v-dialog v-model="isOpen" scrollable persistent width="80%" min-width="500">
    <v-card tile>
      <v-expand-transition>
        <div v-show="expand">
          <v-alert class="mb-0" tile type="error" @click="expand = false">
            <div
              v-for="(message, index) in this.messages"
              :key="index"
              class="subtitle"
            >
              {{ message }}
            </div>
          </v-alert>
        </div>
      </v-expand-transition>
      <v-toolbar color="primary" flat>
        <v-toolbar-title>
          <span>
            <v-icon left>mdi-boom-gate-arrow-up</v-icon
            >{{ $t("entries.new_entry") }}
          </span>
        </v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pb-0 new-entry-form-content">
        <Loading v-if="loading" v-model="loading"></Loading>
        <div v-else>
          <Loading
            v-if="saving"
            :value="saving"
            :custom-message="$t('entries.adding_entry_please_wait')"
          ></Loading>
          <v-form ref="form" class="my-5" v-else>
            <v-row>
              <v-col md="12" sm="12">
                <v-autocomplete
                  v-model="item.vessel_call_id"
                  :label="$t('global.vessel_call')"
                  prepend-inner-icon="mdi-ferry"
                  clearable
                  :items="vesselCalls"
                  :rules="vesselCallRules"
                  required
                  flat
                  outlined
                  hide-no-data
                  persistent-hint
                  item-text="name"
                  item-value="id"
                  :loading="vesselCallsLoading"
                >
                  <template v-slot:item="data">
                    <v-list-item-content>
                      <v-list-item-title
                        class="font-weight-bold"
                        v-html="data.item.name"
                      ></v-list-item-title>
                      <v-list-item-subtitle v-html="data.item.process_number">
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-chip
                        label
                        outlined
                        small
                        class="font-weight-bold mb-1 text-uppercase"
                        >{{ $t("vessel_calls.eta") }}:
                        {{
                          data.item.eta | formatDatetimeWithoutTimezone
                        }}</v-chip
                      >
                      <v-chip
                        label
                        outlined
                        small
                        class="font-weight-bold text-uppercase"
                        >{{ $t("vessel_calls.etd") }}:
                        {{
                          data.item.etd | formatDatetimeWithoutTimezone
                        }}</v-chip
                      >
                    </v-list-item-action>
                  </template>
                </v-autocomplete>
                <v-autocomplete
                  v-model="item.operator_id"
                  :disabled="vehiclesLoading"
                  :label="$t('entries.operator')"
                  prepend-inner-icon="mdi-store"
                  clearable
                  :items="operators"
                  :rules="operatorRules"
                  required
                  flat
                  outlined
                  hide-no-data
                  persistent-hint
                  item-text="name"
                  item-value="id"
                  :loading="operatorsLoading"
                  @change="handleOperatorChange"
                ></v-autocomplete>
                <v-autocomplete
                  v-model="item.vehicle_id"
                  :label="$t('global.vehicle')"
                  prepend-inner-icon="mdi-car-multiple"
                  clearable
                  :items="vehicles"
                  :rules="vehicleRules"
                  flat
                  outlined
                  hide-no-data
                  persistent-hint
                  :loading="vehiclesLoading"
                  :disabled="
                    vehiclesLoading || operatorsLoading || !item.operator_id
                  "
                  item-text="registration_plate"
                  item-value="id"
                  class="registration-plate-font"
                >
                  <template v-slot:item="data">
                    <v-list-item-content>
                      <v-list-item-title class="registration-plate-font">
                        {{ data.item.registration_plate }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ data.item.vehicle_type.name }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                        Nº de Porta:
                        <span class="registration-plate-font">
                          {{
                            data.item.license_number
                              ? data.item.license_number
                              : "N/D"
                          }}</span
                        >
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>

                <v-text-field
                  v-model="item.vehicle_at_pier_date_request"
                  :rules="vehicleAtPierTimeRules"
                  :label="$t('entries.vehicle_at_pier_date_request')"
                  type="time"
                  outlined
                  clearable
                ></v-text-field>

                <v-select
                  v-model="item.entry_type"
                  :items="types"
                  :label="$t('entries.type')"
                  item-text="value"
                  item-value="code"
                  :rules="entryTypeRules"
                  outlined
                  :disabled="disabledEntryTypeSelection"
                ></v-select>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer />
        <v-btn
          class="black--text"
          color="primary"
          depressed
          :disabled="!canSubmitForm"
          @click="save"
        >
          {{ $t("global.create") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import Loading from "@/components/Main/Loading";

const DEFAULT_TYPES = [
  {
    code: "TOUR",
    value: "Excursão",
  },
  {
    code: "RACE",
    value: "Corrida",
  },
  {
    code: "RESERVATION",
    value: "Reserva",
  },
  {
    code: "CALL",
    value: "Chamada",
  },
];

const DEFAULT_ITEM = {
  entry_type: "TOUR",
  vessel_call_id: null,
  operator_id: null,
  vehicle_id: null,
  plan_id: null,
  number_of_passengers: 0,
  vehicle_at_pier_date_request: null,
};

export default {
  props: ["open", "plan", "day"],
  components: { Loading },
  data() {
    return {
      saving: false,
      expand: false,
      messages: [],
      numberOfPassengersKey: 0,
      item: Object.assign({}, DEFAULT_ITEM),
      types: DEFAULT_TYPES,
      vehiclesLoading: false,
      vehicles: [],
      vesselCallsLoading: false,
      vesselCalls: [],
      vesselCallRules: [(v) => !!v || this.$t("rules.required_field")],
      vehicleRules: [(v) => !!v || this.$t("rules.required_field")],
      operatorRules: [(v) => !!v || this.$t("rules.required_field")],
      numberOfPassengersRules: [
        (v) => !!v || this.$t("rules.required_field"),
        (v) => (v && this.isNumeric(v)) || this.$t("rules.must_be_numeric"),
      ],
      vehicleAtPierTimeRules: [(v) => !!v || this.$t("rules.required_field")],
      entryTypeRules: [(v) => !!v || this.$t("rules.required_field")],
    };
  },
  computed: {
    isOpen: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("update:open", val);
      },
    },
    loading() {
      return this.operatorsLoading || this.vesselCallsLoading;
    },
    operatorsLoading() {
      return this.$store.state.operators.loading;
    },
    operators() {
      return this.$store.state.operators.approved_operators;
    },
    canSubmitForm() {
      return !this.loading && !this.saving && !this.operatorsLoading;
    },
    selectedVehicle() {
      return this.item.vehicle_id
        ? this.vehicles.find((v) => v.id == this.item.vehicle_id)
        : null;
    },
    selectedVehicleSeatingCapacity() {
      return this.selectedVehicle ? this.selectedVehicle.seating_capacity : 50;
    },
    disabledEntryTypeSelection() {
      return (
        !this.selectedVehicle ||
        (!!this.selectedVehicle &&
          this.selectedVehicle.vehicle_type.code != "TAXI")
      );
    },
  },
  watch: {
    isOpen(val) {
      if (val) {
        this.$nextTick(() => {
          if (this.$refs.form) {
            this.$refs.form.reset();
          }

          this.vesselCalls = [];
          this.item = Object.assign({}, DEFAULT_ITEM);
          this.item.plan_id = this.plan.id;

          this.loadVesselCalls();
          this.loadOperators();
        });
      }
    },
    selectedVehicle(vehicle) {
      if (
        !vehicle ||
        (vehicle &&
          vehicle.vehicle_type.code != "TAXI" &&
          this.item.entry_type.code != "TOUR")
      ) {
        this.item.entry_type = "TOUR";
      }
    },
  },
  methods: {
    isNumeric(num) {
      return num && !isNaN(num) && parseInt(num) > 0;
    },
    handleNumberOfPassengersChange() {
      if (this.selectedVehicle) {
        this.selectedVehicle.seating_capacity =
          this.selectedVehicle.seating_capacity ?? 100;

        if (
          this.item.number_of_passengers > this.selectedVehicle.seating_capacity
        ) {
          this.item.number_of_passengers =
            this.selectedVehicle.seating_capacity;
          this.numberOfPassengersKey++;
        }
      }
    },
    loadVesselCalls() {
      this.vesselCallsLoading = true;
      this.$store
        .dispatch("vessel_calls/GET_BY_DAY", this.day)
        .then((data) => {
          this.vesselCalls = data;
          this.vesselCallsLoading = false;
        })
        .catch((ex) => {
          this.vesselCallsLoading = false;
          console.error(ex);
        });
    },
    loadOperators() {
      this.$store.dispatch("operators/GET_APPROVED");
    },
    loadOperatorVehiclesApproved() {
      this.vehiclesLoading = true;
      this.item.vehicle_id = null;
      this.vehicles = [];
      this.$store
        .dispatch("operators/GET_OPERATOR_VEHICLES_APPROVED", {
          operator_id: this.item.operator_id,
        })
        .then((data) => {
          this.vehicles = data;
          this.vehiclesLoading = false;
        })
        .catch((ex) => {
          this.vehiclesLoading = false;
          console.error(ex);
        });
    },
    handleOperatorChange() {
      if (this.item.operator_id) {
        this.loadOperatorVehiclesApproved();
      } else {
        this.vehicles = [];
      }
    },
    save() {
      this.messages = [];
      this.expand = false;
      if (this.$refs.form.validate()) {
        this.saving = true;
        this.$store
          .dispatch("shorex/CREATE_ENTRY_REQUEST", {
            entry: this.item,
            day: this.day,
          })
          .then((data) => {
            if (data.errors) {
              Object.entries(data.errors).forEach(([, value]) => {
                this.messages = this.messages.concat(value);
              });
              this.expand = true;
              this.saving = false;
            } else {
              this.close();
              this.$emit("created", data);
            }
          })
          .catch(() => {
            this.expand = true;
            this.saving = false;
          });
      } else {
        this.expand = false;
        this.saving = false;
      }
    },
    close() {
      this.isOpen = false;
      setTimeout(() => {
        this.expand = false;
        this.saving = false;
      }, 600);
    },
  },
};
</script>
<style>
.new-entry-form-content {
  position: relative;
  min-height: 400px;
  max-height: calc(80vh - var(--toolbar-height));
}
</style>
<style scoped>
.registration-plate-font {
  font-family: "Fira Mono", monospace !important;
}
</style>
