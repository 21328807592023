<template>
  <v-dialog v-model="isOpen" persistent width="900">
    <v-card tile>
      <v-progress-linear
        height="10"
        striped
        color="primary"
        indeterminate
        v-show="loading"
      ></v-progress-linear>
      <v-toolbar color="primary" flat>
        <v-toolbar-title>
          <span>
            <v-icon left>mdi-database-import</v-icon
            >{{ $t("users.import_ldap_users") }}</span
          >
        </v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pa-2">
        <v-alert color="blue-grey" text icon="mdi-information-outline">
          {{ $t("users.import_ldap_users_help") }}
        </v-alert>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('users.search_user')"
          single-line
          hide-details
        ></v-text-field>
        <v-data-table
          dense
          show-select
          :headers="headers"
          :items="users"
          :items-per-page="5"
          :loading="loading"
          item-key="username"
          fixed-header
          sort-by="name"
          :search="search"
          must-sort
        >
          <template v-slot:header.data-table-select>
            <v-checkbox
              v-model="selectAll"
              :indeterminate="indeterminate"
              @change="updateUserSelections()"
            ></v-checkbox>
          </template>
          <template v-slot:item.data-table-select="{ item }">
            <v-checkbox
              v-model="item.selected"
              v-if="!item.imported"
              @change="updateUserSelection(item)"
            ></v-checkbox>
            <v-chip v-else small x-small class="text-uppercase">{{
              $t("users.user_already_exists")
            }}</v-chip>
          </template>
        </v-data-table>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer />
        <v-btn
          class="black--text"
          color="primary"
          depressed
          :disabled="cannotImport"
          @click="importUsers"
        >
          {{ $t("global.import") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import Vue from "vue";
export default {
  props: ["open", "item"],
  data() {
    return {
      selectAll: false,
      loading: false,
      users: [],
      search: "",
      headers: [
        {
          text: this.$t("users.name"),
          value: "name",
        },
        {
          text: this.$t("users.username"),
          value: "username",
        },
        {
          text: this.$t("users.email"),
          value: "email",
        },
      ],
    };
  },
  computed: {
    isOpen: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("update:open", val);
      },
    },
    indeterminate() {
      return (
        this.users.some((u) => u.selected) &&
        this.users.some((u) => !u.selected)
      );
    },
    cannotImport() {
      return this.loading || !this.users.some((u) => u.selected);
    },
  },
  watch: {
    open(val) {
      if (val) {
        this.loadLdapUsers();
      }
    },
  },
  methods: {
    close() {
      this.isOpen = false;
    },
    loadLdapUsers() {
      this.selectAll = false;
      this.users = [];
      this.loading = true;
      this.$store
        .dispatch("users/GET_LDAP_USERS")
        .then((users) => {
          this.loading = false;
          this.users = users;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    updateUserSelections() {
      this.users = this.users.map((user) => {
        if (!user.imported) user.selected = this.selectAll;
        return user;
      });
    },
    updateUserSelection(item) {
      let index = this.users.findIndex(
        (user) => user.username == item.username
      );
      Vue.set(this.users, index, item);
      this.selectAll = this.users.every((u) => u.selected);
    },
    importUsers() {
      this.loading = true;
      let usersToImport = this.users.filter((u) => u.selected);
      this.$store
        .dispatch("users/IMPORT_USERS", { users: usersToImport })
        .then(() => {
          this.loading = false;
          this.close();
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>
