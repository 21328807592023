<template>
  <NoShorexEntries />
</template>

<script>
import NoShorexEntries from "@/components/Configurations/NoShorexEntries.vue";

export default {
  components: {
    NoShorexEntries,
  },
};
</script>
