<template>
  <div class="ma-4">
    <div class="d-flex flex-wrap">
      <div class="ma-0 pa-0">
        <p class="text-h5 font-weight-black mb-0">
          {{ $t("configurations.registered_users") }}
        </p>
        <p class="text-caption">
          {{ $t("configurations.registered_users_explanation") }}
        </p>
      </div>
      <v-spacer></v-spacer>
      <div
        v-if="hasUserPermissionToViewOrManage('REGISTERED_USERS')"
        class="ml-auto"
      >
        <div v-if="$vuetify.breakpoint.mobile">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="black--text"
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
                depressed
              >
                <v-icon> mdi-dots-vertical </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-if="hasUserPermissionToViewEditOrManage('REGISTERED_USERS')"
                @click="add"
              >
                <v-list-item-avatar class="mr-2">
                  <v-icon left>mdi-plus-box-multiple</v-icon>
                </v-list-item-avatar>
                <v-list-item-title>
                  {{ $t("registered_users.new_registered_user") }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="hasUserPermissionToViewEditOrManage('REGISTERED_USERS')"
                @click="importUsers"
              >
                <v-list-item-avatar class="mr-2">
                  <v-icon left> mdi-upload</v-icon>
                </v-list-item-avatar>
                <v-list-item-title>
                  {{ $t("global.import") }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click="exportUsers">
                <v-list-item-avatar class="mr-2">
                  <v-icon left>mdi-download</v-icon>
                </v-list-item-avatar>
                <v-list-item-title>
                  {{ $t("global.export") }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <div v-else>
          <v-btn
            v-if="hasUserPermissionToViewEditOrManage('REGISTERED_USERS')"
            class="black--text"
            color="primary"
            depressed
            @click="add"
          >
            <v-icon left>mdi-plus-box-multiple</v-icon>
            {{ $t("registered_users.new_registered_user") }}
          </v-btn>
          <v-btn
            v-if="hasUserPermissionToViewEditOrManage('REGISTERED_USERS')"
            class="ml-2 black--text"
            color="primary"
            depressed
            @click="importUsers"
          >
            <v-icon left> mdi-upload</v-icon>
            {{ $t("global.import") }}
          </v-btn>
          <v-btn
            class="ml-2 black--text"
            color="primary"
            depressed
            @click="exportUsers"
          >
            <v-icon left>mdi-download</v-icon>
            {{ $t("global.export") }}
          </v-btn>
        </div>
      </div>
    </div>
    <List @remove="remove" @edit="edit" />

    <CreateItemDialog :open.sync="openAddItemDialog" />
    <EditItemDialog :open.sync="openEditItemDialog" :item="selectedItem" />
    <DeleteItemDialog :open.sync="openDeleteItemDialog" :item="selectedItem" />
    <ImportItemsDialog :open.sync="openImportItemsDialog" />
    <ExportItemsDialog :open.sync="openExportItemsDialog" />
  </div>
</template>

<script>
import List from "./RegisteredUsers/List";
import CreateItemDialog from "./RegisteredUsers/CreateItemDialog";
import EditItemDialog from "./RegisteredUsers/EditItemDialog";
import DeleteItemDialog from "./RegisteredUsers/DeleteItemDialog";
import ImportItemsDialog from "./RegisteredUsers/ImportItemsDialog";
import ExportItemsDialog from "./RegisteredUsers/ExportItemsDialog";
export default {
  components: {
    List,
    CreateItemDialog,
    EditItemDialog,
    DeleteItemDialog,
    ImportItemsDialog,
    ExportItemsDialog,
  },
  data() {
    return {
      openAddItemDialog: false,
      openEditItemDialog: false,
      openDeleteItemDialog: false,
      selectedItem: null,
      openImportItemsDialog: false,
      openExportItemsDialog: false,
    };
  },
  methods: {
    add() {
      this.openAddItemDialog = true;
    },
    edit(user) {
      this.selectedItem = user;
      this.openEditItemDialog = true;
    },
    remove(user) {
      this.selectedItem = user;
      this.openDeleteItemDialog = true;
    },
    importUsers() {
      this.openImportItemsDialog = true;
    },
    exportUsers() {
      this.openExportItemsDialog = true;
    },
  },
};
</script>
