import configs from "@/helpers/configs";
import axios from "axios";
import { sharedHelpers } from "@/api_services/utils/shared-helpers";

export const operatorService = {
  getOperators,
  getOperatorsPaginated,
  createOperator,
  updateOperator,
  deleteOperator,
  getOperatorVehiclesApproved,
  importOperators,
  exportOperators,
  getMembers,
  getMember,
  inviteMember,
  downloadFile,
  getApprovedOperators,
  getOperatorById,
  getOperatorLicenses,
  updateOperatorLicenses,
};

async function getOperators() {
  const requestOptions = {
    method: "GET",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.get(configs.getApiUrl() + "/v1/operators", requestOptions);
}

async function getApprovedOperators() {
  const requestOptions = {
    method: "GET",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.get(
    configs.getApiUrl() + "/v1/operators/approved",
    requestOptions
  );
}

async function getOperatorsPaginated(options) {
  let sort = options.sortDesc ? "DESC" : "ASC";
  const requestOptions = {
    method: "GET",
    headers: sharedHelpers.getRequestHeaders(),
    params: {
      itemsPerPage: options.itemsPerPage,
      currentPage: options.page,
      sortBy: options.sortBy,
      sort: sort,
      search: options.search,
      filterByStatus: options.filterByStatus,
      filterByAccredited: options.filterByAccredited,
    },
  };
  return await axios.get(
    configs.getApiUrl() + "/v1/operators/paginated",
    requestOptions
  );
}

async function getOperatorById(operatorId) {
  const requestOptions = {
    method: "GET",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.get(
    `${configs.getApiUrl()}/v1/operators/${operatorId}`,
    requestOptions
  );
}

async function getOperatorVehiclesApproved(operatorId) {
  const requestOptions = {
    method: "GET",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.get(
    `${configs.getApiUrl()}/v1/operators/${operatorId}/vehicles_approved`,
    requestOptions
  );
}

async function createOperator(operator) {
  const formData = new FormData();

  for (const property in operator) {
    if (operator.hasOwnProperty(property)) {
      const value = operator[property] === null ? "" : operator[property];
      formData.append(property, value);
    }
  }

  const requestOptions = {
    method: "POST",
    headers: sharedHelpers.getRequestHeaders(),
  };

  return await axios.post(
    configs.getApiUrl() + "/v1/operators",
    formData,
    requestOptions
  );
}

async function updateOperator(operator) {
  const formData = new FormData();

  for (const property in operator) {
    if (operator.hasOwnProperty(property) && property !== "file") {
      const value = operator[property] === null ? "" : operator[property];
      formData.append(property, value);
    }
  }

  if (operator.file && operator.file.size > 0) {
    formData.append("file", operator.file);
  } else {
    formData.append("file", null);
  }

  const requestOptions = {
    method: "POST",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.post(
    configs.getApiUrl() + "/v1/operators/" + operator.id,
    formData,
    requestOptions
  );
}

async function deleteOperator(operatorId) {
  const requestOptions = {
    method: "POST",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.delete(
    configs.getApiUrl() + "/v1/operators/" + operatorId,
    requestOptions
  );
}

async function importOperators(file) {
  const requestOptions = {
    method: "POST",
    headers: sharedHelpers.getRequestHeaders(),
  };
  var bodyFormData = new FormData();
  bodyFormData.append("file", file);
  return await axios.post(
    configs.getApiUrl() + "/v1/operators/import",
    bodyFormData,
    requestOptions
  );
}

async function exportOperators() {
  const requestOptions = {
    method: "POST",
    responseType: "blob",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.post(
    configs.getApiUrl() + "/v1/operators/export",
    {},
    requestOptions
  );
}

async function downloadFile(licenseId) {
  const requestOptions = {
    method: "GET",
    headers: sharedHelpers.getRequestHeaders(),
    responseType: "blob",
  };
  return await axios.get(
    `${configs.getApiUrl()}/v1/operators/licenses/${licenseId}/download`,
    requestOptions
  );
}

async function getOperatorLicenses(operatorId) {
  const requestOptions = {
    method: "GET",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.get(
    `${configs.getApiUrl()}/v1/operators/${operatorId}/licenses`,
    requestOptions
  );
}

async function updateOperatorLicenses({ operatorId, licenses }) {
  const formData = new FormData();

  if (licenses.length > 0) {
    licenses.forEach((item) => {
      formData.append(
        "licenses[]",
        JSON.stringify({
          type_id: item.type_id,
          license_id: item.id,
          file_number: item.file_number,
          validation_date: item.validation_date,
        })
      );

      // Append file to formData separately
      formData.append(
        "file_" + item.type_id,
        item.file.size > 0 ? item.file : null
      );
    });
  }

  const requestOptions = {
    method: "POST",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.post(
    configs.getApiUrl() + "/v1/operators/" + operatorId + "/licenses",
    formData,
    requestOptions
  );
}

async function getMembers(operatorId) {
  const requestOptions = {
    method: "GET",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.get(
    `${configs.getApiUrl()}/v1/operators/${operatorId}/members`,
    requestOptions
  );
}

async function getMember(operatorId, userId) {
  const requestOptions = {
    method: "GET",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.get(
    `${configs.getApiUrl()}/v1/operators/${operatorId}/members/${userId}`,
    requestOptions
  );
}

async function inviteMember(operatorId, member) {
  const requestOptions = {
    method: "POST",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.post(
    `${configs.getApiUrl()}/v1/operators/${operatorId}/invite_member`,
    member,
    requestOptions
  );
}
