import { usersService } from "@/api_services/users.service";
const DEFAULT_STATE = {
  usersList: [],
  options: {
    search: "",
    currentPage: 1,
    itemsPerPage: 10,
    sortBy: "name",
    sortDesc: false,
  },
  total: 0
};

export default {
  namespaced: true,
  state: Object.assign({}, JSON.parse(JSON.stringify(DEFAULT_STATE))),
  getters: {},
  mutations: {
    setOptions(state, options) {
      state.options = options;
    },
    setTotal(state, total) {
      state.total = total;
    },
    setUsers(state, users) {
      state.usersList = users;
    },
    setDefaultState(state) {
      Object.assign(state, JSON.parse(JSON.stringify(DEFAULT_STATE)));
    },
  },
  actions: {
    UPDATE_OPTIONS(context, options) {
      context.commit("setOptions", options);
    },
    GET_USERS: (context, options) => {
      context.commit("setUsers", []);
      return new Promise((resolve, reject) => {
        if (options) {
          context.commit("setOptions", options);
        }
        usersService
          .getUsers(context.state.options)
          .then((res) => {
            context.commit("setUsers", res.data.data);
            context.commit("setTotal", res.data.total);
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    GET_USER: (_, userId) => {
      return new Promise((resolve, reject) => {
        usersService
          .getUser(userId)
          .then((res) => {
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    CREATE_USER: (context, user) => {
      return new Promise((resolve, reject) => {
        usersService
          .createUser(user)
          .then((res) => {
            resolve(res.data);
            context.dispatch("GET_USERS");
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    UPDATE_USER: (context, user) => {
      return new Promise((resolve, reject) => {
        usersService
          .updateUser(user)
          .then((res) => {
            resolve(res.data);
            context.dispatch("GET_USERS");
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    UPDATE_USER_PERMISSIONS: (context, { user, permissions }) => {
      return new Promise((resolve, reject) => {
        usersService
          .updateUserPermissions(user, permissions)
          .then((res) => {
            resolve(res.data);
            context.dispatch("GET_USERS");
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    DELETE_USER: (context, userId) => {
      return new Promise((resolve, reject) => {
        usersService
          .deleteUser(userId)
          .then((res) => {
            resolve(res.data);
            context.dispatch("GET_USERS");
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    GET_LDAP_USERS: () => {
      return new Promise((resolve, reject) => {
        usersService
          .getLdapUsers()
          .then((res) => {
            resolve(res.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },

    IMPORT_USERS: (context, users) => {
      return new Promise((resolve, reject) => {
        usersService
          .importLdapUsers(users)
          .then(() => {
            context.dispatch("GET_USERS");
            resolve();
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },
    
    RESET: (context) => {
      context.commit("setDefaultState");
    },
  },
};
