<template>
  <VehicleTypes />
</template>

<script>
import VehicleTypes from "@/components/Configurations/VehicleTypes";

export default {
  components: {
    VehicleTypes,
  },
};
</script>
