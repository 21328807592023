<template>
  <v-form
    ref="form"
    @submit.prevent="submit"
    lazy-validation
    v-model="valid"
    class="loginForm"
  >
    <v-row>
      <v-col cols="12" md="6">
        <h2
          class="my-1 mb-5 text-center font-weight-bold"
          color="blue-darken-1"
        >
          Dados do Utilizador
        </h2>

        <v-text-field
          :label="$t('users.register_name')"
          name="nome"
          v-model="user.name"
          type="text"
          outlined
          autocomplete="off"
          :rules="basicRules"
          counter="50"
          maxlength="50"
        ></v-text-field>

        <v-text-field
          :label="$t('users.register_email')"
          name="email"
          v-model="user.email"
          type="email"
          outlined
          autocomplete="off"
          :rules="emailRules"
          counter="50"
          maxlength="50"
        ></v-text-field>

        <v-text-field
          :label="$t('registered_users.phone')"
          name="phone"
          v-model="user.phone"
          type="text"
          outlined
          autocomplete="off"
          :rules="phoneNumberRules"
          counter="25"
          maxlength="25"
        ></v-text-field>

        <v-text-field
          :label="$t('login.password')"
          name="password"
          v-model="user.password"
          :type="showPassword ? 'text' : 'password'"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showPassword = !showPassword"
          :disabled="loading"
          outlined
          @focus="handleType"
          @blur="handleType"
          autocomplete="new-password"
          :rules="passwordRules"
          counter="25"
          maxlength="25"
        ></v-text-field>

        <v-text-field
          :label="$t('login.confirm_password')"
          name="password-verify"
          v-model="user.passwordVerify"
          :type="showPasswordVerify ? 'text' : 'password'"
          :append-icon="showPasswordVerify ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showPasswordVerify = !showPasswordVerify"
          :disabled="loading"
          outlined
          :rules="passwordVerifyRules"
          counter="25"
          maxlength="25"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <h2
          class="my-1 mb-5 text-center font-weight-bold"
          color="blue-darken-1"
        >
          Dados do Operador/Empresa
        </h2>

        <v-text-field
          :label="$t('operators.register_name')"
          name="operator_name"
          v-model="operator.name"
          type="text"
          outlined
          autocomplete="off"
          :rules="basicRules"
          counter="50"
          maxlength="50"
        ></v-text-field>

        <v-text-field
          :label="$t('global.vat')"
          name="operator_vat"
          v-model="operator.vatNumber"
          type="text"
          :disabled="loading"
          counter="9"
          maxlength="9"
          outlined
          :rules="vatRules"
        ></v-text-field>

        <v-text-field
          :label="$t('operators.register_email')"
          name="operator_email"
          v-model="operator.email"
          type="email"
          outlined
          autocomplete="off"
          :rules="emailRules"
          counter="50"
          maxlength="50"
        ></v-text-field>

        <v-text-field
          :label="$t('operators.phone')"
          name="operator_phone"
          v-model="operator.phone"
          type="text"
          outlined
          autocomplete="off"
          :rules="phoneNumberRules"
          counter="25"
          maxlength="25"
        ></v-text-field>

        <v-select
          :items="locodes"
          item-text="name"
          item-value="code"
          :label="$t('global.port')"
          name="port-locode"
          v-model="locode"
          outlined
          :disabled="loading"
          :rules="basicRules"
        >
          <template v-slot:item="{ item }">
            <v-list-item-content>
              {{ item.name }}
            </v-list-item-content>
            <v-list-item-action>
              <v-chip label small outlined class="text-uppercase">{{
                item.code
              }}</v-chip>
            </v-list-item-action>
          </template>
          <template v-slot:selection="{ item }">
            {{ item.name }} ({{ item.code }})
          </template>
        </v-select>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" class="mb-3">
        <v-expansion-panels
          :class="warning ? 'mt-0 pt-0 warning-border' : 'mt-0 pt-0'"
          v-model="opTypes"
          multiple
        >
          <v-expansion-panel v-for="item in operatorTypes" :key="item.id">
            <v-expansion-panel-header
              @click="item.expanded = !item.expanded"
              expand-icon
            >
              <v-btn
                text
                :ripple="false"
                plain
                small
                class="mr-2"
                style="max-width: 10px !important"
              >
                <v-icon v-if="item.expanded" color="green"
                  >mdi-checkbox-marked</v-icon
                >
                <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
              </v-btn>
              <span class="text-center">{{ item.name }} </span>
            </v-expansion-panel-header>
            <v-expansion-panel-content v-if="item.expanded">
              <v-row>
                <v-col cols="4">
                  <v-text-field
                    :label="$t('operators.file_number')"
                    v-model="item.file_number"
                    type="text"
                    outlined
                    autocomplete="off"
                    :rules="basicRules"
                    counter="25"
                    maxlength="25"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-menu
                    v-model="item.dateMenu"
                    :close-on-content-click="false"
                    transition="slide-y-transition"
                    min-width="auto"
                    :offset-y="true"
                    top
                    class="ma-0 pa-0"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="item.validation_date"
                        :label="$t('operators.validation_date')"
                        append-icon="mdi-calendar"
                        outlined
                        v-bind="attrs"
                        v-on="on"
                        :rules="validationDateRules"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="item.validation_date"
                      @input="item.dateMenu = false"
                      :allowed-dates="disablePastDates"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="4">
                  <div class="ma-0 pa-0">
                    <v-file-input
                      v-model="item.file"
                      outlined
                      accept="application/pdf"
                      :label="$t('operators.file')"
                      append-icon="mdi-file"
                      clear-icon
                      prepend-icon=""
                      :rules="fileRules"
                    ></v-file-input>
                  </div>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <span v-if="warning" class="warning-text">{{
          $t("operators.operator_type_selector_rule")
        }}</span>
      </v-col>
    </v-row>

    <v-alert v-show="signupFailed" color="red" dark style="font-size: 1rem">
      {{ (error && error.msg) || $t("login.register_failed_message") }}</v-alert
    >

    <v-btn
      depressed
      type="submit"
      :loading="loading"
      :disabled="loading || !valid || warning"
      color="primary"
      class="black--text mb-4"
      block
      large
    >
      {{ $t("login.create_account_btn") }}
    </v-btn>

    <v-btn
      text
      :disabled="loading"
      color="grey darken-2"
      exact
      @click="goToLogin"
      large
      block
    >
      {{ $t("login.return_to_login_btn") }}
    </v-btn>
  </v-form>
</template>

<script>
import configs from "@/helpers/configs";
import { phoneNumberValidation } from "@/helpers/common_validations.js";
export default {
  data() {
    return {
      warning: false,
      opTypes: [],
      operatorTypes: [],
      user: {
        name: null,
        email: null,
        phone: null,
        passwordVerify: null,
        password: null,
      },
      operator: {
        name: null,
        email: null,
        phone: null,
        vatNumber: null,
        type: null,
        file: null,
      },
      valid: false,
      loading: false,
      signupFailed: false,
      error: null,
      locode: "PTFNC",
      showPasswordVerify: false,
      showPassword: false,
      basicRules: [(v) => !!v || this.$t("rules.required_field")],
      vatRules: [
        (v) => !!v || this.$t("rules.required_field"),
        (v) => (v && /^\d{9}$/.test(v)) || this.$t("rules.vat_digit_number"),
      ],
      phoneNumberRules: [
        (v) => !!v || this.$t("rules.required_field"),
        phoneNumberValidation,
      ],
      passwordRules: [
        (value) => !!value || this.$t("rules.required_field"),
        (value) =>
          (value && value.length >= 6) || this.$t("rules.password_min_len"),
      ],
      emailRules: [
        (v) => !!v || this.$t("rules.required_field"),
        (v) => (v && v.length <= 50) || this.$t("rules.character_limit"),
        (v) =>
          !v ||
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          this.$t("rules.email_invalid"),
      ],
      fileRules: [(v) => !!v || this.$t("rules.file_required")],
      fieldTypes: {
        password: "text",
      },
      validationDateRules: [
        (v) => !!v || this.$t("rules.validation_date_required"),
      ],
    };
  },
  computed: {
    locodes() {
      return configs.getLocodes().sort((a, b) => {
        if (a.name.trim().toLowerCase() < b.name.trim().toLowerCase()) {
          return -1;
        }

        if (a.name.trim().toLowerCase() > b.name.trim().toLowerCase()) {
          return 1;
        }

        return 0;
      });
    },
    passwordVerifyRules() {
      return [
        (value) => !!value || this.$t("rules.required_field"),
        (value) =>
          (value && value.length >= 6) || this.$t("rules.password_min_len"),
        this.password === this.passwordVerify ||
          this.$t("rules.password_missmatch"),
      ];
    },
  },
  watch: {
    locode() {
      this.getOperatorTypes();
    },
  },
  created() {
    this.getOperatorTypes();
  },
  methods: {
    disablePastDates(date) {
      return date >= new Date().toISOString().substring(0, 10);
    },
    getOperatorTypes() {
      this.$store
        .dispatch("operator_types/GET_PUBLIC_OPERATOR_TYPES", this.locode)
        .then((data) => {
          this.operatorTypes = data.map((item) => {
            return {
              ...item,
              expanded: false,
              file: null,
              file_number: null,
              validation_date: null,
              dateMenu: false,
            };
          });
        });
    },
    validatePhoneNumber() {
      // Validate the phone number as the user types
      const digitsOnly = this.phoneNumber.replace(/\D/g, ""); // Remove non-digit characters
      if (digitsOnly.length > 8) {
        this.$refs.form.setErrors({
          phoneNumber: ["Phone number must have at most 8 digits."],
        });
      } else {
        this.$refs.form.reset();
      }
    },
    handleType(event) {
      const { srcElement, type } = event;
      const { name, value } = srcElement;

      if (type === "blur" && !value) {
        this.fieldTypes[name] = "text";
      } else {
        this.fieldTypes[name] = "password";
      }
    },
    goToLogin() {
      this.$router.push("/login");
    },
    submit() {
      this.warning = !this.operatorTypes.some((opType) => opType.expanded);
      if (
        this.$refs.form.validate() &&
        !this.$refs.form.hasError &&
        !this.warning
      ) {
        this.loading = true;

        this.$store
          .dispatch("SIGNUP", {
            user: this.user,
            operator: this.operator,
            locode: this.locode,
            operatorTypes: this.operatorTypes
              .filter((item) => item.expanded)
              .map((item) => {
                return {
                  code: item.code,
                  file_number: item.file_number,
                  validation_date: item.validation_date,
                  file: item.file,
                };
              }),
          })
          .then((res) => {
            if (res) {
              if (res.error) {
                this.signupFailed = true;
                this.error = res.error;
                this.loading = false;
                return;
              }

              this.$emit("success");
            }
          })
          .catch(() => {
            this.errorResetPasswordStatus = true;
            this.errorResetPasswordMsg = this.$t(
              "login.email_validation_failed_message"
            );
            this.loading = false;
          });
      }
    },
  },
};
</script>
<style>
.loginForm
  .v-input--selection-controls.v-input--is-disabled:not(.v-input--is-readonly) {
  margin-bottom: 13px !important;
}
</style>

<style scoped>
.warning-border {
  border: 2px solid #b71c1c;
  border-radius: 5px;
}

.warning-text {
  color: #b71c1c;
  font-size: 12px;
  margin: 10px;
}
</style>
