<template>
  <v-dialog v-model="isOpen" persistent width="30%">
    <v-card tile>
      <v-toolbar color="primary" flat>
        <v-toolbar-title v-if="!loading">
          <span> <v-icon left>mdi-ferry</v-icon>{{ item.name }} </span>
        </v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pa-2">
        <v-card outlined min-height="300px">
          <div v-if="loading">
            <Loading
              :value="loading"
              :custom-message="$t('vessel_calls.loading_info')"
            ></Loading>
          </div>
          <div v-else>
            <div class="primary text-center pa-1">
              <span class="black--text font-weight-bold text-uppercase"
                >Informação</span
              >
            </div>
            <v-simple-table dense>
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td class="font-weight-bold">
                      {{ $t("vessel_calls.process_number") }}
                    </td>
                    <td class="text-right">
                      {{ item.process_number }}
                    </td>
                  </tr>
                  <tr>
                    <td class="font-weight-bold">
                      {{ $t("vessel_calls.expected_arrival") }}
                    </td>
                    <td class="text-right">
                      {{ item.eta | formatDatetimeWithoutTimezone }}
                    </td>
                  </tr>
                  <tr>
                    <td class="font-weight-bold">
                      {{ $t("vessel_calls.expected_departure") }}
                    </td>
                    <td class="text-right">
                      {{ item.etd | formatDatetimeWithoutTimezone }}
                    </td>
                  </tr>
                  <tr>
                    <td class="font-weight-bold">
                      {{ $t("vessel_calls.beam") }}
                    </td>
                    <td class="text-right">
                      {{ item.beam }}
                    </td>
                  </tr>
                  <tr>
                    <td class="font-weight-bold">
                      {{ $t("vessel_calls.imo") }}
                    </td>
                    <td class="text-right">
                      {{ item.imo }}
                    </td>
                  </tr>
                  <tr>
                    <td class="font-weight-bold">
                      {{ $t("vessel_calls.lbp") }}
                    </td>
                    <td class="text-right">
                      {{ item.lbp }}
                    </td>
                  </tr>
                  <tr>
                    <td class="font-weight-bold">
                      {{ $t("vessel_calls.loa") }}
                    </td>
                    <td class="text-right">
                      {{ item.loa }}
                    </td>
                  </tr>

                  <tr>
                    <td class="font-weight-bold">
                      {{ $t("vessel_calls.berth_location") }}
                    </td>
                    <td class="text-right">
                      {{ item.berth_location }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
        </v-card>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Loading from "@/components/Main/Loading";

export default {
  props: ["open", "item", "loading"],
  components: {
    Loading,
  },
  data() {
    return {};
  },
  computed: {
    isOpen: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("update:open", val);
      },
    },
  },
  methods: {
    close() {
      this.isOpen = false;
    },
  },
};
</script>
