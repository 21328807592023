<template>
  <div>
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          :value="date"
          :label="label"
          :dense="dense"
          :outlined="outlined"
          readonly
          v-bind="attrs"
          v-on="on"
          :prepend-inner-icon="prependInnerIcon"
          type="date"
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="val"
        @input="save"
        :min="min"
        :max="max"
        :active-picker.sync="activePicker"
      ></v-date-picker>
    </v-menu>
  </div>
</template>
<script>
import configs from "@/helpers/configs";
import { Settings, DateTime } from "luxon";
Settings.defaultZone = configs.getTimezone();
Settings.defaultLocale = configs.getLocale();

export default {
  props: [
    "value",
    "clearable",
    "label",
    "rules",
    "outlined",
    "dense",
    "prepend-inner-icon",
    "min",
    "max",
  ],
  data() {
    return {
      activePicker: "YEAR",
      errorMessages: [],
      hint: "",
      menu: false,
      date: "",
    };
  },
  computed: {
    val: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("update:value", v);
      },
    },
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
  },
  methods: {
    save(date) {
      this.$nextTick(() => {
        this.$refs.menu.save(date);
        this.date = date;
      });
    },
    isDateValid(d) {
      try {
        let date = DateTime.fromFormat(d, "yyyy-LL-dd");
        return date.isValid && date.year >= 0 && date.year <= 9999;
      } catch (e) {}
      return false;
    },
  },
};
</script>
