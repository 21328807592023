var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":200,"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-badge',{attrs:{"overlap":"","color":"green","value":_vm.nFilters,"content":_vm.nFilters}},[_c('v-btn',_vm._g(_vm._b({staticStyle:{"top":"-6px"},attrs:{"icon":"","text":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-tune-vertical-variant")])],1)],1)]}}]),model:{value:(_vm.openMenu),callback:function ($$v) {_vm.openMenu=$$v},expression:"openMenu"}},[_c('v-card',{attrs:{"width":"300"}},[_c('v-card-title',[_vm._v(_vm._s(_vm.$t("vehicles.filter_by"))+":")]),_c('v-card-text',[_c('v-select',{attrs:{"required":"","clearable":"","label":_vm.$t('operators.status'),"placeholder":_vm.$t('vehicles.status'),"item-value":"code","items":_vm.operatorsStatuses,"prepend-inner-icon":"mdi-list-status","outlined":"","hide-details":""},on:{"change":_vm.filterItems},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.displayText)+" ")]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.displayText)+" ")]}}]),model:{value:(_vm.options.filterByStatus),callback:function ($$v) {_vm.$set(_vm.options, "filterByStatus", $$v)},expression:"options.filterByStatus"}})],1),(_vm.extra)?_c('v-checkbox',{staticClass:"ml-4",attrs:{"label":_vm.$t('operators.accredited_operator')},on:{"change":_vm.filterItems},model:{value:(_vm.options.filterByAccredited),callback:function ($$v) {_vm.$set(_vm.options, "filterByAccredited", $$v)},expression:"options.filterByAccredited"}}):_vm._e(),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.resetFilters}},[_vm._v(_vm._s(_vm.$t("global.reset")))]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.openMenu = false}}},[_vm._v(" "+_vm._s(_vm.$t("global.close"))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }