<template>
  <v-data-table
    class="all-users-table"
    :footer-props="{
      showFirstLastPage: true,
      firstIcon: 'mdi-page-first',
      lastIcon: 'mdi-page-last',
      'items-per-page-options': [5, 10, 25, 50],
    }"
    :loading="loading"
    :search.sync="options.search"
    :page.sync="options.page"
    :headers="headers"
    :items="items"
    :items-per-page.sync="options.itemsPerPage"
    :server-items-length="totalItemCount"
    @update:options="handleOptionsUpdate('options', $event)"
    item-key="id"
    fixed-header
    :sort-by.sync="options.sortBy"
    :sort-desc.sync="options.sortDesc"
    must-sort
  >
    <template v-slot:top>
      <v-text-field
        v-model="options.search"
        class="mb-4"
        single-line
        :label="$t('users.search')"
        prepend-inner-icon="mdi-magnify"
        outlined
        hide-details
        @input="filterItems"
      />
    </template>
    <template
      v-if="hasUserPermissionToViewEditOrManage('USERS')"
      v-slot:[`item.actions`]="{ item }"
    >
      <v-menu bottom absolute>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item @click="permissions(item)">
            <v-list-item-icon class="mr-2">
              <v-icon>mdi-lock</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{
                $t("users.permissions")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="edit(item)">
            <v-list-item-icon class="mr-2">
              <v-icon>mdi-pencil</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t("global.edit") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="hasUserPermissionToManage('USERS')" @click="remove(item)">
            <v-list-item-icon class="mr-2">
              <v-icon>mdi-delete</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t("global.remove") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
    <template v-slot:[`item.locodes`]="{ item }">
      <div v-if="!!item.locodes">
        <v-chip
          label
          outlined
          small
          class="mr-1 text-uppercase"
          v-for="locode in item.locodes"
          :key="locode"
        >
          {{ locode }}
        </v-chip>
      </div>
    </template>
    <template v-slot:[`item.is_ldap`]="{ item }">
      <v-chip
        v-if="item.is_ldap"
        label
        outlined
        small
        class="mr-1 text-uppercase"
        color="secondary darken-2"
        >{{ $t("users.ldap_user") }}</v-chip
      >
      <v-chip
        v-else
        label
        outlined
        small
        class="mr-1 text-uppercase"
        color="primary darken-2"
        >{{ $t("users.local_user") }}</v-chip
      >
    </template>
    <template v-slot:item.updated_at="{ item }">
      {{ item.updated_at | formatDatetime }}
    </template>
  </v-data-table>
</template>

<script>
import { debounce } from "debounce";

export default {
  data() {
    return {
      loading: false,
      headers: [
        {
          text: "",
          value: "actions",
          sortable: false,
          width: "30px",
        },
        {
          text: this.$t("users.name"),
          value: "name",
        },
        {
          text: this.$t("users.email"),
          value: "email",
        },
        {
          text: this.$t("users.authentication_type"),
          value: "is_ldap",
        },
        {
          text: this.$t("users.locodes"),
          value: "locodes",
        },
        {
          text: this.$t("global.created_by"),
          value: "creator_name",
          class: "font-weight-bold",
          align: "start",
          sortable: true,
          width: "100px",
        },
        {
          text: this.$t("global.updated_by"),
          value: "updater_name",
          class: "font-weight-bold",
          align: "start",
          sortable: true,
          width: "100px",
        },
        {
          text: this.$t("global.updated_at"),
          value: "updated_at",
          class: "font-weight-bold",
          align: "start",
          sortable: true,
          width: "150px",
        },
      ],
    };
  },
  computed: {
    items() {
      return this.$store.state.users.usersList;
    },
    options: {
      cache: false,
      get() {
        return this.$store.state.users.options;
      },
      set(val) {
        this.$store.dispatch("users/UPDATE_OPTIONS", val);
      },
    },
    totalItemCount() {
      return this.$store.state.users.total;
    },
  },
  mounted() {
    //this.loadUsers();
  },
  methods: {
    loadUsers() {
      this.loading = true;
      this.$store
        .dispatch("users/GET_USERS")
        .then(() => {
          this.loading = false;
        })
        .catch((ex) => {
          console.error("Error loading users", ex);
          this.loading = false;
        });
    },

    filterItems: debounce(function () {
      // Changes the current page to the first one to reset the search stop bugs from happening
      if (this.options.currentPage == 1) {
        this.loadUsers();
      } else {
        this.options.page = 1;
      }
    }, 500),

    handleOptionsUpdate: debounce(function () {
      this.loadUsers();
    }, 500),

    permissions(item) {
      this.$emit("permissions", item);
    },

    edit(item) {
      this.$emit("edit", item);
    },

    remove(item) {
      this.$emit("remove", item);
    },
  },
};
</script>

<style>
.all-users-table .v-data-table__wrapper {
  max-height: calc(100vh - 290px);
}
</style>
