<template>
  <v-card outlined>
    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title class="text-h5 font-weight-black">{{
          data.title
        }}</v-list-item-title>
        <v-list-item-subtitle class="font-weight-medium">{{
          $t("stats.entry_exit_hours")
        }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-card-text>
      <div id="chart-container" ref="chart" class="chart"></div>
    </v-card-text>
  </v-card>
</template>

<script>
import Plotly from "plotly.js-dist";

export default {
  props: ["data"],
  data() {
    return {
      loading: false,
      chartData: [
        {
          x: [],
          y: [],
          type: "bar",
          name: this.$t("stats.entry_hours"),
          marker: {
            color: "#4CAF50",
          },
        },
        {
          x: [],
          y: [],
          type: "bar",
          name: this.$t("stats.exit_hours"),
          marker: {
            color: "#F44336",
          },
        },
      ],
      layout: {
        barmode: "stack",
        title: "",
        autosize: true,
        xaxis: {
          type: "category",
          title: {
            text: "Horário",
            font: {
              size: 12,
              color: "#7f7f7f",
            },
          },
        },
        yaxis: {
          autorange: true,
          type: "linear",
        },
        autosize: true,
        legend: {
          x: 1,
          xanchor: "right",
          y: 1,
        },
        margin: {
          l: 15,
          r: 0,
          b: 40,
          t: 0,
        },
      },
      config: {
        displayModeBar: false,
        responsive: true,
      },
    };
  },
  watch: {
    resize() {
      this.handleResize();
    },
  },
  computed: {},
  mounted() {
    if (Object.keys(this.data.entryHours).length != 0) {
      this.chartData[0].x = Object.keys(this.data.entryHours);
      this.chartData[0].y = Object.values(this.data.entryHours);
    }
    if (Object.keys(this.data.exitHours).length != 0) {
      this.chartData[1].x = Object.keys(this.data.exitHours);
      this.chartData[1].y = Object.values(this.data.exitHours);
    }
    this.initializeChart();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resizeChart);
  },
  methods: {
    initializeChart() {
      this.$nextTick(() => {
        if (this.$refs.chart) {
          Plotly.newPlot(
            this.$refs.chart,
            this.chartData,
            this.layout,
            this.config
          );
        }
      });
    },

    resizeChart() {
      const chartContainer = document.getElementById("chart-container");
      Plotly.relayout(chartContainer, {});
    },
  },
};
</script>
<style scoped>
#chart-container {
  margin-top: 10px;
  width: 100%;
  height: 225px;
}
</style>
