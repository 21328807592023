<template>
  <div style="height: 100%">
    <EntryRequestDialog :open.sync="openEntryRequestDialog" :item="selectedEntryRequest" v-if="openEntryRequestDialog"
      :key="componentEntryRequest" />

    <PassengersDialog :open.sync="openPassengersDialog" :entry="selectedEntryRequest" v-if="openPassengersDialog"
      :key="componentPassengers" />

    <NewEntryRequestDialog :open.sync="openNewEntryRequestDialog" :day="day" :plan="plan"
      @created="handleUpdateScheduling">
    </NewEntryRequestDialog>

    <ImportEntryRequestsDialog :open.sync="openImportEntryRequestsDialog" :day="day" :plan="plan"
      @created="handleUpdateScheduling">
    </ImportEntryRequestsDialog>

    <v-card class="pa-1" elevation="1" style="height: 100%">
      <v-card-text class="pa-0" style="height: calc(100% - 50px)">
        <v-data-table class="entriesList" :headers="headers" :items.sync="entries" item-key="id"
          :custom-filter="customSearch" :search="search" hide-default-footer disable-pagination fixed-header must-sort
          :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :group-by="groupBy"
          :loading="loading || zonesLoading || sectorsLoading || saving" :show-select="plan && plan.status === 'OPENED'"
          v-model="selectedEntries">
          <template v-slot:top>
            <v-row no-gutters style="border-bottom: thin solid rgba(0, 0, 0, 0.12)" class="mt-1 pb-3" justify="end">
              <v-col cols="6" class="pl-1">
                <p class="text-h5 pa-0 ma-0 mt-1 font-weight-bold">
                  {{ $t("entries.entries") }} ({{ entries.length }})
                </p>
              </v-col>
              <v-col cols="6" class="pr-1" align="right">
                <v-row no-gutters justify="end">
                  <v-col cols="6" align="right"><v-btn color="primary" class="black--text mr-1" outlined dense
                      @click.stop="getScheduling(null)" :disabled="loading"
                      height="40px"><v-icon>mdi-refresh</v-icon></v-btn></v-col>
                  <v-col cols="6">
                    <v-select v-model="groupBy" class="mr-1" :items="groups" outlined :label="$t('global.group_by')"
                      dense clearable hide-details :disabled="loading || zonesLoading || sectorsLoading || saving
      ">
                      <template slot="selection" slot-scope="data">
                        {{ data.item.name }}
                      </template>
                      <template slot="item" slot-scope="data">
                        {{ data.item.name }}
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row no-gutters class="pa-2 grey lighten-3"><span class="text-center text-uppercase font-weight-bold"
                style="width: 100%">
                {{ $t("entry_requests.all_entry_requests") }}
              </span></v-row>
          </template>

          <template v-slot:group.header="{ group, items, headers, toggle, isOpen }">
            <td :colspan="headers.length">
              <v-btn @click="toggle" fab tile x-small elevation="0" :ref="group" class="ml-1 mr-2"
                style="margin-top: -15px">
                <v-icon v-if="isOpen">mdi-minus</v-icon>
                <v-icon v-else>mdi-plus</v-icon>
              </v-btn>

              <v-checkbox v-if="groupBy == 'status'" :indeterminate="items.some((item) => item.isSelectable && item.selected) &&
      items.some((item) => item.isSelectable && !item.selected)
      " :disabled="!items.some((item) => item.isSelectable) ||
      !hasUserPermissionToViewEditOrManage('PLANS')
      " v-model="selectedGroupEntries[group]" style="display: inline-block" class="ma-0 pa-0 mt-2t" hide-details
                @change="
      handleSelectedGroupEntries(group, selectedGroupEntries[group])
      " :label="`${getStatusLabel(group)} (${items.length})`"></v-checkbox>

              <v-checkbox v-else-if="groupBy == 'entry_type'" :indeterminate="items.some((item) => item.isSelectable && item.selected) &&
      items.some((item) => item.isSelectable && !item.selected)
      " :disabled="!items.some((item) => item.isSelectable) ||
      !hasUserPermissionToViewEditOrManage('PLANS')
      " v-model="selectedGroupEntries[group]" style="display: inline-block" class="ma-0 pa-0 mt-2t" hide-details
                @change="
      handleSelectedGroupEntries(group, selectedGroupEntries[group])
      " :label="`${getTypeLabel(group)} (${items.length})`"></v-checkbox>

              <v-checkbox v-else :indeterminate="items.some((item) => item.isSelectable && item.selected) &&
      items.some((item) => item.isSelectable && !item.selected)
      " :disabled="!items.some((item) => item.isSelectable) ||
      !hasUserPermissionToViewEditOrManage('PLANS')
      " v-model="selectedGroupEntries[group]" style="display: inline-block" class="ma-0 pa-0 mt-2" hide-details
                @change="
      handleSelectedGroupEntries(group, selectedGroupEntries[group])
      " :label="`${group} (${items.length})`"></v-checkbox>
            </td>
          </template>

          <template v-slot:header.data-table-select>
            <v-checkbox v-model="selectAll" :indeterminate="indeterminate" hide-details
              @change="handleToggleAll(selectAll)" class="shrink mr-0 mt-0 ml-2" :disabled="disabled || !hasUserPermissionToViewEditOrManage('PLANS')
      " v-show="!groupBy && !disabled"></v-checkbox>
          </template>

          <template v-slot:item.data-table-select="{ item }">
            <v-checkbox class="shrink mr-0 mt-0 ml-2" v-model="item.selected" hide-details
              @change="updateEntries($event, item)" :disabled="!item.isSelectable ||
      (plan && plan.status === 'ARCHIVED') ||
      isOutdated() ||
      !hasUserPermissionToViewEditOrManage('PLANS')
      " v-show="item.isSelectable"></v-checkbox>
          </template>

          <template v-slot:item.map="{ item }">
            <v-btn color="grey" outlined fab x-small @click="goTo(item)"
              :disabled="item.zone_id == null && item.sector_id == null">
              <v-icon dark> mdi-map </v-icon>
            </v-btn>
          </template>

          <template v-slot:item.vehicle="{ item }">
            <span class="registration-plate-font">
              {{ item.vehicle.registration_plate }}</span>
          </template>

          <template v-slot:item.vehicle.operator.name="{ item }">
            <v-list-item class="ma-0 pa-0">
              <v-list-item-content class="ma-0 pa-0">
                <v-list-item-title>
                  <div class="text-truncate" style="max-width: 200px">
                    {{
      item.vehicle && item.vehicle.operator
        ? item.vehicle.operator.name
        : ""
    }}
                    <v-tooltip bottom dark>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-if="item.vehicle.operator.accredited" v-bind="attrs" v-on="on" x-small
                          color="blue">mdi-check-decagram
                        </v-icon> </template><span>{{ $t("operators.accredited_operator") }}</span>
                    </v-tooltip>
                  </div>
                </v-list-item-title>
                <v-list-item-subtitle>
                  Matrícula:
                  <span class="registration-plate-font">
                    {{ item.vehicle.registration_plate }}</span>
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  Nº de Porta:
                  <span class="registration-plate-font">
                    {{
      item.vehicle.license_number
        ? item.vehicle.license_number
        : "N/D"
    }}</span>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>

          <template v-slot:item.sector_id="{ item }">
            <SectorSelect v-model="item.sector_id" dense single-line hide-details :key="sectorInputsKey" :disabled="loading ||
      zonesLoading ||
      sectorsLoading ||
      saving ||
      item.status != 'PENDING' ||
      authorizing ||
      unauthorizing ||
      (plan && plan.status === 'ARCHIVED') ||
      isOutdated() ||
      !hasUserPermissionToViewEditOrManage('PLANS')
      "></SectorSelect>
          </template>

          <template v-slot:item.zone_id="{ item }">
            <ZoneSelect v-model="item.zone_id" dense single-line hide-details :key="zoneInputKey"
              :sector_id="item.sector_id" :disabled="loading ||
      zonesLoading ||
      sectorsLoading ||
      saving ||
      item.status != 'PENDING' ||
      authorizing ||
      unauthorizing ||
      (plan && plan.status === 'ARCHIVED') ||
      isOutdated() ||
      !hasUserPermissionToViewEditOrManage('PLANS')
      "></ZoneSelect>
          </template>

          <template v-slot:item.vehicle_at_pier_date="{ item }">
            {{ item.vehicle_at_pier_date | formatDatetimeFromDatabaseOnlyTime }}
          </template>

          <template v-slot:item.vehicle_at_pier_start="{ item }">
            <v-text-field v-model="item.vehicle_at_pier_start_time" type="time" @input="updateStartTime(item)" dense
              hide-details outlined height="20" :disabled="loading ||
      saving ||
      item.status != 'PENDING' ||
      authorizing ||
      unauthorizing ||
      (plan && plan.status === 'ARCHIVED') ||
      isOutdated() ||
      !hasUserPermissionToViewEditOrManage('PLANS')
      "></v-text-field>
          </template>

          <template v-slot:item.vehicle_at_pier_end="{ item }">
            <v-text-field height="10" v-model="item.vehicle_at_pier_end_time" type="time" @input="updateEndTime(item)"
              dense hide-details outlined :disabled="loading ||
      saving ||
      item.status != 'PENDING' ||
      authorizing ||
      unauthorizing ||
      (plan && plan.status === 'ARCHIVED') ||
      isOutdated() ||
      !hasUserPermissionToViewEditOrManage('PLANS')
      "></v-text-field>
          </template>

          <template v-slot:item.status="{ item }">
            <v-chip class="text-uppercase" small label :color="getStatusColor(item.status)" dark>
              {{ getStatusLabel(item.status) }}
            </v-chip>
          </template>

          <template v-slot:item.code="{ item }">
            <v-list-item two-line class="ma-o pa-0">
              <v-list-item-content>
                <v-list-item-title class="text-button">{{
      item.code
    }}</v-list-item-title>
                <v-list-item-subtitle>
                  <v-btn color="primary" class="black--text text-left" depressed small
                    @click="showEntryRequestDetail(item)" width="110px" :disabled="authorizing ||
      unauthorizing ||
      item.vehicle_at_pier_date == null
      ">
                    <v-icon small dark class="mr-1">{{
      item.vehicle.vehicle_type
        ? item.vehicle.vehicle_type.icon
        : "mdi-car"
    }}</v-icon>
                    {{
        types.find((type) => type.code == item.entry_type)
          ? types.find((type) => type.code == item.entry_type)
            .value
          : ""
      }}</v-btn></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-btn class="mx-2" color="green" outlined fab x-small @click="emitAuthorization(item)"
              :loading="authorizing && item.authorizing" :disabled="item.vehicle_at_pier_start_time == null ||
      item.vehicle_at_pier_end_time == null ||
      (item.zone_id == null && item.sector_id == null) ||
      authorizing ||
      unauthorizing ||
      item.status != 'PENDING' ||
      (plan && plan.status === 'ARCHIVED') ||
      isOutdated() ||
      !hasUserPermissionToViewEditOrManage('PLANS')
      ">
              <v-icon dark> mdi-check-bold </v-icon>
            </v-btn>
            <v-btn class="mx-2" color="red" outlined fab x-small @click="emitUnauthorization(item)"
              :loading="unauthorizing && item.unauthorizing" v-if="item.vehicle_at_pier_date != null" :disabled="authorizing ||
      unauthorizing ||
      item.status == 'CANCELED' ||
      item.status == 'REJECTED' ||
      (plan && plan.status === 'ARCHIVED') ||
      isOutdated() ||
      !hasUserPermissionToViewEditOrManage('PLANS') ||
      item.has_scheduled_entry_scan
      ">
              <v-icon dark> mdi-cancel </v-icon>
            </v-btn>
            <v-btn v-if="item.status == 'REJECTED'" class="mx-2" color="blue" outlined fab x-small
              @click="revertUnauthorization(item)" :loading="reverting" :disabled="reverting">
              <v-icon dark> mdi-rotate-left </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions class="pa-1">
        <div v-if="selectedEntriesToApplyChanges.length > 0 &&
      hasUserPermissionToViewEditOrManage('PLANS')
      " class="d-flex fill-width align-center">
          <span class="text-subtitle-1"><span class="font-weight-black">{{
      selectedEntriesToApplyChanges.length
    }}</span>
            {{ $t("entries.selected_requests") }}:
          </span>
          <SectorSelect dense single-line hide-details @input="handleMultipleSectorChanges" class="ml-1"
            style="max-width: 200px"></SectorSelect>
        </div>



        <v-menu top :offset-y="true" v-if="plan &&
      plan.status === 'OPENED' && !isOutdated() &&
      hasUserPermissionToViewEditOrManage('PLANS') && selectedEntriesToApplyChanges.length > 1">
          <template v-slot:activator="{ on: menu, attrs }">
            <v-tooltip top>
              <template v-slot:activator="{ on: tooltip }">
                <v-btn outlined color="black" class="mx-2" :disabled="loading ||
      zonesLoading ||
      sectorsLoading ||
      saving" v-bind="attrs" v-on="{ ...tooltip, ...menu }">
                  <v-icon> mdi-state-machine </v-icon>
                </v-btn>
              </template>
              <span>{{ $t('entries.manage_requests') }}</span>
            </v-tooltip>
          </template>
          <v-list>
            <v-list-item v-if="canApproveEntries" @click="approveAll">
              <v-list-item-avatar class="mr-2">
                <v-icon left>mdi-check</v-icon>
              </v-list-item-avatar>
              <v-list-item-title>
                {{ $t("entries.approve_all") }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item v-if="selectedEntriesToApplyChanges.length > 1" @click="rejectAll">
              <v-list-item-avatar class="mr-2">
                <v-icon left>mdi-cancel</v-icon>
              </v-list-item-avatar>
              <v-list-item-title>
                {{ $t("entries.reject_all") }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-spacer></v-spacer>

        <v-menu top :offset-y="true">
          <template v-slot:activator="{ on: menu, attrs }">
            <v-tooltip top>
              <template v-slot:activator="{ on: tooltip }">
                <v-btn outlined color="black" class="mr-1 font-weight-bold" :dark="plan &&
      plan.status === 'OPENED' && !isOutdated() &&
      hasUserPermissionToViewEditOrManage('PLANS')" :disabled="loading ||
      zonesLoading ||
      sectorsLoading ||
      saving ||
      (plan && plan.status === 'ARCHIVED') ||
      isOutdated() ||
      !hasUserPermissionToViewEditOrManage('PLANS')
      " v-bind="attrs" v-on="{ ...tooltip, ...menu }">
                  <v-icon> mdi-boom-gate-arrow-up </v-icon>
                </v-btn>
              </template>
              <span>{{ $t('entries.manage_entries') }}</span>
            </v-tooltip>
          </template>
          <v-list>
            <v-list-item @click="handleNewEntry">
              <v-list-item-avatar class="mr-2">
                <v-icon left>mdi-plus-box-multiple</v-icon>
              </v-list-item-avatar>
              <v-list-item-title>
                {{ $t("entries.new_entry") }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="handleImportEntries">
              <v-list-item-avatar class="mr-2">
                <v-icon left>mdi-upload</v-icon>
              </v-list-item-avatar>
              <v-list-item-title>
                {{ $t("entries.import_entries") }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-btn outlined color="black" :dark="plan &&
      plan.status === 'OPENED' &&
      !isOutdated() &&
      hasUserPermissionToViewEditOrManage('PLANS')
      " class="mr-1 font-weight-bold" :disabled="loading ||
      zonesLoading ||
      sectorsLoading ||
      saving ||
      (plan && plan.status === 'ARCHIVED') ||
      isOutdated() ||
      !hasUserPermissionToViewEditOrManage('PLANS')
      " @click="save">
          <v-icon dark left> mdi-content-save </v-icon>
          {{ $t("global.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
const DEFAULT_TYPES = [
  {
    code: "TOUR",
    value: "Excursão",
  },
  {
    code: "RACE",
    value: "Corrida",
  },
  {
    code: "RESERVATION",
    value: "Reserva",
  },
  {
    code: "CALL",
    value: "Chamada",
  },
];

import NewEntryRequestDialog from "@/components/Main/Scheduling/NewEntryRequestDialog";
import ImportEntryRequestsDialog from "@/components/Main/Scheduling/ImportEntryRequestsDialog";
import EntryRequestDialog from "@/components/Configurations/EntryRequest/ShowItemDialog";
import PassengersDialog from "@/components/Main/Scheduling/Passengers";
import SectorSelect from "@/components/Main/Scheduling/SectorSelect";
import ZoneSelect from "@/components/Main/Scheduling/ZoneSelect";
import configs from "@/helpers/configs";
import { DateTime } from "luxon";
import Vue from "vue";
export default {
  props: [
    "plan",
    "day",
    "selectedRange",
    "selectedDates",
    "zone",
    "sector",
    "mapRef",
    "entry",
  ],
  components: {
    EntryRequestDialog,
    PassengersDialog,
    SectorSelect,
    ZoneSelect,
    NewEntryRequestDialog,
    ImportEntryRequestsDialog,
  },
  data() {
    return {
      types: DEFAULT_TYPES,
      openNewEntryRequestDialog: false,
      openImportEntryRequestsDialog: false,
      componentEntryRequest: 0,
      componentPassengers: 0,
      saving: false,
      openPassengersDialog: false,
      openEntryRequestDialog: false,
      selectedEntryRequest: null,
      search: "",
      groupBy: null,
      sortBy: "code",
      sortDesc: false,
      popoverOptions: {
        placement: "bottom",
      },
      groups: [
        { value: "status", name: this.$t("entries.status") },
        { value: "vesselcall.name", name: this.$t("global.ship") },
        { value: "vehicle.operator.name", name: this.$t("entries.operator") },
        { value: "entry_type", name: this.$t("entries.type") },
      ],
      headers: [
        {
          text: this.$t("global.code"),
          value: "code",
          width: "20px",
        },
        {
          text: this.$t("entries.operator"),
          value: "vehicle.operator.name",
          width: "200px",
        },
        {
          text: this.$t("entries.vehicle_at_pier_date_request"),
          value: "vehicle_at_pier_date",
        },
        {
          text: this.$t("global.ship"),
          value: "vesselcall.name",
        },
        {
          text: this.$t("entries.sector"),
          value: "sector_id",
          width: "250px",
        },
        {
          text: this.$t("entries.zone"),
          value: "zone_id",
          width: "250px",
        },
        {
          text: "",
          value: "map",
          width: "10px",
          sortable: false,
        },
        {
          text: this.$t("entries.vehicle_at_pier_start"),
          value: "vehicle_at_pier_start",
          width: "60px",
        },
        {
          text: this.$t("entries.vehicle_at_pier_end"),
          value: "vehicle_at_pier_end",
          width: "60px",
        },
        {
          text: this.$t("entries.status"),
          value: "status",
        },
        {
          text: "",
          value: "actions",
          width: "180px",
          sortable: false,
        },
      ],
      selectedEntries: [],
      sectorInputsKey: 0,
      zoneInputKey: 0,
      selectAll: false,
      selectedGroupEntries: [],
      indeterminateGroupEntries: [],
      authorizing: false,
      unauthorizing: false,
      reverting: false,
    };
  },
  created() {
    this.$root.$on("captureAndEmitFinished", this.handleCaptureAndEmitFinished);
  },
  beforeDestroy() {
    this.$root.$off(
      "captureAndEmitFinished",
      this.handleCaptureAndEmitFinished
    );
  },
  mounted() {
    this.getScheduling();
  },
  computed: {
    mapReference() {
      return this.mapRef;
    },
    locode() {
      return configs.getLocode();
    },
    loading() {
      return this.$store.state.shorex.loading;
    },
    sectorsLoading() {
      return this.$store.state.sectors.loading;
    },
    zonesLoading() {
      return this.$store.state.zones.loading;
    },
    sectors() {
      return this.$store.state.sectors.all;
    },
    zones() {
      return this.$store.state.zones.all;
    },
    scheduling() {
      return this.$store.state.shorex.scheduling;
    },
    entries: {
      get() {
        return this.$store.state.shorex.scheduling.map((item) => {
          item.isSelectable =
            item.status == "PENDING" &&
            !this.authorizing &&
            !this.unauthorizing;
          return item;
        });
      },
      set(items) {
        return items;
      },
    },
    selectedDay: {
      get() {
        return this.day;
      },
      set(val) {
        this.$emit("update:day", val);
      },
    },
    timeRange: {
      get() {
        return this.selectedDates;
      },
      set(val) {
        this.getScheduling(val);
        this.$emit("update:selectedDates", val);
      },
    },
    range: {
      get() {
        return this.selectedRange;
      },
      set(val) {
        this.$emit("update:selectedRange", val);
      },
    },
    selectedZone() {
      return this.zone;
    },
    selectedSector() {
      return this.sector;
    },
    selectedEntry: {
      get() {
        return this.entry;
      },
      set(val) {
        this.$emit("update:entry", val);
      },
    },
    indeterminate() {
      return (
        this.entries.some((entry) => entry.selected) &&
        this.entries.some((entry) => !entry.selected)
      );
    },
    disabled() {
      return (
        (this.plan && this.plan.status === "ARCHIVED") ||
        !this.entries.some((entry) => entry.isSelectable) ||
        this.isOutdated()
      );
    },
    selectedEntriesToApplyChanges() {
      return this.entries.filter((entry) => entry.selected);
    },

    canApproveEntries() {
      return (
        this.selectedEntriesToApplyChanges.length > 1 &&
        this.selectedEntriesToApplyChanges.every(
          (entry) => entry.status === "PENDING" && (entry.zone_id || entry.sector_id)
            && entry.vehicle_at_pier_end_time && entry.vehicle_at_pier_start_time
        )
      );
    },
  },
  methods: {
    goTo(item) {
      this.selectedEntry = null;
      setTimeout(() => {
        this.selectedEntry = item;
      }, 100);
    },
    handleUpdateScheduling() {
      this.getScheduling();
    },
    getScheduling(range = null) {
      this.selectedEntry = null;
      this.selectedEntries = [];
      let start, end;
      if (range) {
        start = range.start;
        end = range.end;
      } else {
        start = this.timeRange.start;
        end = this.timeRange.end;
      }
      start = DateTime.fromJSDate(start).toFormat("dd/LL/yyyy HH:mm:ss");
      end = DateTime.fromJSDate(end).toFormat("dd/LL/yyyy HH:mm:ss");
      let time = [start, end];

      this.$store.dispatch("shorex/GET_SCHEDULING", {
        day: this.day,
        timeRange: time,
      });
    },
    showPassengersList(entry) {
      this.openPassengersDialog = true;
      this.selectedEntryRequest = entry;
    },
    showEntryRequestDetail(entry) {
      this.openEntryRequestDialog = true;
      this.selectedEntryRequest = entry;
    },
    isOutdated() {
      let currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);

      let planDate = new Date(this.selectedDay);
      planDate.setHours(0, 0, 0, 0);

      return planDate < currentDate;
    },
    save() {
      this.saving = true;
      this.$store
        .dispatch("shorex/SET_SCHEDULING", {
          day: this.day,
          entries: this.entries,
        })
        .then(() => {
          this.saving = false;
        })
        .catch(() => {
          this.saving = false;
        });
    },
    validateStartTime(item) {
      if (
        item.vehicle_at_pier_start_time == null ||
        item.vehicle_at_pier_end_time == null
      )
        return;

      let startHour = item.vehicle_at_pier_start_time.split(":")[0];
      let startMinute = item.vehicle_at_pier_start_time.split(":")[1];
      let endHour = item.vehicle_at_pier_end_time.split(":")[0];
      let endMinute = item.vehicle_at_pier_end_time.split(":")[1];

      if (
        startHour > endHour ||
        (startHour == endHour && startMinute > endMinute)
      ) {
        item.vehicle_at_pier_end_time = null;
      }
    },
    validateEndTime(item) {
      if (
        item.vehicle_at_pier_start_time == null ||
        item.vehicle_at_pier_end_time == null
      )
        return;

      let startHour = item.vehicle_at_pier_start_time.split(":")[0];
      let startMinute = item.vehicle_at_pier_start_time.split(":")[1];
      let endHour = item.vehicle_at_pier_end_time.split(":")[0];
      let endMinute = item.vehicle_at_pier_end_time.split(":")[1];

      if (
        startHour > endHour ||
        (startHour == endHour && startMinute > endMinute)
      ) {
        item.vehicle_at_pier_end_time = null;
      }
    },
    updateStartTime(entry) {
      this.$nextTick(() => {
        this.validateStartTime(entry);
        this.updateEntry(entry);
      });
    },
    updateEndTime(entry) {
      this.$nextTick(() => {
        this.validateEndTime(entry);
        this.updateEntry(entry);
      });
    },
    updateEntry(entry) {
      var ind = this.entries.findIndex((element) => {
        return element.id === entry.id;
      });

      if (ind !== -1) {
        this.entries.splice(ind, entry);
        Vue.set(this.scheduling, ind, this.scheduling.at(ind));
      }
    },
    async emitAuthorization(entry) {
      this.authorizing = true;
      entry.authorizing = true;

      this.updateEntry(entry);

      this.$root.$emit("captureAndEmit", { entry, day: this.day });
    },

    revertUnauthorization(entry) {
      this.reverting = true;

      this.$store
        .dispatch("shorex/REVERT_UNAUTHORIZATION", entry.id)
        .then((data) => {
          this.reverting = false;
          entry.status = data.status;
          this.updateEntry(entry);
        })
        .catch(() => {
          this.reverting = false;
        });
    },

    emitUnauthorization(entry) {
      this.unauthorizing = true;
      entry.unauthorizing = true;

      this.updateEntry(entry);

      this.$store
        .dispatch("shorex/EMIT_UNAUTHORIZATION", entry)
        .then((data) => {
          entry.status = data.status;
          this.unauthorizing = false;
          entry.unauthorizing = false;
          this.updateEntry(entry);
        })
        .catch(() => {
          this.unauthorizing = false;
          entry.unauthorizing = false;
          this.updateEntry(entry);
        });
    },

    customSearch(value, search, item) {
      if (!search) return true;
      let fields = ["code", "zone_id", "vehicle"];
      if (search.includes(this.$t("zones.zone") + ":")) {
        fields = ["zone_id"];
        search = search.split(":")[1];
      }

      let fieldsToSearch = Object.entries(item)
        .filter(([key, _]) => {
          return fields.includes(key);
        })
        .map((v) => {
          let key = v[0],
            val = v[1];
          if (val) {
            if (key == "vehicle") {
              return [
                val.registration_plate.toString().toLowerCase(),
                val.operator.name.toString().toLowerCase(),
              ];
            } else if (key == "vesselcall") {
              return [val.name.toString().toLowerCase()];
            } else {
              return [val.toString().toLowerCase()];
            }
          }
          return [];
        })
        .flat();

      return fieldsToSearch.some((v) => {
        return v.includes(search.toLowerCase());
      });
    },

    getTypeLabel(type) {
      return type ? this.$t("entry_types." + type.toLowerCase()) : null;
    },

    handleMultipleSectorChanges(sectorId) {
      if (this.selectedEntriesToApplyChanges) {
        for (let i = 0; i < this.selectedEntriesToApplyChanges.length; i++) {
          let index = this.entries.findIndex(
            (entry) => entry.id == this.selectedEntriesToApplyChanges[i].id
          );
          this.selectedEntriesToApplyChanges[i].sector_id = sectorId;
          Vue.set(this.entries, index, this.selectedEntriesToApplyChanges[i]);
        }

        this.sectorInputsKey++;
        this.zoneInputKey++;
      }
    },

    handleNewEntry() {
      this.openNewEntryRequestDialog = true;
    },

    handleImportEntries() {
      this.openImportEntryRequestsDialog = true;
    },

    updateEntries(e, item) {
      this.updateEntry(item);
    },

    handleToggleAll(state) {
      this.entries.forEach((element) => {
        if (element.isSelectable) element.selected = state;
        this.updateEntry(element);
      });
    },

    handleSelectedGroupEntries(group, state) {
      this.entries.forEach((element) => {
        let groupValue = null;

        switch (this.groupBy) {
          case "vehicle.operator.name":
            groupValue = element.vehicle.operator.name;
            break;
          case "vesselcall.name":
            groupValue = element.vesselcall.name;
            break;
          case "entry_type":
            groupValue = element.entry_type;
            break;
          case "status":
            groupValue = element.status;
            break;
          default:
            break;
        }

        if (element.isSelectable && groupValue == group)
          element.selected = state;

        this.updateEntry(element);
      });
    },
    getStatusLabel(status) {
      return configs.getStatusLabel(status);
    },
    getStatusColor(status) {
      return configs.getStatusColor(status);
    },
    handleCaptureAndEmitFinished(entry) {
      this.authorizing = false;
      entry.authorizing = false;
      this.updateEntry(entry);
    },
    approveAll() {
      this.authorizing = true;

      this.selectedEntriesToApplyChanges.forEach((entry) => {
        entry.authorizing = true;
        this.updateEntry(entry);
      });

      this.$store
        .dispatch("shorex/EMIT_MULTIPLE_AUTHORIZATIONS", {
          day: this.day,
          entries: this.selectedEntriesToApplyChanges,
        })
        .then((data) => {
          data.forEach((entry) => {
            this.selectedEntriesToApplyChanges.forEach((selectedEntry) => {
              if (entry.id == selectedEntry.id) {
                selectedEntry.status = entry.status;
                selectedEntry.authorizing = false;
                selectedEntry.selected = false;
                this.updateEntry(selectedEntry);
              }
            });
          });
          this.authorizing = false;
        })
        .catch(() => {
          this.selectedEntriesToApplyChanges.forEach((entry) => {
            entry.authorizing = false;
            this.updateEntry(entry);
          });
          this.authorizing = false;
        });
    },

    rejectAll() {
      this.unauthorizing = true;

      this.selectedEntriesToApplyChanges.forEach((entry) => {
        entry.unauthorizing = true;
        this.updateEntry(entry);
      });

      this.$store
        .dispatch("shorex/EMIT_MULTIPLE_UNAUTHORIZATIONS", this.selectedEntriesToApplyChanges)
        .then((data) => {
          data.forEach((entry) => {
            this.selectedEntriesToApplyChanges.forEach((selectedEntry) => {
              if (entry.id == selectedEntry.id) {
                selectedEntry.status = entry.status;
                selectedEntry.unauthorizing = false;
                selectedEntry.selected = false;
                this.updateEntry(selectedEntry);
              }
            });
            this.unauthorizing = false;
          });
        })
        .catch(() => {
          this.selectedEntriesToApplyChanges.forEach((entry) => {
            entry.unauthorizing = true;
            this.updateEntry(entry);
          });
          this.unauthorizing = false;
        });
    }
  },
  watch: {
    openPassengersDialog(val) {
      this.componentPassengers++;
      if (!val) this.selectedEntryRequest = null;
    },
    openEntryRequestDialog(val) {
      this.componentEntryRequest++;
      if (!val) this.selectedEntryRequest = null;
    },
    selectedEntry(val) {
      this.selectedEntries = [val];
      this.$nextTick(() => {
        let elem = document.querySelector(".v-data-table__selected");
        if (elem) elem.scrollIntoView({ block: "end", behavior: "smooth" });
      });
    },
    groupBy() {
      this.entries.forEach((element) => {
        if (element.isSelectable) element.selected = false;
        this.updateEntry(element);
      });
    },
  },
};
</script>
<style>
.entriesList .v-data-table__wrapper {
  height: 100%;
}

tr.v-data-table__selected {
  background: rgb(241, 241, 182) !important;
}

.entriesList {
  height: calc(100% - 90px);
}

.entriesList td,
.entriesList .v-inpu,
.entriesList .v-select__selections {
  color: black;
  font-weight: 600;
}

.entriesList input[type="time"]::-webkit-calendar-picker-indicator {
  background: none;
  display: none;
}

.entriesList input {
  text-align: left;
}

.entriesList input[type="time"] {
  text-align: center;
  font-size: 14px;
  color: black;
  font-weight: 600;
}

.entriesList .disabled {
  color: black;
}

.entriesList .v-select.v-input--is-disabled .v-select__selections,
.entriesList .v-btn--disabled .v-btn__content {
  color: black;
}

.entriesList>.entriesList__wrapper>table>tbody>tr>td,
.entriesList>.entriesList__wrapper>table>tbody>tr>th,
.entriesList>.entriesList__wrapper>table>thead>tr>td,
.entriesList>.entriesList__wrapper>table>thead>tr>th,
.entriesList>.entriesList__wrapper>table>tfoot>tr>td,
.entriesList>.entriesList__wrapper>table>tfoot>tr>th {
  padding: 0px 8px;
}
</style>

<style scoped>
.registration-plate-font {
  font-family: "Fira Mono", monospace !important;
  font-weight: 500 !important;
}
</style>
