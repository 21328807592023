<template>
  <v-bottom-sheet v-model="showDetails">
    <v-card v-if="!!selectedItem" class="d-flex flex-column" tile>
      <v-toolbar class="d-flex flex-column flex-grow-0" flat color="primary">
        <v-toolbar-title class="font-weight-bold">
          <v-icon class="black--text" v-if="vehicleDetails" left>{{
            vehicleDetails.vehicle_type.icon
          }}</v-icon>
         <span v-if="selectedItem.vehicle_type.code != 'TAXI'" class="registration-plate-font"> {{ selectedItem.registration_plate }} </span>
         <span v-else class="registration-plate-font"> {{ selectedItem.license_number }} </span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="showDetails = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text class="details-section pa-2 ma-0">
        <Loading v-model="loading"></Loading>
        <v-card v-if="!!vehicleDetails" class="ma-0 pa-0" flat>
          <v-card-text class="ma-0 pa-0">
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td width="50%" class="text-subtitle-1 font-weight-black">
                      {{ $t("vehicles.license_state") }}
                    </td>
                    <td>
                      <v-chip
                        dark
                        class="text-uppercase overline"
                        small
                        label
                        :color="getVehicleStatusColor(item)"
                        >{{
                          $t(`vehicle_states.${item.status.toLowerCase()}`)
                        }}</v-chip
                      >
                    </td>
                  </tr>
                  <tr>
                    <td width="50%" class="text-subtitle-1 font-weight-black">
                      {{ $t("vehicles.registration_plate") }}
                    </td>
                    <td class="registration-plate-font">
                      {{ vehicleDetails.registration_plate }}
                    </td>
                  </tr>
                  <tr v-if="!!vehicleDetails.vehicle_type.name">
                    <td width="50%" class="text-subtitle-1 font-weight-black">
                      {{ $t("vehicles.type") }}
                    </td>
                    <td>
                      {{ vehicleDetails.vehicle_type.name }}
                    </td>
                  </tr>
                  <tr v-if="!!vehicleDetails.brand">
                    <td width="50%" class="text-subtitle-1 font-weight-black">
                      {{ $t("vehicles.brand") }}
                    </td>
                    <td>
                      {{ vehicleDetails.brand }}
                    </td>
                  </tr>
                  <tr v-if="!!vehicleDetails.model">
                    <td width="50%" class="text-subtitle-1 font-weight-black">
                      {{ $t("vehicles.model") }}
                    </td>
                    <td>
                      {{ vehicleDetails.model }}
                    </td>
                  </tr>
                  <tr v-if="!!vehicleDetails.responsible_name">
                    <td width="50%" class="text-subtitle-1 font-weight-black">
                      {{ $t("vehicles.responsible_name") }}
                    </td>
                    <td>
                      {{ vehicleDetails.responsible_name }}
                    </td>
                  </tr>
                  <tr v-if="!!vehicleDetails.seating_capacity">
                    <td width="50%" class="text-subtitle-1 font-weight-black">
                      {{ $t("vehicles.seating_capacity") }}
                    </td>
                    <td>
                      {{ vehicleDetails.seating_capacity }}
                    </td>
                  </tr>
                  <tr v-if="!!vehicleDetails.phone">
                    <td width="50%" class="text-subtitle-1 font-weight-black">
                      {{ $t("vehicles.contact_phone") }}
                    </td>
                    <td>
                      {{ vehicleDetails.phone }}
                    </td>
                  </tr>
                  <tr v-if="!!vehicleDetails.license_number">
                    <td width="50%" class="text-subtitle-1 font-weight-black">
                      {{ $t("vehicles.license_number") }}
                    </td>
                    <td class="license-number-font">
                      {{ vehicleDetails.license_number }}
                    </td>
                  </tr>
                  <tr v-if="!!vehicleDetails.permit_number">
                    <td width="50%" class="text-subtitle-1 font-weight-black">
                      {{ $t("vehicles.permit_number") }}
                    </td>
                    <td>
                      {{ vehicleDetails.permit_number }}
                    </td>
                  </tr>

                  <tr v-if="!!vehicleDetails.valid_until">
                    <td width="50%" class="text-subtitle-1 font-weight-black">
                      {{ $t("vehicles.valid_until") }}
                    </td>
                    <td>
                      {{ vehicleDetails.valid_until | formatDate }}
                    </td>
                  </tr>
                </tbody></template
              ></v-simple-table
            >
          </v-card-text>

          <v-card
            outlined
            class="mt-4"
            v-if="!!vehicleDetails.image_path"
            style="max-width: 500px"
          >
            <v-card-text class="ma-0 pa-0">
              <v-img contain width="100%" :src="img_path">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      :size="150"
                      :width="3"
                      color="grey"
                      indeterminate
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-card-text>
          </v-card>
        </v-card>
      </v-card-text>
      <v-card-actions v-if="!!vehicleDetails">
        <div class="stacked-buttons">
          <v-btn
            v-if="
              !!selectedItem &&
              selectedItem.status != 'CANCELED' &&
              operator.accredited
            "
            class="flex-grow-1"
            depressed
            large
            @click="editVehicle"
          >
            <v-icon left>mdi-pencil</v-icon>
            {{ $t("global.edit") }}
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-bottom-sheet>
</template>
<script>
import Loading from "@/components/Main/Loading";
import configs from "@/helpers/configs";
export default {
  props: ["value", "item"],
  components: { Loading },
  data() {
    return {
      loading: false,
      vehicleDetails: null,
    };
  },
  computed: {
    vehicleStatuses() {
      return configs.getVehicleStatuses();
    },
    operator() {
      return this.$store.state.operators.currentOperator;
    },
    columSize() {
      if (this.vehicleDetails.image_path) return "6";
      else return "12";
    },

    img_path() {
      return (
        configs.getApiUrl() +
        "/" +
        "storage/images/" +
        this.vehicleDetails.image_path
      );
    },
    selectedItem: {
      get() {
        return this.item;
      },
      set(val) {
        this.$emit("update:item", val);
      },
    },
    showDetails: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
        if (!val) {
          this.selectedItem = null;
        }
      },
    },
  },
  watch: {
    showDetails(val) {
      if (val) {
        this.loadVehicleDetails();
      }
    },
  },
  methods: {
    getVehicleStatusColor(item) {
      let status = this.vehicleStatuses.find((v) => {
        return v.code == item.status;
      });
      if (status) {
        return status.color;
      }
      return null;
    },
    loadVehicleDetails() {
      if (this.selectedItem.id) {
        this.loading = true;
        this.vehicleDetails = null;
        this.$store
          .dispatch(
            "user_vehicles/GET_USER_VEHICLE_BY_ID",
            this.selectedItem.id
          )
          .then((data) => {
            this.loading = false;
            this.vehicleDetails = data;
          })
          .catch((e) => {
            console.error(e);
          });
      }
    },
    editVehicle() {
      this.$emit("edit");
    },
  },
};
</script>
<style scoped>
.details-section {
  min-height: 300px;
  max-height: calc(60vh - var(--toolbar-height));
  overflow-y: auto;
  position: relative;
}
.details-section:has(+ .request-actions) {
  min-height: 300px;
  max-height: calc(60vh - var(--toolbar-height) - 60px);
}

.stacked-buttons {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
}
.registration-plate-font {
  font-family: "Fira Mono", monospace !important;
  font-weight: 700 !important;
}

.license-number-font {
  font-family: "Fira Mono", monospace !important;
  font-weight: 300 !important;
}
</style>
