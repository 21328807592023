<template>
  <div>
    <v-app-bar app color="primary" dense clipped-right clipped-left>
      <router-link :to="appHomeUrl">
        <v-img class="mx-1" src="@/assets/logo.png" max-height="40" max-width="40" contain></v-img>
      </router-link>
      <v-toolbar-title>
        <v-list-item>
          <v-list-item-content>
            <div>
              <p class="mt-1 mb-1 pa-0" style="font-size: 18px">
                <b>3Shorex</b>
              </p>
              <p class="mt-1 mb-1 pa-0" style="font-size: 12px">
                {{ locode.name }}
              </p>
            </div>
          </v-list-item-content>
        </v-list-item>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <Weather class="pa-1 mt-0" v-if="!$vuetify.breakpoint.xs" />

      <v-divider vertical v-if="!$vuetify.breakpoint.xs"></v-divider>

      <Notifications class="px-3" v-if="isPortAuthorityUser" />

      <v-divider vertical v-if="isPortAuthorityUser"></v-divider>

      <v-switch v-model="scannedRealtimeOpen" inset class="pa-4 mt-6" color="white"
        v-if="isMain && isPortAuthorityUser">
        <template v-slot:label>
          <span v-if="!isMobileDevice">{{
            $t("global.show_scanned_vehicles")
          }}</span>
          <v-icon v-else>mdi-car</v-icon>
        </template>
      </v-switch>

      <v-divider vertical v-if="isMain && isPortAuthorityUser"></v-divider>

      <v-app-bar-nav-icon @click.stop="menuOpen = !menuOpen" v-if="!hasSpeedDialEnabled"></v-app-bar-nav-icon>

      <v-btn @click.stop="goToSpeedDialPage()" v-else icon rounded>
        <v-icon>mdi mdi-apps</v-icon>
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer v-if="isMain && isPortAuthorityUser" disable-resize-watcher v-model="scannedRealtimeOpen"
      absolute right :style="{ top: $vuetify.application.top + 'px', zIndex: 2 }" :width="400"
      :permanent="scannedRealtimeOpen">
      <ScannedRealtimeList v-if="!!scannedRealtimeOpen"></ScannedRealtimeList>
    </v-navigation-drawer>

    <v-navigation-drawer disable-resize-watcher temporary v-model="menuOpen" app clipped right
      :style="{ top: $vuetify.application.top + 'px', zIndex: 4 }" :width="400">
      <Menu />
    </v-navigation-drawer>
  </div>
</template>

<script>
import Menu from "@/components/Main/Menu";
import Weather from "@/components/Main/Weather";
import Notifications from "@/components/Main/Notifications";
import ScannedRealtimeList from "@/components/Main/ScannedRealtimeList";
import configs from "@/helpers/configs";

export default {
  components: {
    Weather,
    Menu,
    Notifications,
    ScannedRealtimeList,
  },
  computed: {
    appHomeUrl() {
      return this.hasSpeedDialEnabled ? '/speed_dial' : '/';
    },

    isMobileDevice() {
      return this.$vuetify.breakpoint.mobile;
    },
    isMain() {
      return this.$route.name.toUpperCase() == "MAP";
    },
    isSpeedDial() {
      return this.$route.name.toUpperCase() == "SPEED_DIAL";
    },
    locode() {
      return configs.getLocode();
    },
    userAccount() {
      return this.$store.state.user.account;
    },
    hasSpeedDialEnabled() {
      return this.$store.state.user.account.user.speed_dial;
    },
    userType() {
      return this.userAccount && this.userAccount.user
        ? this.userAccount.user.type
        : "";
    },
    isShorexUser() {
      return this.userType == configs.getShorexUserType();
    },
    isPortAuthorityUser() {
      return (
        this.userType == configs.getAdminUserType() ||
        this.userType == configs.getManagerUserType()
      );
    },
  },
  data() {
    return {
      menuOpen: false,
      scannedRealtimeOpen: false,
    };
  },
  methods: {
    goToSpeedDialPage() {
      if (this.isSpeedDial) location.reload();
      else this.$router.push("/speed_dial");
    },
  },
};
</script>
