<template>
  <v-overlay
    :value="isLoading"
    :color="backgroundColor"
    :opacity="opacity"
    absolute
    style="z-index: 1"
  >
    <v-container fill-height fluid>
      <v-row align="center" justify="center">
        <v-progress-circular :size="150" :width="3" color="grey" indeterminate>
          <div class="overline text-center">
            <v-img
              src="@/assets/logo.png"
              max-height="40"
              max-width="40"
              contain
              class="mx-auto"
            ></v-img>
            {{ $t("global.loading") }}
          </div>
        </v-progress-circular>
      </v-row>
      <p
        class="text-center text-h6 ma-0 pt-4 px-4"
        style="color: black; width: 100%"
        v-if="customMessage"
      >
        {{ customMessage }}
      </p>
    </v-container>
  </v-overlay>
</template>
<script>
export default {
  props: {
    value: {
      default: false,
      type: Boolean,
    },
    backgroundColor: {
      default: "#FFFFFF",
      type: String,
    },
    opacity: {
      default: 1,
      type: Number,
    },
    customMessage: {
      default: null,
      type: String,
    },
  },
  computed: {
    isLoading() {
      return this.value;
    },
  },
};
</script>
