<template>
  <div class="map-container">
    <div ref="map" class="map" />
    <Loading
      v-if="zonesLoading"
      :value="zonesLoading"
      :custom-message="$t('zones.loading_zones_please_wait')"
    ></Loading>
    <div style="position: absolute; top: 20px; left: 20px">
      <ResetView :map="this.map" class="mb-2" />
      <Plans
        class="mb-2"
        v-if="
          (isAdminUser || isManagerUser) &&
          hasUserPermissionToViewOrManage('PLANS')
        "
      ></Plans>
    </div>

    <div
      style="position: absolute; bottom: 20px; left: 20px"
      v-if="!!locode.ais"
    >
      <AISShips :refMap.sync="map" v-if="map" />
    </div>
  </div>
</template>
<script>
import "maptalks/dist/maptalks.css";
import * as maptalks from "maptalks";

import AISShips from "@/components/Map/AISShips";
import ResetView from "@/components/Map/ResetView";
import Plans from "@/components/Map/Plans";
import Loading from "@/components/Main/Loading";

import configs from "@/helpers/configs";
import Zone from "@/helpers/map/zone";
import Sector from "@/helpers/map/sector";

import SocketServiceHelper from "@/helpers/realtime/socketservicehelper";

const ZONES_LAYERNAME = "zones";
const SECTORS_LAYERNAME = "sectors";
const FIT_BOUNDS_PADDING = 10;

import { DateTime } from "luxon";

const TODAY = DateTime.now().toFormat("yyyy-LL-dd");

import ScannedVehiclesTracker from "@/helpers/realtime/scannedvehiclestracker";

export default {
  components: {
    AISShips,
    ResetView,
    Plans,
    Loading,
  },
  data() {
    return {
      map: null,
      loading: false,
      sectors: [],
      drawnZones: [],
      drawnSectors: [],
      selectedZone: null,
      selectedSector: null,
    };
  },
  computed: {
    selectedVehicle: {
      get() {
        return this.$store.state.ui.map.selectedVehicle;
      },
      set(val) {
        this.$store.dispatch("ui/SET_MAP_SELECTED_VEHICLE", val);
      },
    },
    entries() {
      return this.$store.state.scanned_vehicles.all;
    },
    today() {
      return TODAY;
    },
    locode() {
      return configs.getLocode();
    },
    zonesLoading() {
      return this.$store.state.zones.loading;
    },
    zones() {
      return this.$store.state.zones.all.filter((zone) => {
        return !zone.unavailable;
      });
    },
    userAccount() {
      return this.$store.state.user.account;
    },
    userType() {
      return this.userAccount && this.userAccount.user
        ? this.userAccount.user.type
        : "";
    },
    isShorexUser() {
      return this.userType == configs.getShorexUserType();
    },
    isAdminUser() {
      return this.userType == configs.getAdminUserType();
    },
    isManagerUser() {
      return this.userType == configs.getManagerUserType();
    },
  },
  watch: {
    entries() {
      this.paintOccupiedZones();
    },
    zones() {
      this.processZones();
      this.processSectors();
      this.paintOccupiedZones();
    },
    selectedSector(val) {
      if (!val) {
        this.drawnSectors.forEach((drawnSector) => {
          drawnSector.deHighlight();
        });
      } else {
        this.drawnSectors.forEach((drawnSector) => {
          drawnSector.deHighlight();
        });
        let drawnSector = this.drawnSectors.find((z) => {
          return z.properties.id == this.selectedSector.id;
        });
        if (drawnSector) {
          drawnSector.highlight();
          this.fitMapToSectorBounds();
        }
      }
    },
    selectedZone(val) {
      if (!val) {
        this.drawnZones.forEach((drawnZone) => {
          drawnZone.deHighlight();
        });
      } else {
        this.drawnZones.forEach((drawnZone) => {
          drawnZone.deHighlight();
        });
        let drawnZone = this.drawnZones.find((z) => {
          return z.properties.id == this.selectedZone.id;
        });
        if (drawnZone) {
          drawnZone.highlight();
          this.fitMapToZoneBounds();
        }
      }
    },
    selectedVehicle() {
     
      if (this.selectedVehicle) {
 
        this.selectedZone = null;
        this.selectedSector = null;

        if (!!this.selectedVehicle.zone_id) {
          this.selectedZone = this.zones.find((z) => {
            return z.id == this.selectedVehicle.zone_id;
          });
        }
        
        else if (!!this.selectedVehicle.sector_id) {
          this.selectedSector = this.sectors.find((s) => {
            return s.id == this.selectedVehicle.sector_id;
          });
        }
      }
    },
  },
  mounted() {
    this.loadZonesAndSectors();
    this.initMap();
    this.enableTracking();
    SocketServiceHelper.initialize();
  },
  beforeDestroy() {
    this.map.off("click", this.handleMapClick);
    this.disableTracking();
    SocketServiceHelper.deInitialize();
  },
  methods: {
    enableTracking() {
      ScannedVehiclesTracker.on("add", this.handleScanAdded);
      ScannedVehiclesTracker.on("update", this.handleScanUpdated);
      ScannedVehiclesTracker.on("remove", this.handleScanRemoved);
    },
    disableTracking() {
      ScannedVehiclesTracker.off("add", this.handleScanAdded);
      ScannedVehiclesTracker.off("update", this.handleScanUpdated);
      ScannedVehiclesTracker.off("remove", this.handleScanRemoved);
    },

    handleScanAdded(scan) {
      this.$store.dispatch("scanned_vehicles/ADD_OR_UPDATE", scan);
    },
    handleScanUpdated(scan) {
      this.$store.dispatch("scanned_vehicles/ADD_OR_UPDATE", scan);
    },
    handleScanRemoved(scan) {
      this.$store.dispatch("scanned_vehicles/REMOVE", scan);
    },

    loadZonesAndSectors() {
      this.loadZones().then(() => {
        this.loadSectors();
        this.paintOccupiedZones();
      });
    },

    loadZones() {
      return this.$store.dispatch("zones/GET_ALL");
    },

    loadSectors() {
      return this.$store
        .dispatch("sectors/GET_BY_DAY", this.today)
        .then((data) => {
          this.sectors = data;
          this.processSectors();
          this.paintOccupiedZones();
        });
    },

    paintOccupiedZones() {
      // Clear existing occupied state zones
      this.drawnZones.forEach((drawnZone) => {
        drawnZone.setOccupied(false);
      });
      if (this.entries) {
        this.entries.forEach((entry) => {
          if (entry.zone_id) {
            // Find the drawn zone, and clear place it as occupied
            let drawnZone = this.drawnZones.find((z) => {
              return z.properties.id == entry.zone_id;
            });
            if (drawnZone) {
              drawnZone.setOccupied(true);
            }
          }
        });
      }
    },

    initMap() {
      let locode = configs.getLocode();
      let center = locode.coordinates;
      let bearing = locode.bearing;
      let zoom = locode.zoom;

      this.map = new maptalks.Map(this.$refs.map, {
        center: center,
        zoom: zoom,
        bearing: bearing,
        hitDetect: false, // whether to enable hit detecting of layers for cursor style on this map, disable it to improve performance
        layerCanvasLimitOnInteracting: -1, // -1 to display all layers when interacting
        //fpsOnInteracting: 0,
        zoomControl: false, // add zoom control
        scaleControl: false, // add scale control
        attribution: false,
        baseLayer: new maptalks.TileLayer("baselayer", {
          visible: true,
          urlTemplate: configs.getUrlRaster(),
          subdomains: ["a", "b", "c", "d"],
          attribution: "OSM CARTO",
        }),
        layers: [
          new maptalks.VectorLayer(SECTORS_LAYERNAME, []),
          new maptalks.VectorLayer(ZONES_LAYERNAME, []),
        ],
      });
      this.map.on("click", this.handleMapClick);
    },

    handleMapClick() {
      this.selectedZone = null;
      this.selectedSector = null;
      this.selectedVehicle = null;
      this.drawnZones.forEach((drawnZone) => {
        drawnZone.deHighlight();
      });
      this.drawnSectors.forEach((drawnSector) => {
        drawnSector.deHighlight();
      });
    },

    handleZoomOnSector(sector) {
      this.selectedSector = sector;
      this.fitMapToSectorBounds();
    },

    fitMapToZoneBounds() {
      if (this.selectedZone) {
        let dz = this.drawnZones.find((dz) => {
          return dz.properties.id == this.selectedZone.id;
        });
        if (dz) {
          this.map.fitExtent(dz.zoneSkeleton.getExtent(), 0, {
            paddingLeft: FIT_BOUNDS_PADDING,
            paddingRight: FIT_BOUNDS_PADDING,
            paddingTop: FIT_BOUNDS_PADDING,
            paddingBottom: FIT_BOUNDS_PADDING,
          });
        }
      }
    },

    fitMapToSectorBounds() {
      if (this.selectedSector) {
        var extent = new maptalks.Extent();
        this.selectedSector.zones.forEach((zone) => {
          let dz = this.drawnZones.find((dz) => {
            return dz.properties.id == zone.id;
          });
          if (dz) {
            extent = extent.combine(dz.zoneSkeleton.getExtent());
          }
        });
        if (extent && extent.isValid()) {
          this.map.fitExtent(extent, 0, {
            paddingLeft: FIT_BOUNDS_PADDING,
            paddingRight: FIT_BOUNDS_PADDING,
            paddingTop: FIT_BOUNDS_PADDING,
            paddingBottom: FIT_BOUNDS_PADDING,
          });
        }
      }
    },

    processZones() {
      if (this.map) {
        let layer = this.map.getLayer(ZONES_LAYERNAME);
        layer.clear();
        this.drawnZones = [];
        if (this.zones) {
          this.zones.forEach((zoneSpec) => {
            let zoneToAdd = new Zone(zoneSpec.geojson, {
              id: zoneSpec.id,
              code: zoneSpec.code,
              park: zoneSpec.park,
            });
            zoneToAdd.addTo(layer);
            this.drawnZones.push(zoneToAdd);
          });
        }
        this.map.fitExtent(layer.getExtent(), 0, {
          paddingLeft: FIT_BOUNDS_PADDING,
          paddingRight: FIT_BOUNDS_PADDING,
          paddingTop: FIT_BOUNDS_PADDING,
          paddingBottom: FIT_BOUNDS_PADDING,
        });
      }
    },

    processSectors() {
      if (this.map) {
        this.drawnZones.forEach((dz) => {
          dz.removeSectorColor();
        });
        let layer = this.map.getLayer(SECTORS_LAYERNAME);
        layer.clear();
        this.drawnSectors = [];
        this.sectors.forEach((sectorSpec) => {
          let sectorToAdd = new Sector(sectorSpec, {
            id: sectorSpec.id,
            color: sectorSpec.color,
            name: sectorSpec.name,
          });
          sectorToAdd.addTo(layer);
          this.drawnSectors.push(sectorToAdd);
          sectorSpec.zones.forEach((zone) => {
            let dz = this.drawnZones.find((dz) => {
              return dz.properties.id == zone.id;
            });
            if (dz) {
              dz.setSector(sectorSpec);
            }
          });
        });
      }
    },
  },
};
</script>
<style scoped>
.map,
.map-container {
  width: 100%;
  height: 100%;
}

.map-container {
  position: relative;
}
</style>
