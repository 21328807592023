import { operatorTypeService } from "@/api_services/operator_types.service";

const DEFAULT_STATE = {
  all: [],
  loading: false,
  allPaginated: [],
  options: {
    search: "",
    page: 1,
    itemsPerPage: 10,
    sortBy: "code",
    sortDesc: false,
  },
  total: 0,
};

export default {
  namespaced: true,
  state: Object.assign({}, JSON.parse(JSON.stringify(DEFAULT_STATE))),
  getters: {},
  mutations: {
    setOptions(state, options) {
      state.options = options;
    },
    setTotal(state, total) {
      state.total = total;
    },
    setOperatorTypes(state, operatorTypes) {
      state.all = operatorTypes;
    },
    setOperatorTypesLoading(state, isLoading) {
      state.loading = isLoading;
    },

    setOperatorTypesPaginated(state, operatorTypes) {
      state.allPaginated = operatorTypes;
    },

    setDefaultState(state) {
      Object.assign(state, JSON.parse(JSON.stringify(DEFAULT_STATE)));
    },
  },
  actions: {
    UPDATE_LOADING(context, loading) {
      context.commit("setOperatorTypesLoading", loading);
    },
    UPDATE_OPTIONS(context, options) {
      context.commit("setOptions", options);
    },
    GET_PUBLIC_OPERATOR_TYPES: (_, locode) => {
      return new Promise((resolve, reject) => {
        operatorTypeService
          .getPublicOperatorTypes(locode)
          .then((res) => {
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },
    GET_ALL: (context) => {
      return new Promise((resolve, reject) => {
        context.commit("setOperatorTypesLoading", true);
        operatorTypeService
          .getOperatorTypes()
          .then((res) => {
            context.commit("setOperatorTypes", res.data);
            context.commit("setOperatorTypesLoading", false);
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            context.commit("setOperatorTypesLoading", false);
            reject(e);
          });
      });
    },

    GET_ALL_PAGINATED: (context, options) => {
      return new Promise((resolve, reject) => {
        context.commit("setOperatorTypesLoading", true);
        if (options) {
          context.commit("setOptions", options);
        }
        operatorTypeService
          .getOperatorTypesPaginated(context.state.options)
          .then((res) => {
            context.commit("setOperatorTypesPaginated", res.data.data);
            context.commit("setTotal", res.data.total);
            context.commit("setOperatorTypesLoading", false);
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            context.commit("setOperatorTypesLoading", false);
            reject(e);
          });
      });
    },

    CREATE: (context, operatorType) => {
      return new Promise((resolve, reject) => {
        operatorTypeService
          .createOperatorType(operatorType)
          .then((res) => {
            resolve(res.data);
            context.dispatch("GET_ALL_PAGINATED");
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    UPDATE: (context, operatorType) => {
      return new Promise((resolve, reject) => {
        operatorTypeService
          .updateOperatorType(operatorType)
          .then((res) => {
            resolve(res.data);
            context.dispatch("GET_ALL_PAGINATED");
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    DELETE: (context, operatorTypeId) => {
      return new Promise((resolve, reject) => {
        operatorTypeService
          .deleteOperatorType(operatorTypeId)
          .then((res) => {
            resolve(res.data);
            context.dispatch("GET_ALL_PAGINATED");
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    RESET: (context) => {
      context.commit("setDefaultState");
    },
  },
};
