import * as maptalks from "maptalks";

const ZONES_HIGHLIGHT_SYMBOL = {
  lineWidth: 2,
  lineDasharray: [5],
  lineColor: "rgb(215, 186, 32)",
  polygonOpacity: 0.4,
  polygonFill: "rgb(215, 186, 32)",
};

const ZONES_BASE_SYMBOL = {
  lineColor: "#34495e",
  lineWidth: 2,
  lineDasharray: [],
  polygonFill: {
    property: "polygonFill",
    type: "identity",
  },
  polygonOpacity: {
    property: "polygonOpacity",
    type: "identity",
  },
  textFaceName: "Roboto",
  textName: "{park}\n{code}", //value from name in geometry's properties
  textWeight: "bold", //'bold', 'bold'
  textStyle: "normal", //'italic', 'oblique'
  textSize: {
    stops: [
      [15, 0],
      [18, 2],
      [20, 10],
      [22, 12],
    ],
  },
  textOpacity: 1,
  textLineSpacing: 0,
  textDx: 0,
  textDy: 0,
  textHorizontalAlignment: "middle", //left | middle | right | auto
  textVerticalAlignment: "middle", // top | middle | bottom | auto
  textAlign: "center", //left | right | center | auto,
  textFill: "#34495e",
  textHaloRadius: 1,
};

const UNOCCUPIED_OPACITY = 0.1;
const OCCUPIED_OPACITY = 0.8;
const DEFAULT_POLYGON_FILL = "grey";

export default class Zone extends maptalks.GeometryCollection {
  constructor(geojson, properties) {
    super([], {}); // geometries, opts

    this.baseSymbol = JSON.parse(JSON.stringify(ZONES_BASE_SYMBOL));

    this.properties = properties;

    if (!this.properties) {
      this.properties = {};
    }

    if (!this.properties.polygonOpacity) {
      this.properties.polygonOpacity = UNOCCUPIED_OPACITY;
    }

    if (!this.properties.polygonFill) {
      this.properties.polygonFill = DEFAULT_POLYGON_FILL;
    }

    // Geometry init
    this.zoneSkeleton = maptalks.GeoJSON.toGeometry(geojson);
    this.zoneSkeleton.setProperties(properties);
    this.zoneSkeleton.setSymbol(this.baseSymbol);
    this.setProperties(properties);
    this.setGeometries([this.zoneSkeleton]);

    //sector info
    this.sector = null;
  }

  highlight() {
    if (this.zoneSkeleton) {
      this.zoneSkeleton.updateSymbol(ZONES_HIGHLIGHT_SYMBOL);
    }
  }

  deHighlight() {
    if (this.zoneSkeleton) {
      this.zoneSkeleton.updateSymbol(this.baseSymbol);
    }
  }

  setSectorColor(color) {
    if (this.zoneSkeleton) {
      this.baseSymbol.lineColor = color;
      this.zoneSkeleton.updateSymbol({
        lineColor: color,
      });
      this.properties.polygonFill = this.properties.occupied
        ? color
        : DEFAULT_POLYGON_FILL;
      this.zoneSkeleton.setProperties(this.properties);
      this.setProperties(this.properties);
      this.zoneSkeleton.updateSymbol(this.baseSymbol);
    }
  }

  removeSectorColor() {
    if (this.zoneSkeleton) {
      this.baseSymbol = JSON.parse(JSON.stringify(ZONES_BASE_SYMBOL));
      this.zoneSkeleton.updateSymbol({
        lineColor: ZONES_BASE_SYMBOL.lineColor,
        polygonFill: DEFAULT_POLYGON_FILL,
      });
    }
  }

  setSector(sector) {
    if (sector) {
      this.sector = sector;
      this.setSectorColor(sector.color);
    } else {
      this.sector = null;
      this.removeSectorColor();
    }
  }

  toggleSector(sector) {
    if (!this.sector || this.sector !== sector) {
      this.setSector(sector);
    } else {
      this.setSector(null);
    }
  }

  // Zone is occupied or not
  setOccupied(occupied) {
    this.properties.occupied = occupied;
    if (this.sector && occupied) {
      this.properties.polygonFill = this.sector.color;
    } else {
      this.properties.polygonFill = DEFAULT_POLYGON_FILL;
    }
    this.properties.polygonOpacity = occupied
      ? OCCUPIED_OPACITY
      : UNOCCUPIED_OPACITY;
    if (this.zoneSkeleton) {
      this.zoneSkeleton.setProperties(this.properties);
    }
    this.setProperties(this.properties);
  }
}
