import configs from "@/helpers/configs";
import axios from "axios";
import { sharedHelpers } from "@/api_services/utils/shared-helpers";

export const userService = {
  login,
  logout,
  isLoggedIn,
  forgotPassword,
  resetPassword,
  signup,
  sendEmailVerification,
  updateProfileDetails,
  updateProfilePassword,
};

function isLoggedIn() {
  if (typeof localStorage !== "undefined") {
    return !!sharedHelpers.getStorageItemWithExpiry("account");
  }
  return false;
}

async function login(credential, password, locode) {
  var user = {
    credential,
    password,
    locode,
  };

  var requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  };

  return await axios
    .post(configs.getApiUrl() + "/auth/login", user, requestOptions)
    .catch(() => {
      return null;
    });
}
async function sendEmailVerification(credential) {
  var user = {
    credential,
  };

  var requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  };

  return await axios
    .post(
      configs.getApiUrl() + "/auth/send-email-verification",
      user,
      requestOptions
    )
    .catch(() => {
      return null;
    });
}
async function forgotPassword(email) {
  var requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  };

  return await axios
    .post(configs.getApiUrl() + "/auth/forgot-password", email, requestOptions)
    .catch(() => {
      return null;
    });
}

async function signup(user, operator, locode, operatorTypes) {
  const formData = new FormData();

  for (const property in user) {
    if (user.hasOwnProperty(property)) {
      const value = user[property] === null ? "" : user[property];
      formData.append("user_" + property, value);
    }
  }

  for (const property in operator) {
    if (operator.hasOwnProperty(property)) {
      const value = operator[property] === null ? "" : operator[property];
      formData.append("operator_" + property, value);
    }
  }

  formData.append("operatortypes", JSON.stringify(operatorTypes));
  if (operatorTypes.length > 0) {
    operatorTypes
      .forEach((item) => {
        formData.append(
          "operatortypes[]",
          JSON.stringify({
            code: item.code,
            file_number: item.file_number,
            validation_date: item.validation_date,
          })
        );

        // Append file to formData separately
        formData.append("operatortypes_files[]", item.file);
      });
  }

  formData.append("locode", locode);

  const requestOptions = {
    method: "POST",
    headers: sharedHelpers.getRequestHeadersFormData(),
  };
  return await axios.post(
    configs.getApiUrl() + "/auth/signup",
    formData,
    requestOptions
  );
}

async function resetPassword(email, password, token) {
  var user = {
    email,
    password,
    token,
  };

  var requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  };

  return await axios
    .post(configs.getApiUrl() + "/auth/reset-password", user, requestOptions)
    .catch(() => {
      return null;
    });
}
async function logout() {
  localStorage.removeItem("account");

  var requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  };

  return await axios.post(
    configs.getApiUrl() + "/auth/logout",
    {},
    requestOptions
  );
}
async function updateProfileDetails(name, email, phone) {
  var newUser = {
    name,
    email,
    phone,
  };

  var requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  };
  return await axios
    .post(
      configs.getApiUrl() + "/auth/profile_details",
      newUser,
      requestOptions
    )
    .catch(() => {
      return null;
    });
}

async function updateProfilePassword(currentPassword, password) {
  var newUser = {
    currentPassword,
    password,
  };

  var requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  };

  return await axios
    .post(
      configs.getApiUrl() + "/auth/profile_password",
      newUser,
      requestOptions
    )
    .catch(() => {
      return null;
    });
}
