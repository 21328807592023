import { auditLogsService } from "@/api_services/audit_logs.service";const DEFAULT_STATE = {
  all: [],
  loading: false,
  options: {
    search: "",
    page: 1,
    itemsPerPage: 10,
    sortBy: "created_at",
    sortDesc: true,
  },
  total: 0,
};

export default {
  namespaced: true,
  state: Object.assign({}, JSON.parse(JSON.stringify(DEFAULT_STATE))),
  mutations: {
    setOptions(state, options) {
      state.options = options;
    },
    setTotal(state, total) {
      state.total = total;
    },
    setAuditLogs(state, items) {
      state.all = items;
    },
    setLoading(state, isLoading) {
      state.loading = isLoading;
    },
    setDefaultState(state) {
      Object.assign(state, JSON.parse(JSON.stringify(DEFAULT_STATE)));
    },
  },
  actions: {
    UPDATE_OPTIONS(context, options) {
      context.commit("setOptions", options);
    },
    GET_ALL(context, options) {
      return new Promise((resolve, reject) => {
        context.commit("setLoading", true);
        if (options) {
          context.commit("setOptions", options);
        }
        auditLogsService
          .getAuditLogs(context.state.options)
          .then((res) => {
            context.commit("setAuditLogs", res.data.data);
            context.commit("setTotal", res.data.total);
            context.commit("setLoading", false);
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            context.commit("setLoading", false);
            reject(e);
          });
      });
    },
    
    RESET: (context) => {
      context.commit("setDefaultState");
    },
  },
};
