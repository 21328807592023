import proj4 from 'proj4';
import configs from '@/helpers/configs';
import * as turf from "@turf/turf";

var METER_PROJECTION = configs.getMeterProjection();

/**
 * Global utils for ships (projections, conversions, etc)
 */
export default {

    /**
     * Converts coordinates to a meter based projection system (3763)
     */
    convertCoordsToMeters(coords) {
        var pointOnMapReproj = proj4(METER_PROJECTION, coords);
        return {
            lat: pointOnMapReproj[1],
            lon: pointOnMapReproj[0]
        };
    },

    /**
     * Converts coordinates to a world projection
     */
    convertCoordsToWGS84(coords) {
        var pointOnMapReproj = proj4(METER_PROJECTION, '+proj=longlat +datum=WGS84 +no_defs', [coords.lon, coords.lat]);
        return {
            lat: pointOnMapReproj[1],
            lon: pointOnMapReproj[0]
        };
    },

    rotatePoint(latlonPoint, angleDeg, latlonCenter) {
        var point = turf.point(latlonPoint)
        var options = {
            pivot: latlonCenter
        };
        var rotatedPoint = turf.transformRotate(point, angleDeg, options);
        return rotatedPoint.geometry.coordinates;
    }
}