<template>
    <v-dialog v-model="isOpen" persistent scrollable width="90vw">
      <v-card tile>
        <v-expand-transition>
          <div v-show="expandErrorMessages">
            <v-alert class="mb-0" tile type="error" @click="expandErrorMessages = false">
              <div v-for="(message, index) in this.errorMessages" :key="index" class="subtitle">
                {{ message }}
              </div>
            </v-alert>
          </div>
        </v-expand-transition>
        <v-progress-linear height="10" striped color="primary" indeterminate v-show="loading"></v-progress-linear>
        <v-toolbar color="primary" flat>
          <v-toolbar-title>
            <span>
              <v-icon left>mdi-database-import</v-icon>{{ $t("entry_requests.import_entries_help_title") }}
            </span>
          </v-toolbar-title>
          <v-spacer />
          <v-btn icon @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-5">
          <v-alert color="blue-grey" text icon="mdi-information-outline">
            <div class="font-weight-black">
              {{ $t("entry_requests.import_entries_help_title") }}
            </div>
            <div class="mt-2">
              <div class="ma-0">
                <div v-html="$t('entry_requests.import_entries_help_description')" class="d-inline"></div>
                <a href="#" @click.stop="downloadTemplate">{{ $t('global.excel_file_template') }}</a>
              </div>
            </div>
          </v-alert>
          <v-form ref="form" class="mt-5" :disabled="loading">

            <v-stepper vertical flat v-model="currentStep">
              <v-stepper-step step="1" :complete="isStepComplete(1)">
                {{ $t("entry_requests.ship_process") }}
                <small class="pt-1">{{
                  $t("entry_requests.ship_process_help")
                }}</small>
              </v-stepper-step>
              <v-stepper-content step="1">
                <v-card flat>
                  <VesselCallsSelector :key="componentKey" v-model="vesselCallId" :item.sync="vesselCall"
                    :date.sync="day" :archivedPlanDates="archivedPlanDates">
                  </VesselCallsSelector>
                </v-card>
              </v-stepper-content>
              <v-stepper-step step="2" :complete="isStepComplete(2)">
                {{ $t("entry_requests.import_file") }}
                <small class="pt-1">{{
                  $t("entry_requests.import_file_help")
                }}</small>
              </v-stepper-step>
              <v-stepper-content step="2">
                <v-card flat class="py-2">
                  <v-file-input v-model="file" show-size accept=".xlsx" outlined prepend-icon
                    prepend-inner-icon="mdi-microsoft-excel" :rules="fileRules" persistent-hint
                    :label="$t('entry_requests.import_file')"></v-file-input>
                </v-card>
              </v-stepper-content>
              <v-stepper-step step="3" :complete="isStepComplete(3)">
                {{ $t("entry_requests.briefing") }}
                <small class="pt-1">{{
                  $t("entry_requests.briefing_help")
                }}</small>
              </v-stepper-step>
              <v-stepper-content step="3">
                <v-card flat style="position: relative;min-height: 200px;"
                  class="d-flex flex-column justify-center align-center">
                  <Loading v-if="loading" :value="loading"
                    :custom-message="hasSubmitted ? $t('entry_requests.importing_entries_please_wait') : $t('global.verifying_file_please_wait')">
                  </Loading>
                  <template v-else>
                    <ImportEntryRequestsList v-if="hasAnyRowsToShow" :items="rowItems">
                      <template v-slot:title>
                        <template v-if="hasErrors">
                          <v-icon left>mdi-alert-circle-outline</v-icon>
                          {{ $t("entry_requests.errors_importing_entries") }}
                        </template>
                        <template v-else>
                          <v-icon left>mdi-check-circle-outline</v-icon>
                          {{ $t("entry_requests.entries_to_import") }}
                        </template>

                      </template>
                      <template v-slot:subtitle>
                        <template v-if="hasErrors">
                          {{ $t("entry_requests.entries_were_not_created_please_review") }}
                        </template>
                        <template v-else>
                          {{ $t("entry_requests.entries_about_to_be_imported") }}
                        </template>
                      </template>
                    </ImportEntryRequestsList>
                    <div v-else class="text-h6">{{ $t('global.file_is_empty') }}</div>
                  </template>
                </v-card>
              </v-stepper-content>
            </v-stepper>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-chip v-if="!!vesselCall" small outlined label class="font-weight-bold"><v-icon x-small
              left>mdi-ferry</v-icon>{{
                vesselCall.name }}</v-chip>
          <v-chip v-if="!!day" class="ml-2 font-weight-bold" small outlined label><v-icon x-small
              left>mdi-calendar</v-icon>{{
                formattedDay }}</v-chip>
          <v-spacer />
          <v-btn :disabled="isFirstStep" @click.stop="goToPreviousStep" depressed color="primary"
            class="black--text"><v-icon small>mdi-arrow-up</v-icon>{{ $t("global.back") }}
          </v-btn>
          <v-btn v-if="isFormComplete" class="black--text" color="primary" depressed :disabled="loading"
            @click="importFile">
            <v-icon left> mdi-upload</v-icon>{{ $t("global.import") }}
          </v-btn>
          <v-btn v-else :disabled="!isNextStepAvailable" depressed color="primary" class="black--text"
            @click.stop="goToNextStep"><v-icon small>mdi-arrow-down</v-icon>{{ $t("global.advance") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

</template>
<script>
import Loading from "@/components/Main/Loading";
import ImportEntryRequestsList from "@/components/Main/EntryRequests/ImportEntryRequestsList";
import VesselCallsSelector from "@/components/Main/VesselCalls/VesselCallsSelector";
import configs from "@/helpers/configs";
import { Settings, DateTime } from "luxon";
Settings.defaultZone = configs.getTimezone();
Settings.defaultLocale = configs.getLocale();
export default {
  props: ["open","success"],
  components: { Loading, ImportEntryRequestsList, VesselCallsSelector },
  data() {
    return {
      loading: false,
      fileRules: [(v) => !!v || this.$t("rules.file_required")],
      file: null,
      items: [],
      hasSubmitted: false,
      expandErrorMessages: false,
      errorMessages: [],

      currentStep: 1,
      vesselCallId: null,
      vesselCall: null,
      day: null,
      archivedPlanDates: [],


      componentKey: 0,
    };
  },
  computed: {
    isOpen: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("update:open", val);
      },
    },

    hasErrors() {
      return this.items.some((e) => e.type == "ERROR");
    },

    hasAnyRowsToShow() {
      return this.items.some((e) => !!e.data);
    },

    generalErrorMessages() {
      return this.items.filter((e) => e.type == "ERROR" && !e.data).map((e) => e.message);
    },

    rowItems() {
      return this.items.filter((e) => !!e.data);
    },

    isNextStepAvailable() {
      switch (this.currentStep) {
        case 1:
          return !!this.vesselCall && !!this.day;
        case 2:
          return !!this.file;
      }
      return false;
    },

    isFirstStep() {
      return this.currentStep == 1;
    },

    isFormComplete() {
      return this.currentStep == 3 && this.$refs.form.validate();
    },

    formattedDay() {
      return DateTime.fromJSDate(this.day).toFormat("dd/LL/yyyy");
    },
    successMessage: {
      get() {
        return this.success;
      },
      set(val) {
        this.$emit("update:success", val);
      },
    },
  },
  watch: {
    isOpen(val) {
      if (val) {
        this.$nextTick(() => {
          this.componentKey++;
          this.expandErrorMessages = false;
          this.loading = false;
          this.hasSubmitted = false;
          this.items = [];
          this.currentStep = 1;
          this.vesselCallId = null;
          this.vesselCall = null;
          this.day = null;
          this.$refs.form.reset();
          this.getArchivedPlanDates();
        });
      }
    }
  },
  methods: {
    mapResToItem(item) {
      return {
        ...item.record,
        message: item.message,
        type: item.type,
      };
    },

    getArchivedPlanDates() {
      this.$store
        .dispatch("plans/GET_ARCHIVED_PLAN_DATES")
        .then((data) => {
          this.archivedPlanDates = data;
        })
        .catch((e) => {
          this.archivedPlanDates = [];
          console.error(e);
        });
    },

    goToNextStep() {
      this.currentStep++;
      if (this.currentStep == 3) {
        this.previewFile();
      }
    },

    goToPreviousStep() {
      this.currentStep--;
    },

    isStepComplete(step) {
      return step <= this.currentStep;
    },

    downloadTemplate() {
      this.$store
        .dispatch("entry_requests/DOWNLOAD_TEMPLATE");
    },

    uploadFile() {
      return new Promise((resolve) => {
        this.loading = true;
        this.items = [];

        if (this.$refs.form.validate()) {
          this.$store
            .dispatch("entry_requests/IMPORT", { file: this.file, dryrun: !this.hasSubmitted, vessel_call_id: this.vesselCallId, day: this.formattedDay })
            .then((res) => {

              // res contains "upserts" (lines inserted or about to be inserted) and "errors"
              let resultItems = res.upserts.map(this.mapResToItem);
              resultItems = resultItems.concat(res.errors.map(this.mapResToItem));

              this.items = resultItems;

              if (res.errors && res.errors.length > 0) {
                this.errorMessages = [
                  this.$t("global.file_import_error"),
                  ...this.generalErrorMessages];
                this.expandErrorMessages = true;
              }
              this.loading = false;
              resolve();
            })
            .catch(() => {
              this.errorMessages = [this.$t("global.file_import_error")];
              this.expandErrorMessages = true;
              this.loading = false;
              this.items = [];
              resolve();
            });
        } else {
          this.loading = false;
          this.items = [];
          this.errorMessages = [this.$t("global.file_import_error")];
          this.expandErrorMessages = true;
          resolve();
        }
      });
    },

    previewFile() {
      this.reset();
      if (this.file) {
        this.uploadFile();
      }
    },

    async importFile() {
      if (this.$refs.form.validate()) {
        this.hasSubmitted = true;
        await this.uploadFile();
        if (!this.hasErrors) {
          this.successMessage = true;
          this.$emit('entryRequestsimported');
          this.close();
        }
      }
    },

    close() {
      this.isOpen = false;
    },

    reset() {
      this.expandErrorMessages = false;
      this.loading = false;
      this.hasSubmitted = false;
      this.items = [];
    }
  },
};
</script>
