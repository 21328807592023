var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-iterator',{attrs:{"items":_vm.items,"item-key":"id","sort-by":"scanned_at","page":_vm.options.page,"items-per-page":_vm.options.itemsPerPage,"loading":_vm.loading,"server-items-length":_vm.totalItemCount,"hide-default-footer":""},on:{"update:options":function($event){return _vm.handleOptionsUpdate('options', $event)},"update:page":function($event){return _vm.$set(_vm.options, "page", $event)},"update:itemsPerPage":function($event){return _vm.$set(_vm.options, "itemsPerPage", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.options, "itemsPerPage", $event)},"update:serverItemsLength":function($event){_vm.totalItemCount=$event},"update:server-items-length":function($event){_vm.totalItemCount=$event}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('v-layout',{staticClass:"px-2",attrs:{"row":"","wrap":""}},[_c('v-flex',{staticClass:"pa-1 py-2",attrs:{"xs9":!_vm.$vuetify.breakpoint.mobile}},[_c('v-text-field',{ref:"searchField",attrs:{"hide-details":"","flat":"","solo":"","label":_vm.$t('global.search'),"prepend-inner-icon":"mdi-magnify","outlined":"","disabled":_vm.loading},on:{"input":_vm.filterItems},model:{value:(_vm.options.search),callback:function ($$v) {_vm.$set(_vm.options, "search", $$v)},expression:"options.search"}})],1),_c('v-flex',{staticClass:"pa-1 py-2",attrs:{"xs3":!_vm.$vuetify.breakpoint.mobile}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":""},on:{"input":_vm.onMenuToggle},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"readonly":"","label":_vm.$t('global.filter_scanned_at'),"outlined":"","hide-details":"","color":"deep-purple accent-4","disabled":_vm.loading},model:{value:(_vm.filterByDatesText),callback:function ($$v) {_vm.filterByDatesText=$$v},expression:"filterByDatesText"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.pickerMenu),callback:function ($$v) {_vm.pickerMenu=$$v},expression:"pickerMenu"}},[_c('vc-date-picker',{attrs:{"mode":"dateTime","timezone":"utc","is24hr":"","is-range":"","is-expanded":"","model-config":_vm.modelConfig},model:{value:(_vm.options.filterByDates),callback:function ($$v) {_vm.$set(_vm.options, "filterByDates", $$v)},expression:"options.filterByDates"}})],1)],1)],1)]},proxy:true},{key:"no-results",fn:function(){return [_c('v-container',{attrs:{"fill-height":"","fluid":""}},[_c('v-row',{staticClass:"text-h6 text-center pa-10",attrs:{"align":"center","justify":"center"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-car-off")]),_vm._v(_vm._s(_vm.$t("access_control_page.not_found_exits"))+" ")],1)],1)]},proxy:true},{key:"no-data",fn:function(){return [_c('v-container',{attrs:{"fill-height":"","fluid":""}},[_c('v-row',{staticClass:"text-h6 text-center pa-10",attrs:{"align":"center","justify":"center"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-car-off")]),_vm._v(_vm._s(_vm.$t("access_control_page.not_found"))+" ")],1)],1)]},proxy:true},{key:"loading",fn:function(){return [_c('div',{staticStyle:{"position":"relative","min-height":"250px","margin-top":"20px"}},[_c('Loading',{attrs:{"value":_vm.loading}})],1)]},proxy:true},{key:"default",fn:function(ref){
var items = ref.items;
return [_c('v-row',{staticClass:"px-0"},_vm._l((items),function(item,index){return _c('v-col',{key:index,attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('ScannedExitsCard',{class:_vm.selectedItem && _vm.selectedItem.id == item.id
                ? 'entry-selected'
                : '',attrs:{"item":item},on:{"selected":_vm.handleSelectedItem}})],1)}),1)]}}])}),_c('v-dialog',{attrs:{"width":_vm.$vuetify.breakpoint.mobile ? '90vw' : '40vw'},model:{value:(_vm.viewNoteDialog),callback:function ($$v) {_vm.viewNoteDialog=$$v},expression:"viewNoteDialog"}},[_c('v-card',{attrs:{"tile":""}},[_c('v-toolbar',{attrs:{"color":"primary","flat":""}},[_c('v-toolbar-title',[_c('span',[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-note-edit")]),_vm._v(_vm._s(_vm.$t("global.notes"))+" ")],1)]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.viewNoteDialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',{staticClass:"mt-5"},[_c('v-textarea',{attrs:{"outlined":"","name":"input-7-4","no-resize":"","rows":"5","hide-details":"","readonly":""},model:{value:(_vm.notes),callback:function ($$v) {_vm.notes=$$v},expression:"notes"}})],1)],1)],1),_c('v-row',{staticClass:"text-center px-4 align-center",attrs:{"wrap":""}},[(_vm.options && !_vm.loading && _vm.pageCount != 1)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-pagination',{attrs:{"dense":"","flat":"","length":_vm.pageCount},model:{value:(_vm.options.page),callback:function ($$v) {_vm.$set(_vm.options, "page", $$v)},expression:"options.page"}})],1):_vm._e()],1),_c('ExportScheduledExitScansDialog',{attrs:{"open":_vm.exportDialog,"options":_vm.options},on:{"update:open":function($event){_vm.exportDialog=$event}}}),_c('ScannedExitsOptions',{attrs:{"item":_vm.selectedItem,"loading":_vm.loadingEntryActions},on:{"update:item":function($event){_vm.selectedItem=$event},"revokeScan":_vm.handleRevokeScan,"viewNote":_vm.handleViewNote,"update:loading":function($event){_vm.loadingEntryActions=$event}},model:{value:(_vm.showEntryActions),callback:function ($$v) {_vm.showEntryActions=$$v},expression:"showEntryActions"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }