<template>
  <v-card flat>
    <v-card-title class="text-h4 px-0 mb-3">
      Bem-vindo, <b class="ml-1">{{ userFirstName }}</b>
    </v-card-title>
    <v-card-text class="px-0 pb-0" v-if="isShorexUser">
      <v-card v-if="user" outlined class="pa-2">
        <v-card-title class="text-h6 font-weight-black text-uppercase">
          {{ $t("global.my_data") }}
          <v-icon
            v-if="$vuetify.breakpoint.name == 'xs'"
            @click="showUserInfoSheet = !showUserInfoSheet"
            class="ml-auto"
          >
            mdi-information
          </v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-list-item v-if="$vuetify.breakpoint.name == 'xs'" class="pl-0">
          <v-list-item-avatar>
            <v-icon> mdi-domain</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              {{ user.name }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-card-text v-if="$vuetify.breakpoint.name != 'xs'" class="pa-0">
          <v-list>
            <v-row align="start" no-gutters>
              <v-col cols="12" sm="4" md="4">
                <v-list-item class="pl-0">
                  <v-list-item-avatar>
                    <v-icon> mdi-domain</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-bold text-subtitle-1">
                      {{ user.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle class=""
                      >{{ $t("users.name") }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>

              <v-col cols="12" sm="4" md="4">
                <v-list-item class="pl-0">
                  <v-list-item-avatar>
                    <v-icon> mdi-at</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-bold text-subtitle-1">
                      <a :href="'mailto:' + user.email">{{ user.email }}</a>
                    </v-list-item-title>
                    <v-list-item-subtitle class=""
                      >{{ $t("users.email") }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-list-item class="pl-0">
                  <v-list-item-avatar>
                    <v-icon> mdi-phone</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-bold text-subtitle-1">
                      <a :href="'tel:' + user.phone">{{ user.phone }}</a>
                    </v-list-item-title>
                    <v-list-item-subtitle class=""
                      >{{ $t("users.phone") }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
          </v-list>
        </v-card-text>
      </v-card>
    </v-card-text>
    <v-card-text class="px-0 pb-0" v-if="isShorexUser">
      <v-card v-if="operator" outlined class="pa-2">
        <v-card-title class="text-h6 font-weight-black text-uppercase">
          {{ $t("global.my_operator") }}
          <v-icon
            v-if="$vuetify.breakpoint.name == 'xs'"
            @click="showOperatorInfoSheet = !showOperatorInfoSheet"
            class="ml-auto"
          >
            mdi-information
          </v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-list-item v-if="$vuetify.breakpoint.name == 'xs'" class="pl-0">
          <v-list-item-avatar>
            <v-icon> mdi-domain</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              {{ operator.name }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-card-text v-if="$vuetify.breakpoint.name != 'xs'" class="pa-0">
          <v-list>
            <v-row align="start" no-gutters>
              <v-col cols="12" sm="4" md="4">
                <v-list-item class="pl-0">
                  <v-list-item-avatar>
                    <v-icon> mdi-domain</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-bold text-subtitle-1">
                      {{ operator.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle class=""
                      >{{ $t("operators.name") }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>

              <v-col cols="12" sm="4" md="4">
                <v-list-item class="pl-0">
                  <v-list-item-avatar>
                    <v-icon> mdi-train-car</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-bold text-subtitle-1">
                      {{ operator.types.map((type) => type.name).join(", ") }}
                    </v-list-item-title>
                    <v-list-item-subtitle class=""
                      >{{ $t("operators.operator_type") }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>

              <v-col cols="12" sm="4" md="4">
                <v-list-item class="pl-0">
                  <v-list-item-avatar>
                    <v-icon> mdi-anchor</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-bold text-subtitle-1">
                      {{ locode.name }}
                    </v-list-item-title>

                    <v-list-item-subtitle class=""
                      >{{ $t("global.port") }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-list-item class="pl-0">
                  <v-list-item-avatar>
                    <v-icon> mdi-id-card</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-bold text-subtitle-1">
                      {{ operator.vat_number }}
                    </v-list-item-title>
                    <v-list-item-subtitle class=""
                      >{{ $t("global.vat") }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>

              <v-col cols="12" sm="4" md="4">
                <v-list-item class="pl-0">
                  <v-list-item-avatar>
                    <v-icon> mdi-at</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-bold text-subtitle-1">
                      <a :href="'mailto:' + operator.email">{{
                        operator.email
                      }}</a>
                    </v-list-item-title>
                    <v-list-item-subtitle class=""
                      >{{ $t("operators.email") }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-list-item class="pl-0">
                  <v-list-item-avatar>
                    <v-icon> mdi-phone</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-bold text-subtitle-1">
                      <a :href="'tel:' + operator.phone">{{
                        operator.phone
                      }}</a>
                    </v-list-item-title>
                    <v-list-item-subtitle class=""
                      >{{ $t("operators.phone") }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
          </v-list>
        </v-card-text>
      </v-card>
    </v-card-text>
    <v-bottom-sheet v-model="showUserInfoSheet">
      <v-card v-if="!!user">
        <v-card-title class="text-h6 font-weight-black text-uppercase">
          {{ $t("global.my_data") }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="px-3">
          <v-list>
            <v-row align="start" no-gutters>
              <v-col cols="12" sm="4" md="4">
                <v-list-item class="pl-0">
                  <v-list-item-avatar>
                    <v-icon> mdi-domain</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-bold text-subtitle-1">
                      {{ user.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle class=""
                      >{{ $t("users.name") }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>

              <v-col cols="12" sm="4" md="4">
                <v-list-item class="pl-0">
                  <v-list-item-avatar>
                    <v-icon> mdi-at</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-bold text-subtitle-1">
                      <a :href="'mailto:' + user.email">{{ user.email }}</a>
                    </v-list-item-title>
                    <v-list-item-subtitle class=""
                      >{{ $t("users.email") }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-list-item class="pl-0">
                  <v-list-item-avatar>
                    <v-icon> mdi-phone</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-bold text-subtitle-1">
                      <a :href="'tel:' + user.phone">{{ user.phone }}</a>
                    </v-list-item-title>
                    <v-list-item-subtitle class=""
                      >{{ $t("users.phone") }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
          </v-list>
        </v-card-text>
      </v-card>
    </v-bottom-sheet>
    <v-bottom-sheet v-model="showOperatorInfoSheet">
      <v-card v-if="!!operator">
        <v-card-title class="text-h6 font-weight-black text-uppercase">
          {{ $t("global.my_operator") }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="px-3">
          <v-list>
            <v-row align="start" no-gutters>
              <v-col cols="12" sm="4" md="4">
                <v-list-item class="pl-0">
                  <v-list-item-avatar>
                    <v-icon> mdi-domain</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-bold text-subtitle-1">
                      {{ operator.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle class=""
                      >{{ $t("operators.name") }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>

              <v-col cols="12" sm="4" md="4">
                <v-list-item class="pl-0">
                  <v-list-item-avatar>
                    <v-icon> mdi-train-car</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-bold text-subtitle-1">
                      {{ operator.types.map((type) => type.name).join(", ") }}
                    </v-list-item-title>
                    <v-list-item-subtitle class=""
                      >{{ $t("operators.operator_type") }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>

              <v-col cols="12" sm="4" md="4">
                <v-list-item class="pl-0">
                  <v-list-item-avatar>
                    <v-icon> mdi-anchor</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-bold text-subtitle-1">
                      {{ locode.name }}
                    </v-list-item-title>

                    <v-list-item-subtitle class=""
                      >{{ $t("global.port") }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-list-item class="pl-0">
                  <v-list-item-avatar>
                    <v-icon> mdi-id-card</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-bold text-subtitle-1">
                      {{ operator.vat_number }}
                    </v-list-item-title>
                    <v-list-item-subtitle class=""
                      >{{ $t("global.vat") }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>

              <v-col cols="12" sm="4" md="4">
                <v-list-item class="pl-0">
                  <v-list-item-avatar>
                    <v-icon> mdi-at</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-bold text-subtitle-1">
                      <a :href="'mailto:' + operator.email">{{
                        operator.email
                      }}</a>
                    </v-list-item-title>
                    <v-list-item-subtitle class=""
                      >{{ $t("operators.email") }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-list-item class="pl-0">
                  <v-list-item-avatar>
                    <v-icon> mdi-phone</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-bold text-subtitle-1">
                      <a :href="'tel:' + operator.phone">{{
                        operator.phone
                      }}</a>
                    </v-list-item-title>
                    <v-list-item-subtitle class=""
                      >{{ $t("operators.phone") }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
          </v-list>
        </v-card-text>
      </v-card>
    </v-bottom-sheet>
  </v-card>
</template>
<script>
import configs from "@/helpers/configs";

export default {
  data() {
    return {
      showOperatorInfoSheet: false,
      showUserInfoSheet: false,
    };
  },

  computed: {
    userAccount() {
      return this.$store.state.user.account;
    },
    userFirstName() {
      return this.userAccount
        ? this.userAccount.user.name.split(" ").shift()
        : "";
    },
    user() {
      return this.userAccount && this.userAccount.user
        ? this.userAccount.user
        : null;
    },
    operator() {
      return this.$store.state.operators.currentOperator;
    },
    userType() {
      return this.userAccount && this.userAccount.user
        ? this.userAccount.user.type
        : "";
    },
    locode() {
      return configs.getLocode();
    },
    isShorexUser() {
      return this.userType == configs.getShorexUserType();
    },
    isAdminUser() {
      return this.userType == configs.getAdminUserType();
    },
  },
};
</script>
