<template>
  <v-dialog v-model="isOpen" persistent width="900" scrollable>
    <v-card tile>
      <v-expand-transition>
        <div v-show="expand">
          <v-alert class="mb-0" tile type="error" @click="expand = false">
            <div
              v-for="(message, index) in this.messages"
              :key="index"
              class="subtitle"
            >
              {{ message }}
            </div>
          </v-alert>
        </div>
      </v-expand-transition>
      <v-toolbar color="primary" flat>
        <v-toolbar-title>
          <span>
            <v-icon left>mdi-pencil</v-icon>{{ $t("vehicles.edit") }}
          </span>
        </v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="overflow-y-auto pb-0 form-content">
        <Loading v-if="loading" v-model="loading"></Loading>
        <Loading
          v-if="saving"
          :value="saving"
          :custom-message="$t('global.updating_please_wait')"
        ></Loading>
        <v-form v-else ref="form" class="mt-2">
          <div v-if="!!itemToEdit">
            <VehicleTypeSelector
              v-model="itemToEdit.vehicle_type"
              :disabled="true"
            ></VehicleTypeSelector>
            <v-divider class="mb-4"></v-divider>
            <v-alert color="blue-grey" text icon="mdi-information-outline">
              {{ $t("vehicles.vehicles_form_step_2") }}
            </v-alert>
            <v-autocomplete
              v-model="itemToEdit.operator_id"
              :disabled="true"
              :items="operators"
              prepend-inner-icon="mdi-domain"
              :label="$t('vehicles.operator')"
              item-text="name"
              item-value="id"
              flat
              outlined
              clearable
              hide-no-data
              cache-items
              :loading="operatorsLoading"
            >
            </v-autocomplete>
            <v-text-field
              counter="25"
              maxlength="25"
              autocomplete="off"
              v-model="itemToEdit.registration_plate"
              :label="$t('vehicles.license_plate')"
              :placeholder="$t('vehicles.license_plate')"
              prepend-inner-icon="mdi-card-text"
              :disabled="true"
              outlined
              class="registration-plate-font"
            />
            <v-text-field
              counter="25"
              maxlength="25"
              autocomplete="off"
              required
              v-model="itemToEdit.brand"
              :label="$t('vehicles.brand')"
              :placeholder="$t('vehicles.brand')"
              :rules="characterRules"
              prepend-inner-icon="mdi-text"
              outlined
            ></v-text-field>
            <v-text-field
              counter="25"
              maxlength="25"
              autocomplete="off"
              v-model="itemToEdit.model"
              :label="$t('vehicles.model')"
              :placeholder="$t('vehicles.model')"
              :rules="characterRules"
              prepend-inner-icon="mdi-car-info"
              outlined
            ></v-text-field>

            <v-text-field
              counter="25"
              maxlength="25"
              autocomplete="off"
              v-model="itemToEdit.responsible_name"
              :label="$t('vehicles.responsible_name')"
              :placeholder="$t('vehicles.responsible_name')"
              prepend-inner-icon="mdi-card-account-details"
              outlined
            ></v-text-field>

            <v-text-field
              counter="25"
              maxlength="25"
              v-model="itemToEdit.phone"
              prepend-inner-icon="mdi-phone"
              :label="$t('vehicles.contact_phone')"
              :rules="phoneNumberRules"
              outlined
            />

            <v-text-field
              v-model="itemToEdit.seating_capacity"
              type="number"
              :label="$t('vehicles.seating_capacity')"
              :placeholder="$t('vehicles.seating_capacity')"
              :rules="seatingCapacityRules"
              prepend-inner-icon="mdi-car-seat"
              outlined
            ></v-text-field>

            <v-fade-transition>
              <div v-show="itemToEdit.vehicle_type == 'TAXI'">
                <v-text-field
                  counter="10"
                  maxlength="10"
                  autocomplete="off"
                  v-model="itemToEdit.license_number"
                  type="text"
                  :label="$t('vehicles.license_number')"
                  :placeholder="$t('vehicles.license_number')"
                  prepend-inner-icon="mdi-car-door"
                  outlined
                  :disabled="true"
                  class="registration-plate-font"
                ></v-text-field>
                <v-text-field
                  counter="10"
                  maxlength="10"
                  autocomplete="off"
                  v-model="itemToEdit.permit_number"
                  type="text"
                  :label="$t('vehicles.permit_number')"
                  :placeholder="$t('vehicles.permit_number')"
                  prepend-inner-icon="mdi-license"
                  outlined
                  :disabled="true"
                ></v-text-field>
              </div>
            </v-fade-transition>
          </div>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn
          depressed
          large
          color="error"
          :disabled="loading || saving || operatorsLoading"
          @click="cancelVehicle"
        >
          <v-icon left>mdi-cancel</v-icon>
          {{ $t("global.cancel_vehicle") }}
        </v-btn>
        <v-spacer />
        <v-btn
          class="black--text"
          color="primary"
          depressed
          :disabled="loading || saving || operatorsLoading"
          @click="save"
        >
          {{ $t("global.update") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { phoneNumberValidation } from "@/helpers/common_validations.js";
import VehicleTypeSelector from "@/components/Main/Vehicles/VehicleTypeSelector";
import Loading from "@/components/Main/Loading";
import DateInput from "@/components/Main/DateInput";
export default {
  props: ["open", "item"],
  components: { VehicleTypeSelector, Loading, DateInput },
  data() {
    return {
      dateNow: new Date(),
      loading: false,
      saving: false,
      expand: false,
      messages: [],
      vehicle_image: null,
      itemToEdit: null,
      imageUrl: null,

      phoneNumberRules: [phoneNumberValidation],

      characterRules: [
        (v) => {
          if (!v || (v && v.length <= 255)) return true;
          return this.$t("rules.character_limit");
        },
      ],
      seatingCapacityRules: [
        (v) => {
          if (v && v.toString().trim() !== "") {
            if (/^[0-9]+$/.test(v)) {
              const parsedValue = parseInt(v);
              return parsedValue > 0
                ? true
                : this.$t("rules.invalid_positive_integer");
            } else {
              return this.$t("rules.invalid_positive_integer");
            }
          }
          return true;
        },
      ],
    };
  },
  computed: {
    operatorsLoading() {
      return this.$store.state.operators.loading;
    },
    operators() {
      return this.$store.state.operators.all;
    },
    isOpen: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("update:open", val);
      },
    },
  },
  watch: {
    open(val) {
      if (val) {
        this.$nextTick(() => {
          this.$refs.form.resetValidation();
          this.vehicle_image = null;
          this.$store.dispatch("operators/GET_ALL");
          this.loadVehicleDetails();
        });
      }
    },
    itemToEdit: {
      handler(val) {
        if (val && val.vehicle_type != "TAXI") {
          val.license_number = "";
          val.permit_number = "";
        }
      },
      deep: true,
    },
  },
  methods: {
    loadVehicleDetails() {
      if (this.item.id) {
        this.loading = true;
        this.itemToEdit = null;
        this.$store
          .dispatch("vehicles/GET_VEHICLE_BY_ID", this.item.id)
          .then((data) => {
            this.loading = false;
            this.itemToEdit = data;
          })
          .catch((e) => {
            console.error(e);
          });
      }
    },
    close() {
      this.isOpen = false;
      setTimeout(() => {
        this.expand = false;
        this.loading = false;
        this.saving = false;
      }, 600);
    },
    cancelVehicle() {
      this.$emit("cancel");
    },
    save() {
      this.expand = false;
      this.saving = true;
      this.messages = [];
      if (this.$refs.form.validate()) {
        this.$store
          .dispatch("user_vehicles/UPDATE_USER_VEHICLE", this.itemToEdit)
          .then((res) => {
            if (res.errors) {
              Object.entries(res.errors).forEach(([, value]) => {
                this.messages = this.messages.concat(value);
              });
              this.expand = true;
              this.saving = false;
            } else {
              this.$emit("closeVehicleDetails");
              this.close();
            }
          });
      } else {
        this.expand = false;
        this.saving = false;
      }
    },
  },
};
</script>
<style scoped>
.form-content {
  position: relative;
  min-height: calc(50vh - var(--toolbar-height) - 40px);
  max-height: calc(80vh - var(--toolbar-height) - 40px);
}
.registration-plate-font >>> .v-input__control input {
  font-family: "Fira Mono", monospace !important;
  font-weight: 300 !important;
}
</style>
