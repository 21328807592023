var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticStyle:{"min-height":"150px"}},[_c('Loading',{model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}}),(!_vm.loading)?_c('div',{staticClass:"fill-height"},[_c('v-text-field',{staticClass:"mb-2 px-1",attrs:{"disabled":_vm.loading,"clearable":"","flat":"","solo":"","outlined":"","hide-details":"","prepend-inner-icon":"mdi-magnify","label":_vm.$t('global.search')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),(_vm.items.length == 0)?_c('div',{staticClass:"text-center no-results"},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-close")]),_vm._v(_vm._s(_vm.$t("vessel_calls.no_vessel_calls_found"))+" ")],1):_c('v-item-group',{model:{value:(_vm.itemSelected),callback:function ($$v) {_vm.itemSelected=$$v},expression:"itemSelected"}},[_c('v-row',_vm._l((_vm.items),function(item){return _c('v-col',{key:item.id,attrs:{"cols":"12","lg":"3","md":"4","sm":"12"}},[_c('v-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-card',{staticClass:"ma-1",attrs:{"dark":active,"color":active ? 'primary' : '',"width":"auto","flat":"","outlined":""},on:{"click":toggle}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_c('span',{staticClass:"font-weight-black text-uppercase"},[_vm._v(_vm._s(item.name))])]),_c('v-spacer'),(active)?_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v("mdi-check-circle")])],1):_vm._e()],1),_c('v-divider'),_c('v-card-text',{staticClass:"pa-0"},[_c('div',{staticClass:"backgroundIcon"},[_c('v-icon',{attrs:{"color":"grey lighten-4","size":"80"}},[_vm._v("mdi-ferry")])],1),_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-weight-bold text-subtitle-1"},[_vm._v(_vm._s(_vm._f("formatDatetimeWithoutTimezone")(item.eta)))]),_c('v-list-item-subtitle',{staticClass:"text-caption"},[_vm._v(_vm._s(_vm.$t("vessel_calls.expected_arrival"))+" ")])],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-weight-bold text-subtitle-1"},[_vm._v(_vm._s(_vm._f("formatDatetimeWithoutTimezone")(item.etd)))]),_c('v-list-item-subtitle',{staticClass:"text-caption"},[_vm._v(_vm._s(_vm.$t("vessel_calls.expected_departure"))+" ")])],1)],1),(active)?_c('v-list-item',[_c('v-list-item-content',[_c('v-select',{attrs:{"outlined":"","dense":"","label":_vm.$t('entry_requests.request_day'),"items":_vm.availableDays,"hide-details":""},on:{"click":function($event){$event.stopPropagation();}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatJSDate")(item))+" ")]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatJSDate")(item))+" ")]}}],null,true),model:{value:(_vm.vesselCallDate),callback:function ($$v) {_vm.vesselCallDate=$$v},expression:"vesselCallDate"}})],1)],1):_vm._e()],1)],1)],1)]}}],null,true),model:{value:(item.id),callback:function ($$v) {_vm.$set(item, "id", $$v)},expression:"item.id"}})],1)}),1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }