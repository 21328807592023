import { vehiclesService } from "@/api_services/vehicles.service";

const DEFAULT_STATE = {
  all: [],
  loading: false,
  available: [],
  options: {
    search: "",
    page: 1,
    itemsPerPage: 10,
    sortBy: "registration_plate",
    sortDesc: false,
    filterByType: null,
    filterByStatus: null,
  },
  total: 0,
};

export default {
  namespaced: true,
  state: Object.assign({}, JSON.parse(JSON.stringify(DEFAULT_STATE))),
  mutations: {
    setOptions(state, options) {
      state.options = options;
    },
    setTotal(state, total) {
      state.total = total;
    },
    setVehicles(state, items) {
      state.all = items;
    },
    setLoading(state, isLoading) {
      state.loading = isLoading;
    },
    setAvailable(state, available) {
      state.available = available;
    },
    setDefaultState(state) {
      Object.assign(state, JSON.parse(JSON.stringify(DEFAULT_STATE)));
    },
  },
  actions: {
    UPDATE_OPTIONS(context, options) {
      context.commit("setOptions", options);
    },
    GET_ALL(context, options) {
      return new Promise((resolve, reject) => {
        context.commit("setLoading", true);
        if (options) {
          context.commit("setOptions", options);
        }
        vehiclesService
          .getVehicles(context.state.options)
          .then((res) => {
            context.commit("setVehicles", res.data.data);
            context.commit("setTotal", res.data.total);
            context.commit("setLoading", false);
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            context.commit("setLoading", false);
            reject(e);
          });
      });
    },
    GET_VEHICLE_BY_ID: (_, id) => {
      return new Promise((resolve, reject) => {
        vehiclesService
          .getVehicle(id)
          .then((res) => {
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },
    CREATE: (context, vehicle) => {
      return new Promise((resolve, reject) => {
        vehiclesService
          .createVehicle(vehicle)
          .then((res) => {
            resolve(res.data);
            context.dispatch("GET_ALL");
          })
          .catch((error) => {
            console.error(error);
            reject(error);
          });
      });
    },
    UPDATE: (context, vehicle) => {
      return new Promise((resolve, reject) => {
        vehiclesService
          .updateVehicle(vehicle)
          .then((res) => {
            resolve(res.data);
            context.dispatch("GET_ALL");
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },
    DELETE: (context, vehicleId) => {
      return new Promise((resolve, reject) => {
        vehiclesService
          .deleteVehicle(vehicleId)
          .then((res) => {
            resolve(res.data);
            context.dispatch("GET_ALL");
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },
    IMPORT: (context, file) => {
      return new Promise((resolve, reject) => {
        vehiclesService
          .importVehicles(file)
          .then((res) => {
            resolve(res.data);
            context.dispatch("GET_ALL");
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },
    EXPORT: (_) => {
      return new Promise((resolve, reject) => {
        vehiclesService
          .exportVehicles()
          .then((res) => {
            var fileURL = window.URL.createObjectURL(new Blob([res.data]));
            var fileLink = document.createElement("a");

            fileLink.href = fileURL;
            fileLink.setAttribute("download", "[3SHOREX]LISTA_DE_VEICULOS.csv");
            document.body.appendChild(fileLink);

            fileLink.click();

            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    GET_AVAILABLE(context, payload) {
      return new Promise((resolve, reject) => {
        vehiclesService
          .getAvailableVehicles(payload)
          .then((res) => {
            context.commit("setAvailable", res.data);
            resolve();
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    GET_TAXIS() {
      return new Promise((resolve, reject) => {
        vehiclesService
          .getTaxis()
          .then((res) => {
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    RESET: (context) => {
      context.commit("setDefaultState");
    },
  },
};
