import { membersService } from "@/api_services/members.service";

export default {
  namespaced: true,
  state: {
    all: [],
    loading: false,
  },
  getters: {},
  mutations: {
    setMembers(state, items) {
      state.all = items;
    },
    setMembersLoading(state, isLoading) {
      state.loading = isLoading;
    },
  },
  actions: {
    GET_TEAM_MEMBERS: (context) => {
      return new Promise((resolve, reject) => {
        context.commit("setMembersLoading", true);
        context.commit("setMembers", []);
        membersService
          .getMembers()
          .then((res) => {
            context.commit("setMembers", res.data);
            context.commit("setMembersLoading", false);
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            context.commit("setMembersLoading", false);
            context.commit("setMembers", []);
            reject(e);
          });
      });
    },

    INVITE: (context, member) => {
      return new Promise((resolve, reject) => {
        membersService
          .inviteMember(member)
          .then((res) => {
            resolve(res.data);
            context.dispatch("GET_TEAM_MEMBERS");
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    REMOVE: (context, memberId) => {
      return new Promise((resolve, reject) => {
        membersService
          .removeMember(memberId)
          .then((res) => {
            resolve(res.data);
            context.dispatch("GET_TEAM_MEMBERS");
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },
  },
};
