<template>
  <v-dialog v-model="isOpen" persistent width="900">
    <v-card tile>
      <v-expand-transition>
        <div v-show="expand">
          <v-alert class="mb-0" tile type="error" @click="expand = false">
            <div
              v-for="(message, index) in this.messages"
              :key="index"
              class="subtitle"
            >
              {{ message }}
            </div>
          </v-alert>
        </div>
      </v-expand-transition>
      <v-progress-linear
        height="10"
        striped
        color="primary"
        indeterminate
        v-show="loading"
      ></v-progress-linear>
      <v-toolbar color="primary" flat>
        <v-toolbar-title>
          <span>
            <v-icon left>mdi-add</v-icon>{{ $t("noshorex_entries.create") }}
          </span>
        </v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text style="max-height: 50vh; overflow-y: auto">
        <v-form ref="form" class="mt-10">
          <v-text-field
            counter="50"
            maxlength="50"
            v-model="item.operator_name"
            required
            :label="$t('noshorex_entries.operator_name')"
            :rules="genericRule"
            outlined
          />

          <v-text-field
            counter="50"
            maxlength="50"
            v-model="item.type_name"
            required
            :label="$t('noshorex_entries.type_name')"
            :rules="genericRule"
            outlined
          />

          <v-text-field
            counter="25"
            maxlength="25"
            v-model="item.registration_plate"
            required
            :label="$t('noshorex_entries.registration_plate')"
            :rules="genericRule"
            class="registration-plate-font"
            outlined
            @keyup="transfromRegistrationPlate"
          />

          <v-menu
            v-model="pickerMenuStart"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateStartText"
                readonly
                :label="$t('noshorex_entries.vehicle_at_pier_start')"
                outlined
                color="deep-purple accent-4"
                v-bind="attrs"
                v-on="on"
                :rules="genericRule"
              ></v-text-field>
            </template>
            <vc-date-picker
              v-model="item.vehicle_at_pier_start"
              mode="dateTime"
              is24hr
              is-expanded
              :model-config="modelConfig"
            >
            </vc-date-picker>
          </v-menu>

          <v-menu
            v-model="pickerMenuEnd"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateEndText"
                readonly
                :label="$t('noshorex_entries.vehicle_at_pier_end')"
                outlined
                required
                color="deep-purple accent-4"
                v-bind="attrs"
                v-on="on"
                :rules="genericRule"
              ></v-text-field>
            </template>
            <vc-date-picker
              v-model="item.vehicle_at_pier_end"
              mode="dateTime"
              is24hr
              is-expanded
              :model-config="modelConfig"
            >
            </vc-date-picker>
          </v-menu>

          <v-textarea
            :label="$t('noshorex_entries.notes')"
            v-model="item.notes"
            counter="255"
            maxlength="255"
            outlined
            no-resize
            rows="3"
          ></v-textarea>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer />
        <v-btn
          class="black--text"
          color="primary"
          depressed
          :disabled="loading"
          @click="save"
        >
          {{ $t("global.create") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
const DEFAULT_ITEM = {
  operator_name: null,
  type_name: null,
  registration_plate: null,
  vehicle_at_pier_start: null,
  vehicle_at_pier_end: null,
  notes: null,
};
export default {
  props: ["open"],
  data() {
    return {
      loading: false,
      expand: false,
      messages: [],
      validationErrors: [],
      genericRule: [
        (v) => !!v || this.$t("rules.required_field"),
        (v) => (v && v.length <= 255) || this.$t("rules.character_limit"),
      ],
      item: Object.assign({}, DEFAULT_ITEM),
      modelConfig: {
        type: "string",
        mask: "iso",
      },
      pickerMenuStart: false,
      pickerMenuEnd: false,
    };
  },
  computed: {
    dateStartText: {
      get() {
        if (this.item.vehicle_at_pier_start) {
          return this.$options.filters.formatDatetime(
            this.item.vehicle_at_pier_start
          );
        }
        return "";
      },
      set(val) {
        return val;
      },
    },
    dateEndText: {
      get() {
        if (this.item.vehicle_at_pier_end) {
          return this.$options.filters.formatDatetime(
            this.item.vehicle_at_pier_end
          );
        }
        return "";
      },
      set(val) {
        return val;
      },
    },
    isOpen: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("update:open", val);
      },
    },
  },
  watch: {
    open(val) {
      if (val) {
        this.item = Object.assign({}, DEFAULT_ITEM);
        this.$refs.form?.reset();
      }
    },
    expand(val) {
      if (!val) {
        this.validationErrors = [];
      }
    },
  },
  methods: {
    transfromRegistrationPlate() {
      // Remove any character that is not a letter or a number and after that transform to uppercase
      this.item.registration_plate = this.item.registration_plate
        .replace(/[^a-zA-Z0-9]/g, "")
        .toUpperCase();
    },
    close() {
      this.isOpen = false;
      this.expand = false;
      this.loading = false;
    },
    save() {
      this.expand = false;
      this.loading = true;
      this.messages = [];
      if (this.$refs.form.validate()) {
        let noshorex_entry = Object.assign({}, this.item);
        this.$store
          .dispatch("noshorex_entries/CREATE", noshorex_entry)
          .then((res) => {
            if (res.errors) {
              Object.entries(res.errors).forEach(([, value]) => {
                this.messages = this.messages.concat(value);
                this.validationErrors = res.errors;
              });
              this.expand = true;
              this.loading = false;
            } else {
              this.close();
            }
          });
      } else {
        this.expand = false;
        this.loading = false;
      }
    },
  },
};
</script>
<style>
.is-today > span {
  background-color: rgb(253, 184, 19);
}
</style>
<style scoped>
.registration-plate-font >>> .v-input__control input {
  font-family: "Fira Mono", monospace !important;
  font-weight: 300 !important;
}
</style>
