<template>
  <v-bottom-sheet v-model="showNoShorexEntryDetails" :persistent="loading">
    <v-card class="text-center" tile v-if="!!selectedItem">
      <v-toolbar flat color="primary">
        <v-toolbar-title class="font-weight-bold">
          <v-icon class="blac--text" left
            >mdi-boom-gate-arrow-up-outline</v-icon
          >
          {{ $t("scheduled_entries.scheduled_entry") }}
          NO-SHOREX</v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-btn icon @click="showNoShorexEntryDetails = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="noshorex-entry-details-section pa-2 ma-0">
        <Loading v-model="loading"></Loading>
        <v-card class="pa-0 ma-0" flat v-if="!!noShorexEntryDetails">
          <v-card-text class="pa-0 ma-0">
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <tr v-if="!!noShorexEntryDetails.registration_plate">
                    <td width="50%" class="font-weight-black text-uppercase">
                      {{ $t("vehicles.registration_plate") }}
                    </td>
                    <td class="registration-plate-font">
                      {{ noShorexEntryDetails.registration_plate }}
                    </td>
                  </tr>
                  <tr v-if="!!noShorexEntryDetails.type_name">
                    <td width="50%" class="font-weight-black text-uppercase">
                      {{ $t("noshorex_entries.type_name") }}
                    </td>
                    <td>
                      {{ noShorexEntryDetails.type_name }}
                    </td>
                  </tr>
                  <tr v-if="!!noShorexEntryDetails.operator_name">
                    <td width="50%" class="font-weight-black text-uppercase">
                      {{ $t("global.operator") }}
                    </td>
                    <td>
                      {{ noShorexEntryDetails.operator_name }}
                    </td>
                  </tr>

                  <tr v-if="!!noShorexEntryDetails.vehicle_at_pier_start">
                    <td width="50%" class="font-weight-black text-uppercase">
                      {{ $t("vehicles.vehicle_at_pier_start") }}
                    </td>
                    <td>
                      {{
                        noShorexEntryDetails.vehicle_at_pier_start ||
                        formatDatetimeFromDatabase
                      }}
                    </td>
                  </tr>
                  <tr v-if="!!noShorexEntryDetails.vehicle_at_pier_end">
                    <td width="50%" class="font-weight-black text-uppercase">
                      {{ $t("vehicles.vehicle_at_pier_end") }}
                    </td>
                    <td>
                      {{
                        noShorexEntryDetails.vehicle_at_pier_end ||
                        formatDatetimeFromDatabase
                      }}
                    </td>
                  </tr>
                  <tr v-if="!!noShorexEntryDetails.notes">
                    <td width="50%" class="font-weight-black text-uppercase">
                      {{ $t("global.notes") }}
                    </td>
                    <td>
                      {{ noShorexEntryDetails.notes }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
  </v-bottom-sheet>
</template>
<script>
import Loading from "@/components/Main/Loading";
export default {
  props: ["value", "item"],
  components: { Loading },
  data() {
    return {
      loading: false,
      noShorexEntryDetails: null,
    };
  },
  computed: {
    selectedItem: {
      get() {
        return this.item;
      },
      set(val) {
        this.$emit("update:item", val);
      },
    },
    showNoShorexEntryDetails: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
        if (!val) {
          this.selectedItem = null;
        }
      },
    },
  },
  watch: {
    showNoShorexEntryDetails(val) {
      if (val) {
        this.loadNoShorexEntryDetails();
      }
    },
  },
  methods: {
    handleClose() {
      this.showNoShorexEntryDetails = !this.showNoShorexEntryDetails;
    },
    loadNoShorexEntryDetails() {
      if (this.selectedItem && this.selectedItem.id) {
        this.loading = true;
        this.noShorexEntryDetails = null;
        this.$store
          .dispatch("noshorex_entries/GET_BY_ID", this.selectedItem.id)
          .then((data) => {
            this.loading = false;
            this.noShorexEntryDetails = data;
          })
          .catch((e) => {
            console.error(e);
          });
      }
    },
  },
};
</script>
<style scoped>
.noshorex-entry-details-section {
  min-height: 300px;
  max-height: calc(60vh - var(--toolbar-height));
  overflow-y: auto;
  position: relative;
  text-align: left;
}
.noshorex-entry-details-section:has(+ .entry-request-actions) {
  min-height: 300px;
  max-height: calc(60vh - var(--toolbar-height) - 60px);
}
.registration-plate-font {
  font-family: "Fira Mono", monospace !important;
  font-weight: 700 !important;
}
</style>
