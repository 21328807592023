<template>
   <div class="ma-4">
    <div class="d-flex flex-wrap">
      <div class="ma-0 pa-0">
        <p class="text-h5 font-weight-black mb-0">
          {{ $t("configurations.zones") }}
        </p>
        <p class="text-caption">
          {{ $t("configurations.zones_explanation") }}
        </p>
      </div>
      <v-spacer></v-spacer>

      <div
        v-if="hasUserPermissionToViewEditOrManage('ZONES')"
        class="float-right"
      >
        <v-btn class="mb-5 black--text" color="primary" @click="add" depressed>
          <v-icon left> mdi-upload</v-icon>
          {{ $t("zones.import") }}
        </v-btn>
      </div>
    </div>

    <v-row>
      <v-col cols="12" sm="12" md="6" lg="6">
        <Map :style="mapStyle" :selected.sync="selectedItem" />
      </v-col>
      <v-col cols="12" sm="12" md="6" lg="6">
        <v-card tile flat outlined>
          <v-card-text class="ma-0 pa-0">
            <v-alert
              v-if="hasUserPermissionToViewEditOrManage('ZONES')"
              class="ma-4"
              color="blue-grey"
              text
              icon="mdi-information-outline"
            >
              {{ $t("zones.explanation") }}
            </v-alert>
            <List :selected.sync="selectedItem" @remove="remove" @edit="edit" />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <ImportItemsDialog :open.sync="openImportItemsDialog" />
    <EditItemDialog
      v-if="openEditItemDialog"
      :open.sync="openEditItemDialog"
      :item="selectedItem"
    />
    <DeleteItemDialog :open.sync="openDeleteItemDialog" :item="selectedItem" />
  </div>
</template>

<script>
import Map from "./Zones/Map";
import List from "./Zones/List";
import ImportItemsDialog from "./Zones/ImportItemsDialog";
import EditItemDialog from "./Zones/EditItemDialog";
import DeleteItemDialog from "./Zones/DeleteItemDialog";
import { debounce } from "debounce";
export default {
  components: {
    Map,
    List,
    ImportItemsDialog,
    EditItemDialog,
    DeleteItemDialog,
  },
  data() {
    return {
      openImportItemsDialog: false,
      openEditItemDialog: false,
      openDeleteItemDialog: false,
      selectedItem: [],
      tab: null,
    };
  },
  watch: {
    selectedItem: debounce(function () {
      //when user clicks on map there is no feature selected
      if (
        this.selectedItem.length == 0 ||
        this.openEditItemDialog ||
        this.openDeleteItemDialog
      ) {
        this.$store.state.zones.selectedZoneId = null;
        return;
      }
      this.$store.state.zones.selectedZoneId = this.selectedItem[0].id;
    }, 500),
  },
  computed: {
    mapStyle() {
      return this.$vuetify.breakpoint.name == "xs" ||
        this.$vuetify.breakpoint.name == "sm"
        ? "height: calc(100vh - 700px)"
        : "height: calc(100vh - 200px)";
    },
    tableStyle() {
      return this.$vuetify.breakpoint.name == "xs" ||
        this.$vuetify.breakpoint.name == "sm"
        ? "height: calc(100vh - 700px)"
        : "height: calc(100vh - 200px)";
    },
  },
  methods: {
    add() {
      this.openImportItemsDialog = true;
    },
    edit(zone) {
      this.selectedItem = [zone];
      this.openEditItemDialog = true;
    },
    remove(zone) {
      this.selectedItem = [zone];
      this.openDeleteItemDialog = true;
    },
  },
};
</script>
