import { vesselCallsService } from "@/api_services/vessel_calls.service";

export default {
  namespaced: true,
  state: {},
  mutations: {},

  actions: {
    GET_ALL(_, options) {
      return new Promise((resolve, reject) => {
        vesselCallsService
          .getAll(options)
          .then((res) => {
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },
    GET_VESSEL_CALL(_, processNumber) {
      return new Promise((resolve, reject) => {
        vesselCallsService
          .getVesselCall(processNumber)
          .then((res) => {
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },
    GET_SCHEDULER(_, options) {
      return new Promise((resolve, reject) => {
        vesselCallsService
          .getScheduling(options)
          .then((res) => {
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },
    GET_VESSEL_CALL_PASSENGERS(_, item) {
      return new Promise((resolve, reject) => {
        vesselCallsService
          .getVesselCallPassengers(item.process_number, item.options)
          .then((res) => {
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    GET_UPCOMING(_) {
      return new Promise((resolve, reject) => {
        vesselCallsService
          .getUpcoming()
          .then((res) => {
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },
    
    GET_BY_DAY(_, day) {
      return new Promise((resolve, reject) => {
        vesselCallsService
          .getByDay(day)
          .then((res) => {
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },
    GET_VESSEL_CALLS_LOCATIONS(_, date) {
      return new Promise((resolve, reject) => {
        vesselCallsService
          .getVesselCallsLocations(date)
          .then((res) => {
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },
  },
};
