<template>
  <v-container class="ma-0 pa-5" style="max-width: 100%">
    <div>
      <div class="d-flex flex-wrap my-5">
        <h2 style="text-transform: uppercase" class="pb-2 font-weight-black text--uppercase">
          {{ $t('global.goto') }}
        </h2>
      </div>
    </div>

    <v-row>
      <v-col v-for="(permission, index) in permissions" :key="index" cols="12" :md="permissions.length >= 6 ? 2 : 4"
        :sm="4">
        <v-hover>
          <template v-slot:default="{ hover }">
            <v-card block class="font-weight-bold text-h6 text-center" height="150px" :to="{ name: permission.code }"
              :elevation="hover ? 8 : 2" outlined>
              <v-container bg fill-height grid-list-md text-xs-center>
                <v-layout row wrap align-center>
                  <v-flex>
                    <p class="mb-1">
                      <v-icon color="black" size="48">{{ permission.icon }}</v-icon>
                    </p>
                    <p class="mb-0">
                      {{ $t("speeddial." + permission.code.toLowerCase()) }}
                    </p>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card>
          </template>
        </v-hover>
      </v-col>
      <v-col cols="12" :md="permissions.length >= 6 ? 2 : 4" :sm="4" v-if="hasPermissionStats">
        <v-hover>
          <template v-slot:default="{ hover }">
            <v-card block class="font-weight-bold text-h6 text-center" height="150px" :elevation="hover ? 8 : 2"
              outlined :to="{
                name: 'STATS',
                query: {
                  start: start,
                  end: end,
                },
              }">
              <v-container bg fill-height grid-list-md text-xs-center>
                <v-layout row wrap align-center>
                  <v-flex>
                    <p class="mb-1">
                      <v-icon color="black" size="48">mdi-calendar-month</v-icon>
                    </p>
                    <p class="mb-0">
                      {{ $t("global.stat") }}
                    </p>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card>
          </template>
        </v-hover>
      </v-col>
    </v-row>

    <v-row class="align-center">
      <v-col>
        <v-btn x-large block class="font-weight-bold text-h6 text-center" elevation="2" outlined
          @click.stop="profile">{{
            $t("global.profile") }}</v-btn></v-col>
    </v-row>

    <div v-if="
      (isAdminUser || isManagerUser) && userLocodes && userLocodes.length > 1
    ">
      <div class="d-flex flex-wrap my-5">
        <h2 style="text-transform: uppercase" class="pb-2 font-weight-black text--uppercase">
          {{ $t('global.change_port') }}
        </h2>
      </div>
      <v-row>
        <v-col cols="12" :md="2" v-for="(item, index) in userLocodes" :key="index">
          <v-hover>
            <template v-slot:default="{ hover }">
              <v-card block class="font-weight-bold text-h6 text-center" height="150px" :elevation="hover ? 8 : 2"
                outlined @click.stop="openLocode(item)" :dark="isLocodeCurrentlyActivated(item)"
                :disabled="isLocodeCurrentlyActivated(item)">
                <v-container bg fill-height grid-list-md text-xs-center>
                  <v-layout row wrap align-center>
                    <v-flex>
                      <p class="mb-1">
                        <v-icon :color="isLocodeCurrentlyActivated(item) ? 'white' : 'black'"
                          size="48">mdi-anchor</v-icon>
                      </p>
                      <p class="mb-0">
                        {{ item.name }}
                      </p>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-card>
            </template>
          </v-hover>
        </v-col>
      </v-row>
    </div>
    <v-row class="align-center">
      <v-col>
        <v-btn x-large block class="font-weight-bold text-h6 text-center" elevation="2" outlined @click.stop="logout">{{
          $t("global.logout") }}</v-btn></v-col>
    </v-row>
  </v-container>
</template>
<script>
import { DateTime } from "luxon";
import configs from "@/helpers/configs";
import { sharedHelpers } from "@/api_services/utils/shared-helpers";
export default {
  data() {
    return {
      start: DateTime.now().minus({ days: 7 }).toFormat("yyyy-LL-dd"),
      end: DateTime.now().toFormat("yyyy-LL-dd"),
    };
  },
  computed: {
    locode() {
      return configs.getLocode();
    },

    userLocodes() {
      return configs
        .getLocodes()
        .filter((item) => sharedHelpers.getUserLocodes().includes(item.code));
    },

    permissions() {
      let perms = configs
        .getDefaultPermissions()
        .filter((p) => {
          return (
            this.hasUserPermissionToViewOrManage(p.code) &&
            p.code != "STATS" &&
            p.code != "PLANS"
          );
        })
        .map((perm) => {
          if (perm.code == "SCHEDULED_ENTRY_SCANS") {
            return { ...perm, icon: "mdi-clipboard-check-outline" };
          } else {
            return perm;
          }
        });

      let permIndex = perms.findIndex(
        (p) => p.code === "SCHEDULED_ENTRY_SCANS"
      );

      if (permIndex != -1) {
        perms.splice(permIndex + 1, 0, {
          code: "SCHEDULED_EXIT_SCANS",
          icon: "mdi-clipboard-minus-outline",
          type: "SHOREX",
        });
      }

      return perms;
    },
    hasPermissionStats() {
      return this.hasUserPermissionToViewOrManage("STATS");
    },
    userAccount() {
      return this.$store.state.user.account;
    },
    userType() {
      return this.userAccount && this.userAccount.user
        ? this.userAccount.user.type
        : "";
    },
    isAdminUser() {
      return this.userType == configs.getAdminUserType();
    },
    isManagerUser() {
      return this.userType == configs.getManagerUserType();
    },
  },
  methods: {

    isLocodeCurrentlyActivated(locode) {
      return locode.code == this.locode.code;
    },

    logout() {
      this.$router.push("/login", () => {
        this.$store.dispatch("LOGOUT").then(() => { });
      });
    },

    profile() {
      this.$router.push("/profile");
    },

    openLocode(locode) {
      sharedHelpers.setStorageItemWithExpiry(
        "locode",
        locode,
        8640000 //24hours
      );

      window.location.reload();
    },
  },
};
</script>
<style scoped>
.theme--light.v-sheet--outlined {
  border: thin solid rgb(0 0 0);
}
</style>
