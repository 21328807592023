<template>
  <v-card tile outlined>
    <v-card-title
      ><slot name="title"></slot></v-card-title
    >
    <v-card-subtitle><slot name="subtitle"></slot></v-card-subtitle>
    <v-divider></v-divider>
    <v-card-text style="position: relative">
      <Loading v-if="loading" :value="loading"></Loading>
      <v-simple-table dense fixed-header height="30vh" v-else>
        <template v-slot:default>
          <thead>
            <tr>
              <th v-if="withErrorDescription" class="text-left" style="width:200px">{{ $t('global.error_description') }}</th>
              <th class="text-left">{{ $t('noshorex_entries.operator_name') }}</th>
              <th class="text-left">{{ $t('noshorex_entries.type_name') }}</th>
              <th class="text-left">{{ $t('noshorex_entries.registration_plate') }}</th>
              <th class="text-left">{{ $t('noshorex_entries.vehicle_at_pier_start') }}</th>
              <th class="text-left">{{ $t('noshorex_entries.vehicle_at_pier_end') }}</th>
              <th class="text-left">{{ $t('noshorex_entries.notes') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in items" :key="i" :class="withErrorDescription ? 'error-line' : ''">
              <td v-if="withErrorDescription">{{ item["message"] }}</td>
              <td>{{ item["Operador"] }}</td>
              <td>{{ item["Tipo"] }}</td>
              <td>{{ item["Matrícula"] }}</td>
              <td>{{ item["Entrada (dd/mm/aaaa hh:mm)"] }}</td>
              <td>{{ item["Saída (dd/mm/aaaa hh:mm)"] }}</td>
              <td>{{ item["Notas"] }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>
<script>
import Loading from "@/components/Main/Loading";
export default {
  props: ["items", "loading", "withErrorDescription"],
  components: { Loading },
};
</script>
<style scoped>
.error-line
{
  color: red;
}
</style>