import configs from "@/helpers/configs";
import axios from "axios";
import { sharedHelpers } from "@/api_services/utils/shared-helpers";

export const planService = {
  getPlans,
  getPlanByDay,
  exportPlanEntryRequests,
  archivePlan,
  getArchivedPlanDates,
};

async function getPlans(payload) {
  const requestOptions = {
    method: "GET",
    headers: sharedHelpers.getRequestHeaders(),
    params: {
      ...payload,
    },
  };
  return await axios.get(configs.getApiUrl() + "/v1/plans", requestOptions);
}

async function getPlanByDay(day) {
  const requestOptions = {
    method: "GET",
    headers: sharedHelpers.getRequestHeaders()
  };
  return await axios.get(
    configs.getApiUrl() + "/v1/plans/day/" + day,
    requestOptions
  );
}

async function exportPlanEntryRequests(date) {

  const requestOptions = {
    method: "POST",
    responseType: "blob",
    headers: sharedHelpers.getRequestHeaders()
  };
  return await axios.post(
    configs.getApiUrl() + `/v1/plans/${date}/export/entry_requests`,
    {},
    requestOptions
  );
}

async function archivePlan(date) {
  const requestOptions = {
    method: "POST",
    headers: sharedHelpers.getRequestHeaders()
  };
  return await axios.post(
    configs.getApiUrl() + `/v1/plans/${date}/archive`,
    {},
    requestOptions
  );
}

async function getArchivedPlanDates() {
  const requestOptions = {
    method: "GET",
    headers: sharedHelpers.getRequestHeaders()
  };
  return await axios.get(
    configs.getApiUrl() + `/v1/plans/archived/dates`,
    requestOptions
  );
}
