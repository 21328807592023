<template>
  <v-dialog v-model="isOpen" persistent width="900">
    <v-card tile>
      <v-expand-transition>
        <div v-show="expand">
          <v-alert class="mb-0" tile type="error" @click="expand = false">
            <div
              v-for="(message, index) in this.messages"
              :key="index"
              class="subtitle"
            >
              {{ message }}
            </div>
          </v-alert>
        </div>
      </v-expand-transition>
      <v-progress-linear
        height="10"
        striped
        color="primary"
        indeterminate
        v-show="loading"
      ></v-progress-linear>
      <v-toolbar color="primary" flat>
        <v-toolbar-title>
          <span>
            <v-icon left>mdi-add</v-icon>{{ $t("operators.create") }}
          </span>
        </v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text style="max-height: 50vh; overflow-y: auto">
        <v-form ref="form" class="mt-10">
          <v-text-field
            counter="50"
            maxlength="50"
            v-model="item.name"
            required
            :label="$t('operators.name')"
            :rules="nameRules"
            outlined
          />
          <v-text-field
            counter="25"
            maxlength="25"
            v-model="item.phone"
            required
            :label="$t('operators.phone')"
            :rules="phoneNumberRules"
            outlined
          />
          <v-text-field
            counter="50"
            maxlength="50"
            v-model="item.email"
            required
            :label="$t('operators.email')"
            :rules="emailRules"
            outlined
          />

          <v-text-field
            :label="$t('global.vat')"
            counter="9"
            maxlength="9"
            name="vat"
            v-model="item.vat_number"
            :error-messages="vatNumberValidation"
            type="text"
            outlined
            :rules="vatRules"
          ></v-text-field>
        </v-form>
        <v-checkbox
          class="ml-2"
          v-model="item.accredited"
          :label="$t('operators.accredited_operator')"
        ></v-checkbox>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer />
        <v-btn
          class="black--text"
          color="primary"
          depressed
          :disabled="loading"
          @click="save"
        >
          {{ $t("global.create") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { phoneNumberValidation } from "@/helpers/common_validations.js";
const DEFAULT_ITEM = {
  name: null,
  email: null,
  status: null,
  nif: null,
  vat_number: null,
  accredited: true,
};
export default {
  props: ["open"],
  mounted() {
    this.$store.dispatch("operator_types/GET_ALL");
  },
  data() {
    return {
      loading: false,
      expand: false,
      messages: [],
      validationErrors: [],
      phoneNumberRules: [
        (v) => !!v || this.$t("rules.required_field"),
        phoneNumberValidation,
      ],
      vatRules: [
        (v) => !!v || this.$t("rules.required_field"),
        (v) => (v && /^\d{9}$/.test(v)) || this.$t("rules.vat_digit_number"),
      ],
      nameRules: [
        (v) => !!v || this.$t("rules.required_field"),
        (v) => (v && v.length <= 255) || this.$t("rules.character_limit"),
      ],
      emailRules: [
        (v) => !!v || this.$t("rules.required_field"),
        (v) => (v && v.length <= 50) || this.$t("rules.character_limit"),
        (v) =>
          !v ||
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          this.$t("rules.email_invalid"),
      ],
      item: Object.assign({}, DEFAULT_ITEM),
    };
  },
  computed: {
    vatNumberValidation() {
      if (this.validationErrors?.vat_number) {
        return this.validationErrors.vat_number;
      } else {
        return "";
      }
    },
    isOpen: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("update:open", val);
      },
    },
  },
  watch: {
    open(val) {
      if (val) {
        this.item = Object.assign({}, DEFAULT_ITEM);
        this.$refs.form?.reset();
      }
    },
    expand(val) {
      if (!val) {
        this.validationErrors = [];
      }
    },
  },
  methods: {
    close() {
      this.isOpen = false;
      this.expand = false;
      this.loading = false;
    },
    save() {
      this.expand = false;
      this.loading = true;
      this.messages = [];
      if (this.$refs.form.validate()) {
        let operator = Object.assign({}, this.item);
        this.$store.dispatch("operators/CREATE", operator).then((res) => {
          if (res.errors) {
            Object.entries(res.errors).forEach(([, value]) => {
              this.messages = this.messages.concat(value);
              this.validationErrors = res.errors;
            });
            this.expand = true;
            this.loading = false;
          } else {
            this.close();
          }
        });
      } else {
        this.expand = false;
        this.loading = false;
      }
    },
  },
};
</script>
