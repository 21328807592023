<template>
  <v-dialog
    v-model="dialogOpen"
    scrollable
    transition="dialog-bottom-transition"
    width="70%"
  >
    <v-card tile>
      <v-toolbar flat color="primary">
        <v-toolbar-title v-if="!!item"
          >{{ $t("passengers.view_list") }} -
          {{ item.process_number }}</v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-btn icon @click="dialogOpen = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pa-5">
        <v-data-table
          :footer-props="{
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right',
            'items-per-page-options': [10],
            disableItemsPerPage: true,
          }"
          :loading="loading"
          :search="search"
          :page.sync="page"
          :headers="headers"
          :items="items"
          :server-items-length="totalItemCount"
          @update:options="handleOptionsUpdate('options', $event)"
          :items-per-page="itemsPerPage"
          item-key="code"
          fixed-header
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          must-sort
        >
          <template v-slot:top>
            <v-text-field
              v-model="search"
              single-line
              :label="$t('passengers.search')"
              prepend-inner-icon="mdi-magnify"
              outlined
              @input="filterItems"
            />
          </template>
          <template v-slot:item.date_of_birth="{ item }"
            >{{ item.date_of_birth | formatDate }}
          </template>
          <template v-slot:item.country_name="{ item }">
            <country-flag
              :country="item.country_code.toLowerCase()"
              size="normal"
            /><span>{{ item.country_name }}</span>
          </template>
          <template #footer.prepend>
            <v-spacer />
          </template> </v-data-table
      ></v-card-text> </v-card
  ></v-dialog>
</template>
<script>
import { debounce } from "debounce";
import CountryFlag from "vue-country-flag";
export default {
  props: ["open", "item"],
  components: { CountryFlag },
  data() {
    return {
      search: "",
      page: 1,
      itemsPerPage: 10,
      headers: [
        {
          text: this.$t("passengers.name"),
          align: "start",
          sortable: true,
          value: "name",
        },
        {
          text: this.$t("passengers.date_of_birth"),
          align: "start",
          sortable: true,
          value: "date_of_birth",
          width: "200px",
        },
        {
          text: this.$t("passengers.nationality"),
          align: "start",
          sortable: true,
          value: "country_name",
          width: "200px",
        },
      ],
      items: [],
      totalItemCount: 0,
      sortBy: "name",
      sortDesc: false,
      loading: false,
    };
  },
  computed: {
    dialogOpen: {
      // getter
      get: function () {
        return this.open;
      },
      // setter
      set: function (newValue) {
        this.$emit("update:open", newValue);
      },
    },
  },
  watch: {
    dialogOpen(isOpen) {
      if (isOpen) {
        this.handleOptionsUpdate();
      } else {
        this.page = 1;
        this.search = "";
        this.sortBy = "name";
        this.sortDesc = false;
        this.items = [];
        this.totalItemCount = 0;
      }
    },
  },
  methods: {
    loadPassengers() {
      // Prevent multiple loads
      if (this.loading) {
        return;
      }
      this.items = [];
      this.loading = true;
      this.$store
        .dispatch("vessel_calls/GET_VESSEL_CALL_PASSENGERS", {
          process_number: this.item.process_number,
          options: {
            search: this.search,
            currentPage: this.page,
            itemsPerPage: this.itemsPerPage,
            sortBy: this.sortBy,
            sortDesc: this.sortDesc,
          },
        })
        .then((response) => {
          this.items = response.data;
          this.totalItemCount = response.total;
          this.loading = false;
        })
        .catch((ex) => {
          console.error("Error loading passengers", ex);
          this.loading = false;
          this.totalItemCount = 0;
          this.items = [];
        });
    },

    handleOptionsUpdate: debounce(function () {
      if (this.dialogOpen) // only load passengers when this component is open
        this.loadPassengers();
    }, 500),

    filterItems: debounce(function () {
      // Changes the current page to the first one to reset the search stop bugs from happening
      if (this.page == 1) {
        this.loadPassengers();
      } else {
        this.page = 1;
      }
    }, 500),
  },
};
</script>

<style>
.normal-flag {
  margin: 0px !important;
}

tr td.text-start:last-child {
  display: flex;
  align-items: center;
}
</style>
