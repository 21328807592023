import configs from "@/helpers/configs";
import axios from "axios";
import { sharedHelpers } from "@/api_services/utils/shared-helpers";

export const countriesService = {
  getCountries,
};

async function getCountries() {
  const requestOptions = {
    method: "GET",
    headers: sharedHelpers.getRequestHeaders()
  };
  return await axios.get(
    configs.getApiUrl() + "/v1/my-shorex/countries",
    requestOptions
  );
}
