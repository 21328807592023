<template>
  <div>
    <v-menu
      v-model="openMenu"
      :close-on-content-click="false"
      :nudge-width="200"
      offset-x
    >
      <template v-slot:activator="{ on, attrs }">
        <v-badge overlap color="green" :value="nFilters" :content="nFilters">
          <v-btn icon text style="top: -6px" v-bind="attrs" v-on="on">
            <v-icon>mdi-tune-vertical-variant</v-icon>
          </v-btn>
        </v-badge>
      </template>

      <v-card width="300">
        <v-card-title>{{ $t("vehicles.filter_by") }}:</v-card-title>
        <v-card-text>
          <v-select
            v-model="options.filterByStatus"
            required
            clearable
            :label="$t('operators.status')"
            :placeholder="$t('vehicles.status')"
            item-value="code"
            :items="operatorsStatuses"
            prepend-inner-icon="mdi-list-status"
            outlined
            @change="filterItems"
            hide-details
          >
            <template v-slot:item="{ item }">
              {{ item.displayText }}
            </template>
            <template v-slot:selection="{ item }">
              {{ item.displayText }}
            </template>
          </v-select>
        </v-card-text>
        <v-checkbox
        class="ml-4"
          v-if="extra"
          v-model="options.filterByAccredited"
          :label="$t('operators.accredited_operator')"
          @change="filterItems"
        ></v-checkbox>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="resetFilters">{{ $t("global.reset") }}</v-btn>
          <v-btn text @click="openMenu = false">
            {{ $t("global.close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import configs from "@/helpers/configs";
export default {
  props: ["menu", "extra"],
  data() {
    return {
      showAdvancedSearchOptions: false,
      nFilters: 0,
    };
  },
  watch: {
    options: {
      deep: true,
      handler(v) {
        this.nFilters = 0;
        if (v.filterByStatus) this.nFilters++;
      },
    },
  },

  computed: {
    options: {
      cache: false,
      get() {
        return this.$store.state.operators.options;
      },
      set(val) {
        this.$store.dispatch("operators/UPDATE_OPTIONS", val);
      },
    },
    operatorsStatuses() {
      return configs.getOperatorStatuses();
    },
    openMenu: {
      get() {
        return this.menu;
      },
      set(val) {
        this.$emit("update:menu", val);
      },
    },
  },
  methods: {
    resetFilters() {
      this.options.filterByStatus = null;
      this.filterItems();
    },

    filterItems() {
      this.$emit("filterItems");
    },
  },
};
</script>
