<template>
  <v-card flat :loading="loading">
    <v-card-title class="text-h6 font-weight-black text-uppercase"
      >{{ $t("vehicles.my_vehicles_recent") }}
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text class="pa-0" style="height: 230px; position: relative">
      <Loading v-if="loading" v-model="loading"></Loading>
      <v-row
        align="center"
        justify="center"
        class="text-h6 text-center fill-height pa-4"
      >
        <v-container v-if="items.length == 0">
          <v-icon left>mdi-account-box</v-icon
          >{{ $t("vehicles.no_vehicles_found") }}
        </v-container>
        <v-slide-group
          v-else
          center-active
          show-arrows="desktop"
          v-model="selectedItem"
        >
          <v-slide-item
            v-for="item in items"
            :key="item.id"
            :value="item"
            v-slot="{ active, toggle }"
          >
            <div style="width: 280px">
              <Vehicle
                :item="item"
                :active="active"
                @click.stop="toggle"
                align="left"
              ></Vehicle></div></v-slide-item
        ></v-slide-group>
      </v-row>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn
        :disabled="loading"
        color="primary"
        class="black--text"
        large
        block
        width="100%"
        depressed
        to="/user_vehicles"
        ><v-icon left>mdi-car-multiple</v-icon> Lista de veículos</v-btn
      >
    </v-card-actions>
    <VehicleDetails
      v-model="viewVehicleDetails"
      :item.sync="selectedItem"
      @cancel="confirmCancel"
      @edit="handleEditVehicle"
    ></VehicleDetails>

    <EditItemDialog
      :open.sync="openEditVehicleForm"
      :item="selectedItem"
      @closeVehicleDetails="handleCloseVehicleDetails"
    />

    <ConfirmDialog
      v-model="confirmDialogOpen"
      :loading="loading"
      @confirm="handleCancelVehicle"
    >
      <template v-slot:title>{{ $t("vehicles.cancel_request") }}</template>
      <template v-slot:content>
        {{ $t("vehicles.cancel_request_explanation") }}
      </template>
    </ConfirmDialog>
  </v-card>
</template>
<script>
import Loading from "@/components/Main/Loading";
import Vehicle from "@/components/Main/Vehicles/Vehicle";
import VehicleDetails from "@/components/Main/Vehicles/VehicleDetails";
import ConfirmDialog from "@/components/Main/ConfirmDialog";
import EditItemDialog from "@/components/Main/Vehicles/EditItemDialog.vue";

export default {
  components: {
    Loading,
    Vehicle,
    VehicleDetails,
    ConfirmDialog,
    EditItemDialog,
  },
  data: () => ({
    selectedItem: null,
    viewVehicleDetails: false,
    confirmDialogOpen: false,
    openEditVehicleForm: false,
  }),
  computed: {
    loading() {
      return this.$store.state.user_vehicles.loading;
    },
    items() {
      return this.$store.state.user_vehicles.all.splice(0, 5);
    },
  },
  watch: {
    selectedItem(val) {
      if (val) {
        this.viewVehicleDetails = true;
      }
    },
  },
  mounted() {
    this.loadVehicles();
    this.loadVehicleTypes();
  },
  methods: {
    handleVehicleClick(item) {
      this.selectedItem = item;
    },
    loadVehicles() {
      this.$store.dispatch("user_vehicles/GET_USER_VEHICLES").catch((ex) => {
        console.error("Error loading user vehicles", ex);
      });
    },
    confirmCancel() {
      this.confirmDialogOpen = true;
    },
    loadVehicleTypes() {
      this.$store
        .dispatch("vehicle_types/GET_ALL_WITHOUT_PAGINATED")
        .catch((ex) => {
          console.error("Error loading vehicle types", ex);
        });
    },
    handleCloseVehicleDetails() {
      this.viewVehicleDetails = false;
    },
    handleEditVehicle() {
      this.openEditVehicleForm = true;
    },
    handleCancelVehicle() {
      this.viewVehicleDetails = false;
      this.$store
        .dispatch("user_vehicles/CANCEL", this.selectedItem.id)
        .then(() => {
          this.confirmDialogOpen = false;
          this.selectedItem = null;
          this.loadVehicles();
        })
        .catch((ex) => {
          this.confirmDialogOpen = false;
          console.error("Error canceling vehicle", ex);
        });
    },
  },
};
</script>
