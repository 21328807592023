<template>
  <v-dialog v-model="isOpen" persistent width="400">
    <v-card tile>
      <v-toolbar color="primary" flat>
        <v-toolbar-title>
          <span>
            <v-icon left>mdi-list-box-outline</v-icon
            >{{ $t("operators.choose_type") }}
          </span>
        </v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-select
          class="mt-3"
          hide-details
          outlined
          :items="types"
          v-model="type"
          :label="$t('configurations.operator_types')"
          :menu-props="{ bottom: true, offsetY: true }"
        >
        </v-select>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :disabled="type == null"
          @click="addNewType"
          text
          color="primary"
        >
          {{ $t("global.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["open", "licenses"],
  data() {
    return { type: null };
  },
  computed: {
    isOpen: {
      get() {
        return this.open;
      },
      set(value) {
        this.$emit("update:open", value);
      },
    },
    operatorTypes() {
      return this.$store.state.operator_types.all;
    },
    types() {
      return this.operatorTypes
        .filter((opType) => {
          return !this.licenses.map((t) => t.type_id).includes(opType.id);
        })
        .map((type) => ({
          text: type.name,
          value: type.id,
        }));
    },
  },
  methods: {
    addNewType() {
      this.$emit("addType", this.type);
      this.close();
    },
    close() {
      this.isOpen = false;
    },
  },
};
</script>
