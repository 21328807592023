<template>
  <v-dialog v-model="isOpen" persistent scrollable width="90vw">
    <v-card tile>
      <v-expand-transition>
        <div v-show="expandErrorMessages">
          <v-alert class="mb-0" tile type="error" @click="expandErrorMessages = false">
            <div v-for="(message, index) in this.errorMessages" :key="index" class="subtitle">
              {{ message }}
            </div>
          </v-alert>
        </div>
      </v-expand-transition>
      <v-progress-linear height="10" striped color="primary" indeterminate v-show="loading"></v-progress-linear>
      <v-toolbar color="primary" flat>
        <v-toolbar-title>
          <span>
            <v-icon left>mdi-database-import</v-icon>{{ $t("vehicles.import_vehicles_help_title") }}
          </span>
        </v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="mt-5">
        <v-alert color="blue-grey" text icon="mdi-information-outline">
          <div class="font-weight-black">
            {{ $t("vehicles.import_vehicles_help_title") }}
          </div>
          <div class="mt-2">
            <div class="ma-0">
              <div v-html="$t('vehicles.import_vehicles_operator_help_description')" class="d-inline"></div>
              <a href="#" @click.stop="downloadTemplate">{{ $t('global.excel_file_template') }}</a>
            </div>
          </div>
        </v-alert>
        <v-form ref="form" class="mt-5" :disabled="loading">
          <v-file-input v-model="file" show-size accept=".xlsx" outlined prepend-icon
            prepend-inner-icon="mdi-microsoft-excel" :rules="fileRules" persistent-hint
            :label="$t('vehicles.import_file')" @change="previewFile"></v-file-input>
        </v-form>
        <div v-if="shouldShowFileAnalysis" style="position: relative;min-height: 200px;"
          class="d-flex flex-column justify-center align-center">
          <Loading v-if="loading" :value="loading"
            :custom-message="hasSubmitted ? $t('vehicles.importing_vehicles_please_wait') : $t('global.verifying_file_please_wait')">
          </Loading>
          <template v-else>
            <ImportVehiclesList v-if="hasAnyRowsToShow" :items="rowItems">
              <template v-slot:title>
                <template v-if="hasErrors">
                  <v-icon left>mdi-alert-circle-outline</v-icon>
                  {{ $t("vehicles.errors_importing_vehicles") }}
                </template>
                <template v-else>
                  <v-icon left>mdi-check-circle-outline</v-icon>
                  {{ $t("vehicles.ready_to_import") }}
                </template>

              </template>
              <template v-slot:subtitle>
                <template v-if="hasErrors">
                  {{ $t("vehicles.vehicles_were_not_created_please_review") }}
                </template>
                <template v-else>
                  {{ $t("vehicles.vehicles_about_to_be_imported") }}
                </template>
              </template>
            </ImportVehiclesList>
            <div v-else class="text-h6">{{ $t('global.file_is_empty') }}</div>
          </template>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer />
        <v-btn class="black--text" color="primary" depressed :disabled="loading" @click="importFile">
          <v-icon left> mdi-upload</v-icon>{{ $t("global.import") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>


</template>
<script>
import Loading from "@/components/Main/Loading";
import ImportVehiclesList from "@/components/Main/Vehicles/ImportVehiclesList";
export default {
  props: ["open", "success"],
  components: { Loading, ImportVehiclesList },
  data() {
    return {
      loading: false,
      fileRules: [(v) => !!v || this.$t("rules.file_required")],
      file: null,
      items: [],
      hasSubmitted: false,
      expandErrorMessages: false,
      errorMessages: [],
    };
  },
  computed: {
    isOpen: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("update:open", val);
      },
    },

    shouldShowFileAnalysis() {
      return this.file !== null;
    },

    hasErrors() {
      return this.items.some((e) => e.type == "ERROR");
    },

    hasAnyRowsToShow() {
      return this.items.some((e) => !!e.data);
    },

    generalErrorMessages() {
      return this.items.filter((e) => e.type == "ERROR" && !e.data).map((e) => e.message);
    },

    rowItems() {
      return this.items.filter((e) => !!e.data);
    },
    successMessage: {
      get() {
        return this.success;
      },
      set(val) {
        this.$emit("update:success", val);
      },
    },
  },
  watch: {
    isOpen(val) {
      if (val) {
        this.$nextTick(() => {
          this.expandErrorMessages = false;
          this.loading = false;
          this.hasSubmitted = false;
          this.$refs.form.reset();
        });
      }
    }
  },
  methods: {
    mapResToItem(item) {
      return {
        ...item.record,
        message: item.message,
        type: item.type,
      };
    },

    downloadTemplate() {
      this.$store
        .dispatch("user_vehicles/DOWNLOAD_USER_VEHICLES_TEMPLATE");
    },

    uploadFile() {
      return new Promise((resolve) => {
        this.loading = true;
        this.items = [];
        if (this.$refs.form.validate()) {
          this.$store
            .dispatch("user_vehicles/IMPORT_USER_VEHICLES", { file: this.file, dryrun: !this.hasSubmitted })
            .then((res) => {

              // res contains "upserts" (lines inserted or about to be inserted) and "errors"
              let resultItems = res.upserts.map(this.mapResToItem);
              resultItems = resultItems.concat(res.errors.map(this.mapResToItem));

              this.items = resultItems;

              if (res.errors && res.errors.length > 0) {
                this.errorMessages = [
                  this.$t("global.file_import_error"),
                  ...this.generalErrorMessages];
                this.expandErrorMessages = true;
              }
              this.loading = false;
              resolve();
            })
            .catch(() => {
              this.errorMessages = [this.$t("global.file_import_error")];
              this.expandErrorMessages = true;
              this.loading = false;
              this.items = [];
              resolve();
            });
        } else {
          this.loading = false;
          this.items = [];
          this.errorMessages = [this.$t("global.file_import_error")];
          this.expandErrorMessages = true;
          resolve();
        }
      });
    },

    previewFile() {
      this.reset();
      if (this.file) {
        this.uploadFile();
      }
    },

    async importFile() {
      if (this.$refs.form.validate()) {
        this.hasSubmitted = true;
        await this.uploadFile();
        if (!this.hasErrors) {
          this.successMessage = true;
          this.close();
        }
      }
    },

    close() {
      this.isOpen = false;
    },

    reset() {
      this.expandErrorMessages = false;
      this.loading = false;
      this.hasSubmitted = false;
      this.items = [];
    }
  },
};
</script>
