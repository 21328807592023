<template>
  <v-dialog v-model="isOpen" persistent width="900" scrollable>
    <v-card tile>
      <v-expand-transition>
        <div v-show="expand">
          <v-alert class="mb-0" tile type="error" @click="expand = false">
            <div
              v-for="(message, index) in this.messages"
              :key="index"
              class="subtitle"
            >
              {{ message }}
            </div>
          </v-alert>
        </div>
      </v-expand-transition>

      <v-toolbar color="primary" flat>
        <v-toolbar-title>
          <span>
            <v-icon left>mdi-plus</v-icon>{{ $t("vehicles.new_vehicle") }}
          </span>
        </v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="overflow-y-auto pb-0 form-content">
        <Loading
          v-if="saving"
          :value="saving"
          :custom-message="$t('vehicles.creating_please_wait')"
        ></Loading>
        <v-form ref="form" class="mt-2" v-else>
          <v-alert color="blue-grey" text icon="mdi-information-outline">
            {{ $t("vehicles.vehicles_form_step_1") }}
          </v-alert>
          <VehicleTypeSelector
            v-model="item.vehicle_type"
            :disabled="false"
            :warning="selectorWarning"
          ></VehicleTypeSelector>
          <v-divider class="mb-4"></v-divider>
          <v-alert color="blue-grey" text icon="mdi-information-outline">
            {{ $t("vehicles.vehicles_form_step_2") }}
          </v-alert>
          <v-autocomplete
            v-model="item.operator_id"
            :disabled="isUpdatingOperator"
            :items="operators"
            prepend-inner-icon="mdi-domain"
            :label="$t('vehicles.operator')"
            required
            :rules="operatorRules"
            item-text="name"
            item-value="id"
            flat
            outlined
            clearable
            hide-no-data
            cache-items
            :loading="operatorsLoading"
          >
          </v-autocomplete>
          <v-text-field
            counter="25"
            maxlength="25"
            autocomplete="off"
            v-model="item.registration_plate"
            @keyup="transfromRegistrationPlate"
            :label="$t('vehicles.license_plate')"
            :placeholder="$t('vehicles.license_plate')"
            :rules="registrationPlateRules"
            prepend-inner-icon="mdi-card-text"
            outlined
            class="registration-plate-font"
          />
          <v-text-field
            counter="25"
            maxlength="25"
            autocomplete="off"
            v-model="item.brand"
            :label="$t('vehicles.brand')"
            :placeholder="$t('vehicles.brand')"
            prepend-inner-icon="mdi-text"
            outlined
          ></v-text-field>
          <v-text-field
            counter="25"
            maxlength="25"
            autocomplete="off"
            v-model="item.model"
            :label="$t('vehicles.model')"
            :placeholder="$t('vehicles.model')"
            prepend-inner-icon="mdi-car-info"
            outlined
          ></v-text-field>

          <v-text-field
            counter="25"
            maxlength="25"
            autocomplete="off"
            v-model="item.responsible_name"
            :label="$t('vehicles.responsible_name')"
            :placeholder="$t('vehicles.responsible_name')"
            prepend-inner-icon="mdi-card-account-details"
            outlined
          ></v-text-field>

          <v-text-field
            counter="25"
            maxlength="25"
            v-model="item.phone"
            prepend-inner-icon="mdi-phone"
            :label="$t('vehicles.contact_phone')"
            :rules="phoneNumberRules"
            outlined
          />

          <v-text-field
            v-model="item.seating_capacity"
            type="number"
            :label="$t('vehicles.seating_capacity')"
            :placeholder="$t('vehicles.seating_capacity')"
            prepend-inner-icon="mdi-car-seat"
            :rules="seatingCapacityRules"
            outlined
          ></v-text-field>

          <v-text-field
            counter="10"
            maxlength="10"
            autocomplete="off"
            v-model="item.client_number"
            :label="$t('vehicles.client_number')"
            :placeholder="$t('vehicles.client_number')"
            prepend-inner-icon="mdi-id-card"
            outlined
          ></v-text-field>
          <v-text-field
            counter="10"
            maxlength="10"
            autocomplete="off"
            v-model="item.rate_type"
            :label="$t('vehicles.rate_type')"
            :placeholder="$t('vehicles.rate_type')"
            prepend-inner-icon="mdi-currency-eur"
            outlined
          ></v-text-field>
          <v-textarea
            v-model="item.notes"
            :label="$t('vehicles.notes')"
            :placeholder="$t('vehicles.notes')"
            prepend-inner-icon="mdi-comment"
            outlined
            counter="255"
            maxlength="255"
            no-resize
          ></v-textarea>
          <DateInput
            v-model="item.valid_until"
            prepend-inner-icon="mdi-calendar"
            :label="$t('vehicles.valid_until')"
            :min="
              new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                .toISOString()
                .substring(0, 10)
            "
            :max="
              new Date(
                new Date(
                  new Date().getFullYear() + 20,
                  new Date().getMonth(),
                  0
                ) -
                  new Date().getTimezoneOffset() * 60000
              )
                .toISOString()
                .substr(0, 10)
            "
            outlined
            clearable
          ></DateInput>
          <v-file-input
            v-model="item.vehicle_image"
            prepend-icon=""
            prepend-inner-icon="mdi-image"
            :label="$t('vehicles.image')"
            outlined
            accept="image/png, image/jpeg"
            @change="handleImageUpload"
          >
          </v-file-input>
          <v-card
            outlined
            class="mb-4"
            width="100%"
            v-if="!!item.vehicle_image"
          >
            <v-card-title>
              <v-spacer></v-spacer>
              <v-btn outlined color="red" icon @click="removeImage">
                <v-icon>mdi-trash-can</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-avatar color="blank" height="300" width="100%" tile>
                <v-img contain height="300" width="100%" :src="imageUrl">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        :size="150"
                        :width="3"
                        color="grey"
                        indeterminate
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>
            </v-card-text>
          </v-card>
          <v-fade-transition>
            <div v-show="item.vehicle_type == 'TAXI'">
              <v-text-field
                counter="10"
                maxlength="10"
                autocomplete="off"
                v-model="item.license_number"
                type="text"
                :label="$t('vehicles.license_number')"
                :placeholder="$t('vehicles.license_number')"
                :rules="item.vehicle_type == 'TAXI' ? requiredRule : []"
                prepend-inner-icon="mdi-car-door"
                outlined
                class="registration-plate-font"
              ></v-text-field>
              <v-text-field
                counter="10"
                maxlength="10"
                autocomplete="off"
                v-model="item.permit_number"
                type="text"
                :label="$t('vehicles.permit_number')"
                :placeholder="$t('vehicles.permit_number')"
                :rules="item.vehicle_type == 'TAXI' ? requiredRule : []"
                prepend-inner-icon="mdi-license"
                outlined
              ></v-text-field>
            </div>
          </v-fade-transition>
          <v-select
            v-model="item.status"
            :label="$t('vehicles.status')"
            :placeholder="$t('vehicles.status')"
            :rules="statusRules"
            item-value="code"
            :items="vehicleStatuses"
            prepend-inner-icon="mdi-list-status"
            outlined
          >
            <template v-slot:item="{ item }">
              {{ $t("vehicle_states." + item.code.toLowerCase()) }}
            </template>
            <template v-slot:selection="{ item }">
              {{ $t("vehicle_states." + item.code.toLowerCase()) }}
            </template>
          </v-select>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer />
        <v-btn
          class="black--text"
          color="primary"
          depressed
          :disabled="saving || operatorsLoading"
          @click="save"
        >
          {{ $t("global.create") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
const DEFAULT_ITEM = {
  registration_plate: "",
  vehicle_type: "",
  seating_capacity: "",
  phone: "",
  brand: "",
  model: "",
  license_number: "",
  permit_number: "",
  client_number: "",
  rate_type: "",
  status: "APPROVED",
  notes: "",
  valid_until: null,
  vehicle_image: null,
  responsible_name: "",
};
import { phoneNumberValidation } from "@/helpers/common_validations.js";
import VehicleTypeSelector from "@/components/Main/Vehicles/VehicleTypeSelector";
import configs from "@/helpers/configs";
import Loading from "@/components/Main/Loading";
import DateInput from "@/components/Main/DateInput";

export default {
  props: ["open"],
  components: { VehicleTypeSelector, Loading, DateInput },
  data() {
    return {
      dateNow: new Date(),
      imageUrl: null,
      saving: false,
      selectorWarning: false,
      expand: false,
      messages: [],
      requiredRule: [(v) => !!v || this.$t("rules.required_field")],
      phoneNumberRules: [phoneNumberValidation],
      registrationPlateRules: [
        (v) => !!v || this.$t("rules.required_field"),
        (v) => (v && v.length <= 255) || this.$t("rules.character_limit"),
      ],
      operatorRules: [(v) => !!v || this.$t("rules.required_field")],
      statusRules: [(v) => !!v || this.$t("rules.required_field")],
      seatingCapacityRules: [
        (v) => {
          if (v && v.toString().trim() !== "") {
            if (/^[0-9]+$/.test(v)) {
              const parsedValue = parseInt(v);
              return parsedValue > 0
                ? true
                : this.$t("rules.invalid_positive_integer");
            } else {
              return this.$t("rules.invalid_positive_integer");
            }
          }
          return true;
        },
      ],
      item: Object.assign({}, DEFAULT_ITEM),
      isUpdatingOperator: false,
    };
  },
  created() {
    this.item = Object.assign({}, DEFAULT_ITEM);
  },
  computed: {
    vehicleStatuses() {
      return configs.getVehicleStatuses();
    },
    operatorsLoading() {
      return this.$store.state.operators.loading;
    },
    operators() {
      return this.$store.state.operators.all;
    },
    isOpen: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("update:open", val);
      },
    },
  },
  watch: {
    open(val) {
      if (val) {
        this.$nextTick(() => {
          this.$refs.form.resetValidation();
          this.$store.dispatch("operators/GET_ALL");
        });
      }
    },
    item: {
      handler(val) {
        if (val && val.vehicle_type != "TAXI") {
          val.license_number = "";
          val.permit_number = "";
        }
      },
      deep: true,
    },
  },
  methods: {
    transfromRegistrationPlate() {
      // Remove any character that is not a letter or a number and after that transform to uppercase
      this.item.registration_plate = this.item.registration_plate
        .replace(/[^a-zA-Z0-9]/g, "")
        .toUpperCase();
    },
    handleImageUpload(event) {
      const file = event;
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          this.imageUrl = reader.result;
        };
        reader.readAsDataURL(file);
      } else {
        this.removeImage();
      }
    },
    removeImage() {
      this.item.vehicle_image = null;
      this.imageUrl = null;
    },
    close() {
      this.isOpen = false;
      setTimeout(() => {
        this.expand = false;
        this.saving = false;
        this.selectorWarning = false;
      }, 600);
    },
    save() {
      this.expand = false;
      this.saving = true;
      this.selectorWarning = false;
      this.messages = [];

      if (this.$refs.form.validate() && this.item.vehicle_type != "" &&  this.item.vehicle_type != undefined) {
        let vehicle = Object.assign({}, this.item);
        this.$store.dispatch("vehicles/CREATE", vehicle).then((res) => {
          if (res.errors) {
            Object.entries(res.errors).forEach(([, value]) => {
              this.messages = this.messages.concat(value);
            });
            this.expand = true;
            this.saving = false;
            this.selectorWarning = false;
          } else {
            this.close();
          }
        });
      } else {
        this.expand = false;
        this.saving = false;

        if (this.item.vehicle_type == "" || this.item.vehicle_type == undefined) {
          this.selectorWarning = true;
        }
      }
    },
  },
};
</script>
<style scoped>
.form-content {
  position: relative;
  min-height: calc(50vh - var(--toolbar-height) - 40px);
  max-height: calc(80vh - var(--toolbar-height) - 40px);
}

.registration-plate-font >>> .v-input__control input {
  font-family: "Fira Mono", monospace !important;
  font-weight: 300 !important;
}
</style>
