<template>
  <div class="v-application--wrap">
    <AppBar />
    <v-main>
      <transition name="fade-transform" mode="out-in">
        <v-container class="fill-height pa-0" fluid>
          <v-layout column>
            <v-flex xs12>
              <v-sheet
                :class="
                  /* $vuetify.breakpoint.mobile */
                  shouldShowBottomNav ? 'main-content-mobile' : 'main-content'
                "
              >
                <AppInstallBanner></AppInstallBanner>
                <router-view></router-view>
              </v-sheet>
            </v-flex>
          </v-layout>
        </v-container>
      </transition>
    </v-main>
    <MobileBottomNav v-if="shouldShowBottomNav"></MobileBottomNav>
  </div>
</template>
<script>
import configs from "@/helpers/configs";
import AppBar from "@/components/Main/AppBar";
import AppInstallBanner from "@/components/Main/AppInstallBanner";
import MobileBottomNav from "@/components/Main/MobileBottomNav";
const MOBILE_PAGE_NAMES = ["Main", "EntryRequests", "UserVehicles", "MembersOperator"];
export default {
  components: {
    AppBar,
    MobileBottomNav,
    AppInstallBanner,
  },
  computed: {
    shouldShowBottomNav() {
      return (
        MOBILE_PAGE_NAMES.includes(this.$route.name) &&
        this.$vuetify.breakpoint.mobile &&
        this.isShorexUser
      );
    },
    userAccount() {
      return this.$store.state.user.account;
    },
    userType() {
      return this.userAccount && this.userAccount.user
        ? this.userAccount.user.type
        : "";
    },
    isShorexUser() {
      return this.userType == configs.getShorexUserType();
    },
  },
};
</script>

<style scoped>
.main-content {
  height: calc(100dvh - var(--toolbar-height));
  position: relative;
  overflow-y: auto;
}
.main-content-mobile {
  height: calc(100dvh - var(--toolbar-height) - var(--bottom-nav-height));
  position: relative;
  overflow-y: auto;
}
</style>
