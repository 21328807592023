<template>
  <div class="ma-4">
    <div class="d-flex flex-wrap">
      <div class="ma-0 pa-0">
        <p class="text-h5 font-weight-black mb-0">
          {{ $t("audit_logs.view_logs") }}
        </p>
        <p class="text-caption">
          {{ $t("audit_logs.view_logs_explanation") }}
        </p>
      </div>
    </div>
    <List></List>
  </div>
</template>
<script>
import List from "@/components/Configurations/AuditLogs/List";

export default {
  components: {
    List,
  },
};
</script>
