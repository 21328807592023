<template>
  <v-data-table
    class="all-noshorex_entry-table"
    :footer-props="{
      showFirstLastPage: true,
      firstIcon: 'mdi-page-first',
      lastIcon: 'mdi-page-last',
      'items-per-page-options': [5, 10, 25, 50],
    }"
    :loading="loading"
    :search.sync="options.search"
    :page.sync="options.page"
    :headers="headers"
    :items="items"
    :items-per-page.sync="options.itemsPerPage"
    :server-items-length="totalItemCount"
    @update:options="handleOptionsUpdate('options', $event)"
    item-key="id"
    fixed-header
    :sort-by.sync="options.sortBy"
    :sort-desc.sync="options.sortDesc"
    must-sort
  >
    <template v-slot:top>
      <v-text-field
        v-model="options.search"
        class="mb-4"
        single-line
        :label="$t('noshorex_entries.search')"
        prepend-inner-icon="mdi-magnify"
        outlined
        hide-details
        @input="filterItems"
      >
      </v-text-field>
    </template>
    <template
      v-if="hasUserPermissionToManage('NOSHOREX_ENTRIES')"
      v-slot:[`item.actions`]="{ item }"
    >
      <v-menu bottom absolute>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item @click="remove(item)">
            <v-list-item-icon class="mr-2">
              <v-icon>mdi-delete</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t("global.remove") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
    <template v-slot:item.registration_plate="{ item }">
      <span class="registration-plate-font">
        {{ item.registration_plate }}</span
      >
    </template>
    <template v-slot:item.vehicle_at_pier_start="{ item }">
      {{ item.vehicle_at_pier_start | formatDatetimeFromDatabase }}
    </template>
    <template v-slot:item.vehicle_at_pier_end="{ item }">
      {{ item.vehicle_at_pier_end | formatDatetimeFromDatabase }}
    </template>
    <template v-slot:item.updated_at="{ item }">
      {{ item.updated_at | formatDatetime }}
    </template>
  </v-data-table>
</template>

<script>
import { debounce } from "debounce";

export default {
  data() {
    return {
      menu: false,
      headers: [
        {
          text: "",
          value: "actions",
          sortable: false,
          width: "30px",
        },
        {
          text: this.$t("noshorex_entries.operator_name"),
          value: "operator_name",
        },
        {
          text: this.$t("noshorex_entries.type_name"),
          value: "type_name",
        },
        {
          text: this.$t("noshorex_entries.registration_plate"),
          value: "registration_plate",
        },
        {
          text: this.$t("noshorex_entries.vehicle_at_pier_start"),
          value: "vehicle_at_pier_start",
        },
        {
          text: this.$t("noshorex_entries.vehicle_at_pier_end"),
          value: "vehicle_at_pier_end",
        },
        {
          text: this.$t("noshorex_entries.notes"),
          value: "notes",
        },
        {
          text: this.$t("global.created_by"),
          value: "creator_name",
          class: "font-weight-bold",

          sortable: true,
          width: "100px",
        },
        {
          text: this.$t("global.updated_by"),
          value: "updater_name",
          class: "font-weight-bold",

          sortable: true,
          width: "100px",
        },
        {
          text: this.$t("global.updated_at"),
          value: "updated_at",
          class: "font-weight-bold",

          sortable: true,
          width: "150px",
        },
      ],
    };
  },
  computed: {
    loading() {
      return this.$store.state.noshorex_entries.loadingPaginated;
    },
    items() {
      return this.$store.state.noshorex_entries.allPaginated;
    },
    options: {
      cache: false,
      get() {
        return this.$store.state.noshorex_entries.options;
      },
      set(val) {
        this.$store.dispatch("noshorex_entries/UPDATE_OPTIONS", val);
      },
    },
    totalItemCount() {
      return this.$store.state.noshorex_entries.total;
    },
  },
  mounted() {
    this.$store.dispatch("noshorex_entries/RESET");
  },
  methods: {
    loadNoShorexEntries() {
      this.$store.dispatch("noshorex_entries/GET_ALL_PAGINATED").catch((ex) => {
        console.error("Error loading noshorex_entries", ex);
      });
    },

    filterItems: debounce(function () {
      if (this.options.page == 1) {
        this.loadNoShorexEntries();
      } else {
        this.options.page = 1;
      }
    }, 500),

    handleOptionsUpdate: debounce(function () {
      this.loadNoShorexEntries();
    }, 500),

    remove(item) {
      this.$emit("remove", item);
    },
  },
};
</script>

<style>
.all-noshorex_entry-table .v-data-table__wrapper {
  max-height: calc(100vh - 290px);
}
</style>

<style scoped>
.registration-plate-font {
  font-family: "Fira Mono", monospace !important;
}
</style>
