import { operatorService } from "@/api_services/operators.service";
import { sharedHelpers } from "@/api_services/utils/shared-helpers";

const DEFAULT_STATE = {
  currentOperator: {},
  all: [],
  loading: false,
  allPaginated: [],
  loadingPaginated: false,
  vehicles: [],
  operatorLicenses: [],
  approved_operators: [],
  vehiclesLoading: false,
  options: {
    search: "",
    page: 1,
    itemsPerPage: 10,
    sortBy: "vat_number",
    sortDesc: false,
    filterByStatus: null,
    filterByAccredited: null,
  },
  total: 0,
  members: [],
  membersLoading: false,
};

export default {
  namespaced: true,
  state: Object.assign({}, JSON.parse(JSON.stringify(DEFAULT_STATE))),
  getters: {},
  mutations: {
    setCurrentOperator(state, currentOperator) {
      state.currentOperator = JSON.parse(currentOperator);
    },
    setOptions(state, options) {
      state.options = options;
    },
    setTotal(state, total) {
      state.total = total;
    },
    setOperators(state, operators) {
      state.all = operators;
    },
    setApprovedOperators(state, operators) {
      state.approved_operators = operators;
    },
    setLoading(state, isLoading) {
      state.loading = isLoading;
    },
    setPaginated(state, operators) {
      state.allPaginated = operators;
    },
    setLoadingPaginated(state, isLoading) {
      state.loadingPaginated = isLoading;
    },
    setVehicles(state, vehicles) {
      state.vehicles = vehicles;
    },
    setOperatorLicenses(state, operatorLicenses) {
      state.operatorLicenses = operatorLicenses;
    },
    setVehiclesLoading(state, isLoading) {
      state.vehiclesLoading = isLoading;
    },
    setMembers(state, members) {
      state.members = members;
    },
    setMembersLoading(state, isLoading) {
      state.membersLoading = isLoading;
    },
    setDefaultState(state) {
      Object.assign(state, JSON.parse(JSON.stringify(DEFAULT_STATE)));
    },
  },
  actions: {
    SET_CURRENT_OPERATOR: (context, currentOperator) => {
      context.commit("setCurrentOperator", currentOperator);
    },
    UPDATE_OPTIONS(context, options) {
      context.commit("setOptions", options);
    },
    GET_ALL: (context) => {
      return new Promise((resolve, reject) => {
        context.commit("setLoading", true);
        operatorService
          .getOperators()
          .then((res) => {
            context.commit("setOperators", res.data);
            context.commit("setLoading", false);
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            context.commit("setLoading", false);
            reject(e);
          });
      });
    },

    GET_OPERATOR_BY_ID: (context, id) => {
      return new Promise((resolve, reject) => {
        operatorService
          .getOperatorById(id)
          .then((res) => {
            context.commit("setCurrentOperator", JSON.stringify(res.data));
            sharedHelpers.setStorageItemWithExpiry(
              "operator",
              JSON.stringify(res.data),
              8640000 //24hours
            );
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    GET_APPROVED: (context) => {
      return new Promise((resolve, reject) => {
        context.commit("setLoading", true);
        operatorService
          .getApprovedOperators()
          .then((res) => {
            context.commit("setApprovedOperators", res.data);
            context.commit("setLoading", false);
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            context.commit("setLoading", false);
            reject(e);
          });
      });
    },

    GET_ALL_PAGINATED: (context, options) => {
      return new Promise((resolve, reject) => {
        context.commit("setLoadingPaginated", true);
        context.commit("setPaginated", []);
        if (options) {
          context.commit("setOptions", options);
        }
        operatorService
          .getOperatorsPaginated(context.state.options)
          .then((res) => {
            context.commit("setPaginated", res.data.data);
            context.commit("setTotal", res.data.total);
            context.commit("setLoadingPaginated", false);
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            context.commit("setLoadingPaginated", false);
            reject(e);
          });
      });
    },

    GET_OPERATOR_VEHICLES_APPROVED: (context, payload) => {
      return new Promise((resolve, reject) => {
        context.commit("setVehiclesLoading", true);
        if (payload?.operator_id) {
          operatorService
            .getOperatorVehiclesApproved(payload.operator_id)
            .then((res) => {
              context.commit("setVehicles", res.data);
              context.commit("setVehiclesLoading", false);
              resolve(res.data);
            })
            .catch((e) => {
              console.error(e);
              context.commit("setVehiclesLoading", false);
              reject(e);
            });
        } else {
          context.commit("setVehiclesLoading", false);
        }
      });
    },

    CREATE: (context, operator) => {
      return new Promise((resolve, reject) => {
        operatorService
          .createOperator(operator)
          .then((res) => {
            resolve(res.data);
            context.dispatch("GET_ALL_PAGINATED");
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    UPDATE: (context, operator) => {
      return new Promise((resolve, reject) => {
        operatorService
          .updateOperator(operator)
          .then((res) => {
            resolve(res.data);
            context.dispatch("GET_ALL_PAGINATED");
            context.dispatch("GET_OPERATOR_BY_ID", operator.id);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    UPDATE_LICENSES: (context, payload) => {
      return new Promise((resolve, reject) => {
        operatorService
          .updateOperatorLicenses(payload)
          .then((res) => {
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    DELETE: (context, operatorId) => {
      return new Promise((resolve, reject) => {
        operatorService
          .deleteOperator(operatorId)
          .then((res) => {
            resolve(res.data);
            context.dispatch("GET_ALL_PAGINATED");
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    IMPORT: (context, file) => {
      return new Promise((resolve, reject) => {
        operatorService
          .importOperators(file)
          .then((res) => {
            resolve(res.data);
            context.dispatch("GET_ALL_PAGINATED");
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },
    EXPORT: (_) => {
      return new Promise((resolve, reject) => {
        operatorService
          .exportOperators()
          .then((res) => {
            var fileURL = window.URL.createObjectURL(new Blob([res.data]));
            var fileLink = document.createElement("a");

            fileLink.href = fileURL;
            fileLink.setAttribute(
              "download",
              "[3SHOREX]LISTA_DE_OPERADORES.csv"
            );
            document.body.appendChild(fileLink);

            fileLink.click();

            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    DOWNLOAD_FILE: (_, licenseId) => {
      return new Promise((resolve, reject) => {
        operatorService
          .downloadFile(licenseId)
          .then((res) => {
            var fileURL = window.URL.createObjectURL(new Blob([res.data]));
            var fileLink = document.createElement("a");

            fileLink.href = fileURL;
            fileLink.setAttribute("download", "documento.pdf");
            document.body.appendChild(fileLink);

            fileLink.click();

            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    GET_OPERATOR_LICENSES: (context, id) => {
      return new Promise((resolve, reject) => {
        operatorService
          .getOperatorLicenses(id)
          .then((res) => {
            context.commit("setOperatorLicenses", res.data);
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    GET_MEMBERS: (context, operatorId) => {
      return new Promise((resolve, reject) => {
        context.commit("setMembersLoading", true);
        operatorService
          .getMembers(operatorId)
          .then((res) => {
            context.commit("setMembers", res.data);
            context.commit("setMembersLoading", false);
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            context.commit("setMembersLoading", false);
            reject(e);
          });
      });
    },

    GET_MEMBER: (_, data) => {
      return new Promise((resolve, reject) => {
        operatorService
          .getMember(data.operator_id, data.user_id)
          .then((res) => {
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    INVITE_MEMBER: (context, data) => {
      return new Promise((resolve, reject) => {
        operatorService
          .inviteMember(data.operator_id, data.member)
          .then((res) => {
            resolve(res.data);
            context.dispatch("members/GET_TEAM_MEMBERS", {}, { root: true });
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    RESET: (context) => {
      context.commit("setDefaultState");
    },
  },
};
