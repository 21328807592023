import configs from "@/helpers/configs";
import router from "@/router/index";
import store from "@/store/index";
export const sharedHelpers = {
  getAuthToken,
  getUserPermissions,
  getLocode,
  setStorageItemWithExpiry,
  getStorageItemWithExpiry,
  getRequestHeaders,
  getRequestHeadersFormData,
  getUserLocodes,
  updateAccountData,
  getUserTimezone,
  getPubliRequestHeaders,
  removeStorageItem,
};

function getRequestHeaders() {
  let headers = {};
  // Auth token
  let authToken = getAuthToken();
  if (authToken) {
    headers["Authorization"] = "Bearer " + authToken;
  } else {
    //If the authToken is null that means it is expired so we redirect to login
    store.commit("setShowSessionErr", true);
    // remove from local storage the account info
    localStorage.removeItem("account");
    router.push("/login", () => {});
  }
  headers["Content-Type"] = "application/json";
  let locode = getLocode();
  if (locode) {
    headers["X-PortLocode"] = locode.code;
  }
  return headers;
}

function getPubliRequestHeaders() {
  let headers = {};
  headers["Content-Type"] = "application/json";
  let locode = getLocode();
  if (locode) {
    headers["X-PortLocode"] = locode.code;
  }
  return headers;
}

function getRequestHeadersFormData() {
  let headers = {};
  // Auth token
  let authToken = getAuthToken();
  if (authToken) {
    headers["Authorization"] = "Bearer " + authToken;
  }
  headers["Content-Type"] =
    "multipart/form-data; boundary=" + generateBoundary();
  let locode = getLocode();
  if (locode) {
    headers["X-PortLocode"] = locode.code;
  }
  return headers;
}
function generateBoundary() {
  return "------" + new Date().getTime().toString(16);
}

function getLocode() {
  return configs.getLocode();
}

function getAuthToken() {
  let account = JSON.parse(getStorageItemWithExpiry("account"));
  return account && account.access_token ? account.access_token : null;
}

function getUserLocodes() {
  let account = JSON.parse(getStorageItemWithExpiry("account"));
  return account && account.user && account.user.locodes
    ? account.user.locodes
    : [];
}

function getUserPermissions() {
  let account = JSON.parse(getStorageItemWithExpiry("account"));
  return account && account.user && account.user.user_permissions
    ? account.user.user_permissions
    : [];
}

//Functions accessed by this module
function setStorageItemWithExpiry(key, value, ttl) {
  const now = new Date();

  // `item` is an object which contains the original value
  // as well as the time when it's supposed to expire
  const item = {
    value: value,
    expiry: now.getTime() + ttl,
  };

  localStorage.setItem(key, JSON.stringify(item));
}

function removeStorageItem(key) {
  localStorage.removeItem(key);
}

function getStorageItemWithExpiry(key) {
  const itemStr = localStorage.getItem(key);
  // if the item doesn't exist, return null
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();
  // compare the expiry time of the item with the current time
  if (now.getTime() > item.expiry) {
    // If the item is expired, delete the item from storage
    // and return null
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
}

function updateAccountData(account) {
  const itemStr = localStorage.getItem("account");
  if (!itemStr) {
    throw new Error("Account data not found in localStorage.");
  }
  const item = JSON.parse(itemStr);
  if (item && item.value) {
    let oldUserAccount = JSON.parse(item.value);
    if (oldUserAccount && oldUserAccount.user) {
      let newUserAccount = Object.assign({}, oldUserAccount);
      newUserAccount.user = Object.assign(oldUserAccount.user, account);
      item.value = JSON.stringify(newUserAccount);
    }
  }
  localStorage.setItem("account", JSON.stringify(item));
}

function getUserTimezone() {
  const options = { timeZoneName: "long" };
  const formatter = new Intl.DateTimeFormat("en-US", options);
  return formatter.resolvedOptions().timeZone;
}
