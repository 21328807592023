import configs from "@/helpers/configs";
import axios from "axios";
import { sharedHelpers } from "@/api_services/utils/shared-helpers";

export const vesselCallsService = {
  getAll,
  getScheduling,
  getVesselCallPassengers,
  getUpcoming,
  getVesselCall,
  getByDay,
  getVesselCallsLocations,
};

async function getAll(options) {
  let sort = options.sortDesc ? "DESC" : "ASC";
  var requestOptions = {
    method: "GET",
    headers: sharedHelpers.getRequestHeaders(),
    params: {
      itemsPerPage: options.itemsPerPage,
      currentPage: options.currentPage,
      sortBy: options.sortBy,
      sort: sort,
      search: options.search,
    },
  };
  return await axios.get(
    configs.getApiUrl() + `/v1/vessel_calls`,
    requestOptions
  );
}

async function getVesselCall(processNumber) {
  var requestOptions = {
    method: "GET",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.get(
    configs.getApiUrl() + `/v1/vessel_calls/${processNumber}`,
    requestOptions
  );
}

async function getScheduling(options) {
  const requestOptions = {
    method: "POST",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.post(
    configs.getApiUrl() + `/v1/vessel_calls/scheduler`,
    options,
    requestOptions
  );
}

async function getVesselCallPassengers(processNumber, options) {
  let sort = options.sortDesc ? "DESC" : "ASC";
  var requestOptions = {
    method: "GET",
    headers: sharedHelpers.getRequestHeaders(),
    params: {
      itemsPerPage: options.itemsPerPage,
      currentPage: options.currentPage,
      sortBy: options.sortBy,
      sort: sort,
      search: options.search,
    },
  };
  return await axios.get(
    configs.getApiUrl() + `/v1/vessel_calls/${processNumber}/passengers`,
    requestOptions
  );
}

async function getUpcoming() {
  const requestOptions = {
    method: "GET",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.get(
    configs.getApiUrl() + `/v1/vessel_calls/upcoming`,
    requestOptions
  );
}

async function getByDay(day) {
  var requestOptions = {
    method: "GET",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.get(
    configs.getApiUrl() + `/v1/vessel_calls/day/${day}`,
    requestOptions
  );
}

async function getVesselCallsLocations(date) {
  var requestOptions = {
    method: "POST",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.post(
    configs.getApiUrl() + "/v1/vessel_calls/lsw_locations",
    { date: date },
    requestOptions
  );
}
