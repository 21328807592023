<template>
  <div class="fill-height fill-width ma-1">
    <v-card
      class="fill-width mx-auto"
      @click.stop="handleEntryClick"
      outlined
      :dark="active ? true : false"
    >
      <v-toolbar flat dense>
        <v-toolbar-title>
          <v-list-item two-line class="ma-0 pa-0 pt-2">
            <v-list-item-content
              v-if="item.vehicle.vehicle_type.code != 'TAXI'"
            >
              <v-list-item-title class="registration-plate-font text-h6">
                {{ item.vehicle.registration_plate }}</v-list-item-title
              >
              <v-list-item-subtitle class="text-caption"
                >Matrícula</v-list-item-subtitle
              >
            </v-list-item-content>

            <v-list-item-content v-else>
              <v-list-item-title class="registration-plate-font text-h6">
                {{ item.vehicle.license_number }}</v-list-item-title
              >
              <v-list-item-subtitle class="text-caption"
                >Nº de Porta</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-chip
          dark
          class="text-uppercase overline"
          small
          label
          style="font-size: 0.6rem !important"
          :color="getEntryRequestColor(item)"
          >{{ $t(`entry_states.${item.status.toLowerCase()}`) }}</v-chip
        >
      </v-toolbar>
      <v-card-text class="pa-0" style="overflow: hidden; position: relative">
        <div class="backgroundIcon">
          <v-icon color="grey lighten-4" size="80">
            mdi-boom-gate-arrow-up-outline</v-icon
          >
        </div>
        <v-list dense>
          <v-list-item>
            <v-list-item-content class="pt-0 pb-0 ma-0">
              <v-list-item-title class="font-weight-bold text-subtitle-1">{{
                item.vessel_call.name
              }}</v-list-item-title>
              <v-list-item-subtitle class="text-caption"
                >{{ $t("global.ship") }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <template v-if="hasScheduledEntry">
            <v-list-item>
              <v-list-item-content class="pt-0 pb-0 ma-0">
                <v-list-item-title class="font-weight-bold text-subtitle-1"
                  >{{
                    item.scheduled_entry.vehicle_at_pier_start
                      | formatDatetimeFromDatabase
                  }}
                </v-list-item-title>
                <v-list-item-subtitle class="text-caption"
                  >Horário atríbuido
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
          <template v-else>
            <v-list-item>
              <v-list-item-content class="pt-0 pb-0 ma-0">
                <v-list-item-title class="font-weight-bold text-subtitle-1">{{
                  item.vehicle_at_pier_date | formatDatetimeFromDatabase
                }}</v-list-item-title>
                <v-list-item-subtitle class="text-caption"
                  >Horário pedido
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
          <v-list-item>
            <v-row>
              <v-col>
                <v-list-item-content class="pt-0 pb-0 ma-0">
                  <v-list-item-title class="font-weight-bold text-subtitle-1">{{
                    item.number_of_passengers || "N/D"
                  }}</v-list-item-title>
                  <v-list-item-subtitle class="text-caption"
                    >{{ $t("scheduled_entries.number_of_passengers") }}
                  </v-list-item-subtitle>
                </v-list-item-content> </v-col
              ><v-col>
                <v-list-item-content class="pt-0 pb-0 ma-0 text-right">
                  <v-list-item-title
                    class="font-weight-bold text-subtitle-1"
                    style="color: #fdb813"
                  >
                    {{ item.code }}</v-list-item-title
                  >
                  <v-list-item-subtitle class="text-caption"
                    >Código do Pedido</v-list-item-subtitle
                  >
                </v-list-item-content></v-col
              >
            </v-row>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import configs from "@/helpers/configs";
export default {
  props: ["item", "active"],
  computed: {
    entryRequestStatuses() {
      return configs.getEntryRequestStatuses();
    },
    hasScheduledEntry() {
      return this.item && this.item.scheduled_entry;
    },
  },
  methods: {
    getEntryRequestColor(item) {
      let status = this.entryRequestStatuses.find((v) => {
        return v.code == item.status;
      });
      if (status) {
        return status.color;
      }
      return null;
    },
    handleEntryClick(e) {
      this.$emit("click", e);
    },
  },
};
</script>

<style scoped>
.backgroundIcon {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  position: absolute;
  width: 100%;
  height: 100%;
  margin-top: 10px;
}

.registration-plate-font {
  font-family: "Fira Mono", monospace !important;
  font-weight: 700 !important;
}
</style>
