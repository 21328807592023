import Vue from "vue";
import Vuex from "vuex";

import user from "./modules/user";
import users from "./modules/users";
import operator_types from "./modules/operator_types";
import settings from "./modules/settings";
import configurations from "./modules/configurations";
import vessel_calls from "./modules/vessel_calls";
import operators from "./modules/operators";
import registered_users from "./modules/registered_users";
import vehicles from "./modules/vehicles";
import user_vehicles from "./modules/user_vehicles";
import zones from "./modules/zones";
import stats from "./modules/stats";
import audit_logs from "./modules/audit_logs";
import entry_requests from "./modules/entry_requests";
import scheduled_entries from "./modules/scheduled_entries";
import scheduled_entry_scans from "./modules/scheduled_entry_scans";
import countries from "./modules/countries";
import plans from "./modules/plans";
import vehicle_types from "./modules/vehicle_types";
import shorex from "./modules/shorex";
import sectors from "./modules/sectors";
import members from "./modules/members";
import ui from "./modules/ui";
import ais_ships from "./modules/ais_ships";
import scanned_vehicles from "./modules/scanned_vehicles";
import noshorex_entries from "./modules/noshorex_entries";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
    users,
    settings,
    configurations,
    vessel_calls,
    ui,
    operator_types,
    operators,
    registered_users,
    zones,
    vehicles,
    user_vehicles,
    shorex,
    sectors,
    entry_requests,
    countries,
    scheduled_entries,
    scheduled_entry_scans,
    plans,
    audit_logs,
    vehicle_types,
    members,
    ais_ships,
    scanned_vehicles,
    stats,
    noshorex_entries
  },
  actions: {
    CLEAR_ALL(context) {
      // Clear existing namespaced stores
      context.dispatch("audit_logs/RESET", {}, { root: true });
      context.dispatch("operator_types/RESET", {}, { root: true });
      context.dispatch("operators/RESET", {}, { root: true });
      context.dispatch("plans/RESET", {}, { root: true });
      context.dispatch("registered_users/RESET", {}, { root: true });
      context.dispatch("users/RESET", {}, { root: true });
      context.dispatch("vehicle_types/RESET", {}, { root: true });
      context.dispatch("vehicles/RESET", {}, { root: true });
      context.dispatch("zones/RESET", {}, { root: true });
      context.dispatch("scheduled_entry_scans/RESET", {}, { root: true });
      context.dispatch("noshorex_entries/RESET", {}, { root: true });
    },
  },
});
