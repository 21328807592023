import configs from "@/helpers/configs";
import axios from "axios";
import { sharedHelpers } from "@/api_services/utils/shared-helpers";

export const auditLogsService = {
  getAuditLogs,
};

async function getAuditLogs(options) {
  let sort = options.sortDesc ? "DESC" : "ASC";
  const requestOptions = {
    method: "GET",
    headers: sharedHelpers.getRequestHeaders(),
    params: {
      itemsPerPage: options.itemsPerPage,
      currentPage: options.page,
      sortBy: options.sortBy,
      sort: sort,
      search: options.search,
    },
  };
  return await axios.get(
    configs.getApiUrl() + "/v1/audit_logs",
    requestOptions
  );
}
