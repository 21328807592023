<template>
  <v-sheet style="min-height: 150px; position: relative">
    <Loading v-model="loading"></Loading>
    <div v-if="!loading" class="fill-height ma-2">
      <v-text-field
        :disabled="loading"
        v-model="search"
        clearable
        flat
        solo
        outlined
        hide-details
        prepend-inner-icon="mdi-magnify"
        :label="$t('global.search')"
        class="mb-2"
      ></v-text-field>

      <div class="text-center no-results" v-if="items.length == 0">
        <v-icon left>mdi-car-off</v-icon
        >{{ $t("vehicles.no_approved_vehicles_found") }}
      </div>

      <v-item-group v-else multiple v-model="itemsSelected">
        <v-row>
          <v-col
            v-for="item in items"
            :key="item.id"
            cols="12"
            lg="3"
            md="4"
            sm="12"
          >
            <v-item v-slot="{ active, toggle }" v-model="item.id">
              <v-card
                outlined
                :dark="active"
                :color="active ? (isComplete(item) ? 'primary' : 'red') : ''"
                width="auto"
                class="ma-0"
                style="cursor: pointer"
              >
                <v-toolbar flat @click="toggle"
                  ><v-btn icon v-if="active">
                    <v-icon>mdi-check-circle</v-icon>
                  </v-btn>
                  <v-toolbar-title style="padding-left: 0px !important">
                    <div
                      v-if="item.vehicle_type.code != 'TAXI'"
                      class="registration-plate-font"
                    >
                      {{ item.registration_plate }}
                    </div>
                    <div v-else class="registration-plate-font">
                      {{ item.license_number }}
                    </div>
                    <div class="text-caption">
                      {{ item.vehicle_type.name }}
                    </div>
                  </v-toolbar-title>
                  <div class="ml-6">
                    <v-icon color="grey lighten-4" size="50">{{
                      item.vehicle_type.icon
                    }}</v-icon>
                  </div>
                  <v-spacer />
                  <v-btn
                    v-if="active"
                    small
                    @click.stop="editEntry(item)"
                    fab
                    outlined
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </v-toolbar>

                <v-divider />
                <v-card-text class="pa-0" @click="toggle">
                  <v-list dense>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          class="font-weight-bold text-subtitle-1"
                          >{{
                            item.responsible_name
                              ? item.responsible_name
                              : "N/D"
                          }}</v-list-item-title
                        >
                        <v-list-item-subtitle class="text-caption"
                          >{{ $t("vehicles.responsible_name") }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          class="font-weight-bold text-subtitle-1"
                        >
                          {{ item.responsible_phone ? item.responsible_phone : "N/D" }}
                        </v-list-item-title>
                        <v-list-item-subtitle class="text-caption"
                          >{{ $t("vehicles.responsible_phone") }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions style="justify-content: center">
                  <div style="font-weight: 800; font-size: 1rem" v-if="active">
                    <span>{{ $t("global.hour") }}:</span>
                    <span>
                      {{
                        item.vehicle_at_pier_time
                          ? item.vehicle_at_pier_time
                          : "N/D"
                      }}</span
                    >
                  </div>
                </v-card-actions>
              </v-card>
            </v-item>
          </v-col>
        </v-row>
      </v-item-group>

      <v-row>
        <v-col cols="12" lg="3" md="4" sm="12">
          <v-btn
            color="primary"
            class="black--text mt-2"
            large
            block
            width="100%"
            depressed
            @click="handleNewVehicleClick"
            >Veículo novo? Registe-o aqui!</v-btn
          ></v-col
        ></v-row
      >
    </div>
    <NewVehicleForm
      v-model="addVehicleForm"
      @closed="loadVehicles()"
    ></NewVehicleForm>

    <EditVehicleEntry
      v-if="openEditVehicleEntry"
      :open.sync="openEditVehicleEntry"
      :item.sync="entryToEdit"
      :date="date"
      :vesselCall="vesselCall"
    ></EditVehicleEntry>
  </v-sheet>
</template>
<script>
import NewVehicleForm from "@/components/Main/Vehicles/NewVehicleForm";
import EditVehicleEntry from "@/components/Main/Vehicles/EditVehicleEntry";
import Loading from "@/components/Main/Loading";
export default {
  props: ["date", "vesselCall"],
  components: { Loading, NewVehicleForm, EditVehicleEntry },
  data() {
    return {
      loading: false,
      vehicles: [],
      search: "",
      itemsSelected: [],
      addVehicleForm: false,
      entryToEdit: null,
      openEditVehicleEntry: false,
    };
  },
  mounted() {
    this.loadVehicles();
  },
  computed: {
    items() {
      if (!this.search || this.search === "") return this.vehicles;

      // Remove dashes from the search string
      const sanitizedSearch = this.search.replace(/-/g, "");

      // Create a search regex without dashes
      const searchRegex = new RegExp(sanitizedSearch, "i");

      return this.vehicles.filter((item) => {
        // Remove dashes from the registration plate before testing against the search regex
        const sanitizedPlate = item.registration_plate.replace(/-/g, "");
        return (
          searchRegex.test(sanitizedPlate) ||
          this.itemsSelected.includes(item.id)
        );
      });
    },
  },
  watch: {
    itemsSelected() {
      this.updateItems();
    },
    entryToEdit: {
      handler() {
        this.updateItems();
      },
      deep: true,
    },
  },
  methods: {
    editEntry(item) {
      this.entryToEdit = item;
      this.openEditVehicleEntry = true;
    },
    isComplete(vehicle) {
      return (
        vehicle.vehicle_at_pier_time &&
        vehicle.responsible_name &&
        vehicle.responsible_phone
      );
    },
    loadVehicles() {
      this.vehicles = [];
      this.loading = true;
      this.$store
        .dispatch("user_vehicles/GET_APPROVED_USER_VEHICLES")
        .then((data) => {
          this.vehicles = data.map((vehicle) => {
            return { ...vehicle, responsible_phone: vehicle.phone };
          });
          this.loading = false;
        })
        .catch((ex) => {
          console.error("Error loading vehicles", ex);
          this.loading = false;
          this.vehicles = [];
        });
    },
    handleNewVehicleClick() {
      this.addVehicleForm = true;
    },
    updateItems() {
      let selectedItems = [];
      if (this.itemsSelected) {
        selectedItems = this.items.filter((obj) =>
          this.itemsSelected.includes(obj.id)
        );
      }

      let vehicles = [];

      for (let index = 0; index < selectedItems.length; index++) {
        const vehicle = selectedItems[index];
        vehicles.push(vehicle);
      }

      this.$emit("update:items", JSON.parse(JSON.stringify(vehicles)));
    },
  },
};
</script>
<style scoped>
.no-results {
  min-height: 150px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.registration-plate-font {
  font-family: "Fira Mono", monospace !important;
  font-weight: 700 !important;
}
</style>
