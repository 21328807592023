<template>
  <v-bottom-sheet v-model="showEntryDetails" :persistent="loading">
    <v-card class="text-center" tile v-if="!!selectedItem">
      <v-toolbar flat color="primary">
        <v-toolbar-title class="font-weight-bold">
          <v-icon class="blac--text" left
            >mdi-boom-gate-arrow-up-outline</v-icon
          >
          {{ $t("scheduled_entries.scheduled_entry") }}</v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-btn icon @click="showEntryDetails = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="entry-details-section pa-2 ma-0">
        <Loading v-model="loading"></Loading>
        <v-card class="pa-0 ma-0" flat v-if="!!scheduledEntryDetails">
          <v-card-text class="pa-0 ma-0">
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <tr v-if="scheduledEntryDetails.entry_request.vehicle
                          .license_number">
                    <td width="50%" class="font-weight-black text-uppercase">
                      {{ $t("vehicles.license_number") }}
                    </td>
                    <td class="registration-plate-font text-h6">
                      {{
                        scheduledEntryDetails.entry_request.vehicle
                          .license_number
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td width="50%" class="font-weight-black text-uppercase">
                      {{ $t("vehicles.registration_plate") }}
                    </td>
                    <td class="registration-plate-font text-h6">
                      {{
                        scheduledEntryDetails.entry_request.vehicle
                          .registration_plate
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td width="50%" class="font-weight-black text-uppercase">
                      {{ $t("global.day") }}
                    </td>
                    <td>
                      {{
                        scheduledEntryDetails.vehicle_at_pier_start
                          | formatDatetimeFromDatabaseOnlyDate
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td width="50%" class="font-weight-black text-uppercase">
                      {{ $t("entries.vehicle_at_pier_start") }}
                    </td>
                    <td>
                      {{
                        scheduledEntryDetails.vehicle_at_pier_start
                          | formatDatetimeFromDatabaseOnlyTime
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td width="50%" class="font-weight-black text-uppercase">
                      {{ $t("entries.vehicle_at_pier_end") }}
                    </td>
                    <td>
                      {{
                        scheduledEntryDetails.vehicle_at_pier_end
                          | formatDatetimeFromDatabaseOnlyTime
                      }}
                    </td>
                  </tr>
                  <tr v-if="!!scheduledEntryDetails.sector">
                    <td width="50%" class="font-weight-black text-uppercase">
                      {{ $t("entries.sector") }}
                    </td>
                    <td>
                      {{ scheduledEntryDetails.sector.name }}
                    </td>
                  </tr>
                  <tr v-if="!!scheduledEntryDetails.zone">
                    <td width="50%" class="font-weight-black text-uppercase">
                      {{ $t("entries.zone") }}
                    </td>
                    <td>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            outlined
                            small
                            v-bind="attrs"
                            v-on="on"
                            @click="obtainCentroid(scheduledEntryDetails.zone)"
                            >{{ scheduledEntryDetails.zone.park }} -
                            {{ scheduledEntryDetails.zone.code
                            }}<v-icon right small
                              >mdi-map-marker-radius</v-icon
                            ></v-btn
                          ></template
                        >
                        <span>{{ $t("zones.go_to") }}</span>
                      </v-tooltip>
                    </td>
                  </tr>
                  <tr v-if="!!scheduledEntryDetails.entry_request.vessel_call">
                    <td width="50%" class="font-weight-black text-uppercase">
                      {{ $t("global.ship") }}
                    </td>
                    <td>
                      {{ scheduledEntryDetails.entry_request.vessel_call.name }}
                    </td>
                  </tr>
                  <tr
                    v-if="
                      !!scheduledEntryDetails.entry_request.responsible_name
                    "
                  >
                    <td width="50%" class="font-weight-black text-uppercase">
                      {{ $t("entries.responsible_name") }}
                    </td>
                    <td>
                      {{ scheduledEntryDetails.entry_request.responsible_name }}
                    </td>
                  </tr>
                  <tr
                    v-if="
                      !!scheduledEntryDetails.entry_request.responsible_phone
                    "
                  >
                    <td width="50%" class="font-weight-black text-uppercase">
                      {{ $t("entries.responsible_phone") }}
                    </td>
                    <td>
                      {{
                        scheduledEntryDetails.entry_request.responsible_phone
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td width="50%" class="font-weight-black text-uppercase">
                      {{ $t("entry_requests.number_of_passengers_to_collect") }}
                    </td>
                    <td>
                      {{
                        scheduledEntryDetails.entry_request
                          .number_of_passengers || "N/D"
                      }}
                    </td>
                  </tr>
                  <template
                    v-if="
                      scheduledEntryDetails.entry_request.passengers &&
                      scheduledEntryDetails.entry_request.passengers.length > 0
                    "
                  >
                    <tr
                      v-for="passenger in scheduledEntryDetails.entry_request.passengers.filter(
                        (p) => p.name
                      )"
                      :key="passenger.id"
                    >
                      <td colspan="2">
                        <div
                          class="d-flex justify-space-between flex-wrap py-2"
                        >
                          <span v-if="passenger.country">
                            <country-flag
                              :country="passenger.country.code.toLowerCase()"
                              size="small"
                            />
                          </span>
                          <div>
                            {{ passenger.name }}
                            <small
                              v-if="passenger.date_of_birth"
                              class="font-italic"
                            >
                              ({{
                                passenger.date_of_birth
                                  | formatDateFromDatabase
                              }})</small
                            >
                          </div>
                          <div class="ml-auto">
                            <v-icon
                              v-if="passenger.special_care_or_assistance"
                              right
                              small
                              >mdi-human-wheelchair</v-icon
                            >
                          </div>
                        </div>
                      </td>
                    </tr>
                  </template>
                  <tr v-if="!!scheduledEntryDetails.notes">
                    <td width="50%" class="font-weight-black text-uppercase">
                      {{ $t("global.notes") }}
                    </td>
                    <td>
                      {{ scheduledEntryDetails.notes }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
  </v-bottom-sheet>
</template>
<script>
import Loading from "@/components/Main/Loading";
import CountryFlag from "vue-country-flag";
export default {
  props: ["value", "item"],
  components: { Loading, CountryFlag },
  data() {
    return {
      loading: false,
      scheduledEntryDetails: null,
    };
  },
  computed: {
    selectedItem: {
      get() {
        return this.item;
      },
      set(val) {
        this.$emit("update:item", val);
      },
    },
    showEntryDetails: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
        if (!val) {
          this.selectedItem = null;
        }
      },
    },
    centroid() {
      return this.$store.state.zones.centroid;
    },
    isMobileDevice() {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  watch: {
    showEntryDetails(val) {
      if (val) {
        this.loadScheduledEntryDetails();
      }
    },
    centroid(val) {
      if (val) this.openLocation(val.lat, val.long);
    },
  },
  methods: {
    handleClose() {
      this.showEntryDetails = !this.showEntryDetails;
    },
    loadScheduledEntryDetails() {
      if (this.selectedItem && this.selectedItem.id) {
        this.loading = true;
        this.scheduledEntryDetails = null;
        this.$store
          .dispatch("scheduled_entries/GET_BY_ID", this.selectedItem.id)
          .then((data) => {
            this.loading = false;
            this.scheduledEntryDetails = data;
          })
          .catch((e) => {
            console.error(e);
          });
      }
    },
    obtainCentroid(zone) {
      this.loading = true;
      this.$store
        .dispatch("zones/CENTROID", zone)
        .then(() => {
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
        });
    },

    openLocation(latitude, longitude) {
      // Desktop users will always open google maps
      var url = `https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}`;

      // Depending on what the user has on mobile
      if (this.isMobileDevice) {
        // Check if Google Maps is available
        if (
          typeof window.google !== "undefined" &&
          typeof window.google.maps !== "undefined"
        ) {
          url = `https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}`;
        }
        // Check if Apple Maps is available
        else if (
          typeof window.apple !== "undefined" &&
          typeof window.apple.maps !== "undefined"
        ) {
          url = `http://maps.apple.com/?daddr=${latitude},${longitude}`;
        }
        // Check if Waze is available
        else if (typeof window.waze !== "undefined") {
          url = `https://waze.com/ul?ll=${latitude},${longitude}`;
        }
      }
      // Open the navigation app
      window.open(url);
    },
  },
};
</script>
<style scoped>
.entry-details-section {
  min-height: 300px;
  max-height: calc(60vh - var(--toolbar-height));
  overflow-y: auto;
  position: relative;
  text-align: left;
}
.entry-details-section:has(+ .entry-request-actions) {
  min-height: 300px;
  max-height: calc(60vh - var(--toolbar-height) - 60px);
}
.registration-plate-font {
  font-family: "Fira Mono", monospace !important;
  font-weight: 700 !important;
}
</style>
