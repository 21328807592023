import configs from "@/helpers/configs";
import axios from "axios";
import { sharedHelpers } from "@/api_services/utils/shared-helpers";

export const scheduledEntriesService = {
  getScheduledEntry,
  getScheduledEntryByToken,
  cancelScheduledEntry,
  getScheduledEntriesPaginated,
  registerEntryScan,
  registerExitScan,
};

async function getScheduledEntry(id) {
  const requestOptions = {
    method: "GET",
    headers: sharedHelpers.getRequestHeaders()
  };
  return await axios.get(
    configs.getApiUrl() + `/v1/my-shorex/scheduled-entries/${id}`,
    requestOptions
  );
}

async function getScheduledEntryByToken(token) {
  const requestOptions = {
    method: "GET",
    headers: sharedHelpers.getRequestHeaders()
  };
  return await axios.get(
    configs.getApiUrl() + `/v1/scheduled-entry/token/${token}`,
    requestOptions
  );
}

function blobToData(blob) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}

async function cancelScheduledEntry(id) {
  const requestOptions = {
    method: "PUT",
    headers: sharedHelpers.getRequestHeaders()
  };
  return await axios.put(
    `${configs.getApiUrl()}/v1/my-shorex/scheduled-entries/${id}/cancel`,
    {},
    requestOptions
  );
}

async function getScheduledEntriesPaginated(options) {
  let sort = options.sortDesc ? "DESC" : "ASC";
  const requestOptions = {
    method: "GET",
    headers: sharedHelpers.getRequestHeaders(),
    params: {
      itemsPerPage: options.itemsPerPage,
      currentPage: options.page,
      sortBy: options.sortBy,
      sort: sort,
      search: options.search,
      filterByStatus: options.filterByStatus,
    },
  };
  return await axios.get(
    configs.getApiUrl() + "/v1/scheduled_entries/paginated",
    requestOptions
  );
}

async function registerEntryScan(payload) {

  const requestOptions = {
    method: "POST",
    headers: sharedHelpers.getRequestHeaders()
  };
  return await axios.post(
    configs.getApiUrl() + "/v1/scheduled-entry/register_entry",
    payload,
    requestOptions
  );
}

async function registerExitScan(payload) {

  const requestOptions = {
    method: "POST",
    headers: sharedHelpers.getRequestHeaders()
  };
  return await axios.post(
    configs.getApiUrl() + "/v1/scheduled-entry/register_exit",
    payload,
    requestOptions
  );
}
