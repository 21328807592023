<template>
  <div>
    <v-badge color="red" overlap dot>
      <v-icon @click="openNotifications()"> mdi-bell </v-icon>
    </v-badge>
    <v-dialog v-model="notificationsOpen" width="80%" persistent scrollable>
      <v-card>
        <v-card-title class="text-h5 primary">
          {{ $t("global.notifications") }}

          <v-spacer></v-spacer>
          <v-btn icon @click="notificationsOpen = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="pa-2" style="position: relative; height: 230px;">
          <Loading
            v-if="loading"
            :value="loading"
            :custom-message="$t('global.loading')"
          ></Loading>

          <v-simple-table v-else>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left text-subtitle-1"></th>
                  <th class="text-subtitle-1">Pendentes</th>
                  <th class="text-subtitle-1">Aprovados</th>
                  <th class="text-subtitle-1">Bloqueados</th>
                  <th class="text-subtitle-1">Recusados</th>
                  <th class="text-subtitle-1">Cancelados</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="text-subtitle-1">
                    Operadores
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          @click="redirectToPage('/operators')"
                          small
                          v-bind="attrs"
                          v-on="on"
                          >mdi-open-in-new</v-icon
                        >
                      </template>
                      <span>{{ $t("global.go_to_page") }}</span>
                    </v-tooltip>
                  </td>
                  <td>
                    <v-chip
                      class="ma-2 text-uppercase"
                      label
                      outlined
                      small
                      :color="
                        notifications.operators_pending_count > 0
                          ? 'red'
                          : 'default'
                      "
                    >
                      <v-icon
                        left
                        small
                        v-if="notifications.operators_pending_count > 0"
                      >
                        mdi-alert
                      </v-icon>
                      {{ notifications.operators_pending_count }}
                    </v-chip>
                  </td>
                  <td>
                    <v-chip class="ma-2 text-uppercase" label outlined small>
                      {{ notifications.operators_approved_count }}
                    </v-chip>
                  </td>
                  <td>
                    <v-chip class="ma-2 text-uppercase" label outlined small>
                      {{ notifications.operators_blocked_count }}
                    </v-chip>
                  </td>
                  <td>
                    <v-chip class="ma-2 text-uppercase" label outlined small>
                      {{ notifications.operators_rejected_count }}
                    </v-chip>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td class="text-subtitle-1">
                    Veículos
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          @click="redirectToPage('/vehicles')"
                          small
                          v-bind="attrs"
                          v-on="on"
                          >mdi-open-in-new</v-icon
                        >
                      </template>
                      <span>{{ $t("global.go_to_page") }}</span>
                    </v-tooltip>
                  </td>
                  <td>
                    <v-chip
                      class="ma-2 text-uppercase"
                      label
                      outlined
                      small
                      :color="
                        notifications.vehicles_pending_count > 0
                          ? 'red'
                          : 'default'
                      "
                    >
                      <v-icon
                        left
                        small
                        v-if="notifications.vehicles_pending_count > 0"
                      >
                        mdi-alert
                      </v-icon>
                      {{ notifications.vehicles_pending_count }}
                    </v-chip>
                  </td>
                  <td>
                    <v-chip class="ma-2 text-uppercase" label outlined small>
                      {{ notifications.vehicles_approved_count }}
                    </v-chip>
                  </td>
                  <td>
                    <v-chip class="ma-2 text-uppercase" label outlined small>
                      {{ notifications.vehicles_blocked_count }}
                    </v-chip>
                  </td>
                  <td></td>
                  <td>
                    <v-chip class="ma-2 text-uppercase" label outlined small>
                      {{ notifications.vehicles_canceled_count }}
                    </v-chip>
                  </td>
                </tr>
                <tr>
                  <td class="text-subtitle-1">Pedidos de Entrada</td>
                  <td>
                    <v-chip
                      class="ma-2 text-uppercase"
                      label
                      outlined
                      small
                      :color="
                        notifications.entries_pending_count > 0
                          ? 'red'
                          : 'default'
                      "
                    >
                      <v-icon
                        left
                        small
                        v-if="notifications.entries_pending_count > 0"
                      >
                        mdi-alert
                      </v-icon>
                      {{ notifications.entries_pending_count }}
                    </v-chip>
                  </td>
                  <td>
                    <v-chip class="ma-2 text-uppercase" label outlined small>
                      {{ notifications.entries_approved_count }}
                    </v-chip>
                  </td>
                  <td></td>
                  <td>
                    <v-chip class="ma-2 text-uppercase" label outlined small>
                      {{ notifications.entries_rejected_count }}
                    </v-chip>
                  </td>
                  <td>
                    <v-chip class="ma-2 text-uppercase" label outlined small>
                      {{ notifications.entries_canceled_count }}
                    </v-chip>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Loading from "@/components/Main/Loading";
export default {
  components: { Loading },
  data() {
    return {
      notificationsOpen: false,
      notifications: null,
      loading: false,
    };
  },
  computed: {
    pending_count() {
      return this.notifications
        ? this.notifications.operators_pending_count +
            this.notifications.vehicles_pending_count +
            this.notifications.entries_pending_count
        : 0;
    },
  },
  created() {
    this.$root.$on("loadNotifications", this.loadNotifications);
  },
  beforeDestroy() {
    this.$root.$off("loadNotifications", this.loadNotifications);
  },
  mounted() {
    this.loadNotifications();
  },
  methods: {
    openNotifications() {
      this.notificationsOpen = true;
      this.loadNotifications();
    },
    redirectToPage(page) {
      if (this.$route.path !== page) {
        this.$router.push({ path: page });
      }
      this.notificationsOpen = false;
    },
    loadNotifications() {
      this.loading = true;
      this.$store.dispatch("stats/GET_NOTIFICATIONS").then((data) => {
        this.notifications = data;
        this.loading = false;
      });
    },
  },
};
</script>
