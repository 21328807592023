var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"max-width":"800","persistent":"","hide-overlay":"","transition":"dialog-bottom-transition"},model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"primary","flat":""}},[_c('v-toolbar-title',[_c('span',[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-file-document-outline")]),_vm._v(_vm._s(_vm.$t("operator_licenses.list"))+" ")],1)]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.close}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('v-data-table',{attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.items,"item-key":"id","fixed-header":"","must-sort":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDatetime")(item.updated_at))+" ")]}},{key:"item.legal_doc_path",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"disabled":!item.legal_doc_path,"icon":"","small":"","color":"primary"},on:{"click":function($event){return _vm.downloadFile(item.id)}}},'v-btn',attrs,false),on),[(
                      _vm.downloadingFile !== null && _vm.downloadingFile === item.id
                    )?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}}):(!item.legal_doc_path)?_c('v-icon',[_vm._v("mdi-file")]):_c('v-icon',[_vm._v("mdi-file-download-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("global.download")))])])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }