<template>
  <MembersOperator />
</template>

<script>
import MembersOperator from "@/components/Main/MembersOperator";

export default {
  components: {
    MembersOperator,
  },
};
</script>
