<template>
  <v-dialog
    v-model="isOpen"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    persistent
  >
    <v-card tile>
      <v-toolbar flat dense color="primary">
        <v-toolbar-title class="font-weight-bold"
          >{{ $t("plans.title") }}:
          {{ day | formatDateFromDatabase }}</v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-btn icon @click="closePlan">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="ma-0 pa-0 full-height plan-content">
        <Loading
          v-if="loading"
          :value="loading"
          :custom-message="$t('plans.loading_plan_please_wait')"
        ></Loading>
        <div v-else class="full-height">
          <Sectors
            :plan="plan"
            :sector.sync="selectedSector"
            :mode.sync="mapMode"
            @changeSector="handleSectorChange"
          ></Sectors>
          <v-main class="pt-0"
            ><splitpanes class="default-theme full-height" horizontal>
              <pane :size="50">
                <Map
                  :zone.sync="selectedZone"
                  :sector.sync="selectedSector"
                  :entry.sync="selectedEntry"
                  :mode.sync="mapMode"
                  :plan="plan"
                ></Map>
              </pane>
              <pane :size="50" class="proeminent-pane">
                <Entries
                  :plan="plan"
                  :day.sync="day"
                  :selectedRange="selectedRange"
                  :selectedDates="dates"
                  :entry.sync="selectedEntry"
                  :zone="selectedZone"
                  :sector="selectedSector"
                ></Entries>
              </pane>
            </splitpanes>
          </v-main>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { Splitpanes, Pane } from "splitpanes";
import "splitpanes/dist/splitpanes.css";
import Map from "@/components/Main/Scheduling/Map";
import Entries from "@/components/Main/Scheduling/Entries";
import Sectors from "@/components/Main/Scheduling/Sectors";
import Loading from "@/components/Main/Loading";

export default {
  props: ["open", "selected", "range", "selectedDates"],
  components: {
    Splitpanes,
    Pane,
    Map,
    Entries,
    Sectors,
    Loading,
  },
  data() {
    return {
      selectedZone: null,
      selectedSector: null,
      selectedEntry: null,
      mapMode: "VIEW_PLAN",
      plan: null,
      loading: false,
      numberOfEntryRequests: 0,
      numberOfVesselCalls: 0,
      numberOfPassengers: 0,
    };
  },
  computed: {
    isOpen: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("update:open", val);
      },
    },
    selectedRange: {
      get() {
        return this.range;
      },
      set(val) {
        this.$emit("update:range", val);
      },
    },
    dates: {
      get() {
        return this.selectedDates;
      },
      set(val) {
        this.$emit("update:selectedDates", val);
      },
    },
    day: {
      get() {
        return this.selected;
      },
      set(val) {
        this.$emit("update:selected", val);
      },
    },
    sectorsActive() {
      return this.$store.state.shorex.sectorsActive;
    },
  },
  watch: {
    isOpen(val) {
      if (val) {
        this.loadPlan();
      } else {
        this.selectedZone = null;
        this.selectedSector = null;
        this.selectedEntry = null;
      }
    },
  },
  methods: {
    closePlan() {
      this.$emit("closePlan");
      this.isOpen = null;
    },
    loadPlan() {
      this.loading = true;
      this.$store
        .dispatch("plans/GET_BY_DAY", this.day)
        .then((data) => {
          this.plan = data.plan;
          this.numberOfEntryRequests = data.number_of_entry_requests;
          this.numberOfVesselCalls = data.number_of_vessel_calls;
          this.numberOfPassengers = data.number_of_passengers;
          this.loading = false;
        })
        .catch((ex) => {
          console.error(ex);
          this.loading = false;
        });
    },
    handleSectorChange() {
      this.mapMode = "CHANGE_SECTOR";
    },
  },
};
</script>
<style scoped>
.plan-content {
  position: relative;
}
.full-height {
  height: calc(100vh - var(--toolbar-height));
}
</style>
