var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-badge',{attrs:{"color":"red","overlap":"","dot":""}},[_c('v-icon',{on:{"click":function($event){return _vm.openNotifications()}}},[_vm._v(" mdi-bell ")])],1),_c('v-dialog',{attrs:{"width":"80%","persistent":"","scrollable":""},model:{value:(_vm.notificationsOpen),callback:function ($$v) {_vm.notificationsOpen=$$v},expression:"notificationsOpen"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 primary"},[_vm._v(" "+_vm._s(_vm.$t("global.notifications"))+" "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.notificationsOpen = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',{staticClass:"pa-2",staticStyle:{"position":"relative","height":"230px"}},[(_vm.loading)?_c('Loading',{attrs:{"value":_vm.loading,"custom-message":_vm.$t('global.loading')}}):_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left text-subtitle-1"}),_c('th',{staticClass:"text-subtitle-1"},[_vm._v("Pendentes")]),_c('th',{staticClass:"text-subtitle-1"},[_vm._v("Aprovados")]),_c('th',{staticClass:"text-subtitle-1"},[_vm._v("Bloqueados")]),_c('th',{staticClass:"text-subtitle-1"},[_vm._v("Recusados")]),_c('th',{staticClass:"text-subtitle-1"},[_vm._v("Cancelados")])])]),_c('tbody',[_c('tr',[_c('td',{staticClass:"text-subtitle-1"},[_vm._v(" Operadores "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){return _vm.redirectToPage('/operators')}}},'v-icon',attrs,false),on),[_vm._v("mdi-open-in-new")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("global.go_to_page")))])])],1),_c('td',[_c('v-chip',{staticClass:"ma-2 text-uppercase",attrs:{"label":"","outlined":"","small":"","color":_vm.notifications.operators_pending_count > 0
                        ? 'red'
                        : 'default'}},[(_vm.notifications.operators_pending_count > 0)?_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v(" mdi-alert ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.notifications.operators_pending_count)+" ")],1)],1),_c('td',[_c('v-chip',{staticClass:"ma-2 text-uppercase",attrs:{"label":"","outlined":"","small":""}},[_vm._v(" "+_vm._s(_vm.notifications.operators_approved_count)+" ")])],1),_c('td',[_c('v-chip',{staticClass:"ma-2 text-uppercase",attrs:{"label":"","outlined":"","small":""}},[_vm._v(" "+_vm._s(_vm.notifications.operators_blocked_count)+" ")])],1),_c('td',[_c('v-chip',{staticClass:"ma-2 text-uppercase",attrs:{"label":"","outlined":"","small":""}},[_vm._v(" "+_vm._s(_vm.notifications.operators_rejected_count)+" ")])],1),_c('td')]),_c('tr',[_c('td',{staticClass:"text-subtitle-1"},[_vm._v(" Veículos "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){return _vm.redirectToPage('/vehicles')}}},'v-icon',attrs,false),on),[_vm._v("mdi-open-in-new")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("global.go_to_page")))])])],1),_c('td',[_c('v-chip',{staticClass:"ma-2 text-uppercase",attrs:{"label":"","outlined":"","small":"","color":_vm.notifications.vehicles_pending_count > 0
                        ? 'red'
                        : 'default'}},[(_vm.notifications.vehicles_pending_count > 0)?_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v(" mdi-alert ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.notifications.vehicles_pending_count)+" ")],1)],1),_c('td',[_c('v-chip',{staticClass:"ma-2 text-uppercase",attrs:{"label":"","outlined":"","small":""}},[_vm._v(" "+_vm._s(_vm.notifications.vehicles_approved_count)+" ")])],1),_c('td',[_c('v-chip',{staticClass:"ma-2 text-uppercase",attrs:{"label":"","outlined":"","small":""}},[_vm._v(" "+_vm._s(_vm.notifications.vehicles_blocked_count)+" ")])],1),_c('td'),_c('td',[_c('v-chip',{staticClass:"ma-2 text-uppercase",attrs:{"label":"","outlined":"","small":""}},[_vm._v(" "+_vm._s(_vm.notifications.vehicles_canceled_count)+" ")])],1)]),_c('tr',[_c('td',{staticClass:"text-subtitle-1"},[_vm._v("Pedidos de Entrada")]),_c('td',[_c('v-chip',{staticClass:"ma-2 text-uppercase",attrs:{"label":"","outlined":"","small":"","color":_vm.notifications.entries_pending_count > 0
                        ? 'red'
                        : 'default'}},[(_vm.notifications.entries_pending_count > 0)?_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v(" mdi-alert ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.notifications.entries_pending_count)+" ")],1)],1),_c('td',[_c('v-chip',{staticClass:"ma-2 text-uppercase",attrs:{"label":"","outlined":"","small":""}},[_vm._v(" "+_vm._s(_vm.notifications.entries_approved_count)+" ")])],1),_c('td'),_c('td',[_c('v-chip',{staticClass:"ma-2 text-uppercase",attrs:{"label":"","outlined":"","small":""}},[_vm._v(" "+_vm._s(_vm.notifications.entries_rejected_count)+" ")])],1),_c('td',[_c('v-chip',{staticClass:"ma-2 text-uppercase",attrs:{"label":"","outlined":"","small":""}},[_vm._v(" "+_vm._s(_vm.notifications.entries_canceled_count)+" ")])],1)])])]},proxy:true}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }