/**
 * Takes the action function passed and
 * returns a string for the store module
 * action
 * */
function resolveName(namespace, action) {
  if (!action || typeof action !== "function") {
    throw new Error('The "action" parameter must be a function.');
  }

  if (typeof namespace !== "string") {
    throw new Error('The "namespace" parameter must be a string.');
  }

  if (namespace == "") {
    return action.name;
  } else {
    return `${namespace}/${action.name}`;
  }
}

/**
 * Wraper for vuex store dispatch. Takes a action function
 * from the store module and returns the correct stor dispatch
 *
 * This is a helper wraper so that it is possible to jump
 * to the action definitions
 * */
export default {
  install(Vue, store) {
    Vue.prototype.$dDispatch = ({ namespace = "", action, payload = null }) => {
      if (!action) {
        throw new Error('The action is missing');
      }
      const actionName = resolveName(namespace, action);
      return store.dispatch(actionName, payload);
    };
  },
};
