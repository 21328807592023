<template>
  <div>
    <v-data-table
      class="all-operator-table"
      :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'mdi-page-first',
        lastIcon: 'mdi-page-last',
        'items-per-page-options': [5, 10, 25, 50],
      }"
      :loading="loading"
      :search.sync="options.search"
      :page.sync="options.page"
      :headers="headers"
      :items="items"
      :items-per-page.sync="options.itemsPerPage"
      :server-items-length="totalItemCount"
      @update:options="handleOptionsUpdate('options', $event)"
      item-key="id"
      fixed-header
      :sort-by.sync="options.sortBy"
      :sort-desc.sync="options.sortDesc"
      must-sort
    >
      <template v-slot:top>
        <v-text-field
          v-model="options.search"
          class="mb-4"
          single-line
          :label="$t('operators.search')"
          prepend-inner-icon="mdi-magnify"
          outlined
          hide-details
          @input="filterItems"
        >
          <template v-slot:append>
            <FilterMenu
              :menu.sync="menu"
              @filterItems="loadOperators"
              :extra="true"
            >
            </FilterMenu>
          </template>
        </v-text-field>
      </template>
      <template v-slot:item.status="{ item }">
        <v-chip
          v-if="item.status"
          dark
          small
          label
          :color="findStatusColor(item.status)"
          class="text-uppercase"
        >
          {{ $t(`global.${item.status.toLowerCase()}`) }}
        </v-chip>
      </template>
      <template
        v-if="hasUserPermissionToViewEditOrManage('OPERATORS')"
        v-slot:[`item.actions`]="{ item }"
      >
        <v-menu bottom absolute>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item v-if="operator_types.length > 0" @click="edit(item)">
              <v-list-item-icon class="mr-2">
                <v-icon>mdi-pencil</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t("global.edit") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-if="
                hasUserPermissionToManage('OPERATORS') &&
                item.status == 'BLOCKED'
              "
              @click="remove(item)"
            >
              <v-list-item-icon class="mr-2">
                <v-icon>mdi-delete</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t("global.remove") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template v-slot:item.accredited="{ item }">
        <v-icon v-if="item.accredited" color="blue">mdi-check-decagram </v-icon>
      </template>
      <template v-slot:item.updated_at="{ item }">
        {{ item.updated_at | formatDatetime }}
      </template>
      <template v-slot:item.operator_type="{ item }">
        <v-btn icon @click="openOperatorLicensesList(item)">
          <v-icon>mdi-list-box-outline</v-icon>
        </v-btn>
      </template>
      <!-- <template v-slot:item.legal_doc_path="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              :disabled="!item.legal_doc_path"
              class="mx-2"
              icon
              small
              color="primary"
              @click="downloadFile(item.id)"
            >
              <v-progress-circular
                v-if="downloadingFile == item.id"
                indeterminate
                color="primary"
              ></v-progress-circular>
              <v-icon v-else-if="!item.legal_doc_path">mdi-file</v-icon>
              <v-icon v-else>mdi-file-download-outline</v-icon>
            </v-btn></template
          >
          <span>{{ $t("global.download") }}</span>
        </v-tooltip>
      </template> -->
    </v-data-table>
    <v-dialog v-model="errorDialog" width="450">
      <v-card>
        <v-card-text>
          <div class="text-center pt-4">
            <v-icon size="100" color="red" left>mdi-close-circle</v-icon>
          </div>
          <p class="text-center text-h6 ma-0 pt-4" style="color: black">
            {{ $t("global.download_failed") }}
          </p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            block
            depressed
            color="primary"
            @click="errorDialog = false"
            class="black--text"
          >
            {{ $t("global.close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <OperatorLicensesList
      :open.sync="openOperatorLicensesListDialog"
      :item="selectedOperator"
    ></OperatorLicensesList>
  </div>
</template>

<script>
import { debounce } from "debounce";
import FilterMenu from "@/components/Configurations/Operators/FilterMenu.vue";
import configs from "@/helpers/configs";
import OperatorLicensesList from "@/components/Configurations/Operators/OperatorLicensesList.vue";

export default {
  components: {
    FilterMenu,
    OperatorLicensesList,
  },
  data() {
    return {
      openOperatorLicensesListDialog: false,
      selectedOperator: null,
      downloadingFile: null,
      errorDialog: false,
      menu: false,
      headers: [
        {
          text: "",
          value: "actions",
          sortable: false,
          width: "30px",
        },
        {
          text: this.$t("global.vat"),
          value: "vat_number",
        },
        {
          text: this.$t("operators.accredited"),
          value: "accredited",
          width: "30px",
        },
        {
          text: this.$t("operators.name"),
          value: "name",
        },
        {
          text: this.$t("operators.email"),
          value: "email",
        },
        {
          text: this.$t("operators.phone"),
          value: "phone",
        },
        {
          text: this.$t("operators.status"),
          value: "status",
        },
        {
          text: this.$t("operators.operator_type_documents"),
          value: "operator_type",
          sortable: false,
          align: "center",
        },
        {
          text: this.$t("global.created_by"),
          value: "creator_name",
          class: "font-weight-bold",

          sortable: true,
          width: "100px",
        },
        {
          text: this.$t("global.updated_by"),
          value: "updater_name",
          class: "font-weight-bold",

          sortable: true,
          width: "100px",
        },
        {
          text: this.$t("global.updated_at"),
          value: "updated_at",
          class: "font-weight-bold",

          sortable: true,
          width: "150px",
        },
      ],
    };
  },
  computed: {
    loading() {
      return this.$store.state.operators.loadingPaginated;
    },
    items() {
      return this.$store.state.operators.allPaginated;
    },
    options: {
      cache: false,
      get() {
        return this.$store.state.operators.options;
      },
      set(val) {
        this.$store.dispatch("operators/UPDATE_OPTIONS", val);
      },
    },
    operator_types() {
      return this.$store.state.operator_types.all;
    },
    totalItemCount() {
      return this.$store.state.operators.total;
    },
    operatorsStatuses() {
      return configs.getOperatorStatuses();
    },
  },
  mounted() {
    this.$store.dispatch("operators/RESET");
  },
  methods: {
    openOperatorLicensesList(item) {
      this.selectedOperator = item;
      this.openOperatorLicensesListDialog = true;
    },
    loadOperators() {
      this.$store.dispatch("operators/GET_ALL_PAGINATED").catch((ex) => {
        console.error("Error loading operators", ex);
      });
    },

    filterItems: debounce(function () {
      if (this.options.page == 1) {
        this.loadOperators();
      } else {
        this.options.page = 1;
      }
    }, 500),

    handleOptionsUpdate: debounce(function () {
      this.loadOperators();
    }, 500),

    edit(item) {
      this.$emit("edit", item);
    },
    remove(item) {
      this.$emit("remove", item);
    },
    findStatusColor(status) {
      let st = this.operatorsStatuses.find((item) => {
        return item.code == status;
      });
      // Default color is grey
      return st?.color || "grey";
    },
    downloadFile(id) {
      this.downloadingFile = id;
      this.$store
        .dispatch("operators/DOWNLOAD_FILE", id)
        .then(() => {
          this.downloadingFile = null;
        })
        .catch((err) => {
          this.downloadingFile = null;
          this.errorDialog = true;
          console.log(err);
        });
    },
  },
};
</script>

<style>
.all-operator-table .v-data-table__wrapper {
  max-height: calc(100vh - 290px);
}
</style>
