<template>
  <div class="ma-4">
    <div class="d-flex flex-wrap">
      <div class="ma-0 pa-0">
        <p class="text-h5 font-weight-bold mb-0">
          {{ $t("global.statistics_data") }}
        </p>
        <p class="text-caption">
          {{ dateRangeText }}
        </p>
      </div>
      <v-spacer></v-spacer>
      <div style="width: 300px">
        <v-menu
          v-model="dateMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          @input="onMenuToggle"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateRangeText"
              readonly
              label="Filtrar datas"
              outlined
              hide-details
              color="deep-purple accent-4"
              :disabled="loading"
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <vc-date-picker
            v-model="dates"
            mode="date"
            timezone="utc"
            is24hr
            is-range
            is-expanded
            :model-config="modelConfig"
            :max-date="new Date()"
          >
          </vc-date-picker>
        </v-menu>
      </div>
    </div>
    <Loading
      v-if="loading"
      :value="loading"
      :custom-message="$t('stats.loading_stats_please_wait')"
    ></Loading>
    <div v-if="hasStats">
      <v-layout row wrap class="ma-1 mb-5">
        <v-row>
          <v-col v-for="(item, index) in cardItems" :key="index"
            ><StatsCard :item="item" /> </v-col
        ></v-row>
      </v-layout>
      <v-layout row wrap class="ma-1 mb-5">
        <v-row>
          <v-col
            v-for="(item, index) in barItems"
            :key="index"
            cols="12"
            sm="12"
            md="4"
            lg="4"
            ><StatsBarChart :key="compKey" :data="item" /> </v-col
        ></v-row>
      </v-layout>
      <v-layout row wrap class="ma-1 mb-5">
        <v-row>
          <v-col
            v-for="(data, index) in hoursItems"
            :key="index"
            cols="12"
            sm="12"
            md="12"
            lg="12"
            ><StatsBarTimeChart :key="compKey" :data="data" /> </v-col
        ></v-row>
      </v-layout>
    </div>
    <div v-else class="ma-10">
      <p class="text-h6 font-weight-500 text-center">
        {{ $t("stats.no_stats_found") }}
      </p>
    </div>
  </div>
</template>

<script>
import Loading from "@/components/Main/Loading";
import StatsBarChart from "@/components/Main/Stats/StatsBarChart.vue";
import StatsBarTimeChart from "@/components/Main/Stats/StatsBarTimeChart.vue";
import StatsCard from "@/components/Main/Stats/StatsCard.vue";
import { DateTime } from "luxon";
import { debounce } from "debounce";

export default {
  props: ["start", "end"],
  components: { Loading, StatsCard, StatsBarChart, StatsBarTimeChart },
  data() {
    return {
      loading: false,
      cardItems: [],
      barItems: [],
      hoursItems: [],
      hasStats: false,
      dateMenu: false,
      dates: [],
      modelConfig: {
        type: "string",
        mask: "iso",
      },
    };
  },
  mounted() {
    this.$nextTick(() => {
      if (this.start && this.end) {
        this.dates = {
          start: new Date(this.start).toISOString(),
          end: new Date(this.end).toISOString(),
        };
      }
    });
    this.loadStats();
  },
  watch: {
    start: "loadStats",
    end: "loadStats",
  },

  computed: {
    dateRangeText() {
      if (this.start && this.end) {
        let start = this.$options.filters.formatDate(this.start);
        let end = this.$options.filters.formatDate(this.end);

        return start + " - " + end;
      }

      return "";
    },
  },

  methods: {
    redirectToDefault() {
      let defaultStart = DateTime.now()
        .minus({ days: 7 })
        .toFormat("yyyy-LL-dd");
      let defaultEnd = DateTime.now().toFormat("yyyy-LL-dd");
      this.$router.push({
        path: `/stats?start=${defaultStart}&end=${defaultEnd}`,
      });
    },
    isValidDate(date) {
      return DateTime.fromFormat(date, "yyyy-LL-dd").isValid;
    },
    onMenuToggle(opened) {
      if (opened || this.dates.length === 0) return;

      let start = this.dates.start.split("T")[0];
      let end = this.dates.end.split("T")[0];

      if (start !== this.$route.query.start || end !== this.$route.query.end) {
        this.$router.push({
          path: "/stats?start=" + start + "&end=" + end,
        });
      }
    },
    loadStats: debounce(function () {
      if (this.isValidDate(this.start) && this.isValidDate(this.end)) {
        this.loading = true;
        this.$store
          .dispatch("stats/GET_STATS", {
            start: this.start,
            end: this.end,
          })
          .then((data) => {
            this.hoursItems = [
              {
                title: "Acessos SHOREX",
                entryHours: data.entryHours,
                exitHours: data.exitHours,
              },
            ];

            this.barItems = [
              {
                title: this.$t("stats.entry_requests"),
                subtitle: "Todos os tipos",
                chartData: [
                  {
                    title: this.$t("stats.total_approved"),
                    value: data.request.total_approved,
                    color: "#4CAF50",
                  },
                  {
                    title: this.$t("stats.total_rejected"),
                    value: data.request.total_rejected,
                    color: "#F44336",
                  },
                  {
                    title: this.$t("stats.total_canceled"),
                    value: data.request.total_canceled,
                    color: "#fb8c00",
                  },
                  {
                    title: this.$t("stats.total_pending"),
                    value: data.request.total_pending,
                    color: "#90a4ae",
                  },
                ],
              },
              {
                title: this.$t("stats.shorex_types"),
                subtitle: "Pedidos aprovados",
                chartData: [...data.shorexTypes],
              },
              {
                title: this.$t("stats.vehicle_types"),
                subtitle: "Pedidos aprovados",
                chartData: [...data.vehicleTypes],
              },
            ];

            this.cardItems = [
              {
                title: this.$t("stats.total_effective_cruises"),
                value: data.vesselCalls.total_cruises ?? "N/D",
                icon: "mdi-file-document-outline",
                color: "#e2c73466",
              },
              {
                title: this.$t("stats.total_requests"),
                value: data.request.total_requests ?? "N/D",
                icon: "mdi-file-document-outline",
                color: "#e2c73466",
              },
              {
                title: this.$t("stats.total_effective_entries"),
                value: data.scanned.total_effective_entries ?? "N/D",
                icon: "mdi-arrow-collapse-right",
                color: "#80006866",
              },
              {
                title: this.$t("stats.total_effective_exits"),
                value: data.scanned.total_effective_exits ?? "N/D",
                icon: "mdi-arrow-collapse-right",
                color: "#80006866",
              },
              {
                title: this.$t("stats.total_number_of_passengers"),
                value: data.request.total_number_of_passengers ?? "N/D",
                icon: "mdi-account-group-outline",
                color: "#0980008f",
              },
              {
                title: this.$t("stats.total_approved_number_of_passengers"),
                value:
                  data.request.total_approved_number_of_passengers ?? "N/D",
                icon: "mdi-account-multiple-check",
                color: "#2200808f",
              },
              {
                title: this.$t("stats.total_effective_vehicles_shorex"),
                value: data.scanned.total_effective_vehicles ?? "N/D",
                icon: "mdi-account-arrow-right",
                color: "#80003157",
              },
              {
                title: this.$t("stats.total_effective_operators_avt"),
                value: data.request.total_effective_operators_avt ?? "N/D",
                icon: "mdi-account-arrow-right",
                color: "#80003157",
              },
              {
                title: this.$t("stats.total_effective_operators_aat"),
                value: data.request.total_effective_operators_aat ?? "N/D",
                icon: "mdi-account-arrow-right",
                color: "#80003157",
              },
              {
                title: this.$t("stats.total_effective_operators_taxis"),
                value: data.request.total_effective_operators_taxis ?? "N/D",
                icon: "mdi-account-arrow-right",
                color: "#80003157",
              },
            ];

            this.hasStats = data.request.total_requests > 0;
            this.loading = false;
            this.compKey++;
          });
      } else {
        this.redirectToDefault();
      }
    }, 300),
  },
};
</script>

<style scoped>
.stats-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
</style>
