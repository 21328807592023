<template>
  <div class="ma-4">
    <div class="d-flex flex-wrap">
      <div class="ma-0 pa-0">
        <p class="text-h5 font-weight-black mb-0">
          {{ $t("configurations.users") }}
        </p>
        <p class="text-caption">
          {{ $t("configurations.users_explanation") }}
        </p>
      </div>
      <v-spacer></v-spacer>

      <div v-if="hasUserPermissionToViewEditOrManage('USERS')" class="ml-auto">
        <div v-if="$vuetify.breakpoint.name == 'xs'">
          <div class="text-center">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="black--text"
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  depressed
                >
                  <v-icon> mdi-dots-vertical </v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="add">
                  <v-list-item-avatar class="mr-2">
                    <v-icon left>mdi-plus-box-multiple</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-title>
                    {{ $t("users.new_user") }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click="importLDAPUsers">
                  <v-list-item-avatar class="mr-2">
                    <v-icon left>mdi-database-import</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-title>
                    {{ $t("users.import_ldap_users") }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click="openLDAPConfigurations">
                  <v-list-item-avatar class="mr-2">
                    <v-icon left>mdi-server-security</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-title>
                    {{ $t("users.ldap_configuration") }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
        <div v-else>
          <v-btn class="black--text" color="primary" depressed @click="add">
            <v-icon left>mdi-plus-box-multiple</v-icon>
            {{ $t("users.new_user") }}
          </v-btn>
          <v-btn
            class="ml-1 black--text"
            color="primary"
            @click="importLDAPUsers"
            depressed
          >
            <v-icon left>mdi-database-import</v-icon>
            {{ $t("users.import_ldap_users") }}
          </v-btn>
          <v-btn
            class="ml-1 black--text"
            color="primary"
            @click="openLDAPConfigurations"
            depressed
          >
            <v-icon left>mdi-server-security</v-icon>
            {{ $t("users.ldap_configuration") }}
          </v-btn>
        </div>
      </div>
    </div>
    <List
      @permissions="permissions"
      @remove="remove"
      @edit="edit"
      :key="componentKey"
    />
    <PermissionsDialog
      :open.sync="openPermissionsDialog"
      :item="selectedUser"
    />
    <CreateUserDialog :open.sync="openAddUserDialog" @userCreated="permissions" />
    <EditUserDialog :open.sync="openEditUserDialog" :item="selectedUser" />
    <DeleteUserDialog :open.sync="openDeleteUserDialog" :item="selectedUser" />
    <LDAPConfigurationsDialog :open.sync="openLDAPConfigurationsDialog" />
    <ImportLDAPUsersDialog :open.sync="openImportLDAPUsersDialog" />
  </div>
</template>

<script>
import List from "./Users/List";
import PermissionsDialog from "./Users/PermissionsDialog";
import CreateUserDialog from "./Users/CreateUserDialog";
import EditUserDialog from "./Users/EditUserDialog";
import DeleteUserDialog from "./Users/DeleteUserDialog";
import LDAPConfigurationsDialog from "./Users/LDAPConfigurationsDialog";
import ImportLDAPUsersDialog from "./Users/ImportLDAPUsersDialog";
export default {
  components: {
    List,
    CreateUserDialog,
    EditUserDialog,
    DeleteUserDialog,
    PermissionsDialog,
    LDAPConfigurationsDialog,
    ImportLDAPUsersDialog,
  },
  data() {
    return {
      componentKey: 0,
      openPermissionsDialog: false,
      openAddUserDialog: false,
      openEditUserDialog: false,
      openDeleteUserDialog: false,
      openLDAPConfigurationsDialog: false,
      openImportLDAPUsersDialog: false,
      selectedUser: null,
    };
  },
  watch: {
    openAddUserDialog(val) {
      if (val == false) this.forceRerender();
    },
    openEditUserDialog(val) {
      if (val == false) this.forceRerender();
    },
    openDeleteUserDialog(val) {
      if (val == false) this.forceRerender();
    },
  },
  methods: {
    add() {
      this.openAddUserDialog = true;
    },
    permissions(user) {
      this.selectedUser = user;
      this.openPermissionsDialog = true;
    },
    edit(user) {
      this.selectedUser = user;
      this.openEditUserDialog = true;
    },
    remove(user) {
      this.selectedUser = user;
      this.openDeleteUserDialog = true;
    },
    importLDAPUsers() {
      this.openImportLDAPUsersDialog = true;
    },
    openLDAPConfigurations() {
      this.openLDAPConfigurationsDialog = true;
    },
    forceRerender() {
      this.componentKey += 1;
    },
  },
};
</script>
