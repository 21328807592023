<template>
  <v-dialog
    v-model="isOpen"
    persistent
    scrollable
    width="40vw"
    :fullscreen="$vuetify.breakpoint.xs ? true : false"
  >
    <v-card tile>
      <v-expand-transition>
        <div v-show="expand">
          <v-alert class="mb-0" tile type="error" @click="expand = false">
            <div
              v-for="(message, index) in this.messages"
              :key="index"
              class="subtitle"
            >
              {{ message }}
            </div>
          </v-alert>
        </div>
      </v-expand-transition>
      <v-progress-linear
        height="10"
        striped
        color="primary"
        indeterminate
        v-show="loading"
      ></v-progress-linear>
      <v-toolbar color="primary" flat>
        <v-toolbar-title>
          <span>
            <v-icon left> mdi-upload</v-icon>{{ $t("zones.import") }}
          </span>
        </v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="mt-5">
        <v-alert color="blue-grey" text icon="mdi-information-outline">
          {{ $t("zones.import_zones_alert") }}
        </v-alert>
        <v-alert color="#1DB576" text icon="mdi-download-circle-outline">
          <div v-html="$t('zones.import_zones_help')"></div>
        </v-alert>
        <v-alert color="orange darken-2" text icon="mdi-alert-decagram-outline">
          {{ $t("zones.import_zones_warning") }}
        </v-alert>
        <v-alert v-if="replace" color="orange" text icon="mdi-alert-outline">
          <span v-html="$t('zones.import_substitute_zones_warning')" />
        </v-alert>
        <v-form ref="form" class="mt-5">
          <v-file-input
            v-model="file"
            show-size
            accept=".geojson"
            outlined
            prepend-icon
            prepend-inner-icon="mdi-paperclip"
            :error-messages="fileUploadErrorMessage"
            :rules="fileRules"
            persistent-hint
            :label="$t('global.geojson_file')"
          ></v-file-input>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-checkbox
          v-model="replace"
          :label="$t('global.replace_data')"
        ></v-checkbox>
        <v-spacer />
        <v-btn
          class="black--text"
          color="primary"
          depressed
          :disabled="loading"
          @click="save"
        >
          {{ $t("global.import") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["open"],
  data() {
    return {
      loading: false,
      expand: false,
      messages: [],
      fileRules: [(v) => !!v || this.$t("rules.file_required")],
      file: null,
      fileUploadErrorMessage: "",
      replace: false,
    };
  },
  computed: {
    isOpen: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("update:open", val);
      },
    },
  },
  mounted() {
    this.replace = false;
    if (this.$refs.form) this.$refs.form.reset();
  },
  methods: {
    close() {
      this.isOpen = false;
      this.expand = false;
      this.replace = false;
      this.loading = false;
      this.$refs.form.reset();
    },
    save() {
      this.expand = false;
      this.loading = true;
      this.messages = [];
      if (this.$refs.form.validate()) {
        this.$store
          .dispatch("zones/IMPORT", { file: this.file, replace: this.replace })
          .then((res) => {
            if (res.errors) {
              Object.entries(res.errors).forEach(([, value]) => {
                this.messages = this.messages.concat(value);
              });
              this.expand = true;
              this.loading = false;
            } else {
              this.$refs.form.reset();
              this.close();
            }
          })
          .catch(() => {
            this.messages = [this.$t("global.file_import_error")];
            this.expand = true;
            this.loading = false;
          });
      } else {
        this.expand = false;
        this.loading = false;
      }
    },
  },
};
</script>
