<template>
  <v-dialog v-model="isOpen" persistent width="600">
    <v-card tile>
      <v-expand-transition>
        <div v-show="expand">
          <v-alert class="mb-0" tile type="error" @click="expand = false">
            <div
              v-for="(message, index) in this.messages"
              :key="index"
              class="subtitle"
            >
              {{ message }}
            </div>
          </v-alert>
        </div>
      </v-expand-transition>
      <v-toolbar color="primary" flat>
        <v-toolbar-title>
          <span>
            <v-icon left>mdi-pencil</v-icon>{{ $t("vehicle_types.edit") }}
          </span>
        </v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="overflow-y-auto pb-0 mt-5 form-content">
        <Loading
          v-if="loading"
          :value="loading"
          :custom-message="$t('global.loading')"
        ></Loading>
        <div v-else>
          <Loading
            v-if="saving"
            :value="saving"
            :custom-message="$t('global.updating_please_wait')"
          ></Loading>
          <v-form ref="form" class="mt-2">
            <div v-if="!!itemToEdit">
              <v-text-field
                counter="50"
                maxlength="50"
                autocomplete="off"
                required
                v-model="itemToEdit.name"
                :label="$t('vehicle_types.name')"
                :placeholder="$t('vehicle_types.name')"
                :rules="nameRules"
                prepend-inner-icon="mdi-text"
                outlined
              ></v-text-field>

              <v-text-field
                counter="50"
                maxlength="50"
                autocomplete="off"
                required
                v-model="itemToEdit.code"
                :label="$t('vehicle_types.code')"
                :placeholder="$t('vehicle_types.code')"
                :rules="codeRules"
                prepend-inner-icon="mdi-code-brackets"
                outlined
              ></v-text-field>

              <v-combobox
                v-model="itemToEdit.icon"
                required
                :label="$t('vehicle_types.icon')"
                :placeholder="$t('vehicle_types.icon')"
                :menu-props="{ bottom: true, offsetY: true }"
                item-value="icon"
                :items="availableIcons"
                autocomplete
                clearable
                outlined
              >
                <template v-slot:item="{ item }">
                  <v-icon large class="pr-2"> {{ item }} </v-icon> {{ item }}
                </template>
                <template v-slot:selection="{ item }">
                  <v-icon large class="pr-2"> {{ item }} </v-icon> {{ item }}
                </template></v-combobox
              >
            </div>
          </v-form>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer />
        <v-btn
          class="black--text"
          color="primary"
          depressed
          :disabled="loading || saving"
          @click="save"
        >
          {{ $t("global.update") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Loading from "@/components/Main/Loading";

export default {
  props: ["open", "item"],
  components: { Loading },
  data() {
    return {
      loading: false,
      saving: false,
      expand: false,
      messages: [],
      itemToEdit: null,
      nameRules: [
        (v) => !!v || this.$t("rules.required_field"),
        (v) => (v && v.length <= 255) || this.$t("rules.character_limit"),
      ],
      codeRules: [
        (v) => !!v || this.$t("rules.required_field"),
        (v) => (v && v.length <= 255) || this.$t("rules.character_limit"),
      ],
      availableIcons: [
        "mdi-car-convertible",
        "mdi-car",
        "mdi-car-estate",
        "mdi-car-hatchback",
        "mdi-car-lifted-pickup",
        "mdi-car-limousine",
        "mdi-car-pickup",
        "mdi-taxi",
        "mdi-bus",
        "mdi-bicycle-cargo",
        "mdi-account-tie-hat",
        " mdi-bicycle",
        "mdi-scooter",
        "mdi-van-passenger",
      ],
    };
  },
  computed: {
    isOpen: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("update:open", val);
      },
    },
  },
  watch: {
    open(val) {
      if (val) {
        this.$nextTick(() => {
          this.$refs.form.resetValidation();
          this.loadVehicleTypeDetails();
        });
      }
    },
  },
  methods: {
    loadVehicleTypeDetails() {
      if (this.item.id) {
        this.loading = true;
        this.itemToEdit = null;
        this.$store
          .dispatch("vehicle_types/GET_VEHICLE_TYPE_BY_ID", this.item.id)
          .then((data) => {
            this.loading = false;
            this.itemToEdit = data;
          })
          .catch((e) => {
            console.error(e);
          });
      }
    },
    close() {
      this.isOpen = false;
      setTimeout(() => {
        this.expand = false;
        this.loading = false;
        this.saving = false;
      }, 600);
    },
    save() {
      this.expand = false;
      this.saving = true;
      this.messages = [];
      if (this.$refs.form.validate()) {
        this.$store
          .dispatch("vehicle_types/UPDATE", this.itemToEdit)
          .then((res) => {
            if (res.errors) {
              Object.entries(res.errors).forEach(([, value]) => {
                this.messages = this.messages.concat(value);
              });
              this.expand = true;
              this.saving = false;
            } else {
              this.close();
            }
          });
      } else {
        this.expand = false;
        this.saving = false;
      }
    },
  },
};
</script>
<style scoped>
.form-content {
  position: relative;
  min-height: calc(30vh - var(--toolbar-height));
}
</style>
