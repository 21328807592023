import { shorexService } from "@/api_services/shorex.service";

export default {
  namespaced: true,
  state: {
    scheduling: [],
    loading: false,
    passengers: [],
    matchingPassengers: false,
    sectorsActive: false,
  },
  getters: {},
  mutations: {
    setScheduling(state, scheduling) {
      state.scheduling = scheduling.map((item) => {
        let mappedItem = {
          ...item,
          authorizing: false,
          unauthorizing: false,
        };
        return mappedItem;
      });
    },
    setSchedulingLoading(state, isLoading) {
      state.loading = isLoading;
    },
    setPassengers(state, passengers) {
      state.passengers = passengers;
    },
    setMatchingPassengers(state, isMatchingPassengers) {
      state.matchingPassengers = isMatchingPassengers;
    },
    setSectorsActive(state, active) {
      state.sectorsActive = active;
    },
  },
  actions: {
    GET_SCHEDULING: (context, date) => {
      return new Promise((resolve, reject) => {
        context.commit("setSchedulingLoading", true);
        context.commit("setScheduling", []);
        shorexService
          .getScheduling(date)
          .then((res) => {
            context.commit("setScheduling", res.data);
            context.commit("setSchedulingLoading", false);
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            context.commit("setSchedulingLoading", false);
            reject(e);
          });
      });
    },
    GET_MATCH: (context, payload) => {
      return new Promise((resolve, reject) => {
        context.commit("setMatchingPassengers", true);
        shorexService
          .getMatching(payload.vessel_call_id, payload.passengers)
          .then((res) => {
            context.commit("setPassengers", res.data);
            context.commit("setMatchingPassengers", false);
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            context.commit("setMatchingPassengers", false);
            reject(e);
          });
      });
    },
    SET_SCHEDULING: (context, payload) => {
      return new Promise((resolve, reject) => {
        shorexService
          .setScheduling(payload.day, payload.entries)
          .then((res) => {
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },
    EMIT_AUTHORIZATION: (_, payload) => {
      return new Promise((resolve, reject) => {
        shorexService
          .emitAuthorization(payload.day, payload.entry, payload.image)
          .then((res) => {
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    EMIT_MULTIPLE_AUTHORIZATIONS: (_, payload) => {
      return new Promise((resolve, reject) => {
        shorexService
          .emitMultipleAuthorizations(payload.day, payload.entries)
          .then((res) => {
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    EMIT_UNAUTHORIZATION: (_, payload) => {
      return new Promise((resolve, reject) => {
        shorexService
          .emitUnauthorization(payload)
          .then((res) => {
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    EMIT_MULTIPLE_UNAUTHORIZATIONS: (_, entries) => {
      return new Promise((resolve, reject) => {
        shorexService
          .emitMultipleUnauthorizations(entries)
          .then((res) => {
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    REVERT_UNAUTHORIZATION: (_, entryRequestId) => {
      return new Promise((resolve, reject) => {
        shorexService
          .revertUnauthorization(entryRequestId)
          .then((res) => {
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    TOGGLE_SECTORS: (context) => {
      context.commit("setSectorsActive", !context.state.sectorsActive);
    },

    SET_SECTORS_ACTIVE: (context, val) => {
      context.commit("setSectorsActive", val);
    },

    RESET_PLAN(context) {
      context.commit("setSectorsActive", false);
    },

    CREATE_ENTRY_REQUEST: (_, payload) => {
      return new Promise((resolve, reject) => {
        shorexService
          .createEntryRequest(payload)
          .then((res) => {
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    IMPORT_ENTRY_REQUESTS: (_, payload) => {
      return new Promise((resolve, reject) => {
        shorexService
          .importEntryRequests(payload)
          .then((res) => {
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },
  },
};
