import { vehiclesService } from "@/api_services/vehicles.service";

export default {
  namespaced: true,
  state: {
    all: [],
    loading: false,
  },
  mutations: {
    setVehicles(state, items) {
      state.all = items;
    },
    setLoading(state, isLoading) {
      state.loading = isLoading;
    },
  },
  actions: {
    GET_USER_VEHICLE_BY_ID: (_, id) => {
      return new Promise((resolve, reject) => {
        vehiclesService
          .getUserVehicle(id)
          .then((res) => {
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },
    CREATE_USER_VEHICLE: (context, vehicle) => {
      return new Promise((resolve, reject) => {
        vehiclesService
          .createUserVehicle(vehicle)
          .then((res) => {
            resolve(res.data);
            context.dispatch("GET_USER_VEHICLES");
          })
          .catch((error) => {
            console.error(error);
            reject(error);
          });
      });
    },
    IMPORT_USER_VEHICLES: (context, payload) => {
      return new Promise((resolve, reject) => {
        vehiclesService
          .importUserVehicles(payload)
          .then((res) => {
            resolve(res.data);
            context.dispatch("GET_USER_VEHICLES");
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },
    UPDATE_USER_VEHICLE: (context, vehicle) => {
      return new Promise((resolve, reject) => {
        vehiclesService
          .updateUserVehicle(vehicle)
          .then((res) => {
            resolve(res.data);
            context.dispatch("GET_USER_VEHICLES");
            context.dispatch("GET_USER_VEHICLE_BY_ID", vehicle.id);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },
    EXPORT_USER_VEHICLES: (_) => {
      return new Promise((resolve, reject) => {
        vehiclesService
          .exportUserVehicles()
          .then((res) => {
            var fileURL = window.URL.createObjectURL(new Blob([res.data]));
            var fileLink = document.createElement("a");

            fileLink.href = fileURL;
            fileLink.setAttribute("download", "[3SHOREX]LISTA_DE_VEICULOS.xlsx");
            document.body.appendChild(fileLink);

            fileLink.click();

            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },
    DOWNLOAD_USER_VEHICLES_TEMPLATE: (_) => {
      return new Promise((resolve, reject) => {
        vehiclesService
          .downloadUserVehiclesTemplate()
          .then((res) => {
            var fileURL = window.URL.createObjectURL(new Blob([res.data]));
            var fileLink = document.createElement("a");

            fileLink.href = fileURL;
            fileLink.setAttribute("download", "[3SHOREX]LISTA_DE_VEICULOS.xlsx");
            document.body.appendChild(fileLink);

            fileLink.click();

            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },
    GET_APPROVED_USER_VEHICLES(context) {
      return new Promise((resolve, reject) => {
        vehiclesService
          .getUserVehicles("APPROVED")
          .then((res) => {
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },
    GET_USER_VEHICLES(context) {
      return new Promise((resolve, reject) => {
        context.commit("setLoading", true);
        context.commit("setVehicles", []);
        vehiclesService
          .getUserVehicles()
          .then((res) => {
            context.commit("setLoading", false);
            context.commit("setVehicles", res.data);
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            context.commit("setLoading", false);
            reject(e);
          });
      });
    },
    CANCEL(context, vehicleId) {
      return new Promise((resolve, reject) => {
        context.commit("setLoading", true);
        vehiclesService
          .cancelVehicle(vehicleId)
          .then((res) => {
            context.commit("setLoading", false);
            resolve(res.data);
          })
          .catch((e) => {
            context.commit("setLoading", false);
            console.error(e);
            reject(e);
          });
      });
    },
  },
};
