<template>
  <v-bottom-navigation
    :value="currentRoute"
    background-color="primary"
    active-class="active-btn"
    fixed
    app
  >
    
  <v-btn value="home" to="/map">
      <span>{{ $t("global.home") }}</span>
      <v-icon>mdi-home-circle-outline</v-icon>
    </v-btn>
    <v-btn
      value="entry_requests"
      to="/entry_requests"
      v-if="isShorexUser && userOperator"
    >
      <span>{{ $t("global.requests") }}</span>
      <v-icon>mdi-boom-gate-arrow-up-outline</v-icon>
    </v-btn>
    <v-btn
      value="Vehicles"
      to="/user_vehicles"
      v-if="isShorexUser && userOperator"
    >
      <span>{{ $t("global.vehicles") }}</span>
      <v-icon>mdi-car-multiple</v-icon>
    </v-btn>
    <v-btn
      value="Team"
      to="/members"
      v-if="isShorexUser && userOperator"
    >
      <span>{{ $t("global.team") }}</span>
      <v-icon>mdi-account-group</v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>

<script>
import configs from "@/helpers/configs";
export default {
  computed: {
    currentRoute() {
      return this.$route.name;
    },
    userAccount() {
      return this.$store.state.user.account;
    },
    userType() {
      return this.userAccount && this.userAccount.user
        ? this.userAccount.user.type
        : "";
    },
    userOperator() {
      return this.userAccount && this.userAccount.user
        ? this.userAccount.user.operator
        : null;
    },
    isShorexUser() {
      return this.userType == configs.getShorexUserType();
    },
  },
};
</script>
<style>
.active-btn {
  font-weight: bolder;
}
</style>
