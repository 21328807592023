import configs from "@/helpers/configs";
import axios from "axios";
import { sharedHelpers } from "@/api_services/utils/shared-helpers";

export const registeredUserService = {
  getRegisteredUsers,
  createRegisteredUser,
  updateRegisteredUser,
  deleteRegisteredUser,
  importUsers,
  exportUsers,
  getUser,
};

async function getRegisteredUsers(options) {
  let sort = options.sortDesc ? "DESC" : "ASC";
  const requestOptions = {
    method: "GET",
    headers: sharedHelpers.getRequestHeaders(),
    params: {
      itemsPerPage: options.itemsPerPage,
      currentPage: options.page,
      sortBy: options.sortBy,
      sort: sort,
      search: options.search,
      filterByStatus: options.filterByStatus,
    },
  };
  return await axios.get(
    configs.getApiUrl() + "/v1/registered_users",
    requestOptions
  );
}

async function createRegisteredUser(registered_user) {
  const requestOptions = {
    method: "POST",
    headers: sharedHelpers.getRequestHeaders()
  };
  return await axios.post(
    configs.getApiUrl() + "/v1/registered_users",
    registered_user,
    requestOptions
  );
}

async function updateRegisteredUser(registered_user) {
  const requestOptions = {
    method: "PUT",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.put(
    configs.getApiUrl() + "/v1/registered_users/" + registered_user.id,
    registered_user,
    requestOptions
  );
}

async function deleteRegisteredUser(registered_userId) {
  const requestOptions = {
    method: "POST",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.delete(
    configs.getApiUrl() + "/v1/registered_users/" + registered_userId,
    requestOptions
  );
}

async function importUsers(file) {
  const requestOptions = {
    method: "POST",
    headers: sharedHelpers.getRequestHeaders()
  };
  var bodyFormData = new FormData();
  bodyFormData.append("file", file);
  return await axios.post(
    configs.getApiUrl() + "/v1/registered_users/import",
    bodyFormData,
    requestOptions
  );
}

async function exportUsers() {

  const requestOptions = {
    method: "POST",
    responseType: "blob",
    headers: sharedHelpers.getRequestHeaders()
  };
  return await axios.post(
    configs.getApiUrl() + "/v1/registered_users/export",
    {},
    requestOptions
  );
}

async function getUser(userId) {
  const requestOptions = {
    method: "GET",
    headers: sharedHelpers.getRequestHeaders()
  };
  return await axios.get(
    configs.getApiUrl() + "/v1/registered_users/" + userId,
    requestOptions
  );
}
