import { scheduledEntriesService } from "@/api_services/scheduled_entries.service";

const DEFAULT_STATE = {
  all: [],
  loading: false,
  allPaginated: [],
  loadingPaginated: false,
  options: {
    search: "",
    page: 1,
    itemsPerPage: 12,
    sortBy: "vehicle_at_pier_start",
    sortDesc: false,
    filterByStatus: null,
  },
  total: 0,
};

export default {
  namespaced: true,
  state: Object.assign({}, JSON.parse(JSON.stringify(DEFAULT_STATE))),
  getters: {},
  mutations: {
    setOptions(state, options) {
      state.options = options;
    },
    setTotal(state, total) {
      state.total = total;
    },
    setScheduledEntries(state, all) {
      state.all = all;
    },
    setLoading(state, isLoading) {
      state.loading = isLoading;
    },
    setPaginated(state, all) {
      state.allPaginated = all;
    },
    setLoadingPaginated(state, isLoading) {
      state.loadingPaginated = isLoading;
    },
    setDefaultState(state) {
      Object.assign(state, JSON.parse(JSON.stringify(DEFAULT_STATE)));
    },
  },
  actions: {
    UPDATE_OPTIONS(context, options) {
      context.commit("setOptions", options);
    },
    GET_BY_ID: (_, id) => {
      return new Promise((resolve, reject) => {
        scheduledEntriesService
          .getScheduledEntry(id)
          .then((res) => {
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    GET_BY_TOKEN: (_, token) => {
      return new Promise((resolve, reject) => {
        scheduledEntriesService
          .getScheduledEntryByToken(token)
          .then((res) => {
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    CANCEL: (_, id) => {
      return new Promise((resolve, reject) => {
        scheduledEntriesService
          .cancelScheduledEntry(id)
          .then((res) => {
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    }, 
    
    GET_ALL_PAGINATED: (context, options) => {
      return new Promise((resolve, reject) => {
        context.commit("setLoadingPaginated", true);
        context.commit("setPaginated", []);
        if (options) {
          context.commit("setOptions", options);
        }
        scheduledEntriesService
          .getScheduledEntriesPaginated(context.state.options)
          .then((res) => {
            context.commit("setPaginated", res.data.data);
            context.commit("setTotal", res.data.total);
            context.commit("setLoadingPaginated", false);
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            context.commit("setLoadingPaginated", false);
            reject(e);
          });
      });
    },

    REGISTER_ENTRY_SCAN: (_, payload) => {
      return new Promise((resolve, reject) => {
        scheduledEntriesService
          .registerEntryScan(payload)
          .then((res) => {
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    REGISTER_EXIT_SCAN: (_, payload) => {
      return new Promise((resolve, reject) => {
        scheduledEntriesService
          .registerExitScan(payload)
          .then((res) => {
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    RESET: (context) => {
      context.commit("setDefaultState");
    },
  },
};
