var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"ma-0 pa-5",staticStyle:{"max-width":"100%"}},[_c('div',[_c('div',{staticClass:"d-flex flex-wrap my-5"},[_c('h2',{staticClass:"pb-2 font-weight-black text--uppercase",staticStyle:{"text-transform":"uppercase"}},[_vm._v(" "+_vm._s(_vm.$t('global.goto'))+" ")])])]),_c('v-row',[_vm._l((_vm.permissions),function(permission,index){return _c('v-col',{key:index,attrs:{"cols":"12","md":_vm.permissions.length >= 6 ? 2 : 4,"sm":4}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"font-weight-bold text-h6 text-center",attrs:{"block":"","height":"150px","to":{ name: permission.code },"elevation":hover ? 8 : 2,"outlined":""}},[_c('v-container',{attrs:{"bg":"","fill-height":"","grid-list-md":"","text-xs-center":""}},[_c('v-layout',{attrs:{"row":"","wrap":"","align-center":""}},[_c('v-flex',[_c('p',{staticClass:"mb-1"},[_c('v-icon',{attrs:{"color":"black","size":"48"}},[_vm._v(_vm._s(permission.icon))])],1),_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t("speeddial." + permission.code.toLowerCase()))+" ")])])],1)],1)],1)]}}],null,true)})],1)}),(_vm.hasPermissionStats)?_c('v-col',{attrs:{"cols":"12","md":_vm.permissions.length >= 6 ? 2 : 4,"sm":4}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"font-weight-bold text-h6 text-center",attrs:{"block":"","height":"150px","elevation":hover ? 8 : 2,"outlined":"","to":{
              name: 'STATS',
              query: {
                start: _vm.start,
                end: _vm.end,
              },
            }}},[_c('v-container',{attrs:{"bg":"","fill-height":"","grid-list-md":"","text-xs-center":""}},[_c('v-layout',{attrs:{"row":"","wrap":"","align-center":""}},[_c('v-flex',[_c('p',{staticClass:"mb-1"},[_c('v-icon',{attrs:{"color":"black","size":"48"}},[_vm._v("mdi-calendar-month")])],1),_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t("global.stat"))+" ")])])],1)],1)],1)]}}],null,false,1633891787)})],1):_vm._e()],2),_c('v-row',{staticClass:"align-center"},[_c('v-col',[_c('v-btn',{staticClass:"font-weight-bold text-h6 text-center",attrs:{"x-large":"","block":"","elevation":"2","outlined":""},on:{"click":function($event){$event.stopPropagation();return _vm.profile.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t("global.profile")))])],1)],1),(
    (_vm.isAdminUser || _vm.isManagerUser) && _vm.userLocodes && _vm.userLocodes.length > 1
  )?_c('div',[_c('div',{staticClass:"d-flex flex-wrap my-5"},[_c('h2',{staticClass:"pb-2 font-weight-black text--uppercase",staticStyle:{"text-transform":"uppercase"}},[_vm._v(" "+_vm._s(_vm.$t('global.change_port'))+" ")])]),_c('v-row',_vm._l((_vm.userLocodes),function(item,index){return _c('v-col',{key:index,attrs:{"cols":"12","md":2}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var hover = ref.hover;
return [_c('v-card',{staticClass:"font-weight-bold text-h6 text-center",attrs:{"block":"","height":"150px","elevation":hover ? 8 : 2,"outlined":"","dark":_vm.isLocodeCurrentlyActivated(item),"disabled":_vm.isLocodeCurrentlyActivated(item)},on:{"click":function($event){$event.stopPropagation();return _vm.openLocode(item)}}},[_c('v-container',{attrs:{"bg":"","fill-height":"","grid-list-md":"","text-xs-center":""}},[_c('v-layout',{attrs:{"row":"","wrap":"","align-center":""}},[_c('v-flex',[_c('p',{staticClass:"mb-1"},[_c('v-icon',{attrs:{"color":_vm.isLocodeCurrentlyActivated(item) ? 'white' : 'black',"size":"48"}},[_vm._v("mdi-anchor")])],1),_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(item.name)+" ")])])],1)],1)],1)]}}],null,true)})],1)}),1)],1):_vm._e(),_c('v-row',{staticClass:"align-center"},[_c('v-col',[_c('v-btn',{staticClass:"font-weight-bold text-h6 text-center",attrs:{"x-large":"","block":"","elevation":"2","outlined":""},on:{"click":function($event){$event.stopPropagation();return _vm.logout.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t("global.logout")))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }