var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"all-operator-table",attrs:{"footer-props":{
      showFirstLastPage: true,
      firstIcon: 'mdi-page-first',
      lastIcon: 'mdi-page-last',
      'items-per-page-options': [5, 10, 25, 50],
    },"loading":_vm.loading,"search":_vm.options.search,"page":_vm.options.page,"headers":_vm.headers,"items":_vm.items,"items-per-page":_vm.options.itemsPerPage,"server-items-length":_vm.totalItemCount,"item-key":"id","fixed-header":"","sort-by":_vm.options.sortBy,"sort-desc":_vm.options.sortDesc,"must-sort":""},on:{"update:search":function($event){return _vm.$set(_vm.options, "search", $event)},"update:page":function($event){return _vm.$set(_vm.options, "page", $event)},"update:itemsPerPage":function($event){return _vm.$set(_vm.options, "itemsPerPage", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.options, "itemsPerPage", $event)},"update:options":function($event){return _vm.handleOptionsUpdate('options', $event)},"update:sortBy":function($event){return _vm.$set(_vm.options, "sortBy", $event)},"update:sort-by":function($event){return _vm.$set(_vm.options, "sortBy", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.options, "sortDesc", $event)},"update:sort-desc":function($event){return _vm.$set(_vm.options, "sortDesc", $event)}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mb-4",attrs:{"single-line":"","label":_vm.$t('operators.search'),"prepend-inner-icon":"mdi-magnify","outlined":"","hide-details":""},on:{"input":_vm.filterItems},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('FilterMenu',{attrs:{"menu":_vm.menu,"extra":true},on:{"update:menu":function($event){_vm.menu=$event},"filterItems":_vm.loadOperators}})]},proxy:true}]),model:{value:(_vm.options.search),callback:function ($$v) {_vm.$set(_vm.options, "search", $$v)},expression:"options.search"}})]},proxy:true},{key:"item.status",fn:function(ref){
    var item = ref.item;
return [(item.status)?_c('v-chip',{staticClass:"text-uppercase",attrs:{"dark":"","small":"","label":"","color":_vm.findStatusColor(item.status)}},[_vm._v(" "+_vm._s(_vm.$t(("global." + (item.status.toLowerCase()))))+" ")]):_vm._e()]}},(_vm.hasUserPermissionToViewEditOrManage('OPERATORS'))?{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","absolute":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[(_vm.operator_types.length > 0)?_c('v-list-item',{on:{"click":function($event){return _vm.edit(item)}}},[_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("global.edit")))])],1)],1):_vm._e(),(
              _vm.hasUserPermissionToManage('OPERATORS') &&
              item.status == 'BLOCKED'
            )?_c('v-list-item',{on:{"click":function($event){return _vm.remove(item)}}},[_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',[_vm._v("mdi-delete")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("global.remove")))])],1)],1):_vm._e()],1)],1)]}}:null,{key:"item.accredited",fn:function(ref){
            var item = ref.item;
return [(item.accredited)?_c('v-icon',{attrs:{"color":"blue"}},[_vm._v("mdi-check-decagram ")]):_vm._e()]}},{key:"item.updated_at",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDatetime")(item.updated_at))+" ")]}},{key:"item.operator_type",fn:function(ref){
            var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.openOperatorLicensesList(item)}}},[_c('v-icon',[_vm._v("mdi-list-box-outline")])],1)]}}],null,true)}),_c('v-dialog',{attrs:{"width":"450"},model:{value:(_vm.errorDialog),callback:function ($$v) {_vm.errorDialog=$$v},expression:"errorDialog"}},[_c('v-card',[_c('v-card-text',[_c('div',{staticClass:"text-center pt-4"},[_c('v-icon',{attrs:{"size":"100","color":"red","left":""}},[_vm._v("mdi-close-circle")])],1),_c('p',{staticClass:"text-center text-h6 ma-0 pt-4",staticStyle:{"color":"black"}},[_vm._v(" "+_vm._s(_vm.$t("global.download_failed"))+" ")])]),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{staticClass:"black--text",attrs:{"block":"","depressed":"","color":"primary"},on:{"click":function($event){_vm.errorDialog = false}}},[_vm._v(" "+_vm._s(_vm.$t("global.close"))+" ")])],1)],1)],1),_c('OperatorLicensesList',{attrs:{"open":_vm.openOperatorLicensesListDialog,"item":_vm.selectedOperator},on:{"update:open":function($event){_vm.openOperatorLicensesListDialog=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }