<template>
  <div @click="activateCard">
    <v-card
      class="simple-vehicle-card"
      :color="isActive ? 'primary' : ''"
      :disabled="disabled"
      outlined
    >
      <v-card-text class="pa-2">
        <div class="d-flex flex-row">
          <div class="d-flex flex-column" style="flex: 2">
            <div style="max-width: 180px">
              <span class="registration-plate-font text-h6 card-title">{{
                item.registration_plate
              }}</span>
            </div>
            <div class="d-flex flex-column">
              <div class="flex-1-0 text-ellipsis">
                <span class="font-weight-black"
                  >{{ $t("vehicles.brand") }}:</span
                >
                <span> {{ item.brand || "N/D" }}</span>
              </div>
              <div class="flex-1-0 text-ellipsis">
                <span class="font-weight-black"
                  >{{ $t("vehicles.model") }}:</span
                >
                <span> {{ item.model || "N/D" }}</span>
              </div>
              <div class="flex-1-0 text-ellipsis">
                <span class="font-weight-black"
                  >{{ $t("vehicles.license_number") }}:</span
                >
                <span class="registration-plate-font">
                  {{ item.license_number || "N/D" }}</span
                >
              </div>
            </div>
          </div>
          <div class="vertical-dashed-line"></div>
          <div style="align-self: center">
            <v-icon v-if="item.vehicle_type.icon" color="black" size="50">{{
              item.vehicle_type.icon
            }}</v-icon>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  props: ["item", "index", "activeIndex", "disabled"],
  computed: {
    isActive() {
      return this.activeIndex === this.index;
    },
  },
  methods: {
    activateCard() {
      this.$emit("activate", this.index);
    },
  },
};
</script>

<style scoped>
.simple-vehicle-card {
  width: 100%;
  min-width: 100%;
  height: 100%;
  cursor: pointer;
}

.card-title {
  max-width: 100%;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vertical-dashed-line {
  border-right: 1px dashed black;
  margin: 0 10px;
}

.text-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 140px;
}

.registration-plate-font {
  font-family: "Fira Mono", monospace !important;
  font-weight: 700 !important;
}
</style>
