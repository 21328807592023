<template>
  <v-autocomplete
    v-model="selectedLocodes"
    :rules="rules"
    :items="locodes"
    :label="$t('users.locodes')"
    item-text="name"
    item-value="code"
    :filter="nameAndCodeFilter"
    outlined
    clearable
    multiple
  >
    <template v-slot:selection="data">
      <v-chip small dark class="mr-1 text-uppercase">{{ data.item.code }}</v-chip>
    </template>
    <template v-slot:item="data">
      <v-chip small dark class="mr-1 text-uppercase">{{ data.item.code }}</v-chip>
      {{ data.item.name }}
    </template>
  </v-autocomplete>
</template>
<script>
import configs from "@/helpers/configs";
export default {
  props: ["value", "rules"],
  computed: {
    selectedLocodes: {
      get() {
        let defaultLocode = configs.getLocode().code;
        if (!this.value.includes(defaultLocode)) {
          this.value.push(defaultLocode);
        }
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    locodes() {
      return configs
        .getLocodes()
        .sort((a, b) => {
          if (a.name.trim().toLowerCase() < b.name.trim().toLowerCase()) {
            return -1;
          }

          if (a.name.trim().toLowerCase() > b.name.trim().toLowerCase()) {
            return 1;
          }

          return 0;
        })
        .map((port) => {
          port.disabled = port.code == configs.getLocode().code;
          return port;
        });
    },
  },
  methods: {
    nameAndCodeFilter(item, queryText) {
      return (
        (item.code &&
          item.code.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
            -1) ||
        (item.name &&
          item.name
            .toLocaleLowerCase()
            .indexOf(queryText.toLocaleLowerCase())) > -1
      );
    },
  },
};
</script>
