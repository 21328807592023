<template>
  <v-bottom-sheet v-model="showEntryDetails">
    <v-card class="text-center" tile v-if="!!selectedItem">
      <v-toolbar flat color="primary">
        <v-toolbar-title class="font-weight-bold">
          <v-icon class="blac--text" left
            >mdi-boom-gate-arrow-up-outline</v-icon
          >
          <span
            v-if="
              !!scheduledEntryDetails && !!scheduledEntryDetails.entry_request
            "
            class="registration-plate-font"
          >
            {{
              scheduledEntryDetails.entry_request.vehicle.registration_plate
            }}</span
          ></v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-btn icon @click="showEntryDetails = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="entry-details-section pa-2 ma-0">
        <Loading v-model="loading"></Loading>
        <v-card
          class="pa-0 ma-0"
          flat
          v-if="
            !!scheduledEntryDetails && !!scheduledEntryDetails.entry_request
          "
        >
          <v-card-text class="pa-0 ma-0">
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td width="50%" class="font-weight-black text-uppercase">
                      ESTADO DO PEDIDO
                    </td>
                    <td>
                      <v-chip
                        small
                        label
                        :color="getStatusColor(item.status)"
                        dark
                        class="text-uppercase"
                      >
                        {{ getStatusLabel(item.status) }}
                      </v-chip>
                    </td>
                  </tr>

                  <tr>
                    <td width="50%" class="font-weight-black text-uppercase">
                      Código do Pedido
                    </td>
                    <td>
                      {{ scheduledEntryDetails.entry_request.code }}
                    </td>
                  </tr>

                  <tr>
                    <td width="50%" class="font-weight-black text-uppercase">
                      {{ $t("vehicles.registration_plate") }}
                    </td>
                    <td class="registration-plate-font">
                      {{
                        scheduledEntryDetails.entry_request.vehicle
                          .registration_plate
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td width="50%" class="font-weight-black text-uppercase">
                      {{ $t("global.day") }}
                    </td>
                    <td>
                      {{
                        scheduledEntryDetails.vehicle_at_pier_start
                          | formatDatetimeFromDatabaseOnlyDate
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td width="50%" class="font-weight-black text-uppercase">
                      {{ $t("entries.vehicle_at_pier_start") }}
                    </td>
                    <td>
                      {{ scheduledEntryDetails.vehicle_at_pier_start_time }}
                    </td>
                  </tr>
                  <tr>
                    <td width="50%" class="font-weight-black text-uppercase">
                      {{ $t("entries.vehicle_at_pier_end") }}
                    </td>
                    <td>
                      {{ scheduledEntryDetails.vehicle_at_pier_end_time }}
                    </td>
                  </tr>
                  <tr v-if="!!scheduledEntryDetails.sector">
                    <td width="50%" class="font-weight-black text-uppercase">
                      {{ $t("entries.sector") }}
                    </td>
                    <td>
                      {{ scheduledEntryDetails.sector.name }}
                    </td>
                  </tr>
                  <tr v-if="!!scheduledEntryDetails.zone">
                    <td width="50%" class="font-weight-black text-uppercase">
                      {{ $t("entries.zone") }}
                    </td>
                    <td>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            outlined
                            small
                            v-bind="attrs"
                            v-on="on"
                            @click="obtainCentroid(scheduledEntryDetails.zone)"
                            >{{ scheduledEntryDetails.zone.park }} -
                            {{ scheduledEntryDetails.zone.code
                            }}<v-icon right small
                              >mdi-map-marker-radius</v-icon
                            ></v-btn
                          ></template
                        >
                        <span>{{ $t("zones.go_to") }}</span>
                      </v-tooltip>
                    </td>
                  </tr>
                  <tr>
                    <td width="50%" class="font-weight-black text-uppercase">
                      {{ $t("global.ship") }}
                    </td>
                    <td>
                      {{ scheduledEntryDetails.entry_request.vessel_call.name }}
                    </td>
                  </tr>
                  <tr
                    v-if="
                      !!scheduledEntryDetails.entry_request.responsible_name
                    "
                  >
                    <td width="50%" class="font-weight-black text-uppercase">
                      {{ $t("entries.responsible_name") }}
                    </td>
                    <td>
                      {{ scheduledEntryDetails.entry_request.responsible_name }}
                    </td>
                  </tr>
                  <tr
                    v-if="
                      !!scheduledEntryDetails.entry_request.responsible_phone
                    "
                  >
                    <td width="50%" class="font-weight-black text-uppercase">
                      {{ $t("entries.responsible_phone") }}
                    </td>
                    <td>
                      {{
                        scheduledEntryDetails.entry_request.responsible_phone
                      }}
                    </td>
                  </tr>
                  <tr v-if="!!scheduledEntryDetails.notes">
                    <td width="50%" class="font-weight-black text-uppercase">
                      {{ $t("global.notes") }}
                    </td>
                    <td>
                      {{ scheduledEntryDetails.notes }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-card-text>
      <v-card-actions
        class="entry-request-actions"
        v-if="!!scheduledEntryDetails"
      >
        <div class="stacked-buttons">
          <v-btn
            v-if="
              item.accredited &&
              scheduledEntryDetails.entry_request.status != 'BLOCKED' &&
              scheduledEntryDetails.entry_request.status != 'CANCELED' &&
              scheduledEntryDetails.entry_request.status != 'REJECTED' &&
              !isOutdated()
            "
            @click.stop="handleSwapVehicleEntryRequest"
            class="w-100"
            :disabled="loading"
            depressed
          >
            <v-icon left>mdi-pencil</v-icon>
            {{ $t("scheduled_entries.edit") }}
          </v-btn>
        </div>
      </v-card-actions></v-card
    >

    <EditItemDialog
      v-if="scheduledEntryDetails && scheduledEntryDetails.entry_request"
      :open.sync="substituteVehicleDialogOpen"
      :entryRequestDetails.sync="scheduledEntryDetails.entry_request"
      @cancelRequest="handleCancelScheduledEntry"
      @editedRequestSuccessfully="handleEditedRequestSuccessfully"
      @loadEntryRequest="loadScheduledEntryDetails"
    >
    </EditItemDialog>
    <ConfirmDialog
      v-model="confirmDialogOpen"
      :loading="loadingCancelRequest"
      @confirm="handleCancelScheduledEntryConfirmation"
    >
      <template v-slot:title>{{
        $t("scheduled_entries.cancel_entry")
      }}</template>
      <template v-slot:content>
        {{ $t("scheduled_entries.cancel_entry_explanation") }}
      </template>
    </ConfirmDialog>
  </v-bottom-sheet>
</template>
<script>
import Loading from "@/components/Main/Loading";
import ConfirmDialog from "@/components/Main/ConfirmDialog";
import CountryFlag from "vue-country-flag";
import EditItemDialog from "@/components/Main/EntryRequests/EditItemDialog";
import configs from "@/helpers/configs";

export default {
  props: ["value", "item"],
  components: {
    Loading,
    ConfirmDialog,
    EditItemDialog,
    CountryFlag,
  },
  data() {
    return {
      loading: false,
      scheduledEntryDetails: null,
      confirmDialogOpen: false,
      loadingCancelRequest: false,
      substituteVehicleDialogOpen: false,
      openEditEntryRequestDialog: false,
    };
  },
  computed: {
    selectedItem: {
      get() {
        return this.item;
      },
      set(val) {
        this.$emit("update:item", val);
      },
    },
    showEntryDetails: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
        if (!val) {
          this.selectedItem = null;
        }
      },
    },
    centroid() {
      return this.$store.state.zones.centroid;
    },
    isMobileDevice() {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  watch: {
    showEntryDetails(val) {
      if (val) {
        this.loadScheduledEntryDetails();
      }
    },
    centroid(val) {
      if (val) this.openLocation(val.lat, val.long);
    },
  },
  methods: {
    handleClose() {
      this.showEntryDetails = !this.showEntryDetails;
    },
    handleEditSuccess() {
      this.$store.dispatch(
        "entry_requests/GET_ALL_BY_DATE",
        this.scheduledEntryDetails.entry_request.vehicle_at_pier_date
          .toString()
          .slice(0, 10)
      );
    },
    loadScheduledEntryDetails() {
      if (this.selectedItem && this.selectedItem.scheduled_entry.id) {
        this.loading = true;
        this.scheduledEntryDetails = null;
        this.$store
          .dispatch(
            "scheduled_entries/GET_BY_ID",
            this.selectedItem.scheduled_entry.id
          )
          .then((data) => {
            this.loading = false;
            this.scheduledEntryDetails = data;
          })
          .catch((e) => {
            console.error(e);
          });
      }
    },
    obtainCentroid(zone) {
      this.loading = true;
      this.$store
        .dispatch("zones/CENTROID", zone)
        .then(() => {
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
        });
    },

    openLocation(latitude, longitude) {
      // Desktop users will always open google maps
      var url = `https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}`;

      // Depending on what the user has on mobile
      if (this.isMobileDevice) {
        // Check if Google Maps is available
        if (
          typeof window.google !== "undefined" &&
          typeof window.google.maps !== "undefined"
        ) {
          url = `https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}`;
        }
        // Check if Apple Maps is available
        else if (
          typeof window.apple !== "undefined" &&
          typeof window.apple.maps !== "undefined"
        ) {
          url = `http://maps.apple.com/?daddr=${latitude},${longitude}`;
        }
        // Check if Waze is available
        else if (typeof window.waze !== "undefined") {
          url = `https://waze.com/ul?ll=${latitude},${longitude}`;
        }
      }
      // Open the navigation app
      window.open(url);
    },
    handleCancelScheduledEntry() {
      this.confirmDialogOpen = true;
    },
    handleSwapVehicleEntryRequest() {
      this.substituteVehicleDialogOpen = true;
    },
    handleEditEntryRequest() {
      this.openEditEntryRequestDialog = true;
    },
    handleEditedRequestSuccessfully() {
      this.showEntryDetails = false;
      this.$store.dispatch(
        "entry_requests/GET_ALL_BY_DATE",
        this.scheduledEntryDetails.entry_request.vehicle_at_pier_date
          .toString()
          .slice(0, 10)
      );
    },
    handleCancelScheduledEntryConfirmation() {
      this.loadingCancelRequest = true;
      this.$store
        .dispatch(
          "scheduled_entries/CANCEL",
          this.selectedItem.scheduled_entry.id
        )
        .then(() => {
          this.substituteVehicleDialogOpen = false;
          this.confirmDialogOpen = false;
          this.loadingCancelRequest = false;
          this.showEntryDetails = false;
          this.$store.dispatch(
            "entry_requests/GET_ALL_BY_DATE",
            this.scheduledEntryDetails.entry_request.vehicle_at_pier_date
              .toString()
              .slice(0, 10)
          );
        })
        .catch((e) => {
          console.error(e);
        });
    },
    isOutdated() {
      let currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);

      let entryRequestDate = new Date(
        this.scheduledEntryDetails.entry_request.vehicle_at_pier_date
      );
      entryRequestDate.setHours(0, 0, 0, 0);

      return entryRequestDate < currentDate;
    },
    getStatusLabel(status) {
      return configs.getStatusLabel(status);
    },
    getStatusColor(status) {
      return configs.getStatusColor(status);
    },
  },
};
</script>
<style scoped>
.entry-details-section {
  min-height: 300px;
  max-height: calc(60vh - var(--toolbar-height));
  overflow-y: auto;
  position: relative;
  text-align: left;
}
.entry-details-section:has(+ .entry-request-actions) {
  min-height: 300px;
  max-height: calc(60vh - var(--toolbar-height) - 60px);
}

.stacked-buttons {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
}

.registration-plate-font {
  font-family: "Fira Mono", monospace !important;
  font-weight: 700 !important;
}
</style>
