import { statsService } from "@/api_services/stats.service";

const DEFAULT_STATE = {};

export default {
  namespaced: true,
  state: Object.assign({}, JSON.parse(JSON.stringify(DEFAULT_STATE))),
  getters: {},
  mutations: {},
  actions: {

    GET_STATS: (_, payload) => {
      return new Promise((resolve, reject) => {
        statsService
          .getStats(payload)
          .then((res) => {
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    GET_NOTIFICATIONS: (_, payload) => {
      return new Promise((resolve, reject) => {
        statsService
          .getNotifications(payload)
          .then((res) => {
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    RESET: (context) => {
      context.commit("setDefaultState");
    },
  },
};
