<template>
  <v-container class="container--fluid fill-height generic-page">
    <v-row no-gutters justify="center" align-content="center">
      <v-col cols="12" style="max-width: 500px">
        <v-card class="outlined pa-1">
          <div class="layout column align-center">
            <v-img src="@/assets/logo.png" alt="Logo" width="60" />
            <h2 class="text-center my-1 text-uppercase font-weight-black" color="blue-darken-1">
              3Shorex
            </h2>
          </div>
          <v-card-text>
            <p class="text-body-1 text-center mt-5"> O link de verificação foi enviado para o seu endereço de e-mail.
              Antes de continuar, por favor veja o e-mail de verificação.</p>
            <v-btn text color="grey darken-2" exact large block @click="goToLogin">
              {{ $t('global.back_home') }}
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  methods: {
    goToLogin() {
      this.$router.push("/login");
    },
  },
};
</script>
<style>
.generic-page {
  background-image: url("../../assets/background.png");
  background-color: #1a202c !important;
  background-repeat: no-repeat;
  --background-attachment: fixed;
  background-position: bottom;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  transition: background-color 5000s ease-in-out 0s;
  font-size: 14px;
  line-height: 32px;
  -webkit-text-fill-color: black;
}

.v-text-field--filled:not(.v-text-field--single-line) input {
  margin-top: 5px !important;
}

input:-webkit-autofill::first-line {
  font-size: 14px;
}
</style>