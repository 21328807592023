import { planService } from "@/api_services/plans.service";

const DEFAULT_STATE = {
  all: [],
  loading: false,
  options: {
    search: "",
    currentPage: 1,
    itemsPerPage: 10,
    sortBy: "status",
    sortDesc: false,
  },
  total: 0,
};

export default {
  namespaced: true,
  state: Object.assign({}, JSON.parse(JSON.stringify(DEFAULT_STATE))),
  getters: {},
  mutations: {
    setPlans(state, plans) {
      state.all = plans;
    },
    setPlansLoading(state, isLoading) {
      state.loading = isLoading;
    },
    setDefaultState(state) {
      Object.assign(state, JSON.parse(JSON.stringify(DEFAULT_STATE)));
    },
  },
  actions: {
    GET_ALL: (context, payload) => {
      return new Promise((resolve, reject) => {
        context.commit("setPlansLoading", true);
        planService
          .getPlans(payload)
          .then((res) => {
            context.commit("setPlans", res.data);
            context.commit("setPlansLoading", false);
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            context.commit("setPlansLoading", false);
            reject(e);
          });
      });
    },

    GET_BY_DAY: (context, day) => {
      return new Promise((resolve, reject) => {
        context.dispatch("shorex/RESET_PLAN", null, { root: true }); // clear sectors because they depend on them

        planService
          .getPlanByDay(day)
          .then((res) => {
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    EXPORT_PLAN_ENTRY_REQUESTS: (_, date) => {
      return new Promise((resolve, reject) => {
        planService
          .exportPlanEntryRequests(date)
          .then((res) => {
            var fileURL = window.URL.createObjectURL(new Blob([res.data]));
            var fileLink = document.createElement("a");

            fileLink.href = fileURL;
            fileLink.setAttribute(
              "download",
              "3SHOREX_" + date + ".xlsx"
            );
            document.body.appendChild(fileLink);

            fileLink.click();

            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    ARCHIVE: (_, day) => {
      return new Promise((resolve, reject) => {
        planService
          .archivePlan(day)
          .then((res) => {
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    GET_ARCHIVED_PLAN_DATES(_) {
      return new Promise((resolve, reject) => {
        planService
          .getArchivedPlanDates()
          .then((res) => {
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },
    RESET: (context) => {
      context.commit("setDefaultState");
    },
  },
};
