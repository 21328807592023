<template>
  <v-dialog v-model="isOpen" persistent width="900">
    <v-card tile>
      <v-expand-transition>
        <div v-show="expand">
          <v-alert class="mb-0" tile type="error" @click="expand = false">
            <div
              v-for="(message, index) in this.messages"
              :key="index"
              class="subtitle"
            >
              {{ message }}
            </div>
          </v-alert>
        </div>
      </v-expand-transition>
      <v-toolbar color="primary" flat>
        <v-toolbar-title>
          <span>
            <v-icon left>mdi-pencil</v-icon>{{ $t("registered_users.edit") }}
          </span>
        </v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="overflow-y-auto pb-0 form-content">
        <Loading v-if="loading" v-model="loading"></Loading>
        <div v-else>
          <Loading
            v-if="saving"
            :value="saving"
            :custom-message="$t('global.updating_please_wait')"
          ></Loading>
          <v-form v-else ref="form" class="mt-4">
            <div v-if="!!itemToEdit">
              <v-text-field
                counter="50"
                maxlength="50"
                v-model="itemToEdit.name"
                required
                :label="$t('registered_users.name')"
                :rules="nameRules"
                outlined
              />
              <v-text-field
                counter="50"
                maxlength="50"
                v-model="itemToEdit.email"
                required
                :label="$t('registered_users.email')"
                :rules="emailRules"
                outlined
              />
              <v-text-field
                counter="25"
                maxlength="25"
                v-model="itemToEdit.phone"
                required
                :label="$t('registered_users.phone')"
                :rules="phoneNumberRules"
                outlined
              />
              <v-autocomplete
                v-model="itemToEdit.operator_id"
                :disabled="isUpdatingOperator"
                :items="operators"
                :rules="operatorRules"
                prepend-inner-icon="mdi-domain"
                :label="$t('registered_users.operator')"
                required
                item-text="name"
                item-value="id"
                outlined
                clearable
                hide-no-data
                cache-items
                :loading="operatorsLoading"
              >
              </v-autocomplete>

              <v-select
                v-model="itemToEdit.status"
                required
                :label="$t('registered_users.status')"
                :placeholder="$t('registered_users.status')"
                :rules="statusRules"
                item-value="code"
                :items="status"
                prepend-inner-icon="mdi-list-status"
                outlined
              >
                <template v-slot:item="{ item }">
                  {{ $t("registered_users_states." + item.code.toLowerCase()) }}
                </template>
                <template v-slot:selection="{ item }">
                  {{ $t("registered_users_states." + item.code.toLowerCase()) }}
                </template>
              </v-select>
            </div>
          </v-form>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer />
        <v-btn
          class="black--text"
          color="primary"
          depressed
          :disabled="loading || saving || operatorsLoading"
          @click="save"
        >
          {{ $t("global.update") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { phoneNumberValidation } from "@/helpers/common_validations.js";
import configs from "@/helpers/configs";
import Loading from "@/components/Main/Loading";
export default {
  props: ["open", "item"],
  components: { Loading },
  data() {
    return {
      loading: false,
      saving: false,
      expand: false,
      messages: [],
      itemToEdit: null,
      phoneNumberRules: [phoneNumberValidation],
      nameRules: [
        (v) => !!v || this.$t("rules.required_field"),
        (v) => (v && v.length <= 255) || this.$t("rules.character_limit"),
      ],
      emailRules: [
        (v) => !!v || this.$t("rules.required_field"),
        (v) => (v && v.length <= 50) || this.$t("rules.character_limit"),
        (v) =>
          !v ||
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          this.$t("rules.email_invalid"),
      ],
      vatRules: [
        (v) => !!v || this.$t("rules.required_field"),
        (v) => (v && /^\d{9}$/.test(v)) || this.$t("rules.vat_digit_number"),
      ],
      statusRules: [(v) => !!v || this.$t("rules.required_field")],
      operatorRules: [(v) => !!v || this.$t("rules.required_field")],
      isUpdatingOperator: false,
    };
  },
  computed: {
    isOpen: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("update:open", val);
      },
    },
    status() {
      return configs.getRegisteredUsersStatuses();
    },
    operators() {
      return this.$store.state.operators.all;
    },
    operatorsLoading() {
      return this.$store.state.operators.loading;
    },
  },
  watch: {
    open(val) {
      if (val) {
        this.$nextTick(() => {
          this.$refs.form.resetValidation();
          this.$store.dispatch("operators/GET_ALL");
          this.loadUserDetails();
        });
      }
    },
  },
  methods: {
    close() {
      this.isOpen = false;
      setTimeout(() => {
        this.expand = false;
        this.loading = false;
        this.saving = false;
      }, 600);
    },
    save() {
      this.expand = false;
      this.saving = true;
      this.messages = [];
      if (this.$refs.form.validate()) {
        this.$store
          .dispatch("registered_users/UPDATE", this.itemToEdit)
          .then((res) => {
            if (res.errors) {
              Object.entries(res.errors).forEach(([, value]) => {
                this.messages = this.messages.concat(value);
              });
              this.expand = true;
              this.saving = false;
            } else {
              this.close();
            }
          });
      } else {
        this.expand = false;
        this.saving = false;
      }
    },
    loadUserDetails() {
      if (this.item.id) {
        this.loading = true;
        this.itemToEdit = null;
        this.$store
          .dispatch("registered_users/GET_USER_BY_ID", this.item.id)
          .then((data) => {
            this.loading = false;
            this.itemToEdit = data;
          })
          .catch((e) => {
            console.error(e);
          });
      }
    },
  },
};
</script>

<style scoped>
.form-content {
  position: relative;
  min-height: calc(30vh - var(--toolbar-height));
  max-height: calc(80vh - var(--toolbar-height));
}
</style>
