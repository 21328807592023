<template>
  <RegisteredUsers />
</template>

<script>
import RegisteredUsers from "@/components/Configurations/RegisteredUsers";

export default {
  components: {
    RegisteredUsers,
  },
};
</script>
