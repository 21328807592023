import configs from "@/helpers/configs";
import axios from "axios";
import { sharedHelpers } from "@/api_services/utils/shared-helpers";

export const zonesService = {
  getAllZones,
  getZones,
  importZones,
  updateZone,
  deleteZone,
  getZoneCentroid,
  getZonePageById
};

async function getAllZones() {
  const requestOptions = {
    method: "GET",
    headers: sharedHelpers.getRequestHeaders()
  };
  return await axios.get(configs.getApiUrl() + "/v1/zones/all", requestOptions);
}

async function getZones(options, zoneId) {
  let sort = options.sortDesc ? "DESC" : "ASC";
  const requestOptions = {
    method: "GET",
    headers: sharedHelpers.getRequestHeaders(),
    params: {
      itemsPerPage: options.itemsPerPage,
      currentPage: options.page,
      sortBy: options.sortBy,
      sort: sort,
      search: options.search,
      selectedZoneId: zoneId
    },
  };
  return await axios.get(configs.getApiUrl() + "/v1/zones", requestOptions);
}

async function importZones(payload) {
  const requestOptions = {
    method: "POST",
    headers: sharedHelpers.getRequestHeaders()
  };
  var bodyFormData = new FormData();
  bodyFormData.append("file", payload.file);
  bodyFormData.append("replace", payload.replace);
  return await axios.post(
    configs.getApiUrl() + "/v1/zones",
    bodyFormData,
    requestOptions
  );
}

async function updateZone(zone) {
  const requestOptions = {
    method: "PUT",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.put(
    configs.getApiUrl() + "/v1/zones/" + zone.id,
    zone,
    requestOptions
  );
}

async function deleteZone(zone) {
  const requestOptions = {
    method: "POST",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.delete(
    configs.getApiUrl() + "/v1/zones/" + zone.id,
    requestOptions
  );
}

async function getZoneCentroid(zone) {
  const requestOptions = {
    method: "GET",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.get(
    configs.getApiUrl() + "/v1/zones/" + zone.id + "/centroid",
    requestOptions
  );
}

async function getZonePageById(zoneId, options) {
  let sort = options.sortDesc ? "DESC" : "ASC";
  const requestOptions = {
    method: "GET",
    headers: sharedHelpers.getRequestHeaders(),
    params: {
      itemsPerPage: options.itemsPerPage,
      sortBy: options.sortBy,
      sort: sort,
    },
  };
  return await axios.get(
    configs.getApiUrl() + "/v1/zones/page/" + zoneId,
    requestOptions
  );
}  
