<template>
  <v-autocomplete
    v-model="itemSelected"
    :items="items"
    outlined
    :dense="dense"
    :hide-details="hideDetails"
    item-value="id"
    item-text="name"
    :disabled="disabled"
    clearable
    :single-line="singleLine"
    :rules="rules"
    :prepend-inner-icon="prependInnerIcon"
    :placeholder="$t('sectors.select_sector')"
    :label="label"
  >
    <template v-slot:selection="data">
      <v-avatar
        :color="data.item.color"
        tile
        style="border: 1px solid black; justify-content: center !important"
        size="15"
        class="float-left"
      ></v-avatar>
      <span class="ml-1 wrap-text float-right" style="font-size: 14px">{{
        data.item.name
      }}</span>
    </template>
    <template v-slot:item="data">
      <template v-if="typeof data.item !== 'object'">
        <v-list-item-content v-text="data.item"></v-list-item-content>
      </template>
      <template v-else>
        <v-list-item-avatar
          :color="data.item.color"
          tile
          class="font-weight-bold black--text"
          style="border: 1px solid black"
          size="15"
        >
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title v-html="data.item.name"></v-list-item-title>
        </v-list-item-content>
      </template>
    </template>
  </v-autocomplete>
</template>
<script>
import { sector } from "@turf/turf";

export default {
  props: {
    label: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false, // Set your default value here
    },
    value: {
      default: null,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    // Define default values for other props similarly
    singleLine: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [], // Default to an empty array
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    prependInnerIcon: {
      type: String,
      default: "",
    },
  },
  computed: {
    items() {
      return this.$store.state.sectors.all.filter(
        (sector) => sector.zones.length > 0
      );
    },
    itemSelected: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>
<style scoped>
.wrap-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
