<template>
  <div class="ma-4">
    <div class="d-flex flex-wrap">
      <div class="ma-0 pa-0">
        <p class="text-h5 font-weight-black mb-0">
          {{ $t("global.profile") }}
        </p>
        <p class="text-caption">
          {{ $t("global.profile_explanation") }}
        </p>
      </div>
      <v-spacer></v-spacer>
    </div>
    <v-form>
      <v-card-text class="pb-0">
        <v-form
          ref="formProfileDetails"
          @submit.prevent="updateDetails"
          lazy-validation
          v-model="validProfileDetails"
          :disabled="isUpdatingDetails"
        >
          <v-text-field
            :label="$t('registered_users.name')"
            name="name"
            v-model="name"
            type="text"
            outlined
            autocomplete="off"
            :rules="basicRules"
            counter="50"
            maxlength="50"
          ></v-text-field>

          <v-text-field
            :label="$t('registered_users.phone')"
            name="phone"
            v-model="phone"
            type="text"
            outlined
            autocomplete="off"
            counter="25"
            maxlength="25"
          ></v-text-field>

          <v-text-field
            :label="$t('registered_users.email')"
            name="email"
            v-model="email"
            type="email"
            outlined
            autocomplete="off"
            :rules="basicRules"
            counter="50"
            maxlength="50"
            disabled
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn
          :loading="isUpdatingDetails"
          depressed
          @click="updateDetails"
          large
          block
          class="black--text"
          color="primary"
        >
          <v-icon left> mdi-content-save </v-icon>
          {{ $t("global.update_details") }}
        </v-btn>
      </v-card-actions>

      <v-divider class="my-4"></v-divider>

      <v-card-text class="pb-0">
        <v-form
          ref="formProfilePassword"
          @submit.prevent="updatePassword"
          :disabled="isUpdatingPassword"
          lazy-validation
          v-model="validProfilePassword"
        >
          <v-text-field
            label="Palavra-passe atual"
            name="password-verify"
            v-model="currentPassword"
            :type="showCurrentPassword ? 'text' : 'password'"
            :append-icon="showCurrentPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showCurrentPassword = !showCurrentPassword"
            outlined
            :rules="passwordRules"
            counter="50"
            maxlength="50"
          ></v-text-field>
          <v-text-field
            :label="$t('users.new_password')"
            name="password"
            v-model="password"
            :type="showPassword ? 'text' : 'password'"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPassword = !showPassword"
            outlined
            @focus="handleType"
            @blur="handleType"
            autocomplete="new-password"
            :rules="passwordRules"
            counter="50"
            maxlength="50"
          ></v-text-field>
          <v-text-field
            :label="$t('users.password_confirm')"
            name="password-verify"
            v-model="passwordVerify"
            :type="showPasswordVerify ? 'text' : 'password'"
            :append-icon="showPasswordVerify ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPasswordVerify = !showPasswordVerify"
            outlined
            :rules="passwordVerifyRules"
            counter="50"
            maxlength="50"
          ></v-text-field>
        </v-form>
        <v-alert
          v-show="passwordErrorMessage"
          text
          color="error"
          dark
          dense
          outlined
          style="font-size: 1rem"
        >
          {{ passwordErrorMessage }}
        </v-alert>
      </v-card-text>
      <v-card-actions>
        <v-btn
          :loading="isUpdatingPassword"
          depressed
          @click="updatePassword"
          block
          large
          class="black--text"
          color="primary"
        >
          <v-icon left> mdi-content-save </v-icon>
          {{ $t("users.update_password") }}
        </v-btn>
      </v-card-actions>

      <v-divider class="my-4" v-if="operator"></v-divider>

      <div class="d-flex flex-wrap" v-if="operator">
        <div class="ma-0 pa-0">
          <p class="text-h5 font-weight-black mb-0">
            {{ $t("global.operator") }}
          </p>
          <p class="text-caption">
            {{ $t("global.profile_operator_explanation") }}
          </p>
        </div>
        <v-spacer></v-spacer>
      </div>

      <v-card-actions>
        <v-btn
          v-if="operator"
          depressed
          @click="openOperatorDetails = true"
          large
          block
          class="black--text"
          color="primary"
        >
          <v-icon left> mdi-eye </v-icon>
          {{ $t("global.view_operator_details") }}
        </v-btn>
      </v-card-actions>
    </v-form>

    <OperatorProfile :open.sync="openOperatorDetails" />
  </div>
</template>
<script>
import OperatorProfile from "@/components/Configurations/OperatorProfile.vue";
export default {
  components: {
    OperatorProfile,
  },
  data() {
    return {
      showPassword: false,
      openOperatorDetails: false,
      showCurrentPassword: false,
      name: null,
      phone: null,
      email: null,
      password: null,
      currentPassword: null,
      isUpdatingDetails: false,
      isUpdatingPassword: false,
      validProfileDetails: false,
      validProfilePassword: false,
      basicRules: [(v) => !!v || "Campo obrigatório"],
      passwordRules: [
        (value) => !!value || "Campo obrigatório",
        (value) => (value && value.length >= 6) || "Minimo de 6 caracteres",
      ],
      vatRules: [
        (v) => !!v || this.$t("rules.required_field"),
        (v) => (v && /^\d{9}$/.test(v)) || this.$t("rules.vat_digit_number"),
      ],
      fieldTypes: {
        password: "text",
      },
      passwordErrorMessage: "",
      passwordVerify: null,
      showPasswordVerify: false,
    };
  },
  watch: {},

  computed: {
    userAccount() {
      return this.$store.state.user.account;
    },
    operator() {
      return this.$store.state.operators.currentOperator;
    },
    passwordVerifyRules() {
      return [
        (value) => !!value || "Campo obrigatório",
        (value) => (value && value.length >= 6) || "Minimo de 6 caracteres",
        this.password === this.passwordVerify ||
          "As palavras-passe não coincidem",
      ];
    },
  },

  mounted() {
    if (!!this.userAccount) {
      this.name = this.userAccount.user.name;
      this.phone = this.userAccount.user.phone;
      this.email = this.userAccount.user.email;
    }
  },

  methods: {
    handleType(event) {
      const { srcElement, type } = event;
      const { name, value } = srcElement;

      if (type === "blur" && !value) {
        this.fieldTypes[name] = "text";
      } else {
        this.fieldTypes[name] = "password";
      }
    },
    updateDetails() {
      if (this.$refs.formProfileDetails.validate()) {
        this.isUpdatingDetails = true;
        this.$store
          .dispatch("UPDATE_PROFILE_DETAILS", {
            name: this.name,
            email: this.email,
            phone: this.phone,
          })
          .then((res) => {
            let updatedUser = res.user;
            this.$store
              .dispatch("UPDATE_ACCOUNT_DATA", updatedUser)
              .catch((error) => {
                console.error(error);
              });
            this.isUpdatingDetails = false;
          })
          .catch(() => {
            this.isUpdatingDetails = false;
          });
      }
    },
    updatePassword() {
      this.passwordErrorMessage = "";
      if (this.$refs.formProfilePassword.validate()) {
        this.isUpdatingPassword = true;
        this.$store
          .dispatch("UPDATE_PROFILE_PASSWORD", {
            currentPassword: this.currentPassword,
            password: this.password,
          })
          .then((res) => {
            this.isUpdatingPassword = false;
            if (res.error) {
              this.passwordErrorMessage = res.error.msg;
            }else{
              this.$refs.formProfilePassword.reset();
            }
          })
          .catch(() => {
            this.isUpdatingPassword = false;
          });
      }
    },
  },
};
</script>
