<template>
  <v-dialog v-model="dialogOpen" persistent max-width="500">
    <v-card tile :loading="loading">
      <v-card-title class="font-weight-black text-h5"><slot name="title"></slot></v-card-title>
      <v-card-text class="font-weight-bold text-body-2"><slot name="content"></slot></v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer />
        <v-btn color="darken-1" text @click="cancelClose" :disabled="loading">{{
          $t("global.no")
        }}</v-btn>
        <v-btn color="darken-1" text @click="confirmClose" :disabled="loading">{{
          $t("global.yes")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: ["value", "loading"],
  computed: {
    dialogOpen: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
  methods: {
    cancelClose() {
      this.dialogOpen = false;
      this.$emit("cancel");
    },
    confirmClose() {
      this.$emit("confirm");
    },
  },
};
</script>
