<template>
  <div class="map-container">
    <div ref="map" class="map" />
  </div>
</template>

<script>
import "maptalks/dist/maptalks.css";
import configs from "@/helpers/configs";
import * as maptalks from "maptalks";
const ZONES_LAYERNAME = "zones";
const UNAVAILABLE_COLOR = "rgb(250,0,0)";
export default {
  props: ["selected"],
  data() {
    return {
      map: null,
      loading: false,
      zonesHighlightSymbol: {
        lineWidth: 2,
        polygonOpacity: 0.8,
        polygonFill: "rgb(215, 186, 32)",
      },
      zonesSymbol: null,
      zonesUnavailableSymbol: null,
    };
  },
  computed: {
    locode() {
      return configs.getLocode();
    },
    zones() {
      return this.$store.state.zones.all;
    },
    selectedZone: {
      get() {
        return this.selected;
      },
      set(val) {
        this.$emit("update:selected", val);
      },
    },
  },
  watch: {
    zones() {
      this.processZones();
    },
  },
  mounted() {
    this.getAllZones();
    this.initSymbols();
    this.initMap();
  },
  beforeDestroy() {
    this.map.off("click", this.handleMapClick);
  },
  methods: {
    getAllZones() {
      this.$store.dispatch("zones/GET_ALL").catch((ex) => {
        console.error("Error loading zones", ex);
      });
    },
    initSymbols() {
      let options = {
        lineColor: "#34495e",
        lineWidth: 1,
        polygonFill: "rgb(59, 195, 245)",
        polygonOpacity: 0.6,
        textFaceName: "Roboto",
        textName: "{park}\n{code}", //value from name in geometry's properties
        textWeight: "bold", //'bold', 'bold'
        textStyle: "normal", //'italic', 'oblique'
        textSize: {
          stops: [
            [16, 0],
            [17, 12],
          ],
        },
        textOpacity: 1,
        textLineSpacing: 0,
        textDx: 0,
        textDy: 0,
        textHorizontalAlignment: "middle", //left | middle | right | auto
        textVerticalAlignment: "middle", // top | middle | bottom | auto
        textAlign: "center", //left | right | center | auto,
        textFill: "#34495e",
      };

      this.zonesSymbol = options;
      this.zonesUnavailableSymbol = { ...options };
      this.zonesUnavailableSymbol.polygonFill = UNAVAILABLE_COLOR;
    },
    initMap() {
      let center;
      let bearing;
      let zoom;
      try {
        center = this.locode.coordinates;
        bearing = this.locode.bearing;
        zoom = this.locode.zoom;
      } catch (e) {
        console.error(e);
        center = [-8.702487, 41.183199];
        bearing = -38;
        zoom = 15;
      }
      this.map = new maptalks.Map(this.$refs.map, {
        center: center,
        zoom: zoom,
        bearing: bearing,
        hitDetect: false, // whether to enable hit detecting of layers for cursor style on this map, disable it to improve performance
        layerCanvasLimitOnInteracting: -1, // -1 to display all layers when interacting
        zoomControl: false, // add zoom control
        scaleControl: false, // add scale control
        attribution: false,
        baseLayer: new maptalks.TileLayer("baselayer_nv", {
          visible: true,
          urlTemplate:
            "https://basemaps.cartocdn.com/rastertiles/voyager_nolabels/{z}/{x}/{y}.png",
          subdomains: ["a", "b", "c", "d"],
          attribution: "OSM CARTO",
        }),
        layers: [new maptalks.VectorLayer(ZONES_LAYERNAME, [])],
      });

      this.map.on("click", this.handleMapClick);
    },

    processZones() {
      if (this.map) {
        let layer = this.map.getLayer(ZONES_LAYERNAME);
        layer.clear();
        if (this.zones) {
          this.zones.forEach((zone) => {
            let geometry = maptalks.GeoJSON.toGeometry(zone.geojson);
            if (geometry) {
              geometry.setSymbol(
                zone.unavailable
                  ? this.zonesUnavailableSymbol
                  : this.zonesSymbol
              );
              geometry.setProperties({
                id: zone.id,
                code: zone.code,
                park: zone.park,
                unavailable: zone.unavailable,
              });
              geometry.addTo(layer);
              geometry.on("click", this.handleZoneClicked);
            }
          });
        }
      }
    },

    deSelectZones() {
      if (this.map) {
        this.map
          .getLayer(ZONES_LAYERNAME)
          .forEach((l) =>
            l.setSymbol(
              l.properties.unavailable
                ? this.zonesUnavailableSymbol
                : this.zonesSymbol
            )
          );
      }
      this.selectedZone = [];
    },

    handleZoneClicked(e) {
      if (e && e.domEvent) {
        e.domEvent.preventDefault();
        e.domEvent.stopPropagation();
      }
      this.deSelectZones();
      if (e.target) {
        let found = this.zones.find((z) => {
          return z.id == e.target.properties.id;
        });
        if (found) {
          this.selectedZone = [found];
        }
        e.target.updateSymbol(this.zonesHighlightSymbol);
      }
    },
    handleMapClick() {
      this.deSelectZones();
    },
  },
};
</script>

<style scoped>
.map,
.map-container {
  width: 100%;
  height: 100%;
}

.map-container {
  position: relative;
}
</style>
