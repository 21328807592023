<template>
  <div>
    <v-navigation-drawer
      v-model="sectorsActive"
      app
      clipped
      :permanent="sectorsActive"
      right
      width="400"
      disable-resize-watcher
      style="border: 1px solid #ccc;"
    >
      <v-toolbar color="default" dense flat>
        <v-toolbar-title class="font-weight-bold"
          >{{ $t("global.sectors") }} ({{ sectors.length }})</v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon @click.stop="addSector" v-bind="attrs" v-on="on">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("sectors.create") }}</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon @click.stop="copySectors" v-bind="attrs" v-on="on">
              <v-icon>mdi-history</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("sectors.copy") }}</span></v-tooltip
        >
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              :color="filtersOpen ? 'primary' : 'default'"
              @click.stop="toggleFilters"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-filter</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("global.filters") }}</span>
        </v-tooltip>
      </v-toolbar>
      <v-divider></v-divider>

      <div class="pa-2" v-show="filtersOpen" style="height: 60px">
        <v-text-field
          v-model="searchTerm"
          :placeholder="$t('sectors.search')"
          filled
          rounded
          dense
          hide-details
        ></v-text-field>
      </div>
      <v-divider></v-divider>
      <div
        class="sectors-content full-height"
        :class="filtersOpen ? 'with-filters' : ''"
      >
        <Loading
          v-if="loading"
          :value="loading"
          :custom-message="$t('sectors.loading_sectors_please_wait')"
        ></Loading>
        <div v-else>
          <div class="my-1 text-center" v-if="filteredSectors.length == 0">
            {{ $t("sectors.no_sectors_found") }}
          </div>
          <v-list-item-group
            v-else
            v-model="selectedItem"
            color="blue"
            active-class="selected"
            class="ma-2"
          >
            <v-list-item
              v-for="item in filteredSectors"
              :key="item.id"
              :value="item"
              class="sector-card-list-item"
              :class="{
                selected: selectedItem && item && item.id == selectedItem.id,
              }"
              :disabled="isSectorsListDisabled"
            >
              <Sector
                @changezones="handleChangeZones"
                @edit="handleEditSector"
                @delete="handleDeleteSector"
                :item="item"
                :selected="selectedItem && item && item.id == selectedItem.id"
              ></Sector> </v-list-item
          ></v-list-item-group>
        </div>
      </div>
    </v-navigation-drawer>
    <CreateItemDialog :open.sync="openAddItemDialog" :plan="this.plan" />
    <EditItemDialog :open.sync="openEditItemDialog" :item="selectedItem" />
    <CopyDialog :open.sync="openCopyDialog" :plan="this.plan" />
    <DeleteItemDialog :open.sync="openDeleteItemDialog" :item="selectedItem" />
  </div>
</template>
<script>
import Loading from "@/components/Main/Loading";
import CreateItemDialog from "@/components/Main/Sectors/CreateItemDialog";
import EditItemDialog from "@/components/Main/Sectors/EditItemDialog";
import DeleteItemDialog from "@/components/Main/Sectors/DeleteItemDialog";
import CopyDialog from "@/components/Main/Sectors/CopyDialog";
import Sector from "@/components/Main/Sectors/Sector";
export default {
  props: ["plan", "sector", "mode"],
  components: {
    Loading,
    CreateItemDialog,
    EditItemDialog,
    DeleteItemDialog,
    CopyDialog,
    Sector,
  },
  data() {
    return {
      searchTerm: "",
      filtersOpen: false,
      openAddItemDialog: false,
      openEditItemDialog: false,
      openCopyDialog: false,
      openDeleteItemDialog: false,
    };
  },
  computed: {
    isSectorsListDisabled() {
      return this.mode == "CHANGE_SECTOR";
    },
    selectedItem: {
      get() {
        return this.sector;
      },
      set(val) {
        this.$emit("update:sector", val);
      },
    },
    loading() {
      return this.$store.state.sectors.loading;
    },
    sectors() {
      return this.$store.state.sectors.all;
    },
    filteredSectors() {
      if (!this.searchTerm) {
        return this.sectors;
      }
      return this.sectors.filter((s) => {
        return s.name.toLowerCase().includes(this.searchTerm.toLowerCase());
      });
    },
    zones() {
      return this.$store.state.zones.all;
    },
    sectorsActive: {
      get() {
        return this.$store.state.shorex.sectorsActive;
      },
      set(val) {
        this.$store.dispatch("shorex/SET_SECTORS_ACTIVE", val);
      },
    },
  },
  watch: {
    sectors() {
      this.selectedItem = null;
    },
  },
  methods: {
    toggleFilters() {
      this.filtersOpen = !this.filtersOpen;
    },
    loadSectors() {
      this.$store.dispatch("sectors/GET_ALL", this.plan.id);
    },
    addSector() {
      this.openAddItemDialog = true;
    },
    copySectors() {
      this.openCopyDialog = true;
    },
    handleChangeZones(sector) {
      this.selectedItem = sector;
      this.$emit("changeSector", sector);
    },
    handleEditSector(sector) {
      this.selectedItem = sector;
      this.openEditItemDialog = true;
    },
    handleDeleteSector(sector) {
      this.selectedItem = sector;
      this.openDeleteItemDialog = true;
    },
  },
};
</script>
<style scoped>
.sectors-content {
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 350px;
}
.full-height {
  height: calc(100% - var(--toolbar-height) - 2px);
}
.with-filters {
  height: calc(100% - var(--toolbar-height) - 2px - 60px);
}

.selected .sector-card {
  border: 2px solid #ebe70c !important;
  background-color: #f9f9e7 !important;
}
.sector-card-list-item {
  padding: 3px 2px;
}
</style>
