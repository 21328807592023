import { vehicleTypesService } from "@/api_services/vehicle_types.service";
const DEFAULT_STATE = {
  all: [],
  loading: false,
  options: {
    search: "",
    page: 1,
    itemsPerPage: 10,
    sortBy: "name",
    sortDesc: false,
  },
  total: 0,
};

export default {
  namespaced: true,
  state: Object.assign({}, JSON.parse(JSON.stringify(DEFAULT_STATE))),
  mutations: {
    setOptions(state, options) {
      state.options = options;
    },
    setTotal(state, total) {
      state.total = total;
    },
    setVehicleTypes(state, items) {
      state.all = items;
    },
    setLoading(state, isLoading) {
      state.loading = isLoading;
    },
    setDefaultState(state) {
      Object.assign(state, JSON.parse(JSON.stringify(DEFAULT_STATE)));
    },
  },
  actions: {
    UPDATE_OPTIONS(context, options) {
      context.commit("setOptions", options);
    },
    GET_ALL(context, options) {
      return new Promise((resolve, reject) => {
        context.commit("setLoading", true);
        if (options) {
          context.commit("setOptions", options);
        }
        vehicleTypesService
          .getVehicleTypes(context.state.options)
          .then((res) => {
            context.commit("setVehicleTypes", res.data.data);
            context.commit("setTotal", res.data.total);
            context.commit("setLoading", false);
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            context.commit("setLoading", false);
            reject(e);
          });
      });
    },
    GET_ALL_WITHOUT_PAGINATED(context) {
      return new Promise((resolve, reject) => {
        context.commit("setLoading", true);
        vehicleTypesService
          .getVehicleTypes({
            search: "",
            page: 1,
            itemsPerPage: 100,
            sortBy: "name",
            sortDesc: false,
          })
          .then((res) => {
            context.commit("setVehicleTypes", res.data.data);
            context.commit("setLoading", false);
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            context.commit("setLoading", false);
            reject(e);
          });
      });
    },
    GET_VEHICLE_TYPE_BY_ID: (_, id) => {
      return new Promise((resolve, reject) => {
        vehicleTypesService
          .getVehicleType(id)
          .then((res) => {
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },
    CREATE: (context, vehicle) => {
      return new Promise((resolve, reject) => {
        vehicleTypesService
          .createVehicleType(vehicle)
          .then((res) => {
            resolve(res.data);
            context.dispatch("GET_ALL");
          })
          .catch((error) => {
            console.error(error);
            reject(error);
          });
      });
    },
    UPDATE: (context, vehicle) => {
      return new Promise((resolve, reject) => {
        vehicleTypesService
          .updateVehicleType(vehicle)
          .then((res) => {
            resolve(res.data);
            context.dispatch("GET_ALL");
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },
    DELETE: (context, vehicleTypeId) => {
      return new Promise((resolve, reject) => {
        vehicleTypesService
          .deleteVehicleType(vehicleTypeId)
          .then((res) => {
            resolve(res.data);
            context.dispatch("GET_ALL");
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    RESET: (context) => {
      context.commit("setDefaultState");
    },
  },
};
