import configs from "@/helpers/configs";
import axios from "axios";
import { sharedHelpers } from "@/api_services/utils/shared-helpers";

export const configurationsService = {
  getConfigurations,
  setConfigurations,
};

async function getConfigurations() {
  var requestOptions = {
    method: "GET",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.get(
    configs.getApiUrl() + "/v1/configurations",
    requestOptions
  );
}

async function setConfigurations(config) {
  var requestOptions = {
    method: "POST",
    headers: sharedHelpers.getRequestHeaders(),
  };

  return await axios
    .post(configs.getApiUrl() + "/v1/configurations", config, requestOptions)
    .catch(() => {
      return null;
    });
}
