<template>
  <div class="ma-4">
    <div class="d-flex flex-wrap">
      <div class="ma-0 pa-0">
        <p class="text-h5 font-weight-black mb-0">
          {{ $t("configurations.vehicle_types") }}
        </p>
        <p class="text-caption">
          {{ $t("vehicle_types.explanation") }}
        </p>
      </div>
      <v-spacer></v-spacer>
      <div
        v-if="hasUserPermissionToViewEditOrManage('VEHICLE_TYPES')"
        class="ml-auto"
      >
        <v-btn class="black--text" color="primary" depressed @click="add">
          <v-icon left>mdi-plus-box-multiple</v-icon>
          {{ $t("vehicle_types.create") }}
        </v-btn>
      </div>
    </div>
    <List @remove="remove" @edit="edit" />
    <CreateItemDialog :open.sync="openAddItemDialog" />
    <EditItemDialog :open.sync="openEditItemDialog" :item="selectedItem" />
    <DeleteItemDialog :open.sync="openDeleteItemDialog" :item="selectedItem" />
  </div>
</template>

<script>
import List from "./VehicleTypes/List";
import CreateItemDialog from "./VehicleTypes/CreateItemDialog";
import EditItemDialog from "./VehicleTypes/EditItemDialog";
import DeleteItemDialog from "./VehicleTypes/DeleteItemDialog";
export default {
  components: {
    List,
    CreateItemDialog,
    EditItemDialog,
    DeleteItemDialog,
  },
  data() {
    return {
      openAddItemDialog: false,
      openEditItemDialog: false,
      openDeleteItemDialog: false,
      selectedItem: null,
    };
  },
  methods: {
    add() {
      this.openAddItemDialog = true;
    },
    edit(item) {
      this.selectedItem = null;
      this.selectedItem = item;
      this.openEditItemDialog = true;
    },
    remove(item) {
      this.selectedItem = null;
      this.selectedItem = item;
      this.openDeleteItemDialog = true;
    },
  },
};
</script>
