var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ma-4"},[_c('div',{staticClass:"d-flex flex-wrap"},[_c('div',{staticClass:"ma-0 pa-0"},[_c('p',{staticClass:"text-h5 font-weight-black mb-0"},[_vm._v(" "+_vm._s(_vm.$t("configurations.vehicles"))+" ")]),_c('p',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(_vm.$t("configurations.vehicles_explanation"))+" ")])]),_c('v-spacer'),(_vm.hasUserPermissionToViewOrManage('VEHICLES'))?_c('div',{staticClass:"ml-auto"},[(_vm.$vuetify.breakpoint.mobile)?_c('div',[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"black--text",attrs:{"color":"primary","dark":"","depressed":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-dots-vertical ")])],1)]}}],null,false,3944841453)},[_c('v-list',[(
                _vm.hasUserPermissionToViewEditOrManage('VEHICLES') &&
                _vm.vehicle_types.length > 0
              )?_c('v-list-item',{on:{"click":_vm.add}},[_c('v-list-item-avatar',{staticClass:"mr-2"},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus-box-multiple")])],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t("vehicles.new_vehicle"))+" ")])],1):_vm._e(),(
                _vm.hasUserPermissionToViewEditOrManage('VEHICLES') &&
                _vm.vehicle_types.length > 0
              )?_c('v-list-item',{on:{"click":_vm.importVehicles}},[_c('v-list-item-avatar',{staticClass:"mr-2"},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-upload")])],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t("global.import"))+" ")])],1):_vm._e(),_c('v-list-item',{on:{"click":_vm.exportVehicles}},[_c('v-list-item-avatar',{staticClass:"mr-2"},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-download")])],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t("global.export"))+" ")])],1)],1)],1)],1):_c('div',[(
            _vm.hasUserPermissionToViewEditOrManage('VEHICLES') &&
            _vm.vehicle_types.length > 0
          )?_c('v-btn',{staticClass:"black--text",attrs:{"color":"primary","depressed":""},on:{"click":_vm.add}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus-box-multiple")]),_vm._v(" "+_vm._s(_vm.$t("vehicles.new_vehicle"))+" ")],1):_vm._e(),(
            _vm.hasUserPermissionToViewEditOrManage('VEHICLES') &&
            _vm.vehicle_types.length > 0
          )?_c('v-btn',{staticClass:"ml-2 black--text",attrs:{"color":"primary","depressed":""},on:{"click":_vm.importVehicles}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-upload")]),_vm._v(" "+_vm._s(_vm.$t("global.import"))+" ")],1):_vm._e(),_c('v-btn',{staticClass:"ml-2 black--text",attrs:{"color":"primary","depressed":""},on:{"click":_vm.exportVehicles}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-download")]),_vm._v(" "+_vm._s(_vm.$t("global.export"))+" ")],1)],1)]):_vm._e()],1),_c('List',{on:{"remove":_vm.remove,"edit":_vm.edit}}),_c('CreateItemDialog',{attrs:{"open":_vm.openAddItemDialog},on:{"update:open":function($event){_vm.openAddItemDialog=$event}}}),_c('EditItemDialog',{attrs:{"open":_vm.openEditItemDialog,"item":_vm.selectedItem},on:{"update:open":function($event){_vm.openEditItemDialog=$event}}}),_c('DeleteItemDialog',{attrs:{"open":_vm.openDeleteItemDialog,"item":_vm.selectedItem},on:{"update:open":function($event){_vm.openDeleteItemDialog=$event}}}),_c('ImportItemsDialog',{attrs:{"open":_vm.openImportItemsDialog},on:{"update:open":function($event){_vm.openImportItemsDialog=$event}}}),_c('ExportItemsDialog',{attrs:{"open":_vm.openExportItemsDialog},on:{"update:open":function($event){_vm.openExportItemsDialog=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }