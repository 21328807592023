var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"vessel-calls-table",attrs:{"footer-props":{
      showFirstLastPage: true,
      firstIcon: 'mdi-page-first',
      lastIcon: 'mdi-page-last',
      'items-per-page-options': [5, 10, 25, 50],
    },"loading":_vm.loading,"search":_vm.search,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"headers":_vm.headers,"items":_vm.items,"server-items-length":_vm.totalItemCount,"item-key":"id","fixed-header":"","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"must-sort":""},on:{"update:page":function($event){_vm.page=$event},"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event},"update:options":function($event){return _vm.handleOptionsUpdate('options', $event)},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{attrs:{"single-line":"","label":_vm.$t('vessel_calls.search'),"prepend-inner-icon":"mdi-magnify","outlined":""},on:{"input":_vm.filterItems},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.eta",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDatetimeWithoutTimezone")(item.eta))+" ")]}},{key:"item.etd",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDatetimeWithoutTimezone")(item.etd))+" ")]}},{key:"item.updated_at",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDatetime")(item.updated_at))+" ")]}},{key:"item.number_of_passengers",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"pa-0",attrs:{"color":"blue","text":"","plain":"","title":_vm.$t('vessel_calls.view_vessel_call_passengers')},on:{"click":function($event){$event.stopPropagation();return _vm.viewVesselCallPassengers(item)}}},'v-btn',attrs,false),on),[_c('b',[_vm._v(_vm._s(item.number_of_passengers || "N/D"))])])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("vessel_calls.view_vessel_call_passengers")))])])]}},{key:"footer.prepend",fn:function(){return [_c('v-spacer')]},proxy:true}])}),_c('PassengersList',{attrs:{"open":_vm.openPassengersList,"item":_vm.selectedItem},on:{"update:open":function($event){_vm.openPassengersList=$event},"update:item":function($event){_vm.selectedItem=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }