import { zonesService } from "@/api_services/zones.service";

const DEFAULT_STATE = {
  all: [],
  loading: false,
  paginatedZones: [],
  total: 0,
  centroid: null,
  options: {
    search: "",
    page: 1,
    itemsPerPage: 10,
    sortBy: "code",
    sortDesc: false,
  },
  selectedZoneId: null,
};

export default {
  namespaced: true,
  state: Object.assign({}, JSON.parse(JSON.stringify(DEFAULT_STATE))),
  getters: {},
  mutations: {
    setAll(state, zones) {
      state.all = zones;
    },
    setOptions(state, options) {
      // Merge the existing options with the new options
      state.options = { ...state.options, ...options };
    },
    setLoading(state, loading) {
      state.loading = loading;
    },
    setPaginatedZones(state, paginatedZones) {
      state.paginatedZones = paginatedZones;
    },
    setTotal(state, total) {
      state.total = total;
    },
    setCentroid(state, centroid) {
      state.centroid = centroid;
    },
    setDefaultState(state) {
      Object.assign(state, JSON.parse(JSON.stringify(DEFAULT_STATE)));
    },
  },
  actions: {

    GET_ALL: (context) => {
      return new Promise((resolve, reject) => {
        context.commit("setLoading", true);
        context.commit("sectors/setSectors", [], { root: true }); // clear sectors because they depend on them
        zonesService
          .getAllZones()
          .then((res) => {
            resolve();
            context.commit("setLoading", false);
            context.commit("setAll", res.data);            
          })
          .catch((e) => {
            context.commit("setLoading", false);
            console.error(e);
            reject(e);
          });
      });
    },

    GET_ALL_PAGINATED: (context, options) => {
      context.commit("setLoading", true);
      context.commit("setPaginatedZones", []);
      return new Promise((resolve, reject) => {
        if (options) {
          context.commit("setOptions", options);
        }
        zonesService
          .getZones(context.state.options, context.state.selectedZoneId)
          .then((res) => {
            context.commit("setPaginatedZones", res.data.data);
            context.commit("setTotal", res.data.total);
            context.commit("setLoading", false);
            resolve();
          })
          .catch((e) => {
            console.error(e);
            context.commit("setLoading", false);
            reject(e);
          });
      });
    },

    IMPORT: (context, payload) => {
      return new Promise((resolve, reject) => {
        zonesService
          .importZones(payload)
          .then((res) => {
            resolve(res.data);
            context.dispatch("GET_ALL_PAGINATED", context.state.options);
            context.dispatch("GET_ALL");
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    UPDATE: (context, zone) => {
      return new Promise((resolve, reject) => {
        zonesService
          .updateZone(zone)
          .then((res) => {
            context.dispatch("GET_ALL_PAGINATED", context.state.options);
            context.dispatch("GET_ALL");
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    DELETE: (context, zone) => {
      return new Promise((resolve, reject) => {
        zonesService
          .deleteZone(zone)
          .then((res) => {
            resolve(res.data);
            context.dispatch("GET_ALL_PAGINATED", {
              search: "",
              page: 1,
              itemsPerPage: 10,
              sortBy: "code",
              sortDesc: false,
            });
            context.dispatch("GET_ALL");
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    CENTROID: (context, zone) => {
      return new Promise((resolve, reject) => {
        zonesService
          .getZoneCentroid(zone)
          .then((res) => {
            context.commit("setCentroid", {
              long: parseFloat(res.data[0].centroid_x),
              lat: parseFloat(res.data[0].centroid_y),
            });
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },
    
    RESET: (context) => {
      context.commit("setDefaultState");
    },
  },
};
