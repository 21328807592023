<template>
  <div class="v-application--wrap">
    <v-main class="amber lighten-3">
      <transition name="fade-transform" mode="out-in">
        <v-container class="fill-height pa-0 text-center" fluid>
          <v-row class="align-center">
            <v-col>
              <p class="text-h4 font-weight-black">{{ $t('global.page_access_denied') }}</p>
              <p>{{ $t('global.page_access_denied_explanation') }}</p>
              <v-btn large outlined class="black--text" to="/login"><v-icon left>mdi-home</v-icon>{{ $t('global.back_home') }}</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </transition>
    </v-main>
  </div>
</template>
<script>
export default {};
</script>
