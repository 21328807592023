import configs from "@/helpers/configs";
import axios from "axios";
import { sharedHelpers } from "@/api_services/utils/shared-helpers";

export const vehicleTypesService = {
  getVehicleTypes,
  getVehicleType,
  createVehicleType,
  updateVehicleType,
  deleteVehicleType,
};

async function getVehicleTypes(options) {
  let sort = options.sortDesc ? "DESC" : "ASC";
  const requestOptions = {
    method: "GET",
    headers: sharedHelpers.getRequestHeaders(),
    params: {
      itemsPerPage: options.itemsPerPage,
      currentPage: options.page,
      sortBy: options.sortBy,
      sort: sort,
      search: options.search,
    },
  };
  return await axios.get(
    configs.getApiUrl() + "/v1/vehicle_types",
    requestOptions
  );
}

async function getVehicleType(vehicleTypeId) {
  const requestOptions = {
    method: "GET",
    headers: sharedHelpers.getRequestHeaders()
  };
  return await axios.get(
    configs.getApiUrl() + "/v1/vehicle_types/" + vehicleTypeId,
    requestOptions
  );
}

async function createVehicleType(vehicleType) {
  const requestOptions = {
    method: "POST",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.post(
    configs.getApiUrl() + "/v1/vehicle_types",
    vehicleType,
    requestOptions
  );
}

async function updateVehicleType(vehicleType) {
  const requestOptions = {
    method: "POST",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.post(
    configs.getApiUrl() + "/v1/vehicle_types/" + vehicleType.id,
    vehicleType,
    requestOptions
  );
}

async function deleteVehicleType(vehicleId) {
  const requestOptions = {
    method: "POST",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.delete(
    configs.getApiUrl() + "/v1/vehicle_types/" + vehicleId,
    requestOptions
  );
}
