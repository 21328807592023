<template>
  <v-dialog
    v-model="isOpen"
    persistent
    width="50%"
    content-class="custom-dialog"
  >
    <v-card tile>
      <v-progress-linear
        height="10"
        striped
        color="primary"
        indeterminate
        v-show="loading"
      ></v-progress-linear>
      <v-toolbar color="primary" flat>
        <v-toolbar-title>
          <span>
            <v-icon left>mdi-boom-gate-alert</v-icon>{{ item.code }}
          </span>
        </v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text
        class="pa-2"
        style="overflow-y: auto; height: calc(100vh - 270px)"
      >
        <v-card outlined>
          <div class="grey lighten-4 text-center pa-3">
            <span class="black--text font-weight-bold text-uppercase"
              >Pedido de Entrada</span
            >
          </div>
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr>
                  <td class="font-weight-bold">
                    {{ $t("entries.requester_name") }}
                  </td>
                  <td class="text-right">
                    {{ item.creator }}
                  </td>
                </tr>
                <tr>
                  <td class="font-weight-bold">
                    {{ $t("entries.code") }}
                  </td>
                  <td class="text-right">{{ item.code }}</td>
                </tr>
                <tr>
                  <td class="font-weight-bold">
                    {{ $t("entries.responsible_name") }}
                  </td>
                  <td class="text-right">
                    {{ item.responsible_name }}
                  </td>
                </tr>
                <tr>
                  <td class="font-weight-bold">
                    {{ $t("entries.vehicle_at_pier_date_request") }}
                  </td>
                  <td class="text-right">
                    {{
                      item.vehicle_at_pier_date
                        | formatDatetimeFromDatabaseOnlyTime
                    }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>

        <v-card outlined class="mt-2">
          <div class="grey lighten-4 text-center pa-3">
            <span class="black--text font-weight-bold text-uppercase"
              >Resposta ao Pedido</span
            >
          </div>
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr>
                  <td class="font-weight-bold">{{ $t("entries.status") }}</td>
                  <td class="text-right">
                    <v-chip
                      small
                      label
                      :color="getStatusColor(item.status)"
                      dark
                      class="text-uppercase"
                    >
                      {{ getStatusLabel(item.status) }}
                    </v-chip>
                  </td>
                </tr>
                <tr v-if="item.status != 'REJECTED'">
                  <td class="font-weight-bold">{{ $t("entries.sector") }}</td>
                  <td class="text-right">
                    {{ sector ? sector.name : "N/D" }}
                  </td>
                </tr>
                <tr v-if="item.status != 'REJECTED'">
                  <td class="font-weight-bold">{{ $t("entries.zone") }}</td>
                  <td class="text-right">
                    {{ zone ? zone.park + " - " + zone.code : "N/D" }}
                  </td>
                </tr>
                <tr v-if="item.status != 'REJECTED'">
                  <td class="font-weight-bold">
                    {{ $t("entries.vehicle_at_pier_start") }}
                  </td>
                  <td class="text-right">
                    {{
                      item.vehicle_at_pier_start_time
                        ? item.vehicle_at_pier_start_time
                        : "N/D"
                    }}
                  </td>
                </tr>
                <tr v-if="item.status != 'REJECTED'">
                  <td class="font-weight-bold">
                    {{ $t("entries.vehicle_at_pier_end") }}
                  </td>
                  <td class="text-right">
                    {{
                      item.vehicle_at_pier_end_time
                        ? item.vehicle_at_pier_end_time
                        : "N/D"
                    }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>

        <v-card outlined class="mt-2">
          <div class="grey lighten-4 text-center pa-3">
            <span class="black--text font-weight-bold text-uppercase"
              >Veículo</span
            >
          </div>
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr>
                  <td class="font-weight-bold">
                    {{ $t("vehicles.registration_plate") }}
                  </td>
                  <td class="registration-plate-font text-right">
                    {{ item.vehicle.registration_plate }}
                  </td>
                </tr>
                <tr>
                  <td class="font-weight-bold">{{ $t("vehicles.type") }}</td>
                  <td class="text-right">
                    {{ item.vehicle.vehicle_type.name }}
                  </td>
                </tr>
                <tr>
                  <td class="font-weight-bold">{{ $t("vehicles.brand") }}</td>
                  <td class="text-right">{{ item.vehicle.brand }}</td>
                </tr>
                <tr>
                  <td class="font-weight-bold">{{ $t("vehicles.model") }}</td>
                  <td class="text-right">{{ item.vehicle.model }}</td>
                </tr>
                <tr>
                  <td class="font-weight-bold">
                    {{ $t("vehicles.seating_capacity") }}
                  </td>
                  <td class="text-right">
                    {{ item.vehicle.seating_capacity }}
                  </td>
                </tr>
                <tr>
                  <td class="font-weight-bold">
                    {{ $t("vehicles.contact_phone") }}
                  </td>
                  <td class="text-right">
                    {{ item.vehicle.phone }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>

        <v-card outlined class="mt-2">
          <div class="grey lighten-4 text-center pa-3">
            <span class="black--text font-weight-bold text-uppercase"
              >Operador</span
            >
          </div>
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr>
                  <td class="font-weight-bold">{{ $t("operators.name") }}</td>
                  <td class="text-right">{{ item.vehicle.operator.name }}</td>
                </tr>
                <tr>
                  <td class="font-weight-bold">
                    {{ $t("operators.phone") }}
                  </td>
                  <td class="text-right">{{ item.vehicle.operator.phone }}</td>
                </tr>
                <tr>
                  <td class="font-weight-bold">
                    {{ $t("operators.email") }}
                  </td>
                  <td class="text-right">{{ item.vehicle.operator.email }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>

        <v-card outlined class="mt-2">
          <div class="grey lighten-4 text-center pa-3">
            <span class="black--text font-weight-bold text-uppercase"
              >Navio</span
            >
          </div>
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr>
                  <td class="font-weight-bold">
                    {{ $t("vessel_calls.name") }}
                  </td>
                  <td class="text-right">{{ item.vesselcall.name }}</td>
                </tr>
                <tr>
                  <td class="font-weight-bold">
                    {{ $t("vessel_calls.process_number") }}
                  </td>
                  <td class="text-right">
                    {{ item.vesselcall.process_number }}
                  </td>
                </tr>
                <tr>
                  <td class="font-weight-bold">{{ $t("vessel_calls.eta") }}</td>
                  <td class="text-right">
                    {{ item.vesselcall.eta | formatDatetimeWithoutTimezone }}
                  </td>
                </tr>
                <tr>
                  <td class="font-weight-bold">{{ $t("vessel_calls.etd") }}</td>
                  <td class="text-right">
                    {{ item.vesselcall.etd | formatDatetimeWithoutTimezone }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import configs from "@/helpers/configs";
export default {
  props: ["open", "item"],
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    isOpen: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("update:open", val);
      },
    },
    sectors() {
      return this.$store.state.sectors.all;
    },
    zones() {
      return this.$store.state.zones.all;
    },
    zone() {
      const zones = this.zones.filter((zone) => zone.id == this.item.zone_id);
      let [firstZone] = zones;
      return firstZone;
    },
    sector() {
      const sectors = this.sectors.filter(
        (sector) => sector.id == this.item.sector_id
      );
      let [firstSector] = sectors;
      return firstSector;
    },
  },
  methods: {
    formatDatetimeFromDatabaseOnlyTime(item) {
      return this.$options.filters.formatDatetimeFromDatabaseOnlyTime(item);
    },
    close() {
      this.isOpen = false;
      this.loading = false;
    },
    getStatusLabel(status) {
      return configs.getStatusLabel(status);
    },
    getStatusColor(status) {
      return configs.getStatusColor(status);
    },
  },
};
</script>
<style>
.custom-dialog {
  overflow-y: hidden !important;
}
</style>

<style scoped>
.registration-plate-font {
  font-family: "Fira Mono", monospace !important;
  font-weight: 700 !important;
}
</style>
