<template>
  <Profile></Profile>
</template>
<script>

import Profile from "@/components/Configurations/Profile";

export default {
  components: {
    Profile,
  },
};
</script>