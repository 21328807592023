import * as maptalks from "maptalks";
import * as turf from "@turf/turf";

const SECTORS_HIGHLIGHT_SYMBOL = {
  lineOpacity: 0.8,
  lineWidth: 3,
  lineDasharray: [5],
  lineColor: "rgb(215, 186, 32)",
  polygonOpacity: 0.2,
};

const SECTORS_BASE_SYMBOL = {
  lineColor: {
    property: "color",
    type: "identity",
  },
  lineWidth: 1,
  lineDasharray: [5],
  lineOpacity: 0.3,
  polygonFill: {
    property: "color",
    type: "identity",
  },
  polygonOpacity: 0.1,
  textFaceName: "Roboto",
  textName: "{name}\n", //value from name in geometry's properties
  textWeight: "bold", //'bold', 'bold'
  textStyle: "normal", //'italic', 'oblique'
  textSize: {
    stops: [
      [15, 0],
      [18, 20],
      [20, 30],
      [22, 40],
    ],
  },
  textOpacity: 0.3,
  textLineSpacing: 0,
  textDx: 0,
  textDy: 0,
  textHorizontalAlignment: "middle", //left | middle | right | auto
  textVerticalAlignment: "middle", // top | middle | bottom | auto
  textAlign: "center", //left | right | center | auto,
  textFill: "#34495e",
  textHaloRadius: 1,
};

export default class Sector extends maptalks.GeometryCollection {
  constructor(spec, properties) {
    super([], {}); // geometries, opts    this.baseSymbol = JSON.parse(JSON.stringify(SECTORS_BASE_SYMBOL));
        
    this.properties = properties;

    if (!this.properties) {
      this.properties = {};
    }

    this.sectorSkeleton = null;
    if (spec && spec.zones && spec.zones.length > 0) {
      let polygons = [];
      spec.zones.forEach((zone) => {
        let geojson = JSON.parse(zone.geojson);
        let polygon = turf.polygon(geojson.coordinates);
        polygons.push(polygon);
      });
      let collection = turf.featureCollection(polygons);
      let hull = turf.convex(collection);
      hull = turf.buffer(hull, 0.0005, { units: "kilometers" });
      this.sectorSkeleton = maptalks.GeoJSON.toGeometry(hull);
      this.sectorSkeleton.setSymbol(this.baseSymbol);
      this.sectorSkeleton.setProperties(properties);
      this.setProperties(properties);
      this.setGeometries([this.sectorSkeleton]);
    } else {
      this.setProperties(properties);
      this.setGeometries([]);
    }
  }
  
  deHighlight() {
    if (this.sectorSkeleton) {
      this.sectorSkeleton.updateSymbol(this.baseSymbol);
    }
  }

  highlight() {
    if (this.sectorSkeleton) {
      this.sectorSkeleton.updateSymbol(SECTORS_HIGHLIGHT_SYMBOL);
    }
  }
}
