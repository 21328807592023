<template>
  <div>
    <v-data-iterator
      :items="items"
      @update:options="handleOptionsUpdate('options', $event)"
      :page.sync="options.page"
      :items-per-page.sync="options.itemsPerPage"
      :loading="loading"
      :server-items-length.sync="totalItemCount"
      hide-default-footer
    >
      <template v-slot:header>
        <v-layout row wrap class="pa-3">
          <v-flex xs12>
            <v-text-field
              ref="searchField"
              v-model="options.search"
              hide-details
              clearable
              flat
              solo
              :label="$t('global.search')"
              prepend-inner-icon="mdi-magnify"
              outlined
              @input="filterItems"
              :disabled="loading"
              large
            >
            </v-text-field></v-flex
        ></v-layout>
      </template>
      <template v-slot:no-results>
        <v-container fill-height fluid>
          <v-row
            align="center"
            justify="center"
            class="text-h6 text-center pa-10"
          >
            <v-icon left>mdi-car-off</v-icon
            >{{ $t("access_control_page.not_found") }}
          </v-row></v-container
        >
      </template>
      <template v-slot:no-data>
        <v-container fill-height fluid>
          <v-row
            align="center"
            justify="center"
            class="text-h6 text-center pa-10"
          >
            <v-icon left>mdi-car-off</v-icon
            >{{ $t("access_control_page.not_found") }}
          </v-row></v-container
        >
      </template>
      <template v-slot:loading>
        <div style="position: relative; min-height: 250px; margin-top: 20px">
          <Loading :value="loading"></Loading>
        </div>
      </template>
      <template v-slot:default="{ items }">
        <v-row class="px-0">
          <v-col
            v-for="(item, index) in items"
            :key="index"
            cols="12"
            sm="6"
            md="4"
            lg="3"
          >
            <VehicleEntryCard
              :item="item"
              :class="
                selectedItem && selectedItem.id == item.id
                  ? 'entry-selected'
                  : ''
              "
              @selected="handleSelectedItem"
            ></VehicleEntryCard>
          </v-col>
        </v-row>
      </template>
    </v-data-iterator>

    <v-row class="text-center px-4 align-center" wrap>
      <v-col cols="12" v-if="options && !loading && pageCount != 1">
        <v-pagination v-model="options.page" dense flat :length="pageCount">
        </v-pagination>
      </v-col>
    </v-row>

    <v-dialog
      v-model="openRegisterAccess"
      persistent
      :width="$vuetify.breakpoint.mobile ? '90vw' : '40vw'"
    >
      <v-card tile>
        <v-toolbar :color="this.accessType == 'EXIT' ? 'red' : 'green'" flat>
          <v-toolbar-title class="white--text">
            <span>
              <v-icon left class="white--text">{{
                this.accessType == "EXIT"
                  ? "mdi-location-exit"
                  : "mdi-location-enter"
              }}</v-icon
              >{{
                this.accessType == "EXIT"
                  ? "Registar saída"
                  : "Registar entrada"
              }}
            </span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="openRegisterAccess = false" class="white--text">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-5">
          <v-alert color="blue-grey" text icon="mdi-information-outline">
            {{ $t("scheduled_entry_scans.notes_alert") }}
          </v-alert>
          <v-textarea
            outlined
            name="input-7-4"
            :label="$t('global.notes')"
            persistent-placeholder
            :placeholder="$t('scheduled_entry_scans.write_note_here')"
            no-resize
            rows="5"
            hide-details
            v-model="notes"
          ></v-textarea>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            class="white--text"
            :color="this.accessType == 'EXIT' ? 'red' : 'green'"
            depressed
            block
            @click="save"
          >
            {{
              this.accessType == "EXIT" ? "Registar saída" : "Registar entrada"
            }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <GrantEntryOptions
      v-model="showEntryActions"
      :item.sync="selectedItem"
      @open_entry_details="handleOpenEntryDetails"
      @open_noshorex_entry_details="handleOpenNoShorexEntryDetails"
      @register_entry="handleRegisterEntry"
      @register_entry_no_shorex="handleRegisterEntryNoShorex"
      @register_exit="handleRegisterExit"
      @register_exit_no_shorex="handleRegisterExitNoShorex"
      :loading.sync="loadingEntryActions"
    ></GrantEntryOptions>

    <VehicleEntryDetails
      v-model="showEntryRequestDetails"
      :item="selectedItem"
    ></VehicleEntryDetails>

    <VehicleNoShorexEntryDetails
      v-model="showNoShorexEntryDetails"
      :item="selectedItem"
    ></VehicleNoShorexEntryDetails>
  </div>
</template>
<script>
import Loading from "@/components/Main/Loading";
import { debounce } from "debounce";
import VehicleEntryCard from "@/components/Main/GrantEntries/VehicleEntryCard";
import GrantEntryOptions from "@/components/Main/GrantEntries/GrantEntryOptions";
import VehicleEntryDetails from "@/components/Main/GrantEntries/VehicleEntryDetails";
import VehicleNoShorexEntryDetails from "@/components/Main/GrantEntries/VehicleNoShorexEntryDetails";

export default {
  props: [],
  components: {
    Loading,
    VehicleEntryCard,
    GrantEntryOptions,
    VehicleEntryDetails,
    VehicleNoShorexEntryDetails,
  },
  computed: {
    options: {
      cache: false,
      get() {
        return this.$store.state.scheduled_entries.options;
      },
      set(val) {
        this.$store.dispatch("scheduled_entries/UPDATE_OPTIONS", val);
      },
    },

    totalItemCount() {
      return this.$store.state.scheduled_entries.total;
    },

    pageCount() {
      return this.totalItemCount < this.options.itemsPerPage
        ? 1
        : Math.ceil(this.totalItemCount / this.options.itemsPerPage);
    },
  },
  data() {
    return {
      loading: false,
      items: [],
      selectedItem: null,
      showEntryActions: false,
      showEntryRequestDetails: false,
      showNoShorexEntryDetails: false,
      loadingEntryActions: false,
      openRegisterAccess: false,
      notes: "",
      shorexEntry: false,
      accessType: "",
    };
  },
  mounted() {
    this.notes = "";
    this.$store.dispatch("scheduled_entries/RESET");
  },
  methods: {
    handleOptionsUpdate: debounce(function (_, value) {
      this.loadEntries();
    }, 500),
    filterItems: debounce(function () {
      // Changes the current page to the first one to reset the search stop bugs from happening
      if (this.options.page == 1) {
        this.loadEntries();
      } else {
        this.options.page = 1;
      }

      // Wait for the list to reload and refocus on the text field
      setTimeout(() => {
        this.$refs.searchField.focus();
      }, 500);
    }, 1000),
    loadEntries() {
      // Prevent multiple loads
      if (this.loading) {
        return;
      }
      this.loading = true;
      this.items = [];
      let newOptions = JSON.parse(JSON.stringify(this.options));

      this.$store
        .dispatch("scheduled_entries/GET_ALL_PAGINATED", newOptions)
        .then((response) => {
          this.items = response.data;
          this.loading = false;
        })
        .catch((ex) => {
          console.error("Error loading scheduled entries", ex);
          this.loading = false;
          this.items = [];
        });
    },

    handleSelectedItem(item) {
      this.selectedItem = item;
      this.showEntryActions = true;
    },

    handleOpenEntryDetails() {
      this.showEntryRequestDetails = true;
    },
    handleOpenNoShorexEntryDetails() {
      this.showNoShorexEntryDetails = true;
    },

    handleRegisterEntry() {
      this.shorexEntry = true;
      this.accessType = "ENTRY";
      this.openRegisterAccess = true;
    },
    handleRegisterEntryNoShorex() {
      this.shorexEntry = false;
      this.accessType = "ENTRY";
      this.openRegisterAccess = true;
    },
    handleRegisterExit() {
      this.shorexEntry = true;
      this.accessType = "EXIT";
      this.openRegisterAccess = true;
    },
    handleRegisterExitNoShorex() {
      this.shorexEntry = false;
      this.accessType = "EXIT";
      this.openRegisterAccess = true;
    },
    handleRegisterExitNoShorex() {
      this.shorexEntry = false;
      this.accessType = "EXIT";
      this.openRegisterAccess = true;
    },
    save() {
      this.openRegisterAccess = false;

      if (this.shorexEntry) {
        this.accessType == "ENTRY"
          ? this.registerEntryShorex()
          : this.registerExitShorex();
      } else {
        this.accessType == "ENTRY"
          ? this.registerEntryNoShorex()
          : this.registerExitNoShorex();
      }
    },

    registerExitNoShorex() {
      this.loadingEntryActions = true;
      this.$store
        .dispatch("noshorex_entries/REGISTER_NOSHOREX_EXIT_SCAN", {
          id: this.selectedItem.id,
          notes: this.notes,
        })
        .then(() => {
          this.loadingEntryActions = false;
          this.showEntryActions = false;
          this.notes = "";
          this.loadEntries();
        })
        .catch((ex) => {
          this.loadingEntryActions = false;
          this.showEntryActions = false;
          this.notes = "";
          this.loadEntries();
        });
    },
    registerExitShorex() {
      this.loadingEntryActions = true;
      this.$store
        .dispatch("scheduled_entries/REGISTER_EXIT_SCAN", {
          scheduled_entry_id: this.selectedItem.id,
          notes: this.notes,
        })
        .then(() => {
          this.loadingEntryActions = false;
          this.showEntryActions = false;
          this.notes = "";
          this.loadEntries();
        })
        .catch((ex) => {
          this.loadingEntryActions = false;
          this.showEntryActions = false;
          this.notes = "";
          this.loadEntries();
        });
    },
    registerEntryNoShorex() {
      this.loadingEntryActions = true;
      this.$store
        .dispatch("noshorex_entries/REGISTER_NOSHOREX_ENTRY_SCAN", {
          id: this.selectedItem.id,
          notes: this.notes,
        })
        .then(() => {
          this.loadingEntryActions = false;
          this.showEntryActions = false;
          this.notes = "";
          this.loadEntries();
        })
        .catch((ex) => {
          this.loadingEntryActions = false;
          this.showEntryActions = false;
          this.notes = "";
          this.loadEntries();
        });
    },

    registerEntryShorex() {
      this.loadingEntryActions = true;
      this.$store
        .dispatch("scheduled_entries/REGISTER_ENTRY_SCAN", {
          scheduled_entry_id: this.selectedItem.id,
          notes: this.notes,
        })
        .then(() => {
          this.loadingEntryActions = false;
          this.showEntryActions = false;
          this.notes = "";
          this.loadEntries();
        })
        .catch((ex) => {
          this.loadingEntryActions = false;
          this.showEntryActions = false;
          this.notes = "";
          this.loadEntries();
        });
    },
  },
};
</script>
<style>
.v-pagination__item,
.v-pagination__navigation {
  position: static !important;
  border: 1px solid darkgrey;
  box-shadow: none;
  border-radius: 4px;
}

.v-input.v-text-field.v-textarea .v-text-field__slot {
  padding-right: 5px;
  padding-bottom: 5px;
}
</style>
