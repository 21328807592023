<template>
  <VesselCalls />
</template>

<script>
import VesselCalls from "@/components/Configurations/VesselCalls.vue";

export default {
  components: {
    VesselCalls,
  },
};
</script>
