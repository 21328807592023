import { sectorsService } from "@/api_services/sectors.service";

export default {
  namespaced: true,
  state: {
    all: [],
    loading: false,
  },
  getters: {},
  mutations: {
    setSectors(state, items) {
      state.all = items;
    },
    setLoading(state, isLoading) {
      state.loading = isLoading;
    },
  },
  actions: {
    GET_ALL: (context, planid) => {
      return new Promise((resolve, reject) => {
        context.commit("setLoading", true);
        sectorsService
          .getSectors(planid)
          .then((res) => {
            context.commit("setSectors", res.data);
            context.commit("setLoading", false);
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            context.commit("setLoading", false);
            reject(e);
          });
      });
    },

    CREATE: (context, sector) => {
      return new Promise((resolve, reject) => {
        sectorsService
          .createSector(sector.plan_id, sector)
          .then((res) => {
            resolve(res.data);
            context.dispatch("GET_ALL", sector.plan_id);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    UPDATE: (context, sector) => {
      return new Promise((resolve, reject) => {
        sectorsService
          .updateSector(sector.plan_id, sector)
          .then((res) => {
            resolve(res.data);
            context.dispatch("GET_ALL", sector.plan_id);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    UPDATE_ZONES: (context, sector) => {
      return new Promise((resolve, reject) => {
        sectorsService
          .updateSectorZones(sector.plan_id, sector)
          .then((res) => {
            resolve(res.data);
            context.dispatch("GET_ALL", sector.plan_id);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    DELETE: (context, sector) => {
      return new Promise((resolve, reject) => {
        sectorsService
          .deleteSector(sector.plan_id, sector.id)
          .then((res) => {
            resolve(res.data);
            context.dispatch("GET_ALL", sector.plan_id);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    GET_SECTOR_BY_ID: (_, sector) => {
      return new Promise((resolve, reject) => {
        sectorsService
          .getSector(sector.plan_id, sector.id)
          .then((res) => {
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    COPY_SECTORS: (context, payload) => {
      return new Promise((resolve, reject) => {
        sectorsService
          .copySectors(payload)
          .then((res) => {
            resolve(res.data);
            context.dispatch("GET_ALL", payload.toPlanId);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    GET_BY_DAY: (_, day) => {
      return new Promise((resolve, reject) => {
        sectorsService
          .getSectorsByDay(day)
          .then((res) => {
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },
  },
};
