<template>
  <v-snackbar
    v-model="isChangingSector"
    timeout="-1"
    top
    app
    multi-line
    vertical
    dark
  >
    <div v-if="!!selectedSector">
      <p class="font-weight-black text-uppercase">
        <v-avatar
          size="20"
          tile
          :color="selectedSector.color"
          style="border: 1px solid #ccc"
          class="mr-2"
        ></v-avatar>
        {{ selectedSector.name }}
      </p>
      <p>{{ $t("sectors.select_or_deselect_zones") }}</p>
    </div>

    <template v-slot:action="{ attrs }">
      <v-layout justify-center>
        <v-btn
          small
          v-bind="attrs"
          @click="cancelSectorChanges"
          :disabled="isSavingSector"
          outlined
        >
          <v-icon small left>mdi-close</v-icon>{{ $t("global.cancel") }}
        </v-btn>
        <v-btn
          small
          class="ml-2"
          color="primary"
          outlined
          v-bind="attrs"
          @click="saveSectorChanges"
          :disabled="isSavingSector"
          :loading="isSavingSector"
        >
          <v-icon small left>mdi-content-save</v-icon>{{ $t("global.save") }}
        </v-btn>
      </v-layout>
    </template>
  </v-snackbar>
</template>
<script>
export default {
  props: ["plan", "sector", "mode", "zones"],
  data() {
    return {
      isSavingSector: false,
    };
  },
  computed: {
    mapMode: {
      get() {
        return this.mode;
      },
      set(val) {
        this.$emit("update:mode", val);
      },
    },
    isChangingSector: {
      get() {
        return this.mapMode == "CHANGE_SECTOR" && this.selectedSector;
      },
      set(val) {
        if (val) {
          this.mapMode = "CHANGE_SECTOR";
        } else {
          this.mapMode = "VIEW_PLAN";
        }
      },
    },
    selectedSector: {
      get() {
        return this.sector;
      },
      set(val) {
        this.$emit("update:sector", val);
      },
    },
    selectedZones() {
      return this.zones;
    },
  },
  methods: {
    saveSectorChanges() {
      this.isSavingSector = true;
      let sectorToUpdate = {
        id: this.selectedSector.id,
        plan_id: this.selectedSector.plan_id,
        zones: this.selectedZones,
      };
      this.$store
        .dispatch("sectors/UPDATE_ZONES", sectorToUpdate)
        .then(() => {
          this.isChangingSector = false;
          this.isSavingSector = false;
        })
        .catch((ex) => {
          console.error(ex);
          this.isSavingSector = false;
        });
    },
    cancelSectorChanges() {
      this.isChangingSector = false;
      this.$store.dispatch("sectors/GET_ALL", this.plan.id);
    },
  },
};
</script>
