import Vue from "vue";
import { configurationsService } from "@/api_services/configurations.service";

let initialState = { list: [] };

export default {
  state: initialState,
  getters: {},
  mutations: {
    setConfigurations(state, configurations) {
      state.list = configurations;
    },
    resetState(state) {
      for (let f in state) {
        Vue.set(state, f, initialState[f]);
      }
    },
  },
  actions: {
    GET_CONFIGURATIONS: (context) => {
      return new Promise((resolve, reject) => {
        configurationsService
          .getConfigurations()
          .then((res) => {
            context.commit("setConfigurations", res.data);
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },
    SET_CONFIGURATIONS: (context, config) => {
      return new Promise((resolve, reject) => {
        configurationsService
          .setConfigurations(config)
          .then((res) => {
            context.commit("setConfigurations", res.data);
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },
    RESET_STATE_CONFIGURATIONS: (context) => {
      context.commit("resetState");
    },
  },
};
