import configs from "@/helpers/configs";
import axios from "axios";
import { sharedHelpers } from "@/api_services/utils/shared-helpers";

export const statsService = {
  getStats,
  getNotifications,
};

async function getStats(payload) {
  const requestOptions = {
    method: "GET",
    headers: sharedHelpers.getRequestHeaders(),
    params: {
      ...payload,
    },
  };
  return await axios.get(configs.getApiUrl() + "/v1/stats", requestOptions);
}

async function getNotifications(payload) {
  const requestOptions = {
    method: "GET",
    headers: sharedHelpers.getRequestHeaders(),
    params: {
      ...payload,
    },
  };
  return await axios.get(configs.getApiUrl() + "/v1/stats/notifications", requestOptions);
}

