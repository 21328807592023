<template>
  <v-card tile outlined>
    <v-card-title
      ><slot name="title"></slot></v-card-title
    >
    <v-card-subtitle><slot name="subtitle"></slot></v-card-subtitle>
    <v-divider></v-divider>
    <v-card-text style="position: relative;">
      <Loading v-if="loading" :value="loading"></Loading>
      <v-simple-table dense fixed-header height="30vh"  v-else>
        <template v-slot:default>
          <thead>
            <tr>
              <th v-if="withErrorDescription" class="text-left" style="width:200px">{{ $t('global.error_description') }}</th>
              <th class="text-left">Navio</th>
              <th class="text-left">Dia (dd/mm/aaaa)</th>
              <th class="text-left">Tipo de entrada</th>
              <th class="text-left">Operador</th>
              <th class="text-left">Matrícula / Número de porta</th>
              <th class="text-left">Hora (hh:mm)</th>
              <th class="text-left">Número de passageiros</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in items" :key="i" :class="withErrorDescription ? 'error-line' : ''">
              <td v-if="withErrorDescription">{{ item["message"] }}</td>
              <td>{{ item["Navio"] }}</td>
              <td>{{ item["Dia (dd/mm/aaaa)"] }}</td>
              <td>{{ item["Tipo de entrada"] }}</td>
              <td>{{ item["Operador"] }}</td>
              <td>{{ item["Matrícula / Número de porta"] }}</td>
              <td>{{ item["Hora (hh:mm)"] }}</td>
              <td>{{ item["Número de passageiros"] }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>
<script>
import Loading from "@/components/Main/Loading";
export default {
  props: ["items", "loading", "withErrorDescription"],
  components: { Loading },
};
</script>
<style scoped>
.error-line
{
  color: red;
}
</style>