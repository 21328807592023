<template>
  <div class="ma-4">
    <div class="d-flex flex-wrap">
      <div class="ma-0 pa-0">
        <p class="text-h5 font-weight-bold mb-0">
          {{ $t("entries.view_exits") }}
        </p>
        <p class="text-caption">
          {{ $t("entries.view_exits_help_list") }}
        </p>
      </div>
      <v-spacer></v-spacer>
      <v-btn
          color="primary"
          @click="exportVehicles"
          depressed
          :block="$vuetify.breakpoint.mobile"
          :class="$vuetify.breakpoint.mobile ?  'black--text mb-5' : 'black--text'"
          :disabled="isLoading"
        >
          <v-icon left>mdi-download</v-icon>
          {{ $t("global.export") }}
        </v-btn>
    </div>
    <List
      :openExportScheduledEntryScansDialog.sync="
        openExportScheduledEntryScansDialog
      "
      :isLoading.sync="isLoading"
    ></List>
  </div>
</template>

<script>
import List from "@/components/Main/ScheduledExitScans/List";
export default {
  components: { List },
  data() {
    return {
      openExportScheduledEntryScansDialog: false,
      isLoading: false,
    };
  },
  methods: {
    exportVehicles() {
      this.openExportScheduledEntryScansDialog = true;
    },
  },
};
</script>
