export default {
  namespaced: true,
  state: {
    all: [],
  },
  mutations: {
    addOrUpdate(state, item) {
      let foundIndex = state.all.findIndex((el) => {
        return el.id == item.id;
      });
      if (foundIndex !== -1) {
        state.all.splice(foundIndex, 1, item);
      } else {
        state.all.push(item);
      }
    },

    remove(state, item) {
      let foundIndex = state.all.findIndex((el) => {
        return el.id == item.id;
      });
      if (foundIndex !== -1) {
        state.all.splice(foundIndex, 1);
      }
    },
  },

  actions: {
    ADD_OR_UPDATE(context, record) {
      return new Promise((resolve) => {       
        context.commit("addOrUpdate", record);
        resolve();
      });
    },

    REMOVE(context, record) {
      return new Promise((resolve) => {
        context.commit("remove", record);
        resolve();
      });
    },
  },
};
