<template>
  <div
    class="app-install-banner-container d-flex justify-center"
    v-if="shouldShowInstallPrompt"
  >
    <v-banner
      color="info"
      dark
      tile
      class="ma-4"
      :single-line="!$vuetify.breakpoint.mobile"
    >
      <template v-slot:icon>
        <v-avatar color="white">
          <v-img
            src="@/assets/logo.png"
            max-height="30"
            max-width="30"
            contain
          ></v-img>
        </v-avatar>
      </template>
      <template v-slot:actions>
        <v-btn text small @click.stop="dismiss">{{
          $t("global.pwa_dismiss")
        }}</v-btn>
        <v-divider vertical color="white"></v-divider>
        <v-btn text small @click.stop="install">{{ $t("global.pwa_install") }}</v-btn>
      </template>
      <template v-slot:default>
        {{ $t("global.pwa_install_explanation") }}
      </template>
    </v-banner>
  </div>
</template>
<script>
import { sharedHelpers } from "@/api_services/utils/shared-helpers";
export default {
  data() {
    return {
      deferredPrompt: null,
    };
  },
  computed: {
    shouldShowInstallPrompt() {
      return (
        this.deferredPrompt &&
        !sharedHelpers.getStorageItemWithExpiry("dismissed_install_prompt")
      );
    },
  },
  created() {
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      // Stash the event so it can be triggered later.
      this.deferredPrompt = e;
    });
    window.addEventListener("appinstalled", () => {
      this.deferredPrompt = null;
    });
  },
  methods: {
    async dismiss() {
      this.deferredPrompt = null;
      sharedHelpers.setStorageItemWithExpiry(
        "dismissed_install_prompt",
        true,
        8640000 * 15 // 15 days recomended by webdev
      );
    },
    async install() {
      if (this.deferredPrompt) {
        this.deferredPrompt.prompt();
      }
    },
  },
};
</script>
<style scoped>
.app-install-banner-container {
  position: fixed;
  z-index: 3;
  top: var(--toolbar-height);
  width: 100%;
}
</style>
