import configs from "@/helpers/configs";
import axios from "axios";
import { sharedHelpers } from "@/api_services/utils/shared-helpers";

export const vehiclesService = {
  getVehicles,
  getVehicle,
  createVehicle,
  updateVehicle,
  deleteVehicle,
  importVehicles,
  importUserVehicles,
  exportUserVehicles,
  downloadUserVehiclesTemplate,
  createUserVehicle,
  getUserVehicles,
  getUserVehicle,
  exportVehicles,
  cancelVehicle,
  getAvailableVehicles,
  updateUserVehicle,
  getTaxis,
};

async function getVehicles(options) {
  let sort = options.sortDesc ? "DESC" : "ASC";
  const requestOptions = {
    method: "GET",
    headers: sharedHelpers.getRequestHeaders(),
    params: {
      itemsPerPage: options.itemsPerPage,
      currentPage: options.page,
      sortBy: options.sortBy,
      sort: sort,
      search: options.search,
      filterByStatus: options.filterByStatus,
      filterByType: options.filterByType,
    },
  };
  return await axios.get(configs.getApiUrl() + "/v1/vehicles", requestOptions);
}

async function getVehicle(vehicleId) {
  const requestOptions = {
    method: "GET",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.get(
    configs.getApiUrl() + "/v1/vehicles/" + vehicleId,
    requestOptions
  );
}

async function createVehicle(vehicle) {
  const formData = new FormData();
  for (const property in vehicle) {
    if (vehicle.hasOwnProperty(property)) {
      const value = vehicle[property] === null ? "" : vehicle[property];
      formData.append(property, value);
    }
  }
  const requestOptions = {
    method: "POST",
    headers: sharedHelpers.getRequestHeadersFormData(),
  };
  return await axios.post(
    configs.getApiUrl() + "/v1/vehicles",
    formData,
    requestOptions
  );
}

async function updateVehicle(vehicle) {
  const formData = new FormData();
  for (const property in vehicle) {
    if (vehicle.hasOwnProperty(property)) {
      // Check if the property value is null
      const value = vehicle[property] === null ? "" : vehicle[property];
      formData.append(property, value);
    }
  }
  const requestOptions = {
    method: "POST",
    headers: sharedHelpers.getRequestHeadersFormData(),
  };
  return await axios.post(
    configs.getApiUrl() + "/v1/vehicles/" + vehicle.id,
    formData,
    requestOptions
  );
}

async function deleteVehicle(vehicleId) {
  const requestOptions = {
    method: "POST",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.delete(
    configs.getApiUrl() + "/v1/vehicles/" + vehicleId,
    requestOptions
  );
}

async function importVehicles(file) {
  const requestOptions = {
    method: "POST",
    headers: sharedHelpers.getRequestHeaders(),
  };
  var bodyFormData = new FormData();
  bodyFormData.append("file", file);
  return await axios.post(
    configs.getApiUrl() + "/v1/vehicles/import",
    bodyFormData,
    requestOptions
  );
}

async function importUserVehicles(payload) {
  const requestOptions = {
    method: "POST",
    headers: sharedHelpers.getRequestHeaders(),
  };
  var bodyFormData = new FormData();
  for (let key in payload) {
    bodyFormData.append(key, payload[key]);
  }
  return await axios.post(
    configs.getApiUrl() + "/v1/my-shorex/vehicles/import",
    bodyFormData,
    requestOptions
  );
}

async function exportUserVehicles() {
  const requestOptions = {
    method: "POST",
    responseType: "blob",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.post(
    configs.getApiUrl() + "/v1/my-shorex/vehicles/export",
    {},
    requestOptions
  );
}

async function downloadUserVehiclesTemplate() {
  const requestOptions = {
    method: "POST",
    responseType: "blob",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.post(
    configs.getApiUrl() + "/v1/my-shorex/vehicles/download_template",
    {},
    requestOptions
  );
}

async function createUserVehicle(vehicle) {
  const formData = new FormData();
  for (const property in vehicle) {
    if (vehicle.hasOwnProperty(property) && vehicle[property] !== null) {
      formData.append(property, vehicle[property]);
    }
  }
  const requestOptions = {
    method: "POST",
    headers: sharedHelpers.getRequestHeadersFormData(),
  };
  return await axios.post(
    configs.getApiUrl() + "/v1/my-shorex/vehicles",
    formData,
    requestOptions
  );
}

async function updateUserVehicle(vehicle) {
  const formData = new FormData();
  for (const property in vehicle) {
    if (vehicle.hasOwnProperty(property)) {
      // Check if the property value is null
      const value = vehicle[property] === null ? "" : vehicle[property];
      formData.append(property, value);
    }
  }
  const requestOptions = {
    method: "POST",
    headers: sharedHelpers.getRequestHeadersFormData(),
  };
  return await axios.post(
    configs.getApiUrl() + "/v1/my-shorex/vehicles/" + vehicle.id,
    formData,
    requestOptions
  );
}

async function getUserVehicles(status) {
  const requestOptions = {
    method: "GET",
    headers: sharedHelpers.getRequestHeaders(),
    params: {
      status: status,
    },
  };
  return await axios.get(
    configs.getApiUrl() + "/v1/my-shorex/vehicles",
    requestOptions
  );
}

async function getUserVehicle(id) {
  const requestOptions = {
    method: "GET",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.get(
    configs.getApiUrl() + `/v1/my-shorex/vehicles/${id}`,
    requestOptions
  );
}

async function exportVehicles() {
  const requestOptions = {
    method: "POST",
    responseType: "blob",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.post(
    configs.getApiUrl() + "/v1/vehicles/export",
    {},
    requestOptions
  );
}

async function cancelVehicle(vehicleId) {
  const requestOptions = {
    method: "PUT",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.put(
    configs.getApiUrl() + "/v1/my-shorex/vehicles/" + vehicleId + "/cancel",
    {},
    requestOptions
  );
}

async function getAvailableVehicles(payload) {
  const requestOptions = {
    method: "GET",
    headers: sharedHelpers.getRequestHeaders(),
    params: {
      vehicleId: payload.vehicleId,
      numberOfPassengers: payload.numberOfPassengers,
      vesselCallId: payload.vesselCallId,
      vehicleTypeId: payload.vehicleTypeId,
    },
  };
  return await axios.get(
    configs.getApiUrl() + "/v1/my-shorex/vehicles/available/" + payload.date,
    requestOptions
  );
}

async function getTaxis() {
  const requestOptions = {
    method: "GET",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.get(configs.getApiUrl() + "/v1/taxis", requestOptions);
}
