<template>
  <v-dialog v-model="isOpen" persistent width="900">
    <v-card tile>
      <v-progress-linear
        height="10"
        striped
        color="primary"
        indeterminate
        v-show="loading"
      ></v-progress-linear>
      <v-toolbar color="primary" flat>
        <v-toolbar-title>
          <span>
            <v-icon left>mdi-server-security</v-icon
            >{{ $t("users.ldap_configuration") }}</span
          >
        </v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pa-2" style="overflow: auto; height: 50vh">
        <v-alert color="blue-grey" text icon="mdi-information-outline">
          {{ $t("users.ldap_configuration_help") }}
        </v-alert>
        <v-form ref="form" :disabled="loading">
          <v-layout class="ma-0 pa-0">
            <v-spacer></v-spacer>
          </v-layout>
          <v-text-field
            v-model="configurations.host"
            counter="50"
            maxlength="50"
            required
            :label="$t('users.ldap_host')"
            outlined
          />
          <v-text-field
            v-model="configurations.port"
            type="number"
            required
            :label="$t('users.ldap_port')"
            outlined
          />

          <v-text-field
            v-model="configurations.user"
            required
            :label="$t('users.ldap_user')"
            outlined
            counter="50"
            maxlength="50"
          />
          <v-text-field
            v-model="configurations.password"
            required
            type="password"
            :label="$t('users.ldap_password')"
            outlined
            autocomplete="new-password"
            counter="50"
            maxlength="50"
          />

          <v-text-field
            v-model="configurations.base_dn"
            required
            :label="$t('users.ldap_dn')"
            outlined
            counter="50"
            maxlength="50"
          />

          <v-text-field
            v-model="configurations.name_field"
            required
            :label="$t('users.ldap_name_field')"
            outlined
            counter="50"
            maxlength="50"
          />

          <v-text-field
            v-model="configurations.username_field"
            required
            :label="$t('users.ldap_username_field')"
            outlined
            autocomplete="new-username"
            counter="50"
            maxlength="50"
          />

          <v-text-field
            v-model="configurations.email_field"
            required
            :label="$t('users.ldap_email_field')"
            outlined
            autocomplete="new-email"
            counter="50"
            maxlength="50"
          />
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer />
        <v-btn
          class="black--text"
          color="primary"
          depressed
          :disabled="loading"
          @click="save"
        >
          {{ $t("global.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: ["open", "item"],
  data() {
    return {
      loading: false,
      configurations: {},
    };
  },
  computed: {
    isOpen: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("update:open", val);
      },
    },
  },
  watch: {
    open(val) {
      if (val) {
        this.loadLdapConfigurations();
      }
    },
  },
  methods: {
    close() {
      this.isOpen = false;
    },
    loadLdapConfigurations() {
      // Reset configurations
      this.configurations = {};
      this.loading = true;
      this.$store
        .dispatch("settings/GET_LDAP_CONFIGURATIONS")
        .then((setting) => {
          this.loading = false;
          if (setting.value) {
            this.configurations = setting.value;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    save() {
      this.loading = true;
      this.$store
        .dispatch("settings/UPDATE_LDAP_CONFIGURATIONS", {
          value: this.configurations,
        })
        .then(() => {
          this.loading = false;
          this.close();
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>
