import { scheduledEntryScansService } from "@/api_services/scheduled_entry_scans.service";

const now = new Date();
const start = new Date(now.setMonth(now.getMonth() - 1));
const end = new Date();

const DEFAULT_STATE = {
  all: [],
  loading: false,
  allPaginated: [],
  loadingPaginated: false,
  options: {
    filterByDates: {
      start: start,
      end: end,
    },
    search: "",
    page: 1,
    itemsPerPage: 12,
    sortBy: "scanned_at",
    sortDesc: false,
    scanType: "ENTRY",
  },
  total: 0,
};

export default {
  namespaced: true,
  state: Object.assign({}, JSON.parse(JSON.stringify(DEFAULT_STATE))),
  getters: {},
  mutations: {
    setOptions(state, options) {
      state.options = options;
    },
    setTotal(state, total) {
      state.total = total;
    },
    setLoading(state, isLoading) {
      state.loading = isLoading;
    },
    setPaginated(state, operators) {
      state.allPaginated = operators;
    },
    setLoadingPaginated(state, isLoading) {
      state.loadingPaginated = isLoading;
    },
    setAll(state, all) {
      state.all = all;
    },
    setAllLoading(state, isLoading) {
      state.allLoading = isLoading;
    },
    setDefaultState(state) {
      Object.assign(state, JSON.parse(JSON.stringify(DEFAULT_STATE)));
    },
    setDateRange(state, { start, end }) {
      state.options.filterByDates.start = start;
      state.options.filterByDates.end = end;
    },
  },
  actions: {
    GET_ALL_PAGINATED: (context, options) => {
      return new Promise((resolve, reject) => {
        context.commit("setLoadingPaginated", true);
        context.commit("setPaginated", []);
        if (options) {
          context.commit("setOptions", options);
        }
        scheduledEntryScansService
          .getScheduledEntryScansPaginated(context.state.options)
          .then((res) => {
            context.commit("setPaginated", res.data.data);
            context.commit("setTotal", res.data.total);
            context.commit("setLoadingPaginated", false);
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            context.commit("setLoadingPaginated", false);
            reject(e);
          });
      });
    },
    EXPORT_PLAN: (context, options) => {
      if (options) {
        context.commit("setOptions", options);
      }

      return new Promise((resolve, reject) => {
        scheduledEntryScansService
          .exportPlan(context.state.options)
          .then((res) => {
            var fileURL = window.URL.createObjectURL(new Blob([res.data]));
            var fileLink = document.createElement("a");

            let fileName =
              context.state.options.scanType == "ENTRY"
                ? "Lista de Entradas"
                : "Lista de Saídas";

            fileLink.href = fileURL;
            fileLink.setAttribute("download", fileName + ".xlsx");
            document.body.appendChild(fileLink);

            fileLink.click();

            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },
    REVOKE_ENTRY_SCAN: (_, payload) => {
      return new Promise((resolve, reject) => {
        scheduledEntryScansService
          .revokeEntryScan(payload.id, payload.type)
          .then((res) => {
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },
    UPDATE_OPTIONS(context, options) {
      context.commit("setOptions", options);
    },
    UPDATE_DATE_RANGE(context, { start, end }) {
      context.commit("setDateRange", { start, end });
    },
    RESET: (context) => {
      context.commit("setDefaultState");
    },
  },
};
