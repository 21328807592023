<template>
  <v-card tile outlined width="100%">
    <v-card-title>
      <slot name="title"></slot>
    </v-card-title>
    <v-card-subtitle>
      <slot name="subtitle"></slot>
    </v-card-subtitle>
    <v-divider></v-divider>
    <v-card-text style="position: relative;">
      <Loading v-if="loading" :value="loading"></Loading>
      <v-simple-table dense fixed-header height="30vh" v-else>
        <template v-slot:default>
          <thead>
            <tr>
              <th style="width: 30px;"></th>
              <th>{{ $t('global.line') }}</th>
              <th v-if="hasErrors" class="text-left" style="width:200px">{{ $t('global.error_description') }}
              </th>
              <th class="text-left">{{ $t('vehicles.vehicle_type') }}</th>
              <th class="text-left">{{ $t('vehicles.registration_plate') }}</th>
              <th class="text-left">{{ $t('vehicles.brand') }}</th>
              <th class="text-left">{{ $t('vehicles.model') }}</th>
              <th class="text-left">{{ $t('vehicles.seating_capacity') }}</th>
              <th class="text-left">{{ $t('vehicles.license_number') }}</th>
              <th class="text-left">{{ $t('vehicles.permit_number') }}</th>
              <th class="text-left">{{ $t('vehicles.responsible_name') }}</th>
              <th class="text-left">{{ $t('vehicles.responsible_phone') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in sortedItems" :key="i" :class="item.type == 'ERROR' ? 'error-line' : ''">
              <td>

                <v-icon v-if="item['type'] == 'SUCCESS'" x-small color="success">mdi-check-circle</v-icon>
                <v-icon v-else x-small color="error">mdi-close-circle</v-icon>
              </td>
              <td>
                {{ item.row_number }}</td>
              <td v-if="hasErrors">{{ item["message"] }}</td>
              <td>{{ item["data"]["Tipo*"] }}</td>
              <td>{{ item["data"]["Matrícula*"] }}</td>
              <td>{{ item["data"]["Marca"] }}</td>
              <td>{{ item["data"]["Modelo"] }}</td>
              <td>{{ item["data"]["Número de lugares"] }}</td>
              <td>{{ item["data"]["Número de porta"] }}</td>
              <td>{{ item["data"]["Número de alvará"] }}</td>
              <td>{{ item["data"]["Nome do responsável"] }}</td>
              <td>{{ item["data"]["Telemóvel do responsável"] }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>
<script>
import Loading from "@/components/Main/Loading";
export default {
  props: ["items", "loading"],
  components: { Loading },
  computed: {
    hasErrors() {
      return this.items.some((e) => e.type == 'ERROR');
    },
    sortedItems() {
      return this.items.sort((a, b) => {
        if (a.row_number < b.row_number) {
          return -1;
        } else if (a.row_number > b.row_number) {
          return 1;
        }
        return 0;
      });
    }
  }
};
</script>
<style scoped>
.error-line {
  color: red;
}
</style>