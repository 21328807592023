<template>
  <div ref="menus" :class="this.$vuetify.breakpoint.mobile && this.isShorexUser
    ? 'other-menus d-flex flex-column'
    : 'menus d-flex flex-column'
    ">
    <div class="flex-grow-0">
      <v-list-item class="pa-1 primary" dark @click="openProfilePage()">
        <v-list-item-avatar class="ml-2 mr-2">
          <v-btn color="orange" fab small @click="openProfilePage()">
            <span class="black--text text-h6">{{ getNameChars() }}</span>
          </v-btn>
        </v-list-item-avatar>
        <v-list-item-content class="ma-0 pa-0" style="width: 100%">
          <v-list-item-title class="font-weight-bold black--text">
            {{ user.name }}
          </v-list-item-title>
          <v-list-item-subtitle class="font-weight-regular black--text" style="font-size: 11px">{{ user.email }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </div>
    <v-divider></v-divider>

    <div class="overflow-y-auto flex-grow-1">
      <v-list-group class="ports-group" v-model="ports.active" no-action
        v-if="userLocodes && userLocodes.length >= 1 && user.type !== 'SHOREX'">
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>
              <b class="item-title">{{
    $t("global.ports_locodes").toUpperCase()
  }}</b>
            </v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item v-for="(item, index) in userLocodes" class="pl-4" style="background-color: white" :key="index"
          @click="openLocode(item)" dense :class="locode.name === item.name ? 'item-selected' : ''">
          <v-list-item-action class="mr-2">
            <v-icon class="item-icon">mdi-anchor</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              <b class="item-title">{{ item.name }}</b>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>
      </v-list-group>

      <v-list-group class="myshorex-group" v-model="myshorex.active" no-action v-if="myshorex_items.length > 0">
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>
              <b class="item-title">MYSHOREX - {{ locode.name.toUpperCase() }}</b>
            </v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item v-for="(item, index) in myshorex_items" :key="index" class="pl-4" style="background-color: white"
          :to="item.route" rel="noopener" active-class="item-selected">
          <v-list-item-action class="mr-2">
            <v-icon class="item-icon">{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title><b class="item-title">{{ item.title }}</b>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>
      </v-list-group>

      <v-list-group class="shorex-group" v-model="shorex.active" no-action v-if="shorex_items.length > 0">
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>
              <b class="item-title">SHOREX - {{ locode.name.toUpperCase() }}</b>
            </v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item v-for="(item, index) in shorex_items" :key="index" class="pl-4" style="background-color: white"
          :to="item.route" rel="noopener" active-class="item-selected">
          <v-list-item-action class="mr-2">
            <v-icon class="item-icon">{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title><b class="item-title">{{ item.title }}</b>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>
      </v-list-group>

      <v-list-group class="configurations-group" v-model="configurations.active" no-action
        v-if="configurations_items.length > 0">
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title><b class="item-title">{{ configurations.title }}</b>
            </v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item v-for="(item, index) in configurations_items" :key="index" class="pl-4"
          style="background-color: white" :to="item.route" rel="noopener" active-class="item-selected">
          <v-list-item-action class="mr-2">
            <v-icon class="item-icon">{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title><b class="item-title">{{ item.title }}</b>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>
      </v-list-group>

      <v-list-item rel="noopener" @click.stop="logout" dense>
        <v-list-item-action class="mr-2">
          <v-icon>mdi-exit-to-app</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            <b>{{ $t("global.logout") }}</b>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </div>
  </div>
</template>

<script>
import configs from "@/helpers/configs";
import { sharedHelpers } from "@/api_services/utils/shared-helpers";
import { DateTime } from "luxon";
import debounce from "debounce";

export default {
  name: "Profile",
  data() {
    return {
      user: null,
      ports: {
        active: true,
      },
      shorex: {
        title: "",
        icon: "",
        active: true,
        items: [
          {
            title: this.$t("global.map"),
            icon: "mdi-map",
            route: "/map",
            permission: "MAP",
          },
          {
            title: this.$t("global.scheduled_entries"),
            icon: "mdi-table-edit",
            route: "/scheduled_entries",
            permission: "SCHEDULED_ENTRIES",
          },
          {
            title: this.$t("global.scheduled_entry_scans"),
            icon: "mdi-clipboard-check-outline",
            route: "/scheduled_entry_scans",
            permission: "SCHEDULED_ENTRY_SCANS",
          },
          {
            title: this.$t("global.scheduled_exit_scans"),
            icon: "mdi-clipboard-minus-outline",
            route: "/scheduled_exit_scans",
            permission: "SCHEDULED_ENTRY_SCANS",
          },
          {
            title: this.$t("configurations.noshorex_entries"),
            icon: "mdi-boom-gate-arrow-up",
            route: "/noshorex_entries",
            permission: "NOSHOREX_ENTRIES",
          },
          {
            title: this.$t("configurations.operators"),
            icon: "mdi-store",
            route: "/operators",
            permission: "OPERATORS",
          },
          {
            title: this.$t("global.statistics"),
            icon: "mdi-calendar-month",
            route: {
              name: "STATS",
              query: {
                start: DateTime.now().minus({ days: 7 }).toFormat("yyyy-LL-dd"),
                end: DateTime.now().toFormat("yyyy-LL-dd"),
              },
            },
            permission: "STATS",
          },
          {
            title: this.$t("configurations.registered_users"),
            icon: "mdi-account-box",
            route: "/registered_users",
            permission: "REGISTERED_USERS",
          },

          {
            title: this.$t("configurations.vehicles"),
            icon: "mdi-car-multiple",
            route: "/vehicles",
            permission: "VEHICLES",
          },
        ],
      },
      myshorex: {
        title: "",
        icon: "",
        active: true,
        items: [
          {
            title: this.$t("global.entry_requests"),
            icon: "mdi-boom-gate-arrow-up-outline",
            route: "/entry_requests",
            permission: "ENTRY_REQUESTS",
          },
          {
            title: this.$t("vehicles.my_vehicles"),
            icon: "mdi-car-multiple",
            route: "/user_vehicles",
            permission: "USER_VEHICLES",
          },
          {
            title: this.$t("global.team"),
            icon: "mdi-account-group",
            route: "/members",
            permission: "MEMBERS",
          },
        ],
      },
      configurations: {
        icon: "mdi-cog",
        active: true,
        items: [
          {
            title: this.$t("configurations.vessel_calls"),
            icon: "mdi-ferry",
            route: "/vessel_calls",
            permission: "VESSEL_CALLS",
          },
          {
            title: this.$t("configurations.operator_types"),
            icon: "mdi-train-car",
            route: "/operator_types",
            permission: "OPERATOR_TYPES",
          },
          {
            title: this.$t("configurations.users"),
            icon: "mdi-account-group",
            route: "/users",
            permission: "USERS",
          },
          {
            title: this.$t("configurations.zones"),
            icon: "mdi-texture-box",
            route: "/zones",
            permission: "ZONES",
          },
          {
            title: this.$t("configurations.vehicle_types"),
            icon: "mdi-car-door",
            route: "/vehicle_types",
            permission: "VEHICLE_TYPES",
          },
          {
            title: this.$t("configurations.audit_logs"),
            icon: "mdi-server-security",
            route: "/audit_logs",
            permission: "AUDIT_LOGS",
          },
        ],
        title: this.$t("global.configurations").toUpperCase(),
      },
    };
  },
  created() {
    this.fetchUserData();
  },
  computed: {
    userLocodes() {
      return configs
        .getLocodes()
        .filter((item) => sharedHelpers.getUserLocodes().includes(item.code));
    },
    locode() {
      return configs.getLocode();
    },
    configurations_items() {
      return this.configurations.items.filter((configuration) => {
        return configuration.permission
          ? this.isAdminUser ||
          (this.hasUserPermissionToViewOrManage(configuration.permission) &&
            this.isManagerUser)
          : true;
      });
    },
    shorex_items() {
      return this.shorex.items.filter((shorex) => {
        if (!shorex.permission) return true;

        return (
          this.isAdminUser ||
          (this.hasUserPermissionToViewOrManage(shorex.permission) &&
            this.isManagerUser)
        );
      });
    },
    myshorex_items() {
      if (this.isShorexUser) return this.myshorex.items;
      else return [];
    },
    userAccount() {
      return this.$store.state.user.account;
    },
    userOperator() {
      return this.userAccount && this.userAccount.user
        ? this.userAccount.user.operator
        : null;
    },
    userType() {
      return this.userAccount && this.userAccount.user
        ? this.userAccount.user.type
        : "";
    },
    isAdminUser() {
      return this.userType == configs.getAdminUserType();
    },
    isManagerUser() {
      return this.userType == configs.getManagerUserType();
    },
    isShorexUser() {
      return this.userType == configs.getShorexUserType();
    },
  },
  methods: {
    getNameChars() {
      return this.userAccount.user.name.substring(0, 2);
    },
    openProfilePage: debounce(function () {
      if (this.$route.name !== "Profile") {
        this.$router.push("/profile");
      }
    }, 300),
    fetchUserData() {
      let account = JSON.parse(
        sharedHelpers.getStorageItemWithExpiry("account")
      );
      this.user = account.user;
    },
    openLocode(locode) {
      sharedHelpers.setStorageItemWithExpiry(
        "locode",
        locode,
        8640000 //24hours
      );

      if (this.$route.name.toUpperCase() === "MAP") {
        location.reload();
      } else {
        this.$router.push("/map");
      }

      this.$emit("loadNotifications");
    },
    logout() {
      this.$router.push("/login", () => {
        this.$store.dispatch("LOGOUT").then(() => { });
      });
    },
  },
};
</script>
<style scoped>
.item-selected,
.item-selected::before {
  background: transparent;
}

.item-selected .item-title {
  font-weight: 900;
  color: rgba(0, 0, 0, 0.87);
}

.item-selected .item-icon {
  color: black;
}

.item-title {
  font-size: 0.8125rem;
  line-height: 1rem;
}

.ports-group,
.stats-group,
.configurations-group,
.myshorex-group,
.shorex-group {
  background-color: #eeeeee;
  color: black !important;
  border-color: #eeeeee;
}

.menus {
  height: calc(100dvh - var(--toolbar-height));
  overflow: auto;
}

.other-menus {
  height: calc(100dvh - var(--toolbar-height) - var(--bottom-nav-height));
  overflow: auto;
}
</style>
