import { render, staticRenderFns } from "./ScannedEntriesCard.vue?vue&type=template&id=3ba53852&scoped=true&"
import script from "./ScannedEntriesCard.vue?vue&type=script&lang=js&"
export * from "./ScannedEntriesCard.vue?vue&type=script&lang=js&"
import style0 from "./ScannedEntriesCard.vue?vue&type=style&index=0&id=3ba53852&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ba53852",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VChip,VCol,VDivider,VIcon,VListItem,VListItemAvatar,VListItemContent,VListItemSubtitle,VListItemTitle,VRow})
