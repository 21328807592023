<template>
  <div class="fill-height fill-width ma-1">
    <v-card
      class="fill-width mx-auto"
      outlined
      @click.stop="handleVehicleClick"
      :dark="active ? true : false"
    >
      <v-toolbar flat dense>
        <v-toolbar-title>
          <v-list-item two-line class="ma-0 pa-0 pt-2">
            <v-list-item-content v-if="item.vehicle_type.code != 'TAXI'">
              <v-list-item-title class="registration-plate-font text-h6">
                {{ item.registration_plate }}</v-list-item-title
              >
              <v-list-item-subtitle class="text-caption"
                >Matrícula</v-list-item-subtitle
              >
            </v-list-item-content>

            <v-list-item-content v-else>
              <v-list-item-title class="registration-plate-font text-h6">
                {{ item.license_number }}</v-list-item-title
              >
              <v-list-item-subtitle class="text-caption"
                >Nº de Porta</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-chip
          dark
          class="text-uppercase overline"
          small
          label
          style="font-size: 0.6rem !important"
          :color="getVehicleStatusColor(item)"
          >{{ $t(`vehicle_states.${item.status.toLowerCase()}`) }}</v-chip
        >
      </v-toolbar>
      <v-card-text
        class="pa-0"
        flat
        style="overflow: hidden; position: relative"
      >
        <div class="backgroundIcon">
          <v-icon color="grey lighten-4" size="80">
            {{ item.vehicle_type.icon }}</v-icon
          >
        </div>
        <v-list dense>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold text-subtitle-1">{{
                item.vehicle_type.name ? item.vehicle_type.name : "N/D"
              }}</v-list-item-title>
              <v-list-item-subtitle class="text-caption"
                >{{ $t("vehicles.type") }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold text-subtitle-1">{{
                item.brand ? item.brand : "N/D"
              }}</v-list-item-title>
              <v-list-item-subtitle class="text-caption"
                >{{ $t("vehicles.brand") }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold text-subtitle-1">{{
                item.seating_capacity ? item.seating_capacity : "N/D"
              }}</v-list-item-title>
              <v-list-item-subtitle class="text-caption"
                >{{ $t("vehicles.seating_capacity") }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import configs from "@/helpers/configs";
export default {
  props: ["item", "active"],
  computed: {
    vehicleStatuses() {
      return configs.getVehicleStatuses();
    },
  },
  methods: {
    getVehicleStatusColor(item) {
      let status = this.vehicleStatuses.find((v) => {
        return v.code == item.status;
      });
      if (status) {
        return status.color;
      }
      return null;
    },
    handleVehicleClick(e) {
      this.$emit("click", e);
    },
  },
};
</script>
<style scoped>
.backgroundIcon {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  position: absolute;
  width: 100%;
  height: 100%;
  margin-top: 10px;
}
.registration-plate-font {
  font-family: "Fira Mono", monospace !important;
  font-weight: 700 !important;
}
</style>
