<template>
  <v-dialog v-if="dialogOpen" v-model="dialogOpen" persistent max-width="500">
    <v-card tile :loading="loading">
      <v-card-title
        ><b>{{ $t("noshorex_entries.noshorex_entries_list") }}</b
        >&nbsp;</v-card-title
      >
      <v-card-text>{{ $t("global.exporting") }}</v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
/*eslint-disable*/
export default {
  props: ["open"],
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    dialogOpen: {
      // getter
      get: function () {
        return this.open;
      },
      // setter
      set: function (newValue) {
        this.$emit("update:open", newValue);
      },
    },
  },
  methods: {
    exportNoShorexEntries() {
      this.loading = true;
      this.$store
        .dispatch("noshorex_entries/EXPORT")
        .then(() => {
          this.loading = false;
          this.dialogOpen = false;
        })
        .catch((e) => {
          console.error("Error exporting noshorex_entries", e);
          this.loading = false;
          this.dialogOpen = false;
        });
    },
  },
  watch: {
    dialogOpen(val) {
      if (val) this.exportNoShorexEntries();
    },
  },
};
</script>
