import { entryRequestsService } from "@/api_services/entry_requests.service";

export default {
  namespaced: true,
  state: {
    all: [],
    loading: false,
  },
  getters: {},
  mutations: {
    setEntryRequests(state, items) {
      state.all = items;
    },
    setLoading(state, isLoading) {
      state.loading = isLoading;
    },
  },
  actions: {
    GET_ALL: (context) => {
      return new Promise((resolve, reject) => {
        context.commit("setLoading", true);
        context.commit("setEntryRequests", []);
        entryRequestsService
          .getEntryRequests()
          .then((res) => {
            context.commit("setEntryRequests", res.data);
            context.commit("setLoading", false);
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            context.commit("setLoading", false);
            reject(e);
          });
      });
    },
    GET_ALL_BY_DATE: (context, date) => {
      return new Promise((resolve, reject) => {
        context.commit("setLoading", true);
        context.commit("setEntryRequests", []);
        entryRequestsService
          .getEntryRequestsByDate(date)
          .then((res) => {
            context.commit("setEntryRequests", res.data);
            context.commit("setLoading", false);
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            context.commit("setLoading", false);
            reject(e);
          });
      });
    },
    GET_BY_ID: (_, id) => {
      return new Promise((resolve, reject) => {
        entryRequestsService
          .getEntryRequest(id)
          .then((res) => {
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },
    CREATE: (_, entryRequest) => {
      return new Promise((resolve, reject) => {
        entryRequestsService
          .createEntryRequest(entryRequest)
          .then((res) => {
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    CANCEL: (_, entryRequestId) => {
      return new Promise((resolve, reject) => {
        entryRequestsService
          .cancelEntryRequest(entryRequestId)
          .then((res) => {
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    REVERT_CANCEL: (_, entryRequestId) => {
      return new Promise((resolve, reject) => {
        entryRequestsService
          .revertCancelEntryRequest(entryRequestId)
          .then((res) => {
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },
    EDIT: (_, payload) => {
      return new Promise((resolve, reject) => {
        entryRequestsService
          .editEntryRequestVehicle(payload)
          .then((res) => {
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    DOWNLOAD_TEMPLATE: (_) => {
      return new Promise((resolve, reject) => {
        entryRequestsService
          .downloadTemplate()
          .then((res) => {
            var fileURL = window.URL.createObjectURL(new Blob([res.data]));
            var fileLink = document.createElement("a");

            fileLink.href = fileURL;
            fileLink.setAttribute("download", "[3SHOREX]LISTA_DE_ENTRADAS.xlsx");
            document.body.appendChild(fileLink);

            fileLink.click();

            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    IMPORT: (_, payload) => {
      return new Promise((resolve, reject) => {
        entryRequestsService
          .importEntryRequests(payload)
          .then((res) => {
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    EXPORT: (_, date) => {
      return new Promise((resolve, reject) => {
        entryRequestsService
          .exportEntryRequests(date)
          .then((res) => {
            var fileURL = window.URL.createObjectURL(new Blob([res.data]));
            var fileLink = document.createElement("a");

            fileLink.href = fileURL;
            fileLink.setAttribute("download", "[3SHOREX]LISTA_DE_ENTRADAS.xlsx");
            document.body.appendChild(fileLink);

            fileLink.click();

            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },
  },
};
