<template>
  <v-form ref="form" @submit.prevent="submit" lazy-validation v-model="valid">
    <v-text-field label="Email" name="email" v-model="email" type="email" :disabled="loading" outlined hide-details
      class="mb-4" counter="50" maxlength="50"></v-text-field>

    <v-alert v-show="errorRecoverPasswordStatus" color="red" dark>{{
      errorRecoverPasswordMsg
    }}</v-alert>

    <v-btn type="submit" :loading="loading" :disabled="loading || !valid" color="primary" class="black--text mb-4"
      depressed block large>
      {{ $t('login.reset_password') }}
    </v-btn>

    <v-btn text :disabled="loading" color="grey darken-2" exact depressed @click="goToLogin" block>
      {{ $t('global.back_home') }}
    </v-btn>
  </v-form>
</template>

<script>
export default {
  data: () => ({
    email: "",
    valid: false,
    loading: false,
    errorRecoverPasswordStatus: false,
    errorRecoverPasswordMsg: "",
  }),
  methods: {
    goToLogin() {
      this.$router.push("/login");
    },
    submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        this.errorRecoverPasswordStatus = false;
        this.errorRecoverPasswordMsg = "";

        this.$store
          .dispatch("FORGOT_PASSWORD", {
            email: this.email,
          })
          .then((res) => {
            if (res) {
              if (res.error) {
                this.errorRecoverPasswordStatus = res.error;
                this.errorRecoverPasswordMsg = res.msg;
                this.loading = false;
                return;
              }

              this.$emit("success");
            }
          })
          .catch(() => {
            this.errorRecoverPasswordStatus = true;
            this.errorRecoverPasswordMsg =
              "O sistema não conseguiu validar o seu email. Por favor, verifique os seus dados de acesso e tente novamente";
            this.loading = false;
          });
      }
    },
  },
};
</script>