<template>
  <v-card tile outlined>
    <v-card-title
      ><slot name="title"></slot></v-card-title
    >
    <v-card-subtitle><slot name="subtitle"></slot></v-card-subtitle>
    <v-divider></v-divider>
    <v-card-text style="position: relative;">
      <Loading v-if="loading" :value="loading"></Loading>
      <v-simple-table dense fixed-header height="30vh" v-else>
        <template v-slot:default>
          <thead>
            <tr>
              <th v-if="withErrorDescription" class="text-left" style="width:200px">{{ $t('global.error_description') }}</th>
              <th class="text-left">{{ $t('vehicles.vehicle_type') }}</th>
              <th class="text-left">{{ $t('vehicles.operator') }}</th>
              <th class="text-left">{{ $t('vehicles.registration_plate') }}</th>
              <th class="text-left">{{ $t('vehicles.brand') }}</th>
              <th class="text-left">{{ $t('vehicles.model') }}</th>
              <th class="text-left">{{ $t('vehicles.seating_capacity') }}</th>
              <th class="text-left">{{ $t('vehicles.status') }}</th>
              <th class="text-left">{{ $t('vehicles.client_number') }}</th>
              <th class="text-left">{{ $t('vehicles.rate_type') }}</th>
              <th class="text-left">{{ $t('vehicles.notes') }}</th>
              <th class="text-left">{{ $t('vehicles.valid_until') }} (dd/mm/aaaa)</th>
              <th class="text-left">{{ $t('vehicles.license_number') }}</th>
              <th class="text-left">{{ $t('vehicles.permit_number') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in items" :key="i" :class="withErrorDescription ? 'error-line' : ''">
              <td v-if="withErrorDescription">{{ item["message"] }}</td>
              <td>{{ item["Tipo"] }}</td>
              <td>{{ item["Operador"] }}</td>
              <td>{{ item["Matrícula"] }}</td>
              <td>{{ item["Marca"] }}</td>
              <td>{{ item["Modelo"] }}</td>
              <td>{{ item["Número de lugares"] }}</td>
              <td>{{ item["Estado"] }}</td>
              <td>{{ item["Número de cliente"] }}</td>
              <td>{{ item["Taxa"] }}</td>
              <td>{{ item["Notas"] }}</td>
              <td>{{ item["Válido até (dd/mm/aaaa)"] }}</td>
              <td>{{ item["Número de porta"] }}</td>
              <td>{{ item["Número de alvará"] }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>
<script>
import Loading from "@/components/Main/Loading";
export default {
  props: ["items", "loading", "withErrorDescription"],
  components: { Loading },
};
</script>
<style scoped>
.error-line
{
  color: red;
}
</style>