<template>
  <div class="overline">
      <status-indicator
        :status="!!updateDate ? 'active' : 'negative'"
        pulse
        class="mr-3"
      /><span v-if="!!updateDate" style="color: black !important"
        >{{ updateDate | formatJSFullDatetime }} | {{ labelAIS }}</span
      >
  </div>
</template>

<script>
//Access vue instance to reach global constants
import * as maptalks from "maptalks";
import configs from "@/helpers/configs";
import * as turf from "@turf/turf";

const AISSHIPS_LAYERNAME = "ais_ships";
const DEFAULT_SHIP_LENGTH = 20;
const DEFAULT_SHIP_WIDTH = 5;

import AISShipsTracker from "@/helpers/realtime/aisshipstracker";
import AISShip from "@/helpers/ships/aisship";

import { StatusIndicator } from "vue-status-indicator";

export default {
  props: {
    refMap: Object,
    ship: Object,
  },
  components: {
    StatusIndicator,
  },
  data() {
    return {
      drawnShips: new Map(),
      updateDate: null,
      locode: configs.getLocode(),
    };
  },
  computed: {
    map: {
      get() {
        return this.refMap;
      },
      set(val) {
        this.$emit("update:refMap", val);
      },
    },
    labelAIS() {
      return "AIS (" + this.$store.state.ais_ships.all.length + " Navios)";
    },
  },
  mounted() {
    this.enableTracking();
  },
  beforeDestroy() {
    this.disableTracking();
  },
  methods: {
    clearLayer() {
      if (this.map) {
        if (this.map.getLayer(AISSHIPS_LAYERNAME))
          this.map.getLayer(AISSHIPS_LAYERNAME).clear();
      }
    },
    recreateLayer() {
      if (this.map) {
        if (!this.map.getLayer(AISSHIPS_LAYERNAME))
          this.map.addLayer(new maptalks.VectorLayer(AISSHIPS_LAYERNAME));
        else this.map.getLayer(AISSHIPS_LAYERNAME).clear();
      }
    },
    enableTracking() {
      this.recreateLayer();
      AISShipsTracker.on("add", this.handleShipAdded);
      AISShipsTracker.on("update", this.handleShipUpdated);
      AISShipsTracker.on("remove", this.handleShipRemoved);
    },
    disableTracking() {
      this.clearLayer();
      AISShipsTracker.off("add", this.handleShipAdded);
      AISShipsTracker.off("update", this.handleShipUpdated);
      AISShipsTracker.off("remove", this.handleShipRemoved);
    },
    handleShipAdded(ship) {
      if (ship && ship.lat && ship.lon) {
        this.updateDate = new Date();

        var from = turf.point([ship.lon, ship.lat]);
        var to = turf.point([
          this.locode.coordinates[0],
          this.locode.coordinates[1],
        ]);
        var options = { units: "meters" };

        var distance = turf.distance(from, to, options);

        if (distance < 100000) {
          if (!ship._drawnObject) {
            let shipSpec = this.mapShipToSpec(ship);
            try {
              let drawnShip = new AISShip(
                [],
                {
                  properties: {
                    id: shipSpec.Id,
                    name: shipSpec.Name,
                    lineDasharray: ship.updating ? null : [5],
                    lineColor: configs.getShipType(shipSpec.Cargo).color,
                    fillColor: configs.getShipType(shipSpec.Cargo).color,
                    width: shipSpec.Width,
                    length: shipSpec.Length,
                    flag: shipSpec.Flag,
                  },
                  draggable: false,
                },
                shipSpec
              );
              if (drawnShip) {
                drawnShip.addTo(this.map.getLayer(AISSHIPS_LAYERNAME));
                ship._drawnObject = drawnShip;
                this.$store.dispatch("ais_ships/ADD_OR_UPDATE", ship);
                this.drawnShips.set(shipSpec.Id, ship);
              }
            } catch (e) {
              console.error(e, shipSpec);
            }
          } else {
            this.handleShipUpdated(ship);
          }
        }
      }
    },
    handleShipUpdated(ship) {
      this.updateDate = new Date();
      if (ship) {
        if (ship._drawnObject) {
          let shipSpec = this.mapShipToSpec(ship);
          ship._drawnObject.properties = {
            id: shipSpec.Id,
            name: shipSpec.Name,
            lineDasharray: ship.updating ? null : [5],
            lineColor: configs.getShipType(shipSpec.Cargo).color,
            fillColor: configs.getShipType(shipSpec.Cargo).color,
            width: shipSpec.Width,
            length: shipSpec.Length,
            flag: shipSpec.Flag,
          };
          ship._drawnObject.updateShipSpec(shipSpec);
          this.$store.dispatch("ais_ships/ADD_OR_UPDATE", ship);
          this.drawnShips.set(shipSpec.Id, ship);
        } else {
          //this was wrongly assumed as an update, but we dont have it yet
          this.handleShipAdded(ship);
        }
      }
    },
    handleShipRemoved(ship) {
      this.updateDate = new Date();
      if (ship && ship._drawnObject) {
        this.drawnShips.delete(ship.Id);
        ship._drawnObject.remove();
        this.$store.dispatch("ais_ships/REMOVE", ship);
      }
    },
    mapShipToSpec(ship) {
      return {
        Id: ship.id,
        Name: ship.shipname,
        IMO: ship.imo,
        Draggable: true,
        Length: Math.min(parseFloat(ship.length || DEFAULT_SHIP_LENGTH), 150),
        Width: Math.min(parseFloat(ship.width || DEFAULT_SHIP_WIDTH), 20),
        MapGeom: "POINT(" + ship.lon + " " + ship.lat + ")",
        Heading: parseFloat(ship.hdg || 0),
        Flag: ship.countryCode,
        Color: "black",
        Cargo: ship.cargo,
        shipDimensions: {
          dimA: ship.dimA,
          dimB: ship.dimB,
          dimC: ship.dimC,
          dimD: ship.dimD,
        },
      };
    },
  },
};
</script>
<style>
.aisShipList .v-data-table__wrapper > table > tbody > tr > th,
.aisShipList .v-data-table__wrapper > table > tbody > tr > th,
.aisShipList .v-data-table__wrapper > table > thead > tr > th,
.aisShipList .v-data-table__wrapper > table > tfoot > tr > td,
.aisShipList .v-data-table__wrapper > table > thead > tr > td,
.aisShipList .v-data-table__wrapper > table > thead > tr > td,
.aisShipList tbody tr td {
  font-size: 11px !important;
  padding-left: 2px !important;
}

.aisShipList tr.v-data-table__expanded__content {
  box-shadow: inset 1px 0px 2px rgba(0, 0, 0, 0.39) !important;
  background-color: rgba(174, 221, 243, 0.541);
}
</style>
