<template>
  <v-bottom-sheet
    v-model="isOpen"
    content-class="new-entry-request-dialog"
    persistent
  >
    <v-card flat class="fill-height" v-if="isOpen">
      <v-toolbar color="primary" flat>
        <v-toolbar-title>
          <span>
            <v-icon left>mdi-boom-gate-arrow-up-outline</v-icon
            >{{ $t("scheduled_entries.request_new_entry") }}
          </span>
          <span v-if="currentStep > 1"
            >- {{ item.vessel_call.name }} -
            {{ item.date | formatJSDate }}</span
          >
        </v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="overflow-y-auto pa-0 form-content">
        <v-form ref="form">
          <v-stepper vertical flat v-model="currentStep">
            <v-stepper-step step="1" :complete="isStepComplete(1)">
              {{ $t("entry_requests.ship_process") }}
              <small class="pt-1">{{
                $t("entry_requests.ship_process_help")
              }}</small>
            </v-stepper-step>
            <v-stepper-content step="1">
              <v-card flat>
                <VesselCallsSelector
                  v-model="item.vessel_call_id"
                  :item.sync="item.vessel_call"
                  :date.sync="item.date"
                  :archivedPlanDates="archivedPlanDates"
                ></VesselCallsSelector>
              </v-card>
            </v-stepper-content>

            <v-stepper-step step="2" :complete="isStepComplete(2)">
              {{ $t("global.vehicles") }}
              <small class="pt-1">{{
                $t("entry_requests.vehicles_help")
              }}</small>
            </v-stepper-step>
            <v-stepper-content step="2">
              <v-card flat>
                <VehiclesSelector
                  :items.sync="item.vehicles"
                  :date="item.date"
                  :vesselCall="item.vessel_call"
                  :key="compKey"
                ></VehiclesSelector>
              </v-card>
            </v-stepper-content>

            <v-stepper-step step="3" :complete="isStepComplete(3)">
              {{ $t("entry_requests.briefing") }}
            </v-stepper-step>
            <v-stepper-content step="3">
              <v-card flat>
                <v-card-text class="mx-0 px-0 py-0">
                  <v-alert
                    color="blue-grey"
                    text
                    icon="mdi-information-outline"
                  >
                    <p class="text-h6 font-weight-black mb-0">
                      {{ $t("entry_requests.your_entry_request") }}
                    </p>
                    <p class="text-body-1 mb-0">
                      {{ $t("global.please_confirm_collected_info") }}
                    </p>
                  </v-alert>
                  <v-card outlined class="mt-4" v-if="!!item.vessel_call">
                    <v-card-text class="ma-0 pa-0">
                      <v-simple-table dense>
                        <template v-slot:default>
                          <tbody>
                            <tr>
                              <td
                                class="font-weight-bold text-uppercase"
                                width="50%"
                              >
                                {{ $t("global.ship") }}
                              </td>
                              <td>{{ item.vessel_call.name }}</td>
                            </tr>
                            <tr>
                              <td class="font-weight-bold text-uppercase">
                                {{ $t("global.day") }}
                              </td>
                              <td>{{ item.date | formatJSDate }}</td>
                            </tr>
                          </tbody></template
                        ></v-simple-table
                      >
                    </v-card-text>
                  </v-card>

                  <v-card
                    outlined
                    class="mt-4"
                    v-for="(vehicle, vIndex) in item.vehicles"
                    :key="vIndex"
                  >
                    <v-card-title class="font-weight-black"
                      >Entrada #{{ vIndex + 1 }} -
                      <span
                        v-if="vehicle.vehicle_type.code != 'TAXI'"
                        style="
                          font-family: 'Fira Mono', monospace !important;
                          font-weight: 600 !important;
                        "
                      >
                        {{ vehicle.registration_plate }}
                      </span>
                      <span
                        v-else
                        style="
                          font-family: 'Fira Mono', monospace !important;
                          font-weight: 600 !important;
                        "
                      >
                        {{ vehicle.license_number }}
                      </span>
                    </v-card-title>
                    <v-card-text class="ma-0 pa-0">
                      <v-simple-table dense>
                        <template v-slot:default>
                          <tbody>
                            <tr>
                              <td
                                width="50%"
                                class="font-weight-bold text-uppercase"
                              >
                                {{ $t("entries.vehicle_at_pier_date_request") }}
                              </td>
                              <td>
                                {{ vehicle.vehicle_at_pier_time }}
                              </td>
                            </tr>
                            <tr v-if="!!vehicle.responsible_name">
                              <td class="font-weight-bold text-uppercase">
                                {{ $t("entries.responsible_name") }}
                              </td>
                              <td>
                                {{ vehicle.responsible_name || "N/D" }}
                              </td>
                            </tr>
                            <tr v-if="!!vehicle.responsible_phone">
                              <td class="font-weight-bold text-uppercase">
                                {{ $t("entries.responsible_phone") }}
                              </td>
                              <td>
                                {{ vehicle.responsible_phone || "N/D" }}
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-card-text>
                  </v-card>
                </v-card-text>
              </v-card>
            </v-stepper-content>
          </v-stepper>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn
          :disabled="isFirstStep"
          @click.stop="goToPreviousStep"
          large
          depressed
          color="primary"
          class="black--text"
          ><v-icon small>mdi-arrow-up</v-icon>{{ $t("global.back") }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          v-if="isFormComplete"
          large
          depressed
          color="primary"
          class="black--text"
          @click.stop="createEntryRequest"
          ><v-icon small>mdi-plus</v-icon>{{ $t("global.register") }}
        </v-btn>
        <v-btn
          v-else
          large
          :disabled="!isNextStepAvailable"
          depressed
          color="primary"
          class="black--text"
          @click.stop="goToNextStep"
          ><v-icon small>mdi-arrow-down</v-icon>{{ $t("global.advance") }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog v-model="creatingDialog" persistent width="520">
      <v-card class="fill-height">
        <v-card-text style="min-height: 250px; position: relative">
          <Loading
            v-model="creatingDialog"
            :custom-message="
              $t('entry_requests.creating_your_entry_request_please_wait')
            "
          ></Loading>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="createdDialog" persistent width="450">
      <v-card>
        <v-card-text>
          <div class="text-center pt-4">
            <v-icon size="100" color="green" left>mdi-check-circle</v-icon>
          </div>
          <p class="text-center text-h6 ma-0 pt-4" style="color: black">
            {{
              $t("entry_requests.your_entry_request_was_created_successfully")
            }}
          </p>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="errorDialog" width="450">
      <v-card>
        <v-card-text>
          <div class="text-center pt-4">
            <v-icon size="100" color="red" left>mdi-close-circle</v-icon>
          </div>
          <p class="text-center text-h6 ma-0 pt-4" style="color: black">
            {{ $t("global.an_error_occurred_processing_your_request") }}
          </p>
          <div
            v-for="(message, index) in messages"
            :key="index"
            class="subtitle text-center mt-2"
          >
            {{ message }}
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            block
            color="primary"
            class="black--text"
            depressed
            @click="errorDialog = false"
          >
            {{ $t("global.close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-bottom-sheet>
</template>
<script>
const MAX_SEATING = 100;

const DEFAULT_ITEM = {
  vessel_call: null,
  vessel_call_id: null,
  vehicle_ids: [],
  vehicles: [],
  date: null,
};
import Loading from "@/components/Main/Loading";
import VehiclesSelector from "@/components/Main/Vehicles/VehiclesSelector";
import VesselCallsSelector from "@/components/Main/VesselCalls/VesselCallsSelector";
import DateInput from "@/components/Main/DateInput";
import CountryFlag from "vue-country-flag";
import configs from "@/helpers/configs";
import { Settings, DateTime } from "luxon";
Settings.defaultZone = configs.getTimezone();
Settings.defaultLocale = configs.getLocale();
import { sharedHelpers } from "@/api_services/utils/shared-helpers";
// Default to today
const TODAY = DateTime.now();

export default {
  props: ["value"],
  components: {
    VehiclesSelector,
    VesselCallsSelector,
    Loading,
    CountryFlag,
    DateInput,
  },
  computed: {
    isOpen: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    today() {
      return TODAY;
    },
    isNextStepAvailable() {
      switch (this.currentStep) {
        case 1:
          return !!this.item.vessel_call && !!this.item.date;
        case 2:
          if (this.item.vehicles && this.item.vehicles.length > 0) {
            return (
              this.item.vehicles.every((vehicle) => {
                return (
                  vehicle.vehicle_at_pier_time &&
                  vehicle.responsible_name &&
                  vehicle.responsible_phone
                );
              }) && this.validatePierTimeFrame(this.item.vehicles)
            );
          }
          return false;
      }
      return false;
    },
    isFirstStep() {
      return this.currentStep == 1;
    },
    isFormComplete() {
      return this.currentStep == 3 && this.$refs.form.validate();
    },
  },
  data() {
    return {
      messages: [],
      archivedPlanDates: [],
      item: Object.assign({}, DEFAULT_ITEM),
      maxDescribedPassengerCount: 5,
      currentStep: 1,
      creatingDialog: false,
      createdDialog: false,
      errorDialog: false,
      countriesLoading: false,
      responsibleNameRules: [(v) => !!v || this.$t("rules.required_field")],
      responsiblePhoneRules: [
        (v) => !!v || this.$t("rules.required_field"),
        (v) =>
          !v || /^\+?\d{9,}$/.test(v) || this.$t("rules.invalid_phone_number"),
      ],
      numberOfPassengersRules: [
        (v) => !v || this.isNumeric(v) || this.$t("rules.must_be_numeric"),
      ],
      responsibleAtPierTimeRules: [],
      passengerNameRules: [(v) => !!v || this.$t("rules.required_field")],
      passengerBirthdayMenuActive: false,
      passengerDateOfBirthRules: [
        (v) => {
          return !v || this.isDateValid(v);
        },
      ],
      compKey: 0,
    };
  },
  watch: {
    isOpen(val) {
      if (val) {
        this.$nextTick(() => {
          this.item = Object.assign({}, DEFAULT_ITEM);
          this.currentStep = 1;
          this.$refs.form.reset();
          this.getArchivedPlanDates();
        });
      } else {
        this.$nextTick(() => {
          this.item = Object.assign({}, DEFAULT_ITEM);
          this.currentStep = 1;
        });
      }
    },
    "item.vessel_call": {
      handler(val) {
        if (val) {
          this.compKey++;
        }
      },
      deep: true,
    },
  },
  methods: {
    close() {
      this.isOpen = false;
    },
    goToNextStep() {
      this.currentStep++;
    },
    goToPreviousStep() {
      this.currentStep--;
    },
    isStepComplete(step) {
      return step <= this.currentStep;
    },
    isNumeric(num) {
      return num && !isNaN(num) && parseInt(num) > 0;
    },
    isDateValid(d) {
      try {
        let date = DateTime.fromFormat(d, "yyyy-LL-dd");
        return date.isValid && date <= this.today;
      } catch (e) {
        console.error("Could not parse date", e);
      }
      return false;
    },
    isTimeValid(date) {
      if (
        date &&
        typeof date == "string" &&
        this.item &&
        this.item.vessel_call
      ) {
        let eta = new Date(this.item.vessel_call.eta);
        let etd = new Date(this.item.vessel_call.etd);
        let currentDate = null;

        if (
          eta.getFullYear() == etd.getFullYear() &&
          eta.getMonth() == etd.getMonth() &&
          eta.getDate() == etd.getDate()
        ) {
          currentDate = new Date(
            eta.getFullYear(),
            eta.getMonth(),
            eta.getDate(),
            date.split(":")[0],
            date.split(":")[1],
            0,
            0
          );

          return currentDate >= eta && currentDate <= etd;
        } else {
          if (
            eta.getDate() == this.item.date.getDate() &&
            eta.getMonth() == this.item.date.getMonth() &&
            eta.getFullYear() == this.item.date.getFullYear()
          ) {
            currentDate = new Date(
              eta.getFullYear(),
              eta.getMonth(),
              eta.getDate(),
              date.split(":")[0],
              date.split(":")[1],
              0,
              0
            );

            return currentDate >= eta;
          } else {
            currentDate = new Date(
              this.item.date.getFullYear(),
              this.item.date.getMonth(),
              this.item.date.getDate(),
              date.split(":")[0],
              date.split(":")[1],
              0,
              0
            );

            return currentDate <= etd;
          }
        }
      }
      return true;
    },

    withinOneHour(time1, time2) {
      if (!time1 || !time2) {
        return true;
      }

      function convertToMinutes(time) {
        const [hours, minutes] = time.split(":").map(Number);
        return hours * 60 + minutes;
      }

      // Convertendo as horas para minutos
      const minutes1 = convertToMinutes(time1);
      const minutes2 = convertToMinutes(time2);

      return Math.abs(minutes1 - minutes2) > 60;
    },

    validatePierTimeFrame(vehicles) {
      let valid = true;

      vehicles.forEach((vehicle, index) => {
        if (!vehicle.vehicle_at_pier_time) {
          valid = false;
          return;
        }

        if (!this.isTimeValid(vehicle.vehicle_at_pier_time)) {
          valid = false;
          return;
        }
      });

      return valid;
    },
    createEntryRequest() {
      if (this.$refs.form.validate()) {
        let entryRequest = {
          user_tz: sharedHelpers.getUserTimezone(),
          vessel_call_id: this.item.vessel_call_id,
          date: DateTime.fromJSDate(this.item.date).toFormat("dd/LL/yyyy"),
          vehicles: this.item.vehicles.map((vehicle) => {
            let passengers = [];

            if (
              (vehicle.identify_passengers ||
                vehicle.vehicle_type.code === "TAXI") &&
              vehicle.passengers
            ) {
              passengers = vehicle.passengers.map((passenger) => {
                return {
                  name: passenger.name,
                  date_of_birth: passenger.date_of_birth,
                  country_id: passenger.country ? passenger.country.id : null,
                  special_care_or_assistance:
                    passenger.special_care_or_assistance,
                };
              });
            }

            return {
              id: vehicle.id,
              registration_plate: vehicle.registration_plate,
              number_of_passengers: vehicle.number_of_passengers,
              operator_id: vehicle.operator_id,
              passengers: passengers,
              vehicle_at_pier_time: vehicle.vehicle_at_pier_time,
              responsible_name: vehicle.responsible_name,
              responsible_phone: vehicle.responsible_phone,
            };
          }),
        };

        this.messages = [];
        this.creatingDialog = true;
        this.$store
          .dispatch("entry_requests/CREATE", entryRequest)
          .then((res) => {
            if (res.errors) {
              Object.entries(res.errors).forEach(([, value]) => {
                this.messages = this.messages.concat(value);
              });
              this.creatingDialog = false;
              this.errorDialog = true;
            } else {
              this.creatingDialog = false;
              this.createdDialog = true;
              setTimeout(() => {
                this.createdDialog = false;
                this.close();
              }, 2000);
            }
          })
          .catch((e) => {
            this.creatingDialog = false;
            this.errorDialog = true;
            console.error(e);
          });
      }
    },

    getArchivedPlanDates() {
      this.$store
        .dispatch("plans/GET_ARCHIVED_PLAN_DATES")
        .then((data) => {
          this.archivedPlanDates = data;
        })
        .catch((e) => {
          this.archivedPlanDates = [];
          console.error(e);
        });
    },
  },
};
</script>
<style>
.new-entry-request-dialog {
  height: 90% !important;
}
.new-entry-request-dialog .form-content {
  height: calc(100% - var(--toolbar-height) - 80px);
}

@media (min-width: 960px) {
  .new-entry-request-dialog .form-content {
    height: calc(100% - var(--toolbar-height) - 85px) !important;
  }
}
</style>
