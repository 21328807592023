<template>
  <v-card outlined>
    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title class="text-h5 font-weight-black">{{ item.value }}</v-list-item-title>
        <v-list-item-subtitle class="font-weight-medium">{{ item.title }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-card>
</template>

<script>
export default {
  props: ["item"],
};
</script>
