<template>
  <v-dialog v-model="isOpen" persistent width="900" scrollable>
    <v-card tile>
      <v-toolbar color="primary" flat>
        <v-toolbar-title>
          <span>
            <v-icon left>mdi-pencil</v-icon>{{ $t("entry_requests.edit_vehicle_entry") }}
          </span>
        </v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card outlined>
        <v-card-text>
          <v-form ref="form">
            <v-text-field :rules="vehicleAtPierTimeRules" :label="$t('entry_requests.vehicle_at_pier_time')"
              v-model="itemToEdit.vehicle_at_pier_time" type="time" outlined dense class="mb-2"></v-text-field>
            <v-text-field v-model="itemToEdit.responsible_name" clearable
              append-icon="mdi-badge-account-horizontal-outline" :label="$t('entry_requests.responsible_name')"
              :rules="responsibleNameRules" outlined dense class="mb-2" counter="50" maxlength="50"></v-text-field>
            <v-text-field v-model="itemToEdit.responsible_phone" clearable append-icon="mdi-phone"
              :label="$t('entry_requests.responsible_phone')" :rules="responsiblePhoneRules" outlined dense class="mb-2"
              counter="25" maxlength="25"></v-text-field>
          </v-form>
        </v-card-text>
      </v-card>
      <v-card-actions>
        <v-spacer />
        <v-btn class="black--text" color="primary" depressed @click="save">
          {{ $t("global.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { phoneNumberValidation } from "@/helpers/common_validations.js";
export default {
  props: ["open", "item", "date", "vesselCall"],
  data() {
    return {
      itemToEdit: {},
      responsibleNameRules: [(v) => !!v || this.$t("rules.required_field")],
      responsiblePhoneRules: [
        phoneNumberValidation,
        (v) => !!v || this.$t("rules.required_field"),
        (v) => (v && v.length >= 8) || this.$t("rules.phone_number_length"),

      ],
      vehicleAtPierTimeRules: [
        (v) =>
          !!v ? this.validatePierTimeFrame() : this.$t("rules.required_field"),
      ],
    };
  },
  mounted() {
    this.itemToEdit = JSON.parse(JSON.stringify(this.item));
    if (this.itemToEdit.vehicle_at_pier_time) this.validatePierTimeFrame();
  },

  watch: {
    "itemToEdit.vehicle_at_pier_time": {
      handler: function () {
        this.validatePierTimeFrame();
      },
      deep: true,
    },
  },
  computed: {
    isOpen: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("update:open", val);
      },
    },
    entry: {
      get() {
        return this.item;
      },
      set(val) {
        this.$emit("update:item", val);
      },
    },
  },
  methods: {
    validatePierTimeFrame() {
      if (!this.isTimeValid(this.itemToEdit.vehicle_at_pier_time)) {
        return this.$t("rules.invalid_time");
      }
      return true;
    },
    isTimeValid(date) {
      if (date && typeof date == "string" && this.vesselCall) {
        let eta = new Date(this.vesselCall.eta);
        let etd = new Date(this.vesselCall.etd);
        let currentDate = null;

        if (
          eta.getFullYear() == etd.getFullYear() &&
          eta.getMonth() == etd.getMonth() &&
          eta.getDate() == etd.getDate()
        ) {
          currentDate = new Date(
            eta.getFullYear(),
            eta.getMonth(),
            eta.getDate(),
            date.split(":")[0],
            date.split(":")[1],
            0,
            0
          );

          return currentDate >= eta && currentDate <= etd;
        } else {
          if (
            eta.getDate() == this.date.getDate() &&
            eta.getMonth() == this.date.getMonth() &&
            eta.getFullYear() == this.date.getFullYear()
          ) {
            currentDate = new Date(
              eta.getFullYear(),
              eta.getMonth(),
              eta.getDate(),
              date.split(":")[0],
              date.split(":")[1],
              0,
              0
            );

            return currentDate >= eta;
          } else {
            currentDate = new Date(
              this.date.getFullYear(),
              this.date.getMonth(),
              this.date.getDate(),
              date.split(":")[0],
              date.split(":")[1],
              0,
              0
            );

            return currentDate <= etd;
          }
        }
      }
      return true;
    },
    withinOneHour(time1, time2) {
      if (!time1 || !time2) {
        return true;
      }

      function convertToMinutes(time) {
        const [hours, minutes] = time.split(":").map(Number);
        return hours * 60 + minutes;
      }

      // Convertendo as horas para minutos
      const minutes1 = convertToMinutes(time1);
      const minutes2 = convertToMinutes(time2);

      return Math.abs(minutes1 - minutes2) > 60;
    },
    save() {
      if (this.$refs.form.validate()) {
        Object.assign(this.entry, this.itemToEdit);
        this.close();
      }
    },
    close() {
      this.isOpen = false;
    },
  },
};
</script>
