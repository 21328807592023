<template>
  <div class="ma-4">
    <div class="d-flex flex-wrap">
      <div class="ma-0 pa-0">
        <p class="text-h5 font-weight-black mb-0">
          {{ $t("configurations.noshorex_entries") }}
        </p>
        <p class="text-caption">
          {{ $t("configurations.noshorex_entries_explanation") }}
        </p>
      </div>
      <v-spacer></v-spacer>
      <div
        v-if="hasUserPermissionToViewOrManage('NOSHOREX_ENTRIES')"
        class="ml-auto"
      >
        <div v-if="$vuetify.breakpoint.mobile">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="black--text"
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
                depressed
              >
                <v-icon> mdi-dots-vertical </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-if="hasUserPermissionToViewEditOrManage('NOSHOREX_ENTRIES')"
                @click="add"
              >
                <v-list-item-avatar class="mr-2">
                  <v-icon left>mdi-plus-box-multiple</v-icon>
                </v-list-item-avatar>
                <v-list-item-title>
                  {{ $t("noshorex_entries.new_noshorex_entry") }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="hasUserPermissionToViewEditOrManage('NOSHOREX_ENTRIES')"
                @click="importNoShorexEntries"
              >
                <v-list-item-avatar class="mr-2">
                  <v-icon left> mdi-upload</v-icon>
                </v-list-item-avatar>
                <v-list-item-title>
                  {{ $t("global.import") }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click="exportNoShorexEntries">
                <v-list-item-avatar class="mr-2">
                  <v-icon left>mdi-download</v-icon>
                </v-list-item-avatar>
                <v-list-item-title>
                  {{ $t("global.export") }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <div v-else>
          <v-btn
            v-if="hasUserPermissionToViewEditOrManage('NOSHOREX_ENTRIES')"
            class="black--text"
            color="primary"
            depressed
            @click="add"
          >
            <v-icon left>mdi-plus-box-multiple</v-icon>
            {{ $t("noshorex_entries.new_noshorex_entry") }}
          </v-btn>
          <v-btn
            v-if="hasUserPermissionToViewEditOrManage('NOSHOREX_ENTRIES')"
            class="ml-2 black--text"
            color="primary"
            @click="importNoShorexEntries"
            depressed
          >
            <v-icon left> mdi-upload</v-icon>
            {{ $t("global.import") }}
          </v-btn>
          <v-btn
            class="ml-2 black--text"
            color="primary"
            @click="exportNoShorexEntries"
            depressed
          >
            <v-icon left>mdi-download</v-icon>
            {{ $t("global.export") }}
          </v-btn>
        </div>
      </div>
    </div>
    <List @remove="remove" />
    <CreateItemDialog :open.sync="openAddItemDialog" />
    <DeleteItemDialog :open.sync="openDeleteItemDialog" :item="selectedItem" />
    <ImportItemsDialog :open.sync="openImportItemsDialog" />
    <ExportItemsDialog :open.sync="openExportItemsDialog" />
  </div>
</template>

<script>
import List from "./NoShorexEntries/List";
import CreateItemDialog from "./NoShorexEntries/CreateItemDialog";
import DeleteItemDialog from "./NoShorexEntries/DeleteItemDialog";
import ImportItemsDialog from "./NoShorexEntries/ImportItemsDialog";
import ExportItemsDialog from "./NoShorexEntries/ExportItemsDialog";
export default {
  components: {
    List,
    CreateItemDialog,
    DeleteItemDialog,
    ImportItemsDialog,
    ExportItemsDialog,
  },
  data() {
    return {
      openAddItemDialog: false,
      openDeleteItemDialog: false,
      selectedItem: null,
      openImportItemsDialog: false,
      openExportItemsDialog: false,
    };
  },
  methods: {
    add() {
      this.openAddItemDialog = true;
    },
    remove(noshorex_entry) {
      this.selectedItem = noshorex_entry;
      this.openDeleteItemDialog = true;
    },
    importNoShorexEntries() {
      this.openImportItemsDialog = true;
    },
    exportNoShorexEntries() {
      this.openExportItemsDialog = true;
    },
  },
};
</script>
