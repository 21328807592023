<template>
  <div class="fill-height fill-width ma-1">
    <v-card
      class="fill-width mx-auto"
      outlined
      @click.stop="handleMemberClick"
      :dark="active ? true : false"
    >
      <v-toolbar flat dense>
        <v-toolbar-title class="font-weight-black">
          {{ item.name }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-icon v-if="isTeamOwner">mdi-shield-account</v-icon>
        <v-icon v-else>mdi-account-box</v-icon>
      </v-toolbar>
      <v-card-text
        class="pa-0"
        flat
        style="overflow: hidden; position: relative"
      >
        <div class="backgroundIcon">
          <v-icon color="grey lighten-4" size="80">mdi-user</v-icon>
        </div>
        <v-list dense>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold text-subtitle-1">{{
                item.name || "N/D"
              }}</v-list-item-title>
              <v-list-item-subtitle class="text-caption"
                >{{ $t("users.name") }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="item.email">
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold text-subtitle-1">{{
                item.email || "N/D"
              }}</v-list-item-title>
              <v-list-item-subtitle class="text-caption"
                >{{ $t("users.email") }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold text-subtitle-1">{{
                item.phone || "N/D"
              }}</v-list-item-title>
              <v-list-item-subtitle class="text-caption"
                >{{ $t("users.phone") }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import configs from "@/helpers/configs";
export default {
  props: ["item", "active"],
  computed: {
    operator() {
      return this.$store.state.operators.currentOperator;
    },
    memberStatuses() {
      return configs.getMemberStatuses();
    },
    isTeamOwner() {
      return this.operator && this.item
        ? this.operator.created_by == this.item.id
        : false;
    },
  },
  methods: {
    getMemberStatusColor(item) {
      let status = this.memberStatuses.find((v) => {
        return v.code == item.status;
      });
      if (status) {
        return status.color;
      }
      return null;
    },
    handleMemberClick(e) {
      this.$emit("click", e);
    },
  },
};
</script>
<style scoped>
.backgroundIcon {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  position: absolute;
  width: 100%;
  height: 100%;
  margin-top: 10px;
}
</style>
