<template>
  <v-bottom-sheet v-model="isOpen" persistent>
    <v-card tile v-if="!!item" :loading="loading">
      <v-toolbar flat color="primary">
        <v-toolbar-title class="font-weight-bold">
          <v-icon class="blac--text" left>mdi-boom-gate-arrow-up-outline</v-icon>
          <span class="registration-plate-font">
            {{ item.registration_plate }}</span></v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-progress-linear color="black" indeterminate v-if="isLoading"></v-progress-linear>
      <v-card-text class="px-3 pb-0">
        <v-list three-line>
          <v-row align="start" no-gutters>
            <v-col cols="12">
              <v-list-item v-if="item && item.notes && item.notes.length > 0" class="pl-0" @click="handleViewNote(item)"
                :disabled="loading">
                <v-list-item-avatar>
                  <v-icon> mdi-note</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold text-subtitle-1">
                    {{ $t("global.view_notes") }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="">
                    {{ $t("global.view_note_desc") }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="pl-0" @click="handleRevokeScan(item)" :disabled="loading">
                <v-list-item-avatar>
                  <v-icon> mdi-truck-remove</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold text-subtitle-1">
                    {{ $t("scheduled_entry_scans.revoke_scan") }}:
                    {{ item.scanned_at | formatDatetimeFromDatabase }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="">
                    {{ $t("scheduled_entry_scans.revoke_scan_desc") }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
        </v-list>
      </v-card-text>
    </v-card>
  </v-bottom-sheet>
</template>
<script>
export default {
  props: ["value", "item", "loading"],
  computed: {
    isOpen: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);

        if (!val) {
          // deselect the item
          this.$emit("update:item", null);
        }
      },
    },
    isLoading: {
      get() {
        return this.loading;
      },
      set(val) {
        this.$emit("loading", val);
      },
    },
  },
  methods: {
    handleRevokeScan() {
      this.$emit("revokeScan", this.item);
    },
    handleViewNote() {
      this.$emit("viewNote", this.item);
    },
    close() {
      this.isOpen = false;
    },
  },
};
</script>
<style scoped>
.registration-plate-font {
  font-family: "Fira Mono", monospace !important;
  font-weight: 700 !important;
}
</style>
