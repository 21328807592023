<template>
  <v-autocomplete
    v-model="itemSelected"
    :items="itemsSorted"
    outlined
    :dense="dense"
    :hide-details="hideDetails"
    item-value="id"
    item-text="park"
    :disabled="disabled"
    clearable
    :single-line="singleLine"
    :filter="handleZonesFilter"
    :placeholder="$t('zones.select_zone')"
    :prepend-inner-icon="prependInnerIcon"
    :rules="rules"
    :label="label"
  >
    <template v-slot:selection="data">
      <span class="wrap-text" style="font-size: 14px">{{ data.item.park }} - {{ data.item.code }}</span>
    </template>
    <template v-slot:item="data">
      <span class="wrap-text" style="font-size: 14px">{{ data.item.park }} - {{ data.item.code }}</span>
    </template>
  </v-autocomplete>
</template>
<script>
export default {
  props: {
    label: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false, // Set your default value here
    },
    value: {
      default: null,
    },
    sector_id: {
      default: null,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    // Define default values for other props similarly
    singleLine: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [], // Default to an empty array
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    prependInnerIcon: {
      type: String,
      default: "",
    },
  },
  computed: {
    sectorId() {
      return this.sector_id;
    },
    sectors() {
      return this.$store.state.sectors.all;
    },
    itemsSorted() {
      return JSON.parse(JSON.stringify(this.items)).sort((a, b) => {
        const parkComparison = a.park.localeCompare(b.park, undefined, {
          numeric: true,
        });

        if (parkComparison === 0) {
          return a.code.localeCompare(b.code, undefined, { numeric: true });
        }

        return parkComparison;
      });
    },
    items() {
      if (this.sectorId) {
        let sector = this.sectors.find((s) => s.id == this.sectorId);
        if (sector) {
          return sector.zones;
        }
        return [];
      } else {
        return this.$store.state.zones.all;
      }
    },
    itemSelected: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  watch: {
    sectorId(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.itemSelected = null;
      }
    },
  },
  methods: {
    handleZonesFilter(item, queryText) {
      return (
        (item.code &&
          item.code.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
            -1) ||
        (item.park &&
          item.park
            .toLocaleLowerCase()
            .indexOf(queryText.toLocaleLowerCase())) > -1
      );
    },
  },
};
</script>
