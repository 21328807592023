<template>
  <v-card
    width="100%"
    outlined
    @click="handleItemClicked"
    :class="item.type == 'NOSHOREX' ? 'incognito-vehicle-card' : 'vehicle-card'"
  >
    <v-list-item class="pt-0">
      <v-list-item-content class="pr-1">
        <p
          v-if="item.vehicle_type_code != 'TAXI'"
          class="registration-plate-font mb-0 pb-0 text-h5 text-truncate"
        >
          {{ item.registration_plate }}
        </p>
        <p
          v-else
          class="registration-plate-font mb-0 pb-0 text-h5 text-truncate"
        >
          {{ item.vehicle_license_number }}
        </p>
        <p class="ma-0">
          <v-chip
            color="green"
            text-color="white"
            x-small
            label
            v-if="item.last_scanned_at && item.last_scanned_type == 'ENTRY'"
          >
            {{ $t("global.entered_at") }}
            {{ item.last_scanned_at | formatDatetimeFromDatabase }}
          </v-chip>
          <v-chip
            color="red"
            text-color="white"
            x-small
            label
            v-else-if="item.last_scanned_at && item.last_scanned_type == 'EXIT'"
          >
            {{ $t("global.exited_at") }}
            {{ item.last_scanned_at | formatDatetimeFromDatabase }}
          </v-chip>
          <v-chip color="blue" text-color="white" x-small label v-else>
            {{ $t("global.not_entered") }}
          </v-chip>
        </p>
      </v-list-item-content>
      <v-list-item-avatar
        class="ma-0"
        size="50"
        :color="item.type == 'NOSHOREX' ? 'grey' : 'primary'"
        tile
        right
        width="60px"
      >
        <v-list-item-content>
          <v-list-item-title>
            <v-icon dark v-if="!!item.vehicle_type_icon">{{
              item.vehicle_type_icon
            }}</v-icon>
          </v-list-item-title>
          <v-list-item-subtitle
            class="text-caption font-weight-black white--text"
          >
            {{
              item.entry_type
                ? this.$t("entry_types." + item.entry_type.toLowerCase())
                : ""
            }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item-avatar>
    </v-list-item>
    <v-divider class="ml-4 mr-4 mt-0"></v-divider>
    <div class="pa-4 pt-2 pb-2 text-caption">
      <v-row>
        <v-col cols="8">
          <p class="ma-0 pa-0 text-uppercase text-truncate">
            <b>Entra às: </b>
            {{ item.vehicle_at_pier_start | formatDatetimeFromDatabase }}
          </p>

          <p class="ma-0 pa-0 text-uppercase text-truncate">
            <b>Sai às: </b>
            {{ item.vehicle_at_pier_end | formatDatetimeFromDatabase }}
          </p>

          <p class="ma-0 pa-0 text-uppercase text-truncate">
            <b>{{ $t("scheduled_entries.operator_name") }}:</b>
            {{ item.operator_name }}
          </p>

          <p class="ma-0 pa-0 text-uppercase text-truncate">
            <b>{{ $t("global.vessel_call") }}:</b>
            {{ item.vessel_call_name ? item.vessel_call_name : "N/D" }}
          </p>

          <p class="ma-0 pa-0 text-uppercase text-truncate">
            <b>Estacionamento: </b>
            <span v-if="item.zone_name">{{ item.zone_name }}</span>
            <span v-else>N/D</span>
          </p>
        </v-col>

        <v-col cols="4" class="text-right">
          <p class="ma-0 pa-0 text-uppercase text-truncate">
            {{ item.vehicle_type_name }}
          </p>

          <p class="ma-0 pa-0 text-uppercase text-truncate">
            {{ item.vehicle_brand }}
          </p>

          <p class="ma-0 pa-0 text-uppercase text-truncate">
            {{ item.vehicle_model }}
          </p>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>
<script>
export default {
  props: ["item"],
  methods: {
    handleItemClicked() {
      if (this.hasUserPermissionToViewEditOrManage("SCHEDULED_ENTRIES"))
        this.$emit("selected", this.item);
    },
  },
};
</script>
<style scoped>
.wrapped-text {
  white-space: normal;
  max-width: 100px;
}

.incognito-vehicle-card {
  border: 1px solid #616161;
}

.vehicle-card {
  border: 1px solid #fdb813;
}

.registration-plate-font {
  font-family: "Fira Mono", monospace !important;
  font-weight: 700 !important;
}
</style>
