<template>
  <v-dialog v-model="isOpen" persistent width="900">
    <v-card tile>
      <v-toolbar color="primary" flat>
        <v-toolbar-title>
          <span> <v-icon left>mdi-plus</v-icon>{{ $t("sectors.copy") }} </span>
        </v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="overflow-y-auto pb-0 form-content">
        <Loading
          v-if="copying"
          :value="copying"
          :custom-message="$t('sectors.copying_please_wait')"
        ></Loading>
        <v-form v-else>
          <v-alert
            color="blue-grey"
            text
            icon="mdi-information-outline"
            class="mt-4"
          >
            <div v-html="$t('sectors.copy_help')"></div>
          </v-alert>
          <v-alert
            color="error"
            text
            icon="mdi-alert"
            class="mt-4"
          >
            <div v-html="$t('sectors.copy_alert')"></div>
          </v-alert>
          <p class="text-right">
            <v-avatar size="10" color="primary" class="mr-1"></v-avatar
            >{{ $t("sectors.days_with_plans") }}
          </p>

          <v-date-picker
            v-model="selectedDate"
            :picker-date.sync="pickerDate"
            :disabled="loading"
            locale="pt"
            ref="datepicker"
            no-title
            show-current
            show-adjacent-months
            event-color="primary"
            :events="planDays"
            :allowed-dates="getAllowedDates"
            color="primary"
            full-width
            class="mt-4"
          >
            <template #default>
              <v-progress-linear
                striped
                color="primary"
                indeterminate
                v-show="loading"
              ></v-progress-linear> </template
          ></v-date-picker>
          <p class="text-center text-h5">
            {{ selectedDate | formatDateFromDatabase }}
          </p>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer />
        <v-btn
          class="black--text"
          color="primary"
          depressed
          :disabled="loading || copying || !planIsSelected"
          @click.stop="copySectors"
        >
          {{ $t("global.copy") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import Loading from "@/components/Main/Loading";
import { DateTime } from "luxon";
export default {
  props: ["open", "plan"],
  components: { Loading },
  data() {
    return {
      copying: false,
      selectedDate: null,
      pickerDate: null,

      selectedPlan: null,
    };
  },
  computed: {
    planIsSelected() {
      return !!this.selectedDate;
    },
    isOpen: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("update:open", val);
      },
    },
    planDays() {
      return this.plans
        ? this.plans
            .filter((p) => {
              return this.plan && p.id !== this.plan.id;
            })
            .map((p) => {
              return p.day;
            })
        : [];
    },
    loading() {
      return this.$store.state.plans.loading;
    },
    plans() {
      return this.$store.state.plans.all;
    },
  },
  watch: {
    isOpen(val) {
      if (val) {
        this.selectedDate = null;
        this.loadPlans();
      }
    },
    pickerDate(val) {
      if (val) {
        this.loadPlans();
      }
    },
  },
  methods: {
    getAllowedDates(v) {
      return this.planDays.includes(v);
    },
    loadPlans() {
      if (this.pickerDate) {
        let startOfMonth = DateTime.fromFormat(
          this.pickerDate + "-01",
          "yyyy-LL-dd"
        );
        let endOfMonth = startOfMonth.plus({ months: 1, days: -1 });

        this.$store
          .dispatch("plans/GET_ALL", {
            startDate: startOfMonth.toFormat("dd/LL/yyyy"),
            endDate: endOfMonth.toFormat("dd/LL/yyyy"),
          })
          .catch((e) => console.error(e));
      }
    },
    close() {
      this.isOpen = false;
      setTimeout(() => {
        this.copying = false;
      }, 600);
    },
    copySectors() {
      if (this.selectedDate) {
        this.copying = true;
        this.$store
          .dispatch("sectors/COPY_SECTORS", {
            toPlanId: this.plan.id,
            fromPlanDate: this.selectedDate,
          })
          .then(() => {
            this.close();
          })
          .catch((ex) => {
            this.copying = false;
            console.error(ex);
          });
      }
    },
  },
};
</script>
<style scoped>
.form-content {
  position: relative;
  min-height: 500px;
  max-height: calc(80vh - var(--toolbar-height));
}
</style>
