<template>
  <v-dialog v-model="isOpen" persistent width="90vw">
    <v-card tile>
      <v-expand-transition>
        <div v-show="expand">
          <v-alert class="mb-0" tile type="error" @click="expand = false">
            <div
              v-for="(message, index) in this.messages"
              :key="index"
              class="subtitle"
            >
              {{ message }}
            </div>
          </v-alert>
        </div>
      </v-expand-transition>
      <v-progress-linear
        height="10"
        striped
        color="primary"
        indeterminate
        v-show="loading"
      ></v-progress-linear>
      <v-toolbar color="primary" flat>
        <v-toolbar-title>
          <span>
            <v-icon left> mdi-upload</v-icon>{{ $t("operators.import") }}
          </span>
        </v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="mt-5">
        <v-alert color="blue-grey" text icon="mdi-information-outline">
          <div class="font-weight-black">
            {{ $t("operators.import_operators_help_title") }}
          </div>
          <div class="mt-2">
            <div
              class="ma-0"
              v-html="$t('operators.import_operators_help_description')"
            ></div>
          </div>
        </v-alert>
        <v-form ref="form" class="mt-5" :disabled="loading">
          <v-file-input
            v-model="file"
            show-size
            accept=".csv"
            outlined
            prepend-icon
            prepend-inner-icon="mdi-microsoft-excel"
            :error-messages="fileUploadErrorMessage"
            :rules="fileRules"
            persistent-hint
            :label="$t('operators.import_file')"
            @change="previewFile"
          ></v-file-input>
        </v-form>
        <div>
          <!-- Preview before upload list -->
          <OperatorList
            v-if="canShowItemsToImport"
            :loading="loadingFileData"
            :items="itemsToImport"
          >
            <template v-slot:title>
              <v-icon left>mdi-upload</v-icon>
              {{ $t("operators.operators_to_import") }}
            </template>
          </OperatorList>

          <!-- After import list -->
          <div
            v-if="canShowListBriefing"
            style="position: relative; min-height: 200px"
          >
            <Loading
              v-if="loading"
              :value="loading"
              :custom-message="$t('operators.importing_operators_please_wait')"
            ></Loading>
            <OperatorList
              v-else
              :items="itemsWithError"
              :with-error-description="true"
            >
              <template v-slot:title>
                <v-icon left>mdi-alert-circle-outline</v-icon>
                {{ $t("operators.errors_importing_operators") }}
              </template>
              <template v-slot:subtitle>
                {{ $t("operators.operators_were_not_created_please_review") }}
              </template></OperatorList
            >
          </div>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer />
        <v-btn
          class="black--text"
          color="primary"
          depressed
          :disabled="loading"
          @click="save"
        >
          <v-icon left> mdi-upload</v-icon>{{ $t("global.import") }}
        </v-btn>
      </v-card-actions></v-card
    >
  </v-dialog>
</template>
<script>
import Loading from "@/components/Main/Loading";
import OperatorList from "@/components/Configurations/Operators/OperatorList";
import Papa from "papaparse";
export default {
  props: ["open"],
  components: { Loading, OperatorList },
  data() {
    return {
      loading: false,
      loadingFileData: false,
      expand: false,
      messages: [],
      fileRules: [(v) => !!v || this.$t("rules.file_required")],
      file: null,
      fileUploadErrorMessage: "",
      imported: false,
      itemsToImport: [],
      itemsWithError: [],
    };
  },
  computed: {
    isOpen: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("update:open", val);
      },
    },
    canShowItemsToImport() {
      return !!this.file && !this.loading && !this.imported;
    },
    canShowListBriefing() {
      return !!this.file && (this.loading || this.imported);
    },
  },
  mounted() {
    if (this.$refs.form) this.$refs.form.reset();
  },
  methods: {
    close() {
      this.isOpen = false;
      this.expand = false;
      this.loading = false;
      this.imported = false;
      this.$refs.form.reset();
    },
    save() {
      this.expand = false;
      this.loading = true;
      this.messages = [];
      this.itemsWithError = [];
      if (this.$refs.form.validate()) {
        this.$store
          .dispatch("operators/IMPORT", this.file)
          .then((res) => {
            this.imported = true;
            if (res.errors && res.errors.length > 0) {
              this.itemsWithError = res.errors.map((e) => {
                return {
                  ...e.record,
                  message: e.message,
                };
              });
              this.loading = false;
              this.messages = [this.$t("global.file_import_error")];
              this.expand = true;
            } else {
              this.$refs.form.reset();
              this.close();
            }
          })
          .catch(() => {
            this.messages = [this.$t("global.file_import_error")];
            this.expand = true;
            this.loading = false;
          });
      } else {
        this.expand = false;
        this.loading = false;
      }
    },

    previewFile(file) {
      if (file) {
        this.loadingFileData = true;
        this.itemsToImport = [];
        Papa.parse(file, {
          delimiter: "", // auto-detect
          newline: "", // auto-detect
          quoteChar: '"',
          escapeChar: '"',
          header: true,
          encoding: "ISO-8859-1",
          complete: (e) => {
            this.itemsToImport = e.data;
            this.loadingFileData = false;
          },
          error: (e) => {
            console.error("Error.", e);
            this.loadingFileData = false;
          },
          skipEmptyLines: true,
        });
      } else {
        this.itemsToImport = [];
        this.itemsWithError = [];
        this.imported = false;
      }
    },
  },
};
</script>
