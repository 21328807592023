<template>
  <v-container class="container--fluid fill-height generic-page">
    <v-row no-gutters justify="center" align-content="center">
      <v-col cols="12" style="max-width: 1000px">
        <v-card class="outlined pa-2">
          <v-card-text
            v-show="!showNotification"
            style="overflow-y: auto; max-height: 90vh"
          >
            <div class="layout column align-center">
              <v-img src="@/assets/logo.png" alt="Logo" width="60" />
              <h1
                class="text-center my-1 mb-3 text-uppercase font-weight-black"
                style="font-size: 1.8rem"
                color="blue-darken-1"
              >
                3Shorex
              </h1>
              <h2
                class="text-center my-1 mb-10 text-uppercase font-weight-bold"
                color="blue-darken-1"
              >
                Registo de Utilizador e Operador
              </h2>
            </div>
            <SignupForm
              @success="success"
              :type="this.$route ? this.$route.query.type : null"
            ></SignupForm>
          </v-card-text>
          <v-card-text v-show="showNotification">
            <div class="layout column align-center">
              <v-img src="@/assets/logo.png" alt="Logo" width="60" />
              <h2
                class="text-center my-1 mb-5 text-uppercase font-weight-black"
                color="blue-darken-1"
              >
                3Shorex
              </h2>
            </div>
            <p class="text-body-1 text-center mt-5">
              {{ $t("login.verification_message_1") }}
            </p>

            <p class="text-body-1 text-center">
              {{ $t("login.verification_message_2") }}
            </p>
            <v-btn
              text
              color="grey darken-2"
              exact
              large
              block
              @click="goToLogin"
            >
              {{ $t("login.return_to_login_btn") }}
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SignupForm from "@/pages/Auth/SignupForm.vue";

export default {
  data: () => ({
    showNotification: false,
    type: null,
  }),
  components: {
    SignupForm,
  },

  methods: {
    success() {
      this.showNotification = true;
    },
    goToLogin() {
      this.$router.push("/login");
    },
  },
};
</script>
<style>
.generic-page {
  background-image: url("../../assets/background.png");
  background-color: #1a202c !important;
  background-repeat: no-repeat;
  --background-attachment: fixed;
  background-position: bottom;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  transition: background-color 5000s ease-in-out 0s;
  font-size: 14px;
  line-height: 32px;
  -webkit-text-fill-color: black;
}

.v-text-field--filled:not(.v-text-field--single-line) input {
  margin-top: 5px !important;
}

input:-webkit-autofill::first-line {
  font-size: 14px;
}
</style>
