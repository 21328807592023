<template>
  <v-dialog v-model="isOpen" persistent width="60%" scrollable>
    <v-card tile>
      <v-progress-linear
        height="10"
        color="primary"
        indeterminate
        v-show="loading"
      ></v-progress-linear>
      <v-toolbar color="primary" flat>
        <v-toolbar-title>
          <span>
            <v-icon left>mdi-lock</v-icon>{{ $t("users.permissions") }}</span
          >
        </v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pa-2" style="height: 600px">
        <v-expansion-panels class="mb-5" dense flat>
          <v-expansion-panel style="background-color: #ccc">
            <v-expansion-panel-header class="text-h6" dense>{{
              $t("users.permissions_explanation")
            }}</v-expansion-panel-header>
            <v-expansion-panel-content dense>
              <v-list two-line dense>
                <v-list-item>
                  <template>
                    <v-list-item-icon>
                      <v-icon>mdi-eye-off</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title
                        ><strong>{{
                          $t("global.no_access")
                        }}</strong></v-list-item-title
                      >
                      <v-list-item-subtitle class="text--primary text-wrap">{{
                        $t("global.no_recurs")
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item>
                  <template>
                    <v-list-item-icon>
                      <v-icon>mdi-eye</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title
                        ><strong>{{
                          $t("global.only_view")
                        }}</strong></v-list-item-title
                      >
                      <v-list-item-subtitle class="text--primary text-wrap">{{
                        $t("global.only_view_explanation")
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item>
                  <template>
                    <v-list-item-icon>
                      <v-icon>mdi-eye-plus</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title
                        ><strong>{{
                          $t("global.view_edit")
                        }}</strong></v-list-item-title
                      >
                      <v-list-item-subtitle class="text--primary text-wrap">{{
                        $t("global.view_edit_explanation")
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item>
                  <template>
                    <v-list-item-icon>
                      <v-icon>mdi-pencil</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title
                        ><strong>{{
                          $t("global.full_access")
                        }}</strong></v-list-item-title
                      >
                      <v-list-item-subtitle class="text--primary text-wrap">{{
                        $t("global.full_access_explanation")
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-list-item>
              </v-list>
            </v-expansion-panel-content>
          </v-expansion-panel></v-expansion-panels
        >
        <div>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left text-uppercase">
                    {{ $t("permissions.resource") }}
                  </th>
                  <th width="80px" class="text-center">
                    <v-btn
                      title="Seleccionar todos"
                      x-small
                      text
                      @click="selectAll('NO-ACCESS')"
                    >
                      <v-icon small left>mdi-eye-off</v-icon
                      >{{ $t("permissions.no_access") }}
                    </v-btn>
                  </th>
                  <th width="80px" class="text-center">
                    <v-btn
                      title="Seleccionar todos"
                      x-small
                      text
                      @click="selectAll('VIEW')"
                    >
                      <v-icon small left>mdi-eye</v-icon
                      >{{ $t("permissions.only_view") }}
                    </v-btn>
                  </th>
                  <th width="80px" class="text-center">
                    <v-btn
                      title="Seleccionar todos"
                      x-small
                      text
                      @click="selectAll('VIEW_EDIT')"
                    >
                      <v-icon small left>mdi-eye</v-icon
                      >{{ $t("permissions.view_edit") }}
                    </v-btn>
                  </th>
                  <th width="80px" class="text-center">
                    <v-btn
                      title="Seleccionar todos"
                      x-small
                      text
                      @click="selectAll('MANAGE')"
                    >
                      <v-icon small left>mdi-pencil</v-icon
                      >{{ $t("permissions.full_control") }}
                    </v-btn>
                  </th>
                </tr>
              </thead>
              <tbody style="height: 250px; overflow-y: auto">
                <tr v-for="permission in permissions" :key="permission.id">
                  <td>
                    <v-icon left>{{ permission.icon }}</v-icon
                    >{{ getPermissionName(permission.code) }}
                  </td>
                  <td class="text-center">
                    <v-radio-group row v-model="permission.profile">
                      <v-radio value="NO-ACCESS"></v-radio>
                    </v-radio-group>
                  </td>
                  <td class="text-center">
                    <v-radio-group row v-model="permission.profile">
                      <v-radio value="VIEW"></v-radio>
                    </v-radio-group>
                  </td>
                  <td class="text-center">
                    <v-radio-group row v-model="permission.profile">
                      <v-radio value="VIEW_EDIT"></v-radio>
                    </v-radio-group>
                  </td>
                  <td class="text-center">
                    <v-radio-group row v-model="permission.profile">
                      <v-radio value="MANAGE"></v-radio>
                    </v-radio-group>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" text @click="save" :disabled="loading">
          {{ $t("global.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import configs from "@/helpers/configs";
export default {
  props: ["open", "item"],
  data() {
    return {
      loading: false,
      permissions: configs.getDefaultPermissions(),
    };
  },
  computed: {
    isOpen: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("update:open", val);
      },
    },
  },
  watch: {
    open() {
      this.permissions = JSON.parse(
        JSON.stringify(configs.getDefaultPermissions())
      );
      if (this.item && this.item.user_permissions) {
        this.item.user_permissions.forEach((up) => {
          let permission = this.permissions.find((p) => {
            return p.code == up.code;
          });
          let idx = this.permissions.findIndex((p) => {
            return p.code == up.code;
          });
          if (idx !== -1) {
            up.icon = permission.icon;
            this.permissions.splice(idx, 1, up);
          }
        });
      }
    },
  },
  methods: {
    close() {
      this.isOpen = false;
    },
    save() {
      this.loading = true;
      this.$store
        .dispatch("users/UPDATE_USER_PERMISSIONS", {
          user: this.item,
          permissions: this.permissions,
        })
        .then(() => {
          this.loading = false;
          this.close();
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getPermissionName(code) {
      return this.$t("permissions." + code.toLowerCase());
    },
    selectAll(profile) {
      if (this.permissions) {
        this.permissions.forEach((p) => {
          p.profile = profile;
        });
      }
    },
  },
};
</script>

<style scoped>
.theme--light.v-list {
  background: #ccc !important;
}
</style>
