import configs from "@/helpers/configs";
import axios from "axios";
import { sharedHelpers } from "@/api_services/utils/shared-helpers";

export const shorexService = {
  getScheduling,
  getMatching,
  setScheduling,
  emitUnauthorization,
  emitAuthorization,
  createEntryRequest,
  importEntryRequests,
  revertUnauthorization,
  emitMultipleUnauthorizations,
  emitMultipleAuthorizations,
};

async function getScheduling(date) {
  const requestOptions = {
    method: "GET",
    headers: sharedHelpers.getRequestHeaders(),
    params: {
      timeRange: date.timeRange,
    },
  };
  return await axios.get(
    configs.getApiUrl() + "/v1/shorex/" + date.day + "/scheduling",
    requestOptions
  );
}

async function setScheduling(day, entries) {
  const requestOptions = {
    method: "POST",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.post(
    configs.getApiUrl() + "/v1/shorex/" + day + "/scheduling",
    { entries: entries },
    requestOptions
  );
}

async function getMatching(vessel_call_id, passengers) {
  const requestOptions = {
    method: "POST",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.post(
    configs.getApiUrl() + "/v1/shorex/" + vessel_call_id + "/matching",
    { passengers: passengers },
    requestOptions
  );
}

async function emitAuthorization(day, entry, image) {
  const requestOptions = {
    method: "POST",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.post(
    configs.getApiUrl() + "/v1/shorex/" + day + "/emit-authorization",
    { entry: entry, image: image },
    requestOptions
  );
}

async function emitMultipleAuthorizations(day, entries) {
  const requestOptions = {
    method: "POST",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.post(
    configs.getApiUrl() + "/v1/shorex/" + day + "/emit-multiple-authorizations",
    entries,
    requestOptions
  );
}

async function emitUnauthorization(payload) {
  const requestOptions = {
    method: "POST",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.post(
    configs.getApiUrl() + "/v1/shorex/emit-unauthorization",
    payload,
    requestOptions
  );
}

async function emitMultipleUnauthorizations(entries) {
  const requestOptions = {
    method: "POST",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.post(
    configs.getApiUrl() + "/v1/shorex/emit-multiple-unauthorizations",
    entries,
    requestOptions
  );
}

async function revertUnauthorization(id) {
  const requestOptions = {
    method: "PUT",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.put(
    configs.getApiUrl() + `/v1/shorex/${id}/revert-unauthorization`,
    {},
    requestOptions
  );
}

async function createEntryRequest(payload) {
  const requestOptions = {
    method: "POST",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.post(
    configs.getApiUrl() + `/v1/shorex/${payload.day}/create-entry-request`,
    payload,
    requestOptions
  );
}

async function importEntryRequests(payload) {
  const requestOptions = {
    method: "POST",
    headers: sharedHelpers.getRequestHeaders(),
  };
  var bodyFormData = new FormData();
  bodyFormData.append("file", payload.file);

  return await axios.post(
    configs.getApiUrl() + "/v1/shorex/entry-requests/import",
    bodyFormData,
    requestOptions
  );
}
