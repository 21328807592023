<template>
  <v-container fluid class="pa-0" v-if="!isAdminUser">
    <div class="pa-4" v-if="isShorexUser">
      <v-row align="start">
        <v-col cols="12">
          <WelcomeHeader></WelcomeHeader>
        </v-col>
        <v-col cols="12" md="4" v-if="isShorexUser && userOperator">
          <v-card class="pa-2" outlined tile>
            <VehicleEntryRequests></VehicleEntryRequests>
          </v-card>
        </v-col>
        <v-col cols="12" md="4" v-if="isShorexUser && userOperator">
          <v-card class="pa-2" outlined tile>
            <Vehicles></Vehicles>
          </v-card>
        </v-col>
        <v-col cols="12" md="4" v-if="isShorexUser && userOperator">
          <v-card class="pa-2" outlined tile>
            <Members></Members>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
  <v-container fluid fill-height class="pa-0" v-else>
    <Map></Map>
  </v-container>
</template>
<script>
import WelcomeHeader from "@/components/Main/Home/WelcomeHeader";
import Vehicles from "@/components/Main/Vehicles/Vehicles";
import Members from "@/components/Main/MembersOperator/Members";
import VehicleEntryRequests from "@/components/Main/EntryRequests/VehicleEntryRequests";
import Map from "@/components/Main/Map";
import configs from "@/helpers/configs";

export default {
  components: {
    Vehicles,
    Members,
    WelcomeHeader,
    VehicleEntryRequests,
    Map,
  },
  computed: {
    userAccount() {
      return this.$store.state.user.account;
    },
    userType() {
      return this.userAccount && this.userAccount.user
        ? this.userAccount.user.type
        : "";
    },
    userOperator() {
      return this.userAccount && this.userAccount.user
        ? this.userAccount.user.operator
        : null;
    },

    isShorexUser() {
      return this.userType == configs.getShorexUserType();
    },
    isAdminUser() {
      return (
        this.userType == configs.getAdminUserType() ||
        this.userType == configs.getManagerUserType()
      );
    },
  },
};
</script>
