<template>
  <div
    class="d-flex flex-grow-1 flex-column pt-4 px-4"
    style="height: 100%; overflow-y: auto"
  >
    <div class="flex-grow-0">
      <div class="d-flex flex-wrap">
        <div class="ma-0 pa-0">
          <p class="text-h5 font-weight-bold mb-0">
            {{ $t("scheduled_entries.my_entry_requests") }}
          </p>
          <p class="text-caption">
            {{ $t("scheduled_entries.my_entry_requests_help") }}
          </p>
        </div>
        <v-spacer></v-spacer>
        <v-menu offset-y v-if="$vuetify.breakpoint.mobile">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :disabled="loading"
              class="ml-2 black--text"
              color="primary"
              depressed
              fab
              small
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="handleNewEntryRequestClick">
              <v-list-item-title>
                <v-icon left>mdi-car</v-icon
                >{{
                  $t("scheduled_entries.request_new_entry")
                }}</v-list-item-title
              >
            </v-list-item>
            <v-list-item
              @click="handleImportEntryRequestsClick"
              v-if="operator && operator.accredited"
            >
              <v-list-item-title>
                <v-icon left> mdi-upload</v-icon
                >{{ $t("scheduled_entries.import") }}</v-list-item-title
              >
            </v-list-item>
            <v-list-item @click="handleExportEntryRequestsClick">
              <v-list-item-title>
                <v-icon left>mdi-download</v-icon
                >{{ $t("scheduled_entries.export") }}</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn
          class="black--text"
          v-if="!$vuetify.breakpoint.mobile"
          :disabled="loading"
          color="primary"
          depressed
          @click="handleNewEntryRequestClick"
        >
          <v-icon left>mdi-boom-gate-arrow-up-outline</v-icon
          >{{ $t("scheduled_entries.request_new_entry") }}
        </v-btn>
        <v-btn
          class="ml-2 black--text"
          v-if="!$vuetify.breakpoint.mobile && operator && operator.accredited"
          :disabled="loading"
          color="primary"
          depressed
          @click="handleImportEntryRequestsClick"
        >
          <v-icon left> mdi-upload</v-icon
          >{{ $t("scheduled_entries.import") }}
        </v-btn>
        <v-btn
          class="ml-2 black--text"
          v-if="!$vuetify.breakpoint.mobile"
          :disabled="loading"
          color="primary"
          depressed
          @click="handleExportEntryRequestsClick"
        >
          <v-icon left>mdi-download</v-icon
          >{{ $t("scheduled_entries.export") }}
        </v-btn>
      </div>

      <v-row class="pa-0 my-1">
        <v-col cols="6">
          <v-text-field
            :disabled="loading"
            v-model="search"
            clearable
            flat
            solo
            outlined
            hide-details
            prepend-inner-icon="mdi-magnify"
            :label="$t('global.search')"
          ></v-text-field>
        </v-col>
        <v-col cols="3" align-self="center">
          <v-select
            v-model="groupBy"
            :items="groups"
            outlined
            :label="$t('global.group_by')"
            hide-details
            :disabled="loading"
          >
            <template slot="selection" slot-scope="data">
              {{ $t(`entry_requests_group.${data.item}`) }}
            </template>
            <template slot="item" slot-scope="data">
              {{ $t(`entry_requests_group.${data.item}`) }}
            </template>
          </v-select>
        </v-col>
        <v-col align-self="center">
          <v-menu
            v-model="dateMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            :offset-x="$vuetify.breakpoint.mobile ? true : false"
            :offset-y="$vuetify.breakpoint.mobile ? false : true"
            :left="$vuetify.breakpoint.mobile ? true : false"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date"
                label="Dia"
                append-icon="mdi-calendar"
                readonly
                flat
                solo
                outlined
                hide-details
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="date" @input="dateMenu = false">
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
    </div>

    <div
      class="flex-grow-1"
      style="overflow-y: auto; overflow-x: hidden; min-height: 400px"
    >
      <v-data-iterator
        :items="items"
        :custom-filter="itemsFilter"
        item-key="id"
        :items-per-page="4"
        hide-default-footer
        :group-by="groupBy"
        :custom-group="customGroup"
        :search="search"
        :loading="loading"
        disable-pagination
      >
        <template v-slot:no-results>
          <v-container fill-height fluid>
            <v-row align="center" justify="center" class="text-h6 text-center">
              <v-icon left>mdi-boom-gate-alert-outline</v-icon
              >{{ $t("scheduled_entries.no_entry_requests_found") }}
            </v-row></v-container
          >
        </template>
        <template v-slot:no-data>
          <v-container fill-height fluid>
            <v-row align="center" justify="center" class="text-h6 text-center">
              <v-icon left>mdi-boom-gate-alert-outline</v-icon
              >{{ $t("scheduled_entries.no_entry_requests_found") }}
            </v-row></v-container
          >
        </template>
        <template v-slot:loading>
          <div style="position: relative; min-height: 250px">
            <Loading v-model="loading"></Loading>
          </div>
        </template>
        <template v-slot:group="{ group }">
          <h3>{{ group }}</h3>
        </template>
        <template v-slot:default="{ groupedItems }">
          <div v-for="groupedItem in groupedItems" :key="groupedItem.name">
            <p class="text-h6 ml-2 font-weight-black text-uppercase">
              {{
                groupBy === "vehicle_at_pier_date"
                  ? getDateGroupTitle(groupedItem.name)
                  : groupedItem.name
              }}
            </p>

            <v-row>
              <v-col
                v-for="item in groupedItem.items"
                :key="item.id"
                cols="12"
                sm="6"
                md="4"
                lg="3"
              >
                <VehicleEntryRequest
                  :item="item"
                  @click.stop="handleEntryClick(item)"
                ></VehicleEntryRequest>
              </v-col>
            </v-row>
            <v-divider class="my-4"></v-divider>
          </div>
        </template>
      </v-data-iterator>
    </div>

    <v-snackbar :timeout="5000" :value="showImportedMessage" top absolute center color="success">
      <h3 class="text-center"><v-icon left>mdi-check-circle-outline</v-icon>{{ $t("global.import_successful") }}</h3>
    </v-snackbar>

    <VehicleEntryRequestDetails
      v-model="viewEntryRequestDetails"
      :item.sync="selectedItem"
    ></VehicleEntryRequestDetails>

    <VehicleScheduledEntryDetails
      v-model="viewScheduledEntryDetails"
      :item.sync="selectedItem"
    ></VehicleScheduledEntryDetails>

    <NewEntryRequestForm v-model="addEntryRequestForm"></NewEntryRequestForm>
    <ImportEntryRequestsForm
      :open.sync="importEntryRequestsForm"
      :success.sync="showImportedMessage"
      @entryRequestsimported="loadEntryRequests"
    ></ImportEntryRequestsForm>
    <ExportEntryRequestsDialog
      :open.sync="exportEntryRequestsDialog"
      :date="date"
    ></ExportEntryRequestsDialog>
  </div>
</template>

<script>
import VehicleEntryRequestDetails from "@/components/Main/EntryRequests/VehicleEntryRequestDetails";
import VehicleScheduledEntryDetails from "@/components/Main/EntryRequests/VehicleScheduledEntryDetails";
import VehicleEntryRequest from "@/components/Main/EntryRequests/VehicleEntryRequest";
import NewEntryRequestForm from "@/components/Main/EntryRequests/NewEntryRequestForm";
import ImportEntryRequestsForm from "@/components/Main/EntryRequests/ImportEntryRequestsForm";
import ExportEntryRequestsDialog from "@/components/Main/EntryRequests/ExportEntryRequestsDialog";
import Loading from "@/components/Main/Loading";
import { DateTime } from "luxon";

export default {
  components: {
    NewEntryRequestForm,
    VehicleEntryRequest,
    Loading,
    VehicleEntryRequestDetails,
    VehicleScheduledEntryDetails,
    ImportEntryRequestsForm,
    ExportEntryRequestsDialog,
  },
  computed: {
    loading() {
      return this.$store.state.entry_requests.loading;
    },
    items() {
      return this.$store.state.entry_requests.all.map((item) => {
        return {
          ...item,
          vessel_call_name: item.vessel_call.name,
        };
      });
    },
    operator() {
      return this.$store.state.operators.currentOperator;
    },
  },
  data() {
    return {
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      dateMenu: false,
      search: "",
      viewEntryRequestDetails: false,
      viewScheduledEntryDetails: false,
      selectedItem: null,
      addEntryRequestForm: false,
      importEntryRequestsForm: false,
      exportEntryRequestsDialog: false,
      groupBy: "status",
      groups: ["status", "vessel_call_name"],
      showImportedMessage: false,
    };
  },
  watch: {
    selectedItem() {
      if (this.selectedItem) {
        if (this.selectedItem.scheduled_entry) {
          this.viewScheduledEntryDetails = true;
        } else {
          this.viewEntryRequestDetails = true;
        }
      }
    },
    date() {
      this.loadEntryRequests();
    },
    addEntryRequestForm(val) {
      if (!val) this.loadEntryRequests();
    },
  },
  mounted() {
    this.loadEntryRequests();
  },
  methods: {
    customGroup(items, groupBy) {
      const key = groupBy[0];
      if (key === "status") {
        return this.groupByVehicleStatus(items, key);
      } else if (key === "vessel_call_name") {
        return this.groupByVesselCallName(items, key);
      }
    },
    groupByVehicleStatus(items, key) {
      const groups = items.reduce((rv, x) => {
        const groupName = x[key];
        (rv[groupName] = rv[groupName] || []).push(x);
        return rv;
      }, {});

      return Object.keys(groups).map((key) => ({
        name: key
          ? this.$t("entry_states." + key.toLowerCase()) +
            " (" +
            groups[key].length +
            ")"
          : null,
        items: groups[key],
      }));
    },
    groupByVesselCallName(items, key) {
      const groups = items.reduce((rv, x) => {
        const groupName = x[key];
        (rv[groupName] = rv[groupName] || []).push(x);
        return rv;
      }, {});
      return Object.keys(groups).map((key) => ({
        name: key,
        items: groups[key],
      }));
    },
    getDateGroupTitle(date) {
      let lDate = DateTime.fromFormat(date, "dd/LL/yyyy");
      if (DateTime.now().startOf("day").hasSame(lDate, "day")) {
        return this.$t("global.today") + ` (${date})`;
      } else if (
        DateTime.now().plus({ days: 1 }).startOf("day").hasSame(lDate, "day")
      ) {
        return this.$t("global.tomorrow") + ` (${date})`;
      } else {
        return `${lDate.weekdayLong} (${date})`;
      }
    },
    handleEntryClick(item) {
      this.selectedItem = item;
    },
    loadEntryRequests() {
      this.$store
        .dispatch("entry_requests/GET_ALL_BY_DATE", this.date)
        .catch((e) => {
          console.error(e);
        });
    },
    itemsFilter(items, search) {
      if (!search || search === "") return items;

      // Remove dashes from the search string
      const sanitizedSearch = search.replace(/-/g, "");

      // Create a search regex without dashes
      const searchRegex = new RegExp(sanitizedSearch, "i");

      return this.items.filter((item) => {
        // Remove dashes from the registration plate before testing against the search regex
        const sanitizedPlate = item.vehicle.registration_plate.replace(
          /-/g,
          ""
        );
        return (
          searchRegex.test(sanitizedPlate) ||
          searchRegex.test(item.vessel_call.name)
        );
      });
    },
    handleNewEntryRequestClick() {
      this.addEntryRequestForm = true;
    },
    handleImportEntryRequestsClick() {
      this.importEntryRequestsForm = true;
    },
    handleExportEntryRequestsClick() {
      this.exportEntryRequestsDialog = true;
    },
  },
};
</script>

<style></style>
