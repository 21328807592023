import { registeredUserService } from "@/api_services/registered_users.service";

const DEFAULT_STATE = {
  all: [],
  loading: false,
  options: {
    search: "",
    page: 1,
    itemsPerPage: 10,
    sortBy: "name",
    sortDesc: false,
    filterByStatus: null,
  },
  total: 0,
};

export default {
  namespaced: true,
  state: Object.assign({}, JSON.parse(JSON.stringify(DEFAULT_STATE))),
  getters: {},
  mutations: {
    setOptions(state, options) {
      state.options = options;
    },
    setTotal(state, total) {
      state.total = total;
    },
    setRegisteredUsers(state, registered_users) {
      state.all = registered_users;
    },
    setRegisteredUsersLoading(state, isLoading) {
      state.loading = isLoading;
    },
    setDefaultState(state) {
      Object.assign(state, JSON.parse(JSON.stringify(DEFAULT_STATE)));
    },
  },
  actions: {
    UPDATE_OPTIONS(context, options) {
      context.commit("setOptions", options);
    },

    GET_ALL: (context, options) => {
      return new Promise((resolve, reject) => {
        context.commit("setRegisteredUsersLoading", true);
        context.commit("setRegisteredUsers", []);
        if (options) {
          context.commit("setOptions", options);
        }
        registeredUserService
          .getRegisteredUsers(context.state.options)
          .then((res) => {
            context.commit("setRegisteredUsers", res.data.data);
            context.commit("setTotal", res.data.total);
            context.commit("setRegisteredUsersLoading", false);
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            context.commit("setRegisteredUsersLoading", false);
            context.commit("setRegisteredUsers", []);
            reject(e);
          });
      });
    },

    GET_USER_BY_ID: (_, id) => {
      return new Promise((resolve, reject) => {
        registeredUserService
          .getUser(id)
          .then((res) => {
            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    CREATE: (context, registeredUser) => {
      return new Promise((resolve, reject) => {
        registeredUserService
          .createRegisteredUser(registeredUser)
          .then((res) => {
            resolve(res.data);
            context.dispatch("GET_ALL");
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    UPDATE: (context, registeredUser) => {
      return new Promise((resolve, reject) => {
        registeredUserService
          .updateRegisteredUser(registeredUser)
          .then((res) => {
            resolve(res.data);
            context.dispatch("GET_ALL");
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    DELETE: (context, registeredUserId) => {
      return new Promise((resolve, reject) => {
        registeredUserService
          .deleteRegisteredUser(registeredUserId)
          .then((res) => {
            resolve(res.data);
            context.dispatch("GET_ALL");
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },
    IMPORT: (context, file) => {
      return new Promise((resolve, reject) => {
        registeredUserService
          .importUsers(file)
          .then((res) => {
            resolve(res.data);
            context.dispatch("GET_ALL");
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },
    EXPORT: (_) => {
      return new Promise((resolve, reject) => {
        registeredUserService
          .exportUsers()
          .then((res) => {
            var fileURL = window.URL.createObjectURL(new Blob([res.data]));
            var fileLink = document.createElement("a");

            fileLink.href = fileURL;
            fileLink.setAttribute("download", "[3SHOREX]LISTA_DE UTILIZADORES_SHOREX.csv");
            document.body.appendChild(fileLink);

            fileLink.click();

            resolve(res.data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    RESET: (context) => {
      context.commit("setDefaultState");
    },
  },
};
