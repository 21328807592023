import configs from "@/helpers/configs";
import axios from "axios";
import { sharedHelpers } from "@/api_services/utils/shared-helpers";

export const sectorsService = {
  getSectors,
  getSector,
  createSector,
  updateSector,
  updateSectorZones,
  deleteSector,
  copySectors,
  getSectorsByDay,
};

async function getSectors(planId) {
  const requestOptions = {
    method: "GET",
    headers: sharedHelpers.getRequestHeaders()
  };
  return await axios.get(
    configs.getApiUrl() + `/v1/plans/${planId}/sectors`,
    requestOptions
  );
}

async function createSector(planId, sector) {
  const requestOptions = {
    method: "POST",
    headers: sharedHelpers.getRequestHeaders()
  };
  return await axios.post(
    configs.getApiUrl() + `/v1/plans/${planId}/sectors`,
    sector,
    requestOptions
  );
}

async function updateSector(planId, sector) {
  const requestOptions = {
    method: "PUT",
    headers: sharedHelpers.getRequestHeaders()
  };
  return await axios.put(
    configs.getApiUrl() + `/v1/plans/${planId}/sectors/${sector.id}`,
    sector,
    requestOptions
  );
}

async function updateSectorZones(planId, sector) {
  const requestOptions = {
    method: "PUT",
    headers: sharedHelpers.getRequestHeaders()
  };
  return await axios.put(
    configs.getApiUrl() + `/v1/plans/${planId}/sectors/${sector.id}/zones`,
    sector,
    requestOptions
  );
}

async function deleteSector(planId, sectorId) {
  const requestOptions = {
    method: "DELETE",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.delete(
    configs.getApiUrl() + `/v1/plans/${planId}/sectors/${sectorId}`,
    requestOptions
  );
}

async function getSector(planId, sectorId) {
  const requestOptions = {
    method: "GET",
    headers: sharedHelpers.getRequestHeaders()
  };
  return await axios.get(
    configs.getApiUrl() + `/v1/plans/${planId}/sectors/${sectorId}`,
    requestOptions
  );
}

async function copySectors({ toPlanId, fromPlanDate }) {
  const requestOptions = {
    method: "POST",
    headers: sharedHelpers.getRequestHeaders()
  };
  return await axios.post(
    configs.getApiUrl() + `/v1/plans/${toPlanId}/sectors/copy`,
    { from_plan_date: fromPlanDate },
    requestOptions
  );
}

async function getSectorsByDay(day) {
  const requestOptions = {
    method: "GET",
    headers: sharedHelpers.getRequestHeaders()
  };
  return await axios.get(
    configs.getApiUrl() + `/v1/sectors/day/${day}`,
    requestOptions
  );
}
