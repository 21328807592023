var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-navigation-drawer',{staticStyle:{"border":"1px solid #ccc"},attrs:{"app":"","clipped":"","permanent":_vm.sectorsActive,"right":"","width":"400","disable-resize-watcher":""},model:{value:(_vm.sectorsActive),callback:function ($$v) {_vm.sectorsActive=$$v},expression:"sectorsActive"}},[_c('v-toolbar',{attrs:{"color":"default","dense":"","flat":""}},[_c('v-toolbar-title',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t("global.sectors"))+" ("+_vm._s(_vm.sectors.length)+")")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.addSector.apply(null, arguments)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("sectors.create")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.copySectors.apply(null, arguments)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-history")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("sectors.copy")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":_vm.filtersOpen ? 'primary' : 'default'},on:{"click":function($event){$event.stopPropagation();return _vm.toggleFilters.apply(null, arguments)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-filter")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("global.filters")))])])],1),_c('v-divider'),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.filtersOpen),expression:"filtersOpen"}],staticClass:"pa-2",staticStyle:{"height":"60px"}},[_c('v-text-field',{attrs:{"placeholder":_vm.$t('sectors.search'),"filled":"","rounded":"","dense":"","hide-details":""},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1),_c('v-divider'),_c('div',{staticClass:"sectors-content full-height",class:_vm.filtersOpen ? 'with-filters' : ''},[(_vm.loading)?_c('Loading',{attrs:{"value":_vm.loading,"custom-message":_vm.$t('sectors.loading_sectors_please_wait')}}):_c('div',[(_vm.filteredSectors.length == 0)?_c('div',{staticClass:"my-1 text-center"},[_vm._v(" "+_vm._s(_vm.$t("sectors.no_sectors_found"))+" ")]):_c('v-list-item-group',{staticClass:"ma-2",attrs:{"color":"blue","active-class":"selected"},model:{value:(_vm.selectedItem),callback:function ($$v) {_vm.selectedItem=$$v},expression:"selectedItem"}},_vm._l((_vm.filteredSectors),function(item){return _c('v-list-item',{key:item.id,staticClass:"sector-card-list-item",class:{
              selected: _vm.selectedItem && item && item.id == _vm.selectedItem.id,
            },attrs:{"value":item,"disabled":_vm.isSectorsListDisabled}},[_c('Sector',{attrs:{"item":item,"selected":_vm.selectedItem && item && item.id == _vm.selectedItem.id},on:{"changezones":_vm.handleChangeZones,"edit":_vm.handleEditSector,"delete":_vm.handleDeleteSector}})],1)}),1)],1)],1)],1),_c('CreateItemDialog',{attrs:{"open":_vm.openAddItemDialog,"plan":this.plan},on:{"update:open":function($event){_vm.openAddItemDialog=$event}}}),_c('EditItemDialog',{attrs:{"open":_vm.openEditItemDialog,"item":_vm.selectedItem},on:{"update:open":function($event){_vm.openEditItemDialog=$event}}}),_c('CopyDialog',{attrs:{"open":_vm.openCopyDialog,"plan":this.plan},on:{"update:open":function($event){_vm.openCopyDialog=$event}}}),_c('DeleteItemDialog',{attrs:{"open":_vm.openDeleteItemDialog,"item":_vm.selectedItem},on:{"update:open":function($event){_vm.openDeleteItemDialog=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }