<template>
  <v-bottom-sheet v-model="isOpen" persistent>
    <v-card tile v-if="!!item" :loading="isLoading">
      <v-toolbar flat color="primary">
        <v-toolbar-title class="font-weight-bold">
          <v-icon class="blac--text" left
            >mdi-boom-gate-arrow-up-outline</v-icon
          >
          <span
            v-if="item.vehicle_type_code != 'TAXI'"
            class="registration-plate-font"
          >
            {{ item.registration_plate }}
          </span>
          <span v-else class="registration-plate-font">
            {{ item.vehicle_license_number }}
          </span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="isOpen = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-progress-linear
        color="black"
        indeterminate
        v-if="isLoading"
      ></v-progress-linear>
      <v-card-text class="px-3 pb-0">
        <v-list three-line>
          <v-row align="start" no-gutters>
            <v-col cols="12" v-if="!item.type || item.type != 'NOSHOREX'">
              <v-list-item
                class="pl-0"
                @click="handleOpenRegisterEntry(item)"
                :disabled="isLoading"
              >
                <v-list-item-avatar>
                  <v-icon> mdi-location-enter</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold text-subtitle-1">
                    {{ $t("access_control_page.register_action") }} shorex
                  </v-list-item-title>
                  <v-list-item-subtitle class="">
                    {{ $t("access_control_page.register_action_desc") }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider
                class="my-2"
                v-if="!item.type || item.type != 'NOSHOREX'"
              ></v-divider>
            </v-col>

            <v-col
              cols="12"
              v-if="
                (!item.type || item.type != 'NOSHOREX') &&
                item.last_scanned_type
              "
            >
              <v-list-item
                class="pl-0"
                @click="handleOpenRegisterExit(item)"
                :disabled="isLoading"
              >
                <v-list-item-avatar>
                  <v-icon> mdi-location-exit</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold text-subtitle-1">
                    {{ $t("access_control_page.register_exit_action") }} shorex
                  </v-list-item-title>
                  <v-list-item-subtitle class="">
                    {{ $t("access_control_page.register_exit_action_desc") }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider
                class="my-2"
                v-if="!item.type || item.type != 'NOSHOREX'"
              ></v-divider>
            </v-col>

            <v-col cols="12" v-if="item.type && item.type == 'NOSHOREX'">
              <v-list-item
                class="pl-0"
                @click="handleOpenRegisterEntryNoShorex(item)"
                :disabled="isLoading"
              >
                <v-list-item-avatar>
                  <v-icon> mdi-location-enter</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold text-subtitle-1">
                    {{ $t("access_control_page.register_action") }} NO-SHOREX
                  </v-list-item-title>
                  <v-list-item-subtitle class="">
                    {{ $t("access_control_page.register_action_desc") }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider
                class="my-2"
                v-if="!item.type || item.type != 'NOSHOREX'"
              ></v-divider>
            </v-col>

            <v-col
              cols="12"
              v-if="
                item.type && item.type == 'NOSHOREX' && item.last_scanned_type
              "
            >
              <v-list-item
                class="pl-0"
                @click="handleOpenRegisterExitNoShorex(item)"
                :disabled="isLoading"
              >
                <v-list-item-avatar>
                  <v-icon> mdi-location-exit</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold text-subtitle-1">
                    {{ $t("access_control_page.register_exit_action") }}
                    NO-SHOREX
                  </v-list-item-title>
                  <v-list-item-subtitle class="">
                    {{ $t("access_control_page.register_exit_action_desc") }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider
                class="my-2"
                v-if="!item.type || item.type != 'NOSHOREX'"
              ></v-divider>
            </v-col>

            <v-col cols="12" v-if="!item.type || item.type != 'NOSHOREX'">
              <v-list-item
                class="pl-0"
                @click="handleOpenDetails(item)"
                :disabled="isLoading"
              >
                <v-list-item-avatar>
                  <v-icon> mdi-information</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold text-subtitle-1">
                    {{ $t("access_control_page.details_action") }} shorex
                  </v-list-item-title>
                  <v-list-item-subtitle class="">
                    {{ $t("access_control_page.details_action_desc") }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>

            <v-col cols="12" v-if="!item.type || item.type == 'NOSHOREX'">
              <v-list-item
                class="pl-0"
                @click="handleOpenNoShorexEntryDetails(item)"
                :disabled="isLoading"
              >
                <v-list-item-avatar>
                  <v-icon> mdi-information</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold text-subtitle-1">
                    {{ $t("access_control_page.details_action") }} NO-SHOREX
                  </v-list-item-title>
                  <v-list-item-subtitle class="">
                    {{ $t("access_control_page.details_action_desc") }}
                    NO-SHOREX
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
        </v-list>
      </v-card-text>
    </v-card>
  </v-bottom-sheet>
</template>
<script>
export default {
  props: ["value", "item", "loading"],
  computed: {
    isOpen: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);

        if (!val) {
          // deselect the item
          this.$emit("update:item", null);
        }
      },
    },
    isLoading: {
      get() {
        return this.loading;
      },
      set(val) {
        this.$emit("loading", val);
      },
    },
  },
  methods: {
    handleOpenRegisterEntry(item) {
      this.$emit("register_entry", item);
    },
    handleOpenRegisterEntryNoShorex(item) {
      this.$emit("register_entry_no_shorex", item);
    },
    handleOpenDetails(item) {
      this.$emit("open_entry_details", item);
    },
    handleOpenNoShorexEntryDetails(item) {
      this.$emit("open_noshorex_entry_details", item);
    },
    handleOpenRegisterExit(item) {
      this.$emit("register_exit", item);
    },
    handleOpenRegisterExitNoShorex(item) {
      this.$emit("register_exit_no_shorex", item);
    },
  },
};
</script>
<style scoped>
.registration-plate-font {
  font-family: "Fira Mono", monospace !important;
  font-weight: 700 !important;
}
</style>
