import Vue from "vue";
import VueRouter from "vue-router";
import { sharedHelpers } from "@/api_services/utils/shared-helpers";
import store from "@/store/index";

import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style
NProgress.configure({}); // NProgress Configuration

Vue.use(VueRouter);

const router = new VueRouter({
  routes: [
    {
      path: "/",
      redirect: "/map",
    },
    {
      path: "/login",
      name: "LOGIN",
      component: () => import("@/pages/Auth/LoginPage"),
      meta: {
        noCache: true,
      },
    },
    {
      path: "/forgot-password",
      name: "FORGOTPASSWORD",
      component: () => import("@/pages/Auth/ForgotPasswordPage"),
      meta: {
        noCache: true,
      },
    },
    {
      path: "/signup",
      name: "SIGNUP",
      component: () => import("@/pages/Auth/SignupPage"),
      meta: {
        noCache: true,
      },
    },
    {
      path: "/reset-password",
      name: "RESETPASSWORD",
      component: () => import("@/pages/Auth/ResetPasswordPage"),
      meta: {
        noCache: true,
      },
    },
    {
      path: "/verify-your-account",
      name: "VERIFYYOURACCOUNT",
      component: () => import("@/pages/Auth/VerifyYourAccountPage"),
      meta: {
        noCache: true,
      },
    },
    ,
    {
      path: "/verified",
      name: "VERIFIED",
      component: () => import("@/pages/Auth/VerifiedPage"),
      meta: {
        noCache: true,
      },
    },
    {
      path: "/",
      component: () => import("@/pages/BaseLayout"),
      children: [
        {
          path: "/map",
          name: "MAP",
          component: () => import("@/pages/Main"),
          meta: {
            noCache: true,
          },
        },
        {
          path: "/plan",
          name: "PLAN",
          component: () => import("@/pages/Main"),
          meta: {
            noCache: true,
          },
        },
        {
          path: "/user_vehicles",
          name: "USER_VEHICLES",
          component: () => import("@/pages/UserVehiclesPage"),
          meta: {
            noCache: true,
          },
        },
        {
          path: "/entry_requests",
          name: "ENTRY_REQUESTS",
          component: () => import("@/pages/EntryRequestsPage"),
          meta: {
            noCache: true,
          },
        },
        {
          path: "/scheduled_entries",
          name: "SCHEDULED_ENTRIES",
          component: () => import("@/pages/GrantEntriesPage"),
          meta: {
            noCache: true,
          },
        },
        {
          path: "/noshorex_entries",
          name: "NOSHOREX_ENTRIES",
          component: () => import("@/pages/NoShorexEntriesPage"),
          meta: {
            noCache: true,
          },
        },
        {
          path: "/scheduled_entry_scans",
          name: "SCHEDULED_ENTRY_SCANS",
          component: () => import("@/pages/ScheduledEntryScansPage"),
          meta: {
            noCache: true,
          },
        },
        {
          path: "/scheduled_exit_scans",
          name: "SCHEDULED_EXIT_SCANS",
          component: () => import("@/pages/ScheduledExitScansPage"),
          meta: {
            noCache: true,
          },
        },
        {
          path: "/audit_logs",
          name: "AUDIT_LOGS",
          component: () => import("@/pages/AuditLogsPage"),
          meta: {
            noCache: true,
          },
        },
        {
          path: "/users",
          name: "USERS",
          component: () => import("@/pages/UsersPage"),
          meta: {
            noCache: true,
          },
        },
        {
          path: "/operator_types",
          name: "OPERATOR_TYPES",
          component: () => import("@/pages/OperatorTypesPage"),
          meta: {
            noCache: true,
          },
        },
        {
          path: "/vessel_calls",
          name: "VESSEL_CALLS",
          component: () => import("@/pages/VesselCallsPage"),
          meta: {
            noCache: true,
          },
        },
        {
          path: "/operators",
          name: "OPERATORS",
          component: () => import("@/pages/OperatorsPage"),
          meta: {
            noCache: true,
          },
        },
        {
          path: "/registered_users",
          name: "REGISTERED_USERS",
          component: () => import("@/pages/RegisteredUsersPage"),
          meta: {
            noCache: true,
          },
        },
        {
          path: "/zones",
          name: "ZONES",
          component: () => import("@/pages/ZonesPage"),
          meta: {
            noCache: true,
          },
        },
        {
          path: "/vehicle_types",
          name: "VEHICLE_TYPES",
          component: () => import("@/pages/VehicleTypesPage"),
          meta: {
            noCache: true,
          },
        },
        {
          path: "/vehicles",
          name: "VEHICLES",
          component: () => import("@/pages/VehiclesPage"),
          meta: {
            noCache: true,
          },
        },
        {
          path: "/profile",
          name: "PROFILE",
          component: () => import("@/pages/ProfilePage"),
          meta: {
            noCache: true,
          },
        },
        {
          path: "/members",
          name: "MEMBERS",
          component: () => import("@/pages/MembersOperatorPage"),
          meta: {
            noCache: true,
          },
        },
        {
          path: "/stats",
          name: "STATS",
          component: () => import("@/pages/StatsPage"),
          props: (route) => route.query,
          meta: {
            noCache: true,
          },
        },
        {
          path: "/speed_dial",
          name: "SPEED_DIAL",
          component: () => import("@/pages/SpeedDial"),
          meta: {
            noCache: true,
          },
        },
      ],
    },
    { path: "/access_denied", component: () => import("@/pages/AccessDenied") },
    { path: "*", component: () => import("@/pages/NotFound") },
  ],
});

router.beforeEach(async (to, from, next) => {
  NProgress.start();
  const privatePages = [
    "/map",
    "/scheduled_entries",
    "/scheduled_entry_scans",
    "/noshorex_entries",
    "/operators",
    "/registered_users",
    "/stats",
    "/vessel_calls",
    "/operator_types",
    "/users",
    "/zones",
    "/vehicle_types",
    "/audit_logs",
    "/vehicles",
    "/entry_requests",
    "/user_vehicles",
  ];

  const myshorexPages = [
    "/map",
    "/vehicles",
    "/entry_requests",
    "/user_vehicles",
  ];

  const publicPages = [
    "/login",
    "/forgot-password",
    "/reset-password",
    "/signup",
    "/verify-your-account",
    "/verified",
    "/access_denied"
  ];
  const authRequired = !publicPages.includes(to.path);
  const account = sharedHelpers.getStorageItemWithExpiry("account");
  const locode = sharedHelpers.getStorageItemWithExpiry("locode");
  const operator = sharedHelpers.getStorageItemWithExpiry("operator");

  if (authRequired && (!account || !locode)) {
    return next("/login");
  }

  store.dispatch("SET_ACCOUNT", account);
  store.dispatch("SET_LOCODE", locode);
  store.dispatch("operators/SET_CURRENT_OPERATOR", operator);

  if (!privatePages.includes(to.path)) {
    next();
  } else {
    
    let userPermissions = sharedHelpers.getUserPermissions();

    let userType = JSON.parse(account).user.type;

    let hasPermissions = userPermissions.find((permission) => {
      return permission.code.toLowerCase() === to.path.split("/")[1];
    }) || (userType == "SHOREX" && myshorexPages.includes(to.path));

    if (hasPermissions) {
      next()
    } else {
      next("/access_denied");
    }

  }
});

router.afterEach(async () => {
  NProgress.done();
});

export default router;
