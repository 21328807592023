<template>
  <v-dialog v-model="isOpen" persistent width="600">
    <v-card tile>
      <v-expand-transition>
        <div v-show="expand">
          <v-alert class="mb-0" tile type="error" @click="expand = false">
            <div
              v-for="(message, index) in this.messages"
              :key="index"
              class="subtitle"
            >
              {{ message }}
            </div>
          </v-alert>
        </div>
      </v-expand-transition>
      <v-progress-linear
        height="10"
        striped
        color="primary"
        indeterminate
        v-show="loading"
      ></v-progress-linear>
      <v-toolbar color="primary" flat>
        <v-toolbar-title>
          <span>
            <v-icon left>mdi-plus</v-icon>{{ $t("vehicles.new_vehicle") }}
          </span>
        </v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="overflow-y-auto mt-5 pb-0 form-content">
        <Loading
          v-if="saving"
          :value="saving"
          :custom-message="$t('vehicles.creating_please_wait')"
        ></Loading>
        <v-form ref="form" class="mt-2" v-else>
          <v-text-field
            counter="50"
            maxlength="50"
            autocomplete="off"
            required
            v-model="item.name"
            :label="$t('vehicle_types.name')"
            :placeholder="$t('vehicle_types.name')"
            :rules="nameRules"
            outlined
          ></v-text-field>
          <v-text-field
            counter="50"
            maxlength="50"
            autocomplete="off"
            required
            v-model="item.code"
            :label="$t('vehicle_types.code')"
            :placeholder="$t('vehicle_types.code')"
            :rules="codeRules"
            outlined
          ></v-text-field>

          <v-combobox
            v-model="item.icon"
            required
            :label="$t('vehicle_types.icon')"
            :placeholder="$t('vehicle_types.icon')"
            :menu-props="{ bottom: true, offsetY: true }"
            item-value="icon"
            :items="availableIcons"
            autocomplete
            clearable
            outlined
          >
            <template v-slot:item="{ item }">
              <v-icon large class="pr-2"> {{ item }} </v-icon> {{ item }}
            </template>
            <template v-slot:selection="{ item }">
              <v-icon large class="pr-2"> {{ item }} </v-icon> {{ item }}
            </template></v-combobox
          >
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer />
        <v-btn
          class="black--text"
          color="primary"
          depressed
          :disabled="loading"
          @click="save"
        >
          {{ $t("global.create") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
const DEFAULT_ITEM = {
  name: "",
  icon: "",
  code: "",
};
import Loading from "@/components/Main/Loading";

export default {
  props: ["open"],
  components: { Loading },
  data() {
    return {
      loading: false,
      saving: false,
      expand: false,
      messages: [],
      nameRules: [
        (v) => !!v || this.$t("rules.required_field"),
        (v) => (v && v.length <= 255) || this.$t("rules.character_limit"),
      ],
      codeRules: [
        (v) => !!v || this.$t("rules.required_field"),
        (v) => (v && v.length <= 255) || this.$t("rules.character_limit"),
      ],
      item: Object.assign({}, DEFAULT_ITEM),
      availableIcons: [
        "mdi-car-convertible",
        "mdi-car",
        "mdi-car-estate",
        "mdi-car-hatchback",
        "mdi-car-lifted-pickup",
        "mdi-car-limousine",
        "mdi-car-pickup",
        "mdi-taxi",
        "mdi-bus",
        "mdi-bicycle-cargo",
        "mdi-account-tie-hat",
        "mdi-bicycle",
        "mdi-scooter",
        "mdi-van-passenger",
      ],
    };
  },
  computed: {
    isOpen: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("update:open", val);
      },
    },
  },
  watch: {
    open(val) {
      if (val) {
        this.$nextTick(() => {
          this.item = Object.assign({}, DEFAULT_ITEM);
          this.$refs.form.resetValidation();
        });
      }
    },
  },
  methods: {
    close() {
      this.isOpen = false;
      this.expand = false;
      this.loading = false;
      this.saving = false;
    },
    save() {
      this.expand = false;
      this.saving = true;
      this.messages = [];
      if (this.$refs.form.validate()) {
        let vehicle = Object.assign({}, this.item);
        this.$store.dispatch("vehicle_types/CREATE", vehicle).then((res) => {
          if (res.errors) {
            Object.entries(res.errors).forEach(([, value]) => {
              this.messages = this.messages.concat(value);
            });
            this.expand = true;
            this.saving = false;
          } else {
            this.close();
          }
        });
      } else {
        this.expand = false;
        this.saving = false;
      }
    },
  },
};
</script>
<style scoped>
.form-content {
  position: relative;
  max-height: calc(80vh - var(--toolbar-height));
}
</style>
