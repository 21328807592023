<template>
  <v-dialog
    v-model="isOpen"
    persistent
    scrollable
    width="60%"
    style="overflow: hidden"
  >
    <v-card tile style="height: 100%">
      <v-toolbar color="primary" flat>
        <v-toolbar-title>
          <span>
            <v-icon left>mdi-account-group</v-icon
            >{{ $t("passengers.view_list") }}
          </span>
        </v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pa-0" style="height: 350px">
        <v-data-table
          class="passengersList"
          :headers="headers"
          :items="passengers"
          item-key="id"
          hide-default-footer
          disable-pagination
          fixed-header
        >
          <template v-slot:item.name="{ item }">
            <b>{{ item.name }}</b>
            <v-icon small v-if="item.special_care_or_assistance"
              >mdi-wheelchair</v-icon
            >
          </template>

          <template v-slot:item.date_of_birth="{ item }">
            {{
              item.date_of_birth
                ? formatDateFromDatabase(item.date_of_birth)
                : "N/D"
            }}
          </template>

          <template v-slot:item.country="{ item }">
            <v-avatar left tile height="30" v-if="item.country">
              <country-flag
                :country="item.country.code.toLowerCase()"
                size="normal"
                style="margin-top: -10px"
              />
            </v-avatar>

            {{ item.country ? item.country.name : "N/D" }}
          </template>

          <template v-slot:item.founded="{ item }">
            <v-progress-linear
              color="amber"
              buffer-value="0"
              stream
              v-if="matchingPassengers"
            ></v-progress-linear>
            {{ item.passengers }}
            <div v-if="!matchingPassengers" :key="componentKey">
              <v-list-item
                v-for="(item, i) in item.founded"
                :key="i"
                class="pa-0 ma-0"
                dense
                style="min-height: 20px"
              >
                <v-list-item-content class="pa-0 ma-0">
                  <v-list-item-title class="pa-0 ma-0"
                    ><v-chip class="ma-2 text-uppercase" x-small label
                      >{{
                        Math.round(item.score * 100)
                          .toString()
                          .padStart(2, "0")
                      }}%</v-chip
                    ><v-avatar
                      tile
                      height="24"
                      width="24"
                      class="pa-0 ma-0"
                      style="min-width: 24px"
                    >
                      <country-flag
                        :country="item.country.code.toLowerCase()"
                        size="small"
                        style="margin-top: -10px"
                      /> </v-avatar
                    ><b>{{ item.name }}</b
                    >, {{ item.date_of_birth | formatDateFromDatabase }},
                    {{ item.country.name }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import CountryFlag from "vue-country-flag";
export default {
  props: ["open", "entry"],
  components: {
    CountryFlag,
  },
  data() {
    return {
      componentKey: 0,
      statuses: [
        {
          text: "Pendente",
          value: "PENDING",
        },
        {
          text: "Recusado",
          value: "REJECTED",
        },
        {
          text: "Aprovado",
          value: "APPROVED",
        },
      ],
      headers: [
        {
          text: this.$t("passengers.name"),
          value: "name",
        },
        {
          text: this.$t("passengers.date_of_birth"),
          value: "date_of_birth",
        },
        {
          text: this.$t("passengers.nationality"),
          value: "country",
        },
        {
          text: "Passageiros da Escala",
          value: "founded",
        },
      ],
    };
  },
  computed: {
    isOpen: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("update:open", val);
      },
    },
    matchingPassengers() {
      return this.$store.state.shorex.matchingPassengers;
    },
    passengers: {
      get() {
        return this.entry.passengers;
      },
      set(val) {
        this.$emit("update:passengers", val);
      },
    },
    vessel_call_id: {
      get() {
        return this.entry.vessel_call_id;
      },
    },
  },

  mounted() {
    this.getMatching();
  },

  watch: {
    isOpen(val) {
      if (val) this.getMatching();
    },
  },

  methods: {
    getMatching() {
      this.$store
        .dispatch("shorex/GET_MATCH", {
          vessel_call_id: this.vessel_call_id,
          passengers: this.passengers,
        })
        .then((list) => {
          this.passengers = this.passengers.map((passenger) => {
            passenger.founded = list.filter(
              (item) => item.id == passenger.id
            )[0].matching;

            // Remove duplicates
            passenger.founded = passenger.founded.filter(
              (value, index, arr) =>
                arr.findIndex((val) => val.id === value.id) === index
            );

            return passenger;
          });

          this.componentKey++;
        });
    },

    close() {
      this.isOpen = false;
    },

    formatDateFromDatabase(item) {
      return this.$options.filters.formatDateFromDatabase(item);
    },
  },
};
</script>
<style>
.passengersList .v-data-table__wrapper {
  height: 100%;
}
</style>
