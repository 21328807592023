<template>
  <v-navigation-drawer
    right
    :style="
      openPlanStatistics
        ? $vuetify.breakpoint.xs
          ? 'width: 100%'
          : 'width: 400px'
        : 'width: 0px'
    "
    permanent
  >
    <v-card class="overflow-x-hidden" outlined flat tile>
      <v-toolbar flat>
        <v-toolbar-title>
          <v-list-item two-line class="ml-0 pl-0">
            <v-list-item-content>
              <v-list-item-title class="headline font-weight-black">
                {{ selectedDate | formatDateFromDatabase }}
              </v-list-item-title>
              <v-list-item-subtitle style="font-weight: 800">
                {{ $t("plan.details") }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider class="ml-2 mr-2"></v-divider>
      <v-card-text
        style="
          max-height: calc(100% - 260px);
          overflow-y: auto;
          overflow-x: hidden;
        "
        class="pa-0"
      >
        <Loading
          v-if="loading"
          :value="loading"
          :custom-message="$t('plan.loading_plan_details_please_wait')"
        ></Loading>
        <div v-else>
          <v-simple-table dense v-if="hasPlan">
            <template v-slot:default>
              <tbody>
                <tr v-if="numberOfEntryRequests !== null">
                  <td class="font-weight-bold">
                    {{ $t("plan.requests_number") }}
                  </td>
                  <td class="text-right">
                    {{ numberOfEntryRequests }}
                  </td>
                </tr>
                <tr v-if="numberOfVesselCalls !== null">
                  <td class="font-weight-bold">
                    {{ $t("plan.vessel_call_number") }}
                  </td>
                  <td class="text-right">
                    {{ numberOfVesselCalls }}
                  </td>
                </tr>
                <tr v-if="plan && plan.code !== null">
                  <td class="font-weight-bold">
                    {{ $t("global.code") }}
                  </td>
                  <td class="text-right">{{ plan.code }}</td>
                </tr>
                <tr v-if="plan && plan.status !== null">
                  <td class="font-weight-bold">
                    {{ $t("plan.plan_state") }}
                  </td>
                  <td class="text-right">
                    {{ $t(`plan.${plan.status.toLowerCase()}`) }}
                  </td>
                </tr>
                <tr v-if="plan && plan.notes !== null && plan.notes !== ''">
                  <td class="font-weight-bold">
                    {{ $t("global.notes") }}
                  </td>
                  <td class="text-right">{{ plan.notes }}</td>
                </tr>
                <tr v-if="plan && plan.creator_name !== null">
                  <td class="font-weight-bold">
                    {{ $t("global.created_by") }}
                  </td>
                  <td class="text-right">{{ plan.creator_name }}</td>
                </tr>
                <tr v-if="plan && plan.created_at !== null">
                  <td class="font-weight-bold">
                    {{ $t("global.created_at") }}
                  </td>
                  <td class="text-right">
                    {{ plan.created_at | formatDatetime }}
                  </td>
                </tr>
                <tr v-if="plan && plan.updater_name !== null">
                  <td class="font-weight-bold">
                    {{ $t("global.updated_by") }}
                  </td>
                  <td class="text-right">{{ plan.updater_name }}</td>
                </tr>
                <tr v-if="plan && plan.updated_at !== null">
                  <td class="font-weight-bold">
                    {{ $t("global.updated_at") }}
                  </td>
                  <td class="text-right">
                    {{ plan.updated_at | formatDatetime }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <div v-else class="no-data-info">
            <span> {{ $t("plan.no_plan") }} </span>
          </div>
        </div>
      </v-card-text>
      <v-card-actions class="plan-actions">
        <v-btn
          class="mb-2"
          v-if="hasPlan"
          depressed
          width="100%"
          @click="openPlan"
        >
          <v-icon left small>
            {{
              (plan && plan.status === "ARCHIVED") || isOutdated() || !hasUserPermissionToViewEditOrManage('PLANS')
                ? "mdi-eye"
                : "mdi-playlist-edit"
            }}
          </v-icon>
          {{
            plan && plan.status === "ARCHIVED" || isOutdated() || !hasUserPermissionToViewEditOrManage('PLANS')
              ? $t("plan.view").toUpperCase()
              : $t("plan.edit").toUpperCase()
          }}
        </v-btn>
        <v-btn
          class="mb-2"
          v-if="hasPlan"
          depressed
          width="100%"
          @click="exportPlan"
        >
          <v-icon left> mdi-microsoft-excel </v-icon>
          {{ $t("plan.export").toUpperCase() }}
        </v-btn>
        <v-btn
          class="mb-2"
          v-if="hasPlan && hasUserPermissionToViewEditOrManage('PLANS')"
          depressed
          width="100%"
          @click="archivePlan"
          :disabled="plan && plan.status === 'ARCHIVED'"
        >
          <v-icon left> mdi-archive </v-icon>
          {{ $t("plan.archive").toUpperCase() }}
        </v-btn>
        <v-btn
          class="mb-2"
          v-if="hasPlan"
          depressed
          width="100%"
          @click="redirectToPlanStatistics"
        >
          <v-icon left> mdi-finance </v-icon>
          {{ $t("plan.stats").toUpperCase() }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import Loading from "@/components/Main/Loading";
export default {
  props: ["open", "date", "hasEvents", "dateClickNotAllowed"],
  components: {
    Loading,
  },
  data() {
    return {
      loading: false,
      plan: null,
      numberOfEntryRequests: null,
      numberOfVesselCalls: null,
      numberOfPassengers: null,
    };
  },
  computed: {
    hasPlan() {
      return this.hasEvents;
    },
    openPlanStatistics: {
      get() {
        return this.open;
      },
      set(value) {
        this.$emit("update:open", value);
      },
    },
    selectedDate() {
      return this.date;
    },
    clickAllowed: {
      get() {
        return this.dateClickNotAllowed;
      },
      set(val) {
        this.$emit("update:dateClickNotAllowed", val);
      },
    },
  },
  watch: {
    openPlanStatistics(val) {
      if (val && this.hasEvents) {
        this.loadPlan();
      }
    },
    date(val) {
      if (val && this.hasEvents) {
        this.loadPlan();
      }
    },
  },
  methods: {
    openPlan() {
      this.$emit("openPlanDialog", this.date);
    },
    exportPlan() {
      this.$emit("exportPlanEntryRequests", this.date);
    },
    archivePlan() {
      this.$emit("archivePlan", this.date);
    },
    isOutdated() {
      let currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);

      let planDate = new Date(this.selectedDate);
      planDate.setHours(0, 0, 0, 0);

      return planDate < currentDate;
    },
    loadPlan() {
      if (this.loading) return;

      this.clickAllowed = true;
      this.loading = true;
      this.$store
        .dispatch("plans/GET_BY_DAY", this.date)
        .then((data) => {
          this.plan = data.plan;
          this.numberOfEntryRequests = data.number_of_entry_requests;
          this.numberOfVesselCalls = data.number_of_vessel_calls;
          this.numberOfPassengers = data.number_of_passengers;
          this.loading = false;
          this.clickAllowed = false;
        })
        .catch((ex) => {
          console.error(ex);
          this.loading = false;
          this.clickAllowed = false;
        });
    },
    redirectToPlanStatistics() {
      this.$router.push({
        path: "/stats",
        query: { start: this.date, end: this.date },
      });
    },
    close() {
      this.openPlanStatistics = false;
    },
  },
};
</script>

<style lang="scss">
.overflow-x-hidden {
  overflow-x: hidden !important;
  overflow-y: hidden !important;
  width: 530px;
  height: 100%;
}

.v-navigation-drawer--bottom.v-navigation-drawer--is-mobile {
  max-height: 100vh !important;
}

.no-data-info {
  text-align: center;
  font-size: 1rem;
}

.plan-actions {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
}
</style>
