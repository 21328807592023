<template>
  <div>
    <v-data-iterator
      :items="items"
      item-key="id"
      sort-by="scanned_at"
      @update:options="handleOptionsUpdate('options', $event)"
      :page.sync="options.page"
      :items-per-page.sync="options.itemsPerPage"
      :loading="loading"
      :server-items-length.sync="totalItemCount"
      hide-default-footer
    >
      <template v-slot:header>
        <v-layout row wrap class="px-2">
          <v-flex :xs9="!$vuetify.breakpoint.mobile" class="pa-1 py-2">
            <v-text-field
              ref="searchField"
              v-model="options.search"
              hide-details
              flat
              solo
              :label="$t('global.search')"
              prepend-inner-icon="mdi-magnify"
              outlined
              @input="filterItems"
              :disabled="loading"
            >
            </v-text-field>
          </v-flex>
          <v-flex :xs3="!$vuetify.breakpoint.mobile" class="pa-1 py-2">
            <v-menu
              v-model="pickerMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              @input="onMenuToggle"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="filterByDatesText"
                  readonly
                  :label="$t('global.filter_scanned_at')"
                  outlined
                  hide-details
                  color="deep-purple accent-4"
                  :disabled="loading"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <vc-date-picker
                v-model="options.filterByDates"
                mode="dateTime"
                timezone="utc"
                is24hr
                is-range
                is-expanded
                :model-config="modelConfig"
                :max-date="new Date()"
              >
              </vc-date-picker>
            </v-menu>
          </v-flex>
        </v-layout>
      </template>
      <template v-slot:no-results>
        <v-container fill-height fluid>
          <v-row
            align="center"
            justify="center"
            class="text-h6 text-center pa-10"
          >
            <v-icon left>mdi-car-off</v-icon
            >{{ $t("access_control_page.not_found") }}
          </v-row></v-container
        >
      </template>
      <template v-slot:no-data>
        <v-container fill-height fluid>
          <v-row
            align="center"
            justify="center"
            class="text-h6 text-center pa-10"
          >
            <v-icon left>mdi-car-off</v-icon
            >{{ $t("access_control_page.not_found") }}
          </v-row></v-container
        >
      </template>
      <template v-slot:loading>
        <div style="position: relative; min-height: 250px; margin-top: 20px">
          <Loading :value="loading"></Loading>
        </div>
      </template>
      <template v-slot:default="{ items }">
        <v-row class="px-0">
          <v-col
            v-for="(item, index) in items"
            :key="index"
            cols="12"
            sm="6"
            md="4"
            lg="3"
          >
            <ScannedEntriesCard
              :item="item"
              :class="
                selectedItem && selectedItem.id == item.id
                  ? 'entry-selected'
                  : ''
              "
              @selected="handleSelectedItem"
            ></ScannedEntriesCard>
          </v-col>
        </v-row>
      </template>
    </v-data-iterator>

    <v-dialog
      v-model="viewNoteDialog"
      :width="$vuetify.breakpoint.mobile ? '90vw' : '40vw'"
    >
      <v-card tile>
        <v-toolbar color="primary" flat>
          <v-toolbar-title>
            <span>
              <v-icon left>mdi-note-edit</v-icon>{{ $t("global.notes") }}
            </span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="viewNoteDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-5">
          <v-textarea
            outlined
            name="input-7-4"
            no-resize
            rows="5"
            hide-details
            v-model="notes"
            readonly
          ></v-textarea>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-row class="text-center px-4 align-center" wrap>
      <v-col cols="12" v-if="options && !loading && pageCount != 1">
        <v-pagination v-model="options.page" dense flat :length="pageCount">
        </v-pagination>
      </v-col>
    </v-row>

    <ExportScheduledEntryScansDialog
      :open.sync="exportDialog"
      :options="options"
    />
    <ScannedEntriesOptions
      v-model="showEntryActions"
      :item.sync="selectedItem"
      @revokeScan="handleRevokeScan"
      @viewNote="handleViewNote"
      :loading.sync="loadingEntryActions"
    />
  </div>
</template>
<script>
import Loading from "@/components/Main/Loading";
import { debounce } from "debounce";
import ExportScheduledEntryScansDialog from "@/components/Main/ScheduledEntryScans/ExportScheduledEntryScansDialog.vue";
import ScannedEntriesCard from "@/components/Main/ScheduledEntryScans/ScannedEntriesCard.vue";
import ScannedEntriesOptions from "@/components/Main/ScheduledEntryScans/ScannedEntriesOptions.vue";
export default {
  props: ["openExportScheduledEntryScansDialog", "isLoading"],
  components: {
    ExportScheduledEntryScansDialog,
    ScannedEntriesCard,
    Loading,
    ScannedEntriesOptions,
  },
  computed: {
    loading: {
      get() {
        return this.isLoading;
      },
      set(val) {
        this.$emit("update:isLoading", val);
      },
    },
    filterByDatesText: {
      get() {
        if (this.options.filterByDates) {
          let start = this.$options.filters.formatDatetime(
            this.options.filterByDates.start
          );
          let end = this.$options.filters.formatDatetime(
            this.options.filterByDates.end
          );

          return start + " - " + end;
        }

        return "";
      },
    },
    exportDialog: {
      // getter
      get: function () {
        return this.openExportScheduledEntryScansDialog;
      },
      // setter
      set: function (newValue) {
        this.$emit("update:openExportScheduledEntryScansDialog", newValue);
      },
    },

    options: {
      cache: false,
      get() {
        return this.$store.state.scheduled_entry_scans.options;
      },
      set(val) {
        this.$store.dispatch("scheduled_entry_scans/UPDATE_OPTIONS", val);
      },
    },

    totalItemCount() {
      return this.$store.state.scheduled_entry_scans.total;
    },

    pageCount() {
      return this.totalItemCount < this.options.itemsPerPage
        ? 1
        : Math.ceil(this.totalItemCount / this.options.itemsPerPage);
    },
  },
  data() {
    return {
      pickerMenu: false,
      items: [],
      selectedItem: null,
      showEntryActions: false,
      loadingEntryActions: false,
      modelConfig: {
        type: "string",
        mask: "iso",
      },
      viewNoteDialog: false,
      notes: "",
    };
  },
  created() {
    this.updateDateRange();
  },
  mounted() {
    this.$store.dispatch("scheduled_entry_scans/RESET");
    this.updateDateRange();
  },
  methods: {
    onMenuToggle(opened) {
      if (opened) return;
      this.handleDatesChange();
    },
    handleOptionsUpdate: debounce(function (_, value) {
      this.loadScheduledEntryScans();
    }, 500),
    filterItems: debounce(function () {
      // Changes the current page to the first one to reset the search stop bugs from happening
      if (this.options.page == 1) {
        this.loadScheduledEntryScans();
      } else {
        this.options.page = 1;
      }

      // Wait for the list to reload and refocus on the text field
      setTimeout(() => {
        this.$refs.searchField.focus();
      }, 500);
    }, 500),
    loadScheduledEntryScans() {
      // Prevent multiple loads
      if (this.loading) {
        return;
      }
      this.loading = true;
      this.items = [];

      let newOptions = JSON.parse(JSON.stringify(this.options));
      newOptions.scanType = "ENTRY";

      this.$store
        .dispatch("scheduled_entry_scans/GET_ALL_PAGINATED", newOptions)
        .then((response) => {
          this.items = response.data;
          this.loading = false;
        })
        .catch((ex) => {
          console.error("Error loading scheduled entry scans", ex);
          this.loading = false;
          this.items = [];
        });
    },
    handleDatesChange() {
      this.filterItems();
    },
    handleSelectedItem(item) {
      this.selectedItem = item;
      this.showEntryActions = true;
    },
    updateDateRange() {
      const now = new Date();
      const start = new Date(now.setMonth(now.getMonth() - 1));
      const end = new Date();

      this.$store.dispatch("scheduled_entry_scans/UPDATE_DATE_RANGE", {
        start,
        end,
      });
    },
    handleViewNote(item) {
      this.notes = item.notes;
      this.viewNoteDialog = true;
    },
    handleRevokeScan(item) {
      this.loadingEntryActions = true;
      this.$store
        .dispatch("scheduled_entry_scans/REVOKE_ENTRY_SCAN", {
          id: item.id,
          type: item.type,
        })
        .then(() => {
          this.loadingEntryActions = false;
          this.showEntryActions = false;
          this.loadScheduledEntryScans();
        })
        .catch((e) => {
          this.loadingEntryActions = false;
          this.showEntryActions = false;
          this.loadScheduledEntryScans();
          console.error(e);
        });
    },
  },
};
</script>
<style>
.v-pagination__item,
.v-pagination__navigation {
  position: static !important;
  border: 1px solid darkgrey;
  box-shadow: none;
  border-radius: 4px;
}
.v-input.v-text-field.v-textarea .v-text-field__slot {
  padding-right: 5px;
  padding-bottom: 5px;
}
</style>
<style>
.is-today > span {
  background-color: rgb(253, 184, 19);
}
</style>
