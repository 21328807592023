<template>
  <v-data-table
    class="registered-users-table"
    :footer-props="{
      showFirstLastPage: true,
      firstIcon: 'mdi-page-first',
      lastIcon: 'mdi-page-last',
      'items-per-page-options': [5, 10, 25, 50],
    }"
    :loading="loading"
    :search.sync="options.search"
    :page.sync="options.page"
    :headers="headers"
    :items="items"
    :items-per-page.sync="options.itemsPerPage"
    :server-items-length="totalItemCount"
    @update:options="handleOptionsUpdate('options', $event)"
    item-key="id"
    fixed-header
    :sort-by.sync="options.sortBy"
    :sort-desc.sync="options.sortDesc"
    must-sort
  >
    <template v-slot:top>
      <v-text-field
        v-model="options.search"
        single-line
        :label="$t('registered_users.search')"
        prepend-inner-icon="mdi-magnify"
        outlined
        @input="filterItems"
        hide-details
        class="pb-4"
      >
        <template v-slot:append>
          <FilterMenu :menu.sync="menu" @filterItems="onFilterItems">
          </FilterMenu>
        </template>
      </v-text-field>
    </template>
    <template v-slot:item.users.name="{ item }">
      {{ item.name }}
    </template>
    <template v-slot:item.users.email="{ item }">
      {{ item.email }}
    </template>
    <template v-slot:item.users.phone="{ item }">
      {{ item.phone }}
    </template>
    <template v-slot:item.operator_name="{ item }">
      {{ item.operator_name }}
    </template>
    <template v-slot:item.users.status="{ item }">
      <v-chip
        v-if="item.status"
        dark
        small
        label
        :color="findStatusColor(item.status)"
        class="text-uppercase"
      >
        {{ $t(`global.${item.status.toLowerCase()}`) }}</v-chip
      >
    </template>
    <template
      v-if="hasUserPermissionToViewEditOrManage('REGISTERED_USERS')"
      v-slot:[`item.actions`]="{ item }"
    >
      <v-menu bottom absolute>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item @click="edit(item)">
            <v-list-item-icon class="mr-2">
              <v-icon>mdi-pencil</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t("global.edit") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="hasUserPermissionToManage('REGISTERED_USERS')" @click="remove(item)">
            <v-list-item-icon class="mr-2">
              <v-icon>mdi-delete</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t("global.remove") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
    <template v-slot:item.updated_at="{ item }">
      {{ item.updated_at | formatDatetime }}
    </template>
  </v-data-table>
</template>

<script>
import { debounce } from "debounce";
import FilterMenu from "@/components/Configurations/RegisteredUsers/FilterMenu.vue";
export default {
  components: {
    FilterMenu,
  },
  data() {
    return {
      menu: false,
      headers: [
        {
          text: "",
          value: "actions",
          sortable: false,
          width: "30px",
        },
        {
          text: this.$t("registered_users.name"),
          value: "users.name",
        },
        {
          text: this.$t("registered_users.email"),
          value: "users.email",
        },
        {
          text: this.$t("registered_users.phone"),
          value: "users.phone",
        },
        {
          text: this.$t("registered_users.operator"),
          value: "operator_name",
        },
        {
          text: this.$t("registered_users.status"),
          value: "users.status",
        },
        {
          text: this.$t("global.updated_by"),
          value: "updater_name",
          class: "font-weight-bold",
          align: "start",
          sortable: true,
          width: "100px",
        },
        {
          text: this.$t("global.updated_at"),
          value: "updated_at",
          class: "font-weight-bold",
          align: "start",
          sortable: true,
          width: "150px",
        },
      ],
    };
  },
  computed: {
    loading() {
      return this.$store.state.registered_users.loading;
    },
    items() {
      return this.$store.state.registered_users.all;
    },
    options: {
      cache: false,
      get() {
        return this.$store.state.registered_users.options;
      },
      set(val) {
        this.$store.dispatch("registered_users/UPDATE_OPTIONS", val);
      },
    },
    totalItemCount() {
      return this.$store.state.registered_users.total;
    },
  },
  methods: {
    onFilterItems() {
      this.loadRegisteredUsers();
    },
    edit(item) {
      this.$emit("edit", item);
    },
    remove(item) {
      this.$emit("remove", item);
    },
    findStatusColor(status) {
      const colorMap = {
        REGISTERED: "grey",
        VERIFIED: "success",
        BLOCKED: "red"
      };

      // Default color is gray
      return colorMap[status.toUpperCase()] || "gray";
    },
    filterItems: debounce(function () {
      // Changes the current page to the first one to reset the search stop bugs from happening
      if (this.options.page == 1) {
        this.loadRegisteredUsers();
      } else {
        this.options.page = 1;
      }
    }, 500),

    handleOptionsUpdate: debounce(function () {
      this.loadRegisteredUsers();
    }, 500),

    loadRegisteredUsers() {
      this.$store
        .dispatch("registered_users/GET_ALL", this.options)
        .catch((ex) => {
          console.error("Error loading shorex users", ex);
        });
    },
  },
};
</script>

<style>
.registered-users-table .v-data-table__wrapper {
  max-height: calc(100vh - 290px);
}
</style>
