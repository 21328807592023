<template>
  <v-dialog v-model="isOpen" persistent width="900">
    <v-card tile>
      <v-expand-transition>
        <div v-show="expand">
          <v-alert class="mb-0" tile type="error" @click="expand = false">
            <div
              v-for="(message, index) in this.messages"
              :key="index"
              class="subtitle"
            >
              {{ message }}
            </div>
          </v-alert>
        </div>
      </v-expand-transition>
      <v-progress-linear
        height="10"
        striped
        color="primary"
        indeterminate
        v-show="loading"
      ></v-progress-linear>
      <v-toolbar color="primary" flat>
        <v-toolbar-title>
          <span>
            <v-icon left>mdi-pencil</v-icon>{{ $t("operators.update") }}
          </span>
        </v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text
        v-if="operatorDetails"
        class="pt-4"
        style="max-height: 50vh; overflow-y: auto"
      >
        <v-text-field
          v-model="operatorDetails.name"
          :label="$t('operators.name')"
          type="text"
          outlined
          disabled
        ></v-text-field>

        <v-text-field
          v-model="operatorDetails.vat_number"
          :label="$t('global.vat')"
          type="text"
          outlined
          disabled
        ></v-text-field>

        <v-text-field
          v-model="operatorDetails.email"
          :label="$t('operators.email')"
          type="text"
          outlined
          disabled
        ></v-text-field>

        <v-form
          ref="formOperatorDetails"
          @submit.prevent="updateOperatorDetails"
          lazy-validation
          v-model="validOperatorProfileDetails"
          :disabled="isUpdatingOperatorDetails"
        >
          <div
            class="bordered-div mb-4"
            v-for="license in licenses"
            :key="license.id"
          >
            <div class="type-title">{{ license.name }}</div>
            <v-btn
              class="delete-type elevation-0"
              @click="licenses.splice(licenses.indexOf(license), 1)"
              text
              small
              :ripple="false"
              outlined
            >
              {{ $t("global.delete") }}
            </v-btn>
            <div class="content">
              <v-row>
                <v-col cols="12" md="4" sm="12" xs="12">
                  <v-text-field
                    :label="$t('operator_licenses.file_number')"
                    v-model="license.file_number"
                    type="text"
                    outlined
                    autocomplete="off"
                    :rules="basicRules"
                    counter="25"
                    maxlength="25"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3" sm="12" xs="12">
                  <v-menu
                    v-model="license.dateMenu"
                    :close-on-content-click="false"
                    transition="slide-y-transition"
                    min-width="auto"
                    :offset-y="true"
                    top
                    class="ma-0 pa-0"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="license.validation_date"
                        :label="$t('operator_licenses.validation_date')"
                        append-icon="mdi-calendar"
                        outlined
                        v-bind="attrs"
                        v-on="on"
                        :rules="validationDateRules"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="license.validation_date"
                      @input="license.dateMenu = false"
                      :allowed-dates="disablePastDates"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="9" md="3" sm="9" xs="9">
                  <div class="ma-0 pa-0">
                    <v-file-input
                      v-model="license.file"
                      outlined
                      accept="application/pdf"
                      :label="$t('operator_licenses.file')"
                      append-icon="mdi-file"
                      clear-icon
                      prepend-icon=""
                      :rules="fileRules"
                    ></v-file-input>
                  </div>
                </v-col>

                <v-col cols="3" md="2" sm="3" xs="3">
                  <div class="ma-0 pa-0 d-flex justify-center align-center">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          :disabled="!license.legal_doc_path"
                          class="hover"
                          outlined
                          x-large
                          color="#8c7f7f"
                          @click="downloadFile(license.id)"
                          style="height: 55px"
                        >
                          <v-progress-circular
                            v-if="
                              downloadingFile != null &&
                              downloadFile == license.id
                            "
                            indeterminate
                            color="primary"
                          ></v-progress-circular>
                          <v-icon v-else-if="!license.legal_doc_path"
                            >mdi-file</v-icon
                          >
                          <v-icon v-else>mdi-file-download-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t("operators.download_file") }}</span>
                    </v-tooltip>
                  </div>
                </v-col>
              </v-row>
            </div>
          </div>
          <v-row v-if="verifyIfCanAddOperatorType">
            <v-col cols="12" md="12" sm="12" xs="12">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="openTypeSelector = true"
                    v-bind="attrs"
                    v-on="on"
                    block
                    text
                    outlined
                    style="border-color: black !important"
                    ><v-icon>mdi-plus</v-icon></v-btn
                  >
                </template>
                <span>{{ $t("operators.add_operator_type") }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="pt-0">
        <v-btn
          :loading="isUpdatingOperatorDetails"
          depressed
          @click="updateOperatorDetails"
          large
          block
          class="black--text"
          color="primary"
          :disabled="isUpdatingOperatorDetails || licenses.length == 0"
        >
          <v-icon left> mdi-content-save </v-icon>
          {{ $t("global.update_operator_details") }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-snackbar
      :timeout="5000"
      :value="updatedSuccessfully"
      absolute
      top
      color="green darken-2"
      elevation="10"
      text
    >
      {{ $t("operators.updated_successfully") }}
    </v-snackbar>

    <OperatorTypeSelector
      v-if="openTypeSelector"
      :open.sync="openTypeSelector"
      :licenses="licenses"
      @addType="handleAddOperatorType"
    />
  </v-dialog>
</template>

<script>
import OperatorTypeSelector from "@/components/Configurations/OperatorTypeSelector.vue";
export default {
  props: ["open"],
  components: {
    OperatorTypeSelector,
  },
  data() {
    return {
      openTypeSelector: false,
      expand: false,
      messages: [],
      licenses: [],
      operatorDetails: null,
      loading: false,
      updatedSuccessfully: false,
      downloadingFile: null,
      validOperatorProfileDetails: false,
      isUpdatingOperatorDetails: false,
      validationDateRules: [
        (v) => !!v || this.$t("rules.validation_date_required"),
      ],
      fileRules: [(v) => !!v || this.$t("rules.file_required")],
      basicRules: [(v) => !!v || this.$t("rules.required_field")],
    };
  },
  mounted() {
    this.licenses = [];
    this.operatorDetails = structuredClone(this.operator);
    this.getOperatorTypes();
  },
  watch: {
    isOpen(val) {
      if (val) {
        this.getOperatorDetails();
      }
    },
  },
  computed: {
    operator() {
      return this.$store.state.operators.currentOperator;
    },
    operatorTypes() {
      return this.$store.state.operator_types.all;
    },
    isOpen: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("update:open", val);
      },
    },
    verifyIfCanAddOperatorType() {
      return (
        this.operatorTypes &&
        this.operatorTypes.length > 0 &&
        !this.loading &&
        this.licenses.length < this.operatorTypes.length
      );
    },
  },
  methods: {
    getOperatorDetails() {
      this.loading = true;
      this.$store
        .dispatch("operators/GET_OPERATOR_BY_ID", this.operator.id)
        .then((data) => {
          this.operatorDetails = data;

          this.$store
            .dispatch("operators/GET_OPERATOR_LICENSES", this.operator.id)
            .then((data) => {
              this.licenses = data.map((license) => {
                return {
                  ...license,
                  file: license.legal_doc_path
                    ? new File([], "documento.pdf", {
                        type: "application/pdf",
                      })
                    : null,
                  dateMenu: false,
                };
              });
              this.loading = false;
            })
            .catch(() => {
              this.loading = false;
            });
        })
        .catch(() => {
          this.loading = false;
        });
    },

    getOperatorTypes() {
      this.$store.dispatch("operator_types/GET_ALL");
    },
    disablePastDates(date) {
      return date >= new Date().toISOString().substring(0, 10);
    },
    close() {
      this.isOpen = false;
      this.loading = false;
      this.updatedSuccessfully = false;
    },
    downloadFile(id) {
      this.downloadingFile = id;
      this.$store
        .dispatch("operators/DOWNLOAD_FILE", id)
        .then(() => {
          this.downloadingFile = null;
        })
        .catch((err) => {
          this.downloadingFile = null;
          this.errorDialog = true;
          console.log(err);
        });
    },
    updateOperatorDetails() {
      this.updatedSuccessfully = false;
      this.expand = false;
      this.loading = true;
      this.messages = [];
      if (this.$refs.formOperatorDetails.validate()) {
        this.isUpdatingOperatorDetails = true;

        this.$store
          .dispatch("operators/UPDATE_LICENSES", {
            operatorId: this.operatorDetails.id,
            licenses: this.licenses,
          })
          .then((res) => {
            if (res.errors) {
              Object.entries(res.errors).forEach(([, value]) => {
                this.messages = this.messages.concat(value);
              });
              this.expand = true;
              this.loading = false;
            } else {
              this.updatedSuccessfully = true;
              this.loading = false;
              this.isUpdatingOperatorDetails = false;
              this.getOperatorDetails();
            }
          })
          .catch(() => {
            this.loading = false;
            this.isUpdatingOperatorDetails = false;
          });
      } else {
        this.expand = false;
        this.loading = false;
      }
    },
    handleAddOperatorType(type) {
      let opType = this.operatorTypes.find((t) => t.id === type);

      this.licenses.push({
        id: null,
        type_id: opType.id,
        name: opType.name,
        file_number: null,
        validation_date: null,
        legal_doc_path: null,
        file: null,
        dateMenu: false,
      });
    },
  },
};
</script>

<style scoped>
.hover:hover {
  color: black !important;
}
.bordered-div {
  position: relative;
  border: 1px solid rgb(0 0 0 / 30%);
  border-radius: 5px;
  padding: 10px;
}

.type-title {
  position: absolute;
  top: -7px;
  left: 10px;
  background-color: white;
  padding: 0 5px;
  font-weight: 600;
  font-size: 1rem;
}

.delete-type {
  position: absolute;
  top: -10px;
  right: 10px;
  background-color: white !important;
  color: red;
  font-size: 0.8rem;
  font-weight: 600;
}

.content {
  margin-top: 20px; /* Adjust according to your title height */
}
</style>
