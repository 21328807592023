<template>
  <v-dialog v-model="isOpen" persistent width="60%">
    <v-card tile>
      <v-expand-transition>
        <div v-show="expand">
          <v-alert class="mb-0" tile type="error" @click="expand = false">
            <div
              v-for="(message, index) in this.messages"
              :key="index"
              class="subtitle"
            >
              {{ message }}
            </div>
          </v-alert>
        </div>
      </v-expand-transition>
      <v-toolbar color="primary" flat>
        <v-toolbar-title>
          <span>
            <v-icon left>mdi-pencil</v-icon>{{ $t("sectors.edit") }}
          </span>
        </v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="overflow-y-auto pb-0 form-content">
        <Loading v-if="loading" v-model="loading"></Loading>
        <div v-else>
          <Loading
            v-if="saving"
            :value="saving"
            :custom-message="$t('global.updating_please_wait')"
          ></Loading>
          <v-form ref="form" class="mt-5" v-else>
            <div v-if="!!itemToEdit">
              <v-text-field
                counter="50"
                maxlength="50"
                v-model="itemToEdit.name"
                required
                :label="$t('sectors.name')"
                :rules="nameRules"
                outlined
              />
              <ColorPickerInput
                v-model="itemToEdit.color"
                :label="$t('sectors.color')"
              />
            </div>
          </v-form>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer />
        <v-btn
          class="black--text"
          color="primary"
          depressed
          :disabled="loading || saving"
          @click="save"
        >
          {{ $t("global.update") }}
        </v-btn>
      </v-card-actions>
    </v-card></v-dialog
  >
</template>
<script>
import Loading from "@/components/Main/Loading";
import ColorPickerInput from "@/components/Main/ColorPickerInput";

export default {
  props: ["open", "plan", "item"],
  components: { Loading, ColorPickerInput },
  data() {
    return {
      loading: false,
      saving: false,
      expand: false,
      messages: [],
      nameRules: [
        (v) => !!v || this.$t("rules.required_field"),
        (v) => (v && v.length <= 255) || this.$t("rules.character_limit"),
      ],
    };
  },
  computed: {
    isOpen: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("update:open", val);
      },
    },
    itemToEdit: {
      get() {
        return this.item;
      },
      set(val) {
        this.$emit("update:item", val);
      },
    },
  },
  watch: {
    open(val) {
      if (val) {
        this.$nextTick(() => {
          this.$refs.form.resetValidation();
        });
      }
    },
  },
  methods: {
    close() {
      this.isOpen = false;
      setTimeout(() => {
        this.expand = false;
        this.loading = false;
        this.saving = false;
      }, 600);
    },
    save() {
      this.expand = false;
      this.saving = true;
      this.messages = [];
      if (this.$refs.form.validate()) {
        this.$store.dispatch("sectors/UPDATE", this.itemToEdit).then((res) => {
          if (res.errors) {
            Object.entries(res.errors).forEach(([, value]) => {
              this.messages = this.messages.concat(value);
            });
            this.expand = true;
            this.saving = false;
          } else {
            this.close();
          }
        });
      } else {
        this.expand = false;
        this.saving = false;
      }
    },
  },
};
</script>
<style scoped>
.form-content {
  position: relative;
  min-height: 300px;
  max-height: calc(80vh - var(--toolbar-height));
}
</style>
