<template>
  <div>
    <v-data-table
      :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'mdi-page-first',
        lastIcon: 'mdi-page-last',
        'items-per-page-options': [5, 10, 25, 50],
      }"
      :loading="loading"
      :search.sync="options.search"
      :page.sync="options.page"
      :items-per-page.sync="options.itemsPerPage"
      :headers="headers"
      :items="items"
      :server-items-length="totalItemCount"
      @update:options="handleOptionsUpdate('options', $event)"
      item-key="id"
      fixed-header
      :sort-by.sync="options.sortBy"
      :sort-desc.sync="options.sortDesc"
      must-sort
      show-expand
      class="vehicles-table"
    >
      <template v-slot:top>
        <v-text-field
          v-model="options.search"
          single-line
          :label="$t('vehicles.search')"
          prepend-inner-icon="mdi-magnify"
          outlined
          @input="filterItems"
        >
          <template v-slot:append>
            <v-menu
              v-model="showAdvancedSearchOptions"
              :close-on-content-click="false"
              :nudge-width="200"
              offset-x
            >
              <template v-slot:activator="{ on, attrs }">
                <v-badge
                  overlap
                  color="green"
                  :value="nFilters"
                  :content="nFilters"
                >
                  <v-btn icon text style="top: -6px" v-bind="attrs" v-on="on">
                    <v-icon>mdi-tune-vertical-variant</v-icon>
                  </v-btn>
                </v-badge>
              </template>

              <v-card width="300">
                <v-card-title>{{ $t("vehicles.filter_by") }}:</v-card-title>
                <v-card-text>
                  <v-select
                    v-model="options.filterByType"
                    required
                    clearable
                    :label="$t('vehicles.type')"
                    :placeholder="$t('vehicles.type')"
                    item-value="name"
                    :items="vehicleTypes"
                    prepend-inner-icon="mdi-car-cog"
                    outlined
                    @change="filterItems"
                    hide-details
                    class="mb-2"
                  >
                    <template v-slot:item="{ item }">
                      <v-icon small left v-html="item.icon"></v-icon
                      >{{ item.name }}
                    </template>
                    <template v-slot:selection="{ item }">
                      {{ item.name }}
                    </template>
                  </v-select>
                  <v-select
                    v-model="options.filterByStatus"
                    required
                    clearable
                    :label="$t('vehicles.status')"
                    :placeholder="$t('vehicles.status')"
                    item-value="code"
                    :items="vehicleStatuses"
                    prepend-inner-icon="mdi-list-status"
                    outlined
                    @change="filterItems"
                    hide-details
                  >
                    <template v-slot:item="{ item }">
                      {{ $t("vehicle_states." + item.code.toLowerCase()) }}
                    </template>
                    <template v-slot:selection="{ item }">
                      {{ $t("vehicle_states." + item.code.toLowerCase()) }}
                    </template>
                  </v-select>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn text @click="resetFilters">{{
                    $t("global.reset")
                  }}</v-btn>
                  <v-btn text @click="showAdvancedSearchOptions = false">{{
                    $t("global.close")
                  }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </template>
        </v-text-field>
      </template>
      <template
        v-if="
          (hasUserPermissionToViewEdit('VEHICLES') &&
            vehicle_types.length > 0) ||
          hasUserPermissionToManage('VEHICLES')
        "
        v-slot:[`item.actions`]="{ item }"
      >
        <v-menu bottom absolute>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item v-if="vehicle_types.length > 0" @click="edit(item)">
              <v-list-item-icon class="mr-2">
                <v-icon>mdi-pencil</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t("global.edit") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-if="
                hasUserPermissionToManage('VEHICLES') &&
                (item.status == 'CANCELED' || item.status == 'PENDING') &&
                item.can_be_deleted
              "
              @click="remove(item)"
            >
              <v-list-item-icon class="mr-2">
                <v-icon>mdi-delete</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t("global.remove") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template v-slot:item.registration_plate="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              color="black"
              text
              plain
              class="pa-0 font-weight-black"
              :title="$t('global.view_details')"
              @click.stop="showVehicleDetail(item)"
            >
              <span class="registration-plate-font">
                {{ item.registration_plate }}
              </span>
            </v-btn></template
          >
          <span>{{ $t("global.view_details") }}</span>
        </v-tooltip>
      </template>
      <template v-slot:item.status="{ item }">
        <v-chip
          dark
          small
          label
          :color="getVehicleStatusColor(item)"
          class="text-uppercase"
        >
          {{ $t("vehicle_states." + item.status.toLowerCase()) }}
        </v-chip>
      </template>
      <template v-slot:item.type="{ item }">
        <v-chip small label outlined class="text-uppercase">
          <v-icon
            color="grey lighten-1"
            small
            left
            v-html="item.type_icon"
          ></v-icon
          >{{ item.type }}
        </v-chip>
      </template>
      <template v-slot:item.valid_until="{ item }">
        {{ item.valid_until | formatDateFromDatabase }}
      </template>
      <template v-slot:item.updated_at="{ item }">
        {{ item.updated_at | formatDatetime }}
      </template>
      <template v-slot:item.data-table-expand="{ item, isExpanded, expand }">
        <v-btn
          v-if="!!item.notes"
          icon
          text
          x-small
          @click="expand(!isExpanded)"
        >
          <v-icon small :color="isExpanded ? 'blue' : ''"
            >mdi-chat-processing-outline</v-icon
          >
        </v-btn>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <div class="pa-2">
            <table>
              <tr v-if="!!item.notes">
                <th>{{ $t("vehicles.notes") }}:</th>
                <td
                  class="pl-5 text-uppercase"
                  style="white-space: pre-wrap"
                  v-html="item.notes"
                ></td>
              </tr>
            </table>
          </div>
        </td>
      </template>
      <template #footer.prepend>
        <v-spacer />
      </template>
    </v-data-table>
    <VehicleDialog
      :open.sync="openVehicleDialog"
      :item="selectedVehicle"
      v-if="openVehicleDialog"
    />
  </div>
</template>

<script>
import { debounce } from "debounce";
import configs from "@/helpers/configs";
import VehicleDialog from "@/components/Configurations/Vehicles/ShowItemDialog";
export default {
  components: { VehicleDialog },
  data() {
    return {
      nFilters: 0,
      headers: [
        {
          text: "",
          value: "actions",
          sortable: false,
          width: "30px",
        },
        {
          text: this.$t("vehicles.registration_plate"),
          align: "start",
          sortable: true,
          value: "registration_plate",
          width: "120px",
        },
        {
          text: this.$t("vehicles.operator"),
          value: "operator_name",
          align: "start",
          sortable: true,
          width: "150px",
        },
        {
          text: this.$t("vehicles.type"),
          value: "type",
          align: "start",
          sortable: true,
          width: "80px",
        },
        {
          text: this.$t("vehicles.valid_until"),
          value: "valid_until",
          class: "font-weight-bold",
          align: "start",
          sortable: true,
          width: "100px",
        },
        {
          text: this.$t("vehicles.status"),
          value: "status",
          class: "font-weight-bold",

          sortable: true,
          width: "100px",
        },
        {
          text: this.$t("global.created_by"),
          value: "creator_name",
          class: "font-weight-bold",
          align: "start",
          sortable: true,
          width: "100px",
        },
        {
          text: this.$t("global.updated_by"),
          value: "updater_name",
          class: "font-weight-bold",
          align: "start",
          sortable: true,
          width: "100px",
        },
        {
          text: this.$t("global.updated_at"),
          value: "updated_at",
          class: "font-weight-bold",
          align: "start",
          sortable: true,
          width: "150px",
        },
        {
          text: "",
          value: "data-table-expand",

          groupable: false,
          sortable: false,
        },
      ],
      selectedItem: null,
      openVehicleDialog: false,
      showAdvancedSearchOptions: false,
    };
  },
  mounted() {
    this.loadVehicleTypes();
  },
  watch: {
    options: {
      deep: true,
      handler(v) {
        this.nFilters = 0;
        if (v.filterByStatus) this.nFilters++;
        if (v.filterByType) this.nFilters++;
      },
    },
  },
  computed: {
    vehicleStatuses() {
      return configs.getVehicleStatuses();
    },
    vehicleTypes() {
      return this.$store.state.vehicle_types.all;
    },
    loading() {
      return this.$store.state.vehicles.loading;
    },
    items() {
      return this.$store.state.vehicles.all;
    },
    vehicle_types() {
      return this.$store.state.vehicle_types.all;
    },
    options: {
      cache: false,
      get() {
        return this.$store.state.vehicles.options;
      },
      set(val) {
        this.$store.dispatch("vehicles/UPDATE_OPTIONS", val);
      },
    },
    totalItemCount() {
      return this.$store.state.vehicles.total;
    },
  },
  methods: {
    getVehicleStatusColor(item) {
      let status = this.vehicleStatuses.find((v) => {
        return v.code == item.status;
      });
      if (status) {
        return status.color;
      }
      return null;
    },
    loadVehicleTypes() {
      this.$store
        .dispatch("vehicle_types/GET_ALL_WITHOUT_PAGINATED")
        .catch((ex) => {
          console.error("Error loading vehicle types", ex);
        });
    },
    loadVehicles() {
      this.$store.dispatch("vehicles/GET_ALL").catch((ex) => {
        console.error("Error loading vehicles", ex);
      });
    },
    handleOptionsUpdate: debounce(function () {
      this.loadVehicles();
    }, 500),

    filterItems: debounce(function () {
      // Changes the current page to the first one to reset the search stop bugs from happening
      if (this.options.page == 1) {
        this.loadVehicles();
      } else {
        this.options.page = 1;
      }
    }, 500),
    showVehicleDetail(vehicle) {
      this.openVehicleDialog = true;
      this.selectedVehicle = vehicle;
    },
    edit(item) {
      this.$emit("edit", item);
    },
    remove(item) {
      this.$emit("remove", item);
    },
    resetFilters() {
      this.options.filterByStatus = null;
      this.options.filterByType = null;
      this.filterItems();
    },
  },
};
</script>
<style>
.vehicles-table .v-data-table__wrapper {
  max-height: calc(100vh - 290px);
}

.vehicles-table.v-data-table
  > .v-data-table__wrapper
  tbody
  tr.v-data-table__expanded__content {
  box-shadow: none !important;
  background-color: rgb(245, 242, 223);
}
</style>

<style scoped>
.registration-plate-font {
  font-family: "Fira Mono", monospace !important;
  font-weight: 700 !important;
}
</style>
