import configs from "@/helpers/configs";
import axios from "axios";
import { sharedHelpers } from "@/api_services/utils/shared-helpers";

export const entryRequestsService = {
  createEntryRequest,
  getEntryRequests,
  getEntryRequestsByDate,
  getEntryRequest,
  cancelEntryRequest,
  registerEntryScan,
  editEntryRequestVehicle,
  importEntryRequests,
  exportEntryRequests,
  revertCancelEntryRequest,
  downloadTemplate,
};

async function createEntryRequest(entryRequest) {
  const requestOptions = {
    method: "POST",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.post(
    configs.getApiUrl() + "/v1/my-shorex/entry-requests",
    entryRequest,
    requestOptions
  );
}

async function getEntryRequests() {
  const requestOptions = {
    method: "GET",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.get(
    configs.getApiUrl() + `/v1/my-shorex/entry-requests`,
    requestOptions
  );
}

async function getEntryRequestsByDate(date) {
  const requestOptions = {
    method: "GET",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.get(
    configs.getApiUrl() + `/v1/my-shorex/entry-requests-by-date/${date}`,
    requestOptions
  );
}

async function getEntryRequest(id) {
  const requestOptions = {
    method: "GET",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.get(
    configs.getApiUrl() + `/v1/my-shorex/entry-requests/${id}`,
    requestOptions
  );
}

async function cancelEntryRequest(entryRequestId) {
  const requestOptions = {
    method: "PUT",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.put(
    `${configs.getApiUrl()}/v1/my-shorex/entry-requests/${entryRequestId}/cancel`,
    {},
    requestOptions
  );
}

async function revertCancelEntryRequest(entryRequestId) {
  const requestOptions = {
    method: "PUT",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.put(
    `${configs.getApiUrl()}/v1/my-shorex/entry-requests/${entryRequestId}/revert-cancel`,
    {},
    requestOptions
  );
}

async function registerEntryScan(payload) {
  const requestOptions = {
    method: "POST",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.post(
    configs.getApiUrl() + "/v1/scheduled-entry/register_entry",
    requestOptions,
    payload
  );
}

async function editEntryRequestVehicle(payload) {
  const requestOptions = {
    method: "PUT",
    headers: sharedHelpers.getRequestHeaders(),
    params: {
      vehicleId: payload.vehicleId,
      responsibleName: payload.responsibleName,
      responsiblePhone: payload.responsiblePhone,
      numberOfPassengers: payload.numberOfPassengers,
    },
  };
  return await axios.put(
    `${configs.getApiUrl()}/v1/my-shorex/entry-requests/${
      payload.entryRequestId
    }/edit`,
    {},
    requestOptions
  );
}

async function downloadTemplate() {
  const requestOptions = {
    method: "POST",
    responseType: "blob",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.post(
    configs.getApiUrl() + "/v1/my-shorex/entry-requests/download_template",
    {},
    requestOptions
  );
}

async function importEntryRequests(payload) {
  const requestOptions = {
    method: "POST",
    headers: sharedHelpers.getRequestHeaders(),
  };
  var bodyFormData = new FormData();
  for (let key in payload) {
    bodyFormData.append(key, payload[key]);
  }
  return await axios.post(
    configs.getApiUrl() + "/v1/my-shorex/entry-requests/import",
    bodyFormData,
    requestOptions
  );
}

async function exportEntryRequests(date) {
  const requestOptions = {
    method: "POST",
    responseType: "blob",
    headers: sharedHelpers.getRequestHeaders(),
  };
  return await axios.post(
    configs.getApiUrl() + `/v1/my-shorex/entry-requests/export/${date}`,
    {},
    requestOptions
  );
}
