import * as maptalks from "maptalks";

export default class Rope extends maptalks.ConnectorLine {
  constructor(src, target, options) {
    super(src, target, options);
  }

  _registerEvents() {
    if (!this._connSource || !this._connTarget) {
      return;
    }
    if (!this._connSource.__connectors) {
      this._connSource.__connectors = [];
    }
    if (!this._connTarget.__connectors) {
      this._connTarget.__connectors = [];
    }
    this._connSource.__connectors.push(this);
    this._connTarget.__connectors.push(this);
    this._connSource
      .on("dragging ", this.hide, this)
      .on("positionchange", this._updateCoordinates, this)
      .on("remove", this.remove, this);
    this._connTarget
      .on("dragging ", this.hide, this)
      .on("positionchange", this._updateCoordinates, this)
      .on("remove", this.remove, this);
    this._connSource
      .on("show", this._showConnect, this)
      .on("hide", this.hide, this);
    this._connTarget
      .on("show", this._showConnect, this)
      .on("hide", this.hide, this);
    const trigger = this.options["showOn"];
    this.hide();
    if (trigger === "moving") {
      this._connSource
        .on("dragstart", this._showConnect, this)
        .on("dragend", this.hide, this);
      this._connTarget
        .on("dragstart", this._showConnect, this)
        .on("dragend", this.hide, this);
    } else if (trigger === "click") {
      this._connSource
        .on("mousedown", this._showConnect, this)
        .on("mouseup", this.hide, this);
      this._connTarget
        .on("mousedown", this._showConnect, this)
        .on("mouseup", this.hide, this);
    } else if (trigger === "mouseover") {
      this._connSource
        .on("mouseover", this._showConnect, this)
        .on("mouseout", this.hide, this);
      this._connTarget
        .on("mouseover", this._showConnect, this)
        .on("mouseout", this.hide, this);
    } else {
      this._showConnect();
    }

    //not a geometry
    if (
      !(this._connSource instanceof maptalks.Geometry) ||
      !(this._connTarget instanceof maptalks.Geometry)
    ) {
      const map = this.getMap();
      if (map) {
        map.on(
          "movestart moving moveend zoomstart zooming zoomend rotate pitch fovchange spatialreferencechange",
          this._updateCoordinates,
          this
        );
      }
    }
  }
}

Rope.registerJSONType("Rope");
