<template>
  <div>
    <v-snackbar
      :timeout="10000"
      v-model="snackbar"
      absolute
      centered
      top
      color="red accent-2"
    >
      <span style="font-size: 1rem; font-weight: 600">{{
        $t("global.nav_api_error")
      }}</span>

      <template v-slot:action="{ attrs }">
        <v-btn
          :ripple="false"
          x-small
          color="black"
          text
          v-bind="attrs"
          @click="snackbar = false"
          id="no-background-hover"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
const JULSHIPS_LAYERNAME = "jul_ships";
const DEFAULT_SHIP_LENGTH = 20;
const DEFAULT_SHIP_WIDTH = 5;

import * as maptalks from "maptalks";
import configs from "@/helpers/configs";
import AISShip from "@/helpers/ships/aisship";
export default {
  props: ["active", "map", "plan"],
  data() {
    return {
      vesselCallsData: [],
      snackbar: false,
    };
  },
  computed: {
    refMap: {
      get() {
        return this.map;
      },
      set(val) {
        this.$emit("update:map", val);
      },
    },
    date() {
      return this.plan.day;
    },
    activeShips: {
      get() {
        return this.active;
      },
      set(val) {
        this.$emit("update:active", val);
      },
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.recreateLayer();
      this.getVesselCallsLocations(this.date);
    });
  },
  watch: {
    activeShips(val) {
      if (val) {
        this.recreateLayer();
        this.getVesselCallsLocations(this.date);
      } else {
        this.recreateLayer();
      }
    },
  },
  methods: {
    validateDates(eta, etd) {
      if (eta == null || etd == null) return false;

      let now = new Date();
      let etaDate = new Date(eta);
      let etdDate = new Date(etd);

      return etaDate <= now && etdDate >= now;
    },
    recreateLayer() {
      if (this.map) {
        if (!this.map.getLayer(JULSHIPS_LAYERNAME))
          this.map.addLayer(new maptalks.VectorLayer(JULSHIPS_LAYERNAME));
        else this.map.getLayer(JULSHIPS_LAYERNAME).clear();
      }
    },
    mapShipToSpec(ship) {
      return {
        Id: ship.process_number,
        Name: ship.name,
        IMO: ship.process_number,
        Draggable: false,
        Length: Math.min(parseFloat(ship.beam || DEFAULT_SHIP_LENGTH), 150),
        Width: Math.min(parseFloat(ship.loa || DEFAULT_SHIP_WIDTH), 20),
        MapGeom: "POINT(" + ship.lon + " " + ship.lat + ")",
        Heading: parseFloat(ship.hdg || 0),
        Flag: ship.countryCode || "N/D",
        Color: "black",
        Cargo: ship.cargo,
        shipDimensions: {
          dimA: ship.loa ? ship.loa / 2 : DEFAULT_SHIP_LENGTH / 2,
          dimB: ship.loa ? ship.loa / 2 : DEFAULT_SHIP_LENGTH / 2,
          dimC: ship.beam ? ship.beam / 2 : DEFAULT_SHIP_WIDTH / 2,
          dimD: ship.beam ? ship.beam / 2 : DEFAULT_SHIP_WIDTH / 2,
        },
      };
    },
    getVesselCallsLocations(date) {
      this.$store
        .dispatch("vessel_calls/GET_VESSEL_CALLS_LOCATIONS", date)
        .then((data) => {
          this.vesselCallsData = data;

          if (this.vesselCallsData.error) {
            this.activeShips = false;
            this.snackbar = true;
          } else {
            this.vesselCallsData
              .filter((ship) => {
                return (
                  ship.lat && ship.lon && this.validateDates(ship.eta, ship.etd)
                );
              })
              .forEach((ship) => {
                let shipSpec = this.mapShipToSpec(ship);

                let drawnShip = new AISShip(
                  [],
                  {
                    properties: {
                      id: shipSpec.Id,
                      name: shipSpec.Name,
                      lineDasharray: ship.updating ? null : [5],
                      lineColor: configs.getShipType(shipSpec.Cargo).color,
                      fillColor: configs.getShipType(shipSpec.Cargo).color,
                      width: shipSpec.Width,
                      length: shipSpec.Length,
                      flag: shipSpec.Flag,
                    },
                    draggable: false,
                  },
                  shipSpec
                );
                if (drawnShip) {
                  drawnShip.addTo(this.map.getLayer(JULSHIPS_LAYERNAME));
                }
              });
          }
        });
    },
  },
};
</script>

<style>
#no-background-hover::before {
  background-color: transparent !important;
}
</style>
