<template>
  <Operators />
</template>

<script>
import Operators from "@/components/Configurations/Operators.vue";

export default {
  components: {
    Operators,
  },
};
</script>
